import React from 'react';
import styled from 'styled-components';
import zwijnen from "./../assets/zwijnen.mp4";
import VideoElement from './VideoElement';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Hero = ({checkEndDate}) => {
  const { t, i18n } = useTranslation();
  const path = window.location.pathname;

  return (
    <StyledHero>
        <div className="hero"> 
          <div className="heroCopy"> 
              {path.startsWith("/vacatures") &&
                <div>
                  <h1>{t("menu_over_vacatures")}</h1> 
                </div>
              }
              {path === "/service" &&
                <div>
                  <h1>{t("menu_service")}</h1> 
                  <p>{t("service_copy_1")}</p>
                  <p>{t("service_copy_2")}<Link to="/contact">{t("service_copy_button")}</Link>{t("service_copy_3")} </p>
                </div>
              }
              {path === "/geschiedenis" &&
                <div>
                  <h1>{t("history_page_title")}</h1> 
                </div>
              }
              {path === "/imprint" &&
                <div>
                  <h1>{t("imprint.page_heading")}</h1> 
                </div>
              }
              {path === "/cookieverklaring" &&
                <div>
                  <h1>{t("cookie_policy.page_heading")}</h1> 
                </div>
              }
              {path === "/hoe-wij-werken" &&
                <div>
                  <h1>{t("work_page_title")}</h1> 
                </div>
              }
              {path === "/woordenlijst" &&
                <div>
                  <h1>{t("glossary_page_title")}</h1> 
                </div>
              }
              {path === "/techniek" &&
                <div>
                  <h1>{t("tech_page_title")}</h1> 
                </div>
              }
              {path === "/dealers" &&
                <div>
                  <h1>{t("dealers_page_title")}</h1> 
                </div>
              }
              {path === "/evenementen" &&
                <div>
                  <h1>{t("menu_over_events")}</h1> 
                  <p>{t("events_copy_1")}</p>
                  <p>{t("events_copy_2")}</p>
                  
                </div>
              }
              {path === "/jagd-und-hund" &&
                <div>
                  <h1>{t("jagd_und_hund.page_heading")}</h1>
                  <p>{t("jagd_und_hund.page_hero_copy")}</p> 
                </div>
              }

              {path === "/hohe-jagd-und-fischerei" &&
                <div>
                  <h1>{t("hohe_jagd_und_fischerei.page_heading")}</h1>
                  <p>{t("hohe_jagd_und_fischerei.page_hero_copy")}</p> 
                </div>
              }

              {path === "/contact" &&
                <address>
                  <h1>{t("contact_title")}</h1> 
                  <span></span>
                  <p>Stephensonstraat 47, 2014 KC Haarlem</p>
                  <p className="line">{t("contact_p")}</p>
                        <div>
                            <p>{t("contact_tel")}: <a href="tel:0031235514678"> +31 23 5514678</a> ({t("contact_dealers")})</p>
                        </div>
                        <div>
                            <p>{t("contact_mail")}: <a href="mailto:info@lahoux.nl">info@lahoux.nl</a></p>
                        </div>    
                </address>
              }              
          </div>
          <VideoElement video={zwijnen} videoRoundClass={"video-round"} />  

       </div> 
      
    </StyledHero>
  )
}
const StyledHero = styled.div`


  .hero{
    max-width: 100vw;
    margin-top: 100px;
   
  }
  
  h1{
     padding-bottom: 5px;
     max-width: 400px;
     color: white;
     font-size: 40px;
     font-weight: bold;
     border-bottom: 6px solid var(--brand-accent-color);
     margin-bottom: 10px;
     
     }
  p{
    font-size: 20px;
  }   
 
  address {
    font-style: normal;
  }

  .heroCopy{
    text-align: left;
    width: 100%;
    padding: 10px;

    @media (min-width: 768px) {
      width:50%;
    }
  
    @media (min-width: 1024px) {
      width: 48%;
      left: 50%;
      top: 48vh;
      position: absolute;
      z-index: 10;
    }

    @media (min-width: 1400px) {
      width:36%;
    }

    @media (min-width: 1632px) {
      width:30%;
    }
  }

  .heroCopy a{
    color: var(--link);
    font-weight: bold;
  }
 

 .video-round { 
  width: 300px;
  height: 300px;
  z-index: 1;
  margin: 0 auto;

  @media (min-width: 1024px) {
    width: 350px;
    height: 350px;
  }
}

.rozet {
  width: 250px;
  position: absolute;
  right: 34px;
  top: 100px;
  transform: rotate(12deg);
  cursor: pointer;

  @media (min-width: 768px) {
    width: 300px;
  }
}
  
` 

export default Hero