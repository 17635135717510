import React from "react";
import { Routes, Route } from "react-router-dom";
import HomeArticle from "../HomeArticle";
import SelectShow from "../SelectShow";
import Explanations from "../Explanation";
import VacaturesAll from "../VacaturesAll";
import VacatureDetail from "../VacatureDetail";
import Productoverview from "../Productoverview";
import EventsAll from "../EventsAll";
import Dealers from "../Dealers";
import History from "../History";
import HowWeWork from "../HowWeWork";
import Privacy from "../Privacy";
import Glossary from "../Glossary";
import ProductSpecs from "../ProductSpecs";
import Imprint from "../Imprint";
import CookiePolicy from "../CookiePolicy";
import LahouxJubileum from "../LahouxJubileum/LahouxJubileum";
import JagdUndHund from "../EventPages/JagdUndHund";
import HoheJagdUndFischerei from "../EventPages/HoheJagdUndFischerei";

const RoutesMain = ({showItems, setShowItems, clickHandlerShowItem, clientCountry, clickHandlerShowCountry, clientVat, correctionPercentage, checkEndDate, statusFilter, changeHandlerStatusFilter, searchValue, setSearchValue, scrollPosition, setScrollPosition }) => {
  return (
    <Routes>
      <Route path="/" element={<HomeArticle checkEndDate={checkEndDate} />} />

      <Route
        path="/techniek"
        element={
          <>
            <SelectShow
              showItems={showItems}
              clickHandlerShowItem={clickHandlerShowItem}
            />
            <Explanations
              showItems={showItems}
              setShowItems={setShowItems}
              clickHandlerShowItem={clickHandlerShowItem}
            />
          </>
        }
      />

      <Route path="/vacatures" element={<VacaturesAll />} />

      <Route path="/vacatures/:url" element={<VacatureDetail />} />

      <Route
        path="/producten"
        element={
          <>
            <SelectShow
              showItems={showItems}
              clickHandlerShowItem={clickHandlerShowItem}
            />
            <Productoverview
              clientCountry={clientCountry}
              showItems={showItems}
              setShowItems={setShowItems}
              clickHandlerShowItem={clickHandlerShowItem}
              statusFilter={statusFilter}
              changeHandlerStatusFilter={changeHandlerStatusFilter}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              scrollPosition={scrollPosition}
              setScrollPosition={setScrollPosition}
            />
          </>
        }
      />

      <Route
        path="/evenementen"
        element={
          <>
            <SelectShow
              showItems={showItems}
              clickHandlerShowItem={clickHandlerShowItem}
            />
            <EventsAll showItems={showItems} setShowItems={setShowItems} />
          </>
        }
      />

      <Route
        path="/service"
        element={
          <Dealers
            clientCountry={clientCountry}
            clickHandlerShowCountry={clickHandlerShowCountry}
          />
        }
      />

      <Route
        path="/contact"
        element={
          <Dealers
            clientCountry={clientCountry}
            clickHandlerShowCountry={clickHandlerShowCountry}
          />
        }
      />

      <Route
        path="/dealers"
        element={<Dealers clientCountry={clientCountry} />}
      />

      <Route path="/geschiedenis" element={<History />} />

      <Route path="/hoe-wij-werken" element={<HowWeWork />} />

      <Route path="/privacy" element={<Privacy />} />

      <Route
        path="/woordenlijst"
        element={
          <>
            <SelectShow
              showItems={showItems}
              clickHandlerShowItem={clickHandlerShowItem}
            />
            <Glossary
              showItems={showItems}
              setShowItems={setShowItems}
              clickHandlerShowItem={clickHandlerShowItem}
            />
          </>
        }
      />

      <Route
        path="/producten/:url"
        element={
          <ProductSpecs
            clientCountry={clientCountry}
            clientVat={clientVat}
            correctionPercentage={correctionPercentage}
          />
        }
      />

      <Route
        path="/imprint"
        element={<Imprint clientCountry={clientCountry} />}
      />

      <Route
        path="/cookieverklaring"
        element={<CookiePolicy clientCountry={clientCountry} />}
      />

      <Route
        path="/lahoux-jubileum"
        element={
          <LahouxJubileum clientCountry={clientCountry} clientVat={clientVat} />
        }
      />

      <Route
        path="/jagd-und-hund"
        element={
          <JagdUndHund
            clientCountry={clientCountry}
            clientVat={clientVat}
            checkEndDate={checkEndDate}
          />
        }
      />

      <Route
        path="/hohe-jagd-und-fischerei"
        element={
          <HoheJagdUndFischerei
            clientCountry={clientCountry}
            clientVat={clientVat}
            checkEndDate={checkEndDate}
          />
        }
      />
    </Routes>
  );
};

export default RoutesMain;
