import React from "react";
import { Routes, Route } from "react-router-dom";
import Hero from "../Hero";
import Home from "../Home";
import ProductHighlight from "../ProductHighlight";
import Productdetail from "../Productdetail";
import LahouxJubileumHeader from "../LahouxJubileum/LahouxJubileumHeader";
import PageNotFound from "../PageNotFound";
import ContentCarousel from "../ContentCarousel/ContentCarousel";
import DataContentCarouselHome from "../ContentCarousel/DataContentCarouselHome";

const RoutesHeader = ({ clientCountry, showItems, checkEndDate }) => {
  return (
    <Routes>
      
      <Route path="/" element={
          <>
            <Home checkEndDate={checkEndDate} />
            {/* <ContentCarousel carouselItems={DataContentCarouselHome} /> */}
          </>    
        } 
      />
   
      <Route path="/techniek" element={<Hero />} />
        
      <Route path="/geschiedenis" element={<Hero />} />

      <Route path="/imprint" element={<Hero />} />

      <Route path="/cookieverklaring" element={<Hero />} />

      <Route path="/lahoux-jubileum" element={<LahouxJubileumHeader />} />
        
      <Route path="/vacatures" element={<Hero />} />

      <Route path="/vacatures/:url" element={<Hero />} />

      <Route path="/producten" element={<ProductHighlight showItems={showItems} />} />
        
      <Route path="/evenementen" element={<Hero />} />

      <Route path="/jagd-und-hund" element={<Hero />} />

      <Route path="/hohe-jagd-und-fischerei" element={<Hero />} />

      <Route path="/service" element={<Hero />} />

      <Route path="/dealers" element={<Hero />} />

      <Route path="/privacy" element={<Hero />} />

      <Route path="/hoe-wij-werken" element={<Hero />} />

      <Route path="/contact" element={<Hero />} />

      <Route path="/woordenlijst" element={<Hero />} />

      <Route path="/producten/:url" element={<Productdetail />} />
        
      <Route path="/404" element={<PageNotFound />} />

      <Route path="*" element={<PageNotFound />} />

    </Routes>
  );
};

export default RoutesHeader;
