import React from 'react';
import styled from 'styled-components';
import {Helmet} from "react-helmet"
import { useTranslation } from "react-i18next";
import "../locales/i18n";



const Imprint = () => {
    const { t } = useTranslation();

  return (
      <StyledImprint>
         <Helmet>
            <title>{t("imprint.page_heading")}</title>
        </Helmet>
          <div className="imprint">
              <h1>{t("imprint.page_heading")}</h1>
              <p>
                  <span>{t("imprint.contact_company_name")}</span><br />
                  <span>{t("imprint.contact_street")}</span><br />
                  <span>{t("imprint.contact_zip_city")}</span><br />
                  <span>{t("imprint.contact_email")}</span><br />
                  <span>{t("imprint.contact_tel")}</span>
              </p>
              
              <p><span>{t("imprint.contact_person")}</span></p>
              <p><span>{t("imprint.contact_gericht")}</span></p>
             
              <p>
                  <span>{t("imprint.contact_coc")}</span><br />
                  <span>{t("imprint.contact_vat")}</span><br /> 
              </p>
        

              <h2>{t("imprint.dispute_heading")}</h2>
              <p>{t("imprint.dispute_text")}</p>

              <h2>{t("imprint.picture_credit_heading")}</h2>
              <p>
              <span>{t("imprint.picture_credit_p1")}</span><br />
              <span>{t("imprint.picture_credit_p2")}</span>
              </p>

            <div className="disclaimer">
              <h2>{t("imprint.disclaimer_heading")}</h2>

              <h3>{t("imprint.liability_heading")}</h3>
              <p>{t("imprint.liability_p1")}</p>

              <h3>{t("imprint.linking_heading")}</h3>
              <p>{t("imprint.linking_p1")}</p>

              <h3>{t("imprint.copyright_heading")}</h3>
              <p>{t("imprint.copyright_p1")}</p>
              </div>

          </div>
      </StyledImprint>
  )
}

const StyledImprint = styled.div`
margin: 60px auto 120px auto;
    max-width: 90vw;
    font-size: 1.25rem;
    @media (min-width: 768px) {
        max-width: 70vw;
      }

      @media (min-width: 1024px) {
        max-width: 50vw;
      }
    
    .imprint {
        max-width: 100vw;
        margin: 0 auto;
        @media (min-width: 768px) {
            max-width: 70vw;
          }
    
          @media (min-width: 1024px) {
            max-width: 50vw;
          }
    }

    h1 {
    font-size: 1.5rem;
    font-weight: 700;
    border-bottom: 2px solid var(--brand-accent-color);
    padding-bottom: 6px;
    margin-bottom: 20px;
    display: inline-block;
  }

  h2 {
    font-size: 1.375rem;
    font-weight: 700;
    border-bottom: 2px solid var(--brand-accent-color);
    padding-bottom: 6px;
    margin-top: 20px;
    margin-bottom: 10px;
    display: inline-block;
  }

  h3 {
      font-size: 1.25rem;
      font-weight: 700;
      margin-top: 10px;
      margin-bottom: 6px;
  }

  p {
    font-size: 1rem;
    line-height: 1.4;
    margin-bottom: 20px;
  }
   

.disclaimer h2 {
    margin-bottom: 20px;
}

`

export default Imprint