export const TRANSLATIONS_EN = {
    lang_de: "DE",
    lang_nl: "NL",
    lang_en: "EN",
    menu_home: "Home",
    menu_producten: "Products",
    menu_producten_nacht: "Nightvision",
    menu_producten_warm: "Thermal Imaging",
    menu_producten_acces: "Accessories",
    menu_producten_digi: "Digital",
    menu_producten_overig: "See more products",
    menu_techniek: "Technical",
    menu_over: "About us",
    menu_over_history: "History",
    menu_over_jubileum: "30 years offer",
    menu_over_how: "How we work",
    menu_over_vacatures: "Vacancies",
    menu_over_events: "Events",
    menu_events: "Events",
    menu_events_archive: "Archive",
    menu_service:"Service",
    menu_dealers: "Dealers",
    menu_woorden: "Glossary",
    menu_cookieverklaring: "Cookie Policy",
    menu_privacy: "Privacy policy",
    between:"Innovation in nightvision",
    betweenbutton: "Choose your destination",
    betweenoption1: "Hunting",
    betweenoption2: "Birding",
    betweenoption3: "Government",
    betweenoption4: "Dealerportal",
    betweenoption5: "Close",
    footer_volg: "Follow us",
    footer_rights: "All rights reserved",
    footer_price: "All prices are suggested retail prices incl. VAT",
    footer_disclaimer: "All information on this website is subject to change without notice. Lahoux products are continuously improved to ensure the best possible user experience.",
    home_title: "Give your eyes extraordinary power",
    home_copy: "With the Lahoux Spotter thermal imaging device, a new world opens up for you. Never before has it been so easy to get an overview of the world around us in the dark, or during difficult daytime visibility. With the help of thermal imaging all heat sources, animals, people and objects are easy to find, both day and night. ",
    home_button: "Read more",
    home_article_title: "Sharp vision, even in darkness",
    home_article_copy: "Lahoux Optics' products make finding animals in the dark possible and allow you to record all nighttime activities. For example, you can use a thermal imaging scope to track wildlife in the field or take counts of nocturnal animals. With the detailed image of Lahoux Optics' thermal imaging scopes, even small features become visible and many animals can even be identified.",
    home_article_button: "Discover our models",
    home_article2_title: "Lahoux Optics 30 years",
    home_article2_copy: "This year Lahoux Optics exists 30 years. A milestone that we as a company are proud of and want to reflect on. Because of our 30-year anniversary, we are offering our customers a number of discount offers this year.",
    home_article2_button: "See our anniversary offers",
    tech_intro_title: "Lahoux Optics",
    tech_intro_copy: "Thermal imaging scopes have proved indispensable to those who have experienced the usefulness of this technique. Both during the day and at night the viewers give an extra dimension to watching nature. As soon as a temperature difference can be measured somewhere, the camera will make this visible. It is therefore only thermal radiation that the camera detects. With residual light intensifiers and digital viewers the still present (visible) light at night is amplified into an image that is perfectly usable and turns night into day. The image is therefore much closer to what we are used to.",
    tech_page_title: "Technical",
    tech_nacht_1: "The razor-sharp image of our night vision scopes allows you to observe and address most animals and wildlife after dusk and in dim moonlight.",
    tech_nacht_2: "The classic night vision, also called brightness or residual light amplifier, uses the existing light left in the night and amplifies it into an image that is excellent to use. The night becomes almost as bright as the day.",
    tech_nacht_3: "To do this, the viewer uses moon and starlight, reflections from clouds of artificial light sources and even the natural infrared radiation present.",
    tech_nacht_4: "Because of the latter, it is also possible to obtain an image in complete darkness with the help of an Infrared radiator.",
    tech_nacht_5: "The heart of this technology is a small, analog, residual light amplifying tube that converts the few light particles via an ultra-sensitive layer into electrons that are multiplied under high voltage and made visible to our eyes via a phosphorescent screen. For the phosphor lighting, use is made of the well-known green and a black/white version, called Onyx. ",
    tech_nacht_6: "Since this is a very sensitive system it is important to use the binoculars only in the dark and not to expose the tube to light during the day.",
    tech_nacht_7: "Lahoux Optics has been working with the Dutch / French tube manufacturer Photonis for decades and only uses new tubes from this market leader so we can offer you high quality products exclusively.",
    tech_nacht_8: "",
    tech_nacht_button: "Go to night vision devices",
    tech_warm_1: "With thermal imaging, even animals that are behind, not too dense, bushes do not remain hidden. Even birds, such as crows, that sit close to the trunk or birds elsewhere in trees can be found this way.",
    tech_warm_2: "Not only in open fields but also in the forest a thermal image is an indispensable tool. From mice and small birds also squirrels, marten, foxes to the larger ungulates will not escape your attention. This allows you to detect heat or movement even at several hundred meters even in the forest. ",
    tech_warm_3: "An important point when using thermal imaging is the weather conditions. If there is little moisture in the air, performance is better than during a rainstorm. The operating distance is affected by this. The big advantage is that when a warm object is in the field of view it stands out clearly.",
    tech_warm_4: "It takes some time for the user to interpret the images properly since the image is purely composed of heat and many objects and animals look different than we are used to. This keeps the user fascinated to discover new things. ",
    tech_warm_5: "By using a small part of the infrared radiation around us, (uncooled) thermal imaging cameras can visualize precisely the temperature range of living things. This is done in a purely digital way so a lot can be done with image processing in the camera to make the radiation more visible to the user.",
    tech_warm_6: "In addition, the image can be digitally enlarged and often recorded. The uncooled thermal imaging camera has made the price and ease of use of this technique accessible to many target groups. The even more sensitive and complex cooled cameras remain, due to their high price, only available to governments and some professional users. ",
    tech_warm_7: "Most models have 4 different color modes to have a good image in almost all weather conditions.",
    tech_warm_button: "Go to thermal imagers",
    tech_digi_1: "In addition to classic night vision technology, Lahoux Optics also has digital residual light amplifiers.",
    tech_digi_2: "These are highly sensitive CCD/camera sensors that are comparable to digital camera sensors and are therefore also usable during the day. For a good view under the darkest conditions, an infrared emitter is needed, which Lahoux Optics also has in its assortment. ",
    tech_digi_3: "The big advantage is the lower cost so that this technique is very accessible to many.",
    tech_digi_button: "Go to digital-imagers",
    history_page_title: "History",
    history_copy_title: "History",
    history_copy_1: "Night vision technology has a long tradition in the Netherlands. It began as early as 1934 with the invention of the first image intensifier tube by Philips. Since the 1960s, the Netherlands has been one of the leading nations in the production of image intensifier tubes.  In 1970 PHOTONIS started the production of image intensifier tubes in Roden (Netherlands). Photonis has since become one of the largest manufacturers of image intensifier tubes in the world.",
    history_copy_2: "Lahoux Optics was founded in 1992. At that time the company supplied optical components to Dutch and Belgian night vision manufacturers. Later Lahoux Optics started with the distribution of PHOTONIS image intensifier tubes in some countries. An important step for Lahoux Optics was the development of its own nightvision and thermal devices. ",
    history_copy_3: "At present Lahoux Optics is one of the few companies in Western Europe that design, develop and produce their own high-end nightvision and thermal devices for the civilian market. Lahoux high-end night vision and thermal imagers are completely developed, manufactured and assembled in Holland. Lahoux devices are characterized by uncompromising quality.",
    history_copy_4: "In addition to our own production Lahoux Optics offers devices produced in factories we closely cooperate with. Lahoux Optics stands for fast and first class service.",
    work_page_title: "How we work",
    work_1_title: "Dust Free",
    work_1_copy: "The most important factor when mounting a high quality Lahoux night vision optic, such as the Lahoux LV-81 or the Lahoux Hemera, is an absolutely dust-free and clean laboratory atmosphere. Cleaning the lenses is a top priority. Even the smallest impurities are detected during quality control and the device is sorted out.",
    work_2_title: "Assembly",
    work_2_copy: "Our employees love Lahoux products. That's why they pay attention to maximum precision during assembly. A precision that you will then experience during the hunt. Minimal point of impact shift, maximum return accuracy.",
    work_3_title: "Quality Control",
    work_3_copy: "Every Lahoux unit goes through the strict quality control. Here, every function and dimensional accuracy is checked again. Only units that meet the standards 100 percent leave the production line. ",
    work_4_title: "High precision",
    work_4_copy: "We use high-precision collimation systems in quality control for the maximum precision and the the highest accuracy of our Lahoux attachements. You can have complete confidence in this when shooting. ",
    work_5_title: "ISO certificate",
    work_5_copy: "Our ISO 9001.2015 certificate (available on request) is our promise that we adhere to the highest standards of quality and service. All our business processes are validated against the most strict ISO standards for quality management. Every year, this certificate is renewed through an independent audit by SGS, the world’s leading certification body. Whether you are a supplier, customer or other relation, you can rely on our uncompromising product quality and customer service, both now and in the future.", 
    events_title: "Events",
    events_archive_title: "Archive",
    events_none: "There are currently no events scheduled.",
    events_button: "More information",
    service_title: "Servicebeleid",
    service_copy_1: "With normal use, your Lahoux devices will not require maintenance for many years. If you are an end user and you have a problem with your Lahoux devices, please contact your dealer.",
    service_copy_2: "Are you a dealer and do you have Lahoux devices that are not functioning properly? Please ",
    service_copy_button: "contact",
    service_copy_3: " the service department of Lahoux Optics.",
    service_choise_title: "Distributor or dealer in your country",
    service_choise: "Select your country",
    specs_description: "Description",
    specs_voordelen: "Advantages",
    specs_warning: "*Warning",
    specs_warning_p: "Please note the legal regulations in your respective federal state or country.",
    specs_leveromvang: "Comes standard with the following accessories:",
    specs_tech: "Technical specifications",
    specs_accessoires: "Available accessories for this product",
    specs_dri: "DRI values",
    specs_related: "Related products",
    specs_compatible: "Suitable for", 
    specs_new: "New",
    specs_footnote_battery: "Depending on the usage conditions",
    specs_artNum: "Article number",
    specs_color: "Night vision is available in green or black and white (Onyx).",
    specs_groen: "Green",
    specs_fom_voor: "The",
    specs_fom: "is available with various FOM values.",
    specs_spots_selection: "Select the spot category.",
    specs_spots_veel: "Allowed",
    specs_spots_weinig: "Few",
    specs_spots_geen: "Almost none",
    specs_price: "The price differs for each colour/FOM/spot category combination. Choose the combination that suits you best.",
    specs_download_flyer: "Download product flyer PDF",
    specs_disclaimer_spots: "Disclaimer: Any cosmetic defects or spots that may be present in the image intensifier tube do not affect the performance or reliability of the device. These occurring impairments are inherent to the manufacturing process. Category, B and A tubes have fewer spots than a Category C tube.",
    specs_order_button: "Order",
    vat_incl: "incl.",
    vat: "VAT",
    from: "From",
    for: "For",
    new: "New",
    find_dealer: "Find Dealer",
    products_page_title: "Products",
    product_detail: "Product details",
    product_no_longer_available: "This product is no longer available",
    no_products_found:"No products were found",
    all_products: "All products",
    thermal_imaging: "Thermal Imaging",
    nightvision: "Nightvision",
    digital: "Digital",
    front_attachment: "Front attachment",
    observation: "Observation",
    riflescope: "Riflescope",
    filter_hide: "Hide",
    filter_search_heading: "Find your device",
    filter_search_placeholder: "Search device",
    filter_sort_heading: "Sort",
    filter_sort_price_low_high: "Price low to high",
    filter_sort_price_high_low: "Price high to low",
    filter_sort_clear: "Clear sort",
    filter_options_heading: "Filter devices",
    contact_title: "Address",
    contact_tel: "Phone",
    contact_p: "You can only visit our office by invitation. We do not have a shop or showroom.",
    contact_mail: "Mail",
    contact_dealers: "Dealers only",
    dealers_page_title: "Dealers",
    glossary_page_title: "Glossary",
    glossary_seo_title: "Glossary of terms for thermal imaging and night vision devices",
    events_copy_1: "Meet Lahoux",
    events_copy_2: "Over the past decades Lahoux has specialised in night vision and thermal cameras. Lahoux Optics is present at most important exhibitions at home and abroad, giving you the opportunity to try our innovative night vision and thermal imaging cameras yourself. We gladly show you our innovative techniques and we will give you advice especially suited to your outdoor and  hunting situation. You are always welcome at our stand.",
    cookie_h1: "Lahoux Optics uses cookies",
    cookie_p: "Lahoux Optics uses functional and anonymized analytical cookies on this website. We currently do not set cookies from third-party websites and do not collect personal data from visitors. You can read more in our",
    cookie_consent: "Close",
    Page_not_found: "The page you were looking for no longer exists, continue to home.",
    Netherlands: "The Netherlands",
    Austria: "Austria",
    Belgium: "Belgium",
    Bulgaria: "Bulgaria",
    Cyprus: "Cyprus",
    Czech_republic: "Czech Republic",
    Denmark: "Denmark",
    Estonia: "Estonia",
    Finland: "Finland",
    France: "France",
    Germany: "Germany",
    Greece: "Greece",
    Hungary: "Hungary",
    Ireland: "Ireland",
    Italy: "Italy",
    Croatia: "Croatia",
    Latvia: "Latvia",
    Lithuania: "Lithuania",
    Luxembourg: "Luxembourg",
    Malta: "Malta",
    Poland: "Poland",
    Portugal: "Portugal",
    Romania: "Romania",
    Slovakia: "Slovakia",
    Sweden: "Sweden",
    United_kingdom: "United Kingdom",

    order_email: {
        subject_order: "Order",
        body_opening: "Dear customer,",
        body_copy_1: "If you like to order this item, please fill in your details below and send this email.",
        body_product_title: "Product: ",
        body_product_color: "Chosen color: ",
        body_product_FOM: "Chosen FOM value: ",
        body_product_spot: "Chosen spot category: ",
        body_details_heading: "Please provide your details:",
        body_details_name: "Name: ",
        body_details_address: "Shipping address: ",
        body_details_zip: "Zip code: ",
        body_details_city: "City: ",
        body_details_country: "Country: ",
        body_details_phone: "Phone number: ",
        body_details_email: "Email: ",
        body_thanks: "Thank you for your order!",
    },
    
    imprint: {
        page_heading: "Imprint",
        contact_company_name: "Lahoux Optics BV",
        contact_street: "Stephensonstraat 47",
        contact_zip_city: "2014 KC Haarlem",
        contact_email: "E-Mail: info@lahoux.nl",
        contact_tel: "Phone: + 31 23 5514678",
        contact_person: "", // only for Germany
        contact_gericht: "", // only for Germany
        contact_coc: "C.o.C. number: 301 24 219",
        contact_vat: "VAT number: NL 822 492 374 B01",
        dispute_heading: "Alternative Dispute Resolution",
        dispute_text: "The European Commission provides a platform for out-of-court online dispute resolution (OS platform), available at http://ec.europa.eu/odr.",
        picture_credit_heading: "Picture credits directory",
        picture_credit_p1: "Various pictures: license free at 123rf.com",
        picture_credit_p2: "Various pictures: Public domain CCO on pixabay.com",
        disclaimer_heading: "Disclaimer",
        liability_heading: "Limitation of liability",
        liability_p1: "The content created or maintained on this website by Lahoux Optics BV was created with the greatest possible care. Nevertheless, Lahoux Optics BV assumes no liability for their timeliness, completeness and accuracy (neither at the time of publication nor continuously thereafter). As a service provider, Lahoux Optics BV is responsible for its own content and provided information according to the general laws, however, not obliged to monitor the transmitted or stored third-party information. Removal or blocking of this content takes place immediately from the moment of knowledge of a specific infringement. Liability is only possible from the time the knowledge is acquired. Lahoux Optics BV points out that the transmission of data on the Internet (eg via e-mail) has security gaps and that complete protection of the data against third-party access can not be guaranteed; Accordingly, Lahoux Optics BV assumes no liability in this respect for such damages that a user incurs due to such security gaps.",
        linking_heading: "Linking",
        linking_p1: "This website may contain “external links” to other websites. Lahoux Optics BV makes every effort to verify the legality of the content when linking. At the time of linking no infringements were recognizable. Upon notification of such an infringement, the link will be removed immediately. However, Lahoux Optics BV has no influence on the content or changing content of the linked websites; the provider of the correspondingly linked website is solely responsible for this and Lahoux Optics BV therefore assumes no responsibility for the accuracy or legality of such content. For the contents and correctness of the provided information the respective offerer of the linked web page is responsible.",
        copyright_heading: "Copyright/ancillary rights",
        copyright_p1: "The contents, works and information published on this website are subject to Dutch copyright and ancillary copyright. Any kind of duplication, processing, distribution, storage and any kind of exploitation outside the limits of copyright requires the prior written consent of the respective copyright holder. The unauthorized copying / saving of the provided information on these web pages is not permitted and punishable. As far as the contents on this side were not created by the operator, the copyrights of third parties are considered. In particular contents of third parties are marked as such. Should a possible copyright infringement be detected, please contact Lahoux Optics BV.",
    },

    jubilee: {
        page_heading: "Lahoux Optics 30 years",
        page_hero_copy: "This year Lahoux Optics exists 30 years. A milestone that we as a company are proud of and want to reflect on.",
        intro_article_p1: "This year Lahoux Optics exists 30 years.",
        intro_article_p2: "A milestone that we as a company are proud of and want to reflect on.",
        intro_article_p3: "Lahoux Optics delivers since 30 years high quality technical night vision devices.",
        intro_article_p4: "Our company was founded in 1992 in Haarlem where our headquarters is located. In the beginning we only focused on nightvision equipment, which we always assemble with the well-known Dutch Photonis image intensifier tubes. The technique was quite new in those days.",
        intro_article_p5: "Over the years thermal imaging technology emerged and Lahoux Optics also implemented this technology in its scopes.",
        intro_article_p6: "We design devices for hunting and outdoor applications based on both technologies, including observation devices, clip-on’s, riflescopes and binoculars.",
        intro_article_p7: "As the company grew, we moved to a larger production location within Haarlem in 2012. We are now located in a place where R&D, product testing, production, service and sales can work closely together. The location also functions as a repair centre for all European sales.",
        intro_article_p8: "Since 2018 Lahoux Optics has a subsidiary in Berlin to better serve the for Lahoux important German market with the help of our local German colleagues.",
        intro_article_p9: "In 2021 Lahoux has acquired the ISO 9001 quality mark, which is a further guarantee for the customers and partners of Lahoux Optics for the implemented quality management.",
        intro_article_p10: "Lahoux Optics strives to make products of the highest quality and pays the utmost care to every product it manufactures.",
        intro_article_p11: "Because of our 30-year anniversary we offer our customers several gifts this year in the form of a number of discount actions. Please check our website, social media or our dealers regularly for the various actions. We wish you a long lasting use of your purchased Lahoux product!",
        offer: "Learn more about our special anniversary offer for you!",
        offer_button: "Anniversary offer!",
    },

    cookie_policy: {
        page_heading: "Cookie Policy",
        page_hero_copy: "",
        intro_p1: "Lahoux Optics uses functional and analytical cookies and local storage on this website.",
        intro_p2: "A cookie is a small text file that is stored in the browser of your computer, tablet or smartphone when you visit this website for the first time. Lahoux Optics uses cookies with a purely technical functionality. They ensure that the website works properly and that, for example, your preferences are remembered. These cookies are also used to make the website work properly and to optimize it.",
        intro_p3: "In addition, we place analytical cookies. Analytical cookies collect data and statistics (often anonymously) to understand how visitors use the website, so we can improve the website and our services.",
        uitschakelen_heading: "Enabling and disabling cookies and deleting them",
        uitschakelen_p1: "You can opt out of cookies by setting your Internet browser to no longer store cookies. In addition, you can also delete all information previously stored via your browser settings.",
        uitschakelen_p2: "More information about enabling, disabling and deleting cookies can be found in the instructions of your browser and/or by using the Help function of your browser.",
        analytics_heading: "Google Analytics",
        analytics_p1: "Through our website, a cookie is placed by the American company Google, as part of the 'Analytics' service. We use this service to track and get reports about how visitors use the website. We use this information to make decisions about the design of our website. We also use this information to optimize the functioning of the website. We have taken the following measures to ensure careful processing:",
        analytics_listitem1: "We have entered into a processor agreement with Google;",
        analytics_listitem2: "The information that Google collects is anonymized as much as possible. Your IP address is explicitly not provided;",
        analytics_listitem3: "We have turned off all options offered by Google to 'share data with Google';",
        analytics_listitem4: "We do not use other Google services in conjunction with the Google Analytics cookies.",
        analytics_p2: "Google may provide this information to third parties if Google is legally required to do so, or to the extent that third parties process the information on Google's behalf. We have no influence on this. We have not allowed Google to use the obtained analytics information for other Google services.",
        analytics_p3: "The information that Google collects is anonymized as much as possible. Your IP address is explicitly not provided.",
        local_storage_heading: "Local Storage",
        local_storage_p1: "Local strorage is a form of local data storage, and is intended to make the website work properly. We only use local storage to remember your chosen language of the website in your browser. We also use Local storage to remember that you have closed the cookie banner so that it will not be displayed on your next visit.",
        session_storage_heading: "Session storage",
        session_storage_p1: "Session storage is a form of temporary storage of data, and is intended to make the website work properly. We only use session storage to store information about the chosen language of the website in your browser. The session storage is deleted after you close your browser.",
    },

    message_yearend_closing: {
        heading: "Year-end closing",
        p1: "Lahoux Optics will be closed from Saturday, December 24, 2022 to Sunday, January 1, 2023.",
        p2: "We will be open again from Monday, January 2, 2023.",
    },
    
    jagd_und_hund: {
        page_heading: "Jagd & Hund Dortmund Germany 2023",
        page_hero_copy: "Europe's largest hunting exhibition. Lahoux Optics will be present at Jagd & Hund with a stand in Hal 6 booth number H20.",
        article_link_jagd_hund: "Jagd & Hund",
        article_p1: "is Europe's largest hunting exhibition and will be held from Jan. 24 to Jan. 29, 2023, at the exhibition centre in Dortmund.",
        article_p2: "Lahoux Optics will be present at Jagd & Hund with a stand in Hal 6 booth number H20.",
        article_p3: "We have great promotions during this exhibition. You will soon find more information about these on this page.",
        article_p4: "We have great promotions during this exhibition, you can find them here:",
        home_title: "Jagd & Hund Dortmund Germany 2023",
        home_copy: "Jagd & Hund is Europe's largest hunting exhibition and will be held from Jan. 24 to Jan. 29, 2023, at the exhibition centre in Dortmund. Lahoux Optics will be present at Jagd & Hund with a stand in Hal 6 booth number H20. We have great promotions during this exhibition!",
        home_button: "Check out the promotions",
   },

   hohe_jagd_und_fischerei: {
        page_heading: "Die Hohe Jagd & Fischerei Salzburg 2023",
        page_hero_copy: "Lahoux Optics will be present at Die Hohe Jagd & Fischerei with a stand in Hal 10 booth number 0722.",
        article_link_jagd_hund: "Die Hohe Jagd und Fischerei",
        article_p1: "will be held from Feb. 16 to Feb. 19, 2023, at the exhibition centre in Salzburg.",
        article_p2: "Lahoux Optics will be present at Die Hohe Jagd und Fischerei with a stand in Hal 10 booth number 0722.",
        article_p3: "We have great promotions during this exhibition. You will soon find more information about these on this page.",
        article_p4: "We have great promotions during this exhibition, you can find them here:",
        home_title: "Die Hohe Jagd & Fischerei Salzburg 2023",
        home_copy: "Die Hohe Jagd & Fischerei will be held from Feb. 16 to Feb. 19, 2023, at the exhibition centre in Salzburg. Lahoux Optics will be present at Die Hohe Jagd und Fischerei with a stand in Hal 10 booth number 0722. We have great promotions during this exhibition!",
        home_button: "Check out the promotions",
}

   };