import React from "react";
import styled from "styled-components";
import Seo from "./Seo";
import DataDealers from "./DataDealers";
import { useTranslation } from "react-i18next";
import DealerItem from "./DealerItem";

function Dealers({ clientCountry }) {
  const { t, i18n } = useTranslation();

  let dealers = DataDealers.filter((dealer) =>
    dealer.country.includes(clientCountry)
  );
  if (dealers.length === 0) {
    dealers = DataDealers.filter((dealer) =>
      dealer.country.includes("Netherlands")
    );
  }

  // make description meta tag in Helmet get translatable content
  const helmetDescription = {
    en: "Distributor or dealer in your country",
    nl: "Distributeur of dealer in uw land",
    de: "Distributor oder Händler in Ihrem Land",
  };

  return (
    <StyledDealers>
      <Seo 
        title={t("dealers_page_title")} 
        description={helmetDescription[i18n.language]} 
        url="https://lahouxoptics.com/dealers" 
      />

      <span>
        <h1>{t("service_choise_title")}</h1>
      </span>

      <div className="dealers">
        <ul>
          {dealers.map((dealer) => (
            <DealerItem key={dealer.id} dealer={dealer} />
          ))}
        </ul>
      </div>
    </StyledDealers>
  );
}
const StyledDealers = styled.div`
  max-width: 100vw;
  margin: 60px auto 10px;

  @media (min-width: 768px) {
    max-width: 60vw;
  }

  h1 {
    font-size: 23px;
    font-weight: bold;
    color: #fff;
    border-bottom: 2px solid var(--brand-accent-color);
    display: inline-block;
    padding-bottom: 6px;
    margin-bottom: 20px;
  }

  span {
    display: block;
    margin-bottom: 40px;
  }

  .dealers {
    margin-bottom: 40px;
  }

  .dealers ul {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
`;
export default Dealers;
