import React from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import "../locales/i18n";
import DataProducts from "./DataProducts"
import {
    Link
  } from "react-router-dom";

const LinkProduct = ({id}) => {
    const { t, i18n } = useTranslation();
    const data = DataProducts.find(item => item.id === id)
  return (                   
                        
    <StylesLinkProduct>          
                        <Link to={`/producten/${data.url}`} key={data.id} className="product-card">
                                {/*<p>{item}</p>*/}
                                    <img src={"/productafbeeldingen/"+data.images[0]} alt={data.title[i18n.language]} />
                                    <p>{data.title[i18n.language]}</p>
                                       
                            </Link>
    </StylesLinkProduct>


  ) }
  const StylesLinkProduct = styled.div`
.product-card:link{
    border: 1px solid var(--brand-accent-color);
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0;
    padding: 0;
}

.product-card:hover{
  background-color: var(--brand-accent-color);
}
.product-card img{
    margin: 0 auto;
    max-width: 105%;
    padding: 15px 10px 0 0px;
}
.product-card p{
    color: white;
    padding: 10px;
   
}
.product-card:hover p{
  color: white;
}

`;

export default  LinkProduct;


