import React from 'react';
import styled from 'styled-components';



const Privacy = ({ showItems, clickHandlerShowItem }) => {
        
  return (
      <Styledoverview>
          <div className="glossary">
          <p>Hier komt veel tekst over privacy.</p>
          </div>
      </Styledoverview>
  )
}

const Styledoverview = styled.div`
margin: 120px auto 0 auto;
    max-width: 90vw;
    font-size: 20px;
    @media (min-width: 768px) {
        max-width: 70vw;
      }

      @media (min-width: 1024px) {
        max-width: 50vw;
      }
    
    .glossary{
        max-width: 100vw;
        margin: 0 auto;
        @media (min-width: 768px) {
            max-width: 70vw;
          }
    
          @media (min-width: 1024px) {
            max-width: 50vw;
          }
    }
   
    

   

`

export default Privacy