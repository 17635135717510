import React from 'react';
import styled from 'styled-components';
import Seo from "./Seo";
import hert from "./../assets/uitsnede-hert.jpg";
import { useTranslation } from "react-i18next";
import "../locales/i18n";

const History = () => {
  const { t, i18n } = useTranslation();

  // make description meta tag in Helmet get translatable content
  const helmetDescription = {
    en: "Lahoux Optics was founded in 1992 and is one of the few companies in Western Europe that design, develop and produce their own high-end nightvision and thermal devices for the civilian market. Lahoux devices are characterized by uncompromising quality.",
    nl: "Lahoux Optics is opgericht in 1992 en is een van de weinige bedrijven in West-Europa die hun eigen high-end nachtkijkers en thermische apparaten ontwerpen, ontwikkelen en produceren voor de civiele markt. Lahoux apparaten worden gekenmerkt door compromisloze kwaliteit.",
    de: "Lahoux Optics wurde 1992 gegründet und ist eine der wenigen Firmen in Westeuropa, die ihre eigenen hochwertigen Nachtsicht- und Wärmebildgeräte für den zivilen Markt entwerfen, entwickeln und produzieren. Lahoux-Geräte zeichnen sich durch kompromisslose Qualität aus.",
  };

  return (
    <StyledHome>
      <Seo
        title={t("history_page_title")}
        description={helmetDescription[i18n.language]}
        url="https://lahouxoptics.com/geschiedenis"
        image={hert}
      />

      <article>
        <div className="article-copy">
          <h1>{t("history_copy_title")}</h1>
          <p>{t("history_copy_1")}</p>
          <p>{t("history_copy_2")}</p>
          <p>{t("history_copy_3")}</p>
          <p>{t("history_copy_4")}</p>
        </div>
        <div>
          <img src={hert} />
        </div>
      </article>
    </StyledHome>
  );
}

const StyledHome = styled.div`
article{
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    margin: 60px auto 120px auto;
    align-items: top;
    @media (min-width: 768px) {
      flex-wrap: wrap;
      width: 60vw;
    }

    @media (min-width: 1400px) {
      flex-wrap: nowrap;
    }
      
  }

  img{   
    width: 100%;
    height: auto;
    display: block;

  
  }
  article div{  
    display: block;
    overflow: hidden;
  }
  .article-copy{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 20px 20px 0 20px;

    @media (min-width: 1400px) {
      margin-top: 0px;
    }
  }
  article div p{
    font-size: 1.25rem;
    line-height: 1.1;
    margin-bottom: 20px;
  }

  article h1{
    font-size: 1.4375rem;
    font-weight: bold;
    border-bottom: 2px solid var(--brand-accent-color);
    padding-bottom: 6px;
    margin-bottom: 15px;
    display: inline;
  }
  article a{
      border: solid 1px #707070;
      border-radius: 5px;
      padding: 10px;
      font-size: 1.2em;
      text-decoration: none;
      color: #393939;
  }
  article a:visited, article a:active, article a:hover {
    color:#393939 ;
  }
`;
export default History