import React from 'react';
import styled from 'styled-components';
import {Helmet} from "react-helmet"
import DataVacatures from "./DataVacatures";

import {
     Link
} from "react-router-dom";

const VacaturesAll = () => {

  return (
    <StyledVacanciesAll>
        <Helmet>
            <title>Vacancies</title>
            <meta name="description" content="Vacancies at Lahoux Optics" />
      </Helmet>

        <span>
        <h1>Alle vacatures </h1>
        </span>
    <div className="cards">
        {DataVacatures.length > 0 ?
       <ul>
        
           {DataVacatures
                    .map(vacature => (
                        <Link to={`/vacatures/${vacature.url}`} key={vacature.id}>
                            <li className="content">
                                <div className="copy">         
                                    <div className="vacature-card">
                                        <h2>{vacature.title}</h2>
                                        <p>{vacature.city}, {vacature.hours} </p>
                                    </div>

                                    <p>{vacature.intro}</p>
                                </div>
                                

                            </li>
                        </Link>
                    ))}
                    <li className="content questions">
                                <div className="copy">
                                    <div className="vacature-card">
                                        <h2>Vragen?</h2>
                                    </div>
                                    <p>Neem contact op met  Clémentine Smit.</p>
                                    <p>Tel: <a href="tel:0031235514678">+31 (0) 23 5514 678</a></p>
                                    <p>of</p>
                                    <p>Mail: <a href="mailto:solliciteren@lahoux.nl">solliciteren@lahoux.nl</a></p>
                                    <p>Acquisitie wordt niet op prijs gesteld.</p>
                                    
                                </div>
                                

                    </li>           
            
       </ul>        
        
    :
        <p className="no-vacancy">Momenteel zijn er geen vacatures</p>
    } 

    </div>
    </StyledVacanciesAll>
  )
}
const StyledVacanciesAll = styled.div`
    max-width: 95vw;
    margin: 20px auto 0 auto;
    font-size: 20px;
    @media (min-width: 768px) {
        max-width: 90vw;
        margin: 120px auto 0 auto;
      }
    
      @media (min-width: 1024px) {
        max-width: 65vw;
        margin: 120px auto 0 auto;
      }
    
    h1 {
        font-size: 23px;
        font-weight: bold;
        border-bottom: 4px solid #FC751B;
        display: inline-block;
        padding-bottom: 6px;
        margin-bottom: 20px;
        
    }

    .cards ul li{
        width: 100%;
        min-height: 350px;
        padding: 20px;
        display: block;
        @media (min-width: 768px) {
            width: 100%;
          }
        
          @media (min-width: 1024px) {
            width: 80%;
          }
          @media (min-width: 1200px) {
            width: 60%;
          }
    }
    .cards ul a{
        text-decoration: none;
    }
    .cards ul .content{
        border: 1px solid #FC751B;
        border-radius: 10px;
        margin-bottom: 20px;
        text-decoration: none;
        color: white;
    }
    .cards ul a:active, .cards ul a:visited{
        color: white;
    }

    .cards ul li{
        display: flex;
     }

    .cards ul li:hover{
      background-color: #FC751B;  
    }
    .cards ul .questions:hover{
        background-color: #323522;
    }
    .cards ul .questions a{
        text-decoration: none;
        color: white;
        font-weight: bold;
    }
    .cards ul li div{
        margin-top: 10px;
    }
    .cards h2{
        font-size: 1.2rem;
        font-weight: bold;
        border-bottom: 3px solid #FC751B;
        margin-bottom: 8px;
        

    }
    .vacature-card p{
        font-size: 15px;
    }
    .vacature-card {
        margin-bottom: 20px;
    }
    .vacature-card ~ p {
        margin-bottom: 5px;
    }

    .no-vacancy {
        margin-bottom: 120px;
        font-weight: bold;
    }
    
    `
export default VacaturesAll