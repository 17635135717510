import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import lahouxIcoon from "./../assets/icoon-lahoux-404.png";

const PageNotFound = () => {
  const { t, i18n } = useTranslation();

  const altIcon = {
    en: "Lahoux Optics icon",
    nl: "Lahoux Optics icoon",
    de: "Lahoux Optik Symbol",
  };

  return (
    <StyledEvents>
      <div className="hero">
        <div className="heroCopy">
          <h1>404</h1>
          <p>{t("Page_not_found")}</p>
          <Link to="/">{t("menu_home")}</Link>
        </div>
        <img className="icon" src={lahouxIcoon} alt={altIcon[i18n.language]} loading="lazy" />
      </div>
    </StyledEvents>
  );
};
const StyledEvents = styled.div`
  .hero {
    max-width: 100vw;
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    align-items: center;
  }

  h1 {
    padding-bottom: 5px;
    max-width: 400px;
    color: white;
    font-size: 2.5rem;
    font-weight: bold;
    border-bottom: 6px solid var(--brand-accent-color);
    margin-bottom: 10px;
  }
  p {
    font-size: 1.5625rem;
    margin-bottom: 30px;
  }
  .heroCopy {
    z-index: 10;
    padding: 10px;

    @media (min-width: 768px) {
      width: 50%;
    }

    @media (min-width: 1024px) {
      width: 30%;
    }
  }
  .heroCopy a {
    color: var(--link);
    font-weight: bold;
    font-size: 1.875rem;
    text-decoration: none;
    border: 1px solid #fff;
    padding: 8px;
    border-radius: 10px;
  }
  .heroCopy a:hover {
    background-color: var(--brand-accent-color);
    border: 1px solid var(--brand-accent-color);
  }

  .icon {
    width: 250px;
  }
`;

export default PageNotFound;
