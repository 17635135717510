import React from 'react';
import styled from 'styled-components';
import Seo from "./Seo";
import production1 from "./../assets/howwework/production1.jpg";
import production2 from "./../assets/howwework/production2.jpg";
import production3 from "./../assets/howwework/production3.jpg";
import production4 from "./../assets/howwework/production4.jpg";
import production5 from "./../assets/howwework/iso.jpg";
import { useTranslation } from "react-i18next";
import "../locales/i18n";

const HowWeWork = () => {
  const { t, i18n } = useTranslation();

  // 10-05-2022 PP Add translatable alt-text for images
  const imgAltTextProd1 = {en: "Dust-free assembly of a Lahoux optic", nl: "Stofvrije montage van een Lahoux optiek", de: "Staubfreie Montage einer Lahoux-Optik"}
  const imgAltTextProd2 = {en: "Maximum precision during assembly", nl: "Maximale precisie bij de montage", de: "Höchste Präzision bei der Montage"}
  const imgAltTextProd3 = {en: "Strict quality control", nl: "Strenge kwaliteitscontrole", de: "Strenge Qualitätskontrolle"}
  const imgAltTextProd4 = {en: "High-precision collimation systems in quality control", nl: "Hoge-precisie collimatie systemen bij de kwaliteitscontrole", de: "Hochpräzise Kollimationssysteme in der Qualitätskontrolle"}
  const imgAltTextProd5 = {en: "Lahoux Optics is an ISO9001:2015 certified company", nl: "Lahoux Optics is een ISO9001:2015 gecertificeerde onderneming", de: "Lahoux Optics ist ein nach ISO9001:2015 zertifiziertes Unternehmen"}

  // make description meta tag in Helmet get translatable content
  const helmetDescription = {
    en: "How we work at Lahoux Optics",
    nl: "Hoe we werken bij Lahoux Optics",
    de: "Wie wir bei Lahoux Optics arbeiten",
  };

  return (
      <StyledHome>
        <Seo
          title={t("work_page_title")}
          description={helmetDescription[i18n.language]}
          url="https://lahouxoptics.com/hoe-wij-werken"
          image={production1}
        />

        <article>
          <div className="article-copy">
            <h1>{t("work_1_title")}</h1>
            <p>{t("work_1_copy")}</p>
          </div>
          <div className="article-img">
            <img src={production1} alt={imgAltTextProd1[i18n.language]} loading="lazy" />
          </div>
        </article>
        <article>
          <div className="article-copy">
            <h1>{t("work_2_title")}</h1>
            <p>{t("work_2_copy")}</p>
          </div>
          <div className="article-img">
            <img src={production2} alt={imgAltTextProd2[i18n.language]} loading="lazy" />
          </div>
        </article>
        <article>
          <div className="article-copy">
            <h1>{t("work_3_title")}</h1>
            <p>{t("work_3_copy")}</p>
          </div>
          <div className="article-img">
            <img src={production3} alt={imgAltTextProd3[i18n.language]} loading="lazy" />
          </div>
        </article>
        <article>
          <div className="article-copy">
            <h1>{t("work_4_title")}</h1>
            <p>{t("work_4_copy")}</p>
          </div>
          <div className="article-img">
            <img src={production4} alt={imgAltTextProd4[i18n.language]} loading="lazy" />
          </div>
        </article>
        <article>
          <div className="article-copy">
            <h1>{t("work_5_title")}</h1>
            <p>{t("work_5_copy")}</p>
          </div>
          <div className="article-img logoIso">
            <img src={production5} alt={imgAltTextProd5[i18n.language]} loading="lazy" />
          </div>
        </article>
      </StyledHome>

  );
}

const StyledHome = styled.div`
article{
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    margin: 120px auto 120px auto;
    align-items: top;
    @media (min-width: 768px) {
      flex-wrap: wrap;
      width: 60vw;
    }

    @media (min-width: 1400px) {
      flex-wrap: nowrap;
    }
      
  }

  img{   
    width: 100%;
    height: auto;
    display: block;

  
  }
  article div{  
    display: block;
    overflow: hidden;
  }

  .article-copy{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 20px 20px 0 20px;

    @media (min-width: 1400px) {
      margin-top: 0px;
      width: 60%;
    }
  }
  
  .article-img, .logoIso{
    background-color: #fff;

    @media (min-width: 1400px) {
      width: 60%;
    }
  }
  
  article div p{
    font-size: 1.25rem;
    line-height: 1.1;
    margin-bottom: 20px;
  }

  article h1{
    font-size: 1.4375rem;
    font-weight: bold;
    border-bottom: 2px solid var(--brand-accent-color);
    padding-bottom: 6px;
    margin-bottom: 15px;
    display: inline;
    
  
  }
  .logoIso img{
    margin: 0 auto;
    padding: 15px; 
    width: 60%;
  }
 
`;
export default HowWeWork