import React from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import "../locales/i18n";

const SelectShow = ({ showItems, clickHandlerShowItem }) => {
    const { t } = useTranslation();
    const path = window.location.pathname;

  return (
    
      <StyledSelectShow >
            <div className={`buttons ${path === "/producten" ? "products" : ""}`}>
              {(path !== "/evenementen" && path === "/producten") && 
                <button className={showItems === "all" ? "active" : "inactive"} onClick={event => {
                  clickHandlerShowItem("all");
                  }}>{t("all_products")} 
              </button>
              }

              {path !== "/evenementen" &&
                <>
                  <button className={showItems === "thermal" ? "active" : "inactive"} onClick={event => {
                      clickHandlerShowItem("thermal");
                      }}>{t("menu_producten_warm")} 
                  </button>
                  <button className={showItems === "night" ? "active" : "inactive"} onClick={event => {
                      clickHandlerShowItem("night");
                      }}>{t("menu_producten_nacht")}
                  </button>
                </>
              }

              {(path === "/producten" || path === "/techniek") &&
                  <button className={showItems === "digital" ? "active" : "inactive"} onClick={event => {
                      clickHandlerShowItem("digital");
                  }}>{t("menu_producten_digi")}
                  </button>
              }
              {path === "/producten" &&
                  <button className={showItems === "accessories" ? "active" : "inactive"} onClick={event => {
                      clickHandlerShowItem("accessories");
                      }}>{t("menu_producten_acces")}
                  </button>    
              }

              {path === "/evenementen" &&
                <>
                    <button className={showItems === "events" ? "active" : "inactive"} onClick={event => {
                        clickHandlerShowItem("events");
                        }}>{t("menu_events")}
                    </button> 
                    <button className={showItems === "archive" ? "active" : "inactive"} onClick={event => {
                      clickHandlerShowItem("archive");
                      }}>{t("menu_events_archive")}
                  </button>   
                </>
              }

            </div>
      </StyledSelectShow>
      
    
  )
}
const StyledSelectShow = styled.div`
button{
    padding: 10px;
    color: var(--text);
    background-color: var(--brand-color-dark);
    border: #fff 1px solid;
    border-radius: 10px;
    min-width: 40vw;
    margin: 10px 10px 10px 10px;
    font-size: 16px;
    @media (min-width: 768px) {
        margin: -60px 0px 0px 0px;
        font-size: 25px;
        min-width: 200px;
        border-radius: 0;
      }
}
button:hover {
  background-color: var(--brand-accent-color);
}

button:focus{
    outline: 0;
}

.products button {
  @media (min-width: 768px) {
    font-size: 20px;
    min-width: 130px;
  }

  @media (min-width: 920px) {
    font-size: 22px;
    min-width: 140px;
  }

  @media (min-width: 1024px) {
    font-size: 25px;
    min-width: 200px;
  }
}

.active{
    /* text-decoration: underline rgb(252, 117, 27); */
    background-color: var(--brand-accent-color);

    @media (min-width: 768px) {
        border-bottom: none;
      }
}

.inactive {
    
    @media (min-width: 768px) {
        border: none;
        border-bottom: 1px solid #fff;
      }   
}

.buttons{
    margin: 30px auto 0px auto;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    @media (min-width: 768px) {
        margin: 100px auto 0px auto;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
      }
} 
`;
export default SelectShow