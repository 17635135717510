import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { faStore } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "../locales/i18n";

const EventItem = ({ event }) => {
  const { t } = useTranslation();

  return (
    <a href={event.url} key={event.id} target={event.urlTargetSelf ? "_self" : "_blank"} rel="noopener noreferrer">
      <li>
        <div className="eventImg">
          <img src={"/evenement/" + event.logo} alt="event logo" />
        </div>
        <div className="eventText">
          <div>
            <h2>{event.title}</h2>
            <p>{event.desc}</p>
          </div>
          <div className="eventInfo">
            <div>
              {event.adress && (
                <p>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                  {event.adress}
                </p>
              )}
              {event.price && (
                <p>
                  <FontAwesomeIcon icon={faEuroSign} />{" "}
                  {event.price}
                </p>
              )}
              {event.datum && (
                <p>
                  <FontAwesomeIcon icon={faCalendarAlt} />{" "}
                  {event.datum}
                </p>
              )}
              {event.stand && (
                <p>
                  <FontAwesomeIcon icon={faStore} />
                  {event.stand}
                </p>
              )}
            </div>
            <p className="button">{t("events_button")}</p>
          </div>
        </div>
      </li>
    </a>
  );
};

export default EventItem;
