import React from "react";
import styled from "styled-components";
import productsField from "./../assets/Lahoux-products-field.jpg";
import { useTranslation } from "react-i18next";
import "../locales/i18n";
import { Link } from "react-router-dom";
import VideoElement from "./VideoElement";
import zwijnen from "./../assets/zwijnen.mp4";


const HomeArticle = ({checkEndDate}) => {
  const { t, i18n } = useTranslation();

  // Add translatable alt-text for image
  const imgAltText = {
    en: "Use of Lahoux products in practice",
    nl: "Gebruik van Lahoux producten in de praktijk",
    de: "Anwendung der Lahoux-Produkte in der Praxis",
  };

  return (

      <StyledHome>
        <article>
          <div>
            <img
              src={productsField}
              alt={imgAltText[i18n.language]}
              loading="lazy"
            />
          </div>
          <div className="article-copy">
            <h1>{t("home_article_title")}</h1>
            <p>{t("home_article_copy")} </p>
            <Link to="/producten">{t("home_article_button")}</Link>
          </div>
        </article>

      {checkEndDate("2023-02-19") ? ( 
        <article>
            <div className="article-copy">
              <h1>{t("home_title")}</h1>
              <p>{t("home_copy")}</p>
              <Link to="/techniek"> {t("home_button")}</Link>
            </div>
            <div>
              <VideoElement video={zwijnen} videoRoundClass={"video-round"} />
            </div>
          </article>
        ) : null}

      </StyledHome>

  );
};
const StyledHome = styled.div`
  article {
    display: flex;
    flex-wrap: wrap;
    margin: 120px auto 120px auto;
    align-items: top;
    @media (min-width: 768px) {
      flex-wrap: wrap;
      width: 70vw;
    }

    @media (min-width: 1400px) {
      flex-wrap: nowrap;
    }
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  img.rozet-img {
    width: 300px;
    /* transform: rotate(12deg); */

    @media (min-width: 1024px) {
      width: 350px;
    }
  }

  .article-copy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 20px 0 20px;

    @media (min-width: 768px) {
      margin-top: 20px;
    }

    @media (min-width: 1400px) {
      width: 50%;
      margin-top: 0px;
    }
  }
  .article-copy p {
    font-size: 1.25rem;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  article h1 {
    font-size: 1.4375rem;
    font-weight: bold;
    border-bottom: 3px solid var(--brand-accent-color);
    padding-bottom: 6px;
    margin-bottom: 15px;
    display: inline;
  }
  article a {
    border: solid 1px #fff;
    border-radius: 5px;
    padding: 10px;
    font-size: 1.2em;
    text-decoration: none;
    color: var(--link);
  }

  article a:hover {
    background-color: var(--brand-accent-color);
    border: solid 1px var(--brand-accent-color);
  }

  .video-round {
    width: 300px;
    height: 300px;
    z-index: 1;
    margin-top: 32px;

    @media (min-width: 1024px) {
      width: 350px;
      height: 350px;
      margin-top: 0;
    }
  }

`;
export default HomeArticle;
