export const TRANSLATIONS_NL = {
    lang_de: "DE",
    lang_nl: "NL",
    lang_en: "EN",
    menu_home: "Home",
    menu_producten: "Producten",
    menu_producten_nacht: "Nachtzicht",
    menu_producten_warm: "Warmtebeeld",
    menu_producten_acces: "Accessoires",
    menu_producten_digi: "Digitaal",
    menu_producten_overig: "Meer producten bekijken",
    menu_techniek: "Techniek",
    menu_over: "Over ons",
    menu_over_history: "Geschiedenis",
    menu_over_jubileum: "30 jaar aanbieding",
    menu_over_how: "Hoe wij werken",
    menu_over_vacatures: "Vacatures",
    menu_over_events: "Evenementen",
    menu_events: "Evenementen",
    menu_events_archive: "Archief",
    menu_service: "Service",
    menu_dealers: "Dealers",
    menu_woorden: "Woordenlijst",
    menu_cookieverklaring: "Cookieverklaring",
    menu_privacy: "Privacybeleid",
    between:"Innovatie in nachtzicht",
    betweenbutton: "Kies uw bestemming",
    betweenoption1: "Jacht",
    betweenoption2: "Birding",
    betweenoption3: "Overheid",
    betweenoption4: "Dealerportal",
    betweenoption5: "Sluiten", 
    footer_volg: "Volg ons op",
    footer_rights: "Alle rechten voorbehouden",
    footer_price: "Alle prijzen zijn adviesverkoopprijzen incl. BTW",
    footer_disclaimer: "Alle informatie op deze website kan zonder voorafgaande aankondiging worden gewijzigd. Lahoux-producten worden continu verbeterd om de best mogelijke gebruikerservaring te garanderen.",
    home_title: "Geef uw ogen buitengewoon vermogen",
    home_copy: "Met de Lahoux Spotter warmtebeeldkijker gaat er een nieuwe wereld voor u open. Nog nooit was het zó makkelijk om in het donker, of tijdens moeilijke zicht overdag, overzicht te krijgen van de wereld om ons heen. Met behulp van warmtebeeld zijn alle warmtebronnen, dieren, mensen en objecten, eenvoudig te vinden, zowel overdag als ’s nachts. ",
    home_button: "Lees meer",
    home_article_title: "Scherp zicht, ook in de duisternis",
    home_article_copy: "Met de producten van Lahoux Optics wordt het vinden van dieren in het donker mogelijk en kunt u alle nachtelijke activiteiten vastleggen. Zo kunt u een warmtebeeldkijker gebruiken om wild in het veld op te sporen of tellingen te doen van nachtdieren. Met het gedetailleerde beeld van de warmtebeeldkijkers van Lahoux Optics worden zelfs kleine kenmerken zichtbaar en zijn veel dieren zelfs aan te spreken. ",
    home_article_button: "Ontdek onze modellen",
    home_article2_title: "Lahoux Optics 30 jaar",
    home_article2_copy: "Lahoux Optics bestaat dit jaar 30 jaar. Een mijlpaal waar we als bedrijf trots op zijn en stil bij willen staan. Vanwege onze 30-jarige jubileum bieden wij onze klanten dit jaar een aantal kortingsacties.",
    home_article2_button: "Bekijk onze jubileum aanbiedingen",
    tech_intro_title: "Lahoux Optics",
    tech_intro_copy: "Warmtebeeldkijkers zijn onmisbaar gebleken voor diegene die het nut van deze techniek hebben ervaren. Zowel overdag als ‘s nachts geven de kijkers een extra dimensie aan het kijken naar de natuur. Zodra er ergens een temperatuurverschil meetbaar is zal de camera dit zichtbaar maken.  Het is dan ook uitsluitend warmtestraling die de camera detecteert. Met restlichtversterkers en digitale kijkers wordt juist het nog aanwezige (zichtbare) licht in de nacht versterkt tot een beeld dat uitstekend bruikbaar is en de nacht bijna tot dag veranderd. Het beeld ligt daardoor veel dichterbij wat we gewend zijn. ",
    tech_page_title: "Techniek",
    tech_nacht_1: "Het haarscherpe beeld van onze nachtzicht kijkers stelt u in staat na de schemering en bij zwak maanlicht de meeste dieren en wild te observeren en aan te spreken.",
    tech_nacht_2: "De klassieke nachtzicht, ook genaamd helderheid- of restlichtversterker, gebruikt het bestaande licht dat nog aanwezig is in de nacht en versterkt dat tot een beeld dat uitstekend is te gebruiken. De nacht wordt bijna zo licht als de dag.",
    tech_nacht_3: "Daarvoor gebruikt de kijker maan- en sterrenlicht, reflecties door wolken van kunstmatige lichtbronnen en zelfs de aanwezige natuurlijke infraroodstraling.",
    tech_nacht_4: "Door dat laatste is het ook mogelijk om bij volledige duisternis met behulp van een Infraroodstraler toch beeld te kunnen krijgen.",
    tech_nacht_5: "Het hart van deze technologie is een kleine, analoge, restlichtversterkende buis die de weinige lichtdeeltjes via een ultragevoelig laag omzet in elektronen die onder hoge spanning verveelvoudigt worden en via een, door fosfor, oplichtend scherm voor ons oog zichtbaar gemaakt worden.",
    tech_nacht_6: "Voor het oplichtende fosfor wordt er gebruikt gemaakt van het bekende groen en een zwart/wit uitvoering, genaamd Onyx.",
    tech_nacht_7: "Aangezien het om een zeer gevoelig systeem gaat is het van belang om een kijker uitsluitend te gebruiken in het donker en de buis (overdag) niet bloot te stellen aan licht.",
    tech_nacht_8: "Lahoux Optics werkt al decennia samen met de Nederlands/ Franse buizenfabrikant Photonis en gebruikt uitsluitend nieuwe buizen van deze marktleider zodat wij u hoogwaardige uitsluitend producten aanbieden.",
    tech_nacht_button: "Ga naar nachtzichtkijkers",
    tech_warm_1: "Met warmtebeeld blijven zelfs dieren die zich achter, niet te dicht, struikgewas bevinden niet verborgen. Ook vogels, zoals kraaien, die dicht tegen de stam zitten of vogels elders in bomen kunnen zo gevonden worden.",
    tech_warm_2: "Niet alleen in open velden maar ook in het bos is een warmtebeeld een onmisbaar gereedschap. Van muizen en kleine vogels zullen ook eekhoorns, marterachtigen, vossen tot de grotere hoefdieren niet aan uw aandacht ontsnappen. Hierdoor is zelfs op meerdere honderden meters zelfs in het bos warmte of beweging te detecteren. ",
    tech_warm_3: "Een belangrijk punt bij het gebruik van warmtebeeld zijn de weersomstandigheden. Als er weinig vocht in de lucht is zijn de prestaties beter dan tijdens een regenbui. De gebruiksafstand wordt daardoor beïnvloed. Het grote voordeel is dan wel dat als er een warm object zich in het beeldveld bevindt deze er duidelijk uitspringt.",
    tech_warm_4: "Het kost de gebruiker enige tijd om de beelden goed te interpreteren aangezien het beeld puur uit warmte is opgebouwd en veel voorwerpen en dieren er anders uitzien dan we gewend zijn. Daardoor blijft de gebruiker gefascineerd nieuwe dingen ontdekken. Door gebruik te maken van een klein deel van de infrarode straling om ons heen kunnen (ongekoelde) warmtebeeldcamera’s juist het temperatuurbereik van levende wezens zichtbaar maken. Dit gebeurt op een puur digitale wijze zodat er met beeldbewerking in de camera veel gedaan kan worden om de straling beter zichtbaar te maken voor de gebruiker. ",
    tech_warm_5: "Daarnaast kan het beeld digitaal vergroot worden en vaak ook opgenomen.",
    tech_warm_6: "Door de ongekoelde warmtebeeldcamera is de prijs en het gebruiksgemak van deze techniek is bereikbaar geworden voor veel doelgroepen. De nog gevoeligere en complexe gekoelde camera’s blijven door hun hoge prijs alleen beschikbaar voor overheden en sommige professionele gebruikers.",
    tech_warm_7: "De meeste modellen hebben 4 verschillende kleurmodi om bij vrijwel alle weersomstandigheden een goed beeld te hebben. ",
    tech_warm_button: "Ga naar warmtebeeldkijkers",
    tech_digi_1: "Naast de klassieke nachtzicht technologie heeft Lahoux Optics ook digitale restlichtversterkers.",
    tech_digi_2: "Dit zijn zeer gevoelige CCD/ camera sensoren die vergelijkbaar zijn met digitale camera sensoren en dus ook bruikbaar zijn overdag. Voor een goed zicht onder de donkerste omstandigheden is hierbij een infrarood straler nodig die Lahoux Optics ook in het assortiment heeft. ",
    tech_digi_3: "Het grote voordeel hiervan zijn de lagere kosten zodat deze techniek zeer bereikbaar is voor velen.",
    tech_digi_button: "Ga naar digitaal-kijkers",
    history_page_title: "Geschiedenis",
    history_copy_title: "Geschiedenis",
    history_copy_1: "Nachtzichttechnologie heeft een lange traditie in Nederland. Het begon in 1934 met de uitvinding van de eerste beeldversterkerbuis (restlichtversterker) door Philips. Sinds de jaren zestig is Nederland een van de leidende landen in de productie van beeldversterkerbuizen. In 1970 is PHOTONIS begonnen met de productie van beeldversterkerbuizen in Roden (Nederland). Photonis is momenteel een van de grootste producenten ter wereld van beeldversterkerbuizen.",
    history_copy_2: "Lahoux Optics is opgericht in 1992. Zij leverde aanvankelijk optische componenten aan Nederlandse en Belgische nachtzichtfabrikanten. Later deed Lahoux Optics de distributie van PHOTONIS beeldversterkerbuizen in een aantal landen. Een belangrijke stap voor Lahoux Optics was de ontwikkeling van eigen nachtzicht- en warmtebeeldkijkers.",
    history_copy_3: "Op dit moment is Lahoux Optics een van de weinige bedrijven in West-Europa die eigen high-end nachtzicht- en warmtebeeldkijkers voor de civiele markt ontwerpen, ontwikkelen en produceren. Deze high-end Lahoux nachtzicht- en warmtebeeldkijkers worden volledig in Nederland ontwikkeld, gefabriceerd en geassembleerd en worden gekenmerkt door een compromisloze kwaliteit.",
    history_copy_4: "Naast de eigen productie heeft Lahoux Optics kijkers in het assortiment van enkele fabrieken waar wij al lange tijd nauw mee samenwerken. Lahoux Optics staat garant voor een hoogwaardig serviceniveau.",
    work_page_title: "Hoe wij werken",
    work_1_title: "Stofvrij",
    work_1_copy: "De belangrijkste factor bij het assembleren van een hoge kwaliteit Lahoux nachtkijker, zoals de Lahoux LV-81 of de Lahoux Hemera, is een absoluut stofvrije en schone laboratorium atmosfeer. Het reinigen van de lenzen is een topprioriteit. Zelfs de kleinste onzuiverheden worden tijdens de kwaliteitscontrole gedetecteerd en zo wordt de beste nachtkijker door ons geselecteerd. ",
    work_2_title: "Assemblage",
    work_2_copy: "Onze medewerkers houden van de Lahoux producten. Daarom letten ze bij de montage op maximale precisie. Een precisie die u vervolgens ook tijdens de jacht zult ervaren. Minimale verschuiving van het trefpunt en maximale terugslagnauwkeurigheid.",
    work_3_title: "Kwaliteitscontrole",
    work_3_copy: "Elk Lahoux product doorloopt de strenge kwaliteitscontrole. Hier wordt elke functie en maatnauwkeurigheid nogmaals gecontroleerd. Alleen producten die 100 procent aan de normen voldoen, verlaten de productielijn.",
    work_4_title: "Hoge precisie",
    work_4_copy: "Wij gebruiken bij Lahoux hoge-precisie collimatie systemen bij de kwaliteitscontrole, om de maximale precisie en de hoogste nauwkeurigheid van onze Lahoux voorzetkijkers te verkrijgen. U kunt hier tijdens het schieten volledig op vertrouwen.",
    work_5_title: "ISO certificaat",
    work_5_copy: "Ons ISO 9001.2015 certificaat (op aanvraag beschikbaar) is uw garantie dat we onze beloften op het gebied van kwaliteit en service waarmaken. Al onze bedrijfsprocessen zijn getoetst tegen de strenge ISO normeringen kwaliteitsmanagement. Jaarlijks wordt dit certificaat opnieuw bekrachtigd middels een onafhankelijke audit door SGS, de toonaangevende marktleider in bedrijfscertificeringen. Zo kunt u, zij het als leverancier, klant of andere relatie, zowel nu als in de toekomst vertrouwen op onze compromisloze productkwaliteit en dienstverlening.",    
    events_title: "Evenementen",
    events_archive_title: "Archief",
    events_none: "Momenteel zijn er geen evenementen gepland.",
    events_button: "Meer informatie",
    service_title: "Servicebeleid",
    service_copy_1: "Bij normaal gebruik is er gedurende vele jaren geen onderhoud nodig aan uw Lahoux kijker. Bent u een eindgebruiker en heeft u een probleem met uw Lahoux kijker, neem dan contact op met uw dealer. ",
    service_copy_2: "Bent u een dealer en heeft u een Lahoux kijker die niet goed functioneert? Neem dan ",
    service_copy_button: "contact",
    service_copy_3: " op met de service afdeling van Lahoux Optics.",
    service_choise_title: "Distributeur of dealer in uw land",
    service_choise: "Selecteer uw land",
    specs_description: "Beschrijving",
    specs_voordelen: "Voordelen",
    specs_warning: "*Waarschuwing",
    specs_warning_p: "Let op de wettelijke voorschriften in uw respectieve federale staat of land.",
    specs_leveromvang: "Wordt standaard geleverd met de volgende accessoires:",
    specs_tech: "Technische specificaties", 
    specs_accessoires: "Beschikbare accessoires voor dit product",
    specs_dri: "DRI waarden",
    specs_related: "Gerelateerde producten",
    specs_compatible: "Geschikt voor", 
    specs_new: "Nieuw",
    specs_footnote_battery: "Afhankelijk van de gebruiksomstandigheden",
    specs_artNum: "Artikelnummer",
    specs_color: "Nachtzicht is beschikbaar in groen of zwart/wit (Onyx).",
    specs_groen: "Groen",
    specs_fom_voor: "De",
    specs_fom: "is leverbaar met verschillende FOM waardes.",
    specs_spots_selection: "Selecteer de spot categorie.",
    specs_spots_veel: "Toegestaan",
    specs_spots_weinig: "Enkele",
    specs_spots_geen: "Bijna geen", 
    specs_price: "De prijs verschilt per kleur/FOM/spots combinatie. Kiest u de combinatie die het beste bij u past.",
    specs_download_flyer: "Download productflyer PDF",
    specs_disclaimer_spots: "Disclaimer: Eventuele cosmetische defecten of zwarte puntjes in de beeldversterkerbuis hebben geen invloed op de prestaties of de betrouwbaarheid van de nachtkijker. Deze onvolkomenheden zijn inherent aan het productieproces. Buizen van categorie B en A hebben minder zwarte puntjes dan buizen van categorie C.",
    specs_order_button: "Bestel",
    vat_incl: "incl.",
    vat: "BTW",
    from: "Van",
    for: "Voor",
    new: "Nieuw",
    find_dealer: "Zoek dealer",
    products_page_title: "Producten",
    product_detail: "Product details",
    product_no_longer_available: "Dit product is niet meer leverbaar",
    no_products_found: "Er zijn geen producten gevonden",
    all_products: "Alle producten",
    thermal_imaging: "Warmtebeeld",
    nightvision: "Nachtzicht",
    digital: "Digitaal",
    front_attachment: "Voorzet",
    observation: "Observatie",
    riflescope: "Richtkijker",
    filter_hide: "Verbergen",
    filter_search_heading: "Zoek uw kijker",
    filter_search_placeholder: "Zoek kijker",
    filter_sort_heading: "Sorteren",
    filter_sort_price_low_high: "Prijs laag naar hoog",
    filter_sort_price_high_low: "Prijs hoog naar laag",
    filter_sort_clear: "Wis sorteren",
    filter_options_heading: "Filter kijkers",
    contact_title: "Adres",
    contact_tel: "Tel",
    contact_p: "U kunt alleen op uitnodiging bij ons kantoor langskomen. Wij hebben geen winkel of showroom.",
    contact_mail: "Mail",
    contact_dealers: "Alleen voor dealers",
    dealers_page_title: "Dealers",
    glossary_page_title: "Woordenlijst",
    glossary_seo_title: "Verklarende woordenlijst voor warmtebeeld- en nachtzichtapparatuur",
    events_copy_1: "Maak kennis met Lahoux",
    events_copy_2: "In de afgelopen decennia heeft Lahoux zich gespecialiseerd in nachtzicht en warmtebeeldcamera’s. Lahoux Optics is op de meest belangrijke beurzen in binnen en buitenland aanwezig zodat u onze innovatieve nachtzicht en warmtebeeldcamera’s zelf kunt proberen. Wij laten u graag onze innovatieve technieken zien en we geven u gepast advies dat het beste bij uw outdoor- of jachtsituatie past. U bent altijd van harte welkom op onze stand.",
    cookie_h1: "Lahoux Optics maakt gebruik van cookies",
    cookie_p: "Lahoux Optics maakt op deze website gebruik van functionele en geanonimiseerde analytische cookies. Wij plaatsen momenteel geen cookies van websites van derden en verzamelen geen persoonsgegevens van bezoekers. Meer informatie leest u in onze",
    cookie_consent: "Sluiten",
    Page_not_found: "De pagina die u zocht bestaat niet meer, ga verder naar home.",
    Netherlands: "Nederland",
    Austria: "Oostenrijk",
    Belgium: "België",
    Bulgaria: "Bulgarije",
    Cyprus: "Cyprus",
    Czech_republic: "Tsjechië",
    Denmark: "Denemarken",
    Estonia: "Estland",
    Finland: "Finland",
    France: "Frankrijk",
    Germany: "Duitsland",
    Greece: "Griekenland",
    Hungary: "Hongarije",
    Ireland: "Ierland",
    Italy: "Italië",
    Croatia: "Kroatië",
    Latvia: "Letland",
    Lithuania: "Litouwen",
    Luxembourg: "Luxemburg",
    Malta: "Malta",
    Poland: "Polen",
    Portugal: "Portugal",
    Romania: "Roemenië",
    Slovakia: "Slowakije",
    Sweden: "Zweden",
    United_kingdom: "Verenigd Koninkrijk",

    order_email: {
        subject_order: "Bestelling",
        body_opening: "Geachte klant,",
        body_copy_1: "Als u dit artikel wilt bestellen, vul dan hieronder uw gegevens in en verstuur deze e-mail.",
        body_product_title: "Product: ",
        body_product_color: "Gekozen kleur: ",
        body_product_FOM: "Gekozen FOM waarde: ",
        body_product_spot: "Gekozen spot categorie: ",
        body_details_heading: "Vul alstublieft uw gegevens in:",
        body_details_name: "Naam: ",
        body_details_address: "Verzendadres: ",
        body_details_zip: "Postcode: ",
        body_details_city: "Stad: ",
        body_details_country: "Land: ",
        body_details_phone: "Telefoonnummer: ",
        body_details_email: "E-mail: ",
        body_thanks: "Bedankt voor uw bestelling!",
    },

    imprint: {
        page_heading: "Imprint",
        contact_company_name: "Lahoux Optics BV",
        contact_street: "Stephensonstraat 47",
        contact_zip_city: "2014 KC Haarlem",
        contact_email: "E-Mail: info@lahoux.nl",
        contact_tel: "Telefoon: + 31 23 5514678",
        contact_person: "", // only for Germany
        contact_gericht: "", // only for Germany
        contact_coc: "KVK nummer: 301 24 219",
        contact_vat: "BTW nummer: NL 822 492 374 B01",
        dispute_heading: "Alternatieve geschillenbeslechting",
        dispute_text: "De Europese Commissie biedt een platform voor buitengerechtelijke online geschillenbeslechting (OS-platform), beschikbaar op http://ec.europa.eu/odr.",
        picture_credit_heading: "Afbeelding credits",
        picture_credit_p1: "Verschillende afbeeldingen: licentievrij op 123rf.com",
        picture_credit_p2: "Verschillende afbeeldingen: Public domain CCO op pixabay.com",
        disclaimer_heading: "Disclaimer",
        liability_heading: "Beperking van de aansprakelijkheid",
        liability_p1: "De inhoud die door Lahoux Optics BV op deze website is gemaakt of onderhouden, is met de grootst mogelijke zorg samengesteld. Niettemin aanvaardt Lahoux Optics BV geen aansprakelijkheid voor hun tijdigheid, volledigheid en nauwkeurigheid (noch op het moment van publicatie noch continu daarna). Als dienstverlener is Lahoux Optics BV verantwoordelijk voor zijn eigen inhoud en verstrekte informatie volgens de algemene wetten echter niet verplicht om de verzonden of opgeslagen informatie van derden te controleren. Verwijdering of blokkering van deze inhoud vindt onmiddellijk plaats vanaf het moment van kennis van een specifieke inbreuk. Aansprakelijkheid is alleen mogelijk vanaf het moment dat de kennis is verworven. Lahoux Optics BV wijst erop dat de overdracht van gegevens op internet (bijvoorbeeld via e-mail) beveiligingslekken heeft en dat volledige bescherming van de gegevens tegen toegang door derden niet kan worden gegarandeerd; Dienovereenkomstig aanvaardt Lahoux Optics BV in dit opzicht geen aansprakelijkheid voor dergelijke schade die een gebruiker oploopt als gevolg van dergelijke beveiligingslekken.",
        linking_heading: "Linken",
        linking_p1: "Deze website kan “externe links” naar andere websites bevatten. Lahoux Optics BV stelt alles in het werk om de legaliteit van de inhoud bij het linken te verifiëren. Op het moment van koppelen waren geen inbreuken herkenbaar. Na kennisgeving van een dergelijke inbreuk wordt de link onmiddellijk verwijderd. Lahoux Optics BV heeft geen invloed op de inhoud of veranderende inhoud van de gelinkte websites; alleen de aanbieder van de overeenkomstige gelinkte website is hiervoor verantwoordelijk en Lahoux Optics BV is daarom niet verantwoordelijk voor de juistheid of wettigheid van dergelijke inhoud.",
        copyright_heading: "Copyright/aanvullende rechten",
        copyright_p1: "De inhoud, werken en informatie die op deze website worden gepubliceerd, zijn onderworpen aan Nederlands en aanvullend auteursrecht. Elke vorm van duplicatie, verwerking, distributie, opslag en elke vorm van exploitatie buiten de grenzen van het auteursrecht vereist de voorafgaande schriftelijke toestemming van de respectieve auteursrechthouder. Het ongeautoriseerd kopiëren / opslaan van de verstrekte informatie op deze webpagina’s is niet toegestaan en strafbaar. Voor zover de inhoud op deze pagina niet door de exploitant is gemaakt, worden de auteursrechten van derden in aanmerking genomen. Met name de inhoud van derden is als zodanig gemarkeerd. Neem contact op met Lahoux Optics BV als een mogelijke inbreuk op het auteursrecht wordt vastgesteld.",
    },

    jubilee: {
        page_heading: "Lahoux Optics 30 jaar",
        page_hero_copy: "Lahoux Optics bestaat dit jaar 30 jaar. Een mijlpaal waar we als bedrijf trots op zijn en stil bij willen staan.",
        intro_article_p1: "Lahoux Optics bestaat dit jaar 30 jaar.",
        intro_article_p2: "Een mijlpaal waar we als bedrijf trots op zijn en stil bij willen staan.",
        intro_article_p3: "Lahoux Optics levert al 30 jaar technisch hoogwaardige nachtkijkers.",
        intro_article_p4: "Ons bedrijf is opgericht in 1992 in Haarlem waar tevens ons hoofdkantoor is gevestigd. In het begin richten we ons alleen op nachtkijkers, welke wij altijd assembleren met de bekende Nederlandse Photonis restlichtversterkers. De techniek was vrij nieuw in die tijd.",
        intro_article_p5: "In de loop der jaren kwam warmtebeeldtechnologie op en heeft Lahoux Optics ook deze technologie in zijn kijkers geïmplementeerd.",
        intro_article_p6: "We hebben op basis van beide technologieën kijkers ontworpen voor jacht- en outdoor toepassingen, waaronder observatiekijkers, voorzetmodellen, richtkijkers en binoculairen.",
        intro_article_p7: "Met het groeien van het bedrijf zijn we in 2012 verhuisd naar een grotere productielocatie binnen Haarlem. We zijn nu gevestigd op een locatie waar R&D, product testing, productie, service en verkoop nauw samen kunnen werken. De locatie fungeert tevens als reparatiecentrum voor alle Europese verkopen.",
        intro_article_p8: "Sinds 2018 heeft Lahoux Optics een dochteronderneming in Berlijn om met behulp van onze lokale Duitse collega’s de voor Lahoux belangrijke Duitse markt nog beter te kunnen bedienen.",
        intro_article_p9: "In 2021 heeft Lahoux het ISO 9001 keurmerk verworven, hetgeen voor de klanten en partners van Lahoux Optics een verdere waarborg is voor het gevoerde kwaliteitsmanagement.",
        intro_article_p10: "Lahoux Optics streeft ernaar om producten te maken van de allerhoogste kwaliteit en besteedt de uiterste zorg aan ieder product dat door haar geproduceerd wordt.",
        intro_article_p11: "Vanwege onze 30-jarige verjaardag bieden wij onze klanten dit jaar diverse cadeaus aan in de vorm van een aantal kortingsacties. Raadpleeg regelmatig onze website, sociale media of onze dealers voor de diverse acties. Wij wensen u alvast langdurig plezier met uw aangeschafte Lahoux product!",
        offer: "Lees meer over onze speciale jubileumaanbieding voor u!",
        offer_button: "Jubileum aanbieding!",
    },

    cookie_policy: {
        page_heading: "Cookieverklaring",
        page_hero_copy: "",
        intro_p1: "Lahoux Optics maakt op deze website gebruik van functionele en analytische cookies en van local storage.",
        intro_p2: "Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen in de browser van uw computer, tablet of smartphone. Lahoux Optics gebruikt cookies met een puur technische functionaliteit. Deze zorgen ervoor dat de website naar behoren werkt en dat bijvoorbeeld uw voorkeursinstellingen onthouden worden. Deze cookies worden ook gebruikt om de website goed te laten werken en deze te kunnen optimaliseren.",
        intro_p3: "Daarnaast plaatsen we analytische cookies. Analytische cookies verzamelen (vaak anoniem) gegevens en statistieken om te begrijpen hoe bezoekers de website gebruiken, zodat we de website en onze dienstverlening kunnen verbeteren.",
        uitschakelen_heading: "In- en uitschakelen van cookies en verwijdering daarvan",
        uitschakelen_p1: "U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen.",
        uitschakelen_p2: "Meer informatie omtrent het in- en uitschakelen en het verwijderen van cookies kunt u vinden in de instructies van uw browser en/of met behulp van de Help-functie van uw browser.",
        analytics_heading: "Google Analytics",
        analytics_p1: "Via onze website wordt een cookie geplaatst van het Amerikaanse bedrijf Google, als deel van de “Analytics”-dienst. Wij gebruiken deze dienst om bij te houden en rapportages te krijgen over hoe bezoekers de website gebruiken. Wij gebruiken deze informatie om beslissingen te nemen over de inrichting van onze website. Tevens optimaliseren wij hiermee de werking van de website. Om zorgvuldige verwerking mogelijk te maken hebben we de volgende maatregelen getroffen:",
        analytics_listitem1: "We hebben een bewerkersovereenkomst met Google gesloten;",
        analytics_listitem2: "De informatie die Google verzamelt wordt zo veel mogelijk geanonimiseerd. Uw IP-adres wordt nadrukkelijk niet meegegeven;",
        analytics_listitem3: "We hebben alle mogelijkheden die Google aanbiedt om ‘gegevens met Google te delen’ uitgezet;",
        analytics_listitem4: "We maken geen gebruik van andere Google-diensten in combinatie met de Google Analytics-cookies.",
        analytics_p2: "Google kan deze informatie aan derden verschaffen indien Google hiertoe wettelijk wordt verplicht, of voor zover derden de informatie namens Google verwerken. Wij hebben hier geen invloed op. Wij hebben Google niet toegestaan de verkregen analytics informatie te gebruiken voor andere Google-diensten.",
        analytics_p3: "De informatie die Google verzamelt wordt zo veel mogelijk geanonimiseerd. Uw IP-adres wordt nadrukkelijk niet meegegeven.",
        local_storage_heading: "Local Storage",
        local_storage_p1: "Local storage is een vorm van lokale gegevensopslag, en is bedoeld om de website goed te laten werken. Wij gebruiken Local storage alleen om de door u gekozen taal van de website in uw browser te onthouden. Wij gebruiken local storage ook om te onthouden dat u de cookiebanner heeft gesloten, zodat deze bij uw volgende bezoek niet meer wordt weergegeven.",
        session_storage_heading: "Session storage",
        session_storage_p1: "Session storage is een vorm van tijdelijke opslag van gegevens, en is bedoeld om de website goed te laten werken.. Wij gebruiken session storage alleen om informatie over de gekozen taal van de website op te slaan in uw browser. De session storage wordt verwijderd nadat u uw browser heeft afgesloten.",
    },

    message_yearend_closing: {
        heading: "Eindejaarssluiting",
        p1: "Lahoux Optics is van zaterdag 24 december 2022 t/m zondag 1 januari 2023 gesloten.",
        p2: "Vanaf maandag 2 januari 2023 zijn wij weer geopend.",
    },

    jagd_und_hund: {
        page_heading: "Jagd & Hund Dortmund Duitsland 2023",
        page_hero_copy: "Europa's grootste jachtbeurs. Lahoux Optics zal aanwezig zijn op Jagd & Hund met een stand in Hal 6 standnummer H20.",
        article_link_jagd_hund: "Jagd & Hund",
        article_p1: "is Europa's grootste jachtbeurs en wordt gehouden van 24 tot 29 januari 2023 in het beurscentrum in Dortmund.",
        article_p2: "Lahoux Optics zal aanwezig zijn op Jagd & Hund met een stand in Hal 6 standnummer H20.",
        article_p3: "We hebben mooie acties tijdens deze beurs. Binnenkort vindt u hier meer informatie over op deze pagina.",
        article_p4: "We hebben mooie acties tijdens deze beurs, deze acties kunt u hier vinden:",
        home_title: "Jagd & Hund Dortmund Duitsland 2023",
        home_copy: "Jagd & Hund is Europa's grootste jachtbeurs en wordt gehouden van 24 tot 29 januari 2023 in het beurscentrum in Dortmund. Lahoux Optics zal aanwezig zijn op Jagd & Hund met een stand in Hal 6 standnummer H20. We hebben mooie acties tijdens deze beurs!",
        home_button: "Bekijk de acties",
   },

   hohe_jagd_und_fischerei: {
        page_heading: "Die Hohe Jagd & Fischerei Salzburg 2023",
        page_hero_copy: "Lahoux Optics zal aanwezig zijn op Die Hohe Jagd & Fischerei met een stand in Hal 10 standnummer 0722.",
        article_link_jagd_hund: "Die Hohe Jagd und Fischerei",
        article_p1: "wordt gehouden van 16 tot 19 februari 2023 in het beurscentrum in Salzburg.",
        article_p2: "Lahoux Optics zal aanwezig zijn op Die Hohe Jagd & Fischerei met een stand in Hal 10 standnummer 0722.",
        article_p3: "We hebben mooie aanbiedingen tijdens deze beurs. Binnenkort vindt u hier meer informatie over op deze pagina.",
        article_p4: "We hebben mooie acties tijdens deze beurs, deze acties kunt u hier vinden:",
        home_title: "Die Hohe Jagd & Fischerei Salzburg 2023",
        home_copy: "Die Hohe Jagd & Fischerei wordt gehouden van 16 tot 19 februari 2023 in het beurscentrum in Salzburg. Lahoux Optics zal aanwezig zijn op Die Hohe Jagd & Fischerei met een stand in Hal 10 standnummer 0722. We hebben mooie acties tijdens deze beurs!",
        home_button: "Bekijk de acties",
   }
   
};