import React, {useState, useEffect} from "react"
import axios from 'axios' 
import DataVat from "./components/DataVat"
import {Helmet} from "react-helmet"
import forest from "./assets/forest.jpg";
import Navbar from './components/Nav/Navbar.jsx';
import './App.css';
import Footer from "./components/Footer"
import "./locales/i18n";
import { useTranslation } from "react-i18next";
import RoutesHeader from "./components/Routes/RoutesHeader";
import RoutesMain from "./components/Routes/RoutesMain";
import BackToTopButton from "./components/BackToTopButton";
import CookieBanner from "./components/CookieBanner";
import Seo from "./components/Seo";


function App({open}) {
  const [destination, setDestination] = useState("hunting")
  const [showItems, setShowItems] = useState("all")
  const [showCountry, setShowCountry] = useState("Nederland")
  const [clientCountry, setClientCountry] = useState('Netherlands')
  const [clientVat, setClientVat] = useState(21)
  const [correctionPercentage, setCorrectionPercentage] = useState(1)
  const [showCookieBanner, setShowCookieBanner] = useState(true)
  const [searchValue, setSearchValue] = useState("");
  const [statusFilter, setStatusFilter] = useState({ sortPrice: false, filterObservation: false, filterVoorzet: false, filterRiflescope: false, filterNew: false, filterThermal: false, filterNightvision: false, filterDigital: false})
  const [scrollPosition, setScrollPosition] = useState(0);

  const { t, i18n } = useTranslation();

  const clickHandlerShowCountry = value =>{
    setClientCountry(value)
  }
  const clickHandlerShowItem = value =>{
    setShowItems(value)
  }
 
  const clickHandlerDestination = value =>{
    setDestination(value)
  }

  const changeHandlerStatusFilter = (e) =>{
    const value = e.target.type=== "checkbox" ? e.target.checked : e.target.value
    setStatusFilter(current => ({ ...current, [e.target.name]: value}));
  }

  //creating function to load ip address from the API
  const getClientData = async () => {
    try{ const res = await axios.get('https://geolocation-db.com/json/')

    const resfound = DataVat.find(item => item.country === res.data.country_name)
    // const resfound = DataVat.find(item => item.country === "Germany")
    if (resfound != null) setClientCountry(resfound.country)
    //setClientCountry(res.data.country_name)
    createClientVat(resfound)
    createCorrectionPercentage(resfound)
  }
    catch(_error){setClientCountry("Netherlands")}
  }
  
  const clickHandlerCookieBanner = () =>{
    setShowCookieBanner(false)
    localStorage.setItem("cookieMessage", "closed")
  }
  const cookieMessageClosed = localStorage.getItem("cookieMessage")

  const createClientVat = (resfound) =>{
     
        setClientVat(DataVat
          .find(item => item.country === resfound.country).amount
          )
          
    }

    // 31-5-2022 PP set correction percentage in state for specific country
    // used to calculate a correction on the price if applicable
    // correction percentages per country are set in DataVat.js
    const createCorrectionPercentage = (resfound) => {
      setCorrectionPercentage(
        DataVat.find((item) => item.country === resfound.country).correction
      );
    };

  useEffect( () => {
    //passing getData method to the lifecycle method
    getClientData()

  }, [])

  const checkEndDate = (date) => new Date(date) >= new Date().setHours(0, 0, 0, 0);

  const checkStartDate = (date) => new Date(date) <= new Date().setHours(0, 0, 0, 0);

  // make description meta tag in Helmet get translatable content
  const helmetDescription = {
    en: "Lahoux Optics has been providing high quality night vision and thermal imaging scopes for over 30 years. This includes observation devices, clip-on’s, riflescopes and binoculars.",
    nl: "Lahoux Optics levert al meer dan 30 jaar hoge kwaliteit nachtzicht kijkers en warmtebeeld kijkers. Dit omvat observatieapparatuur, clip-on's, richtkijkers en verrekijkers.",
    de: "Lahoux Optics bietet seit über 30 Jahren qualitativ hochwertige Nachtsichtgeräte und Wärmebildgeräte an. Dazu gehören Beobachtungsgeräte, Clip-On's, Zielfernrohre und Ferngläser.",
  };

  return (
    <div className="app">

      <Seo description={helmetDescription[i18n.language]} url="https://lahouxoptics.com"/>

      <div>
        {/* <Choise
          clickHandlerDestination={clickHandlerDestination}
          destination={destination}
        /> */}
        <header
          className="app-header"
          style={{
            backgroundImage: `url(${forest})`,
          }}
        >
          <Navbar
            clientCountry={clientCountry}
            showItems={showItems}
            clickHandlerShowItem={clickHandlerShowItem}
            checkEndDate={checkEndDate}
          />
          <RoutesHeader clientCountry={clientCountry} showItems={showItems} checkEndDate={checkEndDate} />
        </header>
        <main>
          <RoutesMain showItems={showItems} setShowItems={setShowItems} clickHandlerShowItem={clickHandlerShowItem} clientCountry={clientCountry} clickHandlerShowCountry={clickHandlerShowCountry} clientVat={clientVat} correctionPercentage={correctionPercentage} checkEndDate={checkEndDate} checkStartDate={checkStartDate} statusFilter={statusFilter} changeHandlerStatusFilter={changeHandlerStatusFilter} searchValue={searchValue} setSearchValue={setSearchValue} scrollPosition={scrollPosition} setScrollPosition={setScrollPosition} />   
          <BackToTopButton />
          {cookieMessageClosed ? null :
            <CookieBanner clickHandlerCookieBanner={clickHandlerCookieBanner} />
          }
        </main>
        <Footer checkEndDate={checkEndDate} />
      </div>
    </div>
  );
}

export default App;
