import React, {useCallback, useRef, useEffect} from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
// import "./../../locales/i18n";
import RozetNL from "./../../assets/Sticker_NL.svg";
import RozetEN from "./../../assets/Sticker_EN.svg";
import RozetDE from "./../../assets/Sticker_DE.svg";
import ReactCanvasConfetti from "react-canvas-confetti";

const LahouxJubileumHeader = () => {
  const { t, i18n } = useTranslation();

  const imgAltTextRozet = {
    en: "Lahoux Optics 30 years",
    nl: "Lahoux Optics 30 jaar",
    de: "Lahoux Optics 30 Jahre",
  };

  // Confetti init
  const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 999999,
  };

  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(800 * particleRatio),
        zIndex: 99999999,
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55
    });

    makeShot(0.2, {
      spread: 60
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45
    });
  }, [makeShot]);

// End confetti init

useEffect(() => {
  fire();
}, [fire]);

  return (
    <StyledJubileumHeader>
      <div className="hero">
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
        <div className="heroCopy">
          <div>
            <h1>{t("jubilee.page_heading")}</h1>
            <p>{t("jubilee.page_hero_copy")}</p>
          </div>
        </div>

        {i18n.language === "nl" ? (
          <img src={RozetNL} alt={imgAltTextRozet[i18n.language]} onClick={fire} />
        ) : i18n.language === "de" ? (
          <img src={RozetDE} alt={imgAltTextRozet[i18n.language]} onClick={fire} />
        ) : (
          <img src={RozetEN} alt={imgAltTextRozet[i18n.language]} onClick={fire} />
        )}

      </div>
    </StyledJubileumHeader>
  );
};

const StyledJubileumHeader = styled.div`
  .hero {
    max-width: 100vw;
    margin-top: 100px;
  }

  h1 {
    padding-bottom: 5px;
    max-width: 400px;
    color: white;
    font-size: 40px;
    font-weight: bold;
    border-bottom: 6px solid #fc751b;
    margin-bottom: 10px;
  }

  p {
    font-size: 20px;
  }

  .heroCopy {
    position: absolute;
    top: 48vh;

    z-index: 10;
    text-align: left;
    width: 100%;
    padding: 10px;

    @media (min-width: 768px) {
      width: 50%;
    }

    @media (min-width: 1024px) {
      width: 30%;
      left: 50%;
    }
  }

  .heroCopy a {
    color: white;
    font-weight: bold;
    font-size: 30px;
    text-decoration: none;
    border: 1px solid white;
    padding: 8px;
    border-radius: 10px;
  }

  .heroCopy a:hover {
    background-color: #fc751b;
    border: 1px solid #fc751b;
  }

  img {
    width: 300px;
    position: absolute;
    right: 34px;
    top: 100px;
    transform: rotate(12deg);
    cursor: pointer;
  }
`;

export default LahouxJubileumHeader;
