import React, {useState} from "react"
import DataProducts from "./DataProducts"
import DataLeveromvang from "./DataLeveromvang"
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components';
import LinkProduct from "./LinkProduct";
import { useTranslation } from "react-i18next";
import Seo from "./Seo";
import RozetNL from "./../assets/Sticker_NL.svg";
import RozetEN from "./../assets/Sticker_EN.svg";
import RozetDE from "./../assets/Sticker_DE.svg";
import VideoElement from "./VideoElement";

import {
    Link
} from "react-router-dom";


export const productArray = url => {
    return DataProducts.find(item => {
        return item.url === url;
    })
}

function ProductSpecs({clientCountry, clientVat, correctionPercentage}) {
    const { t, i18n } = useTranslation();
    const { url } = useParams()
    const data = productArray(url)

    // const [fom, setFom] = useState(data.url === "Lahoux-LVS-7"?"1200": "1200")
    const [fom, setFom] = useState("1200")
    const handleFom = event => setFom(event.target.value)

    const [color, setColor] = useState("Groen")
    const handleColor = event => setColor(event.target.value)

    const [spots, setSpots] = useState("veel")
    const handleSpots = event => setSpots(event.target.value)
    
    const imgAltTextRozet = {
        en: "Lahoux Optics 30 years",
        nl: "Lahoux Optics 30 jaar",
        de: "Lahoux Optics 30 Jahre",
      };

    // make description meta tag in Helmet get translatable content
     const helmetDescription = {
        en: "Product information of",
        nl: "Productinformatie van",
        de: "Produktinformation von",
     }; 

    return (
        <StyledSpecs>
            {data === undefined ? null :
                <div className="productspec-container">
                    <Seo 
                      title={data.title[i18n.language]}
                      description={`${helmetDescription[i18n.language]} ${data.title[i18n.language]}`}
                      url={`https://lahouxoptics.com/producten/${data.url}`}
                      image={`https://lahouxoptics.com/productafbeeldingen/${data.images[0]}`}
                    />

                    <div>
                        <div className="productInfo">
                            <h1> {data.title[i18n.language]}</h1>
                            {data.available_date && <p className="available-date">{data.available_date[i18n.language]}</p>}

                            
                            {(clientCountry==="Germany" && data.warning) &&
                                <>
                                    <p className="warning">Wärmebild, Nachtsicht und digitale Zielgeräte besitzen ein integriertes Absehen. Diese Geräte können direkt auf der Waffe montiert werden und als Zieloptik eingesetzt werden.</p>
                                    <div className="alert">
                                        <FontAwesomeIcon icon={faExclamationTriangle} /> 
                                        <p>Der Besitz des oben genannten Zielgeräts mit integriertem Absehen ist in Deutschland strafbar. Daher müssen die rechtlichen Bestimmungen bei einem Kauf beachtet werden.</p>
                                    </div>
                                </>
                            }

                            <div className="subtext">
                                <p>{data.subtitle}</p>
                                
                                {/* {console.log(typeof(Number(data.price)) + (data.price*(clientVat/100+1)).toFixed(2)) } */}
                                {data.type !== "night" && data.price &&
                                
                                    <div className={`pricing-block ${data.endOfLife ? "product-unavailable" : ""}`}>                                        
                                        <p className="price"><span className="amount">€ {Math.round(((data.price) * correctionPercentage * (1+clientVat/100)))}</span> <span className="vat">({t("vat_incl")}{clientVat}% {t("vat")} {t(clientCountry)})</span></p> 
                                        {/* {console.log(typeof(Number(data.price)) + "dit is number(data.price) ")} */}
                                        {data.number && <p className="article-number">{t("specs_artNum")}: {data.number}</p>}
                                        {data.ean && <p className="ean-code">EAN: {data.ean}</p>}
                                        

                                        {/* show anniversary offer  */}
                                        {data.jubilee_offer && 
                                        <>
                                            <p className="jubilee-offer">
                                                {i18n.language === "nl" ? (
                                                    <img src={RozetNL} alt={imgAltTextRozet[i18n.language]} className="rozet-img" />
                                                ) : i18n.language === "de" ? (
                                                    <img src={RozetDE} alt={imgAltTextRozet[i18n.language]} className="rozet-img" />
                                                ) : (
                                                    <img src={RozetEN} alt={imgAltTextRozet[i18n.language]} className="rozet-img" />
                                                )}
                                                {t("jubilee.offer")}
                                            </p>
                                            <Link to="/lahoux-jubileum" className="button-jubilee-offer">{t("jubilee.offer_button")}</Link>
                                        </>
                                        }
                                        {/* end show anniversary offer */}

                                        <br />
                                        {/* <Link to="../../dealers" className="button-find-dealer">{t("find_dealer")}</Link> */}
                                    </div>
                                }

                                {/* if product end of life show alert product not vailable
                                in all other cases show Find Dealer and Order button */}
                                <div>
                                    {data.endOfLife ? 
                                        <div className="alert">
                                            <FontAwesomeIcon icon={faExclamationTriangle} /> 
                                            <p>{t("product_no_longer_available")}</p>
                                        </div>
                                    : 
                                        <div>
                                          {/* dealer button */}
                                          <Link to="../../dealers" className="button-find-dealer">{t("find_dealer")}</Link> 
                                          
                                          {/* order button */}
                                          {data.type !== "night" &&
                                            <a href={`mailto:orders@lahoux.nl?subject=${t("order_email.subject_order")}%3A%20${data.title[i18n.language]}%20(${data.number})&body=${t("order_email.body_opening")}%0A%0A${t("order_email.body_copy_1")}%0A%0A${t("order_email.body_product_title")}${data.title[i18n.language]}%20(${data.number})%0A%0A${t("order_email.body_details_heading")}%0A%0A${t("order_email.body_details_name")}%0A${t("order_email.body_details_address")}%0A${t("order_email.body_details_zip")}%0A${t("order_email.body_details_city")}%0A${t("order_email.body_details_country")}%0A${t("order_email.body_details_phone")}%0A${t("order_email.body_details_email")}%0A%0A${t("order_email.body_thanks")}%0A%0A`}>{t("specs_order_button")}</a>
                                          }
                                        </div>
                                    }                         
                                </div>

                                <div className="longtext">
                                    {data.longDes_1 &&  <h2>{t("specs_description")} </h2>}
                                    {data.longDes_1 && <p>{data.longDes_1[i18n.language]}</p>}
                                    {data.longDes_2 && <p>{data.longDes_2[i18n.language]}</p>}
                                    {data.longDes_3 && <p>{data.longDes_3[i18n.language]}</p>}
                                    {data.longDes_4 && <p>{data.longDes_4[i18n.language]}</p>}

                                    {data.leveromvang && 
                                    
                                    <div>
                                        <h2>{t("specs_leveromvang")}</h2>
                                        <p>{DataLeveromvang
                                        .filter(item => data.leveromvang.includes(item.id))
                                        .map(item => (
                                            item.desc[i18n.language]
                                        ))
                                        
                                        .join(", ")}.</p>
                                    </div>
                                }

                                    {data.pros &&                             
                                        <div>
                                            <h2>{t("specs_voordelen")}</h2>
                                            <ul>
                                                {data.pros[i18n.language].map((item, index) => ( 
                                                <li key={index}>{item}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    }

                                    { data.important_info &&
                                        <div>
                                            <h2>{data.important_info[i18n.language]}</h2>
                                            <p>{data.important_info_Des_1[i18n.language]}</p>
                                            <ul>
                                                {data.important_info_list[i18n.language].map((item, index) => (
                                                    <li key={index}>{item}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    }
                                
                                
                                </div>
                                {data.options &&
                                    <div className="options-night-container">
                                        <p className="explain-nv-price">{t("specs_price")}</p>
                                        <p>{t("specs_color")}</p>
                                        <div className="optionsNight">
                                        <label className={color === "Groen" ? "active" : ""}>{t("specs_groen")}
                                                <input type="radio" onChange={handleColor} checked={color==="Groen"} value="Groen"/>
                                                </label>
                                                <label className={color === "Onyx" ? "active" : ""}>Onyx
                                                <input type="radio" onChange={handleColor} checked={color==="Onyx"} value="Onyx"/>
                                                </label>
                                        </div>
                                        <p>{t("specs_fom_voor")} {data.title[i18n.language]} {t("specs_fom")}</p>
                                        <div className="optionsNight">
                                            {data.url === "Lahoux-LVS-7" && 
                                            <div>
                                                <label className={fom === "1200" ? "active" : ""}>Minimum: 1200
                                                    <input type="radio" onChange={handleFom} checked={fom==="1200"} value="1200"/>
                                                </label>
                                                <label className={fom === "1400" ? "active" : ""}>Minimum: 1400
                                                    <input type="radio" onChange={handleFom} checked={fom==="1400"} value="1400"/>
                                                </label>
                                                <label className={fom === "1500" ? "active" : ""}>Minimum: 1500
                                                    <input type="radio" onChange={handleFom} checked={fom==="1500"}  value="1500"/>
                                                </label>
                                            
                                            </div>
                                            }  
                                            {(data.url === "Lahoux-LVS-14" || data.url === "Lahoux-Hemera" || data.url === "Lahoux-LV-81" || data.url === "Lahoux-D-545" || data.url === "Lahoux-LVS-31") &&  
                                            
                                                <div>
                                                    <label className={fom === "1200" ? "active" : ""}>Minimum: 1200
                                                        <input type="radio"  onChange={handleFom} checked={fom==="1200"} value="1200"/>
                                                    </label>
                                                    <label className={fom === "1400" ? "active" : ""}>Minimum: 1400
                                                        <input type="radio" onChange={handleFom} checked={fom==="1400"} value="1400"/>
                                                    </label>
                                                    <label className={fom === "1500" ? "active" : ""}>Minimum: 1500
                                                        <input type="radio" onChange={handleFom} checked={fom==="1500"}  value="1500"/>
                                                    </label>
                                                    <label className={fom === "1700" ? "active" : ""}>Minimum: 1700
                                                        <input type="radio" onChange={handleFom} checked={fom==="1700"}  value="1700"/>
                                                    </label>
                                                    <label className={fom === "1750" ? "active" : ""}>Minimum: 1750
                                                        <input type="radio" onChange={handleFom} checked={fom==="1750"}  value="1750"/>
                                                    </label>
                                                    <label className={fom === "1800" ? "active" : ""}>Minimum: 1800
                                                        <input type="radio" onChange={handleFom} checked={fom==="1800"}  value="1800"/>
                                                    </label>
                                                    <label className={fom === "1900" ? "active" : ""}>Minimum: 1900
                                                        <input type="radio" onChange={handleFom} checked={fom==="1900"}  value="1900"/>
                                                    </label>
                                                    <label className={fom === "1950" ? "active" : ""}>Minimum: 1950
                                                        <input type="radio" onChange={handleFom} checked={fom==="1950"}  value="1950"/>
                                                    </label>
                                                </div>
                                            }
                                            
                                        </div>
                                        <p>{t("specs_spots_selection")}</p>
                                        <div className="optionsNight">
                                            <label className={spots === "veel" ? "active" : ""}> {t("specs_spots_veel")}
                                                <input type="radio" onChange={handleSpots} checked={spots==="veel"} value="veel"/>
                                            </label>
                                            <label className={spots === "weinig" ? "active" : ""}> {t("specs_spots_weinig")}
                                                <input type="radio" onChange={handleSpots} checked={spots==="weinig"} value="weinig"/>
                                            </label>
                                            <label className={spots === "geen" ? "active" : ""}> {t("specs_spots_geen")}
                                                <input type="radio" onChange={handleSpots} checked={spots==="geen"}  value="geen"/>
                                            </label>
                                        </div>
                                        <div>
                                            <p className="price"><span className="amount">€ {Math.round((Number(data.options[color][fom][spots].price)*correctionPercentage*(1+clientVat/100)))}</span> <span className="vat">({t("vat_incl")}{clientVat}% {t("vat")} {t(clientCountry)})</span></p>
                                            <p>{t("specs_artNum")}: {data.options[color][fom][spots].number}</p>
                                            <br />
                                            {/* if product end of life show alert product not vailable
                                            in all other cases show Find Dealer and Order button */}
                                            <div>
                                              {data.endOfLife ? 
                                                  <div className="alert">
                                                      <FontAwesomeIcon icon={faExclamationTriangle} /> 
                                                      <p>{t("product_no_longer_available")}</p>
                                                  </div>
                                              : 
                                                  <div>
                                                    {/* dealer button */}
                                                    <Link to="../../dealers" className="button-find-dealer">{t("find_dealer")}</Link>

                                                    {/* order button */}
                                                    <a href={`mailto:orders@lahoux.nl?subject=${t("order_email.subject_order")}%3A%20${data.title[i18n.language]}%20(${data.options[color][fom][spots].number})&body=${t("order_email.body_opening")}%0A%0A${t("order_email.body_copy_1")}%0A%0A${t("order_email.body_product_title")}${data.title[i18n.language]}%20(${data.options[color][fom][spots].number})%0A${t("order_email.body_product_color")}${color}%0A${t("order_email.body_product_FOM")}${fom}%0A${t("order_email.body_product_spot")}${spots}%0A%0A${t("order_email.body_details_heading")}%0A%0A${t("order_email.body_details_name")}%0A${t("order_email.body_details_address")}%0A${t("order_email.body_details_zip")}%0A${t("order_email.body_details_city")}%0A${t("order_email.body_details_country")}%0A${t("order_email.body_details_phone")}%0A${t("order_email.body_details_email")}%0A%0A${t("order_email.body_thanks")}%0A%0A`}>{t("specs_order_button")}</a>
                                                  </div>
                                              }                         
                                            </div>
                                        </div>
                                        
                                        {/* show anniversary offer nightvision */}
                                        {data.jubilee_offer && 
                                        <>
                                            <p className="jubilee-offer">
                                                {i18n.language === "nl" ? (
                                                    <img src={RozetNL} alt={imgAltTextRozet[i18n.language]} className="rozet-img" />
                                                ) : i18n.language === "de" ? (
                                                    <img src={RozetDE} alt={imgAltTextRozet[i18n.language]} className="rozet-img" />
                                                ) : (
                                                    <img src={RozetEN} alt={imgAltTextRozet[i18n.language]} className="rozet-img" />
                                                )}
                                                {t("jubilee.offer")}
                                            </p>
                                            <Link to="/lahoux-jubileum" className="button-jubilee-offer">{t("jubilee.offer_button")}</Link>
                                        </>
                                        }
                                        {/* end show anniversary offer nightvision */}
                                    </div>
                                }
                            </div>

                        </div>

                        {data.videos && 
                          <div className="videos">
                            {Object.keys(data.videos[i18n.language]).map(video => (
                              <>
                                <VideoElement video={"/productafbeeldingen/"+video} />
                                <p className="caption">{data.videos[i18n.language][video]}</p>
                              </>
                            ))}
                          </div>
                        }
                        
                        {data.photography && 
                            <div className="photography">

                                {Object.keys(data.photography[i18n.language]).map(photo => (
                              <>
                                <img src={"/productafbeeldingen/"+photo} loading="lazy" />
                                <p className="caption">{data.photography[i18n.language][photo]}</p>
                              </>
                            ))}
                        </div>  
                        }   
                    
                        {data.techs &&
                            <div className="product-specs">
                                <div className="techs">
                                    <h2>{t("specs_tech")}</h2>
                                    {data.flyer&& 
                                    <a href={"/productflyers/"+ data.flyer[i18n.language]} download > <FontAwesomeIcon icon={faDownload} />{t("specs_download_flyer")}</a>
                                    }
                                </div>
                                <div className="specs">
                                    <div>

                                        <div>{Object.keys(data.techs).map((key) => <div key={key}><h3>{key}</h3><ul>{Object.keys(data.techs[key][i18n.language]).map(spec => (
                                            <li key={spec}><p>{data.techs[key][i18n.language][spec]}</p><p>{spec}</p></li>
                                        ))}</ul></div>)}</div>
                                    </div>
                                </div>
                                { data.footnote_battery &&
                                    <p className="footnote"><span>* </span>{t("specs_footnote_battery")}</p>
                                }
                            </div>
                        }
                        {data.type === "night" && 
                            <p className="disclaimer-spots">{t("specs_disclaimer_spots")}</p>
                        }
                        {data.dri &&
                            <div className="dri">
                                <h2>{t("specs_dri")}</h2>
                                <img src={"/dri/" + data.dri} alt={t("specs_dri")} className="driImage" loading="lazy" />
                            </div>
                        }

                        {data.accessories &&
                            <div>
                                <h2>{t("specs_accessoires")}</h2>
                                <div className="products-cards"> 
                                {data.accessories.map( item => (
                                    <LinkProduct key={item} id={item}/>
                                )

                                )}
                                </div>
                            </div>
                        }
                        {data.related &&
                            <div>
                                <h2>{t("specs_related")}</h2>
                                <div className="products-cards">
                                {data.related.map( item => (
                                    <LinkProduct key={item} id={item}/>
                                )

                                )}</div>
                        
                            </div>
                        }
                        {data.compatible &&
                            <div>
                                <h2>{t("specs_compatible")}</h2>
                                <div className="products-cards">
                                {data.compatible.map( item => (
                                    <LinkProduct key={item} id={item}/>
                                )

                                )}</div>
                        
                            </div>
                        }
                    </div>
                </div> 
            }
        </StyledSpecs>  
    )
}

const StyledSpecs = styled.div`
  max-width: 100vw;

  .productspec-container {
    padding: 20px;
    @media (min-width: 768px) {
      max-width: 80vw;
    }

    @media (min-width: 1200px) {
      max-width: 40vw;
    }
    margin: 100px auto 0 auto;
  }

  h1,
  h2 {
    border-bottom: 4px solid var(--brand-accent-color);
    display: inline-block;
    padding-bottom: 6px;
    color: #fff;
    font-weight: bold;
  }

  h1 {
    margin-bottom: 5px;
    font-size: 28px;
  }

  h2 {
    margin-top: 20px;
    margin-bottom: 12px;
    font-size: 20px;
  }

  p {
    font-size: 20px;
    line-height: 22px;
  }

  .productInfo h1 {
    margin-bottom: 22px;
  }

  .available-date {
    font-style: italic;
    font-size: 18px;
    margin-bottom: 20px;
    text-decoration: underline;
  }

  .subtext {
    font-size: 14px;
    color: #fff;
    line-height: 18px;
    margin-bottom: 15px;
  }

  .longtext {
    margin-bottom: 20px;
  }

  .longtext ul {
    padding-left: 22px;
  }

  .longtext li {
    color: #fff;
    line-height: 22px;
    font-size: 20px;
    padding-bottom: 2px;
  }

  .longtext li:before {
    content: "- ";
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  .longtext p:first-of-type {
    margin-bottom: 15px;
  }

  .longtext div {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .techs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #fff;
  }

  .techs a:link,
  .subtext a {
    color: var(--link);
    border: 1px solid var(--brand-accent-color);
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.5px;
  }

  .techs a:hover,
  .subtext a:hover {
    background-color: var(--brand-accent-color);
  }

  .techs a:hover svg {
    color: #fff;
  }

  a:link,
  a:visited,
  a:hover,
  a:active {
    color: var(--link);
    text-decoration: none;
    border: 1px solid var(--brand-accent-color);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 50px;
    display: inline-block;
  }

  .active,
  label:hover {
    background-color: var(--brand-accent-color);
  }

  a svg {
    color: var(--brand-accent-color);
    margin-right: 5px;
  }

  .specs {
  }

  .specs li {
    list-style: none;
    width: 80vw;
    margin-bottom: 10px;
    @media (min-width: 768px) {
      min-width: 30vw;
    }

    @media (min-width: 1024px) {
      width: 13vw;
    }
  }

  .specs h3 {
    border-bottom: 1px solid #c1b8b8;
    font-size: 24px;
    margin-bottom: 5px;
  }

  .specs li p:last-of-type {
    font-size: 14px;
  }

  .specs ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    @media (min-width: 768px) {
      flex-wrap: wrap;
    }

    @media (min-width: 1024px) {
      flex-wrap: wrap;
    }
  }

  .options-night-container {
    margin-top: 40px;
  }

  .explain-nv-price {
    margin-bottom: 20px;
  }

  .optionsNight label {
    padding: 10px;
    font-size: 20px;
    border: 1px solid var(--brand-accent-color);
    display: block;
    border-radius: 10px;
    margin-top: 10px;
    margin-right: 10px;
    text-align: center;
    @media (min-width: 422px) {
      margin-right: 15px;
    }
  }

  .optionsNight li:first-of-type {
    border: 4px solid var(--brand-accent-color);
  }

  .optionsNight,
  .optionsNight div {
    display: flex;
    flex-wrap: wrap;
  }

  .optionsNight {
    margin-bottom: 40px;
  }

  .optionsNight input {
    visibility: hidden;
  }

  .optional {
    overflow: hidden;
    color: #fff;
  }

  .lens {
    max-width: 100%;
  }

  .price {
    margin-bottom: 14px;
  }

  .price .amount {
    font-weight: bold;
    margin-right: 6px;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--brand-accent-color);
  }

  .price .vat {
    font-size: 14px;
    font-weight: normal;
  }

  .article-number,
  .ean-code {
    font-size: 17px;
  }

  .subtext .button-find-dealer {
    margin-bottom: 30px;
    margin-right: 20px;
  }

  .optional h3 {
    font-weight: bold;
    margin-top: 20px;
    border-bottom: 2px solid var(--brand-accent-color);
    margin-bottom: 5px;
    display: inline-block;
    padding-bottom: 6px;
    color: #fff;
  }

  .dri {
    margin-bottom: 80px;
  }

  .driImage {
    max-width: 100%;
  }

  .FXoWx .sliderimg {
    height: 100% !important;
  }
  
  .products-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;
  }

  .video-element,
  .photography img {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .videos .video-element, .photography img {
    margin-bottom: 6px;

    @media (min-width: 720px) {
      max-width: 80%;
    }
  }

  .videos .caption, .photography .caption {
    font-size: 16px;
    font-style: italic;
    margin-bottom: 40px;
  }

  .alert {
    padding: 1rem 1rem;
    margin-bottom: 2rem;
    border: 1px solid #fff;
    font-style: italic;
    line-height: 1.2;
    display: flex;
    align-items: center;
    border-radius: 12px;
  }

  .alert svg {
    margin-right: 16px;
    color: var(--brand-accent-color);
    font-size: 32px;
  }

  .warning {
    margin-bottom: 22px;
  }

  .jubilee-offer {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 6px;
  }

  .jubilee-offer img {
    width: 130px;
    transform: rotate(-12deg);
    display: inline-block;
  }

  .subtext .button-jubilee-offer {
    margin-bottom: 30px;
  }
  .product-specs {
    margin-bottom: 15px;
  }

  .footnote {
    font-size: 16px;
  }

  .disclaimer-spots {
    font-size: 13px;
    font-style: italic;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .pricing-block.product-unavailable .price,
  .pricing-block.product-unavailable .article-number,
  .pricing-block.product-unavailable .ean-code {
    opacity: 0.4;
  }
`;
export default ProductSpecs