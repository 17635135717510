import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faSortAmountDown } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faCompress } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const SelectFilter = ({searchValue, setSearchValue, showItems, statusFilter, changeHandlerStatusFilter}) => {
  const { t } = useTranslation();
  const path = window.location.pathname;
  const [showFilter, setShowFilter] = useState(false);
  const animateFilterPane = {animation: "animate-filter 1s ease-out"};

  const handleClearSearchField = () => {
    setSearchValue("");
  }

  // const handleKeyPress = (e) => {
  //   e.preventDefault()
  //   if (e.key === "Enter") {
  //     console.log("Enter pressed")
  //     setShowFilter(false);
  //   }
  // };

/*
  useEffect(() => {
    const screenWidth = window.innerWidth;
    const breakPoint = 400;
    if (screenWidth < breakPoint) {
      setShowFilter(false)
    } else {
      setShowFilter(true)
    };
  }, []);
*/

  return (
    <StyledSelectShow>
      {!showFilter ? (
        <button
          className="filterContainer notShowFilter"
          onClick={(event) => setShowFilter(true)}
          style={!showFilter ? animateFilterPane : null}
        >
          <FontAwesomeIcon icon={faSearch} />
        </button>
      ) : (
        <div className="filterContainer showFilter" style={showFilter ? animateFilterPane : null}>
          <button onClick={(event) => setShowFilter(false)}>
            {" "}
            {t("filter_hide")} <FontAwesomeIcon icon={faCompress} />
          </button>

          {/* --- Search field --- */}
          <div className="filterLabel">
            <FontAwesomeIcon icon={faSearch} />
            <h3>{t("filter_search_heading")}</h3>
          </div>
          <div className="search-field-container">
            <input
              size="30"
              id="productSearch"
              type="search"
              value={searchValue}
              placeholder={t("filter_search_placeholder")}
              onChange={(event) => setSearchValue(event.target.value)}
              // onKeyUp={handleKeyPress}
            />
            <div onClick={handleClearSearchField} className="clear-button"><FontAwesomeIcon icon={faTimesCircle} /></div>
          </div>

          {/* --- Sorting options --- */}
          <div className="filterLabel">
            <FontAwesomeIcon icon={faSortAmountDown} />
            <h3>{t("filter_sort_heading")}</h3>
          </div>
          {/* Sort price */}
          <label>
            <input
              onChange={changeHandlerStatusFilter}
              id="sortUp"
              type="radio"
              name="sortPrice"
              value="sortUp"
              checked={statusFilter.sortPrice === "sortUp"}
            />
            {t("filter_sort_price_low_high")}
          </label>
          <label>
            <input
              onChange={changeHandlerStatusFilter}
              id="sortdown"
              type="radio"
              name="sortPrice"
              value="sortDown"
              checked={statusFilter.sortPrice === "sortDown"}
            />
            {t("filter_sort_price_high_low")}
          </label>
          <label>
            <input
              onChange={changeHandlerStatusFilter}
              id="sortdown"
              type="radio"
              name="sortPrice"
              value="geen"
            />
            {t("filter_sort_clear")}
          </label>

          {/* --- Filter options with radio selections --- */}
{/*
          <div className="filterLabel">
            <FontAwesomeIcon icon={faFilter} />
            <h3>{t("filter_options_heading")}</h3>
          </div>
          <label>
            <input
              onChange={changeHandlerStatusFilter}
              id="filterType"
              type="radio"
              checked={statusFilter.filterType === "voorzet"}
              name="filterType"
              value="voorzet"
            />
            voorzet
          </label>
          <label>
            <input
              onChange={changeHandlerStatusFilter}
              id="filterType"
              type="radio"
              checked={statusFilter.filterType === "handheld"}
              name="filterType"
              value="handheld"
            />
            handheld
          </label>
          <label>
            <input
              onChange={changeHandlerStatusFilter}
              id="filterType"
              type="radio"
              checked={statusFilter.filterType === "no preference"}
              name="filterType"
              value="no preference"
            />
            Clear choise
          </label>
*/}

          {/* --- Filter options with checkbox selections --- */}
          <div className="filterLabel">
            <FontAwesomeIcon icon={faFilter} />
            <h3>{t("filter_options_heading")}</h3>
          </div>
          {/* Filter New */}
          <label>
            <input
              onChange={changeHandlerStatusFilter}
              id="filterType"
              type="checkbox"
              checked={statusFilter.filterNew}
              name="filterNew"
            />
            {t("new")}
          </label>
          {/* Filter observatie */}
          <label>
            <input
              onChange={changeHandlerStatusFilter}
              id="filterType"
              type="checkbox"
              checked={statusFilter.filterObservation}
              name="filterObservation"
            />
            {t("observation")}
          </label>
          {/* Filter voorzet */}
          <label>
            <input
              onChange={changeHandlerStatusFilter}
              id="filterType"
              type="checkbox"
              checked={statusFilter.filterVoorzet}
              name="filterVoorzet"
            />
            {t("front_attachment")}
          </label>
          {/* Filter riflescope */}
          <label>
            <input
              onChange={changeHandlerStatusFilter}
              id="filterType"
              type="checkbox"
              checked={statusFilter.filterRiflescope}
              name="filterRiflescope"
            />
            {t("riflescope")}
          </label>
          {/* Filter thermal */}
          <label>
            <input
              onChange={changeHandlerStatusFilter}
              id="filterType"
              type="checkbox"
              checked={statusFilter.filterThermal}
              name="filterThermal"
            />
            {t("thermal_imaging")}
          </label>
          {/* Filter nightvision */}
          <label>
            <input
              onChange={changeHandlerStatusFilter}
              id="filterType"
              type="checkbox"
              checked={statusFilter.filterNightvision}
              name="filterNightvision"
            />
            {t("nightvision")}
          </label>
          {/* Filter digital */}
          <label>
            <input
              onChange={changeHandlerStatusFilter}
              id="filterType"
              type="checkbox"
              checked={statusFilter.filterDigital}
              name="filterDigital"
            />
            {t("digital")}
          </label>
        </div>
      )}
    </StyledSelectShow>
  );
};

const StyledSelectShow = styled.div`
  .filterContainer {
    position: fixed;
    left: 0;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    background-color: grey;
    width: 300px;
    height: max-content;
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    display: flex;
    flex-direction: column;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }
  .notShowFilter {
    width: max-content;
    padding: 8px;
    bottom: 20px;

    @media (min-width: 768px) {
      padding: 12px;
      top: 72%;
    }

    @media (min-width: 1200px) {
      padding: 18px;
    }
  }

  .notShowFilter:hover {
    background-color: #fc751b;
  }

  .showFilter {
    bottom: 20px;
    /* @media (min-width: 768px) {
      top: 42%;
    } */
  } 

  .filterContainer input {
    margin-top: 10px;
    margin-right: 7px;
    padding: 4px;
  }

  .filterContainer input:focus-visible {
    outline: 1px solid #fc751b;
  }

  .filterContainer .filterLabel {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  .filterContainer svg {
    background-color: #fc751b;
    font-size: 25px;
    margin-right: 10px;
    border: 1px solid white;
    padding: 5px;
    border-radius: 50%;
    color: #fff;
  }

  .filterContainer label {
    display: block;
    cursor: pointer;
    margin-left: 14px;
  }

  .showFilter button {
    background-color: grey;
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    align-self: flex-end;
  }

  .showFilter button svg {
    margin-left: 5px;
    min-width: 25px;
  }

  .notShowFilter {
    border: none;
    background-color: grey;
    cursor: pointer;
    outline: none;
  }

  .notShowFilter svg {
    margin-right: 0;
    font-size: 32px;
    padding: 7px;

    @media (min-width: 768px) {
      font-size: 34px;
    }

    @media (min-width: 1200px) {
      font-size: 38px;
    }
  }

  .filterContainer .search-field-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 240px;
  }

  .filterContainer #productSearch {
    width: 220px;
    padding-right: 28px;
  }

  .filterContainer .clear-button {
    margin-top: 11px;
    position: absolute;
    right: 14px;
  }

  .filterContainer .clear-button svg {
    color: #808080;
    background-color: transparent;
  }

  /* remove standard html cross "X" icon from input search field, not compatible with all browsers (e.g. Firefox) */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { 
    display: none; 
  }

  @keyframes animate-filter {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
        
`;
export default SelectFilter;
