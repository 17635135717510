import { useEffect, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";

const BackToTopButton = () => {
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);

  useEffect(() => {
    const pageOffset = () => {
      if (window.pageYOffset > 1200) {
        setShowBackToTopButton(true);
      } else {
        setShowBackToTopButton(false);
      }
    };

    window.addEventListener("scroll", pageOffset);

    return () => window.removeEventListener("scroll", pageOffset);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 200,
      behavior: "smooth",
    });
  };

  return (
    <StyledBackToTopButton>
      {showBackToTopButton && (
        <button onClick={scrollToTop} className="back-to-top">
          <FontAwesomeIcon icon={faArrowCircleUp} />
        </button>
      )}
    </StyledBackToTopButton>
  );
};

const StyledBackToTopButton = styled.div`
  .back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    outline: none;
    cursor: pointer;
    background: transparent;
    border: none;
    animation: fadeIn 2.5s ease-in-out;
  }

  .back-to-top svg {
    font-size: 52px;
    color: #fff;
    padding: 7px;
  }

  .back-to-top svg:hover {
    color: var(--brand-accent-color);
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export default BackToTopButton;
