import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CookieBanner = ({ clickHandlerCookieBanner }) => {
  const { t } = useTranslation();

  return (
    <StyledCookieBanner>
      <div className="cookie-container">
        <div className="cookie-copy">
          {/* <h1>{t("cookie_h1")}</h1> */}
          <p>{t("cookie_p")} <Link to="/cookieverklaring"><strong>{t("menu_cookieverklaring")}</strong></Link>.</p>
        </div>
        <div className="cookie-buttons">
          <button
            onClick={() => {
              clickHandlerCookieBanner();
            }}
          >
            {" "}
            {t("cookie_consent")}
          </button>
        </div>
      </div>
    </StyledCookieBanner>
  );
};

const StyledCookieBanner = styled.div.attrs({
  className: "cookie-banner",
})`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--brand-color-light);
  padding: 10px 20px;
  font-size: 14px;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.3) 0px -10px 20px;
  /* animation: fadeIn 1.2s ease-in-out; */

  .cookie-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .cookie-copy {
    max-width: 80%;
  }
  .cookie-copy h1 {
    margin-bottom: 20px;
    font-weight: bold;
  }
  .cookie-copy p {
    line-height: 1.3;
  }
  .cookie-copy a {
    color: var(--text)
  }
  .cookie-copy a:hover {
    color: var(--brand-accent-color)
  }
  button {
    border: none;
    cursor: pointer;
    background-color: var(--brand-accent-color);
    color: var(--text);
    padding: 16px;
    margin-left: 14px;
    font-size: 1rem;
    border-radius: 8px;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
export default CookieBanner;
