import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToTop = () => {
	const location = useLocation();

	useEffect(() => {
		if (location.pathname !== "/producten") {
			window.scrollTo(0, 0);
		}
	}, [location]);

	return null;
};