import React from "react"
import DataVacatures from "./DataVacatures"
import { useParams } from "react-router-dom";
import styled from 'styled-components';
import {Helmet} from "react-helmet";
import PageNotFound from "./PageNotFound";

export const vacatureArray = url => {
    return DataVacatures.find(item => {
        return item.url === url;
    })
}

function VacatureDetail() {
    const { url } = useParams()
    const data = vacatureArray(url)
    
  

    return (
        <StyledSpecs>
            {data === undefined ? <PageNotFound /> :
            <>
            <Helmet>
                <title>Vacancy {data.title}</title>
                <meta name="description" content={`Vacancy ${data.title} at Lahoux Optics`} />
            </Helmet>
            
                <div className="vacatureInfo">
                    <h1> {data.title}</h1>
                    <div className="vacatureText">
                        <div className="subText">
                            <h2>{data.title}</h2>
                            <p>Standplaats: {data.city}</p>
                            <p>Dienstverband:{data.hours}</p>
                            <p>Referentienummer: {data.number}</p>
                            {data.date&& <p>Sluitingsdatum: {data.date} </p>}
                        </div>
                        <div className="content">
                            <p>{data.p1}</p>
                            <p>{data.p2}</p>
                            <p>{data.p3}</p>
                            <ul className="dashed">
                                {data.bullits1.map(item => (
                                    <li>{item}</li>
                                ))}
                            </ul>
                            <p>{data.p4}</p>
                            <p>{data.p5}</p>

                            <h2>Functievereisten</h2>
                            <ul className="dashed">
                                {data.eisen.map(item=>(
                                    <li>{item}</li>
                                    ))}
                            </ul>
                            <h2>Arbeidsvoorwaarden</h2>
                            <ul className="dashed">
                                {data.voorwaarden.map(item=>(
                                    <li>{item}</li>
                                ))}
                            </ul>
                        </div>
                    </div>   
                    <div className="cards">
                        <div className="contact-card">
                            <div><h3>Vragen?</h3>
                            <p>Neem contact op met  Clémentine Smit.</p>
                            </div>
                            <div>
                                <p>Tel: <a href="tel:0031235514678">+31 (0) 23 5514 678</a></p>
                                <p>of</p>
                                <p>Mail: <a href="mailto:solliciteren@lahoux.nl">solliciteren@lahoux.nl</a></p>
                            </div>
                            <p>Acquisitie wordt niet op prijs gesteld.</p>
                        </div>
                        <div className="contact-card">
                            <div>
                            <h3>Solliciteren op deze functie?</h3>
                                <p>Stuur ons jouw korte motivatie en cv per email t.a.v. Clémentine Smit. Vermeld hierbij het referentienummer: {data.number}</p>
                            </div>
                            <p>Mail: <a href="mailto:solliciteren@lahoux.nl">solliciteren@lahoux.nl</a></p>
                            
                            <p>Acquisitie wordt niet op prijs gesteld.</p>
                        </div>
                    </div>
                </div>
            </>
            }
        </StyledSpecs>
    )
}
const StyledSpecs = styled.div`
 max-width: 100vw;
 padding: 20px;
 @media (min-width: 768px) {
    max-width: 80vw;
    
  }

  @media (min-width: 1400px) {
    max-width: 40vw;
  }
 margin: 60px auto 0 auto;
    h1  {
        border-bottom: 3px solid #FC751B;
        display: inline-block;
        padding-bottom: 6px;
        font-size: 28px;
        font-weight: bold;
        
    }
    h1{
        margin-bottom: 5px;
    }
    h2, .subText h2, .contact-card h3{
        border-bottom: 3px solid #FC751B;
        display: inline-block;
        font-weight: bold;
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 5px;
    }
    .vacatureText .subText{
        font-size: 15px;
        margin-bottom: 40px;
    }
    .vacatureText .subText p{
        margin-bottom: 0px;
    }
    .vacatureText, .contact-card {
        border: 1px solid grey;
        margin-top: 20px;
        padding: 0 20px 20px 20px;
        font-size: 17px;
    }
    .cards{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (min-width: 540px) {
            flex-direction: row;  
        }
    }

    .contact-card{
        min-height: 250px;
        min-width: 230px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (min-width: 540px) {
            width: 49%;  
        }
    }
    .contact-card p{
        margin-bottom: 10px;    
    }
    .contact-card p a{
        text-decoration: none;
        color: white;
        font-weight: bold;
    }
    .contact-card p:last-of-type{
        margin-bottom: 0px;
    }
    
    .vacatureText ul {
        margin-left: 15px;
      }
      ul.dashed {
        list-style-type: none;
      }
      ul.dashed > li {
        text-indent: -10px;
        margin-bottom: 8px;
      }
      ul.dashed > li:before {
        content: "- ";
        text-indent: -10px;
      }

    .vacatureText p{
        margin-bottom: 10px;
    }
 
    .hero {
        margin-top: 0;
    }

    `
export default VacatureDetail