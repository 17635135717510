import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const DealerItem = ({ dealer }) => {
  const { t, i18n } = useTranslation();

  return (
    <StyledDealerItem>
      <div className="dealer__img">
        <a href={dealer.url} target="_blank" rel="noopener noreferrer">
          <img src={"/dealers/" + dealer.logo} alt="dealer logo" />
        </a>
      </div>
      <div className="dealer__info">
        {dealer.type ? <h2>{dealer.type[i18n.language]}</h2> : null}
        <h3>{dealer.name}</h3>
        <p>{dealer.city}</p>
        <p>
          <a href={dealer.url} target="_blank" rel="noopener noreferrer">
            {dealer.website}
          </a>
        </p>
        <p>{dealer.email}</p>
        <p>{dealer.phone}</p>
      </div>
    </StyledDealerItem>
  );
};

const StyledDealerItem = styled.li.attrs({
  className: "dealer",
})`
  border: 1px solid var(--brand-accent-color);
  width: 350px;

  img {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .dealer__img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    background-color: #fff;
    /* background-color: rgb(193, 184, 184); */
  }

  .dealer__info {
    padding: 20px;
    line-height: 24px;
  }

  .dealer__info h2 {
    font-size: 1.2rem;
    padding-bottom: 5px;
    text-decoration: underline;
  }

  .dealer__info h3 {
    font-size: 1.1rem;
    padding-bottom: 5px;
  }

  .dealer__info a {
    color: #fff;
  }
`;

export default DealerItem;
