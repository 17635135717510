const DataLeveromvang = [

	{
		id: 1,
		desc: {
			en: "Carrying bag",
			de: "Tasche",
			nl: "Tas",
		}
		   
	},
	{
		id: 2,
		desc: {
			en: "Pouch",
			de: "Aufbewarungstasche",
			nl: "Tasje",
		}
		   
	},
	{
		id: 3,
		desc: {
			en: "Lens cloth",
			de: "Linsentuch",
			nl: "Lensdoekje",
		}
		   
	},
	{
		id: 4,
		desc: {
			en: "User manual",
			de: "Gebrauchsanleitung",
			nl: "Gebruiksaanwijzing",
		}
		   
	},
	{
		id: 5,
		desc: {
			en: "Carrying strap",
			de: "Umhängeband",
			nl: "Draagriem",
		}
		   
	},		
	{
		id: 6,
		desc: {
			en: "Luxury Carrying strap",
			de: "Luxuriöser umhängeband",
			nl: "Luxe draagriem",
		}
		   
	},
	{
		id: 7,
		desc: {
			en: "USB Cable with AC-adapter",
			de: "USB-Kabel mit Netzadapter",
			nl: "USB-kabel met AC-Adapter",
		}
		   
	},

	{
		id: 8,
		desc: {
			en: "Videocable",
			de: "Videokabel",
			nl: "Videokabel",
		}
		   
	},	
	{
		id: 9,
		desc: {
			en: "Charger + USB Cable",
			de: "Ladegeraet + USB Kabel",
			nl: "Lader + USB kabel",
		}
		   
	},	
	{
		id: 10,
		desc: {
			en: "USB-C Cable",
			de: "USB-C Kabel",
			nl: "USB-C kabel",
		}
		   
	},	
	
	
	{
		id: 11,
		desc: {
			en: "2x AA Batteries",
			de: "2x AA Batterien",
			nl: "2x AA Batterijen",
		}
		   
	},	
	{
		id: 12,
		desc: {
			en: "1x CR-123 Battery",
			de: "1x CR-123 Batterie",
			nl: "1x CR-123 Batterij",
		}
		   
	},	
	{
		id: 13,
		desc: {
			en: "2x CR-123 Battery",
			de: "2x CR-123 Batterie",
			nl: "2x CR-123 Batterij",
		}
		   
	},	
	{
		id: 14,
		desc: {
			en: "Battery for remote control CR2032",
			de: "Batterie für Fernbedienung CR2032",
			nl: "Batterij voor afstandsbediening CR232",
		}
		   
	},	
	{
		id: 15,
		desc: {
			en: "Battery CR2",
			de: "Batterie CR2",
			nl: "Batterij CR2",
		}
		   
	},	
	{
		id: 16,
		desc: {
			en: "18650 Battery",
			de: "18650 Akku",
			nl: "18650 Accu",
		}
		   
	},
	{
		id: 17,
		desc: {
			en: "18500 Battery",
			de: "18500 Akku",
			nl: "18500 Accu",
		}
		   
	},	
	{
		id: 18,
		desc: {
			en: "Reducing ring",
			de: "Einschiebering",
			nl: "Inschuifring",
		}
		   
	},	

	{
		id: 19,
		desc: {
			en: "Lens cap",
			de: "Linsenkappe",
			nl: "Lens kap",
		}
		   
	},	
	{
		id: 20,
		desc: {
			en: "Eye rubber",
			de: "Augengummi",
			nl: "Oogrubber",
		}
		   
	},
	{
		id: 21,
		desc: {
			en: "Rubber eyepiece",
			de: "Okulargummi",
			nl: "Oculair rubber",
		}
		   
	},	
	{
		id: 22,
		desc: {
			en: "Remote control",
			de: "Fernbedienung",
			nl: "Afstandsbediening",
		}
		   
	},	
	{
		id: 23,
		desc: {
			en: "Adapter ring",
			de: "Gewindeadapterring",
			nl: "Adapterring",
		}
		   
	},	
	{
		id: 24,
		desc: {
			en: "Adapter",
			de: "Adapter",
			nl: "Adapter",
		}
		   
	},
	{
		id: 25,
		desc: {
			en: "Monitor",
			de: "Monitor",
			nl: "Monitor",
		}
		   
	},
	{
		id: 26,
		desc: {
			en: "Tripod",
			de: "Stativ",
			nl: "Statief",
		}
		   
	},
	{
		id: 27,
		desc: {
			en: "Drone",
			de: "Drohne",
			nl: "Drone",
		}
		   
	},
	{
		id: 28,
		desc: {
			en: "Powerbank",
			de: "Powerbank",
			nl: "Powerbank",
		}
		   
	},
	{
		id: 29,
		desc: {
			en: "4x Lahoux heat pad",
			de: "4x Lahoux Wärmepad",
			nl: "4x Lahoux warmtekussen",
		}
		   
	},
	{
		id: 30,
		desc: {
			en: "2x Lahoux heat pad",
			de: "2x Lahoux Wärmepad",
			nl: "2x Lahoux warmtekussen",
		}
		   
	},
	{
		id: 31,
		desc: {
			en: "Pouch Lahoux",
			de: "Aufbewarungstasche Lahoux",
			nl: "Tasje Lahoux",
		}
		   
	},
	{
		id: 32,
		desc: {
			en: "LV-8 adapter",
			de: "LV-8-Adapter",
			nl: "LV-8-adapter",
		}   
	},
	{
		id: 33,
		desc: {
			en: "LV-8/E adapter",
			de: "LV-8/E-Adapter",
			nl: "LV-8/E-adapter",
		}
	},
	{
		id: 34,
		desc: {
			en: "Battery compartment Extender BFE 2",
			de: "Batteriefach Extender BFE 2",
			nl: "Batterijvak Extender BFE 2",
		}
		   
	},
	{
		id: 35,
		desc: {
			en: "2x 16650 Battery",
			de: "2x 16650 Batterie",
			nl: "2x 16650-Batterij",
		}
		   
	},



    
];
export default DataLeveromvang;