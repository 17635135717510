import React, {useEffect} from "react";
import styled from "styled-components";
import Seo from "./Seo";
import DataEvents from "./DataEvents";
import { useTranslation } from "react-i18next";
import "../locales/i18n";
import EventItem from "./EventItem";

const EventsAll = ({showItems, setShowItems}) => {
  const { t, i18n } = useTranslation();
  const currentDate = new Date();

  let dd = currentDate.getDate();
  let mm = currentDate.getMonth() + 1;
  let yyyy = currentDate.getFullYear();

  // add leading zero if day and month are below 10
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  let today = yyyy + "-" + mm + "-" + dd;

  useEffect(() => {
    setShowItems("events");
  }, []);

  const currentEvents = DataEvents.filter((event) => event.date >= today)

  // make description meta tag in Helmet get translatable content
  const helmetDescription = {
    en: "Lahoux Optics is present at most important exhibitions at home and abroad, giving you the opportunity to try our innovative night vision and thermal imaging cameras yourself.",
    nl: "Lahoux Optics is aanwezig op de belangrijkste beurzen in binnen- en buitenland, zodat u onze innovatieve nachtzicht- en warmtebeeldcamera's zelf kunt uitproberen.",
    de: "Lahoux Optics ist auf den wichtigsten Messen im In- und Ausland vertreten und bietet Ihnen die Möglichkeit, unsere innovativen Nachtsicht- und Wärmebildkameras selbst zu testen.",
  };

  return (
    <StyledEventsAll>
        <Seo 
          title={t("events_title")}
          description={helmetDescription[i18n.language]} 
          url="https://lahouxoptics.com/evenementen"
          image="https://lahouxoptics.com/evenement/iwa.png" />

      <span>
        {showItems === "events" && 
          <h1>{t("events_title")}</h1>
        }
        {showItems === "archive" && 
          <h1>{t("events_archive_title")}</h1>
        }
      </span>
      <div className="cards">
        <ul>
          {showItems === "events" && 
            <>
              {currentEvents.length > 0 ? 
                currentEvents
                  .map((event) => (
                    <EventItem event={event} key={event.id} />
                  ))
                :
                <li>{t("events_none")}</li>
              }
            </>
          }

          {showItems === "archive" && 
            <>
              {DataEvents
                .filter((event) => event.date < today)
                .map((event) => (
                  <EventItem event={event} key={event.id} />
                ))
              }
            </>
          }

        </ul>
      </div>

    </StyledEventsAll>
  );
};

const StyledEventsAll = styled.div`
  max-width: 92vw;
  margin: 60px auto 0 auto;
  font-size: 20px;

  @media (min-width: 934px) {
    max-width: 84vw;
  }

  @media (min-width: 1024px) {
    max-width: 74vw;
  }

  @media (min-width: 1180px) {
    max-width: 64vw;
  }

  @media (min-width: 1582px) {
    max-width: 48vw;
  }

  h1 {
    font-size: 23px;
    font-weight: bold;
    border-bottom: 4px solid #fc751b;
    display: inline-block;
    padding-bottom: 6px;
    margin-bottom: 20px;
  }
  img {
    width: 100%;
    height: auto;
    padding: 20px;
    background-color: white;
  }
  .cards ul {
    display: flex;
    flex-direction: column;
  }
  .cards ul a {
    border: 1px solid #fc751b;
    margin-bottom: 20px;
    text-decoration: none;
    color: white;
  }
  .cards ul a:active,
  .cards ul a:visited {
    color: white;
  }

  .cards ul a li {
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    overflow: hidden;

    @media (min-width: 550px) {
      flex-direction: row;
    }
  }
  .eventImg {
    width: 100%;
    overflow: hidden;

    @media (min-width: 550px) {
      width: 30%;
    }
  }
  .cards ul a li .eventText {
    min-height: 250px;
    padding: 20px;

    background-color: #323522;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 550px) {
      width: 70%;
    }
  }

  .eventText div p {
    font-size: 18px;
  }

  .cards ul a li div:hover {
    background-color: #fc751b;
  }
  .cards ul a li div:hover svg {
    color: white;
  }
  .cards .button {
    background-color: #fc751b;
    padding: 10px;
    border-radius: 5px;
  }
  .cards ul a li div:hover .button {
    background-color: white;
    color: #fc751b;
  }
  .cards h2 {
    font-size: 1.2rem;
    padding-bottom: 5px;
    font-weight: bold;
  }
  .cards .eventInfo p {
    margin-bottom: 10px;
    line-height: 24px;
  }

  svg {
    color: #fc751b;
    margin-right: 5px;
  }
  .cards {
    margin-bottom: 60px;
  }
  .cards .eventInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-top: 10px;
    flex-wrap: wrap;

    @media (min-width: 550px) {
      flex-wrap: nowrap;
    }
  }
  .cards .eventInfo div {
    max-width: 100%;

    @media (min-width: 550px) {
      max-width: 50%;
    }
  }
`;
export default EventsAll;
