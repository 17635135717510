export const TRANSLATIONS_DE = {
    lang_de: "DE",
    lang_nl: "NL",
    lang_en: "EN",
    menu_home: "Home",
    menu_producten: "Produkte",
    menu_producten_nacht: "Nachtsicht",
    menu_producten_warm: "Wärmebild",
    menu_producten_acces: "Zubehör",
    menu_producten_digi: "Digital",
    menu_producten_overig: "Mehr Produkte ansehen",
    menu_techniek: "Technik",
    menu_over: "Über uns",
    menu_over_history: "Geschichte",
    menu_over_jubileum: "30 Jahre angebot", 
    menu_over_how: "Wie wir arbeiten",
    menu_over_vacatures: "Stellenangebote",
    menu_over_events: "Messe / Events",
    menu_events: "Messe",
    menu_events_archive: "Archiv",
    menu_service:"Service",
    menu_dealers: "Händler",
    menu_woorden: "Glossar",
    menu_cookieverklaring: "Cookie-Richtlinie",
    menu_privacy: "Datenschutz-Bestimmungen",
    between:"Innovation in Nachtsicht",
    betweenbutton: "Wähle dein Ziel",
    betweenoption1: "Jagd",
    betweenoption2: "Birding",
    betweenoption3: "Behörden",
    betweenoption4: "Händlerportal",
    betweenoption5: "schließen",
    footer_volg: "Folge uns",
    footer_rights: "Alle Rechte vorbehalten",
    footer_price: "Alle Preise sind unverbindliche Preisempfehlungen inkl. MwSt.",
    footer_disclaimer: "Alle Informationen auf dieser Website können ohne Vorankündigung geändert werden. Die Lahoux-Produkte werden ständig verbessert, um die bestmögliche Benutzererfahrung zu gewährleisten.",
    home_title: "Geben Sie Ihren Augen außergewöhnliche Kraft",
    home_copy: "Mit dem Wärmebildgerät Lahoux Spotter eröffnet sich Ihnen eine neue Welt. Noch nie war es so einfach, sich im Dunkeln oder bei schlechter Sicht am Tag einen Überblick über die Welt um uns herum zu verschaffen. Mit Hilfe der Wärmebildtechnik lassen sich alle Wärmequellen, Tiere, Menschen und Gegenstände sowohl bei Tag als auch bei Nacht leicht auffinden. ",
    home_button: "Weiterlesen",
    home_article_title: "Scharfes Sehen, auch bei Dunkelheit",
    home_article_copy: "Mit den Produkten von Lahoux Optics können Sie Tiere im Dunkeln finden und alle nächtlichen Aktivitäten aufzeichnen. Mit einem Wärmebildbetrachter können Sie zum Beispiel Wild im Feld aufspüren oder nachtaktive Tiere zählen. Mit dem detaillierten Bild der Wärmebildkameras von Lahoux Optics werden selbst kleine Merkmale sichtbar und viele Tiere können sogar angesprochen werden.",
    home_article_button: "Entdecken Sie unsere Modelle",
    home_article2_title: "Lahoux Optics 30 Jahre",
    home_article2_copy: "30 Jahre Lahoux Optics, ein Meilenstein auf den wir stolz sind und den wir mit Ihnen feiern. Anlässlich unseres 30-jährigen Jubiläums bieten wir unseren Kunden in diesem Jahr eine Reihe von Rabattaktionen an.",
    home_article2_button: "Unsere Jubiläumsangebote ansehen",
    tech_intro_title: "Lahoux Optics",
    tech_intro_copy: "Wärmebildbetrachter haben sich für diejenigen, die die Vorteile dieser Technik kennengelernt haben, als unverzichtbar erwiesen. Sowohl am Tag als auch in der Nacht verleihen die Zuschauer der Naturbeobachtung eine zusätzliche Dimension. Sobald irgendwo ein Temperaturunterschied gemessen werden kann, macht die Kamera diesen sichtbar. Die Kamera erfasst also nur die Wärmestrahlung. Mit Restlichtverstärkern und digitalen Betrachtern wird das (sichtbare) Licht, das in der Nacht noch vorhanden ist, zu einem perfekt nutzbaren Bild verstärkt, das die Nacht fast zum Tag macht. Das Bild ist also viel näher an dem, was wir gewohnt sind. ",
    tech_page_title: "Technik",
    tech_nacht_1: "Das gestochen scharfe Bild unserer Nachtsichtgeräte ermöglicht es Ihnen, die meisten Tiere und Wildtiere nach Einbruch der Dunkelheit und bei schwachem Mondlicht zu beobachten und anzuvisieren.",
    tech_nacht_2: "Das klassische Nachtsichtgerät, auch Helligkeits- oder Restlichtverstärker genannt, nutzt das vorhandene Licht, das in der Nacht noch vorhanden ist, und verstärkt es zu einem hervorragend verwertbaren Bild. Die Nacht wird fast so hell wie der Tag.",
    tech_nacht_3: "Der Betrachter nutzt Mond- und Sternenlicht, Wolkenreflexionen von künstlichen Lichtquellen und sogar natürliche Infrarotstrahlung.",
    tech_nacht_4: "Letzteres ermöglicht es auch, mit Hilfe eines Infrarotstrahlers ein Bild in völliger Dunkelheit zu erhalten.",
    tech_nacht_5: "Herzstück dieser Technologie ist eine kleine, analoge Restlichtverstärkerröhre, die die wenigen Lichtteilchen über eine ultraempfindliche Schicht in Elektronen umwandelt, die unter Hochspannung vervielfacht und über einen phosphoreszierenden Bildschirm für unser Auge sichtbar gemacht werden.",
    tech_nacht_6: "Für die Phosphorbeleuchtung werden das bekannte Grün und eine schwarz/weiße Version namens Onyx verwendet. ",
    tech_nacht_7: "Da es sich um ein sehr empfindliches System handelt, ist es wichtig, das Gerät nur im Dunkeln zu benutzen und die Rohre tagsüber nicht dem Licht auszusetzen.",
    tech_nacht_8: "Lahoux Optics arbeitet seit Jahrzehnten mit dem niederländisch/französischen Röhrenhersteller Photonis zusammen und verwendet ausschließlich neue Röhren dieses Marktführers, um Ihnen ausschließlich hochwertige Produkte anbieten zu können.",
    tech_nacht_button: "Weiter zu Nachtsichtgeräte",
    tech_warm_1: "Mit der Wärmebildtechnik bleiben auch Tiere, die sich hinter nicht zu dichtem Gebüsch verstecken, nicht verborgen. Sogar Vögel wie Krähen, die in der Nähe des Stammes sitzen, oder Vögel an anderen Stellen in Bäumen können auf diese Weise gefunden werden.",
    tech_warm_2: "Nicht nur auf freiem Feld, sondern auch im Wald ist ein Wärmebild ein unverzichtbares Hilfsmittel. Von Mäusen und kleinen Vögeln über Eichhörnchen, Wühlmäuse, Füchse bis hin zu größeren Huftieren wird Ihnen nichts entgehen. Dadurch können Wärme oder Bewegung auch auf mehrere hundert Meter Entfernung, selbst im Wald, erkannt werden. ",
    tech_warm_3: "Ein wichtiger Punkt beim Einsatz der Wärmebildtechnik sind die Wetterbedingungen. Wenn die Luftfeuchtigkeit gering ist, ist die Leistung besser als bei einem Regenschauer. Dies wirkt sich auf die Reichweite aus. Der große Vorteil ist, dass ein warmes Objekt im Sichtfeld deutlich hervorsticht.",
    tech_warm_4: "Es dauert einige Zeit, bis der Benutzer die Bilder richtig interpretieren kann, da das Bild nur aus Wärme besteht und viele Objekte und Tiere anders aussehen als wir es gewohnt sind. So bleibt der Nutzer fasziniert, neue Dinge zu entdecken. ",
    tech_warm_5: "Indem sie einen kleinen Teil der uns umgebenden Infrarotstrahlung nutzen, können (ungekühlte) Wärmebildkameras den Temperaturbereich von Lebewesen sichtbar machen. Dies geschieht auf rein digitale Weise, so dass mit der Bildverarbeitung in der Kamera viel getan werden kann, um die Strahlung für den Benutzer besser sichtbar zu machen.",
    tech_warm_6: "Darüber hinaus kann das Bild digital vergrößert und oft auch aufgezeichnet werden. Die ungekühlte Wärmebildkamera hat den Preis und die Benutzerfreundlichkeit dieser Technik für viele Zielgruppen zugänglich gemacht. Aufgrund ihres hohen Preises sind die noch empfindlicheren und komplexeren gekühlten Kameras nur für Behörden und einige professionelle Nutzer erhältlich. ",
    tech_warm_7: "Die meisten Modelle verfügen über 4 verschiedene Farbmodi, um bei fast allen Wetterbedingungen ein gutes Bild zu gewährleisten.",
    tech_warm_button: "Weiter zu Wärmebildkameras",
    tech_digi_1: "Neben der klassischen Nachtsichttechnik verfügt Lahoux Optics auch über digitale Restlichtverstärker.",
    tech_digi_2: "Dabei handelt es sich um sehr empfindliche CCD-/Kamerasensoren, die mit den Sensoren von Digitalkameras vergleichbar sind und daher auch tagsüber verwendet werden können. Für eine gute Sicht unter den dunkelsten Bedingungen wird ein Infrarotstrahler benötigt, den Lahoux Optics ebenfalls in seinem Sortiment hat. ",
    tech_digi_3: "Der große Vorteil sind die geringeren Kosten, so dass diese Technik für viele sehr zugänglich ist.  ",
    tech_digi_button: "Weiter zu digital-kameras",
    history_page_title: "Geschichte",
    history_copy_title: "Geschichte",
    history_copy_1: "Nachtsichttechnik hat in den Niederlanden eine lange Tradition. Schon 1934 wurde sie mit der Erfindung der ersten Bildverstärkerröhre (Restlicht) von Philips begründet. Seit den 60er Jahren sind die Niederlande eine der führenden Nationen in der Produktion von Bildverstärkerröhren. 1970 startete PHOTONIS in Roden (Niederlande) mit der Produktion von Bildverstärkerröhren, inzwischen einer der größten Hersteller von Bildverstärkerröhren in der Welt.",
    history_copy_2: "Lahoux Optics wurde 1992 gegründet. Damals lieferte man optische Komponenten an holländische und belgische Nachtsichtgerätehersteller. Kurze Zeit später übernahm Lahoux die Distribution von PHOTONIS Bildverstärkerröhren für einige Länder. Ein wichtiger Schritt für Lahoux Optics war der Beginn der Entwicklung eigener Nachtsicht- und Wärmebildgeräte. ",
    history_copy_3: "Zur Zeit ist Lahoux Optics eines der wenigen Unternehmen in Westeuropa, die eigene high-end Nachtsicht- und Wärmebildgeräte für den zivilen Markt entwickeln und produzieren. Lahoux high-end Nachtsicht- und Wärmebildgeräte werden komplett in Holland entwickelt, gefertigt und montiert. Lahoux Geräte zeichnen sich durch kompromisslose Qualität aus. ",
    history_copy_4: "Neben der hauseigenen Produktion bietet Lahoux Optics Geraete an aus einigen Werken womit wir schon lange eng zusammen arbeiten. Lahoux Optics steht für schnellen und erstklassigen Service.",
    work_page_title: "Wie wir arbeiten",
    work_1_title: "Staubfrei",
    work_1_copy: "Der wichtigste Faktor bei der Montage einer hochwertigen Lahoux Nachtsichtoptik, wie beispielsweise das Lahoux LV-81 oder das Lahoux Hemera, ist eine absolut staubfreie und reine Laboratmosphäre. Die Reinigung der Linsen hat höchste Priorität. Schon kleinste Verunreinigungen werden bei der Qualitätskontrolle entdeckt und das Gerät wird aussortiert.",
    work_2_title: "Montage",
    work_2_copy: "Unsere Mitarbeiter lieben die Lahoux Produkte. Daher achten sie bei der Montage auf maximale Präzision. Eine Präzision, die Sie bei der Jagd dann erleben. Geringste Treffpunktverlagerung, maximale Wiederkehrgenauigkeit.",
    work_3_title: "Qualitätskontrolle",
    work_3_copy: "Jedes Lahoux Gerät durchläuft die strenge Qualitätskontrolle. Hier wird nochmals jede Funktion und Maßhaltigkeit geprüft. Nur Geräte die die Normen zu 100 Prozent erfüllen, verlassen die Fertigung. ",
    work_4_title: "Hochpräzise",
    work_4_copy: "Wir setzen hochpräzise Kollimationssysteme in der Qualitätskontrolle für die maximale Präzision unserer Lahoux Vorsatzgeräte ein. Darauf können Sie sich beim Schuss voll vertrauen. ", 
    work_5_title: "ISO zertifiziert",
    work_5_copy: "Unser ISO 9001.2015-Zertifikat (auf Anfrage erhältlich) ist Ihre Garantie dafür, dass wir unsere Versprechen in Bezug auf Qualität und Service einhalten. Alle unsere Geschäftsprozesse sind nach den strengen ISO-Normen für das Qualitätsmanagement geprüft worden. Dieses Zertifikat wird jedes Jahr durch ein unabhängiges Audit von SGS, der führenden Zertifizierungsstelle für Unternehmen, erneut bestätigt. So können Sie sich jetzt und in Zukunft auf unsere kompromisslose Produktqualität und unseren Service verlassen, egal ob Sie Lieferant, Kunde oder sonstiger Geschäftspartner sind.", 
    events_title: "Messe",
    events_archive_title: "Archiv",
    events_none: "Derzeit sind keine Veranstaltungen geplant.",
    events_button: "Mehr Informationen",
    service_title: "Servicebeleid",
    service_copy_1: "Bei normalem Gebrauch ist Ihr Lahoux Gerät viele Jahre wartungsfrei. Wenn Sie als Endbenutzer ein Problem mit Ihrem Lahoux Gerät haben, wenden Sie sich bitte an Ihren Fachhändler.",
    service_copy_2: "Sie sind Händler und haben ein nicht richtig funktionierendes Lahoux Gerät? Bitte ",
    service_copy_button: "wenden",
    service_copy_3: " Sie sich an die Serviceabteilung von Lahoux Optics.",
    service_choise_title: "Distributor oder Händler in Ihrem Land",
    service_choise: "Wähle dein Land", 
    specs_description: "Beschreibung",
    specs_voordelen: "Merkmale",
    specs_warning: "*Warnung",
    specs_warning_p: "Bitte beachten Sie die gesetzlichen Bestimmungen in ihrem jeweiligen Bundesland beziehungsweise Land.",
    specs_leveromvang: "Wird standardmäßig mit folgendem Zubehör geliefert:",
    specs_tech: "Technische Spezifikationen", 
    specs_accessoires: "Verfügbares Zubehör für dieses Produkt",
    specs_dri: "DRI Werte",
    specs_related: "Verwandte Produkte",
    specs_compatible: "Passend für",
    specs_new: "Neu",
    specs_footnote_battery: "Abhängig von den Einsatzbedingungen",
    specs_artNum: "Artikelnummer",
    specs_color: "Nachtsicht ist in grün oder schwarz-weiß (Onyx) erhältlich.",
    specs_groen: "Grün",
    specs_fom_voor: "Der",
    specs_fom: "ist erhältlich mit unterschiedenen FOM Werten.",
    specs_spots_selection: "Wählen Sie die Spots-Kategorie.",
    specs_spots_veel: "Erlaubt",
    specs_spots_weinig: "Einige",
    specs_spots_geen: "Fast keine", 
    specs_price: "Der Preis ist für jede Farb-/FOM-/Spots-Kombination unterschiedlich. Wählen Sie die Kombination, die am besten zu Ihnen passt.",
    specs_download_flyer: "Download Produktbroschüre PDF",
    specs_disclaimer_spots: "Haftungsausschluss: Eventuell vorhandene kosmetische Fehler oder Spots in der Bildverstärkerröhre beinträchtigen weder die Leistung noch die Zuverlässigkeit des Gerätes. Diese auftretenden Beeinträchtigungen sind dem Herstellungsverfahren inhärent. Röhren der Kategorie, B und A haben weniger Spots als eine Röhren der Kategorie C.",
    specs_order_button: "Bestellen",
    vat_incl: "inkl.",
    vat: "MwSt",
    from: "Ab",
    for: "Für",
    new: "Neu",
    find_dealer: "Händler finden",
    products_page_title: "Produkte",
    product_detail: "Produktdetails", 
    product_no_longer_available: "Dieses Produkt ist nicht länger verfügbar",
    no_products_found:"Es wurden keine Produkte gefunden",
    all_products: "Alle Produkte",
    thermal_imaging: "Wärmebild",
    nightvision: "Nachtsicht",
    digital: "Digital",
    front_attachment: "Vorsatzgerät",
    observation: "Beobachtung",
    riflescope: "Zielfernrohr",
    filter_hide: "Verstecken",
    filter_search_heading: "Finden Sie Ihr Gerät",
    filter_search_placeholder: "Gerät suchen",
    filter_sort_heading: "Sortieren",
    filter_sort_price_low_high: "Preis von niedrig nach hoch",
    filter_sort_price_high_low: "Preis von hoch nach niedrig",
    filter_sort_clear: "Klare Eindeutige Sortierung",
    filter_options_heading: "Geräte filtern",
    contact_title: "Anschrift",
    contact_tel: "Telefon",
    contact_p: "Sie können unser Büro nur auf Einladung besuchen. Wir haben keinen Shop oder Showroom.",
    contact_mail: "E-Mail",
    contact_dealers: "Nur für Händler",
    dealers_page_title: "Händler",
    glossary_page_title: "Glossar",
    glossary_seo_title: "Glossar der Fachbegriffe für Wärmebildtechnik und Nachtsichtgeräte",
    events_copy_1: "Treffen Sie Lahoux ",
    events_copy_2: "In den vergangenen Jahrzehnten hat sich Lahoux auf Nachtsicht- und Wärmebildgeräte spezialisiert. Lahoux Optics ist auf allen relevanten Messen im In- und Ausland vertreten. Hier können Sie unsere innovativen Nachtsicht- und Wärmebildkameras live erleben und testen. Gerne beraten wir Sie und zeigen Ihnen die Möglichkeiten, die speziell für Ihre Outdoor- und Jagdsituation am besten geeignet ist. Wir freuen uns auf Ihren Besuch an unserem Messestand.",
    cookie_h1: "Lahoux Optics verwendet Cookies",
    cookie_p: "Lahoux Optics verwendet auf dieser Website funktionale und anonymisierte analytische Cookies. Wir setzen derzeit keine Cookies von Websites Dritter und sammeln keine personenbezogenen Daten von Besuchern. Weitere Informationen finden Sie in unserer",
    cookie_consent: "Schließen",
    Page_not_found: "Die gewünschte Seite existiert nicht mehr. Bitte gehen Sie zurück zur Startseite.",
    Netherlands: "Niederlande",
    Austria: "Österreich",
    Belgium: "Belgien",
    Bulgaria: "Bulgarien",
    Cyprus: "Zypern",
    Czech_republic: "Tschechien",
    Denmark: "Dänemark",
    Estonia: "Estland",
    Finland: "Finnland",
    France: "Frankreich",
    Germany: "Deutschland",
    Greece: "Griechenland",
    Hungary: "Ungarn",
    Ireland: "Irland",
    Italy: "Italien",
    Croatia: "Kroatien",
    Latvia: "Lettland",
    Lithuania: "Litauen",
    Luxembourg: "Luxemburg",
    Malta: "Malta",
    Poland: "Polen",
    Portugal: "Portugal",
    Romania: "Rumänien",
    Slovakia: "Slowakei",
    Sweden: "Schweden",
    United_kingdom: "Vereinigtes Königreich",

    order_email: {
        subject_order: "Bestellung",
        body_opening: "Sehr geehrter Kunde,",
        body_copy_1: "Wenn Sie diesen Artikel bestellen möchten, geben Sie bitte unten Ihre Daten ein und senden Sie diese E-Mail.",
        body_product_title: "Produkt: ",
        body_product_color: "Gewählte Farbe: ",
        body_product_FOM: "Gewählter FOM-Wert: ",
        body_product_spot: "Gewählte Spot-Kategorie: ",
        body_details_heading: "Bitte geben Sie Ihre Daten an:",
        body_details_name: "Name: ",
        body_details_address: "Versandadresse: ",
        body_details_zip: "Postleitzahl: ",
        body_details_city: "Stadt: ",
        body_details_country: "Land: ",
        body_details_phone: "Telefonnummer: ",
        body_details_email: "E-Mail: ",
        body_thanks: "Vielen Dank für Ihre Bestellung!",
    },

    imprint: {
        page_heading: "Impressum",
        contact_company_name: "", // "Lahoux Optics GmbH",
        contact_street: "", // "Wigandstaler Str. 23",
        contact_zip_city: "", // "13086 Berlin",
        contact_email: "Mail: info@lahouxoptics.de",
        contact_tel: "Tel.: +49 1639141308",
        contact_person: "", // "Geschäftsführer: Eduard Leinwand",
        contact_gericht: "", // "Registergericht: Amtsgericht Charlottenburg (Berlin)", 
        contact_coc: " ", // """Steuernummer: DE318608623",
        contact_vat: " ", //"VAT number: NL 822 492 374 B01",
        dispute_heading: "Alternative Streitbeilegung",
        dispute_text: "Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, aufrufbar unter http://ec.europa.eu/odr.",
        picture_credit_heading: "Bildnachweisverzeichnis",
        picture_credit_p1: "diverse Bilder: lizenzfrei auf 123rf.com",
        picture_credit_p2: "diverse Bilder: gemeinfrei CCO auf pixabay.com",
        disclaimer_heading: "Haftungsausschuss (Disclaimer)",
        liability_heading: "Haftungsbeschränkung",
        liability_p1: "Die auf dieser Webseite von Lahoux Optics erstellten oder vorgehaltenen Inhalte wurden mit größtmöglicher Sorgfalt erstellt. Dennoch übernimmt Lahoux Optics keine Gewähr für deren Aktualität, Vollständigkeit und Richtigkeit (weder zum Zeitpunkt der Veröffentlichung noch fortwährend danach). Als Diensteanbieter ist Lahoux Optics gemäß § 7 Abs. 1 TMG für eigene Inhalte und bereitgestellte Informationen nach den allgemeinen Gesetzen verantwortlich; nach den §§ 8 bis 10 TMG jedoch nicht verpflichtet, die übermittelten oder gespeicherten fremden Informationen zu überwachen. Eine Entfernung oder Sperrung dieser Inhalte erfolgt umgehend ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung. Eine Haftung ist erst ab dem Zeitpunkt der Kenntniserlangung möglich. Lahoux Optics weist darauf hin, dass die Übertragung von Daten im Internet (z. B. per E-Mail) Sicherheitslücken aufweisen und ein lückenloser Schutz der Daten vor dem Zugriff Dritter nicht gewährleistet werden kann; dementsprechend übernimmt Lahoux Optics diesbezüglich keine Haftung für solche Schäden, die einem Nutzer durch solche Sicherheitslücken entstehen.",
        linking_heading: "Verlinkung",
        linking_p1: "Diese Webseite kann „externe Links“ (Verlinkungen) zu anderen Webseiten enthalten. Lahoux Optics ist bemüht, die Rechtmäßigkeit der Inhalte bei Verlinkung zu überprüfen. Zum Zeitpunkt der Verlinkung waren keine Rechtsverstöße erkennbar. Bei Bekanntwerden einer solchen Rechtsverletzung wird der Link umgehend entfert. Indes hat Lahoux Optics auf den Inhalt oder sich ändernde Inhalte der verlinkten Webseiten keinen Einfluss; der Anbieter der entsprechend verlinkten Webseite ist hierfür allein verantwortlich und Lahoux Optics übernimmt daher keine Gewähr für die Richtigkeit oder Rechtmäßigkeit solcher Inhalte. Für die Inhalte und Richtigkeit der bereitgestellten Informationen ist der jeweilige Anbieter der verlinkten Webseite verantwortlich.",
        copyright_heading: "Urheberrecht/Leistungsschutzrecht",
        copyright_p1: "Die auf dieser Webseite veröffentlichten Inhalte, Werke und bereitgestellten Informationen unterliegen dem deutschen Urheberrecht und Leistungsschutzrecht. Jede Art der Vervielfältigung, Bearbeitung, Verbreitung, Einspeicherung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedarf der vorherigen schriftlichen Zustimmung des jeweiligen Rechteinhabers. Das unerlaubte Kopieren/Speichern der bereitgestellten Informationen auf diesen Webseiten ist nicht gestattet und strafbar. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten dennoch eine möglich Urheberrechtsverletzung erkannt werden, wird um einen entsprechenden Hinweis an Lahoux Optics gebeten.",
    },

    jubilee: {
        page_heading: "Lahoux Optics 30 Jahre",
        page_hero_copy: "30 Jahre Lahoux Optics, ein Meilenstein auf den wir stolz sind und den wir mit Ihnen feiern.",
        intro_article_p1: "30 Jahre Lahoux Optics, ein Meilenstein auf den wir stolz sind und den wir mit Ihnen feiern.",
        intro_article_p2: "Seit 30 Jahren liefert Lahoux Optics hochwertige Nachtsichtgeräte. 1992 wurde das Unternehmen in Haarlem/Niederlande gegründet. Hier befindet sich nach wie vor unser Stammsitz. Anfangs konzentrierten wir uns ausschließlich auf die Fertigung von Nachtsichtgeräten, die stets mit den bekannten und auch im Behördenbereich bewährten niederländischen Photonis-Restlichtverstärkern ausstattet wurden. Diese Technologie war damals noch revolutionär.",
        intro_article_p3: "Im Laufe der Jahre entwickelte sich die Wärmebildtechnologie zu einem weiterem innovativen Standbein, die Lahoux Optics für Zielfernrohre und Spotter nutzte.",
        intro_article_p4: "Auf der Grundlage beider Technologien haben wir Geräte für die Jagd und den Outdoor-Bereich entwickelt, darunter Nachtischtferngläser, Wärmebildspotter, Zielfernrohre und Dual-Use-Vorsatzgeräte.",
        intro_article_p5: "Durch den Unternehmenserfolg veranlasst, zogen wir 2012 an einen größeren Produktionsstandort in Haarlem um. Der neue Standort erlaubt, dass F&E, Produkttests, Produktion, Service und Vertrieb vernetzt zusammenarbeiten können. Hier konnten wir auch das neue Servicezentrum für den europäischen Markt etablieren.",
        intro_article_p6: "Seit 2018 hat Lahoux Optics eine Niederlassung in Berlin, um den wichtigen deutschen Markt vor Ort mit Unterstützung  der deutschen Kollegen optimal bedienen zu können.",
        intro_article_p7: "Im Jahr 2021 hat Lahoux das Qualitätssiegel ISO 9001 erworben. Das bedeutet, dass Lahoux Optics-Kunden und -Partner eine nachvollziehbare Gewähr für das implementierte Qualitätsmanagement haben. Diese ISO-Zertifizierung ist die Grundlage für die gleichbleibend hohe Qualität unserer Produkte.",
        intro_article_p8: "Anlässlich unseres 30-jährigen Jubiläums machen wir unseren Kunden in diesem Jahr mehrere Geschenke in Form von Rabattaktionen. Bitte informieren Sie sich regelmäßig auf unserer Website, in den sozialen Medien oder bei unseren Händlern über die verschiedenen Jubiläumsaktionen.",
        offer: "Lesen Sie mehr über unser spezielles Jubiläumsangebot für Sie!",
        offer_button: "Jubiläumsangebot!",
    },

    cookie_policy: {
        page_heading: "Cookie-Richtlinie",
        page_hero_copy: "",
        intro_p1: "Lahoux Optics verwendet auf dieser Website funktionale und analytische Cookies und Local Storage.",
        intro_p2: "Ein Cookie ist eine kleine Textdatei, die im Browser Ihres Computers, Tablets oder Smartphones gespeichert wird, wenn Sie diese Website von Lahoux Optics zum ersten Mal besuchen. Lahoux Optics verwendet Cookies mit rein technischer Funktionalität. Diese Cookies stellen sicher, dass die Website ordnungsgemäß funktioniert und merken sich beispielsweise Ihre bevorzugten Einstellungen. Die funktionalen Cookies sind notwendig, damit die Website ordnungsgemäß funktioniert und, um die Leistung zu optimieren.",
        intro_p3: "Darüber hinaus platzieren wir analytische Cookies. Unsere Analyse-Cookies enthalten keine personenbezogenen Daten und erheben auch keine. Sie werden verwendet, um anonyme Informationen über die Nutzung unserer Websites zu sammeln, um uns dabei zu helfen, unsere Dienste kontinuierlich zu verbessern.",
        uitschakelen_heading: "Cookies aktivieren und deaktivieren und löschen",
        uitschakelen_p1: "Sie können Cookies ablehnen, indem Sie Ihren Internetbrowser so einstellen, dass er keine Cookies mehr speichert. Darüber hinaus können Sie auch alle zuvor gespeicherten Informationen über Ihre Browsereinstellungen löschen.",
        uitschakelen_p2: "Weitere Informationen zum Aktivieren, Deaktivieren und Löschen von Cookies finden Sie in den Anweisungen Ihres Browsers und/oder über die Hilfefunktion Ihres Browsers.",
        analytics_heading: "Google Analytics",
        analytics_p1: "Über unsere Website wird ein Cookie von der amerikanischen Firma Google als Teil des Dienstes 'Analytics' platziert. Wir nutzen diesen Dienst, um Berichte darüber zu sammeln, wie Besucher die Website nutzen.  Wir verwenden diese Informationen, um Entscheidungen über die Gestaltung unserer Website zu treffen. Wir verwenden diese Informationen auch, um das Funktionieren der Website zu optimieren. Wir haben folgende Maßnahmen ergriffen, um eine sorgfältige Verarbeitung zu gewährleisten:",
        analytics_listitem1: "Wir haben eine Auftragsverarbeitungsvereinbarung mit Google abgeschlossen.",
        analytics_listitem2: "Die Informationen, die Google sammelt, werden so weit wie möglich anonymisiert. Ihre IP-Adresse ist ausdrücklich nicht angegeben.",
        analytics_listitem3: "Wir haben alle von Google angebotenen Optionen zum 'Teilen von Daten mit Google' deaktiviert.",
        analytics_listitem4: "Wir verwenden keine anderen Google-Dienste in Verbindung mit den Google Analytics-Cookies.",
        analytics_p2: "Google wird diese Informationen gegebenenfalls an Dritte weitergeben, sofern Google gesetzlich dazu verpflichtet ist oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Darauf haben wir keinen Einfluss. Wir haben Google nicht gestattet, die erhaltenen Analysedaten für andere Google-Dienste zu verwenden.",
        analytics_p3: "Die Informationen, die Google sammelt, werden so weit wie möglich anonymisiert. Ihre IP-Adresse wird ausdrücklich nicht angegeben.",
        local_storage_heading: "Local Storage",
        local_storage_p1: "Local storage ist eine Form der lokalen Datenspeicherung und dient dazu, dass die Website ordnungsgemäß funktioniert. Wir verwenden Local storage nur, um die von Ihnen gewählte Sprache der Website in Ihrem Browser zu speichern. Wir verwenden auch Local storage, um zu wissen, dass Sie das Cookie-Banner geschlossen haben, damit es bei Ihrem nächsten Besuch nicht mehr angezeigt wird.",
        session_storage_heading: "Session Storage",
        session_storage_p1: "Session Storage ist eine Form der temporären Speicherung von Daten und soll dazu dienen, dass die Website ordnungsgemäß funktioniert. Wir verwenden Session Storage nur, um Informationen über die gewählte Sprache der Website in Ihrem Browser zu speichern. Die Session Storage werden beim Schließen Ihres Browsers automatisch gelöscht.",
    },

    message_yearend_closing: {
        heading: "Jahresabschluss",
        p1: "Lahoux Optics ist von Samstag, 24. Dezember 2022 bis Sonntag, 1. Januar 2023 geschlossen.",
        p2: "Ab Montag, 2. Januar 2023 sind wir wieder geöffnet.",
    },
    
    jagd_und_hund: {
        page_heading: "Jagd & Hund Dortmund Deutschland 2023",
        page_hero_copy: "Europas größte Jagdmesse. Lahoux Optics wird auf der Jagd & Hund mit einem Stand in Halle 6, Standnummer H20, vertreten sein.",
        article_link_jagd_hund: "Die Jagd & Hund",
        article_p1: "ist Europas größte Jagdmesse und findet vom 24. bis 29. Januar 2023 auf dem Messegelände in Dortmund statt.",
        article_p2: "Lahoux Optics wird auf der Jagd & Hund mit einem Stand in Halle 6, Standnummer H20, vertreten sein.",
        article_p3: "Während der Messe gibt es tolle Sonderangebote. Weitere Informationen dazu finden Sie in Kürze auf dieser Seite.",
        article_p4: "Während der Messe gibt es tolle Sonderangebote, die Sie hier finden können:",
        home_title: "Jagd & Hund Dortmund Deutschland 2023",
        home_copy: "Jagd & Hund ist Europas größte Jagdmesse und findet vom 24. bis 29. Januar 2023 auf dem Messegelände in Dortmund statt. Lahoux Optics wird auf der Jagd & Hund mit einem Stand in Halle 6, Standnummer H20, vertreten sein. Während der Messe gibt es tolle Sonderangebote!",
        home_button: "Sehen Sie sich die Sonderangebote an",
   },

   hohe_jagd_und_fischerei: {
        page_heading: "Die Hohe Jagd & Fischerei Salzburg 2023",
        page_hero_copy: "Lahoux Optics wird auf Die Hohe Jagd & Fischerei mit einem Stand in Halle 10, Standnummer 0722, vertreten sein.",
        article_link_jagd_hund: "Die Hohe Jagd & Fischerei",
        article_p1: "findet vom 16. bis 19. Februar 2023 auf dem Messegelände in Salzburg statt.",
        article_p2: "Lahoux Optics wird auf Die Hohe Jagd & Fischerei mit einem Stand in Halle 10, Standnummer 0722, vertreten sein.",
        article_p3: "Während der Messe gibt es Sonderangebote. Weitere Informationen dazu finden Sie in Kürze auf dieser Seite.",
        article_p4: "Während der Messe gibt es Sonderangebote, die Sie hier finden können:",
        home_title: "Die Hohe Jagd & Fischerei Salzburg 2023",
        home_copy: "Die Hohe Jagd & Fischerei findet vom 16. bis 19. Februar 2023 auf dem Messegelände in Salzburg statt. Lahoux Optics wird auf Die Hohe Jagd & Fischerei mit einem Stand in Halle 10, Standnummer 0722, vertreten sein. Während der Messe gibt es Sonderangebote!",
        home_button: "Sehen Sie sich die Sonderangebote an",
}

   };