import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Seo = ({ title, description, url, image }) => {

  const { i18n } = useTranslation();

  // make default title meta tag in Helmet get translatable content
  const defaultTitle = {
    en: "Lahoux Optics - High Quality Night Vision, Thermal Imaging and Digital devices",
    nl: "Lahoux Optics - Hoge kwaliteit nachtzicht kijkers, warmtebeeld kijkers en digitale kijkers",
    de: "Lahoux Optics - Hochwertige Nachtsichtgeräte, Wärmebildgeräte und digitale Geräte",
  };

  return (
    <Helmet titleTemplate={"%s | " + defaultTitle[i18n.language]} defaultTitle={defaultTitle[i18n.language]}>
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* Open Graph meta tags for socials (Facebook/Linkedin etc) */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      {image ? <meta property="og:image" content={image} /> : null}

      {/* Twitter meta tags */}
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      {image ? <meta property="twitter:image" content={image} /> : null}
    </Helmet>
  );
};

export default Seo;
