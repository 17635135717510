import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Link } from "react-router-dom";

function ContentCarouselItem({ item }) {
  const { i18n } = useTranslation();

  const carouselImgFolder = "./content-carousel-images/";

  return (
    <StyledContentCarouselItem>
      <div className="carousel-item">
        {item.imgLang ? (
          <img
            src={carouselImgFolder + item.img[i18n.language]}
            alt={item.title[i18n.language]}
          />
        ) : (
          <img
            src={carouselImgFolder + item.img}
            alt={item.title[i18n.language]}
          />
        )}
        <div className="copy">
          <h1>{item.title[i18n.language]}</h1>
          <p>{item.copy_p1[i18n.language]}</p>
          <div className="links">
            {item.linkNewWindow ? (
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                {item.link_text[i18n.language]}
              </a>
            ) : (
              <Link to={item.url}>{item.link_text[i18n.language]}</Link>
            )}
          </div>
        </div>
      </div>
    </StyledContentCarouselItem>
  );
}

const StyledContentCarouselItem = styled.div`
  .carousel-item {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    @media (min-width: 768px) {
      margin-top: 20px;
      flex-wrap: wrap;
    }

    @media (min-width: 1024px) {
      margin-top: 50px;
      flex-wrap: no-wrap;
    }
  }

  .carousel-item img {
    width: 60vw;

    @media (min-width: 650px) {
      max-width: 350px;
      width: auto;
    }
  }

  .carousel-item .copy {
    max-width: 500px;
    flex-direction: column;
    margin-left: 40px;
    margin-right: 40px;
    align-items: stretch;
  }

  .carousel-item h1 {
    border-bottom: 2px solid #fc751b;
    padding-bottom: 6px;
    margin-bottom: 10px;
    display: inline-block;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    @media (min-width: 768px) {
      font-size: 32px;
    }

    @media (min-width: 1024px) {
      font-size: 32px;
    }
  }
  .carousel-item p {
    color: #fff;
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 22px;
    @media (min-width: 768px) {
      font-size: 22px;
      line-height: 24px;
    }
  }
  .carousel-item .links {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;
  }

  .carousel-item .links a {
    color: #fff;
    text-decoration: none;
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 10px;
    display: inline-block;
    margin: 0 20px 20px 0;
    font-size: 18px;

    /* @media (min-width: 400px) {
      font-size: 1.4em;
    } */
  }
  .carousel-item .links a:hover {
    background-color: #fc751b;
    color: #fff;
    border-color: #fc751b;
  }
`;
export default ContentCarouselItem;
