import React, {useEffect} from 'react';
import DataAboutus from './DataAboutus.js'
import styled from 'styled-components';
import Seo from "./Seo";
import VideoElement from './VideoElement';
import nachtzichtZwijnen from './../assets/nachtzicht-zwijnen-hemera-groen.png'
import thermalVideo from "./../assets/videos/thermal.mp4";
import digital from "./../assets/wild-zwijn-digitaal.jpg";
import { useTranslation } from "react-i18next";
import "../locales/i18n";
import {
    Link
} from "react-router-dom";



const Explanations = ({ showItems, setShowItems, clickHandlerShowItem }) => {
    const { t, i18n } = useTranslation();

    // check if showItems is event or archive or all
    // if so, set showItem to standard thermal on page load to prevent empty tab/list 
    // (because this page does not know of event or archive or accessories)
    useEffect(() => {
      if (showItems === "events" || showItems === "archive" || showItems === "accessories" || showItems === "all") {
          setShowItems("thermal");
      }
    }, []);

    // 10-05-2022 PP Add translatable alt-text for images
    const imgAltTextNight = {en: "Wild boar viewed through a night vision scope", nl: "Wilde zwijnen bekeken door een nachtzicht kijker", de: "Wildschwein durch Nachtsichtbrille gesehen"}
    const imgAltTextDigital = {en: "Wild boar viewed through a digital viewer", nl: "Wilde zwijnen bekeken door een digitale kijker", de: "Wildschwein durch einen digitalen Viewer betrachtet"}

    // make description meta tag in Helmet get translatable content
    const helmetDescription = {
      en: "Explanation of thermal imaging, night vision and digital",
      nl: "Uitleg van warntebeeld, nachtzicht en digitaal",
      de: "Erläuterung von Wärmebildern, Nachtsichtgeräten und digitalen",
   }; 

    return (
        <StyledExplanations>
          <Seo 
            title={t("tech_page_title")} 
            description={helmetDescription[i18n.language]}
            url="https://lahouxoptics.com/techniek"
            image={digital}
          />
                        
          {showItems === "thermal" &&
              <React.Fragment>
                  <br />
                  <div className="thermal">
                      <div className="content">
                          <p>{t("tech_warm_1")}</p>
                          <p>{t("tech_warm_2")}</p>
                          <p>{t("tech_warm_3")}</p>
                          <p>{t("tech_warm_4")}</p>
                          <p>{t("tech_warm_5")}</p>
                          <p>{t("tech_warm_6")}</p>
                          <p>{t("tech_warm_7")}</p>
                      </div>
                      <VideoElement video={thermalVideo} videoRoundClass={"video-round"} />
                  </div>
                  <div className="coloroverview">
                      <ul>
                          {DataAboutus.map(colormodi => (
                              <li key={colormodi.id}>
                                  <div className="content">
                                      <h2>{colormodi.title[i18n.language]}</h2>
                                      <p>{colormodi.desc[i18n.language]}</p>
                                  </div>
                                  <VideoElement video={"/videos/" + colormodi.video} videoRoundClass={"video-round"} />
                              </li>

                          ))}
                      </ul>
                  </div>
                  <Link to="/producten">{t("tech_warm_button")}</Link>
                  <br />
              </React.Fragment>
          }
          {showItems === "night" &&
              <React.Fragment>
                  <br />
                  <div className="nachtzicht">
                      <div className="content">
                          <p>{t("tech_nacht_1")}  </p>
                          <p>{t("tech_nacht_2")}  </p>
                          <p>{t("tech_nacht_3")}  </p>
                          <p>{t("tech_nacht_4")}  </p>
                          <p>{t("tech_nacht_5")}  </p>
                          <p>{t("tech_nacht_6")}  </p>        
                          <p>{t("tech_nacht_7")}  </p>
                          <p>{t("tech_nacht_8")}  </p>                         
                      </div>
                      <div className="preview">
                        <img src={nachtzichtZwijnen} alt={imgAltTextNight[i18n.language]} loading="lazy" />
                      </div>
                  </div>
                  <Link to="/producten">{t("tech_nacht_button")}</Link>
              </React.Fragment>
          }
            {showItems === "digital" &&
                <React.Fragment>
                <br />
                <div className="digital">
                    <div className="content">
                        <p>{t("tech_digi_1")}  </p>
                        <p>{t("tech_digi_2")}  </p>
                        <p>{t("tech_digi_3")}  </p>                 
                    </div>
                    <div className="preview">
                        <img src={digital} alt={imgAltTextDigital[i18n.language]} loading="lazy" />
                    </div>
                </div>
                <Link to="/producten">{t("tech_digi_button")}</Link>
            
            </React.Fragment>
          }
        </StyledExplanations>
    )
}

const StyledExplanations = styled.div`
  margin: 10px auto 0 auto;
  max-width: 90vw;
  font-size: 20px;
  @media (min-width: 768px) {
    max-width: 70vw;
  }

  @media (min-width: 1024px) {
    max-width: 60vw;
  }

  p {
    max-width: 100%;
    margin-bottom: 20px;
  }

  p:last-of-type {
    margin-bottom: 50px;
  }

  .coloroverview ul li {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;

    @media (min-width: 768px) {
      flex-wrap: nowrap;
    }

    @media (min-width: 1024px) {
      flex-wrap: nowrap;
    }
  }

  .coloroverview ul li .video-element .video-round {
    width: 220px;
    height: 220px;
    min-width: 220px;
    @media (min-width: 344px) { 
      width: 250px;
      height: 250px;
    }
  }

  .coloroverview li .content {
    width: 500px;
    margin-right: 60px;
  }

  .coloroverview .content h2 {
    border-bottom: 2px solid var(--brand-accent-color);
    display: block;
    font-size: 22px;
    padding-bottom: 6px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .coloroverview {
    border-left: 4px solid var(--brand-accent-color);
    padding-left: 20px;
    margin-left: 50px;
  }

  a {
    color: var(--link);
    text-decoration: none;
    border: 1px solid var(--brand-accent-color);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 50px;
    display: inline-block;
  }

  a:hover {
    background-color: var(--brand-accent-color);
  }

  .nachtzicht,
  .thermal,
  .digital {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1200px) {
      flex-wrap: wrap;
      margin-bottom: 30px;
    }
  }
  
  .preview img {
    border-radius: 50%;
    width: 350px;
  }

  .digital .preview img {
    width: 305px;
  }

  .preview, .video-element {
    @media (max-width: 1200px) {
      margin: 0 auto;
    }
  }
`;
export default Explanations