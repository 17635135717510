const DataAboutus = [
	{
		id: 2,
		title: { 
			en: "White hot",
			nl: "White hot",
			de: "Weißglut",
			}	,
		desc: {
			en: "The most commonly used setting. Anything hot is displayed in white or shades of gray. White stands out well for detecting.",
			nl: "De meest algemeen gebruikte setting. Alles wat warm is wordt in wit of grijstinten weergegeven. Wit valt voor detecteren goed op.",
			de: "Die am häufigsten verwendete Einstellung. Alles, was heiß ist, wird in weißen oder grauen Farbtönen dargestellt. Weiß fällt bei der Erkennung auf.",
		},
        video: "whitehot.mp4"      
	},
	{
		id: 3,
		title: { 
			en: "Black hot",
			nl: "Black hot",
			de: "Schwarz heiß",
			}	,
		desc: {
			en: "The negative of white hot. Anything hot is rendered in dark or black tones. The image resembles that of a black and white monitor and often works well under freezing conditions. Depending on distance and some conditions, individual features may not be seen as well.",
			nl: "Het negatief van white hot. Alles wat warm is wordt in donkere of zwarte tinten weergegeven. Het beeld lijkt op dat van een zwart-wit monitor en werkt vaak goed onder vriezende omstandigheden. Afhankelijk van de afstand en sommige omstandigheden zijn individuele kenmerken minder goed te zien.",
			de: "Das Negativ von White Hot. Alles, was heiß ist, wird in dunklen oder schwarzen Tönen wiedergegeben. Das Bild ähnelt dem eines Schwarz-Weiß-Monitors und funktioniert oft gut bei Frost. Je nach Entfernung und bestimmten Bedingungen können einzelne Merkmale weniger gut sichtbar sein.",
		},
		
        video: "blackhot.mp4"      
	},
	{
		id: 1,
		title: { 
			en: "Red hot",
			nl: "Red hot",
			de: "Rotglühend",
			}	,
		desc: {
			en: "Similar to white hot. Anything hot has gray or white tones. The parts that, compared to the white parts, are even hotter are depicted red or yellowish. Especially under difficult conditions with lots of bush and reeds, a mammal or bird stands out well. ",
			nl: "Vergelijkbaar met white hot. Alles wat warm is heeft grijze of witte tonen. De delen die, in vergelijking met de witte delen, nog warmer zijn worden rood of geelachtig afgebeeld. Vooral onder moeilijke omstandigheden met veel struik en riet valt een zoogdier of vogel goed op. ",
			de: "Ähnlich wie bei White Hot. Alles, was heiß ist, hat graue oder weiße Töne. Die Teile, die im Vergleich zu den weißen Teilen noch heißer sind, werden als rot oder gelblich dargestellt. Besonders unter schwierigen Bedingungen mit viel Gebüsch und Schilf fällt ein Säugetier oder ein Vogel gut auf. ",
		}, 
		video: "redhot.mp4"      
	},
    {
		id: 4,
		title: { 
			en: "Pseudo colour",
			nl: "Pseudo colour",
			de: "Pseudo Farbe",
			}	,
		desc: {
			en: "The different shades in an object, animal or bird can be clearly distinguished.",
			nl: "Hiermee zijn de verschillende tinten in een object, dier of vogel goed te onderscheiden.",
			de: "Damit lassen sich die verschiedenen Schattierungen eines Gegenstandes, Tieres oder Vogels gut unterscheiden.",
		},video: "pseudo.mp4"      
	}
];
export default DataAboutus;