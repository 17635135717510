const DataEvents = [

	{
		id: 1,
		title: "Enforce Tac Neurenberg",
		desc: "Find everything official security experts need for their professional work: whether police equipment, security or tactical solutions, at Enforce Tac 2022 you can see the latest trends and products in a discreet atmosphere and at international level.",
		price: "49,--",
		adress: "Nuremberg, Deutschland",
		datum: "1 - 3 Marz 2022",
		date: "2022-03-03", 
		url: "https://www.enforcetac.com/",
        logo: "enforcetac.jfif"     
	},
	{
		id: 2,
		title: "IWA Outdoor Classics 2022",
		desc: "High performance in target sports, nature activities and protecting people",
		price: "34,--",
		adress: "Nuremberg, Deutschland",
		datum: "3 - 6 March 2022",
		date: "2022-03-06", 
		url: "https://www.iwa.info/en",
        logo: "iwa.png"     
	},
	
	{
		id: 4,
		title: "May Game Fair Vlaanderen Sint Truiden BE",
		desc: "‘Hét ontmoetingspunt van het jaar voor buitenmensen’",
		price: "10,--",
		adress: "Kasteel van Duras in sint-Truiden, België",
		datum: "27 - 29 May 2022",
		date: "2022-05-29", 
		url: "https://gamefairvlaanderen.be/",
        logo: "gamefair.png"     
	},
	{
		id: 5,
		title: "Jagd und Hund Dortmund DE",
		desc: "Europe's largest hunting exhibition",
		price: "18,--",
		adress: "Messe Dortmund, Duitsland",
		datum: "7 - 12 June 2022",
		date: "2022-06-12", 
		url: "https://www.jagd-und-hund.de/en/home/",
        logo: "jagdhund.png"     
	},
	{
		id: 8,
		title: "Outdoor Hunting Day",
		desc: "De Outdoor Hunting Day is een dag vol jacht en natuurplezier voor het hele gezin.",
		price: "Gratis",
		adress: "Nationaal Jachtschietcentrum Berkenhorst, Elspeet",
		datum: "9 juli 2022",
		date: "2022-07-09", 
		url: "https://geweermakerij.nl/outdoor-hunting-day/",
        logo: "jachtboutique.svg"     
	},
	{
		id: 6,
		title: "Hubana Schloss Lembeck DE",
		desc: "Jagd erleben mit allen Sinnen!",
		adress: "Wasserschloss Lembeck, Schloss 2, 46286 Dorsten",
		datum: "9 - 11 september 2022",
		date: "2022-09-11", 
		url: "https://www.hubana.events/",
        logo: "hubana.png"     
	},
	{
		id: 7,
		title: "Jacht en Buitenleven Liempde",
		desc: "Hét trefpunt voor de buitenmens",
		price: "10,--",
		adress: "Landgoed Velder, Hollands Diep (Parkeren) 5298 LE Liempde, Nederland",
		datum: "23 - 25 september 2022",
		date: "2022-09-25", 
		url: "https://www.jachtenbuitenleven.nl/bezoekers/",
        logo: "jachtbuitenleven.jpg"     
	},
	{
		id: 9,
		title: "Internationale Jagd- und Schützentage 2022",
		desc: "Deutschlands größte Outdoormesse für Jäger und Schützen",
		price: "",
		adress: "Schloss Grünau Neuburg a.d. Donau bei Ingolstadt",
		datum: "14 - 16 Oktober 2022",
		date: "2022-10-16", 
		url: "https://www.jagdundschuetzentage.de/",
        logo: "jagdundschuetzentage-logo-2022.jpg"     
	},
	{
		id: 10,
		title: "Jagd & Hund Dortmund",
		desc: "Europe's largest hunting exhibition.",
		price: "",
		adress: "Messe Dortmund, Duitsland",
		datum: "24 - 29 January 2023",
		stand: "Lahoux stand H6/H20",
		date: "2023-01-29", 
		url: "https://lahouxoptics.com/jagd-und-hund",
		urlTargetSelf: true,
        logo: "jagdhund.png"     
	},
	{
		id: 3,
		title: "Die Hohe Jagd & Fischerei",
		desc: "Internationale Messe für Jagd, Fischerei, Abenteuer, Natur & Reisen.",
		price: "",
		adress: "Messezentrum Salzburg, Austria",
		datum: "16 - 19 Februar 2023",
		stand: "Lahoux stand H10/0722",
		date: "2023-02-19", 
		url: "https://lahouxoptics.com/hohe-jagd-und-fischerei",
		urlTargetSelf: true,
        logo: "jagd_fischerei.png"     
	},
	{
		id: 12,
		title: "Enforce Tac Neurenberg",
		desc: "Find everything official security experts need for their professional work: whether police equipment, security or tactical solutions, at Enforce Tac 2023 you can see the latest trends and products in a discreet atmosphere and at international level.",
		price: "",
		stand: "Lahoux stand Hal 10-233",
		adress: "Nuremberg, Deutschland",
		datum: "28 February – 1 March 2023",
		date: "2023-03-01", 
		url: "https://www.enforcetac.com/en",
        logo: "enforcetac.jfif"     
	},
	{
		id: 11,
		title: "IWA Outdoor Classics 2023",
		desc: "High performance in target sports, nature activities and protecting people.",
		price: "",
		adress: "Nuremberg, Deutschland",
		datum: "2 - 5 March 2023",
		stand: "Lahoux stand H7A/515",
		date: "2023-03-05", 
		url: "https://www.iwa.info/en",
        logo: "iwa.png"     
	},
	{
		id: 12,
		title: "May Game Fair Vlaanderen Sint Truiden BE",
		desc: "‘Hét ontmoetingspunt van het jaar voor buitenmensen’",
		price: "",
		adress: "Kasteel van Duras in sint-Truiden, België",
		datum: "26 - 28 May 2023",
		date: "2023-05-28", 
		url: "https://gamefairvlaanderen.be/",
        logo: "gamefair.png"     
	},

];
export default DataEvents;