import React, { useState } from "react";
import { Link } from "react-router-dom";
import Burger from './Burger';
import styled from 'styled-components';
import logo from "./../../assets/logo-lahoux.png";
import { useTranslation } from "react-i18next";
import "../../locales/i18n";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'


const Navbar = ({ clientCountry, showItems, clickHandlerShowItem, checkEndDate }) => {
  
  const { t, i18n } = useTranslation();
  const imgAltTextLogo = {en: "Logo Lahoux Optics Innovation in nightvision", nl: "Logo Lahoux Optics Innovatie in nachtzicht", de: "Logo Lahoux Optics Innovation in Nachtsicht"}

 const setLanguage = event => {
   i18n.changeLanguage(event.target.value)
 }
  return (
    <Nav>
      <div className="wrapNav">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt={imgAltTextLogo[i18n.language]} />
          </Link>
        </div>
        <Burger showItems={showItems} clickHandlerShowItem={clickHandlerShowItem} clientCountry={clientCountry} checkEndDate={checkEndDate}/>
      </div>

      {/* <div className="wrapLang">
      <div className="showCountry"><p><FontAwesomeIcon icon={faMapMarkerAlt} /></p><p>{t(clientCountry)}</p></div>
      <div className="setLang">
      
        {i18n.language !== "en" &&
          <button value='en' onClick={setLanguage} >
            {t("lang_en")}
          </button>
        }
        {i18n.language !== "nl" &&
          <button value='nl' onClick={setLanguage}>
            {t("lang_nl")}
          </button>
        }

        {i18n.language !== "de" &&
          <button value='de' onClick={setLanguage} >
            {t("lang_de")}
          </button>
        }

      </div>

      </div> */}
    </Nav>
  )
}

const Nav = styled.nav`
  width: 100%;
  position: relative;
  padding: 10px 4px;
  align-items: center;
  background-color: rgba(1, 1, 1, 50%);
  
  @media (min-width: 1280px) { 
    padding: 10px 20px;
  }
  
  @media (min-width: 1440px) {
    padding: 10px 40px 10px 40px;
  }

  @media (max-width: 1024px) {
      justify-content: space-between;
  }

  .logo {
    padding: 0 0;
    }

  .logo img{
      width: 250px;
      padding: 10px;
  }
  .wrapNav, .wrapLang, .showCountry{
    display: flex;
    align-items: center;
  }

  .wrapNav{
    width: 100%;
  }

  div button, div p{
    border: none;
    background: none;
    padding: 3px;
    color: white;
  }

  ul{
    width: 100%;
  }  

  .setLang button{
    cursor: pointer;
  }
  
  .setLang button:first-of-type::after{
    content: "    |" 
  }

  .showCountry{
    margin-right: 10px;
  }
`

export default Navbar