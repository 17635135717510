import React from "react";
import logoNL from "./../assets/logoNL-innovatie.png";
import logoDE from "./../assets/logoDE-innovatie.png";
import logoEN from "./../assets/logoEN-innovatie.png";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import logoiso from "./../assets/iso-footer.jpg";
import forest from "./../assets/forest.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const logos = { en: logoEN, nl: logoNL, de: logoDE };

const Footer = () => {
  const { t, i18n } = useTranslation();
  const imgAltTextLogo = {
    en: "Logo Lahoux Optics Innovation in nightvision",
    nl: "Logo Lahoux Optics Innovatie in nachtzicht",
    de: "Logo Lahoux Optics Innovation in Nachtsicht",
  };
  const imgAltTextIso = {
    en: "Lahoux Optics is an ISO9001:2015 certified company",
    nl: "Lahoux Optics is een ISO9001:2015 gecertificeerde onderneming",
    de: "Lahoux Optics ist ein nach ISO9001:2015 zertifiziertes Unternehmen",
  };

  return (
    <StyledFooter
      style={{
        backgroundImage: `url(${forest})`,
      }}
    >
      <div className="sitemap-wrapper">
        <nav className="sitemap">
          <ul>
            <li>
              <Link to="/producten">{t("menu_producten")}</Link>
            </li>
            <li>
              <Link to="/evenementen">{t("menu_over_events")}</Link>
            </li>
            <li>
              <Link to="/woordenlijst">{t("menu_woorden")}</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/hoe-wij-werken">{t("menu_over")}</Link>
            </li>
            {/* <li>
              <Link to="/vacatures">{t("menu_over_vacatures")}</Link>
            </li> */}
            <li>
              <Link to="/dealers">{t("menu_dealers")}</Link>
            </li>
            <li>
              <Link to="/service">{t("menu_service")}</Link>
            </li>
          </ul>
        </nav>
        <div className="logo">
          <Link to="/">
            <img
              src={logos[i18n.language]}
              alt={imgAltTextLogo[i18n.language]}
            />
          </Link>
        </div>
        <div>
          <div className="social-wrapper">
            <p>{t("footer_volg")}:</p>
            <ul className="social-icons">
              <a
                href="https://www.facebook.com/lahouxoptics"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>
                  <FontAwesomeIcon icon={faFacebookF} />
                </li>
              </a>
              <a
                href="https://www.instagram.com/lahouxoptics/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>
                  <FontAwesomeIcon icon={faInstagram} />
                </li>
              </a>
              <a
                href="https://twitter.com/LahouxOpticsBV"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>
                  <FontAwesomeIcon icon={faTwitter} />
                </li>
              </a>
              <a
                href="https://www.youtube.com/user/LahouxOpticsBV"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>
                  <FontAwesomeIcon icon={faYoutube} />
                </li>
              </a>
            </ul>
          </div>
          <img
            src={logoiso}
            alt={imgAltTextIso[i18n.language]}
            className="logoIso"
          />
        </div>
      </div>
      <div className="fine-print">
        <ul>
          <li>
            <Link to="/imprint">{t("imprint.page_heading")}</Link>
          </li>
          <li>
            <Link to="/cookieverklaring">{t("menu_cookieverklaring")}</Link>
          </li>
        </ul>

        <p className="footerRights">{t("footer_price")}.</p>
        <p className="footerRights">{t("footer_disclaimer")}</p>
        <p className="footerRights">
          © {new Date().getFullYear()} Lahoux Optics || {t("footer_rights")}
        </p>
      </div>
    </StyledFooter>
  );
};
const StyledFooter = styled.footer`
  width: 100%;
  font-size: 1.25rem;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  background-color: rgba(1, 1, 1, 90%);
  background-blend-mode: darken;
  color: white;

  p {
    text-align: center;
    padding-bottom: 40px;
  }
  .logo {
    padding: 15px 0;
    margin-bottom: 15px;
  }
  .logo img {
    width: 250px;
    @media (min-width: 375px) {
      width: 300px;
    }
  }
  .logoIso {
    padding: 3px 0px 3px 3px;
    background-color: #fff;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .sitemap-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;
    padding-top: 40px;
    @media (min-width: 768px) {
      flex-wrap: wrap;
    }

    @media (min-width: 1024px) {
      flex-wrap: nowrap;
    }
  }
  .sitemap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .sitemap ul {
    margin-right: 40px;
  }
  .sitemap li {
    padding: 1em;
  }
  .sitemap li a,
  .fine-print li a {
    text-decoration: none;
    color: var(--link);
  }
  .social-icons {
    display: flex;
    list-style-type: none;
    font-size: 1.2em;
  }
  .social-icons li {
    padding: 7px;
    border: 1px solid #fff;
    border-radius: 100%;
    margin-right: 10px;
    color: #fff;
  }
  .social-icons li svg {
    width: 1.2em;
    height: 1.2em;
  }
  .social-icons li:hover {
    background-color: var(--brand-accent-color);
    border-color: var(--brand-accent-color);
  }
  .social-wrapper p {
    text-align: left;
    padding: 1em 0;
  }
  .social-wrapper {
    margin-bottom: 20px;

    @media (min-width: 768px) {
      flex-wrap: wrap;
    }

    @media (min-width: 1024px) {
      flex-wrap: no-wrap;
    }
  }

  .fine-print {
    font-size: 0.9375rem;
    border-top: 1px solid #808080;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 90%;
    margin: 20px auto 0 auto;
  }

  .fine-print ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }

  .fine-print li {
    padding-left: 20px;
  }

  .footerRights {
    font-size: 0.9375rem;
    padding-bottom: 8px;
    line-height: 1.2;
  }
`;
export default Footer;
