import React from "react";
import {
  Link
} from "react-router-dom";
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import "../../locales/i18n";
import iconDealer from "../../assets/icons/dealersquare.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

function RightNav({open, showItems, clickHandlerShowItem, clientCountry }) {
  const { t, i18n } = useTranslation();
  const setLanguage = event => {
    i18n.changeLanguage(event.target.value)
  }
  return (
    
            <Ul open={open}>   
                <Link to="/"> 
                  <li>{t("menu_home")}</li>
                </Link>         
                <div className="dropdown">
                  <Link to="/producten" onClick={event => {
                    clickHandlerShowItem("all");}}>
                    <li>{t("menu_producten")}</li>
                  </Link>
                  <div className="dropdown-content">
                  <Link to="/producten" onClick={event => {
                    clickHandlerShowItem("all");}}>
                      <li>{t("all_products")}</li>
                    </Link>
                    <Link to="/producten" onClick={event => {
                    clickHandlerShowItem("night");}}>
                      <li>{t("menu_producten_nacht")}</li>
                    </Link>
                    <Link to="/producten" onClick={event => {
                    clickHandlerShowItem("thermal");}}>
                      <li>{t("menu_producten_warm")}</li>
                    </Link>
                    <Link to="/producten" onClick={event => {
                    clickHandlerShowItem("digital");}}>
                      <li>{t("menu_producten_digi")}</li>
                    </Link>
                    <Link to="/producten" onClick={event => {
                    clickHandlerShowItem("accessories");}}>
                      <li>{t("menu_producten_acces")}</li>
                    </Link>
                    <a href="https://www.outdooroptics.eu/" target="_blank" rel="noopener noreferrer">
                      <li>{t("menu_producten_overig")}</li>
                    </a>
                  </div>
                </div>
                <div className="dropdown">
                  <Link to="/techniek">
                    <li>{t("menu_techniek")}</li>
                  </Link>
                  <div className="dropdown-content">
                    <Link to="/techniek" onClick={event => {
                      clickHandlerShowItem("thermal");}}>
                        <li>{t("menu_producten_warm")}</li>
                      </Link>
                      <Link to="/techniek" onClick={event => {
                      clickHandlerShowItem("night");}}>
                        <li>{t("menu_producten_nacht")}</li>
                      </Link>
                      <Link to="/techniek" onClick={event => {
                      clickHandlerShowItem("digital");}}>
                        <li>{t("menu_producten_digi")}</li>
                      </Link>
                      <Link to="/woordenlijst">
                        <li>{t("menu_woorden")}</li>
                      </Link>
                  </div>
                </div>
                <div className="dropdown">
                  <a>
                    <li>{t("menu_over")}</li>
                  </a>
                  <div className="dropdown-content">
                    <Link to="/geschiedenis">
                      <li>{t("menu_over_history")}</li>
                    </Link>
                    <Link to="/hoe-wij-werken">
                      <li>{t("menu_over_how")}</li>
                    </Link>
                    {/* <Link to="/vacatures">
                      <li>{t("menu_over_vacatures")}</li>
                    </Link> */}
                    <Link to="/dealers">
                      <li>{t("menu_dealers")}</li>
                    </Link>
                    <Link to="/evenementen">
                      <li>{t("menu_over_events")}</li>
                    </Link>
                  </div>
                </div>
                <Link to="/service">
                  <li>{t("menu_service")}</li>
                </Link>
                
                {/* <a href="https://portal.lahouxoptics.com/" target="_blank" rel="noopener noreferrer" className="menuLogin">
                  <li>
                        <img src={iconDealer} alt="icoon user"/> 
                        {t("betweenoption4")}
                  </li>
                </a> */}
               
                <div className="wrapLang">
                  <div className="showCountry"><p><FontAwesomeIcon icon={faMapMarkerAlt} /></p><p>{t(clientCountry)}</p></div>
                  <div className="setLang">
                  
                    {i18n.language !== "en" &&
                      <button value='en' onClick={setLanguage} >
                        {t("lang_en")}
                      </button>
                    }
                    {i18n.language !== "nl" &&
                      <button value='nl' onClick={setLanguage}>
                        {t("lang_nl")}
                      </button>
                    }

                    {i18n.language !== "de" &&
                      <button value='de' onClick={setLanguage} >
                        {t("lang_de")}
                      </button>
                    }
                    </div>

                </div>    
            </Ul>
    
  );
}
const Ul = styled.ul`
  width: 100%;
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  
  
  li, .dropbtn {
    padding: 25px 20px;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
    background-color: transparent;
    border: none;

    @media (min-width: 1200px) {
      font-size: 1.0625rem;
    }

    @media (min-width: 1232px) {
      font-size: 1.1875rem;
    }

    @media (min-width: 1380px) {
      font-size: 1.5rem;
    }

    @media (min-width: 1405) {
      padding: 25px 25px;
    }
  }

  .showCountry svg{
    font-size: 1.125rem;
    vertical-align: middle;
  }

  .showCountry p:first-of-type svg{
    align-items: flex-start;
  }

  a{
    text-decoration: none;
    color: #393939;
  }
  .menuLogin{
    display: flex;
    flex-wrap: nowrap;
  }
  .menuLogin img{
    width: 0.75em;
    height: 0.75em;
    padding-right: 4px;
    display: inline-block;
  }
  div.wrapLang {
      margin-left: auto;
      font-size: 0.9375rem;
    }
  .dropdown-content {
    display: none;
    font-size: 0.875rem;
    position: absolute;
    background-color: rgba(138, 138, 138, 60%);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    z-index: 900;
  }
  @media (max-width: 1200px) {
    .dropdown-content {
      position: relative;
    }
  }
  .dropdown:hover .dropdown-content{
    display: block;
  }
  @media (max-width: 1200px) {
    flex-flow: column nowrap;
    background-color: #393939;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    right: 0;
    top: 0;
    z-index: 998;
    align-items: flex-start;
    width: 300px;
    padding-top: 3.5rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
    transition: transform 0.3s ease-in-out;
    div.wrapLang{
      margin-left: 0;
    }
    li {
      color: white;
      padding: 18px 10px;
    }

  }

  .menu-focus li {
    color: #FC751B;
    font-weight: bold;
    text-shadow: 0px 0px 5px #000;
  }
`;
export default RightNav;



