const DataDealers = [
	{
		id: 1,
		name: "Lahoux Optics BV",
		city: "Haarlem",
		website: "www.lahouxoptics.com",
		email: "info@lahoux.nl",
		phone: "023 - 55 14 678", 
		url: "https://lahouxoptics.com/",
		logo: "Logo-Lahoux Optics.png",
		country: "Netherlands",
	},
	{
		id: 2,
		type: {
			en: "Dealer",
            nl: "Dealer",
            de: "Händler", 
		},
		name: "Wapenhandel Kuiper BV",
		city: "Blijham,",
		website: "www.wapenhandelkuiper.nl",
		email: "info@wapenhandelkuiper.nl",
		phone: "0597 - 56 13 24", 
		url: "https://www.wapenhandelkuiper.nl",
		logo: "wapenhandel-kuiper-schietsport-jacht-logo1.png",
		country: "Netherlands", 
	},
	{
		id: 3,
		type: {
			en: "Distributor",
            nl: "Distributeur",
            de: "Verteiler", 
		},
		name: "ARMS - CZ",
		city: "Humpolec ",
		website: "www.arms-cz.cz",
		email: "shop@arms-cz.cz",
		phone: "+420 602 783 081", 
		url: "https://www.arms-cz.cz/",
		logo: "arms.jpg",
		country: "Czech Republic",
	},
	{
		id: 4,
		name: "Fritidsshop",
		city: "Kolding",
		website: "www.fritidsshop.dk",
		email: "support@fritidsshop.dk",
		phone: "+45 20 65 77 92", 
		url: "https://fritidsshop.dk/",
		logo: "Fritidsshop-logo.jpg",
		country: "Denmark",
	},
	{
		id: 5,
		type: {
			en: "Distributor",
            nl: "Distributeur",
            de: "Verteiler", 
		},
		name: "Z-shops",
		city: "Košice",
		website: "www.z-shops.eu",
		email: "info@z-shops.eu",
		phone: "+36 20 95 96 375", 
		url: "https://www.z-shops.eu/",
		logo: "z-shops.png",
		country: "Hungary",   
	},
	{
		id: 6,
		type: {
			en: "Distributor",
            nl: "Distributeur",
            de: "Verteiler", 
		},
		name: "Bignami",
		city: "Bolzano",
		website: "www.bignami.it",
		email: "",
		phone: "", 
		url: "https://www.bignami.it/it/",
		logo: "bignami.jpg",
		country: "Italie",
	},
	{
		id: 7,
		type: {
			en: "Distributor",
            nl: "Distributeur",
            de: "Verteiler", 
		},
		name: "Johann Fanzoj",
		city: "Zagreb",
		website: "www.fanzoj.hr",
		email: "info@fanzoj.hr",
		phone: "01 3822 655", 
		url: "https://www.fanzoj.hr/",
		logo: "fanzoj.png",
		country: "Croatia",  
	},
	{
		id: 8,
		name: "Lahoux Optics BV",
		city: "Haarlem",
		website: "www.lahouxoptics.com",
		email: "info@lahoux.nl",
		phone: "023 - 55 14 678", 
		url: "https://lahouxoptics.com/",
		logo: "Logo-Lahoux Optics.png",
		country: "Norway",			
	},
	{
		id: 9,
		type: {
			en: "Distributor",
            nl: "Distributeur",
            de: "Verteiler", 
		},
		name: "Hunting Gear",
		city: "Cluj – Napoca",
		website: "www.huntinggear.eu",
		email: "office@huntinggear.eu",
		phone: "0040 728 344 784", 
		url: "https://huntinggear.eu",
		logo: "gear.png",
		country: "Romania",
	},
	{
		id: 10,
		type: {
			en: "Distributor",
            nl: "Distributeur",
            de: "Verteiler", 
		},
		name: "ARMS - CZ",
		city: "Humpolec",
		website: "www.arms-cz.cz",
		email: "shop@arms-cz.cz",
		phone: "+420 602 783 081",
		url: "https://www.arms-cz.cz/",
		logo: "arms.jpg",
		country: "Slovakia",  
	},
	{
		id: 11,
		type: {
			en: "Distributor",
            nl: "Distributeur",
            de: "Verteiler", 
		},
		name: "Ibérica de Armerías",
		city: "Ripollet",
		website: "www.ibericadearmerias.com/",
		email: "info@ibericadearmerias.com",
		phone: "+34 935 641 396", 
		url: "https://www.ibericadearmerias.com/",
		logo: "iberica.jpg",
		country: "Spain", 
	},
	{
		id: 12,
		name: "Lahoux Optics BV",
		city: "Haarlem",
		website: "www.lahouxoptics.com",
		email: "info@lahoux.nl",
		phone: "+31 23 55 14 678", 
		url: "https://lahouxoptics.com/",
		logo: "Logo-Lahoux Optics.png",
		country: "Sweden",			
	},	
	{
		id: 13,
		type: {
			en: "Distributor",
            nl: "Distributeur",
            de: "Verteiler", 
		},
		name: "Tvprzemyslowa Nowak",
		city: "Poznań",
		website: "www.tvprzemyslowa.pl",
		email: "kontakt@tvprzemyslowa.pl",
		phone: "+48 61 875 04 76", 
		url: "https://tvprzemyslowa.pl/",
		logo: "poland.jpg",
		country: "Poland", 
	},
	{
		id: 14,
		type: {
			en: "Dealer",
            nl: "Dealer",
            de: "Händler", 
		},
		name: "Hunt & Shoot",
		city: "Großberg",
		website: "www.huntandshoot.de",
		email: "info@huntandshoot.de",
		phone: "0151-50602903", 
		url: "https://www.huntandshoot.de",
		logo: "huntshoot.jpg",
		country: "Germany",
	},
	{
		id: 15,
		type: {
			en: "Dealer",
            nl: "Dealer",
            de: "Händler", 
		},
		name: "Speciaalzaak Jan Witvoet",
		city: "Dalen",
		website: "www.schietsport-janwitvoet.nl",
		email: "info@janwitvoet.nl",
		phone: "0524 – 551 253", 
		url: "https://www.schietsport-janwitvoet.nl/",
		logo: "janwitvoet.jpg",
		country: "Netherlands",
	},
	{
		id: 16,
		type: {
			en: "Dealer",
            nl: "Dealer",
            de: "Händler", 
		},		
		name: "Armurerie Paul Pletsers",
		city: "Ferrières",
		website: "www.paulpletsers.be",
		email: "info@paulpletsers.be",
		phone: "+31 (0)86 43 44 53", 
		url: "https://www.paulpletsers.be",
		logo: "logo-Paul-Pletsers.png",
		country: "France", 
	},
	{
		id: 17,
		type: {
			en: "Dealer",
            nl: "Dealer",
            de: "Händler", 
		},		
		name: "Armurerie Paul Pletsers",
		city: "Ferrières",
		website: "www.paulpletsers.be",
		email: "info@paulpletsers.be",
		phone: "+31 (0)86 43 44 53", 
		url: "https://www.paulpletsers.be",
		logo: "logo-Paul-Pletsers.png",
		country: "Belgium", 
	},
	{
		id: 18,
		type: {
			en: "Dealer",
            nl: "Dealer",
            de: "Händler", 
		},		
		name: "Hunt & Shoot",
		city: "Großberg",
		website: "www.huntandshoot.de",
		email: "info@huntandshoot.de",
		phone: "0151-50602903", 
		url: "https://www.huntandshoot.de",
		logo: "huntshoot.jpg",
		country: "Austria",
	},			

// Template
/*
{
		id: ,
		type: {
			en: "Distributor",
            nl: "Distributeur",
            de: "Verteiler", 
		},
		name: "",
		city: "",
		website: "",
		email: "",
		phone: "", 
		url: "",
		logo: "",
		country: "",
	},	
*/
// Verwijderde Dealers en distributeurs
/*	{
		id: 2,
		type: {
			en: "Distributor",
            nl: "Distributeur",
            de: "Verteiler", 
		},
		name: "Mikx Outdoor Wholesale",
		city: "Groningen",
		website: "www.mikx.nl",
		email: "info@mikx.nl",
		phone: "+31 (0)50 318 0948", 
		url: "https://www.mikx.nl/contact/",
		logo: "mikx.jpg",
		country: "Belgium", 
	},
*/
/*	{
		id: 3,
		type: {
			en: "Distributor",
            nl: "Distributeur",
            de: "Verteiler", 
		},
		name: "AKAH",
		city: "Gummersbach - Hunstig",
		website: "www.akah.de/teilnehmende-haendler/",
		email: "info@akah.de",
		phone: "+49 2261 / 705-555", 
		url: "https://www.akah.de/teilnehmende-haendler/",
		logo: "akah.png",
		country: "Austria",   
	},
*/	
/*	{
		id: 9,
		type: {
			en: "Distributor",
            nl: "Distributeur",
            de: "Verteiler", 
		},
		name: "Mikx Outdoor Wholesale",
		city: "Groningen",
		website: "www.mikx.nl",
		email: "info@mikx.nl",
		phone: "+31 (0)50 318 0948", 
		url: "https://www.mikx.nl/contact/",
		logo: "mikx.jpg",
		country: "Luxembourg",
	},
*/
/*	{
		id: 10,
		type: {
			en: "Distributor",
            nl: "Distributeur",
            de: "Verteiler", 
		},
		name: "Magne Landrø",
		city: "Lillestrøm",
		website: "www.landro.no",
		email: "Post@landro.no",
		phone: "+47 64 84 75 75", 
		url: "https://www.landro.no/",
		logo: "landro.png",
		country: "Norway",
	},
*/	
/*	{
		id: 14,
		type: {
			en: "Distributor",
            nl: "Distributeur",
            de: "Verteiler", 
		},
		name: " Torsbo Handels",
		city: "Gällstad",
		website: "www.torsbohandels.com",
		email: "info@torsbohandels.com",
		phone: "0321-68 58 00", 
		url: "https://www.torsbohandels.com",
		logo: "torsbo.jpg",
		country: "Sweden",
	},
*/
/*	{
		id: 15,
		type: {
			en: "Distributor",
            nl: "Distributeur",
            de: "Verteiler", 
		},
		name: "AKAH",
		city: "Gummersbach - Hunstig",
		website: "www.akah.de",
		email: "info@akah.de",
		phone: "+49 2261 / 705-555", 
		url: "https://www.akah.de/teilnehmende-haendler/",
		logo: "akah.png",
		country: "Germany",
	},
*/	
/* {
		id: 17,
		type: {
			en: "Distributor",
            nl: "Distributeur",
            de: "Verteiler", 
		},
		name: "Frankonia",
		city: "Rottendorf",
		website: "www.frankonia.de",
		email: "",
		phone: "09302 / 20 50 400", 
		url: "https://www.frankonia.de",
		logo: "frankonia.png",
		country: "Germany",
	},
*/	
/*	{
		id: 19,
		type: {
			en: "Dealer",
            nl: "Dealer",
            de: "Händler", 
		},
		name: "Jachtboutique & Geweermakerij",
		city: "Elspeet",
		website: "www.geweermakerij.nl",
		email: "info@geweermakerij.nl",
		phone: "0577 - 491 546", 
		url: "https://geweermakerij.nl/",
		logo: "jachtboutique.jpg",
		country: "Netherlands",
	},
*/
/*	{
		id: 19,
		type: {
			en: "Distributor/Dealer",
            nl: "Distributeur/Dealer",
            de: "Verteiler/Händler", 
		},
		name: "Lahoux Optics BV",
		city: "Haarlem",
		website: "www.lahouxoptics.com",
		email: "info@lahoux.nl",
		phone: "023 - 55 14 678", 
		url: "https://lahouxoptics.com/",
		logo: "Logo-Lahoux Optics.png",
		country: "Netherlands",
	},
*/	
/*	{
		id: 21,
		name: "TB Hunting",
		city: "Laa an der Thaya",
		website: "www.tb-hunting.at",
		email: "bernscherer@tb-hunting.at",
		phone: "+43 699/816 47 022", 
		url: "https://tb-hunting.at/",
		logo: "TB-Hunting.png",
		country: "Austria",
	},		
*/

];
export default DataDealers;