import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";


const Card = ({ clientCountry, clientVat, card }) => {
  const { t, i18n } = useTranslation();

  return (
    <StyledCard>
        <img src={"./card-header-img/" + card.img} alt={card.title[i18n.language]} />

        <div className="card-body">
          <h3 className="card-subtitle">{card.sub_title[i18n.language]}</h3>
          <h2 className="card-title">{card.title[i18n.language]}</h2>
          <p className="card-content">{card.desc[i18n.language]}</p>

          {/* check if there is text to be shown in bold, if not, do not show this paragraph */}
          {card.descWeight ?
              <p className="card-content"><strong>{card.descWeight[i18n.language]}</strong></p> 
            : 
              null 
          }

          {/* check if offerForForm is true. If so, show the For-From offer block, if false, do not show this block */}
          {card.offerForFrom ?
              <p className="card-content card-offer">
                <i> 
                    <strong>{card.title_offer[i18n.language]}:</strong><br />

                    <span className="card-price__label"><strong>{card.fromText[i18n.language]}:</strong>{" "}</span>
                    <span className="card-price__regular">
                      {/* check if specialVAT has a value to show same VAT for every country. If so, show the first block, else, show second block */}
                      {card.specialVAT ? 
                          <>€{" "}{(card.price_regular_ex_vat * (1+card.specialVAT/100)).toFixed(2)}</> 
                        : 
                          <>€{" "}{(card.price_regular_ex_vat * (1+clientVat/100)).toFixed(2)}</> 
                      }
                    </span>{" "}
                    <span className="card-price__subtext">
                      {
                        /* check if specialVAT has a value to show same VAT for every country. If so, show the first block, else, show second block */
                        /* also specialCountry needs to be set to show same country */
                      }
                      {card.specialVAT ? 
                          <>({t("vat_incl")} {card.specialVATText}% {t("vat")} {t(card.specialCountry[i18n.language])})</> 
                        : 
                          <>({t("vat_incl")} {clientVat}% {t("vat")} {t(clientCountry)})</> 
                      }
                    </span><br />

                    <span className="card-price__label"><strong>{card.forText[i18n.language]}:</strong>{" "}{" "}</span>
                    <span className="card-price__offer">
                      {/* check if specialVAT has a value to show same VAT for every country. If so, show the first block, else, show second block */}
                      {card.specialVAT ? 
                          <>€{" "}{Math.round((card.price_offer_ex_vat) * (1+card.specialVAT/100))}</> 
                        : 
                          <>€{" "}{Math.round((card.price_offer_ex_vat) * (1+clientVat/100))}</> 
                      }
                    </span>{" "}
                    <span className="card-price__subtext">
                      {
                        /* check if specialVAT has a value to show same VAT for every country. If so, show the first block, else, show second block */
                        /* also specialCountry needs to be set to show same country */
                      }
                      {card.specialVAT ? 
                          <>({t("vat_incl")} {card.specialVATText}% {t("vat")} {t(card.specialCountry[i18n.language])})</> 
                        : 
                          <>({t("vat_incl")} {clientVat}% {t("vat")} {t(clientCountry)})</> 
                      }
                    </span>
                    
                </i>
              </p>
            : 
              null
          }
        </div>

        <div className="card-footer">
            <Link to={card.url}>{card.button_text[i18n.language]}</Link>

            {card.button_text_2 ? <Link to={card.url_2}>{card.button_text_2[i18n.language]}</Link> : null }
            {card.button_text_3 ? <Link to={card.url_3}>{card.button_text_3[i18n.language]}</Link> : null }
            {card.button_text_4 ? <Link to={card.url_4}>{card.button_text_4[i18n.language]}</Link> : null }
        </div>
    
    </StyledCard>
  );
};

const StyledCard = styled.div.attrs({
  className: "card",
})`
  p {
    line-height: 22px;
    margin-bottom: 20px;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    min-width: 230px;
    min-height: 324px;
    width: 320px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 20px;
    border-radius: 8px;
    background-color: #595d40;

    animation-name: carditem;
    animation-duration: 2.5s;
    animation-timing-function: ease-out;
  }

  @keyframes carditem {
    from {opacity: 0;}
    to {opacity: 1;}
  }

  .card img {
    width: 100%;
    height: auto;
  }

  .card-body {
    padding: 0 10px;
    flex-grow: 1;
    flex-shrink: 0;
    font-size: 18px;
  }

  .card-title {
    font-size: 24px;
    padding-top: 4px;
    margin-top: 0;
    border-bottom: none;
  }

  .card-subtitle {
    letter-spacing: 1px;
    font-size: 17px;
    font-weight: 400;
  }

  .card-price__offer {
    font-weight: bold;
    padding: 2px 4px;
    border-radius: 10px;
    background-color: #fc751b;
  }

  .card-price__regular {
    text-decoration-line: line-through;
    text-decoration-color: #fc751b;
    text-decoration-thickness: 2px;
  }

  .card-price__label {
    font-size: 16px;
  }

  .card-price__subtext {
    font-size: 15px;
  }

  .card-footer {
    padding: 4px 10px;
    text-align: right;
    padding-bottom: 10px;
    font-size: 18px;
  }

  .card-footer a {
    color: #fff;
    text-decoration: none;
    border: 1px solid rgb(252, 117, 27);
    padding: 8px 10px;
    border-radius: 10px;
    display: inline-block;
    margin-top: 6px;
  }

  .card-footer a:hover {
    background-color: rgb(252, 117, 27);
    color: #fff;
  }

`;

export default Card;
