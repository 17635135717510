import React, { useEffect, useState } from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import RightNav from './RightNav';


const Burger = ({showItems, clickHandlerShowItem, clientCountry, checkEndDate }) => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  useEffect(() => {
    setOpen(false);
  }, [location])

  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <RightNav open={open} showItems={showItems} clickHandlerShowItem ={clickHandlerShowItem} clientCountry={clientCountry} checkEndDate={checkEndDate}/>
    </>
  )
}
const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  right: 20px;
  display: none;
  @media (max-width: 1200px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    z-index: 999;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => open ? 'white' : '#FC751B'};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({ open }) => open ? 0 : 1};
    }
    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;
export default Burger