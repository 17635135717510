import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
// import Card from "./../Card";
import { Link } from "react-router-dom";
import DataOffers from "./DataOffers";
import RozetNL from "./../../assets/Sticker_NL.svg";
import RozetEN from "./../../assets/Sticker_EN.svg";
import RozetDE from "./../../assets/Sticker_DE.svg";

const LahouxJubileum = ({ clientCountry, clientVat }) => {
  const { t, i18n } = useTranslation();

  // Get the date of today in format yyyy-mm-dd (e.g. 2022-08-15) be able to check the end date of an item
  const getTodayDate = () => {
    const currentDate = new Date();

    let dd = currentDate.getDate();
    let mm = currentDate.getMonth() + 1;
    let yyyy = currentDate.getFullYear();

    // add leading zero if day and month are below 10
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    let today = yyyy + "-" + mm + "-" + dd;

    return today;
  };

  // Add translatable alt-text for image
  const imgAltTextRozet = {
    en: "Lahoux Optics 30 years",
    nl: "Lahoux Optics 30 jaar",
    de: "Lahoux Optics 30 Jahre",
  };

  // filters out the product with not_show_country set for given country
  // returns complete list if country is not the country set at not_show_country
  const filterCountryOffers = (offer) => {
    if (clientCountry==="Italy" && offer.not_show_country!=="Italy") {
      return offer
    } else if (clientCountry!=="Italy") {
      return offer
    }
  }

  return (
    <StyledLahouxJubileum>
      <Helmet>
        <title>Lahoux Optics 30 years</title>
        <meta
          name="description"
          content="This year Lahoux Optics exists 30 years. A milestone that we as a company are proud of and want to reflect on. Lahoux Optics delivers since 30 years high quality technical night vision devices."
        />
      </Helmet>


      {/* <section className="cards">
 
        {DataOffers
          .filter((offer) => offer.end_date >= getTodayDate())
          .filter(filterCountryOffers)
          .map((offer) => (
            
          <div className="card" key={offer.id}>
            <img
              src={"./card-header-img/" + offer.img}
              alt={offer.title[i18n.language]}
            />
            <div className="card-body">
              <h3 className="card-subtitle">{offer.sub_title[i18n.language]}</h3>
              <h2 className="card-title">{offer.title[i18n.language]}</h2>
              <p className="card-content">{offer.desc[i18n.language]}</p>
              <p className="card-content card-offer">
                <i>
                  <strong>{offer.title_offer[i18n.language]}</strong><br />
                  <span><strong>{t("for")}:</strong>{" "}{" "}
                    <span className="price-offer">
                      €{" "}{Math.round((offer.price_offer_ex_vat / offer.price_regular_ex_vat) * (offer.price_regular_ex_vat) * (1+clientVat/100))}

                     
                    </span>
                  </span>{" "}
                  <span className="price-subtext">({t("vat_incl")} {clientVat}% {t("vat")} {t(clientCountry)})</span><br />
                  
                  <span><strong>{t("from")}:</strong>{" "}
                    <span className="price-regular">{(offer.price_regular_ex_vat * (1+clientVat/100)).toFixed(2)}</span>
                  </span>{" "}
                  <span className="price-subtext">({t("vat_incl")} {clientVat}% {t("vat")} {t(clientCountry)})</span>
                </i>
              </p>
              <p className="card-content warranty">{offer.warranty?.[i18n.language]}</p>
            </div>
            <div className="card-footer">
              <Link to={offer.url}>{offer.button_text[i18n.language]}</Link>
            </div>
          </div>
        ))}
      </section> */}


      <article id="lahoux30years">
        <h1>{t("jubilee.page_heading")}</h1>
        <p>{t("jubilee.intro_article_p1")}</p>
        <p>{t("jubilee.intro_article_p2")}</p>
        <p>{t("jubilee.intro_article_p3")}</p>
        <p>{t("jubilee.intro_article_p4")}</p>
        <p>{t("jubilee.intro_article_p5")}</p>
        <p>{t("jubilee.intro_article_p6")}</p>
        <p>{t("jubilee.intro_article_p7")}</p>
        <p>{t("jubilee.intro_article_p8")}</p>

        {(i18n.language === "nl" || i18n.language === "en") && (
          <>
            <p>{t("jubilee.intro_article_p9")}</p>
            <p>{t("jubilee.intro_article_p10")}</p>
            <p>{t("jubilee.intro_article_p11")}</p>
          </>
        )}
      </article>
    </StyledLahouxJubileum>
  );
};

const StyledLahouxJubileum = styled.div`
  margin: 60px auto 120px auto;
  max-width: 90vw;
  font-size: 20px;
  @media (min-width: 768px) {
    max-width: 80vw;
  }

  /* @media (min-width: 1024px) {
    max-width: 50vw;
  } */

  h1 {
    font-size: 24px;
    font-weight: 700;
    border-bottom: 2px solid #fc751b;
    padding-bottom: 6px;
    margin-bottom: 20px;
    display: inline-block;
  }

  h2 {
    font-size: 22px;
    font-weight: 700;
    border-bottom: 2px solid #fc751b;
    padding-bottom: 6px;
    margin-top: 20px;
    margin-bottom: 10px;
    display: inline-block;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 6px;
  }

  p {
    line-height: 22px;
    margin-bottom: 20px;
  }

  article {
    /* width: 72ch; */
    @media (min-width: 768px) {
      max-width: 70vw;
    }

    @media (min-width: 1024px) {
      max-width: 50vw;
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
  }

  .price-offer {
    font-weight: bold;
    padding: 2px 4px;
    border-radius: 10px;
    background-color: #fc751b;
  }

  .price-regular {
    text-decoration-line: line-through;
    text-decoration-color: #fc751b;
    text-decoration-thickness: 2px;
  }

  .price-subtext {
    font-size: 15px;
  }

  .warranty {
    font-size: 16px;
    font-style: italic;
  }

  .card img.rozet-img {
    width: 250px;
    transform: rotate(-12deg);
  }

  .card-offer {
    line-height: 24px;
  }

  .card {
    display: flex;
    flex-direction: column;
    min-width: 230px;
    min-height: 324px;
    width: 320px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 20px;
    border-radius: 8px;
    background-color: #595d40;

    animation-name: carditem;
    animation-duration: 2.5s;
    animation-timing-function: ease-out;
  }

  @keyframes carditem {
    from {opacity: 0;}
    to {opacity: 1;}
  }

  .card img {
    width: 100%;
    height: auto;
  }

  .card-body {
    padding: 0 10px;
    flex-grow: 1;
    flex-shrink: 0;
    font-size: 18px;
  }

  .card-title {
    font-size: 24px;
    padding-top: 4px;
    margin-top: 0;
    border-bottom: none;
  }

  .card-subtitle {
    letter-spacing: 1px;
    font-size: 17px;
    font-weight: 400;
  }

  .card-footer {
    padding: 4px 10px;
    text-align: right;
    padding-bottom: 10px;
    font-size: 18px;
  }

  .card-footer a {
    color: #fff;
    text-decoration: none;
    border: 1px solid rgb(252, 117, 27);
    padding: 8px 10px;
    border-radius: 10px;
    display: inline-block;
  }

  .card-footer a:hover {
    background-color: rgb(252, 117, 27);
    color: #fff;
  }
`;

export default LahouxJubileum;
