import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ContentCarouselItem from "./ContentCarouselItem";

function ContentCarousel({ carouselItems }) {
  const { i18n } = useTranslation();

  const items = carouselItems.map((item) => (
    <ContentCarouselItem key={item.id} item={item} />
  ));

  return (
    <StyledContentCarousel>
      <AliceCarousel
        autoPlay
        autoPlayInterval="6000"
        infinite
        disableButtonsControls
        items={items}
      />
    </StyledContentCarousel>
  );
}

const StyledContentCarousel = styled.div`
  margin: 100px auto 0 auto;

  .alice-carousel__dots-item {
    width: 12px;
    height: 12px;
    background-color: rgba(250, 250, 250, 0);
    border: 2px solid #fff;
    margin: 0px;
    background-blend-mode: inherit;
  }
  .alice-carousel__dots-item:hover {
    background-color: #fff;
  }

  .alice-carousel__dots-item.__active {
    background-color: #fff;
  }

  .alice-carousel__prev-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    font-size: 70px;
    text-align: left;
  }

  .alice-carousel__next-btn {
    right: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 70px;
    text-align: right;
  }

  .alice-carousel__prev-btn-item, 
  .alice-carousel__next-btn-item {
    color: var(--text);
  }

  .alice-carousel__prev-btn-item:hover, 
  .alice-carousel__next-btn-item:hover {
    color: var(--brand-accent-color);
  }

`;
export default ContentCarousel;
