const DataCardsHoheJagdFischerei = [
  {
    id: 1,
    title: {
      en: "Lahoux Spotter NL series (4 versions)",
      nl: "Lahoux Spotter NL serie (4 versies)",
      de: "Lahoux Spotter NL-Serie (4 Versionen)",
    },
    sub_title: {
      en: "Special exhibition offer:",
      nl: "Speciale beursaanbieding:",
      de: "Sonderangebot zur Ausstellung:",
    },
    desc: {
      en: "New product, thermal imaging observation camera: the Lahoux Spotter NL series (4 versions: 325, 350, 625, 650).",
      nl: "Nieuw product, warmtebeeld observatiekijker: de Lahoux Spotter NL serie (4 versies: 325, 350, 625, 650).",
      de: "Neues Product, Wärmebild-Beobachtungskamera: die Lahoux Spotter NL-Serie (4 Versionen: 325, 350, 625, 650).",
    },
    descWeight: {
        en: "We offer a 10% discount on the purchase price of the first 25 cameras sold during the fair.",
        nl: "Wij bieden 10% korting op de aankoopprijs van de eerste 25 camera's die tijdens de beurs worden verkocht.",
        de: "Auf die ersten 25 während der Messe verkauften Kameras gewähren wir einen Rabatt von 10% auf den Kaufpreis.",
    },
    offerForFrom: false,
    title_offer: {
      en: "Lahoux Spotter NL series (4 versions)",
      nl: "Lahoux Spotter NL serie (4 versies)",
      de: "Lahoux Spotter NL-Serie (4 Versionen)",
    },
    fromText: {
      en: "Normal price",
      nl: "Normale prijs",
      de: "Normalpreis",
    },
    forText: {
      en: "Exhibition price",
      nl: "Beursprijs",
      de: "Messepreis",
    },
    price_offer_ex_vat: null,
    price_regular_ex_vat: null,
    specialVAT: 19,
    specialVATText: 20,
    specialCountry: {
      en: "",
      nl: "",
      de: "",
    },
    button_text: {
      en: "More about Spotter NL 325",
      nl: "Meer over Spotter NL 325",
      de: "Mehr über Spotter NL 325",
    },
    url: "producten/Lahoux-Spotter-NL-325",
    button_text_2: {
      en: "More about Spotter NL 350",
      nl: "Meer over Spotter NL 350",
      de: "Mehr über Spotter NL 350",
    },
    url_2: "producten/Lahoux-Spotter-NL-350",
    button_text_3: {
      en: "More about Spotter NL 625",
      nl: "Meer over Spotter NL 625",
      de: "Mehr über Spotter NL 625",
    },
    url_3: "producten/Lahoux-Spotter-NL-625",
    button_text_4: {
      en: "More about Spotter NL 650",
      nl: "Meer over Spotter NL 650",
      de: "Mehr über Spotter NL 650",
    },
    url_4: "producten/Lahoux-Spotter-NL-650",
    img: "Lahoux-Spotter-NL-series.png",
    end_date: "2023-02-19",
  },

  {
    id: 2,
    title: {
      en: "Lahoux Horus",
      nl: "Lahoux Horus",
      de: "Lahoux Horus",
    },
    sub_title: {
      en: "Special exhibition offer:",
      nl: "Speciale beursaanbieding:",
      de: "Sonderangebot zur Ausstellung:",
    },
    desc: {
      en: "The Lahoux Horus Thermal Clip-on.",
      nl: "De Lahoux Horus thermische voorzetkijker.",
      de: "Das Lahoux Horus Wärmebild Vorsatzgerät.",
    },
    descWeight: {
        en: "A very generous discount for this exhibition!",
        nl: "Een zeer grote korting tijdens deze beurs!",
        de: "Ein sehr großer Rabatt während dieser Messe!",
    },
    offerForFrom: true,
    title_offer: {
      en: "Lahoux Horus",
      nl: "Lahoux Horus",
      de: "Lahoux Horus",
    },
    fromText: {
      en: "Normal price",
      nl: "Normale prijs",
      de: "Normalpreis",
    },
    forText: {
      en: "Exhibition price",
      nl: "Beursprijs",
      de: "Messepreis",
    },
    price_offer_ex_vat: 2432.77,
    price_regular_ex_vat: 3357.14,
    specialVAT: 19,
    specialVATText: 20,
    specialCountry: {
      en: "Austria",
      nl: "Oostenrijk",
      de: "Österreich",
    },
    button_text: {
      en: "More about Lahoux Horus",
      nl: "Meer over Lahoux Horus",
      de: "Mehr über Lahoux Horus",
    },
    url: "/producten/Lahoux-Horus",
    img: "Lahoux-Horus.png",
    end_date: "2023-02-19",
  },

  {
    id: 3,
    title: {
      en: "Lahoux Buzzard",
      nl: "Lahoux Buzzard",
      de: "Lahoux Buzzard",
    },
    sub_title: {
      en: "Special exhibition offer:",
      nl: "Speciale beursaanbieding:",
      de: "Sonderangebot zur Ausstellung:"
    },
    desc: {
      en: "The Lahoux Buzzard clip-on thermal imaging including drone, monitor and tripod.",
      nl: "De Lahoux Buzzard clip-on thermisch beeldcamera inclusief drone, monitor en statief.",
      de: "Das Lahoux Buzzard Clip-on Wärmebildkamera inklusive Drohne, Monitor und Stativ.",
    },
    offerForFrom: true,
    title_offer: {
      en: "Lahoux Buzzard + drone + monitor + tripod",
      nl: "Lahoux Buzzard + drone + monitor + statief",
      de: "Lahoux Buzzard + Drohne + Monitor + Stativ",
    },
    fromText: {
      en: "Normal price",
      nl: "Normale prijs",
      de: "Normalpreis",
    },
    forText: {
      en: "Exhibition price",
      nl: "Beursprijs",
      de: "Messepreis",
    },
    price_offer_ex_vat: 2393.27,
    price_regular_ex_vat: 2681.00,
    specialVAT: 19,
    specialVATText: 20,
    specialCountry: {
      en: "Austria",
      nl: "Oostenrijk",
      de: "Österreich",
    },
    button_text: {
      en: "More about Lahoux Buzzard",
      nl: "Meer over Lahoux Buzzard",
      de: "Mehr über Lahoux Buzzard",
    },
    url: "/producten/Lahoux-Buzzard-set",
    img: "Lahoux-Buzzard-set.png",
    end_date: "2023-02-19",
  },

];
export default DataCardsHoheJagdFischerei;
