import React from "react";
import styled from "styled-components";
import zwijnen from "./../assets/zwijnen.mp4";
import VideoElement from "./VideoElement";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HoheJagdUndFischereiHeroImgDE from "./../assets/Hohe-Jagd-und-Fischerei-Lahoux-offers-DE.png";
import HoheJagdUndFischereiHeroImgNL from "./../assets/Hohe-Jagd-und-Fischerei-Lahoux-offers-NL.png";
import HoheJagdUndFischereiHeroImgEN from "./../assets/Hohe-Jagd-und-Fischerei-Lahoux-offers-EN.png";

const Home = ({checkEndDate}) => {
  const { t, i18n } = useTranslation();

  // Add translatable alt-text for image
  const imgAltText = {
    en: "Die Hohe Jagd & Fischerei Salzburg 2023 promotions",
    nl: "Die Hohe Jagd & Fischerei Salzburg 2023 acties",
    de: "Die Hohe Jagd & Fischerei Salzburg 2023 Sonderangebote",
  };

  return (
    <StyledHome>
      {checkEndDate("2023-02-19") ? ( 
            <div className="hero">
              <Link to="/hohe-jagd-und-fischerei" className="hero-img-link">
                {i18n.language === "nl" ? (
                  <img
                    src={HoheJagdUndFischereiHeroImgNL}
                    alt={imgAltText[i18n.language]}
                    className="hero-img"
                  />
                ) : i18n.language === "de" ? (
                  <img
                    src={HoheJagdUndFischereiHeroImgDE}
                    alt={imgAltText[i18n.language]}
                    className="hero-img"
                  />
                ) : (
                  <img
                    src={HoheJagdUndFischereiHeroImgEN}
                    alt={imgAltText[i18n.language]}
                    className="hero-img"
                  />
                )}
              </Link>

              <div className="heroCopy">
                <h1>{t("hohe_jagd_und_fischerei.home_title")}</h1>
                <p>
                  {t("hohe_jagd_und_fischerei.home_copy")}
                  <Link to="/hohe-jagd-und-fischerei"> {t("hohe_jagd_und_fischerei.home_button")}</Link>
                </p>
              </div>
            </div>
      ) : 
            <div className="hero">
              <div className="heroCopy">
                <h1>{t("home_title")}</h1>
                <p>{t("home_copy")}
                  <Link to="/techniek"> {t("home_button")}</Link>
                </p>
              </div>
              <VideoElement video={zwijnen} videoRoundClass={"video-round"} />
            </div>
      }
          </StyledHome>
        );
};

const StyledHome = styled.div`
  position: relative;
  z-index: 1;
  .hero {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 100px;
    margin-bottom: 100px;
    /* padding-top: 200px; */

    /* @media (min-width: 1024px) {
      padding-top: 116px;
    }
    @media (min-width: 1380px) {
      padding-top: 0;
    } */
  }

  h1 {
    border-bottom: 4px solid var(--brand-accent-color);
    padding-bottom: 6px;
    margin-bottom: 10px;
    display: inline;
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    margin-top: 20px;
    @media (min-width: 768px) {
      margin-top: 0px;
    }
  }
  .heroCopy {
    display: flex;
    flex-direction: column;
    max-width: 550px;
    padding: 15px;

    /* @media (min-width: 1024px) {
      margin-top: 130px;
    } */
  }
  p {
    font-size: 22px;
    margin-bottom: 40px;
    line-height: 26px;
  }
  p a {
    font-weight: bold;
    text-decoration: underline;
    color: #fff;
  }
  p a:active,
  p a:hover,
  p a:visited {
    color: #fff;
  }

  .video-round {
    width: 300px;
    height: 300px;
    z-index: 1;

    @media (min-width: 1024px) {
      width: 350px;
      height: 350px;
      margin-top: 130px;
    }
  }

  .hero-img {
    width: 90%;
    /* height: 300px; */
    /* min-width: 320px; */
    z-index: 1;

    @media (min-width: 1024px) {
      width: 700px;
      /* height: 350px; */
      /* margin-top: 130px; */
    }
  }

  .hero-img-link {
    @media (max-width: 1024px) {
      width: 90%;
    }
  }

  .rozet-link {
    position: absolute;
    right: 34px;
    top: -100px;
  }

  .rozet {
    width: 250px;
    transform: rotate(12deg);
    cursor: pointer;

    @media (min-width: 1024px) {
      width: 300px;
    }
  }
`;
export default Home;
