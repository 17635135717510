import React, {useState, useEffect} from "react";
import Seo from './Seo';
import DataProducts from './DataProducts'
import styled from 'styled-components';
import {
    Link
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../locales/i18n";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import SelectFilter from './SelectFilter';

const Productoverview = ({ clientCountry, showItems, setShowItems, clickHandlerShowItem, searchValue, setSearchValue, statusFilter, changeHandlerStatusFilter, scrollPosition, setScrollPosition }) => {
    const { t, i18n } = useTranslation();

    // 19-04-2022 PP
    // check if showItems is event or archive
    // if so, set showItem to standard thermal on page load to prevent empty tab/list 
    // (because this page does not know of event or archive)
    useEffect(() => {
        // window.scrollTo(0, 0);

        if (showItems === "events" || showItems === "archive") {
            setShowItems("thermal");
        }

        if (scrollPosition > 0) {
            window.scrollTo(0, parseInt(scrollPosition));
            setScrollPosition(0);
          } else {
            window.scrollTo(0, 0);
          }

      }, []);


    // make description meta tag in Helmet get translatable content
    const helmetDescription = {
      en: "Product overview of Lahoux night vision, digital and thermal imaging scopes",
      nl: "Productoverzicht van Lahoux nachtkijkers, digitale en warmtebeeldkijkers",
      de: "Produktübersicht der Nachtsicht-, Digital- und Wärmebildgeräte von Lahoux",
    };

    const sortProducts = (a, b) => {
        return a.orderID - b.orderID
    }    

    const filteredProducts = DataProducts
    // .filter(product =>  product.type === showItems)
    
    .filter(product =>  showItems === "all" ? product : product.type === showItems)
    .sort(sortProducts)

    // Search
    .filter(product => product.title[i18n.language].toLowerCase().includes(searchValue.toLowerCase()) || product.shortDes[i18n.language].toLowerCase().includes(searchValue.toLowerCase()))
    
     // Filter voor voorzet of handheld _ radio!
    //  .filter(product => statusFilter.filterType === "no preference" || product.filter?.filterType === statusFilter.filterType)
    
     //filter use field / bos per stuk apart. als de statusfilter true is, dan mag je filteren op producten waarbij filter.filterUseField true is. 
     .filter (product => statusFilter.filterNew === true ? product.new === true : true)
     .filter(product => statusFilter.filterObservation === true ? product.filter?.filterObservation ===  true : true)
     .filter(product => statusFilter.filterVoorzet === true ? product.filter?.filterVoorzet ===  true : true)
     .filter(product => statusFilter.filterRiflescope === true ? product.filter?.filterRiflescope ===  true : true)
     .filter(product => statusFilter.filterThermal === true ? product.filter?.filterThermal ===  true : true)
     .filter(product => statusFilter.filterNightvision === true ? product.filter?.filterNightvision ===  true : true)
     .filter(product => statusFilter.filterDigital === true ? product.filter?.filterDigital ===  true : true)
    
    
     const sortPrice = (a, b ) => {
        if (statusFilter.sortPrice === "sortUp"){
            return a.price- b.price;
        } 
        else if (statusFilter.sortPrice === "sortDown")
        {return b.price - a.price;}
        else {return null}
    } 

    return (
        <Styledoverview>

            <Seo 
                title={t("products_page_title")} 
                description={helmetDescription[i18n.language]} 
                url="https://lahouxoptics.com/producten" 
                image="https://lahouxoptics.com/productafbeeldingen/wm1_1.png" 
            />

            <SelectFilter searchValue={searchValue} setSearchValue={setSearchValue} statusFilter={statusFilter} changeHandlerStatusFilter={changeHandlerStatusFilter}/>

            <div className="productoverview">
                <ul>
                    {(filteredProducts.length > 0) ?
                    
                    filteredProducts
                        // .filter(product => product.type === showItems)
                        // .sort((a, b) => a.orderID - b.orderID)
                        .sort(sortPrice)
                        .map(product => (
                            <Link to={`/producten/${product.url/*[i18n.language]*/}`} key={product.id} onClick={() => setScrollPosition(window.pageYOffset)}>
                                <li className={`content ${product.endOfLife ? "product-unavailable" : ""}`} >
                                    <img src={"/productafbeeldingen/" + product.images[0]} alt={product.title[i18n.language]} loading="lazy" />  
                                    <div className="copy">
                                        {product.new && <p className="banner">{t("specs_new")}</p>}
                                        {product.available_date && <p className="available-date">{product.available_date[i18n.language]}</p>}
                                        {product.endOfLife && <p className="product-warning"> <FontAwesomeIcon icon={faExclamationTriangle} />  {t("product_no_longer_available")}</p>}
                                        <h2>{product.title[i18n.language]}</h2>
                                        {product.shortDes && 
                                            <p>{product.shortDes[i18n.language]}</p>
                                        }
                                    </div>
                                </li>
                            </Link>
                        ))
                        :
                        <li>{t("no_products_found")}</li>
                    }
                </ul>
            </div>
        </Styledoverview>
    )
}

const Styledoverview = styled.div`
    margin: 10px auto 0 auto;
    max-width: 90vw;
    @media (min-width: 768px) {
        max-width: 70vw;
       }
   
       @media (min-width: 1024px) {
        max-width: 60vw;
       } 
    .content{
        display: flex;
        border-bottom: 4px solid var(--brand-accent-color);
        padding-bottom: 50px;
    }
    .content h2{
        font-size: 2.5rem;                     
    }
    
    .productoverview{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
         
    }
    .productoverview .content{
        margin: 0 auto 50px auto;
        display: flex;

        @media (max-width: 1200px) {
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 auto 30px auto;
        }  
    }
    .productoverview .content .copy{
        
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media (max-width: 1200px) {
            width: 100%;
        }  
    }
    .productoverview .content h2{
        font-size: 1.875rem;
        margin-bottom: 10px;
        font-weight: bold;
        padding-top: 10px;  
        border-bottom: 2px solid var(--brand-accent-color); 
        
        @media (max-width: 1200px) {
           font-size: 1.75rem;
        }
    }
    .productoverview .content p{
        margin-bottom: 10px;
        line-height: 24px;
        font-size: 1.25rem;

        @media (max-width: 1200px) {
            font-size: 1.125rem;
        }  
    }
    .productoverview a{
        text-decoration: none;
        color: #fff;
    }
 
    ul{
        margin-top: 50px;
        margin-bottom: 50px;
    }
    li {     
        animation-name: productitem;
        animation-duration: 2.5s;
        animation-timing-function: ease-out;

        @keyframes productitem {
            from {
            opacity: 0;
            }
            to {
            opacity: 1;
            }
        }
    }
    a:last-of-type .line{
        display: none;
     
    }
    img{
        align-self: center;
        width: 90%;
        
        @media (min-width: 465px) {
           width: 80%;
          }

          @media (min-width: 1000px) {
           width: 76%;
          }
      
          @media (min-width: 1200px) {
            width: 25vw;
          }  
    }
    .productoverview .content p.banner{
        background-color: var(--brand-accent-color);
        color: #fff;
        padding: 6px 11px;
        border-radius: 12%;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.125rem;
        }
    
    .productoverview .content .available-date {
        font-style: italic;
        font-size: 1.125rem;
    }

    .productoverview .content .product-warning {
        font-weight: 700;
        font-style: italic;
        font-size: 1.125rem;
    }

    .productoverview .content.product-unavailable img,
    .productoverview .content.product-unavailable .copy h2,
    .productoverview .content.product-unavailable .copy p:not(.product-warning) {
        opacity: 0.4;
    }

`
export default Productoverview