import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
 
import { TRANSLATIONS_NL } from "./nl/translations";
import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_DE } from "./de/translations";
 
i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
   resources: {
     en: {
       translation: TRANSLATIONS_EN
     },
     nl: {
       translation: TRANSLATIONS_NL
     },
     de: {
        translation: TRANSLATIONS_DE
      }
   }, fallbackLng: "en", supportedLngs: ['en', 'de', 'nl']
 });
