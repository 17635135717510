/** Usage of available properties for a product
 *
 * @property id - Unique id for the product. Each id starts with a letter combination to mark the category: wm for Thermal; nz for Night vision; dg for Digital and as for Accessories
 * @property orderID - Unique id for sorting the products within a category
 * @property type - Denotes if product is of type thermal, night, digital or accessories. Based on this the product will show under the correct product tab
 * @property title - Title of the product in 3 languages (Example: title: {nl: "Lahoux Horus", en: "Lahoux Horus", de: "Lahoux Horus"})
 * @property url - The url of the product. Only set ones, so do not changes after it has been used (for Seo purposes). If you must change it, make sure to create a redirect from old url to new url
 * @property price - Price of the product in euro's excl. vat. 
 * @property new - Bolean true or false. Show a little block with 'New" with the product on the product overview page
 * @property available_date - Text in 3 languages to show when the product will be available
 * @property endOfLife - Boolean true or false. When true, product will be grayed out in product overview and will get a message 'This producyt is nom longer available'. This way the product info will still be accessable and link will continue to work for Seo purposes
 * @property warning - Boolean true or false. Used to show a warning text for Germany whether a product is forbidden by law
 * @property spotlight - Text in 3 languages used to highlight a product in the header of a prduct tab when this product is set to be used as a highlight in ProductHighlight.js
 * @property shortDes - Text in 3 languages to show a short description of the product on the product overview page (/producten)
 * @property longDes_1 - First paragraph with longer description text in 3 languages of the product shown on the product specs page of a single product (/producten/[product])
 * @property longDes_2 - Second paragraph with longer description text in 3 languages of the product shown on the product specs page of a single product (/producten/[product])
 * @property longDes_3 - Third paragraph with longer description text in 3 languages of the product shown on the product specs page of a single product (/producten/[product])
 * @property longDes_4 - Fourth paragraph with longer description text in 3 languages of the product shown on the product specs page of a single product (/producten/[product])
 * @property pros - Array of text in 3 languages which describes the advantages of the product and shows it as a bullet list on the product specs page of a single product (/producten/[product])
 * @property number - The article number of the product
 * @property ean - The EAN code of the product
 * @property leveromvang - Array of reference numbers referring to the accessories in DataLeveromvang.js which standard will be deliverd with the product
 * @property flyer - File name to the pdf's of the product flyer (in 3 languages). User will be able to download the flyer through a button on the product specs page. File need to be stored in 'public/productflyers' directory
 * @property images - File names of the images of the product. Images need to be stores in 'public/productafbeeldingen' directory
 * @property dri - File name to the dri table image. DRI image need to be stored in 'public/dri' directory
 * @property related  - Array with unique product id of other products that are related to the current product. Make sure the product id and product used here do excist
 * @property accessories - Array with unique accessories id of accessories that are available to the current product. Make sure the accessories id and accessories used here does excist
 * @property footnote_battery - Boolean true or false. If true, a text about usage conditions for battery life time apears under the list of technical specifications
 * @property techs - Holds the text of all product specifications in 3 languages. Object within categories with key-value pairs where key is the technical spec description and value is it's value
 * @property filter - Holds the different filter states for the current product as a boolean so you know if this product should appear in a specific filter category
 * @property videos - Object with with key-value pairs in 3 languages to show one or more video's on the product specs page. The key will be the video file name, the value will be the caption text. Video's need to be stores in 'public/productafbeeldingen' directory
 * @property photography - Object with with key-value pairs in 3 languages to show one or more photographs on the product specs page. The key will be the image file name, the value will be the caption text. Images need to be stores in 'public/productafbeeldingen' directory
 * @property important_info - Heading text in 3 languages for important info about the product a user needs to be aware of. Currently only used at the Buzzard
 * @property important_info_Des_1 - Paragraph text in 3 languages for important info about the product a user needs to be aware of. Currently only used at the Buzzard
 * @property important_info_list - List with items in 3 languages for important info about the product a user needs to be aware of. Currently only used at the Buzzard
 * 
 ** Extra properties or properties that are used differently for a night vision product
 * 
 * @property price - The price field for nightvision is only used for sorting purposes; the price for NV is calculated through the options, color and FOM values
 * @property options - These are the different options for night vision like color and FOM values on which the price of the NV product is eventually calculated
 * @property Groen - Specific color for the nightvision product
 * @property Onyx - Specific color for the nightvision product
 * @property 1200: (1400, 1500, etc): - The FOM value at a specific color; holds the Spot categories veel, weinig or geen and each of the spot categories holds the price and the article number for that specific FOM value and spot categorie
 * 
 * 
*/

const DataProducts = [

// **** Thermal ****
// Last used id: wm40

    {
		id: "wm1",
        orderID: "101",
        type: "thermal",
        title: {nl: "Lahoux Horus", en: "Lahoux Horus", de: "Lahoux Horus"},
        url: "Lahoux-Horus",
		price: "1928",
        spotlight: {nl: "De Lahoux Horus is een warmtebeeldkijker die alle noodzakelijke eisen van een jager succesvol met elkaar combineert, het is zowel geschikt voor de jager die geïnteresseerd is in de nieuwste innovaties, alsook voor de jager die een simpele en intuïtieve bediening wenst.", 
                    en: "The Lahoux Horus is a thermal device that successfully combines all the necessary requirements of a hunter, it is suitable for the hunter who is interested in the latest innovations, as well as for the hunter who wants simple and intuitive operation.", 
                    de: "Der holländische Nachtsicht- und Wärmebildgerätehersteller Lahoux Optics präsentierte mit dem Lahoux Horus ein Wärmebildvorsatzgerät der neuesten Generation. Dieses komplett in Westeuropa konstruierte und gebaute Gerät kann in der Ganzheit seiner Eigenschaften als einzigartig bezeichnet werden."},
        shortDes: { nl:"De Lahoux Horus is een warmtebeeldkijker die alle noodzakelijke eisen van een jager succesvol met elkaar combineert, het is zowel geschikt voor de jager die geïnteresseerd is in de nieuwste innovaties, alsook voor de jager die een simpele en intuïtieve bediening wenst. De nieuwste en hoogste productienormen maken de Horus een betrouwbaar hulpmiddel voor natuurobservatie en dagelijkse jacht. Een nauwkeurig scherpstelmechanisme maakt een gedetailleerde beeldreproductie mogelijk.", 
                    en: "The Lahoux Horus is a thermal device that successfully combines all the necessary requirements of a hunter, it is suitable for the hunter who is interested in the latest innovations, as well as for the hunter who wants simple and intuitive operation. State-of-the-art and highest production standards make the Horus a reliable tool for nature observation and everyday hunting. A precise focusing mechanism allows detailed image reproduction.", 
                    de: "Der holländische Nachtsicht- und Wärmebildgerätehersteller Lahoux Optics präsentierte mit dem Lahoux Horus ein Wärmebildvorsatzgerät der neuesten Generation. Dieses komplett in Westeuropa konstruierte und gebaute Gerät kann in der Ganzheit seiner Eigenschaften als einzigartig bezeichnet werden."},
        longDes_1:{ nl:"De Lahoux Horus is een warmtebeeldkijker die alle noodzakelijke eisen van een jager succesvol met elkaar combineert, het is zowel geschikt voor de jager die geïnteresseerd is in de nieuwste innovaties, alsook voor de jager die een simpele en intuïtieve bediening wenst. De nieuwste en hoogste productienormen maken de Horus een betrouwbaar hulpmiddel voor natuurobservatie en dagelijkse jacht. Een nauwkeurig scherpstelmechanisme maakt een gedetailleerde beeldreproductie mogelijk.", 
                    en: "The Lahoux Horus is a thermal device that successfully combines all the necessary requirements of a hunter, it is suitable for the hunter who is interested in the latest innovations, as well as for the hunter who wants simple and intuitive operation. State-of-the-art and highest production standards make the Horus a reliable tool for nature observation and everyday hunting. A precise focusing mechanism allows detailed image reproduction.", 
                    de: "Neueste Technologie wie 12 Mikron Pixelgröße, innenliegende Steuerung des Fokus sowie innovativste Systemkomponenten, die eine bis zu 10-fache Vergrößerung der Primäroptik erlaubt, setzen bei diesem neuem Gerät Maßstäbe. Die intelligente und effiziente Stromversorgung sichert eine bis zu 12-stündige Betriebsdauer und gewährleistet durch den Verpolungsschutz einen schnellen und fehlerfreien Akkuwechsel auch im Dunkeln. Die intuitive und einfache Bedienung des Horus wurde durch optimierte und praxisbezogene Tastenbelegung erreicht, beispielsweise der “Momentary Zoom” springt automatisch und blitzschnell von der dreifachen digitalen Vergrößerung zurück in die Nullposition."},
        longDes_2:{ nl:"De Lahoux Horus is uitgerust met de innovatieve 12 micron pixelpitch technologie, die de kijker compacter maakt, voor een scherper beeld zorgt en vergrotingen van de primaire optiek tot 10x mogelijk maakt zonder dat het beeld aan kwaliteit inboet. Hierdoor is wild aanspreken op grotere afstanden mogelijk. De intelligente en efficiënte elektronica zorgen voor een batterijduur van 12 uur. De accu kan willekeurig in het batterij vak geplaatst worden, zodat in het donker een snelle en foutloze accuwissel mogelijk is. De Lahoux Horus is eenvoudig en intuïtief in zijn bediening, je kunt met de Lahoux Horus niet per ongeluk in een verkeerde modus terechtkomen, met alle consequenties van dien. Bijvoorbeeld de digitale driemaal vergroting gaat automatisch terug naar de uitgangspositie.", 
                    en: "The Lahoux Horus is equipped with the innovative 12 micron pixel pitch technology, which makes the thermal imaging camera more compact, provides a sharper image and allows enlargements of the primary optics up to 10x without compromising on quality. This makes it possible to approach wildlife at greater distances. The intelligent and efficient electronics ensure a battery life of 12 hours. The battery can be placed both ways in the battery compartment, so that a quick and error-free battery change is possible in the dark. The Lahoux Horus is simple and intuitive in its operation, you cannot accidentally end up in the wrong mode with the Lahoux Horus, with all its consequences. For example the digital three-fold magnification automatically returns to the starting position.", 
                    de: "Das Lahoux Horus bedient durch sein Konzept den an Innovationen interessierten Anwender einerseits, sowie den an einer simplen und intuitiven Bedienung orientierten Jäger andererseits. Modernste und präzise Eigenschaften machen das Horus zu einem zuverlässigen Werkzeug für den jagdlichen Alltag."},
        pros:  {    nl: ["Het eerste multifunctionele warmtebeeldapparaat met 12 micron-technologie", "Geoptimaliseerd voor gebruik met vergroting vanaf 6x en hoger", "Zuinige elektronica voor een lange batterijduur van 12 uur", "Eenvoudig in het gebruik", "Compact en licht in gewicht", "Uniek Lahoux ontwerp" ], 
                    en: ["The first multi-purpose thermal imaging device with 12 micron technology", "Optimized for use with primary optics magnification of 6x and up", "Economical electronics for a long battery life of 12 hours", "Easy to use", "Compact and lightweight", "Unique Lahoux design"], 
                    de: ["Erster Wärmebild Vorsatz mit 12 Mikron Technologie", "Optimiert für die Verwendung bis zu 10-fache Vergrößerung", "Effiziente Elektronik für eine lange Akkulaufzeit von 12 Stunden", "Einfach zu bedienen", "Kompakt und leicht im Gewicht", "Einzigartiges Lahoux-Design"]},
        number: "02-0002-02447",
        ean: "6090300901903",               
        leveromvang: [2, 9, 16, 3, 4],
        jubilee_offer: false,
        flyer: {nl:"Lahoux_Horus_NL_V2.pdf", en: "Lahoux_Horus_EN_V2.pdf", de: "Lahoux_Horus_DE_V2.pdf"},
        images: ["wm1_1.png", "wm1_2.png", "wm1_3.png", "wm1_4.png"],
        dri: "Warmtebeeld_Voorzetten.png",
        related: ["wm18",  "wm20"],
        accessories: ["as6", "as10"],
        footnote_battery: true,
        techs: {
            Detector: { 
                en: {"Resolution": "320 × 256", "Pixel size": "12 µm", "Frequency": "60 Hz", "Spectral range": "8-14 µm", "Sensitivity(NETD) Standard Version": "<60 mK"},
                nl: {"Resolutie": "320 × 256", "Pixelgrootte": "12 µm", "Frequentie": "60 Hz", "Spectraalbereik": "8-14 µm", "Gevoeligheid (NETD) Standaardversie": "<60 mK"},
                de: {"Auflösung": "320 × 256", "Pixelgröße": "12 µm", "Frequenz": "60 Hz", "Spektrallbereich": "8-14 µm", "Empfindlichkeit (NETD) Standardversion": "<60 mK"}
            },
            Optics: {
                en: {"Lens": "40mm Ophir Germanium", "Relative aperture": "F/1.0", "Ocular Diameter": "29 mm", "Field of View (hxv)": "5.5°x4.4°", "Focus range": "10m to ∞", "Magnification (Digital)": "3x Momentary Zoom"},
                nl: {"Lens": "40mm Ophir Germanium", "Relatieve lensopening": "F/1.0", "Oculair Diameter": "29 mm", "Gezichtsveld (hxv)": "5.5°x4.4°", "Focusbereik": "10m to ∞", "Vergroting (digitaal)": "3x Momentzoom"},
                de: {"Linse": "40mm Ophir Germanium", "Relative Blende": "F/1.0", "Okular-Durchmesser": "29 mm", "Sichtfeld (hxv)": "5.5°x4.4°", "Fokusbereich": "10m to ∞", "Vergrößerung (digital)": "3x kurzzeitiger Zoom"}
            },
            Electronics: {
                en: {"Battery": "18650 3,7V rechargeable Lithium-Ion", "Battery life": "Up to 12 Hours (at 21°C) *", "Display": "OLED"},
                nl: {"Batterij": "18650 3,7V oplaadbare Lithium-Ion", "Batterijduur": "Tot 12 uur (bij 21°C) *", "Beeldscherm": "OLED"},
                de: {"Batterie": "18650 3,7V wiederaufladbare Lithium-Ion", "Batterielaufzeit": "Bis zu 12 Stunden (bei 21°C) *", "Anzeige": "OLED"}
            },
            Operational: {
                en: {"Operating temperature range": "-20°C to 40°C", "Watertight": "IP67", "Dimensions" : "148x70x74 mm", "Weight without batteries": "0,45 kg"},
                nl: {"Bedrijfstemperatuurbereik": "-20°C to 40°C", "Waterdichtheid": "IP67", "Afmetingen" : "148x70x74 mm", "Gewicht zonder batterijen": "0,45 kg"},
                de: {"Betriebstemperaturbereich": "-20°C to 40°C", "Wasserfest": "IP67", "Abmessungen" : "148x70x74 mm", "Gewicht ohne Batterien": "0,45 kg"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: true, filterRiflescope: false}
	},

    // *** Remove Lahoux Spotter 19 from site: EOL; keep data here for future use ***
    // {
	// 	id: "wm2",
    //  type: "thermal",
	// 	title: "Lahoux Spotter 19",
    //  url: "Spotter-19",
	// 	price: "1351.43",
    //  warning: false,    
    //  number: "02-0006-02839",
    //  images: ["wm2_1.png", "wm2_2.png", "wm2_3.png", "wm2_4.png"],
    //  related: ["wm8", "wm9", "wm6", "wm10", "wm3"],
    //  accessories: ["as13"],
    //  techs: {
    //      Detector: { "Resolution VOx uncooled": "384 × 288", "Pixel pitch": "12 µm", "Spectral range": "8-14 µm", "Sensitivity (NETD)": "< 40 mK", "Frame Rate": "50 Hz"},
    //      Optics: {"Lens": "19 mm", "Magnification (digital)": "1x, 2x, 3×, 4×", "Eye relief": "20 mm"},
    //      Electronics: {"Internal memroy": "16 GB", "Display LCOS": "1280 × 960"},
    //      Operational: {"Temperature range": "−20  °C to +50 °C", "Power supply": "Exchangeable li-ion battery", "Battery runtime" : "7.5 hours", "Enscapsulation": "IP67", "Dimensions": "165×50×85 mm", "Weight": "0,38 kg" }
    //     }        
	// },	

/*
    *** Remove Lahoux Spotter 25 LRF from site: EOL; keep data here for future use ***
    {
		id: "wm3",
        orderID: "12",
        type: "thermal",
        title: {nl: "Lahoux Spotter 25 LRF", en: "Lahoux Spotter 25 LRF", de: "Lahoux Spotter 25 LRF"},
        url: "Spotter-25-LRF",
		price: "2121.43",
        shortDes: {     nl:"De Lahoux Spotter 25 LRF is de ideale metgezel voor de mobiele gebruiker; bijvoorbeeld bij het jagen of wandelen. Door het lichte en compacte ontwerp is de Lahoux Spotter 25 LRF gemakkelijk mee te nemen in uw jaszak en zeer geschikt om met één hand te worden bediend.", 
                        en: "The Lahoux Spotter 25 LRF is the ideal companion for the mobile user; for example when hunting or hiking. The light and compact design makes the Lahoux Spotter 25 LRF easy to carry in your pocket and very suitable to be operated with one hand.", 
                        de: "Der Lahoux Spotter 25 LRF ist die idealen Begleiter für den mobilen Anwender; zum Beispiel beim Jagen oder Wandern. Durch die leichte und kompakte Bauweise ist der Lahoux Spotter 25 LRF leicht in der Jackentasche zu transportieren und sehr gut geeignet um sie mit einer Hand bedienen zu können."},
        longDes_1: {    nl:"De Lahoux Spotter 25 LRF is de ideale metgezel voor de mobiele gebruiker; bijvoorbeeld bij het jagen of wandelen. Door het lichte en compacte ontwerp is de Lahoux Spotter 25 LRF gemakkelijk mee te nemen in uw jaszak en zeer geschikt om met één hand te worden bediend.", 
                        en: "The Lahoux Spotter 25 LRF is the ideal companion for the mobile user; for example when hunting or hiking. The light and compact design makes the Lahoux Spotter 25 LRF easy to carry in your pocket and very suitable to be operated with one hand.", 
                        de: "Der Lahoux Spotter 25 LRF ist die idealen Begleiter für den mobilen Anwender; zum Beispiel beim Jagen oder Wandern. Durch die leichte und kompakte Bauweise ist der Lahoux Spotter 25 LRF leicht in der Jackentasche zu transportieren und sehr gut geeignet um sie mit einer Hand bedienen zu können."},
        longDes_2: {    nl:"De Lahoux Spotter 25 LRF heeft een hoge beeldkwaliteit en het hoge resolutie scherm van 1280 × 960 px zorgt voor een scherp beeld met levendige kleuren. Samen met de hoge verversingssnelheid van 50 Hz krijgt u een uitstekend bruikbaar beeld en een goed situationeel bewustzijn. De 25 mm lens biedt een breed gezichtsveld en stelt u in staat meer objecten waar te nemen. De pixelafstand van 12 μm van de detector levert een uitstekend beeld op en maakt detectie over een grote afstand mogelijk.", 
                        en: "The Lahoux Spotter 25 LRF has a high image quality and the high resolution screen of 1280 × 960 px ensures a sharp image with vivid colours. Together with the high refresh rate of 50 Hz, you get an excellent usable image and good situational awareness. The 25 mm lens provides a wide field of vision and allows you to observe more objects. The 12 μm pixel pitch detector provides an excellent image and allows detection over a large distance.", 
                        de: "Der Lahoux Spotter 25 LRF hat eine hohe Bildqualität und der hochauflösende Bildschirm von 1280 × 960 px liefert ein scharfes Bild mit lebendigen Farben. Zusammen mit der hohen Bildwiederholrate von 50 Hz erhalten Sie ein hervorragend nutzbares Bild und ein gutes Situationsbewusstsein. Das 25-mm-Objektiv bietet ein weites Sichtfeld und ermöglicht die Beobachtung weiterer Objekte. Der 12-μm-Pixelabstand des Detektors liefert ein hervorragendes Bild und ermöglicht die Erfassung über eine große Entfernung."},
        pros: {     nl: ["Geïntegreerde laser-rangefinder (LRF) toont u direct op welke afstand uw doel zich bevindt", "Indien nodig, kunt u de LRF zelfs continu laten meten", "Foto’s en video’s maken en deze intern opslaan", "Automatische uitschakeling van het display bespaart de batterij", "WiFi", "Licht en compact ontwerp"], 
                    en: ["Integrated laser rangefinder (LRF) shows you immediately at which distance your target is located", "If necessary, you can even have the LRF measure continuously", "Take photos and videos and store them internally", "Automatic switch-off of the display saves battery", "WiFi", "Light and compact design"], 
                    de: ["Integrierte Laser-Entfernungsmesser (LRF) zeigt Ihnen sofort, in welcher Entfernung sich Ihr Ziel befindet", "Falls erforderlich, können Sie den LRF sogar kontinuierlich messen lassen", "Bilder und Videos aufnehmen und intern abspeichern", "WiFi", "Leichtes und kompaktes Design"]
            },
        warning: false,
        number: "02-0006-03037",
        flyer: {nl:"Lahoux_Spotter_25_LRF_NL_V1.pdf", en: "Lahoux_Spotter_25_LRF_EN_V1.pdf", de: "Lahoux_Spotter_25_LRF_DE_V1.pdf"},
        images: ["wm3_1.png", "wm3_2.png", "wm3_3.png",  "wm3_4.png"],
        dri: "Warmtebeeld_Spotters.png",
        related: ["wm4", "wm8", "wm10"],
        accessories: ["as13"],
        footnote_battery: true,
        techs: {
            Detector: { "Resoluction VOx uncooled": "384 × 288", "Pixel pitch": "12 µm", "Sensitivity (NETD)": "< 40 mK", "Frame Rate": "50 Hz"},
            Optics: {"Lens": "25 mm", "Field of view": "10.5° × 7.9°", "Magnification (digital)": "1×, 1.5×, 2×, 2.5×, 3×, 3.5×, 4×", "Diopter adjustment": "-4 to +5 D"},
            Electronics: {"Internal memory": "16 GB", "Display LCOS": "1280 × 960"},
            Operational: {"Laser rangefinder range": "600 m", "Power supply": "Li-ion battery", "Battery runtime" : "6 hours (at 21°C) *", "Encapsulation": "IP67", "Dimensions": "70×52×130 m", "Weight": "0,32 kg" }
        }
	},	
*/

/*
    *** Remove Lahoux Spotter Elite 25 LRF from site: EOL; keep data here for future use ***
    {
		id: "wm4",
        orderID: "13",
        type: "thermal",
        title: {nl: "Lahoux Spotter Elite 25 LRF", en: "Lahoux Spotter Elite 25 LRF", de: "Lahoux Spotter Elite 25 LRF"},
        url: "Spotter-Elite-25-LRF",
		price: "2507.14",
        shortDes: {     nl:"De Lahoux Spotter Elite 25 LRF is de ideale metgezel voor de mobiele gebruiker; bijvoorbeeld bij het jagen of wandelen. Door het lichte en compacte ontwerp is de Lahoux Spotter Elite 25 LRF gemakkelijk mee te nemen in uw jaszak en zeer geschikt om met één hand te worden bediend.", 
                        en: "The Lahoux Spotter Elite 25 LRF are the ideal companion for the mobile user; for example when hunting or hiking. The light and compact design makes the Lahoux Spotter Elite 25 LRF easy to carry in your pocket and very suitable to be operated with one hand.", 
                        de: "Der Lahoux Spotter Elite 25 LRF ist die idealen Begleiter für den mobilen Anwender; zum Beispiel beim Jagen oder Wandern. Durch die leichte und kompakte Bauweise ist der Lahoux Spotter Elite 25 LRF leicht in der Jackentasche zu transportieren und sehr gut geeignet um sie mit einer Hand bedienen zu können."},
        longDes_1: {    nl:"De Lahoux Spotter Elite 25 LRF is de ideale metgezel voor de mobiele gebruiker; bijvoorbeeld bij het jagen of wandelen. Door het lichte en compacte ontwerp is de Lahoux Spotter Elite 25 LRF gemakkelijk mee te nemen in uw jaszak en zeer geschikt om met één hand te worden bediend.", 
                        en: "The Lahoux Spotter Elite 25 LRF is the ideal companion for the mobile user; for example when hunting or hiking. The light and compact design makes the The Lahoux Spotter Elite 25 LRF easy to carry in your pocket and very suitable to be operated with one hand.", 
                        de: "Der Lahoux Spotter Elite 25 LRF ist die idealen Begleiter für den mobilen Anwender; zum Beispiel beim Jagen oder Wandern. Durch die leichte und kompakte Bauweise ist der Lahoux Spotter Elite 25 LRF leicht in der Jackentasche zu transportieren und sehr gut geeignet um sie mit einer Hand bedienen zu können."},
        longDes_2: {    nl:"De Lahoux Spotter Elite 25 LRF heeft een hoge beeldkwaliteit en het hoge resolutie scherm van 1280 × 960 px zorgt voor een scherp beeld met levendige kleuren. Samen met de hoge verversingssnelheid van 50 Hz krijgt u een uitstekend bruikbaar beeld en een goed situationeel bewustzijn. De 25 mm lens biedt een breed gezichtsveld en stelt u in staat meer objecten waar te nemen. De pixelafstand van 12 μm van de detector levert een uitstekend beeld op en maakt detectie over een grote afstand mogelijk.", 
                        en: "The Lahoux Spotter Elite 25 LRF has a high image quality and the high resolution screen of 1280 × 960 px ensures a sharp image with vivid colours. Together with the high refresh rate of 50 Hz, you get an excellent usable image and good situational awareness. The 25 mm lens provides a wide field of vision and allows you to observe more objects. The 12 μm pixel pitch detector provides an excellent image and allows detection over a large distance.", 
                        de: "Der Lahoux Spotter Elite 25 LRF hat eine hohe Bildqualität und der hochauflösende Bildschirm von 1280 × 960 px liefert ein scharfes Bild mit lebendigen Farben. Zusammen mit der hohen Bildwiederholrate von 50 Hz erhalten Sie ein hervorragend nutzbares Bild und ein gutes Situationsbewusstsein. Das 25-mm-Objektiv bietet ein weites Sichtfeld und ermöglicht die Beobachtung weiterer Objekte. Der 12-μm-Pixelabstand des Detektors liefert ein hervorragendes Bild und ermöglicht die Erfassung über eine große Entfernung."},
        pros: {     nl: ["Geïntegreerde laser-rangefinder (LRF) toont u direct op welke afstand uw doel zich bevindt", "Indien nodig, kunt u de LRF zelfs continu laten meten", "Foto’s en video’s maken en deze intern opslaan", "Automatische uitschakeling van het display bespaart de batterij", "WiFi", "Licht en compact ontwerp"], 
                    en: ["Integrated laser rangefinder (LRF) shows you immediately at which distance your target is located", "If necessary, you can even have the LRF measure continuously", "Take photos and videos and store them internally", "Automatic switch-off of the display saves battery", "WiFi", "Light and compact design"], 
                    de: ["Integrierte Laser-Entfernungsmesser (LRF) zeigt Ihnen sofort, in welcher Entfernung sich Ihr Ziel befindet", "Falls erforderlich, können Sie den LRF sogar kontinuierlich messen lassen", "Bilder und Videos aufnehmen und intern abspeichern", "WiFi", "Leichtes und kompaktes Design"]
            },
        number: "02-0006-02842",
        ean: "6090301688636",
        flyer: {nl:"Lahoux_Spotter_25_LRF_NL_V1.pdf", en: "Lahoux_Spotter_25_LRF_EN_V1.pdf", de: "Lahoux_Spotter_25_LRF_DE_V1.pdf"},
        images: ["wm4_1.png", "wm4_2.png", "wm4_3.png", "wm4_4.png"],
        dri: "Warmtebeeld_Spotters.png",
        related: ["wm10", "wm12", "wm3"],
        accessories: ["as13"],
        footnote_battery: true,
        techs: {
            Detector: { "Resoluction VOx uncooled": "640 × 512", "Pixel pitch": "12 µm", "Sensitivity (NETD)": "< 40 mK", "Frame Rate": "50 Hz"},
            Optics: {"Lens": "25 mm", "Field of view": "17.5° × 14.0°", "Magnification (digital)": "1×, 1.5×, 2×, 2.5×, 3×, 3.5×, 4×", "Diopter adjustment": "-4 to +5 D"},
            Electronics: {"Internal memory": "16 GB", "Display LCOS": "1280 × 960"},
            Operational: {"Laser rangefinder range": "600 m", "Power supply": "Li-ion battery", "Battery runtime" : "6 hours (at 21°C) *", "Encapsulation": "IP67", "Dimensions": "70×52×130 m", "Weight": "0,32 kg" }
        }
	},
*/
    {
    id: "wm7",
    orderID: "102",
    type: "thermal",
    title: {nl: "Lahoux Horus Elite", en: "Lahoux Horus Elite", de: "Lahoux Horus Elite"},
    url: "Lahoux-Horus-Elite",
    price: "2707.14",
    shortDes: { nl:"De Lahoux Horus Elite is een warmtebeeldkijker die alle noodzakelijke eisen van een jager succesvol met elkaar combineert, het is zowel geschikt voor de jager die geïnteresseerd is in de nieuwste innovaties, alsook voor de jager die een simpele en intuïtieve bediening wenst. De nieuwste en hoogste productienormen maken de Horus een betrouwbaar hulpmiddel voor natuurobservatie en dagelijkse jacht. Een nauwkeurig scherpstelmechanisme maakt een gedetailleerde beeldreproductie mogelijk.", 
                en: "The Lahoux Horus Elite is a thermal device that successfully combines all the necessary requirements of a hunter, it is suitable for the hunter who is interested in the latest innovations, as well as for the hunter who wants simple and intuitive operation. State-of-the-art and highest production standards make the Horus a reliable tool for nature observation and everyday hunting. A precise focusing mechanism allows detailed image reproduction.", 
                de: "Der holländische Nachtsicht- und Wärmebildgerätehersteller Lahoux Optics präsentierte mit dem Lahoux Horus Elite ein Wärmebildvorsatzgerät der neuesten Generation. Dieses komplett in Westeuropa konstruierte und gebaute Gerät kann in der Ganzheit seiner Eigenschaften als einzigartig bezeichnet werden."},
    longDes_1:{ nl:"De Lahoux Horus Elite is een warmtebeeldkijker die alle noodzakelijke eisen van een jager succesvol met elkaar combineert, het is zowel geschikt voor de jager die geïnteresseerd is in de nieuwste innovaties, alsook voor de jager die een simpele en intuïtieve bediening wenst. De nieuwste en hoogste productienormen maken de Horus een betrouwbaar hulpmiddel voor natuurobservatie en dagelijkse jacht. Een nauwkeurig scherpstelmechanisme maakt een gedetailleerde beeldreproductie mogelijk.", 
                en: "The Lahoux Horus Elite is a thermal device that successfully combines all the necessary requirements of a hunter, it is suitable for the hunter who is interested in the latest innovations, as well as for the hunter who wants simple and intuitive operation. State-of-the-art and highest production standards make the Horus a reliable tool for nature observation and everyday hunting. A precise focusing mechanism allows detailed image reproduction.", 
                de: "Neueste Technologie wie 12 Mikron Pixelgröße, innenliegende Steuerung des Fokus sowie innovativste Systemkomponenten, die eine bis zu 10-fache Vergrößerung der Primäroptik erlaubt, setzen bei diesem neuem Gerät Maßstäbe. Die intelligente und effiziente Stromversorgung sichert eine bis zu 12-stündige Betriebsdauer und gewährleistet durch den Verpolungsschutz einen schnellen und fehlerfreien Akkuwechsel auch im Dunkeln. Die intuitive und einfache Bedienung des Horus wurde durch optimierte und praxisbezogene Tastenbelegung erreicht, beispielsweise der “Momentary Zoom” springt automatisch und blitzschnell von der dreifachen digitalen Vergrößerung zurück in die Nullposition."},
    longDes_2:{ nl:"De Lahoux Horus Elite is uitgerust met de innovatieve 12 micron pixelpitch technologie, die de kijker compacter maakt, voor een scherper beeld zorgt en vergrotingen van de primaire optiek tot 10x mogelijk maakt zonder dat het beeld aan kwaliteit inboet. Hierdoor is wild aanspreken op grotere afstanden mogelijk. De intelligente en efficiënte elektronica zorgen voor een batterijduur van 12 uur. De accu kan willekeurig in het batterij vak geplaatst worden, zodat in het donker een snelle en foutloze accuwissel mogelijk is. De Lahoux Horus is eenvoudig en intuïtief in zijn bediening, je kunt met de Lahoux Horus niet per ongeluk in een verkeerde modus terechtkomen, met alle consequenties van dien. Bijvoorbeeld de digitale driemaal vergroting gaat automatisch terug naar de uitgangspositie.", 
                en: "The Lahoux Horus Elite is equipped with the innovative 12 micron pixel pitch technology, which makes the thermal imaging camera more compact, provides a sharper image and allows enlargements of the primary optics up to 10x without compromising on quality. This makes it possible to approach wildlife at greater distances. The intelligent and efficient electronics ensure a battery life of 12 hours. The battery can be placed both ways in the battery compartment, so that a quick and error-free battery change is possible in the dark. The Lahoux Horus is simple and intuitive in its operation, you cannot accidentally end up in the wrong mode with the Lahoux Horus, with all its consequences. For example the digital three-fold magnification automatically returns to the starting position.", 
                de: "Das Lahoux Horus Elite bedient durch sein Konzept den an Innovationen int eressierten Anwender einerseits, sowie den an einer simplen und intuitiven Bedienung orientierten Jäger andererseits. Modernste und präzise Eigenschaften machen das Horus zu einem zuverlässigen Werkzeug für den jagdlichen Alltag."},
    pros:  {    nl: ["Het eerste multifunctionele warmtebeeldapparaat met 12 micron-technologie", "Geoptimaliseerd voor gebruik met vergroting vanaf 6x en hoger", "Zuinige elektronica voor een lange batterijduur van 12 uur", "Eenvoudig in het gebruik", "Compact en licht in gewicht", "Uniek Lahoux ontwerp" ], 
                en: ["The first multi-purpose thermal imaging device with 12 micron technology", "Optimized for use with primary optics magnification of 6x and up", "Economical electronics for a long battery life of 12 hours", "Easy to use", "Compact and lightweight", "Unique Lahoux design"], 
                de: ["Erster Wärmebild Vorsatz mit 12 Mikron Technologie", "Optimiert für die Verwendung bis zu 10-fache Vergrößerung", "Effiziente Elektronik für eine lange Akkulaufzeit von 12 Stunden", "Einfach zu bedienen", "Kompakt und leicht im Gewicht", "Einzigartiges Lahoux-Design"]},
    number: "02-0002-02448", 
    ean: "6090300920911",           
    leveromvang: [2, 9, 16, 3, 4],
    jubilee_offer: false,
    flyer: {nl:"Lahoux_Horus_NL_V2.pdf", en: "Lahoux_Horus_EN_V2.pdf", de: "Lahoux_Horus_DE_V2.pdf"},
    images: ["wm1_1.png", "wm1_2.png", "wm1_3.png", "wm1_4.png"],
    dri: "Warmtebeeld_Voorzetten.png",
    related: ["wm18",  "wm20"],
    accessories: ["as6", "as10"],
    footnote_battery: true,
    techs: {
        Detector: { 
            en: {"Resolution": "320 × 256", "Pixel size": "12 µm", "Frequency": "60 Hz", "Spectral range": "8-14 µm", "Sensitivity(NETD) Elite Version": "<40 mK"},
            nl: {"Resolutie": "320 × 256", "Pixelgrootte": "12 µm", "Frequentie": "60 Hz", "Spectraalbereik": "8-14 µm", "Gevoeligheid (NETD) Elite-versie": "<40 mK"},
            de: {"Auflösung": "320 × 256", "Pixelgröße": "12 µm", "Frequenz": "60 Hz", "Spektrallbereich": "8-14 µm", "Empfindlichkeit (NETD) Elite Version": "<40 mK"}
        },
        Optics: {
            en: {"Lens": "40mm Ophir Germanium", "Relative aperture": "F/1.0", "Ocular Diameter": "29 mm", "Field of View (hxv)": "5.5°x4.4°", "Focus range": "10m to ∞", "Magnification (Digital)": "3x Momentary Zoom"},
            nl: {"Lens": "40mm Ophir Germanium", "Relatieve lensopening": "F/1.0", "Oculair Diameter": "29 mm", "Gezichtsveld (hxv)": "5.5°x4.4°", "Focusbereik": "10m to ∞", "Vergroting (digitaal)": "3x Momentzoom"},
            de: {"Linse": "40mm Ophir Germanium", "Relative Blende": "F/1.0", "Okular-Durchmesser": "29 mm", "Sichtfeld (hxv)": "5.5°x4.4°", "Fokusbereich": "10m to ∞", "Vergrößerung (digital)": "3x kurzzeitiger Zoom"}
        },
        Electronics: {
            en: {"Battery": "18650 3,7V rechargeable Lithium-Ion", "Battery life": "Up to 12 Hours (at 21°C) *", "Display": "OLED"},
            nl: {"Batterij": "18650 3,7V oplaadbare Lithium-Ion", "Batterijduur": "Tot 12 uur (bij 21°C) *", "Beeldscherm": "OLED"},
            de: {"Batterie": "18650 3,7V wiederaufladbare Lithium-Ion", "Batterielaufzeit": "Bis zu 12 Stunden (bei 21°C) *", "Anzeige": "OLED"}
        },
        Operational: {
            en: {"Operating temperature range": "-20°C to 40°C", "Watertight": "IP67", "Dimensions" : "148x70x74 mm", "Weight without batteries": "0,45 kg"},
            nl: {"Bedrijfstemperatuurbereik": "-20°C to 40°C", "Waterdichtheid": "IP67", "Afmetingen" : "148x70x74 mm", "Gewicht zonder batterijen": "0,45 kg"},
            de: {"Betriebstemperaturbereich": "-20°C to 40°C", "Wasserfest": "IP67", "Abmessungen" : "148x70x74 mm", "Gewicht ohne Batterien": "0,45 kg"}
        }
    },
    filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: true, filterRiflescope: false}
    },
    {
        id: "wm40",
        orderID: "140",
        type: "thermal",
        title: {nl: "Lahoux Spotter 35 LRF", en: "Lahoux Spotter 35 LRF", de: "Lahoux Spotter 35 LRF"},
        url: "Lahoux-Spotter-35-LRF",
        price: "2125.85",
        new: false,
        endOfLife: true,
        shortDes: { nl:"De Lahoux Spotter 35 LRF is de ideale metgezel voor de mobiele gebruiker; bijvoorbeeld bij het jagen of wandelen. Door het lichte en compacte ontwerp is de Lahoux Spotter 35 LRF gemakkelijk mee te nemen in uw jaszak en zeer geschikt om met één hand te worden bediend.", 
                    en: "The Lahoux Spotter 35 LRF is the ideal companion for the mobile user; for example when hunting or hiking. The light and compact design makes the Lahoux Spotter 35 LRF easy to carry in your pocket and very suitable to be operated with one hand.", 
                    de: "Der Lahoux Spotter 35 LRF ist die idealen Begleiter für den mobilen Anwender; zum Beispiel beim Jagen oder Wandern. Durch die leichte und kompakte Bauweise ist der Lahoux Spotter 35 LRF leicht in der Jackentasche zu transportieren und sehr gut geeignet um sie mit einer Hand bedienen zu können."},
        longDes_1: {    nl:"De Lahoux Spotter 35 LRF is de ideale metgezel voor de mobiele gebruiker; bijvoorbeeld bij het jagen of wandelen. Door het lichte en compacte ontwerp is de Lahoux Spotter 35 LRF gemakkelijk mee te nemen in uw jaszak en zeer geschikt om met één hand te worden bediend.", 
                    en: "The Lahoux Spotter 35 LRF is the ideal companion for the mobile user; for example when hunting or hiking. The light and compact design makes the Lahoux Spotter 35 LRF easy to carry in your pocket and very suitable to be operated with one hand.", 
                    de: "Der Lahoux Spotter 35 LRF ist die idealen Begleiter für den mobilen Anwender; zum Beispiel beim Jagen oder Wandern. Durch die leichte und kompakte Bauweise ist der Lahoux Spotter 35 LRF leicht in der Jackentasche zu transportieren und sehr gut geeignet um sie mit einer Hand bedienen zu können."},
        longDes_2: {    nl:"De Lahoux Spotter 35 LRF heeft een hoge beeldkwaliteit en het hoge resolutie scherm zorgt voor een scherp beeld met levendige kleuren. Samen met de hoge verversingssnelheid krijgt u een uitstekend bruikbaar beeld en een goed situationeel bewustzijn. De lens biedt een breed gezichtsveld en stelt u in staat meer objecten waar te nemen. De pixelafstand van de detector levert een uitstekend beeld op en maakt detectie over een grote afstand mogelijk.", 
                    en: "The Lahoux Spotter 35 LRF has a high image quality and the high resolution screen ensures a sharp image with vivid colours. Together with the high refresh rate, you get an excellent usable image and good situational awareness. The 35 mm lens provides a higher magnification, allowing for a more detailed observation, comparable with the Spotter 35. The pixel pitch detector provides an excellent image and allows detection and observation over a large distance.", 
                    de: "Der Lahoux Spotter 35 LRF hat eine hohe Bildqualität und der hochauflösende Bildschirm liefert ein scharfes Bild mit lebendigen Farben. Zusammen mit der hohen Bildwiederholrate erhalten Sie ein hervorragend nutzbares Bild und ein gutes Situationsbewusstsein. Das Objektiv bietet ein weites Sichtfeld und ermöglicht die Beobachtung weiterer Objekte. Der Pixelabstand des Detektors liefert ein hervorragendes Bild und ermöglicht die Erfassung über eine große Entfernung."},
        // pros: {         nl: [""], 
        //                 en: [""], 
        //                 de: [""]
        //     },
        number: "02-0006-03224",
        ean: "6090301585560",
        // flyer: {nl:"", en: "", de: ""},
        dri: "Warmtebeeld_Spotters.png",
        images: ["Lahoux_Spotter_35_LRF.png"],
        techs: { 
            Detector: {
                en: {"Detector": "384x288 Vox", "Pixel Size, μm": "12", "NETD, mk": "≤40", "Frame Rate, Hz": "50"},
                nl: {"Detector": "384x288 Vox", "Pixelgrootte, μm": "12", "NETD, mk": "≤40", "Beeldfrequentie, Hz": "50"},
                de: {"Detektor": "384x288 Vox", "Pixelgröße, μm": "12", "NETD, mk": "≤40", "Bildfrequenz, Hz": "50"}
            },
            Optics: {
                en: {"Objective Lens, mm": "35 f/1.0", "Field of view": "7.5°x5.7°", "Optical Magnification, x": "3.4-13.6", "Digital zoom": "1x 1.5x 2x 2.5x 3x 3.5x 4x", "Diopter adjustment": "-4 tot +5"},
                nl: {"Objectieflens, mm": "35 f/1.0", "Gezichtsveld": "7.5°x5.7°", "Optische vergroting, x": "3.4-13.6", "Digitale zoom": "1x 1.5x 2x 2.5x 3x 3.5x 4x", "Dioptrie aanpassing": "-4 tot +5"},
                de: {"Objektive Linse, mm": "35 f/1.0", "Sichtfeld": "7.5°x5.7°", "Optische Vergrößerung, x": "3.4-13.6", "Digitaler Zoom": "1x 1.5x 2x 2.5x 3x 3.5x 4x", "Dioptrien-Einstellung": "-4 tot +5"}
            },
            Electronics: {
                en: {"Display": "1024x768 OLED", "Internal memory GB photo/video": "32"},
                nl: {"Beeldscherm": "1024x768 OLED", "Intern geheugen GB foto/video": "32"},
                de: {"Anzeige": "1024x768 OLED", "Interner Speicher GB Foto/Video": "32"}
            },
            Operating: {
                en: {"Laser Range Finder, Range m": "600 +/- 1", "Dimension, mm":  "160/90/50", "Weight, (without battery), kg": "0.40", "Power supply, li-ion": "exchangeable", "Encapsulation": "IP67", "Detection R I": "1846/462/231"},
                nl: {"Laserafstandsmeter, Bereik m": "600 +/- 1", "Afmetingen, mm":  "160/90/50", "Gewicht, (zonder batterij), kg": "0.40", "Voeding, li-ion": "verwisselbaar", "Waterdichtheid": "IP67", "Detectie R I": "1846/462/231"},
                de: {"Laser-Entfernungsmesser, Bereich, m": "600 +/- 1", "Abmessungen, mm":  "160/90/50", "Gewicht, (ohne Batterie), kg": "0.40", "Stromversorgung, li-ion": "austauschbar", "Wasserdicht": "IP67", "Detektion R I": "1846/462/231"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
    },

    {
        id: "wm41",
        orderID: "127",
        type: "thermal",
        title: {nl: "Lahoux Spotter 35 LRF V2", en: "Lahoux Spotter 35 LRF V2", de: "Lahoux Spotter 35 LRF V2"},
        url: "Lahoux-Spotter-35-LRF-V2",
        price: "1682.76",
        new: false,
        endOfLife: false,
        shortDes: { nl:"De Lahoux Spotter 35 LRF V2 is de ideale metgezel voor de mobiele gebruiker; bijvoorbeeld bij het jagen of wandelen. Door het lichte en compacte ontwerp is de Lahoux Spotter 35 LRF V2 gemakkelijk mee te nemen in uw jaszak en zeer geschikt om met één hand te worden bediend.", 
                    en: "The Lahoux Spotter 35 LRF V2 is the ideal companion for the mobile user; for example when hunting or hiking. The light and compact design makes the Lahoux Spotter 35 LRF V2 easy to carry in your pocket and very suitable to be operated with one hand.", 
                    de: "Der Lahoux Spotter 35 LRF V2 ist die idealen Begleiter für den mobilen Anwender; zum Beispiel beim Jagen oder Wandern. Durch die leichte und kompakte Bauweise ist der Lahoux Spotter 35 LRF V2 leicht in der Jackentasche zu transportieren und sehr gut geeignet um sie mit einer Hand bedienen zu können."},
        longDes_1: {    nl:"De Lahoux Spotter 35 LRF V2 is de ideale metgezel voor de mobiele gebruiker; bijvoorbeeld bij het jagen of wandelen. Door het lichte en compacte ontwerp is de Lahoux Spotter 35 LRF V2 gemakkelijk mee te nemen in uw jaszak en zeer geschikt om met één hand te worden bediend.", 
                    en: "The Lahoux Spotter 35 LRF V2 is the ideal companion for the mobile user; for example when hunting or hiking. The light and compact design makes the Lahoux Spotter 35 LRF V2 easy to carry in your pocket and very suitable to be operated with one hand.", 
                    de: "Der Lahoux Spotter 35 LRF V2 ist die idealen Begleiter für den mobilen Anwender; zum Beispiel beim Jagen oder Wandern. Durch die leichte und kompakte Bauweise ist der Lahoux Spotter 35 LRF V2 leicht in der Jackentasche zu transportieren und sehr gut geeignet um sie mit einer Hand bedienen zu können."},
        longDes_2: {    nl:"De Lahoux Spotter 35 LRF V2 heeft een hoge beeldkwaliteit en het hoge resolutie scherm zorgt voor een scherp beeld met levendige kleuren. Samen met de hoge verversingssnelheid krijgt u een uitstekend bruikbaar beeld en een goed situationeel bewustzijn. De lens biedt een breed gezichtsveld en stelt u in staat meer objecten waar te nemen. De pixelafstand van de detector levert een uitstekend beeld op en maakt detectie over een grote afstand mogelijk.", 
                    en: "The Lahoux Spotter 35 LRF V2 has a high image quality and the high resolution screen ensures a sharp image with vivid colours. Together with the high refresh rate, you get an excellent usable image and good situational awareness. The lens provides a higher magnification, allowing for a more detailed observation, comparable with the Spotter 35. The pixel pitch detector provides an excellent image and allows detection and observation over a large distance.", 
                    de: "Der Lahoux Spotter 35 LRF V2 hat eine hohe Bildqualität und der hochauflösende Bildschirm liefert ein scharfes Bild mit lebendigen Farben. Zusammen mit der hohen Bildwiederholrate erhalten Sie ein hervorragend nutzbares Bild und ein gutes Situationsbewusstsein. Das Objektiv bietet ein weites Sichtfeld und ermöglicht die Beobachtung weiterer Objekte. Der Pixelabstand des Detektors liefert ein hervorragendes Bild und ermöglicht die Erfassung über eine große Entfernung."},
        // pros: {         nl: [""], 
        //                 en: [""], 
        //                 de: [""]
        //     },
        number: "02-0006-03771",
//        ean: "6090301585560",
        // flyer: {nl:"", en: "", de: ""},
 //       dri: "Warmtebeeld_Spotters.png",
        images: ["Lahoux_Spotter_35_LRF_V2.png"],
        techs: { 
            Detector: {
                en: {"Detector": "384x288 px", "Pixel Size, μm": "12", "NETD, mk": "≤40", "Frame Rate, Hz": "50"},
                nl: {"Detector": "384x288 px", "Pixelgrootte, μm": "12", "NETD, mk": "≤40", "Beeldfrequentie, Hz": "50"},
                de: {"Detektor": "384x288 px", "Pixelgröße, μm": "12", "NETD, mk": "≤40", "Bildfrequenz, Hz": "50"}
            },
            Optics: {
                en: {"Objective Lens, mm": "35 F/1.0", "Field of view": "7.5°x5.6°", "Optical Magnification, x": "2.6x", "Digital zoom": "1x 2x 4x", "Diopter adjustment": "-4 tot +2"},
                nl: {"Objectieflens, mm": "35 F/1.0", "Gezichtsveld": "7.5°x5.6°", "Optische vergroting, x": "2.6x", "Digitale zoom": "1x 2x 4x", "Dioptrie aanpassing": "-4 tot +2"},
                de: {"Objektive Linse, mm": "35 F/1.0", "Sichtfeld": "7.5°x5.6°", "Optische Vergrößerung, x": "2.6x", "Digitaler Zoom": "1x 2x 4x", "Dioptrien-Einstellung": "-4 tot +2"}
            },
            Display: {
                en: {"Type": "0.23’’ OLED", "Resolution": "640x400 "},
                nl: {"Type": "0.23’’ OLED", "Resolutie": "640x400 "},
                de: {"Type": "0.23’’ OLED", "Auflösung": "640x400 "}
            },
            Operating: {
                en: {"600m": "Laser Range Finder" ,"150×77×74mm": "Dimension", "400g": "Weight, (without battery)", "Exchangeable 18650 Li-ion": "Power supply","IP66": "Encapsulation", "1400m": "Detection","√": "Wifi"},
                nl: {"600m": "Laserafstandsmeter", "150×77×74mm": "Afmetingen", "400g": "Gewicht, (zonder batterij)", "Verwisselbare 18650 Li-ion": "Voeding", "IP66": "Waterdichtheid", "1400m": "Detectie","√": "Wifi"},
                de: {"600m": "Laser-Entfernungsmesser", "150×77×74mm": "Abmessungen", "400g": "Gewicht, (ohne Batterie)", "Austauschbares  18650 Li-ion": "Stromversorgung", "IP66": "Wasserdicht", "1400m": "Detektion","√": "Wifi"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
    },  

    {
        id: "wm42",
        orderID: "125",
        type: "thermal",
        title: {nl: "Lahoux Spotter 25 LRF V2", en: "Lahoux Spotter 25 LRF V2", de: "Lahoux Spotter 25 LRF V2"},
        url: "Lahoux-Spotter-25-LRF-V2",
        price: "1372.41",
        new: false,
        endOfLife: false,
        shortDes: { nl:"De Lahoux Spotter 25 LRF V2 is de ideale metgezel voor de mobiele gebruiker; bijvoorbeeld bij het jagen of wandelen. Door het lichte en compacte ontwerp is de Lahoux Spotter 25 LRF V2 gemakkelijk mee te nemen in uw jaszak en zeer geschikt om met één hand te worden bediend.", 
                    en: "The Lahoux Spotter 25 LRF V2 is the ideal companion for the mobile user; for example when hunting or hiking. The light and compact design makes the Lahoux Spotter 25 LRF V2 easy to carry in your pocket and very suitable to be operated with one hand.", 
                    de: "Der Lahoux Spotter 25 LRF V2 ist die idealen Begleiter für den mobilen Anwender; zum Beispiel beim Jagen oder Wandern. Durch die leichte und kompakte Bauweise ist der Lahoux Spotter 25 LRF V2 leicht in der Jackentasche zu transportieren und sehr gut geeignet um sie mit einer Hand bedienen zu können."},
        longDes_1: {    nl:"De Lahoux Spotter 25 LRF V2 is de ideale metgezel voor de mobiele gebruiker; bijvoorbeeld bij het jagen of wandelen. Door het lichte en compacte ontwerp is de Lahoux Spotter 25 LRF V2 gemakkelijk mee te nemen in uw jaszak en zeer geschikt om met één hand te worden bediend.", 
                    en: "The Lahoux Spotter 25 LRF V2 is the ideal companion for the mobile user; for example when hunting or hiking. The light and compact design makes the Lahoux Spotter 25 LRF V2 easy to carry in your pocket and very suitable to be operated with one hand.", 
                    de: "Der Lahoux Spotter 25 LRF V2 ist die idealen Begleiter für den mobilen Anwender; zum Beispiel beim Jagen oder Wandern. Durch die leichte und kompakte Bauweise ist der Lahoux Spotter 25 LRF V2 leicht in der Jackentasche zu transportieren und sehr gut geeignet um sie mit einer Hand bedienen zu können."},
        longDes_2: {    nl:"De Lahoux Spotter 25 LRF V2 heeft een hoge beeldkwaliteit en het hoge resolutie scherm zorgt voor een scherp beeld met levendige kleuren. Samen met de hoge verversingssnelheid krijgt u een uitstekend bruikbaar beeld en een goed situationeel bewustzijn. De lens biedt een breed gezichtsveld en stelt u in staat meer objecten waar te nemen. De pixelafstand van de detector levert een uitstekend beeld op en maakt detectie over een grote afstand mogelijk.", 
                    en: "The Lahoux Spotter 25 LRF V2 has a high image quality and the high resolution screen ensures a sharp image with vivid colours. Together with the high refresh rate, you get an excellent usable image and good situational awareness. The lens provides a higher magnification, allowing for a more detailed observation, comparable with the Spotter 35. The pixel pitch detector provides an excellent image and allows detection and observation over a large distance.", 
                    de: "Der Lahoux Spotter 25 LRF V2 hat eine hohe Bildqualität und der hochauflösende Bildschirm liefert ein scharfes Bild mit lebendigen Farben. Zusammen mit der hohen Bildwiederholrate erhalten Sie ein hervorragend nutzbares Bild und ein gutes Situationsbewusstsein. Das Objektiv bietet ein weites Sichtfeld und ermöglicht die Beobachtung weiterer Objekte. Der Pixelabstand des Detektors liefert ein hervorragendes Bild und ermöglicht die Erfassung über eine große Entfernung."},
        // pros: {         nl: [""], 
        //                 en: [""], 
        //                 de: [""]
        //     },
        number: "02-0006-03770",
//        ean: "6090301585560",
        // flyer: {nl:"", en: "", de: ""},
 //       dri: "Warmtebeeld_Spotters.png",
        images: ["Lahoux_Spotter_25_LRF_V2.png"],
        techs: { 
            Detector: {
                en: {"Detector": "384x288 px", "Pixel Size, μm": "12", "NETD, mk": "≤40", "Frame Rate, Hz": "50"},
                nl: {"Detector": "384x288 px", "Pixelgrootte, μm": "12", "NETD, mk": "≤40", "Beeldfrequentie, Hz": "50"},
                de: {"Detektor": "384x288 px", "Pixelgröße, μm": "12", "NETD, mk": "≤40", "Bildfrequenz, Hz": "50"}
            },
            Optics: {
                en: {"Objective Lens, mm": "25 F/1.1", "Field of view": "10.4°×7.9°", "Optical Magnification, x": "1.9x", "Digital zoom": "1x 2x 4x", "Diopter adjustment": "-4 tot +2"},
                nl: {"Objectieflens, mm": "25 F/1.1", "Gezichtsveld": "10.4°×7.9°", "Optische vergroting, x": "1.9x", "Digitale zoom": "1x 2x 4x", "Dioptrie aanpassing": "-4 tot +2"},
                de: {"Objektive Linse, mm": "25 F/1.1", "Sichtfeld": "10.4°×7.9°", "Optische Vergrößerung, x": "1.9x", "Digitaler Zoom": "1x 2x 4x", "Dioptrien-Einstellung": "-4 tot +2"}
            },
            Display: {
                en: {"Type": "0.23’’ OLED", "Resolution": "640x400 "},
                nl: {"Type": "0.23’’ OLED", "Resolutie": "640x400 "},
                de: {"Type": "0.23’’ OLED", "Auflösung": "640x400 "}
            },
            Operating: {
                en: {"600m": "Laser Range Finder" ,"150×75×74mm": "Dimension", "360g": "Weight, (without battery)", "Exchangeable 18650 Li-ion": "Power supply","IP66": "Encapsulation", "1000m": "Detection","√": "Wifi"},
                nl: {"600m": "Laserafstandsmeter", "150×75×74mm": "Afmetingen", "360g": "Gewicht, (zonder batterij)", "Verwisselbare 18650 Li-ion": "Voeding", "IP66": "Waterdichtheid", "1000m": "Detectie","√": "Wifi"},
                de: {"600m": "Laser-Entfernungsmesser", "150×75×74mm": "Abmessungen", "360g": "Gewicht, (ohne Batterie)", "Austauschbares  18650 Li-ion": "Stromversorgung", "IP66": "Wasserdicht", "1000m": "Detektion","√": "Wifi"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
    }, 

    {
        id: "wm43",
        orderID: "126",
        type: "thermal",
        title: {nl: "Lahoux Spotter 35 V2", en: "Lahoux Spotter 35 V2", de: "Lahoux Spotter 35 V2"},
        url: "Lahoux-Spotter-35-V2",
        price: "1510.34",
        endOfLife: false,
        shortDes: { nl:"Wildobservatie van de volgende generatie. De Lahoux Spotter 35 V2 is de ideale metgezel voor bos- en veldjacht, zelfs in totale duisternis.", 
                    en: "Wildlife watching of the next generation. The Lahoux Spotter 35 V2 is the ideal companion for forest and field hunting even in total darkness.", 
                    de: "Die nächste Generation der Wildbeobachtung. Der Lahoux Spotter 35 V2 ist der ideale Begleiter für die Wald- und Feldjagd, auch bei völliger Dunkelheit."},
        longDes_1:{ nl:"Wildobservatie van de volgende generatie. De Lahoux Spotter 35 V2 is de ideale metgezel voor bos- en veldjacht, zelfs in totale duisternis. De beeldsnelheid van 50 Hz zorgt voor een trillingsvrij beeld en met de vijf beeldmodi (White Hot, Black Hot, Red Hot, Iron Hot und Blue Hot) kan het beeld naar wens worden geselecteerd. Dankzij de 35 mm optiek en de pixelafstand van 12μm en een resolutie van 384 x 288 pixels kunt u wild ontdekken, zelfs over langere afstand.", 
                    en: "Wildlife watching of the next generation. The Lahoux Spotter 35 V2 is the ideal companion for forest and field hunting even in total darkness. The 50 Hz frame rate ensures a jitter-free image and with its five viewing modes (White Hot, Black Hot, Red Hot, Iron Hot und Blue Hot), the image can be selected as needed. The 35mm optics and 12μm pixel pitch and a resolution of 384 x 288 pixel allows you to find game even at longer distances.", 
                    de: "Die nächste Generation der Wildbeobachtung. Der Lahoux Spotter 35 V2 ist der ideale Begleiter für die Wald- und Feldjagd, auch bei völliger Dunkelheit. Die Bildfrequenz von 50 Hz sorgt für ein flimmerfreies Bild und mit den fuenf Bildmodi (White Hot, Black Hot, Red Hot, Iron Hot und Blue Hot) kann das Bild nach Belieben gewählt werden. Mit der 35mm-Optik und 12μm Pixelabstand und einer Auflösung von 384 x 288 Pixel können Sie Wildtiere auch über größere Entfernungen entdecken."},
        pros:     { nl: ["Intuïtieve bediening met één hand", "Lichtgewicht en compact ontwerp", "Kan dag en nacht worden gebruikt", "Groot temperatuurbereik", "Gebruiksvriendelijke bediening", "Waterdicht (IP66)", "Hoge beeldsnelheid van 50 Hz", "Digitale vergroting", "Externe lithium-ion accu", "WiFi"], 
                    en: ["Intuitive one-hand operation", "Lightweight and compact design", "Can be used day or night", "Large operating temperature range", "User-friendly operation", "Waterproof (IP66)" , "High frame rate of 50 Hz", "Digital magnification", "External lithium-ion battery", "WiFi"], 
                    de: ["Intuitive Einhandbedienung", "Leichtes und kompaktes Design", "Tag und Nacht einsetzbar", "Großer Temperaturbereich", "Einfache Bedienung", "Wasserdicht (IP66)", "Hohe Bildrate von 50 Hz", "Digitale Vergrößerung", "Externer Lithium-Ionen-Akku", "WiFi"]},                 
        number: "02-0006-03844",
//        ean: "6090301578555",
//        leveromvang: [ 1, 7, 4, 8, 3, 5],
 //       flyer: {nl:"Lahoux_Spotter_NL_V10.pdf", en: "Lahoux_Spotter_EN_V9.pdf", de: "Lahoux_Spotter_DE_V11.pdf"},
 //       images: ["wm8_1.png"],
        images: ["Lahoux-Spotter-35-V2-1.png", "Lahoux-Spotter-35-V2-2.png", "Lahoux-Spotter-35-V2-3.png"],        
 //       dri: "Warmtebeeld_Spotters.png",
        related: ["wm6", "wm29", "wm15", "wm42"],
        accessories: ["as13"],
        footnote_battery: true,
        techs: {
            Detector: {
                en: {"Detector": "384x288 px", "Pixel pitch": "12 µm", "Spectral range": "8-14 µm", "Sensitivity (NETD)": "≤ 40 mK", "Frame rate": "50 Hz"},
                nl: {"Detector": "384x288 px", "Pixelafstand": "12 µm", "Spectraalbereik": "8-14 µm", "Gevoeligheid (NETD)": "≤ 40 mK", "Beeldfrequentie": "50 Hz"},
                de: {"Detektor": "384x288 px", "Pixelabstand": "12 µm", "Spektrallbereich": "8-14 µm", "Empfindlichkeit (NETD)": "≤ 40 mK", "Bildfrequenz": "50 Hz"}
            },
            Optics: {
                en: {"Lens": "35 F/1.0", "Optical Magnification": "2.6x", "Digital Magnification": "1x, 2x, 4x", "Diopter adjustment": "-4 to＋2"},
                nl: {"Lens": "35 F/1.0", "Optische Vergroting": "2.6x", "Digitale Vergroting": "1x, 2x, 4x", "Dioptriecorrectie": "-4 to＋2"},
                de: {"Linse": "35 F/1.0", "Optische Vergrößerung": "2.6x","Digitale Vergrößerung": "1x, 2x, 4x", "Dioptrieneinstellung": "-4 to＋2"}
            },
            Interface: {
                en: {"Video": "USB-C", "Mechanical": "UNC ¼’’-20 (Tripod mounting)" ,"Display 0.23’’ OLED": "640 × 400", "Wifi": "Transmission of real-time video via WIFI for remote control in the 2.4G frequency band and within 15 meters"},
                nl: {"Video": "USB-C", "Mechanical": "UNC ¼’’-20 (Tripod mounting)",  "Beeldscherm 0.23’’ OLED": "640 × 400", "Wifi": "Overdracht van real-time video via WIFI binnen 15 meter"},
                de: {"Video": "USB-C", "Mechanical": "UNC ¼’’-20 (Tripod mounting)", "Anzeige 0.23’’ OLED": "640 × 400", "Wifi": "Übertragung von Echtzeitvideos über WLAN im Umkreis von 15 Metern"}
            },
            Operating: {
                en: {"Temperature range": "-30 °C to 60 °C", "Power supply": "Single, replaceable 18650 battery", "Battery life" : "≥10 hours (at 21°C) *", "Encapsulation": "IP66", "Dimensions": "150×49×74 mm", "Weight": "0,345 kg"},
                nl: {"Temperatuurbereik": "-30 °C to 60 °C", "Voeding": "Enkele, vervangbare 18650-batterij", "Batterijduur" : "≥10 uur (bij 21°C) *", "Waterdichtheid": "IP66", "Afmetingen": "150×49×74 mm", "Gewicht": "0,345 kg"},
                de: {"Temperaturbereich": "-30 °C to 60 °C", "Stromversorgung": "Einzelne, austauschbare 18650-Batterie", "Batterielaufzeit" : "≥10 Stunden (bei 21°C) *", "Wasserdicht": "IP66", "Abmessungen": "150×49×74 mm", "Gewicht": "0,345 kg"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
    },     
    {
        id: "wm44",
        orderID: "128",
        type: "thermal",
        title: {nl: "Lahoux Spotter Elite 35 V2", en: "Lahoux Spotter Elite 35 V2", de: "Lahoux Spotter Elite 35 V2"},
        url: "Lahoux-Spotter-Elite-35-V2",
        price: "2200.00",
        endOfLife: false,
        shortDes: { nl:"Wildobservatie van de volgende generatie. De Lahoux Spotter Elite 35 V2 is de ideale metgezel voor bos- en veldjacht, zelfs in totale duisternis.", 
                    en: "Wildlife watching of the next generation. The Lahoux Spotter Elite 35 V2 is the ideal companion for forest and field hunting even in total darkness.", 
                    de: "Die nächste Generation der Wildbeobachtung. Der Lahoux Spotter Elite 35 V2 ist der ideale Begleiter für die Wald- und Feldjagd, auch bei völliger Dunkelheit."},
        longDes_1:{ nl:"Wildobservatie van de volgende generatie. De Lahoux Spotter Elite 35 V2 is de ideale metgezel voor bos- en veldjacht, zelfs in totale duisternis. De beeldsnelheid van 50 Hz zorgt voor een trillingsvrij beeld en met de vijf beeldmodi (White Hot, Black Hot, Red Hot, Iron Hot und Blue Hot) kan het beeld naar wens worden geselecteerd. Dankzij de 35 mm-optiek en de pixelafstand van 12μm en een resolutie van 640x480 pixels kunt u wild ontdekken, zelfs over langere afstand.", 
                    en: "Wildlife watching of the next generation. The Lahoux Spotter Elite 35 V2 is the ideal companion for forest and field hunting even in total darkness. The 50 Hz frame rate ensures a jitter-free image and with its five viewing modes (White Hot, Black Hot, Red Hot, Iron Hot und Blue Hot), the image can be selected as needed. The 35mm optics and 12μm pixel pitch and a resolution of 640x480 pixel allows you to find game even at longer distances.", 
                    de: "Die nächste Generation der Wildbeobachtung. Der Lahoux Spotter Elite 35 V2 ist der ideale Begleiter für die Wald- und Feldjagd, auch bei völliger Dunkelheit. Die Bildfrequenz von 50 Hz sorgt für ein flimmerfreies Bild und mit den fuenf Bildmodi (White Hot, Black Hot, Red Hot, Iron Hot und Blue Hot) kann das Bild nach Belieben gewählt werden. Mit der 35mm-Optik und 12μm Pixelabstand und einer Auflösung von 640x480 Pixel können Sie Wildtiere auch über größere Entfernungen entdecken."},
        pros:     { nl: ["Intuïtieve bediening met één hand", "Lichtgewicht en compact ontwerp", "Kan dag en nacht worden gebruikt", "Groot temperatuurbereik", "Gebruiksvriendelijke bediening", "Waterdicht (IP66)", "Hoge beeldsnelheid van 50 Hz", "Digitale vergroting", "Externe lithium-ion accu", "WiFi"], 
                    en: ["Intuitive one-hand operation", "Lightweight and compact design", "Can be used day or night", "Large operating temperature range", "User-friendly operation", "Waterproof (IP66)" , "High frame rate of 50 Hz", "Digital magnification", "External lithium-ion battery", "WiFi"], 
                    de: ["Intuitive Einhandbedienung", "Leichtes und kompaktes Design", "Tag und Nacht einsetzbar", "Großer Temperaturbereich", "Einfache Bedienung", "Wasserdicht (IP66)", "Hohe Bildrate von 50 Hz", "Digitale Vergrößerung", "Externer Lithium-Ionen-Akku", "WiFi"]},                 
        number: "02-0006-02420",
//        ean: "6090301578555",
//        leveromvang: [ 1, 7, 4, 8, 3, 5],
 //       flyer: {nl:"Lahoux_Spotter_NL_V10.pdf", en: "Lahoux_Spotter_EN_V9.pdf", de: "Lahoux_Spotter_DE_V11.pdf"},
          images: ["Lahoux_Spotter_Elite_35_V2.png"],
//        images: ["no-image-icon.png"],        
 //       dri: "Warmtebeeld_Spotters.png",
        related: ["wm6", "wm29", "wm15", "wm42","wm43"],
        accessories: ["as13"],
        footnote_battery: true,
        techs: {
            Detector: {
                en: {"Resolutie VOx uncooled FPA": "640x480", "Pixel pitch": "12 µm", "Spectral range": "8-14 µm", "Sensitivity (NETD)": "≤30 mK @ F1 25℃", "Frame rate": "50 Hz"},
                nl: {"Resolutie VOx ongekoeld FPA": "640x480", "Pixelafstand": "12 µm", "Spectraalbereik": "8-14 µm", "Gevoeligheid (NETD)": "≤30 mK @ F1 25℃", "Beeldfrequentie": "50 Hz"},
                de: {"Auflösung VOx ungekühlt FPA": "640x480", "Pixelabstand": "12 µm", "Spektrallbereich": "8-14 µm", "Empfindlichkeit (NETD)": "≤30 mK @ F1 25℃", "Bildfrequenz": "50 Hz"}
            },
            Optics: {
                en: {"Lens": "35 F/1.0", "Optical Magnification": "2x", "Digital Magnification": "1-8x", "Diopter adjustment": "-4 to＋2"},
                nl: {"Lens": "35 F/1.0", "Optische Vergroting": "2x", "Digitale Vergroting": "1-8x", "Dioptriecorrectie": "-4 to＋2"},
                de: {"Linse": "35 F/1.0", "Optische Vergrößerung": "2x","Digitale Vergrößerung": "1-8x", "Dioptrieneinstellung": "-4 to＋2"}
            },
            Interface: {
                en: {"Video": "USB-C", "Mechanical": "UNC ¼’’-20 (Tripod mounting)" ,"Display 0.4'' FLCOS": "1280x960", "Wifi": "Transmission of real-time video via WIFI for remote control in the 2.4G frequency band and within 15 meters"},
                nl: {"Video": "USB-C", "Mechanical": "UNC ¼’’-20 (Tripod mounting)",  "Beeldscherm 0.4'' FLCOS": "1280x960", "Wifi": "Overdracht van real-time video via WIFI binnen 15 meter"},
                de: {"Video": "USB-C", "Mechanical": "UNC ¼’’-20 (Tripod mounting)", "Anzeige 0.4'' FLCOS": "1280x960", "Wifi": "Übertragung von Echtzeitvideos über WLAN im Umkreis von 15 Metern"}
            },
            Operating: {
                en: {"Temperature range": "-30 °C to 60 °C", "Power supply": "2 built-in 18650 batteries (6000mAh)", "Battery life" : "≥4.5 hours (at 21°C) *", "Encapsulation": "IP66", "Dimensions": "214×65×71mm", "Weight": "0,560 kg"},
                nl: {"Temperatuurbereik": "-30 °C to 60 °C", "Voeding": "2 ingebouwde 18650 batterijen (6000mAh)", "Batterijduur" : "≥4.5 uur (bij 21°C) *", "Waterdichtheid": "IP66", "Afmetingen": "214×65×71mm", "Gewicht": "0,560 kg"},
                de: {"Temperaturbereich": "-30 °C to 60 °C", "Stromversorgung": "2 eingebaute 18650er Batterien (6000mAh)", "Batterielaufzeit" : "≥4.5 Stunden (bei 21°C) *", "Wasserdicht": "IP66", "Abmessungen": "214×65×71mm", "Gewicht": "0,560 kg"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
    },    
    {
        id: "wm28",
        orderID: "129",
        type: "thermal",
        title: {nl: "Lahoux Spotter Elite 35 LRF V2", en: "Lahoux Spotter Elite 35 LRF V2", de: "Lahoux Spotter Elite 35 LRF V2"},
        url: "Lahoux-Spotter-Elite-35-LRF-V2",
        price: "2475.86",
        new: false,
        endOfLife: false,
        shortDes: { nl:"De Lahoux Spotter Elite 35 LRF V2 is de ideale metgezel voor de mobiele gebruiker; bijvoorbeeld bij het jagen of wandelen. Door het lichte en compacte ontwerp is de Lahoux Spotter Elite 35 LRF V2 gemakkelijk mee te nemen in uw jaszak en zeer geschikt om met één hand te worden bediend.", 
                    en: "The Lahoux Spotter Elite 35 LRF V2 is the ideal companion for the mobile user; for example when hunting or hiking. The light and compact design makes the Lahoux Spotter Elite 35 LRF V2 easy to carry in your pocket and very suitable to be operated with one hand.", 
                    de: "Der Lahoux Spotter Elite 35 LRF V2 ist die idealen Begleiter für den mobilen Anwender; zum Beispiel beim Jagen oder Wandern. Durch die leichte und kompakte Bauweise ist der Lahoux Spotter Elite 35 LRF V2 leicht in der Jackentasche zu transportieren und sehr gut geeignet um sie mit einer Hand bedienen zu können."},
        longDes_1: {    nl:"De Lahoux Spotter Elite 35 LRF V2 is de ideale metgezel voor de mobiele gebruiker; bijvoorbeeld bij het jagen of wandelen. Door het lichte en compacte ontwerp is de Lahoux Spotter Elite 35 LRF V2 gemakkelijk mee te nemen in uw jaszak en zeer geschikt om met één hand te worden bediend.", 
                    en: "The Lahoux Spotter Elite 35 LRF V2 is the ideal companion for the mobile user; for example when hunting or hiking. The light and compact design makes the Lahoux Spotter Elite 35 LRF V2 easy to carry in your pocket and very suitable to be operated with one hand.", 
                    de: "Der Lahoux Spotter Elite 35 LRF V2 ist die idealen Begleiter für den mobilen Anwender; zum Beispiel beim Jagen oder Wandern. Durch die leichte und kompakte Bauweise ist der Lahoux Spotter Elite 35 LRF V2 leicht in der Jackentasche zu transportieren und sehr gut geeignet um sie mit einer Hand bedienen zu können."},
        longDes_2: {    nl:"De Lahoux Spotter Elite 35 LRF V2 heeft een hoge beeldkwaliteit en het hoge resolutie scherm zorgt voor een scherp beeld met levendige kleuren. Samen met de hoge verversingssnelheid krijgt u een uitstekend bruikbaar beeld en een goed situationeel bewustzijn. De lens biedt een breed gezichtsveld en stelt u in staat meer objecten waar te nemen. De pixelafstand van de detector levert een uitstekend beeld op en maakt detectie over een grote afstand mogelijk.", 
                    en: "The Lahoux Spotter Elite 35 LRF V2 has a high image quality and the high resolution screen ensures a sharp image with vivid colours. Together with the high refresh rate, you get an excellent usable image and good situational awareness. The lens provides a wider field of view compared to the Spotter Elite 35 LRF V2, allowing observation at longer and shorter distances making it more allround. The pixel pitch detector provides an excellent image and allows detection and observation at all distances.", 
                    de: "Der Lahoux Spotter Elite 35 LRF V2 hat eine hohe Bildqualität und der hochauflösende Bildschirm liefert ein scharfes Bild mit lebendigen Farben. Zusammen mit der hohen Bildwiederholrate erhalten Sie ein hervorragend nutzbares Bild und ein gutes Situationsbewusstsein. Das Objektiv bietet ein weites Sichtfeld und ermöglicht die Beobachtung weiterer Objekte. Der Pixelabstand des Detektors liefert ein hervorragendes Bild und ermöglicht die Erfassung über eine große Entfernung."},
        // pros: {         nl: [""], 
        //                 en: [""], 
        //                 de: [""]
        //     },
        number: "02-0006-02852",
//        ean: "6090301589513",
        // flyer: {nl:"", en: "", de: ""},
        images: ["Lahoux_Spotter_Elite_35_LRF_V2a.png"],
//        dri: "Warmtebeeld_Spotters.png",
        techs: { 
            Detector: {
                en: {"Detector": "640x480 Vox", "Pixel Size, μm": "12", "NETD, mk": "≤40", "Frame Rate, Hz": "50"},
                nl: {"Detector": "640x480 Vox", "Pixelgrootte, μm": "12", "NETD, mk": "≤40", "Beeldfrequentie, Hz": "50"},
                de: {"Detektor": "640x480 Vox", "Pixelgröße, μm": "12", "NETD, mk": "≤40", "Bildfrequenz, Hz": "50"}
            },
            Optics: {
                en: {"Objective Lens, mm": "35 f/1.0", "Field of view": "12.5°x 9.4°", "Optical Magnification, x": "1.7", "Digital zoom": "1x 2x 4x 8x", "Diopter adjustment": "-4 tot +2"},
                nl: {"Objectieflens, mm": "35 f/1.0", "Gezichtsveld": "12.5°x 9.4°", "Optische vergroting, x": "1.7", "Digitale zoom": "1x 2x 4x 8x", "Dioptrie aanpassing": "-4 tot +2"},
                de: {"Objektive Linse, mm": "35 f/1.0", "Sichtfeld": "12.5°x 9.4°", "Optische Vergrößerung, x": "1.7", "Digitaler Zoom": "1x 2x 4x 8x", "Dioptrien-Einstellung": "-4 tot +2"}
            },
            Electronics: {
                en: {"Display": "640x400 AMOLED"},
                nl: {"Beeldscherm": "640x400 AMOLED"},
                de: {"Anzeige": "640x400 AMOLED"}
            },
            Operating: {
                en: {"Laser Range Finder, Range m": "600", "Dimension, mm":  "153×77×74", "Weight, (without battery), g": "≤399", "Detection Range, m": "1400", "exchangeable 18650 battery": "Power supply", "Encapsulation": "IP66"},
                nl: {"Laserafstandsmeter, Bereik m": "600", "Afmetingen, mm":  "153×77×74", "Gewicht, (zonder batterij), g": "≤399 ", "Detectiebereik, m": "1400", "verwisselbaar 18650 accu":"Voeding", "Waterdichtheid": "IP66"},
                de: {"Laser-Entfernungsmesser, Bereich m": "600", "Abmessungen, mm":  "153×77×74", "Gewicht, (ohne Batterie), g": "≤399 ", "Detektionsbereich, m": "1400", "austauschbar 18650 Akku":"Stromversorgung", "Wasserdicht": "IP66"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
    },


    

    // *** Remove Lahoux Spotter M from site: EOL; keep data here for future use ***
	// {
	// 	id: "wm5",
    //     type: "thermal",
	// 	title: "Lahoux Spotter M",
    //     url: "Spotter-M",
	// 	price: "811.25",
    //     shortDes: { nl:"Licht, klein en handig – de Lahoux Spotter M is de perfecte instap in de fascinerende warmtebeeldtechnologie.", 
    //                 en: "Light, small and handy – the Lahoux Spotter M is the perfect entry model into the fascinating thermal imaging technology.",
    //                 de: "Leicht, klein und handlich – der Lahoux Spotter M ist der perfekte Einstieg in die faszinierende Wärmebildtechnologie."},
    //     longDes_1: {  nl:"Licht, klein en handig – de Lahoux Spotter M is de perfecte instap in de fascinerende warmtebeeldtechnologie. Zelfs in absolute duisternis of dicht gebladerte kunt u met deze warmtebeeldcamera zien wat eerder verborgen bleef. De 13 mm-lens biedt een breed gezichtsveld. Met een pixelafstand van 17 μm en een resolutie van 240 x 180 pixels is de Lahoux Spotter M de beste instapper voor wildobservatie.", 
    //                 en: "Light, small and handy – the Lahoux Spotter M is the perfect entry model into the fascinating thermal imaging technology. Even in absolute darkness or dense foliage, this thermal imaging camera allows you to see what previously remained hidden. The 13 mm lens offers a wide field of view. With 17μm pixel pitch and a resolution of 240 x 180 pixel, the Lahoux Spotter M is the right entry model for game observation.",  
    //                 de: "Leicht, klein und handlich – der Lahoux Spotter M ist der perfekte Einstieg in die faszinierende Wärmebildtechnologie. Selbst bei absoluter Dunkelheit oder dichtem Laub erlaubt diese Wärmebildkamera zu sehen, was bisher verborgen blieb. Das 13 mm Objektiv bietet ein weites Sehfeld. Mit 17µm Pixelabstand und einer Auflösung von 240 x 180 Pixel ist der Lahoux Spotter M der richtige Einstieg bei der Wildbeobachtung."},
    //     pros:  {    nl: ["Intuïtieve bediening met één hand", "Lichtgewicht en compact ontwerp", "Kan dag en nacht worden gebruikt" , "Groot temperatuurbereik", "Gebruiksvriendelijke bediening", "Waterdicht (IP66)", "Hoge beeldsnelheid van 50 Hz", "Digitale vergroting", "Geïntegreerde lithium-ion accu" ], 
    //                 en: ["Intuitive one-hand operation", "Lightweight and compact design", "Can be used day or night", "Large operating temperature range", "User-friendly operation", "Waterproof (IP66)", "High frame rate of 50 Hz", "Digital magnification", "Integrated lithium-ion battery"], 
    //                 de: ["Intuitive Einhandbedienung", "Leichte und kompakte Bauform", "Bei Tag und Nacht einsetzbar", "Großer Betriebstemperaturbereich", "Benutzerfreundliche Bedienung", "Wasserdicht (IP66)", "Hohe Bildwechselfrequenz von 50 Hz", "Digitale Vergrößerung", "Integrierter Lithium-Ionen-Akku" ]},
    //     number: "02-0006-01990",
    //     accessoires: [ 1, 7, 4, 8, 3, 5],
    //     flyer: {nl:"Lahoux_Spotter_NL_V10.pdf", en: "Lahoux_Spotter_EN_V9.pdf", de: "Lahoux_Spotter_DE_V11.pdf"},
    //     images: ["wm5_1.png", "wm5_3.png"],
    //     related: ["wm6"],
    //     accessories: ["as13" ],
    //     techs: {
    //         Detector: { "Resolutie VOx uncooled FPA": "240 x 180", "Pixel pitch": "17 µm", "Spectral range": "8-14 µm", "Sensitivity (NETD)": "< 50 mK", "Frame rate": "50 Hz"},
    //         Optics: {"Lens": "13 mm", "Magnification (digital)": "1x, 2x", "Eye relief": "Adjustment with rotary knob"},
    //         Electronics: {"Video output": "PAL", "USB": "For charging, can be charged by USB powerbank", "Image transmission": "Not possible", "Display FLCOS": "720 x 540"},
    //         Operating: {"Temperature range": "−20°C to +50°C", "Power supply": "Internal lithium-ion battery", "Battery life" : "15 hours", "Encapsulation": "IP66", "Dimensions": "160x62x62 mm", "Weight": "0,32 kg" }
    //     }        
	// },

    {
        id: "wm29",
        orderID: "122",
        type: "thermal",
        title: {nl: "Lahoux Spotter S", en: "Lahoux Spotter S", de: "Lahoux Spotter S"},
        url: "Lahoux-Spotter-S",
        price: "528.00",
        new: false,
        shortDes: { nl: "Het nieuwe juweeltje voor de beginnende warmtebeeldgebruiker. De Lahoux Spotter S is ons nieuwe instapmodel met zeer goede beeldkwaliteit en opties die alleen in duurdere modellen te vinden zijn.", 
                    en: "The new gem for starters. The Lahoux Spotter S is our new entry-level model with very good imagery and options only found in more expensive models.", 
                    de: "Das passende Modell für den Einstig in die Welt der Wärmebildkameras. Der Lahoux Spotter S ist unser neues Einstiegsmodell mit sehr guten Bildern und Optionen, die man sonst nur in teureren Modellen findet."},
        longDes_1: {    nl: "Het nieuwe juweeltje voor de beginnende warmtebeeldgebruiker. De Lahoux Spotter S is ons nieuwe instapmodel met zeer goede beeldkwaliteit en opties die alleen in duurdere modellen te vinden zijn.", 
                        en: "The new gem for starters. The Lahoux Spotter S is our new entry-level model with very good imagery and options only found in more expensive models.", 
                        de: "Das passende Modell für den Einstig in die Welt der Wärmebildkameras. Der Lahoux Spotter S ist unser neues Einstiegsmodell mit sehr guten Bildern und Optionen, die man sonst nur in teureren Modellen findet."},
        longDes_2: {    nl: "Het ergonomische ontwerp past goed in de meeste handen en door zijn compacte formaat zelfs in een (broek)zak. Met een ultramoderne detector en het beste beeld in zijn klasse, is de Lahoux Spotter S een betrouwbare partner voor voornamelijk bos en korte afstandsgebruik. Het lage gewicht zorgt voor een lange observatietijd zonder vermoeidheid. De stille knoppen, het snelle opstarten en de lange looptijd zorgen ervoor dat beeldinformatie missen geen optie meer is.", 
                        en: "The ergonomic design fits well in most hands and because of its compact size it will fit in a pocket. With a state of the art detector and the best image in its class, the Lahoux Spotter S is a reliable partner for mostly forest and short range use. The light weight enables the user long observation time without fatigue. The silent buttons, fast start up and long run time makes sure you will not miss a thing.", 
                        de: "Das ergonomische Design passt gut an die Hand an und aufgrund seiner kompakten Größe passt er in jede Tasche. Mit seinem hochmodernen Detektor und dem besten Bild in seiner Klasse, ist der Lahoux Spotter S ein zuverlässiger Partner für den Einsatz im Wald und auf kurze Distanz. Das geringe Gewicht ermöglicht es dem Benutzer lange Zeit ohne Ermüdung zu beobachten. Die leisen Tasten, der schnelle Start und die lange Laufzeit sorgen dafür, dass Sie nichts verpassen."},
        pros: {         nl: ["Makkelijk te gebruiken", "2 seconden opstarttijd", "Gemakkelijk vervangbare en oplaadbare batterij", "Breed gezichtsveld", "Wifi-streaming naar de Lahoux-app","Foto- en video-opname", "Beeld-in-beeld-functie", "Hotspot volgen","Mode selectie", "Verschillende afbeeldingsmodi"], 
                        en: ["Easy to use", "2 seconds start up", "Easily replaceable and rechargeable battery", "Wide field of view", "Wifi streaming onto the Lahoux App", "Photo and video recording", "PIP", "Hot spot tracking", "Mode selection", "Several Image modes"], 
                        de: ["Einfach zu verwenden", "Startzeit von 2 Sekunden", "Leicht austauschbarer und wiederaufladbarer Akku", "Ein großes Sichtfeld", "Wi-Fi-Streaming auf die Lahoux-App", "Foto- und Videoaufnahme", "Bild-in-Bild- Funktion", "Hotspot-Tracking", "Verschiedene Modi stehen zur Auswahl", "Mehrere Bildmodi"]
    
            },
        number: "02-0006-03145",
        ean: "6090301639607",
        flyer: {nl:"Lahoux_Spotter_S_NL.pdf", en: "Lahoux_Spotter_S_EN.pdf", de: "Lahoux_Spotter_S_DE.pdf"},
        images: ["Lahoux-Spotter-S.png", "Lahoux-Spotter-S-2.png", "Lahoux-Spotter-S-3.png", "Lahoux-Spotter-S-4.png"],
        dri: "Warmtebeeld_Spotters.png",
        accessories: ["as13"],
        footnote_battery: true,
        techs: { 
            Detector: {
                en: {"Resolution": "256×192", "Pixel pitch": "12μm", "Spectral range": "8μm～14μm", "NETD": "≤50mk", "Frame rate": "50Hz"},
                nl: {"Resolutie": "256×192", "Pixelafstand": "12μm", "Spectraalbereik": "8μm～14μm", "NETD": "≤50mk", "Beeldfrequentie": "50Hz"},
                de: {"Auflösung": "256×192", "Pixelabstand": "12μm", "Spektrallbereich": "8μm～14μm", "NETD": "≤50mk", "Bildfrequenz": "50Hz"}
            },
            Optics: {
                en: {"Lens": "10mm", "Relavtive apeture": "F1.0", "Field of view": "17.5°×13.1°", "Zoom": "1.8x optical zoom. 2x digital zoom"},
                nl: {"Lens": "10mm", "Relatieve lensopening": "F1.0", "Gezichtsveld": "17.5°×13.1°", "Zoom": "1,8x optische zoom. 2x digitale zoom"},
                de: {"Linse": "10mm", "Relative Blende": "F1.0", "Sichtfeld": "17.5°×13.1°", "Zoom": "1.8x optischer Zoom. 2x Digitalzoom"}
            },
            Electronics: {
                en: {"Display": "1280 x 960 High-resolution FLCOS Display", "Battery": "18650 replaceable battery", "Video output": "USB type C interface (video out)", "Wifi": "Wifi transmission of video", "Recording": "photo / video"},
                nl: {"Beeldscherm": "1280 x 960 hoge resolutie FLCOS Beeldscherm", "Batterij": "18650 vervangbare batterij", "Video-uitgang": "USB type C-interface (video uit)", "Wifi": "Wifi-overdracht van video", "Opname": "foto / video"},
                de: {"Anzeige": "1280 x 960 hohe Auflösung FLCOS Anzeige", "Batterie": "18650 austauschbare Batterie", "Video-Ausgang": "USB-Typ-C-Schnittstelle (Videoausgang)", "Wifi": "Wifi-Übertragung von Video", "Aufnahme": "Foto / Video"}
            },
            Operating: {
                en: {"Dimensions": "130x40x70", "Weight": "320 gram", "Encapsulation": "IP66", "Battery life": "8h run time at 25°C *, depending on the 18650 battery used"},
                nl: {"Afmetingen": "130x40x70", "Gewicht": "320 gram", "Waterdichtheid": "IP66", "Batterijduur": "8u looptijd bij 25°C *, afhankelijk van de gebruikte 18650-batterij"},
                de: {"Abmessungen": "130x40x70", "Gewicht": "320 gram", "Wasserdicht": "IP66", "Battery life": "8 Stunden Laufzeit bei 25°C *, abhängig vom verwendeten 18650-Akku"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
    },

    {
		id: "wm6",
        orderID: "123",
        type: "thermal",
        title: {nl: "Lahoux Spotter Mini", en: "Lahoux Spotter Mini", de: "Lahoux Spotter Mini"},
        url: "Lahoux-Spotter-Mini",
		price: "646.26",
        shortDes: { nl:"Licht, klein en handig – de Lahoux Spotter Mini is de perfecte instap in de fascinerende warmtebeeldtechnologie. Nu met een pixelafstand van 12 μm!",   
                    en: "Light, small and handy – the Lahoux Spotter Mini is the perfect entry model into the fascinating thermal imaging technology.", 
                    de: "Leicht, klein und handlich – der Lahoux Spotter Mini ist der perfekte Einstieg in die faszinierende Wärmebildtechnologie. Jetzt mit 12µm Pixelabstand!"},
        longDes_1: {    nl:"Licht, klein en handig – de Lahoux Spotter Mini is de perfecte instap in de fascinerende warmtebeeldtechnologie. Zelfs in absolute duisternis of dicht gebladerte kunt u met deze warmtebeeldcamera zien wat eerder verborgen bleef. De 13 mm-lens biedt een breed gezichtsveld. Nu met een pixelafstand van 12 μm en een resolutie van 256 x 192 pixels is de Lahoux Spotter Mini de beste instapper voor wildobservatie.", 
                        en: "Light, small and handy – the Lahoux Spotter Mini is the perfect entry model into the fascinating thermal imaging technology. Even in absolute darkness or dense foliage, this thermal imaging camera allows you to see what previously remained hidden. The 13 mm lens offers a wide field of view. Now with 12 μm pixel pitch and a resolution of 256 x 192 pixel, the Lahoux Spotter Mini is the right entry model for game observation.", 
                        de: "Leicht, klein und handlich – der Lahoux Spotter Mini ist der perfekte Einstieg in die faszinierende Wärmebildtechnologie. Selbst bei absoluter Dunkelheit oder dichtem Laub erlaubt diese Wärmebildkamera zu sehen, was bisher verborgen blieb. Das 13 mm Objektiv bietet ein weites Sehfeld. Jetzt mit 12µm Pixelabstand und einer Auflösung von 256 x 192 Pixel ist der Lahoux Spotter Mini der richtige Einstieg bei der Wildbeobachtung."},
        pros:  {    nl: ["Intuïtieve bediening met één hand", "Lichtgewicht en compact ontwerp", "Kan dag en nacht worden gebruikt", "Groot temperatuurbereik", "Gebruiksvriendelijke bediening", "Waterdicht (IP66)", "Hoge beeldsnelheid van 50 Hz", "Digitale vergroting", "Geïntegreerde lithium-ion accu", "WiFi" ], 
                    en: ["Intuitive one-hand operation","Lightweight and compact design", "Can be used day or night", "Large operating temperature range", "User-friendly operation", "Waterproof (IP66)", "High frame rate of 50 Hz", "Digital magnification", "Integrated lithium-ion battery", "WiFi"], 
                    de: ["Intuitive Einhandbedienung", "Leichte und kompakte Bauform", "Bei Tag und Nacht einsetzbar", "Großer Betriebstemperaturbereich", "Benutzerfreundliche Bedienung", "Wasserdicht (IP66)", "Hohe Bildwechselfrequenz von 50 Hz", "Digitale Vergrößerung", "Integrierter Lithium-Ionen-Akku", "WiFi" ]},
    
        number: "02-0006-03161",
        ean: "6090301496446",
        leveromvang: [ 1, 7, 4, 8, 3, 5],
        flyer: {nl:"Lahoux_Spotter_NL_V10.pdf", en: "Lahoux_Spotter_EN_V9.pdf", de: "Lahoux_Spotter_DE_V11.pdf"},
        images: ["wm6_1.png", "wm6_3.png"],
        dri: "Warmtebeeld_Spotters.png",
        related: ["wm8"],
        accessories: ["as13"],
        footnote_battery: true,
        techs: {
            Detector: {
                en: {"Resolutie VOx uncooled FPA": "240 x 180", "Pixel pitch": "12 µm", "Spectral range": "8-14 µm", "Sensitivity (NETD)": "< 50 mK", "Frame rate": "50 Hz"},
                nl: {"Resolutie VOx ongekoeld FPA": "240 x 180", "Pixelafstand": "12 µm", "Spectraalbereik": "8-14 µm", "Gevoeligheid (NETD)": "< 50 mK", "Beeldfrequentie": "50 Hz"},
                de: {"Auflösung VOx ungekühlt FPA": "240 x 180", "Pixelabstand": "12 µm", "Spektrallbereich": "8-14 µm", "Empfindlichkeit (NETD)": "< 50 mK", "Bildfrequenz": "50 Hz"}
            },
            Optics: {
                en: {"Lens": "13 mm", "Magnification (digital)": "1x, 2x", "Eye relief": "Adjustment with rotary knob"},
                nl: {"Lens": "13 mm", "Vergroting (digitaal)": "1x, 2x", "Oogafstand": "Instelling met draaiknop"},
                de: {"Linse": "13 mm", "Vergrößerung (digital)": "1x, 2x", "Augenabstand": "Einstellung mit Drehknopf"}
            },
            Electronics: {
                en: {"USB": "For charging, can be charged by USB powerbank", "Display FLCOS": "720 x 540", "Wifi": "√"},
                nl: {"USB": "Om op te laden, kan worden opgeladen met een USB powerbank", "Beeldscherm FLCOS": "720 x 540", "Wifi": "√"},
                de: {"USB": "Zum Aufladen, kann über USB-Powerbank aufgeladen werden", "Anzeige FLCOS": "720 x 540", "Wifi": "√"}
            },
            Operating: {
                en: {"Temperature range": "−20°C to +50°C", "Power supply": "Internal lithium-ion battery", "Battery life" : "20 hours (at 21°C) *", "Encapsulation": "IP66", "Dimensions": "160x62x62 mm", "Weight": "0,32 kg"},
                nl: {"Temperatuurbereik": "−20°C to +50°C", "Voeding": "Interne lithium-ion batterij", "Batterijduur" : "20 uur (bij 21°C) *", "Waterdichtheid": "IP66", "Afmetingen": "160x62x62 mm", "Gewicht": "0,32 kg"},
                de: {"Temperaturbereich": "−20°C to +50°C", "Stromversorgung": "Interne Lithium-Ionen-Batterie", "Batterielaufzeit" : "20 Stunden (bei 21°C) *", "Wasserdicht": "IP66", "Abmessungen": "160x62x62 mm", "Gewicht": "0,32 kg"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}

        
	},
    {
        id: "wm8",
        orderID: "124",
        type: "thermal",
        title: {nl: "Lahoux Spotter Pro V", en: "Lahoux Spotter Pro V", de: "Lahoux Spotter Pro V"},
        url: "Lahoux-Spotter-Pro-V",
        price: "952.38",
        endOfLife: true,
        shortDes: { nl:"Wildobservatie van de volgende generatie. De Lahoux Spotter Pro V is de ideale metgezel voor bos- en veldjacht, zelfs in totale duisternis.", 
                    en: "Wildlife watching of the next generation. The Lahoux Spotter Pro V is the ideal companion for forest and field hunting even in total darkness.", 
                    de: "Die nächste Generation der Wildbeobachtung. Der Lahoux Spotter Pro V ist der ideale Begleiter für die Wald- und Feldjagd, auch bei völliger Dunkelheit."},
        longDes_1:    { nl:"Wildobservatie van de volgende generatie. De Lahoux Spotter Pro V is de ideale metgezel voor bos- en veldjacht, zelfs in totale duisternis. De beeldsnelheid van 50 Hz zorgt voor een trillingsvrij beeld en met de vier beeldmodi (witheet, zwartheet, roodheet en pseudokleuren) kan het beeld naar wens worden geselecteerd. Dankzij de 19 mm-optiek en de pixelafstand van 17 μm en een resolutie van 384 x 288 pixels kunt u wild ontdekken, zelfs over langere afstand.", 
                        en: "Wildlife watching of the next generation. The Lahoux Spotter Pro V is the ideal companion for forest and field hunting even in total darkness. The 50 Hz frame rate ensures a jitter-free image and with its four viewing modes (heat = black / heat = white / heat = red / multi-color), the image can be selected as needed. The 19mm optics and 17μm pixel pitch and a resolution of 384 x 288 pixel allows you to find game even at longer distances.", 
                        de: "Die nächste Generation der Wildbeobachtung. Der Lahoux Spotter Pro V ist der ideale Begleiter für die Wald- und Feldjagd, auch bei völliger Dunkelheit. Die Bildrate von 50 Hz sorgt für ein flimmerfreies Bild und mit den vier Bildmodi (White Hot, Black Hot, Red Hot und Pseudocolor) kann das Bild nach Belieben gewählt werden. Mit der 19-mm-Optik und 17 m Pixelabstand und einer Auflösung von 384 x 288 Pixel können Sie Wildtiere auch über größere Entfernungen entdecken."},
        pros:  {        nl: ["Intuïtieve bediening met één hand", "Lichtgewicht en compact ontwerp", "Kan dag en nacht worden gebruikt", "Groot temperatuurbereik", "Gebruiksvriendelijke bediening", "Waterdicht (IP66)", "Hoge beeldsnelheid van 50 Hz", "Digitale vergroting", "Geïntegreerde lithium-ion accu", "WiFi"], 
                        en: ["Intuitive one-hand operation", "Lightweight and compact design", "Can be used day or night", "Large operating temperature range", "User-friendly operation", "Waterproof (IP66)" , "High frame rate of 50 Hz", "Digital magnification", "Integrated lithium-ion battery", "WiFi"], 
                        de: ["Intuitive Einhandbedienung", "Leichtes und kompaktes Design", "Tag und Nacht einsetzbar", "Großer Temperaturbereich", "Einfache Bedienung", "Wasserdicht (IP66)", "Hohe Bildrate von 50 Hz", "Digitale Vergrößerung", "Integrierter Lithium-Ionen-Akku", "WiFi"]},
                    
        number: "02-0006-03160",
        ean: "6090301578555",
        leveromvang: [ 1, 7, 4, 8, 3, 5],
        flyer: {nl:"Lahoux_Spotter_NL_V10.pdf", en: "Lahoux_Spotter_EN_V9.pdf", de: "Lahoux_Spotter_DE_V11.pdf"},
        images: ["wm8_1.png"],
        dri: "Warmtebeeld_Spotters.png",
        related: ["wm6", "wm10"],
        accessories: ["as13"],
        footnote_battery: true,
        techs: {
            Detector: {
                en: {"Resolutie VOx uncooled FPA": "384 x 288", "Pixel pitch": "17 µm", "Spectral range": "8-14 µm", "Sensitivity (NETD)": "< 50 mK", "Frame rate": "50 Hz"},
                nl: {"Resolutie VOx ongekoeld FPA": "384 x 288", "Pixelafstand": "17 µm", "Spectraalbereik": "8-14 µm", "Gevoeligheid (NETD)": "< 50 mK", "Beeldfrequentie": "50 Hz"},
                de: {"Auflösung VOx ungekühlt FPA": "384 x 288", "Pixelabstand": "17 µm", "Spektrallbereich": "8-14 µm", "Empfindlichkeit (NETD)": "< 50 mK", "Bildfrequenz": "50 Hz"}
            },
            Optics: {
                en: {"Lens": "19 mm", "Magnification (digital)": "1x, 2x, 4x", "Eye relief": "Adjustment with rotary knob"},
                nl: {"Lens": "19 mm", "Vergroting (digitaal)": "1x, 2x, 4x", "Oogafstand": "Instelling met draaiknop"},
                de: {"Linse": "19 mm", "Vergrößerung (digital)": "1x, 2x, 4x", "Augenabstand": "Einstellung mit Drehknopf"}
            },
            Electronics: {
                en: {"Video output": "PAL", "USB": "For charging, can be charged by USB powerbank", "Image transmission": "Not possible", "Display FLCOS": "720 x 540", "Wifi": "√"},
                nl: {"Video-uitgang": "PAL", "USB": "Om op te laden, kan worden opgeladen met een USB powerbank", "Beeldoverdracht": "Niet mogelijk", "Beeldscherm FLCOS": "720 x 540", "Wifi": "√"},
                de: {"Video-Ausgang": "PAL", "USB": "Zum Aufladen, kann über USB-Powerbank aufgeladen werden", "Bildübertragung": "Nicht möglich", "Anzeige FLCOS": "720 x 540", "Wifi": "√"}
            },
            Operating: {
                en: {"Temperature range": "−20°C to +50°C", "Power supply": "Internal lithium-ion battery", "Battery life" : "15 hours (at 21°C) *", "Encapsulation": "IP66", "Dimensions": "170x65x65 mm", "Weight": "0,40 kg"},
                nl: {"Temperatuurbereik": "−20°C to +50°C", "Voeding": "Interne lithium-ion batterij", "Batterijduur" : "15 uur (bij 21°C) *", "Waterdichtheid": "IP66", "Afmetingen": "170x65x65 mm", "Gewicht": "0,40 kg"},
                de: {"Temperaturbereich": "−20°C to +50°C", "Stromversorgung": "Interne Lithium-Ionen-Batterie", "Batterielaufzeit" : "15 Stunden (bei 21°C) *", "Wasserdicht": "IP66", "Abmessungen": "170x65x65 mm", "Gewicht": "0,40 kg"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}

    },
    {
        id: "wm9",
        orderID: "125",
        type: "thermal",
        title: {nl: "Lahoux Spotter T", en: "Lahoux Spotter T", de: "Lahoux Spotter T"},
        url: "Lahoux-Spotter-T",
        price: "1214.29",
        shortDes: { nl:"De Lahoux Spotter T is de nieuwste handwarmtebeeld monocamera van Lahoux Optics voor jagers die een eenvoudige spotter willen voor de korte tot middellange afstand met foto en video mogelijkheid.", 
                    en: "The Lahoux Spotter T is the latest handheld thermal observation monocular of Lahoux Optics for hunters that want an easy to use Spotter for short to intermediate range with photo and Video possibility. ", 
                    de: "Der Lahoux Spotter T ist das neueste handliche Wärmebeobachtungsgerät von Lahoux Optics für Jäger, die einen einfach zu bedienenden Spotter für kurze bis mittlere Entfernungen mit Foto- und Videomöglichkeit suchen. "},
        longDes_1: {    nl:"De Lahoux Spotter T is de nieuwste handwarmtebeeld monocamera van Lahoux Optics voor jagers die een eenvoudige spotter willen voor de korte tot middellange afstand met foto en video mogelijkheid. De 400 x 300 IR detector, 1280 x 960 HD beeldscherm en 25 mm lens, zorgen voor een excellent inzetbare warmtebeeld observatiekijker voor elke omstandigheid.De hoge ververs snelheid van 50 Hz is wat u van Lahoux Optics verwacht en de Lahoux Spotter T zal u niet teleurstellen in elk weertype.", 
                        en: "The Lahoux Spotter T is the latest handheld thermal observation monocular of Lahoux Optics for hunters that want an easy to use Spotter for short to intermediate range with photo and Video possibility. The 400 x 300 IR sensor, 1280 x 960 HD display and 25 mm lens, give the user an excellent adaptable thermal imager for any condition. The high frame rate of 50 Hz is what you expect from, Lahoux and the Lahoux Spotter T will not let you down in any type of weather.", 
                        de: "Der Lahoux Spotter T ist das neueste handliche Wärmebeobachtungsgerät von Lahoux Optics für Jäger, die einen einfach zu bedienenden Spotter für kurze bis mittlere Entfernungen mit Foto- und Videomöglichkeit suchen. Der 400 x 300 IR-Sensor, das 1280 x 960 HD-Display und das 25-mm-Objektiv geben dem Anwender eine hervorragend anpassbare Wärmebildkamera für alle Bedingungen. Die hohe Bildwiederholrate von 50 Hz ist das, was Sie von Lahoux erwarten, und der Lahoux Spotter T lässt Sie nicht im Stich; egal bei welchem Wetter."},
        pros: {     nl: ["1280 × 960 HD beeldscherm voor comfortabel observeren", "Hoge resolutie detector zorgt voor een prettig neutraal beeld.", "Stille en ergonomisch geplaatste knoppen", "Oogdop is ook zeer geschikt voor brildrager", "Ingebouwde stadiametrische afstandsmeter voor een snelle afstands-indicatie", "Eenvoudig bruikbaar snelmenu en functieknoppen", "Micro-stap zoom", "Meervoudige (kleuren) paletten voor een aangepast beeld onder elke omstandigheid", "Shutter-kalibratie met extra stille modus" ], 
                    en: ["1280 × 960 HD display for comfortable viewing", "High resolution sensor ensures a very pleasant image", "Silent and ergonomically placed buttons", "Eyepiece also suited for spectacle wearers", "Built-in stadiametric rangefinder for a fast range estimation", "Easy to use quick menu and button functions", "Micro step zoom", "Multiple (colour) palettes for adapted vision in any environment", "Shutter calibration with extra noiseless setting"], 
                    de: ["1280 × 960 HD-Display für komfortable Betrachtung", "Hochauflösender Sensor sorgt für ein sehr angenehmes Bild", "Leise und ergonomisch platzierte Tasten", "Okular auch für Brillenträger geeignet", "Eingebauter stadiametrischer Entfernungsmesser für eine schnelle Entfernungseinschätzung", "Schnell und einfach zu bedienendes Menü und Tastenfunktionen", "Mikro-Stufen-Zoom", "Mehrere (Farb-)Paletten für angepasstes Sehen in jeder Umgebung", "Shutter-Kalibrierung mit extra geräuscharmer Einstellung"]

                    },
        number: "02-0006-02631",
        ean: "6090301422438",
        leveromvang: [ 1, 7, 4, 8, 3],
        flyer: {nl:"Lahoux_Spotter_NL_V10.pdf", en: "Lahoux_Spotter_EN_V9.pdf", de: "Lahoux_Spotter_DE_V11.pdf"},
        images: ["wm9_1.png", "wm9_2.png", "wm9_3.png", "wm9_4.png"],
        dri: "Warmtebeeld_Spotters.png",
        related: ["wm8", "wm10"],
        accessories: ["as13"],
        footnote_battery: true,
        endOfLife: true,        
        techs: {
            Detector: { 
                en: {"Resolution": "400 × 300 px", "Pixel pitch": "17 µm", "NETD": "≤50 mK"},
                nl: {"Resolutie": "400 × 300 px", "Pixelafstand": "17 µm", "NETD": "≤50 mK"},
                de: {"Auflösung": "400 × 300 px", "Pixelabstand": "17 µm", "NETD": "≤50 mK"}
            },
            Optics: {
                en: {"Lens": "25 mm", "Magnification (digital)": "1x, 2x, 4x", "Relative aperture": "F/1.1", "Field of view": "15.4° × 11.6°", "Field of view at 100 m": "27.5 m", "Detection distance": "931 m"},
                nl: {"Lens": "25 mm", "Vergroting (digitaal)": "1x, 2x, 4x", "Relatieve lensopening": "F/1.1", "Gezichtsveld": "15.4° × 11.6°", "Gezichtsveld bij 100 m": "27.5 m", "Detectieafstand": "931 m"},
                de: {"Linse": "25 mm", "Vergrößerung (digital)": "1x, 2x, 4x", "Relative Blende": "F/1.1", "Sichtfeld": "15.4° × 11.6°", "Sichtfeld bei 100 m": "27.5 m", "Erkennungsabstand": "931 m"}
            },
            Electronics: {
                en: {"Screen resolution": "1280 × 960 px", "Diopter Adjustment": "-4～+2 D", "Frame Rate": "50 Hz", "Colour palettes": "White hot, Black hot, Red hot 1, Red hot 2, Red hot 3", "Scene modes": "Enhanced, Hightlight, Forest, City, Snow and Default (indoor)"},
                nl: {"Schermresolutie": "1280 × 960 px", "Dioptrie aanpassing": "-4～+2 D", "Beeldfrequentie": "50 Hz", "Kleurenpaletten": "Wit heet, Zwart heet, Rood heet 1, Rood heet 2, Rood heet 3", "Scènemodi": "Verbeterd, Uitgelicht, Bos, Stad, Sneeuw en Standaard (binnen)"},
                de: {"Bildschirmauflösung": "1280 × 960 px", "Dioptrien-Einstellung": "-4～+2 D", "Bildfrequenz": "50 Hz", "Farbpaletten": "Weiß heiß, Schwarz heiß, Rot heiß 1, Rot heiß 2, Rot heiß 3", "Szene-Modi": "Erweitert, beleuchtet, Wald, Stadt, Schnee und Standard (innen)"}
            },
            Operating: {
                en: {"Battery": "Internal Li-ion batteries, 2 × 18650", "Video output": "Micro HDMI", "Battery runtime" : "5 hours (at 21°C) *", "Operating temperature": "-10 °C ~ 50 °C", "Storage temperature": "-30 °C ~ 60 °C", "Encapsulation": "IP66", "Weight": "470 g", "Dimensions": "192 × 65 × 71 mm"},
                nl: {"Batterij": "Interne Li-ion batterijen, 2 × 18650", "Video-uitgang": "Micro HDMI", "Batterijgebruiksduur" : "5 uur (bij 21°C) *", "Bedrijfstemperatuurbereik": "-10 °C ~ 50 °C", "Bewaartemperatuur": "-30 °C ~ 60 °C", "Waterdichtheid": "IP66", "Gewicht": "470 g", "Afmetingen": "192 × 65 × 71 mm"},
                de: {"Batterie": "Interne Li-Ionen-Batterien, 2 × 18650", "Video-Ausgang": "Micro HDMI", "Batterielaufzeit" : "5 Stunden (bei 21°C) *", "Betriebstemperaturbereich": "-10 °C ~ 50 °C", "Lagertemperatur": "-30 °C ~ 60 °C", "Wasserdicht": "IP66", "Gewicht": "470 g", "Abmessungen": "192 × 65 × 71 mm"}
            },
            Functions: {
                en: {"Photo": "√", "Video": "√", "Hotspot Tracking": "√", "Stadiametric rangefinder": "√", "Wifi": "√"},
                nl: {"Foto": "√", "Video": "√", "Hotspot volgen": "√", "Stadiametrische afstandsmeter": "√", "Wifi": "√"},
                de: {"Foto": "√", "Video": "√", "Hotspot-Verfolgung": "√", "Stadiametrischer Entfernungsmesser": "√", "Wifi": "√"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}

    },
    {
		id: "wm10",
        orderID: "126",
        type: "thermal",
        title: {nl: "Lahoux Spotter 25", en: "Lahoux Spotter 25", de: "Lahoux Spotter 25"},
        url: "Lahoux-Spotter-25",
		price: "1530.61",
        shortDes: { nl:"De Lahoux Spotter 25 is de nieuwe dimensie van warmtebeeldtechnologie. Het wordt gekenmerkt door de grote 25 mm-lens en een pixelgrootte van 12μm.", 
                    en: "The Lahoux Spotter 25 is the new dimension of thermal imaging technology. It is characterized by the large 25 mm lens and a pixel size of 12μm.", 
                    de: "Der Lahoux Spotter 25 ist die neue Dimension der Wärmebildtechnologie. Er zeichnet sich durch das große 25 mm Objektiv und einer Pixelabstand von 12µm."},
        longDes_1: {    nl:"De Lahoux Spotter 25 is de nieuwe dimensie van warmtebeeldtechnologie. Het wordt gekenmerkt door de grote 25 mm-lens en een pixelgrootte van 12μm. De beeldsnelheid van 50 Hz geeft een absoluut trillingsvrij beeld en de vier beeldmodi (witheet, zwartheet, roodheet en pseudokleuren) zorgen voor een perfecte weergave. Bovendien is het bij de Lahoux Spotter 25 via de geintegreerde wifi-functie mogelijk om via een Android- of iOS app, tot een maximale afstand van 50 meter, live mee te kijken of foto’s of video te verzenden.", 
                        en: "The Lahoux Spotter 25 is the new dimension of thermal imaging technology. It is characterized by the large 25 mm lens and a pixel size of 12μm. The 50 Hz frame rate, will give an absolutely smooth image and the four viewing modes (white hot, black hot, red hot and pseudo colours ensure a perfect image reproduction. In addition, the Lahoux Spotter 25, through its integrated WiFi function and an Android or IOS app, it is possible to view live images or transmit fotos or video, up to a distance of 50 meters.", 
                        de: "Der Lahoux Spotter 25 ist die neue Dimension der Wärmebildtechnologie. Die 50 Hz Bildfrequenz bietet ein absolut wackelfreies Bild und die vier Betrachtermodi (Wärme = schwarz / Wärme = weiß / Wärme = rot / Multifarben) sorgen für die perfekte Bildwiedergabe. Zusätzlich übermittelt der Lahoux Spotter 25 via integrierten WiFi-Funktion bis auf eine Entfernung von ca. 50 Meter live auf ein Android- bzw. iOS-Gerät mit einer App."},
        pros: {     nl: ["Intuïtieve bediening met één hand", "Lichtgewicht en compact ontwerp", "Kan dag en nacht worden gebruikt", "Groot temperatuurbereik", "Gebruiksvriendelijke bediening", "Waterdicht (IP67)", "Hoge beeldsnelheid van 50 Hz", "Digitale vergroting", "Geïntegreerde lithium-ion accu"], 
                    en: ["Intuitive one-hand operation", "Lightweight and compact design", "Can be used day or night", "Large operating temperature range", "User-friendly operation", "Waterproof (IP67)", "High frame rate of 50 Hz", "Digital magnification", "Integrated lithium-ion battery"], 
                    de: ["Intuitive Einhandbedienung", "Leichte und kompakte Bauform", "Bei Tag und Nacht einsetzbar", "Großer Betriebstemperaturbereich", "Benutzerfreundliches Interface", "Wasserdicht (IP67)", "Hohe Bildwechselfrequenz von 50 Hz", "Digitale Vergrößerung", "Integrierter Lithium-Ionen-Akku"]

                    },
        number: "02-0006-02643",
        leveromvang: [ 1, 7, 4, 8, 3, 5],
        flyer: {nl:"Lahoux_Spotter_NL_V10.pdf", en: "Lahoux_Spotter_EN_V9.pdf", de: "Lahoux_Spotter_DE_V11.pdf"},
        images: ["wm10_1.png", "wm10_2.png", "wm10_3.png", "wm10_4.png"],
        dri: "Warmtebeeld_Spotters.png",
        related: ["wm9", "wm11"],
        accessories: ["as13"],
        footnote_battery: true,
        endOfLife: false,        
        techs: {
            Detector: { 
                en: {"Resolutie VOx uncooled FPA": "384 x 288", "Pixel pitch": "12 µm", "Spectral range": "8-14 µm", "Sensitivity (NETD)": "< 50 mK", "Frame rate": "50 Hz"},
                nl: {"Resolutie VOx ongekoeld FPA": "384 x 288", "Pixelafstand": "12 µm", "Spectraalbereik": "8-14 µm", "Gevoeligheid (NETD)": "< 50 mK", "Beeldfrequentie": "50 Hz"},
                de: {"Auflösung VOx ungekühlt FPA": "384 x 288", "Pixelabstand": "12 µm", "Spektrallbereich": "8-14 µm", "Empfindlichkeit (NETD)": "< 50 mK", "Bildfrequenz": "50 Hz"}
            },
            Optics: {
                en: {"Lens": "25 mm", "Magnification (digital)": "2x, 4x", "Eye relief": "Adjustment with rotary knob"},
                nl: {"Lens": "25 mm", "Vergroting (digitaal)": "2x, 4x", "Oogafstand": "Instelling met draaiknop"},
                de: {"Linse": "25 mm", "Vergrößerung (digital)": "2x, 4x", "Augenabstand": "Einstellung mit Drehknopf"}
            },
            Electronics: {
                en: {"Video output": "Wifi", "USB": "For charging, can be charged by USB powerbank", "Image transmission": "Video / photo transmission (8GB internal) by WiFi in combination with special app", "Display FLCOS": "1280 x 960"},
                nl: {"Video-uitgang": "Wifi", "USB": "Om op te laden, kan worden opgeladen met een USB powerbank", "Beeldoverdracht": "Video/foto-overdracht (8GB intern) via WiFi in combinatie met speciale app", "Beeldscherm FLCOS": "1280 x 960"},
                de: {"Video-Ausgang": "Wifi", "USB": "Zum Aufladen, kann über USB-Powerbank aufgeladen werden", "Bildübertragung": "Video-/Fotoübertragung (8GB intern) per WiFi in Kombination mit einer speziellen App", "Anzeige FLCOS": "1280 x 960"}
            },
            Operating: {
                en: {"Temperature range": "−20°C to +50°C", "Power supply": "Internal lithium-ion battery", "Battery life": "3 hours (at 21°C, wifi switched on) * - 4,5 hours (at 21°C, wifi switched off) *", "Encapsulation": "IP67", "Dimensions": "181x65x64 mm", "Weight": "0,45 kg"},
                nl: {"Temperatuurbereik": "−20°C to +50°C", "Voeding": "Interne lithium-ion batterij", "Batterijduur": "3 uur (bij 21°C, wifi ingeschakeld) * - 4,5 uur (bij 21°C, wifi uitgeschakeld) *", "Waterdichtheid": "IP67", "Afmetingen": "181x65x64 mm", "Gewicht": "0,45 kg"},
                de: {"Temperaturbereich": "−20°C to +50°C", "Stromversorgung": "Interne Lithium-Ionen-Batterie", "Batterielaufzeit": "3 Stunden (bei 21°C, Wifi eingeschaltet) * - 4,5 Stunden (bei 21°C, Wifi ausgeschaltet) *", "Wasserdicht": "IP67", "Abmessungen": "181x65x64 mm", "Gewicht": "0,45 kg"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
	},
    {
        id: "wm11",
        orderID: "127",
        type: "thermal",
        title: {nl: "Lahoux Spotter 35", en: "Lahoux Spotter 35", de: "Lahoux Spotter 35"},
        url: "Lahoux-Spotter-35",
        price: "1785.71",
        spotlight: {    nl:"De nieuwe Lahoux Spotter 35 (nieuwe generatie) is de ideale warmtebeeldcamera voor bos- en veldjacht. Dankzij de detector met een pixelpitch van 12 µm kenmerkt het toestel zich door een aanzienlijk scherper en contrastrijker beeld. In combinatie met de 35 mm lens is het detectiebereik vergroot, maar is het gezichtsveld om technische redenen wat kleiner.", 
                        en: "The new Lahoux Spotter 35 (new generation) is the ideal thermal imaging camera for forest and field hunting. Thanks to the detector with a 12 µm pixel pitch, the device is characterized by a significantly sharper and higher-contrast image. In combination with the 35 mm lens, the detection range has increased, but the field of view is somewhat smaller for technical reasons.", 
                        de: "Der neue Lahoux Spotter 35 (neue Generation) ist die ideale Wärmebildkamera für die Wald- und Feldjagd. Durch den Detektor mit 12 µm Pixel Pitch zeichnet sich das Gerät durch ein deutlich schärferes und kontrastreicheres Bild aus. In Kombination mit dem 35 mm großen Objektiv ist die Detektionsreichweite größer geworden, das Sehfeld aber technisch bedingt etwas geringer."},
        shortDes: { nl:"De nieuwe Lahoux Spotter 35 (nieuwe generatie) is de ideale warmtebeeldcamera voor bos- en veldjacht. Dankzij de detector met een pixelpitch van 12 µm kenmerkt het toestel zich door een aanzienlijk scherper en contrastrijker beeld. In combinatie met de 35 mm lens is het detectiebereik vergroot, maar is het gezichtsveld om technische redenen wat kleiner.", 
                    en: "The new Lahoux Spotter 35 (new generation) is the ideal thermal imaging camera for forest and field hunting. Thanks to the detector with a 12 µm pixel pitch, the device is characterized by a significantly sharper and higher-contrast image. In combination with the 35 mm lens, the detection range has increased, but the field of view is somewhat smaller for technical reasons.", 
                    de: "Der Lahoux Spotter 35 ist die neue Dimension der Wärmebildtechnologie. Er zeichnet sich durch das große 35 mm Objektiv und einer Pixelabstand von 12µm. Damit lässt sich auch auf große Distanz noch Wild entdecken, bestimmen und ansprechen. Die 50 Hz Bildfrequenz bietet ein absolut wackelfreies Bild und die vier Betrachtermodi (Wärme = schwarz / Wärme = weiß / Wärme = rot / Multifarben) sorgen für die perfekte Bildwiedergabe. Zusätzlich übermittelt der Lahoux Spotter 35 mit integrierter Foto- und Videofunktion bis auf eine Entfernung von ca. 50 Meter live auf ein Android- bzw. iOS-Gerät mit einer App."},
        longDes_1: {    nl:"De nieuwe Lahoux Spotter 35 (nieuwe generatie) is de ideale warmtebeeldcamera voor bos- en veldjacht. Dankzij de detector met een pixelpitch van 12 µm kenmerkt het toestel zich door een aanzienlijk scherper en contrastrijker beeld. In combinatie met de 35 mm lens is het detectiebereik vergroot, maar is het gezichtsveld om technische redenen wat kleiner.", 
                        en: "The new Lahoux Spotter 35 (new generation) is the ideal thermal imaging camera for forest and field hunting. Thanks to the detector with a 12 µm pixel pitch, the device is characterized by a significantly sharper and higher-contrast image. In combination with the 35 mm lens, the detection range has increased, but the field of view is somewhat smaller for technical reasons.", 
                        de: "Der Lahoux Spotter 35 ist die neue Dimension der Wärmebildtechnologie. Er zeichnet sich durch das große 35 mm Objektiv und einer Pixelabstand von 12µm. Damit lässt sich auch auf große Distanz noch Wild entdecken, bestimmen und ansprechen. Die 50 Hz Bildfrequenz bietet ein absolut wackelfreies Bild und die vier Betrachtermodi (Wärme = schwarz / Wärme = weiß / Wärme = rot / Multifarben) sorgen für die perfekte Bildwiedergabe. Zusätzlich übermittelt der Lahoux Spotter 35 mit integrierter Foto- und Videofunktion bis auf eine Entfernung von ca. 50 Meter live auf ein Android- bzw. iOS-Gerät mit einer App."},
        pros: {     nl: ["Intuïtieve bediening met één hand", "Lichtgewicht en compact ontwerp", "Kan dag en nacht worden gebruikt", "Groot temperatuurbereik", "Gebruiksvriendelijke bediening", "Waterdicht (IP65)", "Hoge beeldsnelheid van 50 Hz", "Digitale vergroting (2×, 4×)", "Geïntegreerde lithium-ion accu"], 
                    en: ["Intuitive one-hand operation", "Lightweight and compact design", "Can be used day or night", "Large operating temperature range", "User-friendly operation", "Waterproof (IP65)", "High frame rate of 50 Hz", "Digital magnification (2×, 4×)", "Integrated lithium-ion battery"], 
                    de: ["Intuitive Einhandbedienung", "Leichte und kompakte Bauform", "Bei Tag und Nacht einsetzbar", "Großer Betriebstemperaturbereich", "Benutzerfreundliche Bedienung", "Wasserdicht (IP65)", "Hohe Bildwechselfrequenz von 50 Hz", "Digitale Vergrößerung (Faktor: 2-fach, 4-fach)", "Integrierter Lithium-Ionen-Akku"]

                    },
        number: "02-0006-02422",
        ean: "6090300843838",
        leveromvang: [ 1, 7, 4, 8, 3, 5],
        flyer: {nl:"Lahoux_Spotter_NL_V10.pdf", en: "Lahoux_Spotter_EN_V9.pdf", de: "Lahoux_Spotter_DE_V11.pdf"},
        images: ["wm11_1.png", "wm11_2.png", "wm11_3.png", "wm11_4.png"],
        dri: "Warmtebeeld_Spotters.png",
        related: ["wm10", "wm12"],
        accessories: ["as13"],
        endOfLife: true,        
        videos: {
            nl: {"eulen.mp4": "Uilen geobserveerd en opgenomen met de Spotter 35", "Nachtzwaluw-Lahoux-Spotter-35.mp4": "Nachtzwaluw (Caprimulgus europaeus) geobserveerd en opgenomen met de Spotter 35"},
            en: {"eulen.mp4": "Owls observed and recorded with the Spotter 35", "Nachtzwaluw-Lahoux-Spotter-35.mp4": "Nightjar (Caprimulgus europaeus) observed and recorded with the Spotter 35"},
            de: {"eulen.mp4": "Mit dem Spotter 35 beobachtete und erfasste Eulen", "Nachtzwaluw-Lahoux-Spotter-35.mp4": "Mit dem Spotter 35 beobachtete und erfasste Ziegenmelker (Caprimulgus europaeus)"}
        },
        footnote_battery: true,
        techs: {
            Detector: { 
                en: {"Resolutie": "384 x 288", "Pixel pitch": "12 µm", "NETD, mk": "≤40", "Frame rate": "50 Hz"},
                nl: {"Resolutie": "384 x 288", "Pixelafstand": "12 µm", "NETD, mk": "≤40", "Beeldfrequentie": "50 Hz"},
                de: {"Auflösung": "384 x 288", "Pixelabstand": "12 µm", "NETD, mk": "≤40", "Bildfrequenz": "50 Hz"}
            },
            Optics: {
                en: {"Lens": "35 mm", "Field of View": "7.5°×5.7°", "Optical Magnification, x": "3.5-14.0", "Digital Zoom": "2x, 4x"},
                nl: {"Lens": "35 mm", "Gezichtsveld": "7.5°×5.7°", "Optische vergroting, x": "3.5-14.0", "Digitale zoom": "2x, 4x"},
                de: {"Linse": "35 mm", "Sichtfeld": "7.5°×5.7°", "Optische Vergrößerung, x": "3.5-14.0", "Digitaler Zoom": "2x, 4x"}
            },
            Electronics: {
                en: {"Display LCOS": "1280 x 960", "Amount of built-in memory, GB": "16", "Video output": "WiFi"},
                nl: {"Beeldscherm LCOS": "1280 x 960", "Hoeveelheid ingebouwd geheugen, GB": "16", "Video-uitgang": "WiFi"},
                de: {"Anzeige LCOS": "1280 x 960", "Umfang des eingebauten Speichers, GB": "16", "Video-Ausgang": "WiFi"}
            },
            Operating: {
                en: {"Battery life": "max 7 hours (at 21°C) *", "Dimensions": "186x65x64 mm", "Weight, g": "<420", "Encapsulation": "IP65","Detection Range, m  (Target size:1.7m×0.5m,P(n)=99%)": "1818"},
                nl: {"Batterijduur": "max 7 uur (bij 21°C) *", "Afmetingen": "186x65x64 mm", "Gewicht, g": "<420", "Waterdichtheid": "IP65","Detectiebereik, m  (Doelgrootte:1.7m×0.5m,P(n)=99%)": "1818"},
                de: {"Batterielaufzeit": "max 7 Stunden (bei 21°C) *", "Abmessungen": "186x65x64 mm", "Gewicht, g": "<420", "Wasserdicht": "IP65","Detektionsbereich, m  (Zielgröße:1.7m×0.5m,P(n)=99%)": "1818"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}

    },
    {
        id: "wm12",
        orderID: "155",
        type: "thermal",
        title: {nl: "Lahoux Spotter Elite 35V", en: "Lahoux Spotter Elite 35V", de: "Lahoux Spotter Elite 35V"},
        url: "Lahoux-Spotter-Elite-35V",
        price: "2295.92",
        shortDes: { nl:"De Lahoux Spotter Elite 35V is de nieuwe dimensie van warmtebeeldtechnologie. Het belangrijkste punt van de Elite-serie is de grote detector met een resolutie van 640 x 480 pixels met een pixelafstand van 12μm.", 
                    en: "The Lahoux Spotter Elite 35V is the new dimension of thermal imaging technology. The highlight of the Elite series is the large detector with a resolution of 640 x 480 pixels with a pixel pitch of 12μm.", 
                    de: "Der Lahoux Spotter Elite 35V ist die neue Dimension der Wärmebildtechnologie. Das Highlight der Elite Serie ist der große Monitor mit einer Auflösung von 640 x 480 Pixel bei einer Pixelabstand von 12µm."},
        longDes_1: {    nl:"De Lahoux Spotter Elite 35V is de nieuwe dimensie van warmtebeeldtechnologie. Het belangrijkste punt van de Elite-serie is de grote detector met een resolutie van 640 × 480 pixels met een pixelafstand van 12μm. Hiermee kun je wild op grote afstanden ontdekken, bepalen en erop reageren. De beeldsnelheid van 50 Hz zorgt voor een absoluut trillingsvrij beeld en de vier beeldmodi (witheet, zwartheet, roodheet en pseudokleuren) zorgen voor een perfecte weergave. Daarbij kan de Lahoux Spotter Elite 35V via de geïntegreerde WiFi-functie via een Android- of iOS app tot een afstand van ongeveer 50 meter live meekijken.", 
                        en: "The Lahoux Spotter Elite 35V is the new dimension of thermal imaging technology. The highlight of the Elite series is the large detector with a resolution of 640 × 480 pixels with a pixel pitch of 12μm. With this you can discover, determine and respond to game at great distances. With a 50 Hz frame rate, you will have an absolutely smooth image and the four viewing modes (white hot, black hot, red hot and pseudo colours ensure a perfect image reproduction. In addition, the Lahoux Spotter Elite 35V can use its integrated WiFi function, up to a distance 50 meters through an Android or iOS app, to transmit live images.", 
                        de: "Der Lahoux Spotter Elite 35V ist die neue Dimension der Wärmebildtechnologie. Das Highlight der Elite Serie ist der große Monitor mit einer Auflösung von 640 × 480 Pixel bei einer Pixelabstand von 12µm. Damit lässt sich auch auf große Distanz noch Wild entdecken, bestimmen und ansprechen. Die 50 Hz Bildfrequenz bietet ein absolut wackelfreies Bild und die vier Betrachtermodi (Wärme = schwarz / Wärme = weiß / Wärme = rot / Multifarben) sorgen für die perfekte Bildwiedergabe. Zusätzlich übermittelt der Lahoux Spotter Elite 35V via integrierten WiFi-Funktion bis auf eine Entfernung von ca. 50 Meter live auf ein Android- bzw. iOS-Gerät mit einer App."},
        pros: {     nl: ["Intuïtieve bediening met één hand", "Lichtgewicht en compact ontwerp", "Kan dag en nacht worden gebruikt", "Groot temperatuurbereik", "Gebruiksvriendelijke bediening", "Waterdicht (IP67)", "Hoge beeldsnelheid van 50 Hz", "Digitale vergroting", "Geïntegreerde lithium-ion batterij"], 
                    en: ["Intuitive one-hand operation", "Lightweight and compact design", "Can be used day or night", "Large operating temperature range", "User-friendly operation", "Waterproof (IP67)", "High frame rate of 50 Hz", "Digital magnification", "Integrated lithium-ion battery"], 
                    de: ["Intuitive Einhandbedienung", "Leichte und kompakte Bauform", "Bei Tag und Nacht einsetzbar", "Großer Betriebstemperaturbereich", "Benutzerfreundliche Bedienung", "Wasserdicht (IP67)", "Hohe Bildwechselfrequenz von 50 Hz", "Digitale Vergrößerung", "Integrierter Lithium-Ionen-Akku"]

        },
        
        number: "02-0006-02414",
        leveromvang: [ 1, 7, 4, 8, 3, 6],
        flyer: {nl:"Lahoux_Spotter_NL_V10.pdf", en: "Lahoux_Spotter_EN_V9.pdf", de: "Lahoux_Spotter_DE_V11.pdf"},
        images: ["wm12_1.png", "wm12_2.png", "wm12_3.png", "wm12_4.png"],
        photography: {
            nl: {"spotter-natuur.jpg": "De Lahoux Spotter Elite 35V is makkelijk mee te nemen"},
            en: {"spotter-natuur.jpg": "The Lahoux Spotter Elite 35V is easy to take with you"},
            de: {"spotter-natuur.jpg": "Der Lahoux Spotter Elite 35V ist leicht zu tragen"}
        },
        dri: "Warmtebeeld_Spotters.png",
        related: ["wm11", "wm13"],
        footnote_battery: true,
        endOfLife: true,        
        techs: { 
            Detector: { 
                en: {"Resolutie VOx uncooled FPA": "640 × 480", "Pixel pitch": "12 µm", "Spectral range": "8-14 µm", "Sensitivity (NETD)": "< 50 mK", "Frame rate": "50 Hz"},
                nl: {"Resolutie VOx ongekoeld FPA": "640 × 480", "Pixelafstand": "12 µm", "Spectraalbereik": "8-14 µm", "Gevoeligheid (NETD)": "< 50 mK", "Beeldfrequentie": "50 Hz"},
                de: {"Auflösung VOx ungekühlt FPA": "640 × 480", "Pixelabstand": "12 µm", "Spektrallbereich": "8-14 µm", "Empfindlichkeit (NETD)": "< 50 mK", "Bildfrequenz": "50 Hz"}
            },
            Optics: {
                en: {"Lens": "35 mm", "Magnification (digital)": "2×, 4×", "Eye relief": "Adjustment with rotary knob"},
                nl: {"Lens": "35 mm", "Vergroting (digitaal)": "2×, 4×", "Oogafstand": "Instelling met draaiknop"},
                de: {"Linse": "35 mm", "Vergrößerung (digital)": "2×, 4×", "Augenabstand": "Einstellung mit Drehknopf"}
            },
            Electronics: {
                en: {"Video output": "wifi", "USB": "For charging, can be charged by USB powerbank", "Image transmission": "Video / photo transmission (8GB internal) by WiFi in combination with special app", "Display FLCOS": "1280 × 960"},
                nl: {"Video-uitgang": "wifi", "USB": "Om op te laden, kan worden opgeladen met een USB powerbank", "Beeldoverdracht": "Video/foto-overdracht (8GB intern) via WiFi in combinatie met speciale app", "Beeldscherm FLCOS": "1280 × 960"},
                de: {"Video-Ausgang": "wifi", "USB": "Zum Aufladen, kann über USB-Powerbank aufgeladen werden", "Bildübertragung": "Video-/Fotoübertragung (8GB intern) per WiFi in Kombination mit einer speziellen App", "Anzeige FLCOS": "1280 × 960"}
            },
            Operating: {
                en: {"Temperature range": "−20°C to +50°C", "Power supply": "Internal lithium-ion battery", "Battery life": "3.5 hours (at 21°C, wifi switched on) * - 6.5 hours (at 21°C, wifi switched off) *", "Encapsulation": "IP67", "Dimensions": "188×65×64 mm", "Weight": "0,45 kg"},
                nl: {"Temperatuurbereik": "−20°C to +50°C", "Voeding": "Interne lithium-ion batterij", "Batterijduur": "3.5 uur (bij 21°C, wifi ingeschakeld) * - 6.5 uur (bij 21°C, wifi uitgeschakeld) *", "Waterdichtheid": "IP67", "Afmetingen": "188×65×64 mm", "Gewicht": "0,45 kg"},
                de: {"Temperaturbereich": "−20°C to +50°C", "Stromversorgung": "Interne Lithium-Ionen-Batterie", "Batterielaufzeit": "3.5 Stunden (bei 21°C, Wifi eingeschaltet) * - 6.5 Stunden (bei 21°C, Wifi ausgeschaltet) *", "Wasserdicht": "IP67", "Abmessungen": "188×65×64 mm", "Gewicht": "0,45 kg"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}

    },
	{
		id: "wm13",
        orderID: "129",
        type: "thermal",
        title: {nl: "Lahoux Spotter Elite 50V", en: "Lahoux Spotter Elite 50V", de: "Lahoux Spotter Elite 50V"},
        url: "Lahoux-Spotter-Elite-50V",
        price: "2337.93",
        shortDes: { nl:"Maximale prestaties, meest innovatieve technologie, maximaal comfort: zijn de belangrijkste eigenschappen van de Lahoux Spotter Elite 50V. Met zijn 50 mm lens, de 640 x 480 pixels detector en een pixelgrootte van 12μm mist u zelfs op de grootste afstand geen wild.", 
                    en: "Maximum performance, most innovative technology, maximum comfort are the most important features of the Lahoux Spotter Elite 50V. With its 50 mm lens, the 640 x 480 pixel detector and a pixel pitch of 12μm you will not miss any game even at the greatest distances.", 
                    de: "Maximale Leistung, innovativste Technik, größtmöglicher Komfort: Das ist der Lahoux Spotter Elite 50V. Mit seinem 50 mm Objektiv, dem 640 x 480 Pixel großem Detektor und bei einer Pixelgröße vom 12µm entgeht Ihnen auch auf größte Entfernung kein Wild."},
        longDes_1: {    nl:"Maximale prestaties, meest innovatieve technologie, maximaal comfort: zijn de belangrijkste eigenschappen van de Lahoux Spotter Elite 50V. Met zijn 50mm lens, de 640 × 480 pixels detector en een pixelgrootte van 12μm mist u zelfs op de grootste afstand geen wild. Via de geïntegreerde wifi-functie kunt u via een Android- of IOS app, tot een maximale afstand van 50 meter, live meekijken of de foto’s of video verzenden. De beeldsnelheid van 50Hz zorgt voor een absoluut trillingsvrij beeld en de vier beeldmodi (witheet, zwartheet, roodheet en pseudokleuren) zorgen voor een perfecte weergave.", 
                        en: "Maximum performance, most innovative technology, maximum comfort are the most important features of the Lahoux Spotter Elite 50V. With its 50 mm lens, the 640 x 480 pixel detector and a pixel pitch of 12μm you will not miss any game even at the greatest distances. Through the integrated WiFi function and an Android or IOS app, up to a distance of 50 meters, it is possible to view live images or transmit fotos or video. With a 50 Hz frame rate, you will have an absolutely smooth image and the four viewing modes (white hot, black hot, red hot and pseudo colours ensure a perfect image reproduction.", 
                         de: "Maximale Leistung, innovativste Technik, größtmöglicher Komfort: Das ist der Lahoux Spotter Elite 50V. Mit seinem 50 mm Objektiv, dem 640 × 480 Pixel großem Monitor und bei einer Pixelabstand vom 12µm entgeht Ihnen auch auf größte Entfernung kein Wild. Über die integrierte WiFi-Funktion können Sie via App bis auf eine Entfernung von maximal 50 Meter live  auf ein Android- bzw. iOS-Geräte das gesehene Wärmebild übertragen oder fotografieren bzw. filmen. Die 50 Hz Bildfrequenz bietet ein absolut wackelfreies Bild und die vier Betrachtermodi (Wärme = schwarz / Wärme = weiß / Wärme = rot / Multifarben) sorgen für die perfekte Bildwiedergabe."},
        pros: {     nl: ["Intuïtieve bediening met één hand", "Lichtgewicht en compact ontwerp", "Kan dag en nacht worden gebruikt", "Groot temperatuurbereik", "Gebruiksvriendelijke bediening", "Waterdicht (IP67)", "Hoge beeldsnelheid van 50Hz", "Digitale vergroting", "Geïntegreerde lithium-ion batterij"], 
                    en: ["Intuitive one-hand operation", "Lightweight and compact design", "Can be used day or night", "Large operating temperature range", "User-friendly operation", "Waterproof (IP67)", "High frame rate of 50 Hz", "Digital magnification", "Integrated lithium-ion battery"], 
                    de: ["Intuitive Einhandbedienung", "Leichte und kompakte Bauform", "Bei Tag und Nacht einsetzbar", "Großer Betriebstemperaturbereich", "Benutzerfreundliche Bedienung", "Wasserdicht (IP67)", "Hohe Bildwechselfrequenz von 50 Hz", "Digitale Vergrößerung", "Integrierter Lithium-Ionen-Akku"]

        },
        number: "02-0006-02413",
        leveromvang: [ 1, 7, 4, 8, 3, 6],
        flyer: {nl:"Lahoux_Spotter_NL_V10.pdf", en: "Lahoux_Spotter_EN_V9.pdf", de: "Lahoux_Spotter_DE_V11.pdf"},
        images: ["wm13_1.png", "wm13_2.png", "wm13_3.png", "wm13_4.png"],
        dri: "Warmtebeeld_Spotters.png",
        related: [ "wm12"],
        footnote_battery: true,
        endOfLife: true,        
        techs: { 
            Detector: { 
                en: {"Resolutie VOx uncooled FPA": "640 × 480", "Pixel pitch": "12 µm", "Spectral range": "8-14 µm", "Sensitivity (NETD)": "< 50 mK", "Frame rate": "50 Hz"},
                nl: {"Resolutie VOx ongekoeld FPA": "640 × 480", "Pixelafstand": "12 µm", "Spectraalbereik": "8-14 µm", "Gevoeligheid (NETD)": "< 50 mK", "Beeldfrequentie": "50 Hz"},
                de: {"Auflösung VOx ungekühlt FPA": "640 × 480", "Pixelabstand": "12 µm", "Spektrallbereich": "8-14 µm", "Empfindlichkeit (NETD)": "< 50 mK", "Bildfrequenz": "50 Hz"}
            },
            Optics: {
                en: {"Lens": "50 mm", "Magnification (digital)": "2×, 4×", "Eye relief": "Adjustment with rotary knob"},
                nl: {"Lens": "50 mm", "Vergroting (digitaal)": "2×, 4×", "Oogafstand": "Instelling met draaiknop"},
                de: {"Linse": "50 mm", "Vergrößerung (digital)": "2×, 4×", "Augenabstand": "Einstellung mit Drehknopf"}
            },
            Electronics: {
                en: {"Video output": "wifi", "USB": "For charging, can be charged by USB powerbank", "Image transmission": "Video / photo transmission (8GB internal) by WiFi in combination with special app", "Display FLCOS": "1280 × 960"},
                nl: {"Video-uitgang": "wifi", "USB": "Om op te laden, kan worden opgeladen met een USB powerbank", "Beeldoverdracht": "Video/foto-overdracht (8GB intern) via WiFi in combinatie met speciale app", "Beeldscherm FLCOS": "1280 × 960"},
                de: {"Video-Ausgang": "wifi", "USB": "Zum Aufladen, kann über USB-Powerbank aufgeladen werden", "Bildübertragung": "Video-/Fotoübertragung (8GB intern) per WiFi in Kombination mit einer speziellen App", "Anzeige FLCOS": "1280 × 960"}
            },
            Operating: {
                en: {"Temperature range": "−20°C to +50°C", "Power supply": "Internal lithium-ion battery", "Battery life": "3.5 hours (at 21°C, wifi switched on) * - 6.5 hours (at 21°C, wifi switched off) *", "Encapsulation": "IP67", "Dimensions": "202×65×64 mm", "Weight": "0,52 kg"},
                nl: {"Temperatuurbereik": "−20°C to +50°C", "Voeding": "Interne lithium-ion batterij", "Batterijduur": "3.5 uur (bij 21°C, wifi ingeschakeld) * - 6.5 uur (bei 21°C, wifi uitgeschakeld) *", "Waterdichtheid": "IP67", "Afmetingen": "202×65×64 mm", "Gewicht": "0,52 kg"},
                de: {"Temperaturbereich": "−20°C to +50°C", "Stromversorgung": "Interne Lithium-Ionen-Batterie", "Batterielaufzeit": "3.5 Stunden (bei 21°C, Wifi eingeschaltet) * - 6.5 Stunden (bei 21°C, Wifi ausgeschaltet) *", "Wasserdicht": "IP67", "Abmessungen": "202×65×64 mm", "Gewicht": "0,52 kg"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
	},
    {
        id: "wm15",
        orderID: "124",
        type: "thermal",
        title: {nl: "Lahoux Spotter Pro V2", en: "Lahoux Spotter Pro V2", de: "Lahoux Spotter Pro V2"},
        url: "Lahoux-Spotter-Pro-V2",
        price: "952.38",
        endOfLife: false,
        shortDes: { nl:"Wildobservatie van de volgende generatie. De Lahoux Spotter Pro V2 is de ideale metgezel voor bos- en veldjacht, zelfs in totale duisternis.", 
                    en: "Wildlife watching of the next generation. The Lahoux Spotter Pro V2 is the ideal companion for forest and field hunting even in total darkness.", 
                    de: "Die nächste Generation der Wildbeobachtung. Der Lahoux Spotter Pro V2 ist der ideale Begleiter für die Wald- und Feldjagd, auch bei völliger Dunkelheit."},
        longDes_1:{ nl:"Wildobservatie van de volgende generatie. De Lahoux Spotter Pro V2 is de ideale metgezel voor bos- en veldjacht, zelfs in totale duisternis. De beeldsnelheid van 50 Hz zorgt voor een trillingsvrij beeld en met de vijf beeldmodi (White Hot, Black Hot, Red Hot, Iron Hot und Blue Hot) kan het beeld naar wens worden geselecteerd. Dankzij de 19 mm-optiek en de pixelafstand van 12μm en een resolutie van 384 x 288 pixels kunt u wild ontdekken, zelfs over langere afstand.", 
                    en: "Wildlife watching of the next generation. The Lahoux Spotter Pro V2 is the ideal companion for forest and field hunting even in total darkness. The 50 Hz frame rate ensures a jitter-free image and with its five viewing modes (White Hot, Black Hot, Red Hot, Iron Hot und Blue Hot), the image can be selected as needed. The 19mm optics and 12μm pixel pitch and a resolution of 384 x 288 pixel allows you to find game even at longer distances.", 
                    de: "Die nächste Generation der Wildbeobachtung. Der Lahoux Spotter Pro V2 ist der ideale Begleiter für die Wald- und Feldjagd, auch bei völliger Dunkelheit. Die Bildfrequenz von 50 Hz sorgt für ein flimmerfreies Bild und mit den fuenf Bildmodi (White Hot, Black Hot, Red Hot, Iron Hot und Blue Hot) kann das Bild nach Belieben gewählt werden. Mit der 19-mm-Optik und 12μm Pixelabstand und einer Auflösung von 384 x 288 Pixel können Sie Wildtiere auch über größere Entfernungen entdecken."},
        pros:     { nl: ["Intuïtieve bediening met één hand", "Lichtgewicht en compact ontwerp", "Kan dag en nacht worden gebruikt", "Groot temperatuurbereik", "Gebruiksvriendelijke bediening", "Waterdicht (IP66)", "Hoge beeldsnelheid van 50 Hz", "Digitale vergroting", "Externe lithium-ion accu", "WiFi"], 
                    en: ["Intuitive one-hand operation", "Lightweight and compact design", "Can be used day or night", "Large operating temperature range", "User-friendly operation", "Waterproof (IP66)" , "High frame rate of 50 Hz", "Digital magnification", "External lithium-ion battery", "WiFi"], 
                    de: ["Intuitive Einhandbedienung", "Leichtes und kompaktes Design", "Tag und Nacht einsetzbar", "Großer Temperaturbereich", "Einfache Bedienung", "Wasserdicht (IP66)", "Hohe Bildrate von 50 Hz", "Digitale Vergrößerung", "Externer Lithium-Ionen-Akku", "WiFi"]},                 
        number: "02-0006-03751",
//        ean: "6090301578555",
//        leveromvang: [ 1, 7, 4, 8, 3, 5],
 //       flyer: {nl:"Lahoux_Spotter_NL_V10.pdf", en: "Lahoux_Spotter_EN_V9.pdf", de: "Lahoux_Spotter_DE_V11.pdf"},
 //       images: ["wm8_1.png"],
        images: ["Lahoux-Spotter-Pro-V2-1.png", "Lahoux-Spotter-Pro-V2-2.png", "Lahoux-Spotter-Pro-V2-3.png"],        
 //       dri: "Warmtebeeld_Spotters.png",
        related: ["wm6", "wm29"],
        accessories: ["as13"],
        footnote_battery: true,
        techs: {
            Detector: {
                en: {"Resolutie VOx uncooled FPA": "384 x 288", "Pixel pitch": "12 µm", "Spectral range": "8-14 µm", "Sensitivity (NETD)": "≤ 40 mK", "Frame rate": "50 Hz"},
                nl: {"Resolutie VOx ongekoeld FPA": "384 x 288", "Pixelafstand": "12 µm", "Spectraalbereik": "8-14 µm", "Gevoeligheid (NETD)": "≤ 40 mK", "Beeldfrequentie": "50 Hz"},
                de: {"Auflösung VOx ungekühlt FPA": "384 x 288", "Pixelabstand": "12 µm", "Spektrallbereich": "8-14 µm", "Empfindlichkeit (NETD)": "≤ 40 mK", "Bildfrequenz": "50 Hz"}
            },
            Optics: {
                en: {"Lens": "19 mm", "Optical Magnification": "1.4x", "Digital Magnification": "1x, 2x, 4x", "Diopter adjustment": "-4 to＋2"},
                nl: {"Lens": "19 mm", "Optische Vergroting": "1.4x", "Digitale Vergroting": "1x, 2x, 4x", "Dioptriecorrectie": "-4 to＋2"},
                de: {"Linse": "19 mm", "Optische Vergrößerung": "1.4x","Digitale Vergrößerung": "1x, 2x, 4x", "Dioptrieneinstellung": "-4 to＋2"}
            },
            Interface: {
                en: {"Video": "USB-C", "Mechanical": "UNC ¼’’-20 (Tripod mounting)" ,"Display 0.23’’ OLED": "640 × 400", "Wifi": "Transmission of real-time video via WIFI for remote control in the 2.4G frequency band and within 15 meters"},
                nl: {"Video": "USB-C", "Mechanical": "UNC ¼’’-20 (Tripod mounting)",  "Beeldscherm 0.23’’ OLED": "640 × 400", "Wifi": "Overdracht van real-time video via WIFI binnen 15 meter"},
                de: {"Video": "USB-C", "Mechanical": "UNC ¼’’-20 (Tripod mounting)", "Anzeige 0.23’’ OLED": "640 × 400", "Wifi": "Übertragung von Echtzeitvideos über WLAN im Umkreis von 15 Metern"}
            },
            Operating: {
                en: {"Temperature range": "-30 °C to 60 °C", "Power supply": "Single, replaceable 18650 battery", "Battery life" : "≥ 10 hours (at 21°C) *", "Encapsulation": "IP66", "Dimensions": "150×45×74 mm", "Weight": "0,330 kg"},
                nl: {"Temperatuurbereik": "-30 °C to 60 °C", "Voeding": "Enkele, vervangbare 18650-batterij", "Batterijduur" : "≥ 10 uur (bij 21°C) *", "Waterdichtheid": "IP66", "Afmetingen": "150×45×74 mm", "Gewicht": "0,33 kg"},
                de: {"Temperaturbereich": "-30 °C to 60 °C", "Stromversorgung": "Einzelne, austauschbare 18650-Batterie", "Batterielaufzeit" : "≥ 10 Stunden (bei 21°C) *", "Wasserdicht": "IP66", "Abmessungen": "150×45×74 mm", "Gewicht": "0,33 kg"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
    },    
/*
    {
		id: "wm15",
        orderID: "103",
        type: "thermal",
        title: {nl: "Lahoux Buzzard 320 (set)", en: "Lahoux Buzzard 320 (set)", de: "Lahoux Buzzard 320 (set)"},
        url: "Lahoux-Buzzard-set",
        price: "2432.00",
        new: false,
        shortDes: { nl: "De Lahoux Buzzard is een clip-on met thermisch beeld, ontworpen voor een C1 klasse drone.", 
                    en: "The Lahoux Buzzard is a thermal imaging clip-on designed for a C1 class drone.", 
                    de: "Der Lahoux Buzzard ist ein Wärmebild-Aufsatz für eine Drohne der Klasse C1. "},
        longDes_1: {    nl:"De Lahoux Buzzard is een clip-on met thermisch beeld, ontworpen voor een C1 klasse drone. De toepassingen zijn zeer uiteenlopend, bijvoorbeeld: beveiliging & bewaking, jacht en anti-stroperij, agrarisch gebruik, natuurbehoud, zoeken van reekalveren en weidevogels voor het maaien, zoeken van wilde zwijnen in de mais, industriële inspectie, etc. De videozender heeft een operationeel bereik* geeft van 500 meter. (*Het bereik is sterk afhankelijk van lokale atmosferische condities en obstructies). De thermische module heeft een resolutie van 320×256 pixels. Het zoekgebied is 19.4 x 15 meter op 30 meter hoogte. DRI (Detectie/Herkenning/Identificatie): Voertuig (4,00 × 1,75 m) – 956/239/120 meter, Mens (1,75 × 0,75 m) – 414/104/52 meter, Reekalf (0,5 × 0,5 m) – 180/45/23 meter.", 
                        en: "The Lahoux Buzzard is a thermal imaging clip-on designed for a C1 class drone. Applications are wide ranging, for instance security & surveillance, anti-poaching, agricultural use, nature conservation, rescuing fawns and birds before mowing, industrial inspection amongst others. The video transmitter gives an operational range* of up to 500 meters. (*The operational range is highly dependent on local atmospheric conditions and obstructions). The thermal imaging module has a resolution of 320×256 pixels. The search area is 19.4 x 15 meters at 30 meters altitude. DRI (detection/recognition/identification): Vehicle (4.00 × 1.75 m) – 956/239/120 meters, Human (1.75 × 0.75 m) – 414/104/52 meters, Fawn (0.5 × 0.5 m) – 180/45/23 meters", 
                         de: "Der Lahoux Buzzard ist ein Wärmebild-Aufsatz für eine Drohne der Klasse C1. Die Anwendungen sind vielfältig, z. B. Sicherheit und Überwachung; Jagd und Naturschutz, Suche von Wildschweinen im Mais, bestimmen von Schäden in der Landwirtschaft, Rettung von Rehkitzen und Vögeln vor dem Mähen, industrielle Inspektionen und vieles mehr. Der Videosender hat eine Reichweite* von ungefähr 500 Metern. (*Die Reichweite hängt stark von den örtlichen atmosphärischen Bedingungen und Hindernissen ab). Die Wärmebildaufnahme erfolgt mit einer Auflösung von 320 × 256 px. Der Suchbereich beträgt für Rehkitz 19.4 x 15 Meter in 30 Metern Höhe. DRI (Detektion/Erkennung/Identifizierung): Fahrzeug (4,00 × 1,75 m) – 956/239/120 Meter, Mensch (1,75 × 0,75 m) – 414/104/52 Meter, Rehkitz (0,5 × 0,5 m) – 180/45/23 Meter"},
        pros: {     nl:[ "Een premium lichtgewicht koolstofvezel behuizing", "Gemakkelijk te bevestigen aan en te verwijderen van verschillende drones", "Video transmissie via markt leidende hardware", "On-board videorecorder", "Het thermisch beeld wordt doorgestuurd naar een LCD monitor op een statief", "Inclusief drone, monitor en statief"], 
                    en: ["Lightweight composite carbon fiber construction", "Easy to attach to and remove from various drones", "Video transmission via market leading hardware", "On-board video recorder", "Thermal image is transmitted to an LCD monitor on a tripod", "Includes drone, monitor and tripod"], 
                    de: ["Leichte Kohlefaser-Verbundkonstruktion", "Einfaches Anbringen und Abnehmen an verschiedenen Drohnen", "Videoübertragung über marktführende Hardware", "An Bord Videorekorder", "Das Wärmebild wird auf einen LCD-Monitor auf einem Stativ übertragen", "Inklusive Drohne, Monitor und Stativ"]

        },
        number: "02-0002-03078",
        ean: "6090301720794",
        leveromvang: [25, 26, 27, 29, 31],
        flyer: {nl:"Lahoux_Buzzard_NL.pdf", en: "Lahoux_Buzzard_EN.pdf", de: "Lahoux_Buzzard_DE.pdf"},
        images: ["wm15_1.png", "wm15_2.png", "wm15_3.png", "wm15_5.png"],
        dri: "Warmtebeeld_Buzzard.png",
        accessories: ["as17", "as18"],
        videos: {
            nl: {"wm15.mp4": "Lahoux Buzzard met drone"},
            en: {"wm15.mp4": "Lahoux Buzzard with drone"},
            de: {"wm15.mp4": "Lahoux Buzzard mit Drohne"}
        },
        footnote_battery: true,
        techs: { 
            Detector: { 
                en: {"Type/Resolution": "VOx/ 320 x 256 pixels", "Pixel pitch": "12 µm", "Frame rate": "60 Hz"},
                nl: {"Type/Resolutie": "VOx/ 320 x 256 pixels", "Pixelafstand": "12 µm", "Beeldfrequentie": "60 Hz"},
                de: {"Typ/Auflösung": "VOx/ 320 x 256 pixels", "Pixelabstand": "12 µm", "Bildfrequenz": "60 Hz"}
            },
            Optics: {
                en: {"Field of view": "23.4° × 19.2°", "Field of view per pixel": "0.1 × 0.1°", "Observable area at 100 meter": "64.7 x 50.1", "Area per pixel at 100 meters": "0.2 × 0.2 m"},
                nl: {"Gezichtsveld": "23.4° × 19.2°", "Gezichtsveld per pixel": "0.1 × 0.1°", "Waarneembaar gebied op 100 meter": "64.7 x 50.1", "Gebied per pixel op 100 meter": "0.2 × 0.2 m"},
                de: {"Sichtfeld": "23.4° × 19.2°", "Sichtfeld pro Pixel": "0.1 × 0.1°", "Beobachtbares Gebiet in 100 Metern": "64.7 x 50.1", "Gebiet pro Pixel bei 100 Metern": "0.2 × 0.2 m"}
            },
            Electronics: {
                en: {"Video transmitter frequency": "5.8 GHz", "Video transmitter power": "25 mW", "Internal recording": "Yes", "Recording resolution": "640 x 480"},
                nl: {"Videozendfrequentie": "5.8 GHz", "Videozendervermogen": "25 mW", "Intern opnemen": "Yes", "Opnameresolutie": "640 x 480"},
                de: {"Video-Senderfrequenz": "5.8 GHz", "Video-Senderleistung": "25 mW", "Interne Aufnahme": "Yes", "Auflösung der Aufnahme": "640 x 480"}
            },
            Operating: {
                en: {"Power supply": "9V rechargeable LiPO", "Battery lifetime": "> 1 hour *", "Encapsulation": "IP20", "Dimensions": "L 138 × W 59 × H 58 mm", "Weight": "0,115 kg"},
                nl: {"Voeding": "9V oplaadbare LiPO", "Batterijduur": "> 1 uur *", "Waterdichtheid": "IP20", "Afmetingen": "L 138 × W 59 × H 58 mm", "Gewicht": "0,115 kg"},
                de: {"Stromversorgung": "9V wiederaufladbare LiPO", "Batterielaufzeit": "> 1 Stunde *", "Wasserdicht": "IP20", "Abmessungen": "L 138 × W 59 × H 58 mm", "Gewicht": "0,115 kg"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
	},
*/    
    {
		id: "wm32",
        orderID: "108",
        type: "thermal",
        title: {nl: "Lahoux Buzzard 320", en: "Lahoux Buzzard 320", de: "Lahoux Buzzard 320"},
        url: "Lahoux-Buzzard",
        price: "1500",
        new: false,
        shortDes: { nl: "De Lahoux Buzzard is een clip-on met thermisch beeld, ontworpen voor een C1 klasse drone.", 
                    en: "The Lahoux Buzzard is a thermal imaging clip-on designed for a C1 class drone.", 
                    de: "Der Lahoux Buzzard ist ein Wärmebild-Aufsatz für eine Drohne der Klasse C1. "},
        longDes_1: {    nl:"De Lahoux Buzzard is een clip-on met thermisch beeld, ontworpen voor een C1 klasse drone. De toepassingen zijn zeer uiteenlopend, bijvoorbeeld: beveiliging & bewaking, jacht en anti-stroperij, agrarisch gebruik, natuurbehoud, zoeken van reekalveren en weidevogels voor het maaien, zoeken van wilde zwijnen in de mais, industriële inspectie, etc. De videozender heeft een operationeel bereik geeft van 500 meter. De thermische module heeft een resolutie van 320×256 pixels. Het zoekgebied is 19.4 x 15 meter op 30 meter hoogte. DRI (Detectie/Herkenning/Identificatie): Voertuig (4,00 × 1,75 m) – 956/239/120 meter, Mens (1,75 × 0,75 m) – 414/104/52 meter, Reekalf (0,5 × 0,5 m) – 180/45/23 meter", 
                        en: "The Lahoux Buzzard is a thermal imaging clip-on designed for a C1 class drone. Applications are wide ranging, for instance security & surveillance, anti-poaching, agricultural use, nature conservation, rescuing fawns and birds before mowing, industrial inspection amongst others. The video transmitter gives an operational range of up to 500 meters. The thermal imaging module has a resolution of 320×256 pixels. The search area is 19.4 x 15 meters at 30 meters altitude. DRI (detection/recognition/identification): Vehicle (4.00 × 1.75 m) – 956/239/120 meters, Human (1.75 × 0.75 m) – 414/104/52 meters, Fawn (0.5 × 0.5 m) – 180/45/23 meters", 
                         de: "Der Lahoux Buzzard ist ein Wärmebild-Aufsatz für eine Drohne der Klasse C1. Die Anwendungen sind vielfältig, z. B. Sicherheit und Überwachung; Jagd und Naturschutz, Suche von Wildschweinen im Mais, bestimmen von Schäden in der Landwirtschaft, Rettung von Rehkitzen und Vögeln vor dem Mähen, industrielle Inspektionen und vieles mehr. Der Videosender hat eine Reichweite von ungefähr 500 Metern. Die Wärmebildaufnahme erfolgt mit einer Auflösung von 320 × 256 px. Der Suchbereich beträgt für Rehkitz 19.4 x 15 Meter in 30 Metern Höhe. DRI (Detektion/Erkennung/Identifizierung): Fahrzeug (4,00 × 1,75 m) – 956/239/120 Meter, Mensch (1,75 × 0,75 m) – 414/104/52 Meter, Rehkitz (0,5 × 0,5 m) – 180/45/23 Meter"},
        pros: {     nl:[ "Een premium lichtgewicht koolstofvezel behuizing", "Gemakkelijk te bevestigen aan en te verwijderen van verschillende drones", "Video transmissie via markt leidende hardware", "On-board videorecorder", "Het thermisch beeld wordt doorgestuurd naar een LCD monitor op een statief", "Inclusief monitor en statief"], 
                    en: ["Lightweight composite carbon fiber construction", "Easy to attach to and remove from various drones", "Video transmission via market leading hardware", "On-board video recorder", "Thermal image is transmitted to an LCD monitor on a tripod", "Includes monitor and tripod"], 
                    de: ["Leichte Kohlefaser-Verbundkonstruktion", "Einfaches Anbringen und Abnehmen an verschiedenen Drohnen", "Videoübertragung über marktführende Hardware", "An Bord Videorekorder", "Das Wärmebild wird auf einen LCD-Monitor auf einem Stativ übertragen", "Inklusive Monitor und Stativ"]

        },
        important_info: {
            nl: "Belangrijke informatie over de drone",
            en: "Important information about the drone",
            de: "Wichtige Informationen zur Drohne",
        },
        important_info_Des_1: {
            nl: "Wanneer u de Lahoux Buzzard als los apparaat koopt, zonder Hubsan Zino drone, dan dient de drone die u gebruikt aan de volgende eigenschappen te voldoen:",
            en: "When you purchase the Lahoux Buzzard as a separate device, without a Hubsan Zino drone, the drone you are using must meet the following requirements:",
            de: "Wenn Sie den Lahoux Buzzard als separates Gerät kaufen, ohne eine Hubsan Zino Drohne, muss die von Ihnen verwendete Drohne die folgenden Anforderungen erfüllen:",
        },
        important_info_list: {
            nl: ["Aan de onderkant dient er een plat ononderbroken vlak te zijn met een minimaal formaat van: 140 mm lengte en 59 mm breedte.", "De drone moet een extra gewicht van 0,120 kg kunnen dragen.", "De drone moet stabiel genoeg zijn om met lichte wind het extra oppervlak van de Lahoux Buzzard aan te kunnen."],
            en: ["On the bottom there should be a flat uninterrupted surface with a minimum size of: 140 mm length and 59 mm width.", "The drone must be able to carry an additional weight of 0.120 kg.", "The drone must be stable enough to handle the extra surface of the Lahoux Buzzard in light winds."],
            de: ["Auf der Unterseite sollte sich eine ebene, ununterbrochene Fläche befinden mit einer Mindestgröße von: 140 mm Länge und 59 mm Breite.", "Die Drohne muss in der Lage sein, ein zusätzliches Gewicht von 0,120 kg zu tragen.", "Die Drohne muss stabil genug sein, um die zusätzliche Fläche des Lahoux Buzzard bei leichtem Wind zu tragen."],
        },
        number: "02-0002-03379",
        ean: "6090301513549",
        leveromvang: [25, 26],
        flyer: {nl:"Lahoux_Buzzard_NL.pdf", en: "Lahoux_Buzzard_EN.pdf", de: "Lahoux_Buzzard_DE.pdf"},
        images: ["Lahoux_Buzzard_1.png", "Lahoux_Buzzard_2.png", "Lahoux_Buzzard_3.png", "Lahoux_Buzzard_4.png", "Lahoux_Buzzard_5.png"],
        dri: "Warmtebeeld_Buzzard.png",
        accessories: ["as17", "as18"],
        videos: {
            nl: {"DJI-with-Lahoux Buzzard-manual-landing.mp4": "Handmatige landing van een DJI met Lahoux Buzzard"},
            en: {"DJI-with-Lahoux Buzzard-manual-landing.mp4": "Manual landing of a DJI with Lahoux Buzzard"},
            de: {"DJI-with-Lahoux Buzzard-manual-landing.mp4": "Manuelle Landung eines DJI mit Lahoux Buzzard"}
        },
        photography: {
            nl: {"saved-by-buzzard.jpg": "Opgespoord en gered dankzij de Lahoux Buzzard"},
            en: {"saved-by-buzzard.jpg": "Tracked down and rescued thanks to the Lahoux Buzzard"},
            de: {"saved-by-buzzard.jpg": "Dank des Lahoux-Buzzards aufgespürt und gerettet"}
        },
        footnote_battery: true,
        techs: { 
            Detector: { 
                en: {"Type/Resolution": "VOx/ 320 x 256 pixels", "Pixel pitch": "12 µm", "Frame rate": "60 Hz"},
                nl: {"Type/Resolutie": "VOx/ 320 x 256 pixels", "Pixelafstand": "12 µm", "Beeldfrequentie": "60 Hz"},
                de: {"Typ/Auflösung": "VOx/ 320 x 256 pixels", "Pixelabstand": "12 µm", "Bildfrequenz": "60 Hz"}
            },
            Optics: {
                en: {"Field of view": "23.4° × 19.2°", "Field of view per pixel": "0.1 × 0.1°", "Observable area at 100 meter": "64.7 x 50.1", "Area per pixel at 100 meters": "0.2 × 0.2 m"},
                nl: {"Gezichtsveld": "23.4° × 19.2°", "Gezichtsveld per pixel": "0.1 × 0.1°", "Waarneembaar gebied op 100 meter": "64.7 x 50.1", "Gebied per pixel op 100 meter": "0.2 × 0.2 m"},
                de: {"Sichtfeld": "23.4° × 19.2°", "Sichtfeld pro Pixel": "0.1 × 0.1°", "Beobachtbares Gebiet in 100 Metern": "64.7 x 50.1", "Gebiet pro Pixel bei 100 Metern": "0.2 × 0.2 m"}
            },
            Electronics: {
                en: {"Video transmitter frequency": "5.8 GHz", "Video transmitter power": "25 mW", "Internal recording": "Yes", "Recording resolution": "640 x 480"},
                nl: {"Videozendfrequentie": "5.8 GHz", "Videozendervermogen": "25 mW", "Intern opnemen": "Yes", "Opnameresolutie": "640 x 480"},
                de: {"Video-Senderfrequenz": "5.8 GHz", "Video-Senderleistung": "25 mW", "Interne Aufnahme": "Yes", "Auflösung der Aufnahme": "640 x 480"}
            },
            Operating: {
                en: {"Power supply": "9V rechargeable LiPO", "Battery lifetime": "> 1 hour *", "Encapsulation": "IP20", "Dimensions": "L 138 × W 59 × H 58 mm", "Weight": "0,115 kg"},
                nl: {"Voeding": "9V oplaadbare LiPO", "Batterijduur": "> 1 uur *", "Waterdichtheid": "IP20", "Afmetingen": "L 138 × W 59 × H 58 mm", "Gewicht": "0,115 kg"},
                de: {"Stromversorgung": "9V wiederaufladbare LiPO", "Batterielaufzeit": "> 1 Stunde *", "Wasserdicht": "IP20", "Abmessungen": "L 138 × W 59 × H 58 mm", "Gewicht": "0,115 kg"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
	},

    {
		id: "wm39",
        orderID: "109",
        type: "thermal",
        title: {nl: "Lahoux Buzzard 640", en: "Lahoux Buzzard 640", de: "Lahoux Buzzard 640"},
        url: "Lahoux-Buzzard-640",
        price: "2000.00",
        new: false,
        //available_date: {nl: "Binnenkort verkrijgbaar", en: "Available soon", de: "Bald verfügbar"},
        shortDes: { nl: "De Lahoux Buzzard 640 is een clip-on met thermisch beeld, ontworpen voor een C1 klasse drone.", 
                    en: "The Lahoux Buzzard 640 is a thermal imaging clip-on designed for a C1 class drone.", 
                    de: "Der Lahoux Buzzard 640 ist ein Wärmebild-Aufsatz für eine Drohne der Klasse C1. "},
        longDes_1: {    nl:"De Lahoux Buzzard 640 is een clip-on met thermisch beeld, ontworpen voor een C1 klasse drone. De toepassingen zijn zeer uiteenlopend, bijvoorbeeld: beveiliging & bewaking, jacht en anti-stroperij, agrarisch gebruik, natuurbehoud, zoeken van reekalveren en weidevogels voor het maaien, zoeken van wilde zwijnen in de mais, industriële inspectie, etc. De videozender heeft een operationeel bereik geeft van 500 meter. De thermische module heeft een resolutie van 640x512 pixels. Het zoekgebied is 31.3 x 23.2 meter op 30 meter hoogte. DRI (Detectie/Herkenning/Identificatie): Voertuig (4,00 × 1,75 m) – 1324/331/165 meter, Mens (1,75 × 0,75 m) – 468/117/59 meter, Reekalf (0,5 × 0,5 m) – 250/63/31 meter", 
                        en: "The Lahoux Buzzard 640 is a thermal imaging clip-on designed for a C1 class drone. Applications are wide ranging, for instance security & surveillance, anti-poaching, agricultural use, nature conservation, rescuing fawns and birds before mowing, industrial inspection amongst others. The video transmitter gives an operational range of up to 500 meters. The thermal imaging module has a resolution of 640x512 pixels. The search area is 31.3 x 23.2 meters at 30 meters altitude. DRI (detection/recognition/identification): Vehicle (4.00 × 1.75 m) – 1324/331/165 meters, Human (1.75 × 0.75 m) – 468/117/59 meters, Fawn (0.5 × 0.5 m) – 250/63/31 meters", 
                         de: "Der Lahoux Buzzard 640 ist ein Wärmebild-Aufsatz für eine Drohne der Klasse C1. Die Anwendungen sind vielfältig, z. B. Sicherheit und Überwachung; Jagd und Naturschutz, Suche von Wildschweinen im Mais, bestimmen von Schäden in der Landwirtschaft, Rettung von Rehkitzen und Vögeln vor dem Mähen, industrielle Inspektionen und vieles mehr. Der Videosender hat eine Reichweite von ungefähr 500 Metern. Die Wärmebildaufnahme erfolgt mit einer Auflösung von 640x512 Pixel. Der Suchbereich beträgt für Rehkitz 31.3 x 23.2 Meter in 30 Metern Höhe. DRI (Detektion/Erkennung/Identifizierung): Fahrzeug (4,00 × 1,75 m) – 1324/331/165 Meter, Mensch (1,75 × 0,75 m) – 468/117/59 Meter, Rehkitz (0,5 × 0,5 m) – 250/63/31 Meter"},
        pros: {     nl:[ "Een premium lichtgewicht koolstofvezel behuizing", "Gemakkelijk te bevestigen aan en te verwijderen van verschillende drones", "Video transmissie via markt leidende hardware", "On-board videorecorder", "Het thermisch beeld wordt doorgestuurd naar een LCD monitor op een statief", "Inclusief monitor en statief"], 
                    en: ["Lightweight composite carbon fiber construction", "Easy to attach to and remove from various drones", "Video transmission via market leading hardware", "On-board video recorder", "Thermal image is transmitted to an LCD monitor on a tripod", "Includes monitor and tripod"], 
                    de: ["Leichte Kohlefaser-Verbundkonstruktion", "Einfaches Anbringen und Abnehmen an verschiedenen Drohnen", "Videoübertragung über marktführende Hardware", "An Bord Videorekorder", "Das Wärmebild wird auf einen LCD-Monitor auf einem Stativ übertragen", "Inklusive Monitor und Stativ"]

        },
        important_info: {
            nl: "Belangrijke informatie over de drone",
            en: "Important information about the drone",
            de: "Wichtige Informationen zur Drohne",
        },
        important_info_Des_1: {
            nl: "Wanneer u de Lahoux Buzzard als los apparaat koopt, zonder Hubsan Zino drone, dan dient de drone die u gebruikt aan de volgende eigenschappen te voldoen:",
            en: "When you purchase the Lahoux Buzzard as a separate device, without a Hubsan Zino drone, the drone you are using must meet the following requirements:",
            de: "Wenn Sie den Lahoux Buzzard als separates Gerät kaufen, ohne eine Hubsan Zino Drohne, muss die von Ihnen verwendete Drohne die folgenden Anforderungen erfüllen:",
        },
        important_info_list: {
            nl: ["Aan de onderkant dient er een plat ononderbroken vlak te zijn met een minimaal formaat van: 140 mm lengte en 59 mm breedte.", "De drone moet een extra gewicht van 0,140 kg kunnen dragen.", "De drone moet stabiel genoeg zijn om met lichte wind het extra oppervlak van de Lahoux Buzzard aan te kunnen."],
            en: ["On the bottom there should be a flat uninterrupted surface with a minimum size of: 140 mm length and 59 mm width.", "The drone must be able to carry an additional weight of 0.140 kg.", "The drone must be stable enough to handle the extra surface of the Lahoux Buzzard in light winds."],
            de: ["Auf der Unterseite sollte sich eine ebene, ununterbrochene Fläche befinden mit einer Mindestgröße von: 140 mm Länge und 59 mm Breite.", "Die Drohne muss in der Lage sein, ein zusätzliches Gewicht von 0,140 kg zu tragen.", "Die Drohne muss stabil genug sein, um die zusätzliche Fläche des Lahoux Buzzard bei leichtem Wind zu tragen."],
        },
        number: "02-0002-03703",
        ean: "6090301891807",
        leveromvang: [25, 26],
        // flyer: {nl:"Lahoux_Buzzard_NL.pdf", en: "Lahoux_Buzzard_EN.pdf", de: "Lahoux_Buzzard_DE.pdf"},
        images: ["Lahoux_Buzzard_1.png"],
        // dri: "Warmtebeeld_Buzzard.png",
        accessories: ["as17", "as18"],
        videos: {
            nl: {"DJI-with-Lahoux Buzzard-manual-landing.mp4": "Handmatige landing van een DJI met Lahoux Buzzard"},
            en: {"DJI-with-Lahoux Buzzard-manual-landing.mp4": "Manual landing of a DJI with Lahoux Buzzard"},
            de: {"DJI-with-Lahoux Buzzard-manual-landing.mp4": "Manuelle Landung eines DJI mit Lahoux Buzzard"}
        },
        photography: {
            nl: {"saved-by-buzzard.jpg": "Opgespoord en gered dankzij de Lahoux Buzzard"},
            en: {"saved-by-buzzard.jpg": "Tracked down and rescued thanks to the Lahoux Buzzard"},
            de: {"saved-by-buzzard.jpg": "Dank des Lahoux-Buzzards aufgespürt und gerettet"}
        },
        footnote_battery: true,
        techs: { 
            Detector: { 
                en: {"Type/Resolution": "VOx/ 640 x 512 pixels", "Pixel pitch": "12 µm", "Frame rate": "60 Hz"},
                nl: {"Type/Resolutie": "VOx/ 640 x 512 pixels", "Pixelafstand": "12 µm", "Beeldfrequentie": "60 Hz"},
                de: {"Typ/Auflösung": "VOx/ 640 x 512 pixels", "Pixelabstand": "12 µm", "Bildfrequenz": "60 Hz"}
            },
            Optics: {
                en: {"Field of view": "45.76° × 37.31°", "Field of view per pixel": "0.07 × 0.07°", "Observable area at 100 meter": "102.7 x 76.2", "Area per pixel at 100 meters": "0.13  × 0.13 m"},
                nl: {"Gezichtsveld": "45.76° × 37.31°", "Gezichtsveld per pixel": "0.07 × 0.07°", "Waarneembaar gebied op 100 meter": "102.7 x 76.2", "Gebied per pixel op 100 meter": "0.13  × 0.13 m"},
                de: {"Sichtfeld": "45.76° × 37.31°", "Sichtfeld pro Pixel": "0.07 × 0.07°", "Beobachtbares Gebiet in 100 Metern": "102.7 x 76.2", "Gebiet pro Pixel bei 100 Metern": "0.13  × 0.13 m"}
            },
            Electronics: {
                en: {"Video transmitter frequency": "5.8 GHz", "Video transmitter power": "25 mW", "Internal recording": "Yes", "Recording resolution": "640 x 480"},
                nl: {"Videozendfrequentie": "5.8 GHz", "Videozendervermogen": "25 mW", "Intern opnemen": "Yes", "Opnameresolutie": "640 x 480"},
                de: {"Video-Senderfrequenz": "5.8 GHz", "Video-Senderleistung": "25 mW", "Interne Aufnahme": "Yes", "Auflösung der Aufnahme": "640 x 480"}
            },
            Operating: {
                en: {"Power supply": "9V rechargeable LiPO", "Battery lifetime": "> 1 hour *", "Encapsulation": "IP20", "Dimensions": "L 138 × W 59 × H 58 mm", "Weight": "0,133 kg"},
                nl: {"Voeding": "9V oplaadbare LiPO", "Batterijduur": "> 1 uur *", "Waterdichtheid": "IP20", "Afmetingen": "L 138 × W 59 × H 58 mm", "Gewicht": "0,133 kg"},
                de: {"Stromversorgung": "9V wiederaufladbare LiPO", "Batterielaufzeit": "> 1 Stunde *", "Wasserdicht": "IP20", "Abmessungen": "L 138 × W 59 × H 58 mm", "Gewicht": "0,133 kg"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
	},
/*
    {
		id: "wm40",
        orderID: "106",
        type: "thermal",
        title: {nl: "Lahoux Buzzard 640 (set)", en: "Lahoux Buzzard 640 (set)", de: "Lahoux Buzzard 640 (set)"},
        url: "Lahoux-Buzzard-640-set",
        price: "3357.00",
        new: false,
        //available_date: {nl: "Binnenkort verkrijgbaar", en: "Available soon", de: "Bald verfügbar"},
        shortDes: { nl: "De Lahoux Buzzard 640 is een clip-on met thermisch beeld, ontworpen voor een C1 klasse drone.", 
                    en: "The Lahoux Buzzard 640 is a thermal imaging clip-on designed for a C1 class drone.", 
                    de: "Der Lahoux Buzzard 640 ist ein Wärmebild-Aufsatz für eine Drohne der Klasse C1. "},
        longDes_1: {    nl:"De Lahoux Buzzard 640 is een clip-on met thermisch beeld, ontworpen voor een C1 klasse drone. De toepassingen zijn zeer uiteenlopend, bijvoorbeeld: beveiliging & bewaking, jacht en anti-stroperij, agrarisch gebruik, natuurbehoud, zoeken van reekalveren en weidevogels voor het maaien, zoeken van wilde zwijnen in de mais, industriële inspectie, etc. De videozender heeft een operationeel bereik geeft van 500 meter. De thermische module heeft een resolutie van 640x512 pixels. Het zoekgebied is 31.3 x 23.2 meter op 30 meter hoogte. DRI (Detectie/Herkenning/Identificatie): Voertuig (4,00 × 1,75 m) – 1324/331/165 meter, Mens (1,75 × 0,75 m) – 468/117/59 meter, Reekalf (0,5 × 0,5 m) – 250/63/31 meter", 
                        en: "The Lahoux Buzzard 640 is a thermal imaging clip-on designed for a C1 class drone. Applications are wide ranging, for instance security & surveillance, anti-poaching, agricultural use, nature conservation, rescuing fawns and birds before mowing, industrial inspection amongst others. The video transmitter gives an operational range of up to 500 meters. The thermal imaging module has a resolution of 640x512 pixels. The search area is 31.3 x 23.2 meters at 30 meters altitude. DRI (detection/recognition/identification): Vehicle (4.00 × 1.75 m) – 1324/331/165 meters, Human (1.75 × 0.75 m) – 468/117/59 meters, Fawn (0.5 × 0.5 m) – 250/63/31 meters", 
                         de: "Der Lahoux Buzzard 640 ist ein Wärmebild-Aufsatz für eine Drohne der Klasse C1. Die Anwendungen sind vielfältig, z. B. Sicherheit und Überwachung; Jagd und Naturschutz, Suche von Wildschweinen im Mais, bestimmen von Schäden in der Landwirtschaft, Rettung von Rehkitzen und Vögeln vor dem Mähen, industrielle Inspektionen und vieles mehr. Der Videosender hat eine Reichweite von ungefähr 500 Metern. Die Wärmebildaufnahme erfolgt mit einer Auflösung von 640x512 Pixel. Der Suchbereich beträgt für Rehkitz 31.3 x 23.2 Meter in 30 Metern Höhe. DRI (Detektion/Erkennung/Identifizierung): Fahrzeug (4,00 × 1,75 m) – 1324/331/165 Meter, Mensch (1,75 × 0,75 m) – 468/117/59 Meter, Rehkitz (0,5 × 0,5 m) – 250/63/31 Meter"},
        pros: {     nl:[ "Een premium lichtgewicht koolstofvezel behuizing", "Gemakkelijk te bevestigen aan en te verwijderen van verschillende drones", "Video transmissie via markt leidende hardware", "On-board videorecorder", "Het thermisch beeld wordt doorgestuurd naar een LCD monitor op een statief", "Inclusief drone, monitor en statief"], 
                    en: ["Lightweight composite carbon fiber construction", "Easy to attach to and remove from various drones", "Video transmission via market leading hardware", "On-board video recorder", "Thermal image is transmitted to an LCD monitor on a tripod", "Includes drone, monitor and tripod"], 
                    de: ["Leichte Kohlefaser-Verbundkonstruktion", "Einfaches Anbringen und Abnehmen an verschiedenen Drohnen", "Videoübertragung über marktführende Hardware", "An Bord Videorekorder", "Das Wärmebild wird auf einen LCD-Monitor auf einem Stativ übertragen", "Inklusive Drohne, Monitor und Stativ"]

        },
        number: "02-0002-03706",
        ean: "6090301891883",
        leveromvang: [25, 26, 27, 29, 31],
        // flyer: {nl:"Lahoux_Buzzard_NL.pdf", en: "Lahoux_Buzzard_EN.pdf", de: "Lahoux_Buzzard_DE.pdf"},
        images: ["wm15_1.png"],
        // dri: "Warmtebeeld_Buzzard.png",
        accessories: ["as17", "as18"],
        videos: {
            nl: {"wm15.mp4": "Lahoux Buzzard met drone"},
            en: {"wm15.mp4": "Lahoux Buzzard with drone"},
            de: {"wm15.mp4": "Lahoux Buzzard mit Drohne"}
        },
        footnote_battery: true,
        techs: { 
            Detector: { 
                en: {"Type/Resolution": "VOx/ 640 x 512 pixels", "Pixel pitch": "12 µm", "Frame rate": "60 Hz"},
                nl: {"Type/Resolutie": "VOx/ 640 x 512 pixels", "Pixelafstand": "12 µm", "Beeldfrequentie": "60 Hz"},
                de: {"Typ/Auflösung": "VOx/ 640 x 512 pixels", "Pixelabstand": "12 µm", "Bildfrequenz": "60 Hz"}
            },
            Optics: {
                en: {"Field of view": "45.76° × 37.31°", "Field of view per pixel": "0.07 × 0.07°", "Observable area at 100 meter": "102.7 x 76.2", "Area per pixel at 100 meters": "0.13  × 0.13 m"},
                nl: {"Gezichtsveld": "45.76° × 37.31°", "Gezichtsveld per pixel": "0.07 × 0.07°", "Waarneembaar gebied op 100 meter": "102.7 x 76.2", "Gebied per pixel op 100 meter": "0.13  × 0.13 m"},
                de: {"Sichtfeld": "45.76° × 37.31°", "Sichtfeld pro Pixel": "0.07 × 0.07°", "Beobachtbares Gebiet in 100 Metern": "102.7 x 76.2", "Gebiet pro Pixel bei 100 Metern": "0.13  × 0.13 m"}
            },
            Electronics: {
                en: {"Video transmitter frequency": "5.8 GHz", "Video transmitter power": "25 mW", "Internal recording": "Yes", "Recording resolution": "640 x 480"},
                nl: {"Videozendfrequentie": "5.8 GHz", "Videozendervermogen": "25 mW", "Intern opnemen": "Yes", "Opnameresolutie": "640 x 480"},
                de: {"Video-Senderfrequenz": "5.8 GHz", "Video-Senderleistung": "25 mW", "Interne Aufnahme": "Yes", "Auflösung der Aufnahme": "640 x 480"}
            },
            Operating: {
                en: {"Power supply": "9V rechargeable LiPO", "Battery lifetime": "> 1 hour *", "Encapsulation": "IP20", "Dimensions": "L 138 × W 59 × H 58 mm", "Weight": "0,133 kg"},
                nl: {"Voeding": "9V oplaadbare LiPO", "Batterijduur": "> 1 uur *", "Waterdichtheid": "IP20", "Afmetingen": "L 138 × W 59 × H 58 mm", "Gewicht": "0,133 kg"},
                de: {"Stromversorgung": "9V wiederaufladbare LiPO", "Batterielaufzeit": "> 1 Stunde *", "Wasserdicht": "IP20", "Abmessungen": "L 138 × W 59 × H 58 mm", "Gewicht": "0,133 kg"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
	},
*/
    {
		id: "wm30",
        orderID: "140",
        type: "thermal",
        title: {nl: "Lahoux Clip 25", en: "Lahoux Clip 25", de: "Lahoux Clip 25"},
        url: "Lahoux-Clip-25",
        price: "1294.64",
        new: false,
        endOfLife: true,
        shortDes: { nl:"De Lahoux Clip 25 kan zowel als handkijker als als voorzet (dual-use) worden gebruikt. In combinatie met zijn lage prijs resulteert dit in een onverslaanbare prijs-prestatieverhouding.", 
                    en: "The Lahoux Clip 25 can be used both as a handheld device and as a dual use attachment. In combination with its low price, this results in an unbeatable price-performance ratio.", 
                    de: "Das Lahoux Clip 25 ermöglicht einen doppelten Verwendungszweck und kann sowohl als Hand- als auch als Aufsatzgerät verwendet werden. In Kombination mit dem günstigen Preis ergibt sich so ein unschlagbares Preis-Leistungs-Verhältnis."},
        longDes_1: {    nl:"De Lahoux Clip 25 kan zowel als handkijker als als voorzet (dual-use) worden gebruikt. In combinatie met zijn lage prijs resulteert dit in een onverslaanbare prijs-prestatieverhouding. Door de mogelijkheid tot snelle montage en demontage van het opzetstuk kunt u het potentieel van de Lahoux Clip 25 volledig benutten. Het compacte formaat en het lage gewicht zorgen voor een ontspannen jacht. Of het nu tijdens de bers is of bij het schieten, de Lahoux Clip 25 voldoet aan alle eisen. De Clip 25 is gemaakt voor kortere afstanden, toch is het detectiebereik bijna een kilometer en het grote, superscherpe 1024×768 OLED-scherm kan voor elk type bosjacht worden gebruikt. Zowel bij voerplekken of bij schadepercelen (zoals mais), de Lahoux Clip 25 maakt jagen op de juiste manier mogelijk, zelfs bij slechte weersomstandigheden, zoals nieuwe maan of mist. Voor uw gemak wordt de Batterijvak Extender BFE 2 meegeleverd voor de Lahoux Clip 25. 16650-batterijen hebben een aanzienlijk langere looptijd dan de standaard CR123-batterijen.", 
                        en: "The Lahoux Clip 25 can be used both as a handheld device and as a dual use attachment. In combination with its low price, this results in an unbeatable price-performance ratio. The possibility of quick mounting and dismounting of the attachment allows you to fully exploit the potential of the Lahoux Clip 25. Its compact size and low weight allow for relaxed hunting. Whether during a stalk or when firing a shot, the Lahoux Clip 25 will meet every requirement. The  Clip 25 is made for shorter ranges, still the detection range is almost one kilometer and the large, super sharp 1024×768 OLED display can be used for every type of forest hunting. Whether at feeding places or near damaged crops (like corn), the Lahoux Clip 25 enables hunting in the right way even in bad weather conditions, such as new moon or fog. For your convenience the Battery compartment Extender BFE 2 is included for the Lahoux Clip 25. 16650 batteries have a significantly longer runtime than the standard CR123 batteries.", 
                        de: "Das Lahoux Clip 25 ermöglicht einen doppelten Verwendungszweck und kann sowohl als Hand- als auch als Aufsatzgerät verwendet werden. In Kombination mit dem günstigen Preis ergibt sich so ein unschlagbares Preis-Leistungs-Verhältnis. Durch die Möglichkeit der schnellen Montage und Demontage des Aufsatzes, können Sie das Potenzial des Lahoux Clip 25 voll ausschöpfen. Die kompakte Größe und das geringe Gewicht ermöglichen eine entspannte Jagd. Ob auf der Pirsch oder beim Schuss, das Lahoux Clip 25 wird jedem Anspruch gerecht. Das Lahoux Clip 25 ist für kurze Distanzen gemacht; dennoch beträgt die Erkennungsreichweite fast einen Kilometer. Das große, superscharfe 1024×768 Pixel OLED-Display kann für jede Art der Waldjagd verwendet werden. Ob an Futterstellen oder in der Nähe von beschädigten Pflanzen (wie Mais), das Lahoux Clip 25 ermöglicht die Jagd auch bei schlechten Wetterbedingungen, wie z. Bsp. Neumond oder Nebel. Für einen besseren Komfort ist die Batteriefach Extender BFE 2 für das Lahoux Clip 25 bereits im Lieferumfang enthalten. Typ 16650 Batterien haben eine deutlich höhere Batterielaufzeit als die Standard-CR123-Batterien."},
        longDes_2: {    nl:"Dit maakt de Lahoux Clip 25 tot de optimale korte afstand warmtebeeldkijker voor prijsbewuste jagers! De Lahoux Clip 25 is met minder dan 400 gram de lichtste uit de Lahoux Clip serie. Het gezichtsveld van 14,6° x 11,0° biedt een uitstekend zicht voor uitgebreide observatie en is daarom bij uitstek geschikt voor de bosjager. De aanbevolen schietafstand is iets minder dan 100 meter.", 
                        en: "This makes the Lahoux Clip 25 the optimal short range thermal imaging device for price-conscious hunters! The Lahoux Clip 25 is the lightest of the Lahoux Clip series with less than 400 grams. The field of view of 14.6 ° x 11.0 ° offers an excellent view for extensive observations and is therefore ideally suited for the forest hunter. The recommended shooting distance is slightly below 100 meters.", 
                        de: "All die zuvor genannten Eigenschaften machen das Lahoux Clip 25 zum optimalen Nahbereichs-Wärmebildgerät für preisbewusste Jäger! Das Lahoux Clip 25 ist mit weniger als 400 Gramm das leichteste Gerät der Lahoux Clip-Serie. Das Sehfeld von 25,62 m Breite auf 100 m Entfernung (14,6° x 11,0°) bietet eine hervorragende Sicht für ausgiebige Beobachtungen und ist somit ideal für den Jäger im Wald. Die empfohlene Aufnahmeentfernung liegt etwas unter 100 Metern."},                 
        pros: {     nl: ["4 kleurmodi: wit-heet, zwart-heet, rood-heet/hot spot, wit-heet/groen", "Digitale zoom van monoculair: 2x, 4x", "Geïntegreerd: bluetooth, kompas, laser en bewegingssensor", "Geschikt voor standaardkalibers", "De Lahoux Clip 25 heeft een extra brede, stevige aluminium lensring die veel grip biedt bij het scherpstellen"], 
                    en: ["4 colour modes: white-hot, black-hot, red-hot / hot spot, white-hot/green", "Digital zoom from monocular： 2x, 4x", "Integrated: bluetooth, compass, laser and motion sensor", "Suitable for standard calibres", "The Lahoux Clip 25 features an extra wide, sturdy aluminum lens ring that provides plenty of grip when focusing"], 
                    de: ["4 Farbmodi: Weißglühend, Schwarzglühend, Rotglühend, Hot Spot, Weißglühend/Grün", "Digitales Zoom des Monokular: 2x und 4x", "Integriertes Bluetooth, Kompass und Bewegungssensor", "Passend für Standardkaliber", "Das Lahoux Clip 25 verfügt über einen extra breiten, stabilen Objektivring aus Aluminium, der beim Fokussieren viel Gripp bietet"]

        },
        number: "02-0006-03231",
        ean: "6090301662636",
        jubilee_offer: false,
        leveromvang: [1, 20, 13, 3, 23, 4, 28, 34, 35], 
        flyer: {nl:"Lahoux_Clip_serie_NL.pdf", en: "Lahoux_Clip_serie_EN.pdf", de: "Lahoux_Clip_serie_DE.pdf"},
        images: ["Lahoux_Clip_25.png", "Lahoux_Clip_25-2.png", "Lahoux_Clip_25-3.png", "Lahoux_Clip_25-4.png"],
        dri: "Warmtebeeld_Voorzetten.png",
        accessories: ["as6", "as10", "as12", "as19"],
        footnote_battery: true,
        techs: { 
            Detector: { 
                en: {"Resolution": "384 × 288 Pixel", "Pixel pitch": "17 µm", "Sensitivity (NETD)": "< 50 mK"},
                nl: {"Resolutie": "384 × 288 Pixel", "Pixelafstand": "17 µm", "Gevoeligheid (NETD)": "< 50 mK"},
                de: {"Auflösung": "384 × 288 Pixel", "Pixelabstand": "17 µm", "Empfindlichkeit (NETD)": "< 50 mK"}
            },
            Optics: {
                en: {"Lens": "25 mm", "Relative aperture": "F/1.1", "Field of view": "14.6°x11.0°", "Zoom (digital)": "1×, 2×, 4×"},
                nl: {"Lens": "25 mm", "Relatieve lensopening": "F/1.1", "Gezichtsveld": "14.6°x11.0°", "Zoom (digital)": "1×, 2×, 4×"},
                de: {"Linse": "25 mm", "Relative Blende": "F/1.1", "Sichtfeld": "14.6°x11.0°", "Zoom (digital)": "1×, 2×, 4×"}
            },
            Electronics: {
                en: {"Display": "OLED 1024 × 768 Pixel", "Battery": "2x CR123", "Battery (with extended battery compartment, Lahoux BFE 2)": "2x 16650", "Battery runtime with Lahoux BFE 2": "Circa 6 hours (at 21°C) *"},
                nl: {"Beeldscherm": "OLED 1024 × 768 Pixel", "Batterij": "2x CR123", "Batterij (met verlengd batterijvak, Lahoux BFE 2)": "2x 16650", "Batterijduur met Lahoux BFE 2": "Circa 6 uur (bij 21°C) *"},
                de: {"Anzeige": "OLED 1024 × 768 Pixel", "Batterie": "2x CR123", "Batterie mit erweitertem Batteriefach, Lahoux BFE 2)": "2x 16650", "Batterielaufzeit mit Lahoux BFE 2": "Etwa 6 Stunden (bei 21°C) *"}
            },
            Operating: {
                en: {"Watertight": "IP67", "Dimensions": "150 × 52 × 57 mm", "Weight": "<0.4 kg"},
                nl: {"Waterdichtheid": "IP67", "Afmetingen": "150 × 52 × 57 mm", "Gewicht": "<0.4 kg"},
                de: {"Wasserdicht": "IP67", "Abmessungen": "150 × 52 × 57 mm", "Gewicht": "<0.4 kg"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: true, filterRiflescope: false}
	},
    {
		id: "wm16",
        orderID: "131",
        type: "thermal",
        title: {nl: "Lahoux Clip 35", en: "Lahoux Clip 35", de: "Lahoux Clip 35"},
        url: "Lahoux-Clip-35",
        price: "1455.36",
        new: false,
        endOfLife: false,
        shortDes: { nl:"De Lahoux Clip 35 kan zowel als handkijker als als voorzet (dual-use) worden gebruikt. In combinatie met zijn lage prijs resulteert dit in een onverslaanbare prijs-prestatieverhouding.", 
                    en: "The Lahoux Clip 35 can be used both as a handheld device and as a dual use attachment. In combination with its low price, this results in an unbeatable price-performance ratio.", 
                    de: "Das Lahoux Clip 35 ist sowohl als Handgerät wie auch als Vorsatzgerät (Dual Use) nutzbar. In Kombination mit seinem günstigen Preis ergibt sich hieraus ein unschlagbares Preis-Leistungs-Verhältnis."},
        longDes_1: {    nl:"De Lahoux Clip 35 kan zowel als handkijker als als voorzet (dual-use) worden gebruikt. In combinatie met zijn lage prijs resulteert dit in een onverslaanbare prijs-prestatieverhouding. Door de mogelijkheid tot snelle montage en demontage van het opzetstuk kunt u het potentieel van de Lahoux Clip 35 volledig benutten. Het compacte formaat en het lage gewicht zorgen voor een ontspannen jacht. Of het nu tijdens de bers is of bij het schieten, de Lahoux Clip 35 voldoet aan alle eisen. Het hoge detectiebereik van meer dan een kilometer en het grote, superscherpe 1024 × 768 OLED-scherm maken het mogelijk om aan elke jachtsituatie te voldoen. Zowel bij voerplekken of bij schadepercelen (zoals mais), de Lahoux Clip 35 maakt jagen op de juiste manier mogelijk, zelfs bij slechte weersomstandigheden, zoals nieuwe maan of mist. Voor uw gemak wordt de Batterijvak Extender BFE 2 meegeleverd voor de Lahoux Clip 35. 16650-batterijen hebben een aanzienlijk langere looptijd dan de standaard CR123-batterijen.", 
                        en: "The Lahoux Clip 35 can be used both as a handheld device and as a dual use attachment. In combination with its low price, this results in an unbeatable price-performance ratio. The possibility of quick mounting and dismounting of the attachment allows you to fully exploit the potential of the Lahoux Clip 35. Its compact size and low weight allow for relaxed hunting. Whether during a stalk or when firing a shot, the Lahoux Clip 35 will meet every requirement. The high detection range of more than one kilometre and the large, super sharp 1024×768 OLED display allow to meet every hunting situation. Whether at feeding places or near damaged crops (like corn), the Lahoux Clip 35 enables hunting in the right way even in bad weather conditions, such as new moon or fog. For your convenience the Battery compartment Extender BFE 2 is included for the Lahoux Clip 35. 16650 batteries have a significantly longer runtime than the standard CR123 batteries.", 
                         de: "Das Lahoux Clip 35 ist sowohl als Handgerät wie auch als Vorsatzgerät (Dual Use) nutzbar. In Kombination mit seinem günstigen Preis ergibt sich hieraus ein unschlagbares Preis-Leistungs-Verhältnis. Die Möglichkeit der schnellen Montage und Demontage des Vorsatzes gestatten hier die Potenziale des Lahoux Clip 35 voll auszuschöpfen. Seine kompakte Größe und sein geringes Gewicht erlauben ein entspanntes Jagen. Ob nun auf der Pirsch oder bei der Schussabgabe, das Lahoux Clip 35 wird jeder Anforderung gerecht. Die hohe Detektionsreichweite von über einem Kilometer und das große, superscharfe 1024×768 OLED Display erlauben es jeder jagdlichen Situation gerecht zu werden. Ob nun an der Kirrung oder bei Wildschäden (im Mais), das Lahoux Clip 35 ermöglicht auch bei schlechten Witterungsbedingten, wie Neumond oder Nebel ein waidgerechtes Jagen. Für einen besseren Komfort ist die Batteriefach Extender BFE 2 für das Lahoux Clip 35 bereits im Lieferumfang enthalten. Typ 16650 Batterien haben eine deutlich höhere Batterielaufzeit als die Standard-CR123-Batterien."},
        longDes_2: {    nl:"Dit maakt de Lahoux Clip 35 tot de optimale warmtebeeldkijker voor prijsbewuste jagers! De Lahoux Clip 35 is met zijn 400 gram een van de lichtste van de Lahoux Clip serie. Het gezichtsveld van 10,66° x 8° biedt een uitstekend zicht voor uitgebreide observatie en is daarom bij uitstek geschikt voor de allround jager. De aanbevolen schietafstand is hier maximaal 120 m.", 
                        en: "This makes the Lahoux Clip 35 the optimal thermal imaging device for price-conscious hunters! The Lahoux Clip 35 is one of the lightest of the Lahoux Clip series with its 400 grams. The field of view of 10.66 ° x 8 ° offers an excellent view for extensive observations and is therefore ideally suited for the all-round hunter. The recommended shooting distance here is a maximum of 120 m.", 
                        de: "Damit ist das Lahoux Clip 35 das optimale Wärmebildgerät für preisbewusste Jäger! Das Lahoux Clip 35 ist mit seinem 400 Gramm eines der leichtesten Geräte der Lahoux Clip Reihe. Das Sichtfeld von 10,66 ° x 8 ° bietet eine hervorragende Sicht für weitläufige Beobachtungen und ist somit ideal für den Allroundjäger geeignet. Die empfohlene Schussentfernung liegt hier bei maximal 120 m."},                 
        pros: {     nl: ["4 kleurmodi: wit-heet, zwart-heet, rood-heet/hot spot, wit-heet/groen", "Digitale zoom van monoculair: 2x, 4x", "Geïntegreerd: bluetooth, kompas, laser en bewegingssensor", "Geschikt voor standaardkalibers", "De Lahoux Clip 35 heeft een extra brede, stevige aluminium lensring die veel grip biedt bij het scherpstellen"], 
                    en: ["4 colour modes: white-hot, black-hot, red-hot / hot spot, white-hot/green", "Digital zoom from monocular： 2x, 4x", "Integrated: bluetooth, compass, laser and motion sensor", "Suitable for standard calibres", "The Lahoux Clip 35 features an extra wide, sturdy aluminum lens ring that provides plenty of grip when focusing"], 
                    de: ["4 Bildmodi: weiß-heiß/grün, schwarz-heiß, rot-heiß / hot spot, weiß-heiß/grün", "Digitalzoom vom Monokular： 2x, 4x", "Integriert: Bluetooth, Kompass, Laser und Bewegungssensor", "Geeignet für Standardkaliber", "Das Lahoux Clip 35 verfügt über einen extra breiten, stabilen Objektivring aus Aluminium, der beim Fokussieren viel Gripp bietet"]

        },
        number: "02-0006-02475",
        ean: "6090300937988",
        jubilee_offer: false,
        leveromvang: [1, 20, 13, 3, 23, 4, 28, 34, 35], 
        flyer: {nl:"Lahoux_Clip_serie_NL.pdf", en: "Lahoux_Clip_serie_EN.pdf", de: "Lahoux_Clip_serie_DE.pdf"},
        images: ["wm16_1.png", "wm16_2.png", "wm16_3.png", "wm16_4.png"],
        dri: "Warmtebeeld_Voorzetten.png",
        accessories: ["as6", "as10", "as12", "as19"],
        footnote_battery: true,
        techs: { 
            Detector: { 
                en: {"Resolution": "384 × 288 Pixel", "Pixel pitch": "17 µm", "Sensitivity (NETD)": "< 50 mK"},
                nl: {"Resolutie": "384 × 288 Pixel", "Pixelafstand": "17 µm", "Gevoeligheid (NETD)": "< 50 mK"},
                de: {"Auflösung": "384 × 288 Pixel", "Pixelabstand": "17 µm", "Empfindlichkeit (NETD)": "< 50 mK"}
            },
            Optics: {
                en: {"Lens": "35 mm", "Relative aperture": "F/1.2", "Field of view": "10.66°x8°", "Zoom (digital)": "1×, 2×, 4×"},
                nl: {"Lens": "35 mm", "Relatieve lensopening": "F/1.2", "Gezichtsveld": "10.66°x8°", "Zoom (digital)": "1×, 2×, 4×"},
                de: {"Linse": "35 mm", "Relative Blende": "F/1.2", "Sichtfeld": "10.66°x8°", "Zoom (digital)": "1×, 2×, 4×"}
            },
            Electronics: {
                en: {"Display": "OLED 1024 × 768 Pixel", "Battery": "2x CR123", "Battery (with extended battery compartment, Lahoux BFE 2)": "2x 16650", "Battery runtime with Lahoux BFE 2": "Circa 6 hours (at 21°C) *"},
                nl: {"Beeldscherm": "OLED 1024 × 768 Pixel", "Batterij": "2x CR123", "Batterij (met verlengd batterijvak, Lahoux BFE 2)": "2x 16650", "Batterijduur met Lahoux BFE 2": "Circa 6 uur (bij 21°C) *"},
                de: {"Anzeige": "OLED 1024 × 768 Pixel", "Batterie": "2x CR123", "Batterie mit erweitertem Batteriefach, Lahoux BFE 2)": "2x 16650", "Batterielaufzeit mit Lahoux BFE 2": "Etwa 6 Stunden (bei 21°C) *"}
            },
            Operating: {
                en: {"Watertight": "IP67", "Dimensions": "150 × 52 × 57 mm", "Weight": "0.40 kg"},
                nl: {"Waterdichtheid": "IP67", "Afmetingen": "150 × 52 × 57 mm", "Gewicht": "0.40 kg"},
                de: {"Wasserdicht": "IP67", "Abmessungen": "150 × 52 × 57 mm", "Gewicht": "0.40 kg"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: true, filterRiflescope: false}
	},

// *** Remove Lahoux Clip 35 Pro from site: EOL; keep data here for future use ***   
    // {
	// 	id: "wm17",
    //     type: "thermal",
	// 	title: "Lahoux Clip 35 Pro",
    //     url: "Clip-35-Pro",
    //     price: "3357.14",
    //     shortDes: { nl:"De Lahoux Clip 35 Pro kan zowel als handkijker als als voorzet (dual-use) worden gebruikt.", 
    //                 en: "The Lahoux Clip 35 Pro can be used both as a handheld device and as a dual use attachment.", 
    //                 de: "Das Lahoux Clip 35 Pro ist sowohl als Handgerät wie auch als Vorsatzgerät (Dual Use) nutzbar."},
    //     longDes_1: {    nl:"De Lahoux Clip 35 Pro kan zowel als handkijker als als voorzet (dual-use) worden gebruikt.", 
    //                     en: "The Lahoux Clip 35 Pro can be used both as a handheld device and as a dual use attachment.", 
    //                      de: "Das Lahoux Clip 35 Pro ist sowohl als Handgerät wie auch als Vorsatzgerät (Dual Use) nutzbar."},
    //     warning: true, 
    //     number: "02-0006-02713",
    //     accessoires: [1, 20, 13,  3, 23, 4],
    //     flyer: {nl:"Lahoux_Clip_NL_V3.pdf", en: "Lahoux_Clip_EN_V3.pdf", de: "Lahoux_Clip_DE_V3.pdf"},
    //     images: ["wm17_1.png", "wm17_2.png", "wm17_3.png", "wm17_4.png"],
    //     related: ["wm1", "wm16", "wm18"],
    //     accessories: ["as6", "as10"],
    //     techs: { 
    //         Detector: { "Resolution": "384 × 288 Pixel", "Pixel pitch": "12 µm", "Sensitivity (NETD)": "< 40 mK"},
    //         Optics: {"Lens": "35 mm", "Relative aperture": "F/1.0", "Field of view": "8° × 12°", "Zoom (digital)": "1×, 2×, 3×, 4×"},
    //         Electronics: {"Display": "Colour HD OLED 1024 × 768 Pixel", "Battery": "1× 18650 battery", "Battery life": "5 hours"},
    //         Operating: {"Watertight": "IPX6", "Dimensions": "133 × 64 × 52 mm", "Weight": "0,47 kg" }
    //     }
	// },

    {
		id: "wm18",
        orderID: "155",
        type: "thermal",
        title: {nl: "Lahoux Clip 42", en: "Lahoux Clip 42", de: "Lahoux Clip 42"},
        url: "Lahoux-Clip-42",
        price: "1924.14",
        new: false,
        endOfLife: false,
        shortDes: { nl:"De Lahoux Clip 42 kan zowel als handkijker als als voorzet (dual-use) worden gebruikt. In combinatie met zijn lage prijs resulteert dit in een onverslaanbare prijs-prestatieverhouding.", 
                    en: "The Lahoux Clip 42 can be used both as a handheld device and as a dual use attachment. In combination with its low price, this results in an unbeatable price-performance ratio.", 
                    de: "Das Lahoux Clip 42 ist sowohl als Handgerät wie auch als Vorsatzgerät (Dual Use) nutzbar. In Kombination mit seinem günstigen Preis ergibt sich hieraus ein unschlagbares Preis-Leistungs-Verhältnis."},
        longDes_1: {    nl:"De Lahoux Clip 42 kan zowel als handkijker als als voorzet (dual-use) worden gebruikt. In combinatie met zijn lage prijs resulteert dit in een onverslaanbare prijs-prestatieverhouding. Door de mogelijkheid tot snelle montage en demontage van het opzetstuk kunt u het potentieel van de Lahoux Clip 42 volledig benutten. Het compacte formaat en het lage gewicht zorgen voor een ontspannen jacht. Of het nu tijdens de bers is of bij het schieten, de Lahoux Clip 42 voldoet aan alle eisen. Het hoge detectiebereik van meer dan een kilometer en het grote, superscherpe 1024 × 768 OLED-scherm maken het mogelijk om aan elke jachtsituatie te voldoen. Of het nu bij de voederplaats is of op het beschadigde gebied in maïs, de Lahoux Clip 42 maakt jagen op de juiste manier mogelijk, zelfs bij slechte weersomstandigheden, zoals nieuwe maan of mist.", 
                        en: "The Lahoux Clip 42 can be used both as a handheld device and as a dual use attachment. In combination with its low price, this results in an unbeatable price-performance ratio. The possibility of quick mounting and dismounting of the attachment allows you to fully exploit the potential of the Lahoux Clip 42. Its compact size and low weight allow for relaxed hunting. Whether during a stalk or when firing a shot, the Lahoux Clip 42 will meet every requirement. The high detection range of more than one kilometre and the large, super sharp 1024×768 OLED display allow to meet every hunting situation. Whether at the feeding place, or on the damaged area in the corn, the Lahoux Clip 42 enables hunting in the right way even in bad weather conditions, such as new moon or fog.", 
                         de: "Das Lahoux Clip 42 ist sowohl als Handgerät wie auch als Vorsatzgerät (Dual Use) nutzbar. In Kombination mit seinem günstigen Preis ergibt sich hieraus ein unschlagbares Preis-Leistungs-Verhältnis. Die Möglichkeit der schnellen Montage und Demontage des Vorsatzes gestatten hier die Potenziale das Lahoux Clip 42 voll auszuschöpfen. Seine kompakte Größe und sein geringes Gewicht erlauben ein entspanntes Jagen. Ob nun auf der Pirsch oder bei der Schussabgabe, das Lahoux Clip 42 wird jeder Anforderung gerecht. Die hohe Detektionsreichweite von über einem Kilometer und das große, superscharfe 1024×768 OLED Display erlauben es jeder jagdlichen Situation gerecht zu werden. Ob nun an der Kirrung, oder auf der Schadensfläche im Mais, das Lahoux Clip 42 ermöglicht auch bei schlechten Witterungsbedingten, wie Neumond oder Nebel ein waidgerechtes Jagen."},
        longDes_2: {     nl:"De Lahoux Clip 42 heeft een nieuw Super OLED-display, dat niet alleen de hoogste beeldkwaliteit biedt, maar ook de energie-efficiëntie heeft geoptimaliseerd, waardoor ook de batterij wordt gespaard. Met zijn gezichtsveld van 8,9° x 6,7° voldoet de Lahoux Clip 42 zeer goed aan de behoeften van een veld-/bosjager door een compromis tussen de breedte van het gezichtsveld en de grootte van het gedetailleerde display. Dit maakt de Lahoux Clip 42 tot de optimale warmtebeeldkijker voor prijsbewuste jagers! ",
                    en: "The Lahoux Clip 42 has a new Super OLED display, which not only provides the highest picture quality but also has optimised energy efficiency, thus also saving the battery. With its field of view of 8.9 ° x 6.7 ° the Lahoux Clip 42 meets the needs of a field / forest hunter very well by creating a compromise between the width of the field of view and the size of the detailed display. This makes the Lahoux Clip 42 the optimal thermal imaging device for price-conscious hunters!", 
                    de: "Das Lahoux Clip 42 verfügt über ein neuartiges Super OLED Display, welches neben höchster Bildqualität auch über eine optimierte Energieeffizienz verfügt und somit auch das Akku schont. Mit seinem Sichtfeld von 8,9 ° x 6,7 ° bildet das Lahoux Clip 42 die Bedürfnisse eines Feld- / Waldjägers sehr gut ab, indem es einen Kompromiss schafft, zwischen der Breite des Sehfeldes und der Größe der Detaildarstellung. Damit ist das Lahoux Clip 42 das optimale Wärmebildgerät für preisbewusste Jäger!" },                 
        pros: {     nl: ["4 kleurmodi: wit-heet, zwart-heet, rood-heet/hot spot, pseudokleur", "Geïntegreerd: bluetooth, kompas, laser en bewegingssensor", "Bluetooth-afstandsbediening", "Geschikt voor standaardkalibers"], 
                    en: ["4 colour modes: white-hot, black-hot, red-hot / hot spot, pseudo colour", "Integrated: bluetooth, compass, laser and motion sensor", "Bluetooth remote control", "Suitable for standard calibres"], 
                    de: ["4 Bildmodi: weiß-heiß/grün, schwarz-heiß, rot-heiß / hot spot, pseudofarbe", "Integriert: Bluetooth, Kompass, Laser und Bewegungssensor", "Bluetooth Fernbedienung", "Geeignet für Standardkaliber"]

        },
        number: "02-0006-02452",
        leveromvang: [4, 13, 3, 1, 8, 22, 14],
        flyer: {nl:"Lahoux_Clip_serie_NL.pdf", en: "Lahoux_Clip_serie_EN.pdf", de: "Lahoux_Clip_serie_DE.pdf"},
        images: ["wm18_1.png", "wm18_2.png", "wm18_3.png", "wm18_4.png"],
        dri: "Warmtebeeld_Voorzetten.png",
        related: ["wm1", "wm20"],
        accessories: ["as6", "as10", "as11", "as19"],
        footnote_battery: true,       
        techs: { 
            Detector: { 
                en: {"Resolution": "384 × 288 Pixel", "Pixel pitch": "17 µm", "Sensitivity (NETD)": "< 50 mK"},
                nl: {"Resolutie": "384 × 288 Pixel", "Pixelafstand": "17 µm", "Gevoeligheid (NETD)": "< 50 mK"},
                de: {"Auflösung": "384 × 288 Pixel", "Pixelabstand": "17 µm", "Empfindlichkeit (NETD)": "< 50 mK"}
            },
            Optics: {
                en: {"Lens": "42 mm", "Relative aperture": "F/1.2", "Field of view": "8.9°x6.7°", "Zoom (digital)": "1×"},
                nl: {"Lens": "42 mm", "Relatieve lensopening": "F/1.2", "Gezichtsveld": "8.9°x6.7°", "Zoom (digital)": "1×"},
                de: {"Linse": "42 mm", "Relative Blende": "F/1.2", "Sichtfeld": "8.9°x6.7°", "Zoom (digital)": "1×"}
            },
            Electronics: {
                en: {"Display": "Super OLED 1024 × 768 Pixel", "Battery": "2x CR123 battery", "Battery (with extended battery compartment, Lahoux BFE 1)": "2x 16650", "Battery runtime with Lahoux BFE 1": "Circa 6 hours (at 21°C) *"},
                nl: {"Beeldscherm": "Super OLED 1024 × 768 Pixel", "Batterij": "2x CR123 batterij", "Batterij (met verlengd batterijvak, Lahoux BFE 1)": "2x 16650", "Batterijduur met Lahoux BFE 1": "Circa 6 uur (bij 21°C) *"},
                de: {"Anzeige": "Super OLED 1024 × 768 Pixel", "Batterie": "2x CR123 batterie", "Batterie mit erweitertem Batteriefach, Lahoux BFE 1)": "2x 16650", "Batterielaufzeit mit Lahoux BFE 1": "Etwa 6 Stunden (bei 21°C) *"}
            },
            Operating: {
                en: {"Watertight": "IP67", "Dimensions": "154 × 61 × 58 mm", "Weight": "0,42 kg"},
                nl: {"Waterdichtheid": "IP67", "Afmetingen": "154 × 61 × 58 mm", "Gewicht": "0,42 kg"},
                de: {"Wasserdicht": "IP67", "Abmessungen": "154 × 61 × 58 mm", "Gewicht": "0,42 kg"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: true, filterRiflescope: false}
	},
    {
        id: "wm31",
        orderID: "133",
        type: "thermal",
        title: {nl: "Lahoux Clip 50", en: "Lahoux Clip 50", de: "Lahoux Clip 50"},
        url: "Lahoux-Clip-50",
        price: "1544.64",
        new: false,
        endOfLife: true,
        shortDes: { nl: "De Lahoux Clip 50 kan zowel als handkijker als als voorzet (dual-use) worden gebruikt. In combinatie met zijn lage prijs resulteert dit in een onverslaanbare prijs-prestatieverhouding.", 
                    en: "The Lahoux Clip 50 can be used both as a handheld device and as a dual use attachment. In combination with its low price, this results in an unbeatable price-performance ratio.", 
                    de: "Das Lahoux Clip 50 ermöglicht einen doppelten Verwendungszweck und kann sowohl als Hand- als auch als Aufsatzgerät verwendet werden. In Kombination mit dem günstigen Preis ergibt sich so ein unschlagbares Preis-Leistungs-Verhältnis."},
        longDes_1: {    nl: "De Lahoux Clip 50 kan zowel als handkijker als als voorzet (dual-use) worden gebruikt. In combinatie met zijn lage prijs resulteert dit in een onverslaanbare prijs-prestatieverhouding. Door de mogelijkheid tot snelle montage en demontage van het opzetstuk kunt u het potentieel van de Lahoux Clip 50 volledig benutten. Het compacte formaat en het lage gewicht zorgen voor een ontspannen jacht. Of het nu tijdens de bers is of bij het schieten, de Lahoux Clip 50 voldoet aan alle eisen. De Clip 50 is gemaakt voor middellange en lange afstanden, en met een detectiebereik van bijna twee kilometer en het grote, superscherpe 1024×768 OLED-scherm kan het worden gebruikt voor jacht op middellange en lange afstand in het veld. Zowel bij voerplekken of bij schadepercelen (zoals mais), de Lahoux Clip 50 maakt jagen op de juiste manier mogelijk, zelfs bij slechte weersomstandigheden, zoals nieuwe maan of mist. Voor uw gemak wordt de Batterijvak Extender BFE 2 meegeleverd voor de Lahoux Clip 50. 16650-batterijen hebben een aanzienlijk langere looptijd dan de standaard CR123-batterijen.", 
                        en: "The Lahoux Clip 50 can be used both as a handheld device and as a dual use attachment. In combination with its low price, this results in an unbeatable price-performance ratio. The possibility of quick mounting and dismounting of the attachment allows you to fully exploit the potential of the Lahoux Clip 50. Its compact size and low weight allow for relaxed hunting. Whether during a stalk or when firing a shot, the Lahoux Clip 50 will meet every requirement. The Clip 50 is made for medium and longer ranges, with a detection range of almost two kilometer and the large, super sharp 1024×768 OLED display can be used for medium and long range field hunting. Whether at feeding places or near damaged crops (like corn), the Lahoux Clip 50 enables hunting in the right way even in bad weather conditions, such as new moon or fog. For your convenience the Battery compartment Extender BFE 2 is included for the Lahoux Clip 50. 16650 batteries have a significantly longer runtime than the standard CR123 batteries.", 
                        de: "Das Lahoux Clip 50 ermöglicht einen doppelten Verwendungszweck und kann sowohl als Hand- als auch als Aufsatzgerät verwendet werden. In Kombination mit dem günstigen Preis ergibt sich so ein unschlagbares Preis-Leistungs-Verhältnis. Durch die Möglichkeit der schnellen Montage und Demontage des Aufsatzes können Sie das Potenzial des Lahoux Clip 50 voll ausschöpfen. Die kompakte Größe und das geringe Gewicht ermöglichen eine entspannte Jagd. Ob auf der Pirsch oder beim Schuss, das Lahoux Clip 50 wird jedem Anspruch gerecht. Das Lahoux Clip 50 ist für mittlere bis größere Entfernungen konzipiert, mit einer Erkennungsreichweite von fast zwei Kilometern. Das großen, superscharfen 1024×768 Pixel OLED-Display kann für die Feldjagd auf mittlere und lange Entfernungen verwendet werden. Ob an Futterstellen oder in der Nähe von beschädigten Pflanzen (wie Mais), das Lahoux Clip 50 ermöglicht die Jagd auch bei schlechten Wetterbedingungen, wie z. Bsp. Neumond oder Nebel. Für einen besseren Komfort ist die Batteriefach Extender BFE 2 für das Lahoux Clip 50 bereits im Lieferumfang enthalten. Typ 16650 Batterien haben eine deutlich höhere Batterielaufzeit als die Standard-CR123-Batterien."},
        longDes_2: {    nl: "Dit maakt de Lahoux Clip 50 tot de optimale middellange en lange afstand warmtebeeldkijker voor prijsbewuste jagers! De Lahoux Clip 50 is een lichte Lahoux Clip van nog geen 450 gram. Het gezichtsveld van 7.5° x 5.6° biedt een uitstekend zicht voor uitgebreide observatie en is daarom bij uitstek geschikt voor de veldjager. De aanbevolen schietafstand is iets minder dan 150 meter.", 
                        en: "This makes the Lahoux Clip 50 the optimal medium and long range thermal imaging device for price-conscious hunters! The Lahoux Clip 50 is a light Lahoux Clip with less than 450 grams. The field of view of 7.5° x 5.6° offers an excellent view for extensive observations and is therefore ideally suited for the field hunter. The recommended shooting distance is slightly below 150 meters.", 
                        de: "All die zuvor genannten Eigenschaften machen Lahoux Clip 50 zum optimalen Mittel- und Langstrecken-Wärmebildgerät für preisbewusste Jäger! Das Lahoux Clip 50 ist mit weniger als 450 Gramm ein leichtes Gerät aus der Lahoux Clip Serie. Das Sehfeld von 13,11 m Breite auf 100 m (7,5° x 5,6°) bietet eine ausreichende Sicht in die Breite sowie Entfernung und ist somit ideal für den Jäger im Feld. Die empfohlene Aufnahmeentfernung liegt etwas unter 150 Metern."},
        pros: {         nl: ["4 kleurmodi: wit-heet, zwart-heet, rood-heet/hot spot, wit-heet/groen", "Digitale zoom van monoculair: 2x, 4x", "Geïntegreerd: bluetooth, kompas, laser en bewegingssensor", "Geschikt voor standaardkalibers"], 
                        en: ["4 colour modes: white-hot, black-hot, red-hot / hot spot, white-hot/green", "Digital zoom from monocular： 2x, 4x", "Integrated: bluetooth, compass, laser and motion sensor", "Suitable for standard calibres"], 
                        de: ["4 Farbmodi: Weißglühend, Schwarzglühend, Rotglühend / Hot Spot, Weißglühend/Grün", "Digitales Zoom des Monokular: 2x und 4x", "Integriertes Bluetooth, Kompass und Bewegungssensor", "Passend für Standardkaliber"]
    
            },
        number: "02-0006-02476",
        jubilee_offer: false,
        leveromvang: [1, 20, 13, 3, 23, 4, 28, 34, 35], 
        flyer: {nl:"Lahoux_Clip_serie_NL.pdf", en: "Lahoux_Clip_serie_EN.pdf", de: "Lahoux_Clip_serie_DE.pdf"},
        images: ["Lahoux_Clip_50.png", "Lahoux_Clip_50-2.png", "Lahoux_Clip_50-3.png", "Lahoux_Clip_50-4.png"],
        dri: "Warmtebeeld_Voorzetten.png",
        accessories: ["as12"],
        footnote_battery: true,
        techs: { 
            Detector: { 
                en: {"Resolution": "384 × 288 Pixel", "Pixel pitch": "17 µm", "Sensitivity (NETD)": "< 50 mK"},
                nl: {"Resolutie": "384 × 288 Pixel", "Pixelafstand": "17 µm", "Gevoeligheid (NETD)": "< 50 mK"},
                de: {"Auflösung": "384 × 288 Pixel", "Pixelabstand": "17 µm", "Empfindlichkeit (NETD)": "< 50 mK"}
            },
            Optics: {
                en: {"Lens": "50 mm", "Relative aperture": "F/1.2", "Field of view": "7.5° x 5.6°", "Zoom (digital)": "1×, 2×, 4×"},
                nl: {"Lens": "50 mm", "Relatieve lensopening": "F/1.2", "Gezichtsveld": "7.5° x 5.6°", "Zoom (digital)": "1×, 2×, 4×"},
                de: {"Linse": "50 mm", "Relative Blende": "F/1.2", "Sichtfeld": "7.5° x 5.6°", "Zoom (digital)": "1×, 2×, 4×"}
            },
            Electronics: {
                en: {"Display": "OLED 1024 × 768 Pixel", "Battery": "2x CR123", "Battery (with extended battery compartment, Lahoux BFE 2)": "2x 16650", "Battery runtime with Lahoux BFE 2": "Circa 6 hours (at 21°C) *"},
                nl: {"Beeldscherm": "OLED 1024 × 768 Pixel", "Batterij": "2x CR123", "Batterij (met verlengd batterijvak, Lahoux BFE 2)": "2x 16650", "Batterijduur met Lahoux BFE 2": "Circa 6 uur (bij 21°C) *"},
                de: {"Anzeige": "OLED 1024 × 768 Pixel", "Batterie": "2x CR123", "Batterie mit erweitertem Batteriefach, Lahoux BFE 2)": "2x 16650", "Batterielaufzeit mit Lahoux BFE 2": "Etwa 6 Stunden (bei 21°C) *"}
            },
            Operating: {
                en: {"Watertight": "IP67", "Dimensions": "178 x 58 x 62 mm", "Weight": "<0.45 kg"},
                nl: {"Waterdichtheid": "IP67", "Afmetingen": "178 x 58 x 62 mm", "Gewicht": "<0.45 kg"},
                de: {"Wasserdicht": "IP67", "Abmessungen": "178 x 58 x 62 mm", "Gewicht": "<0.45 kg"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: true, filterRiflescope: false}
    },
    {
		id: "wm20",
        orderID: "155",
        type: "thermal",
        title: {nl: "Lahoux Clip Elite 50V", en: "Lahoux Clip Elite 50V", de: "Lahoux Clip Elite 50V"},
        url: "Lahoux-Clip-Elite-50V",
        price: "3600.27",
        endOfLife: true,
        shortDes: { nl:"De Lahoux Clip Elite 50V kan zowel als handkijker als als voorzet (dual-use) worden gebruikt. In combinatie met zijn lage prijs resulteert dit in een onverslaanbare prijs-prestatieverhouding.", 
                    en: "The Lahoux Clip Elite 50V can be used both as a handheld device and as a dual use attachment. In combination with its low price, this results in an unbeatable price-performance ratio.", 
                    de: "Das Lahoux Clip Elite 50V ist sowohl als Handgerät wie auch als Vorsatzgerät (Dual Use) nutzbar. In Kombination mit seinem günstigen Preis ergibt sich hieraus ein unschlagbares Preis-Leistungs-Verhältnis."},
        longDes_1: {    nl:"De Lahoux Clip Elite 50V kan zowel als handkijker als als voorzet (dual-use) worden gebruikt. In combinatie met zijn lage prijs resulteert dit in een onverslaanbare prijs-prestatieverhouding. Door de mogelijkheid tot snelle montage en demontage van het opzetstuk kunt u het potentieel van de Lahoux Clip Elite 50V volledig benutten. Het compacte formaat en het lage gewicht zorgen voor een ontspannen jacht. Of het nu tijdens de bers is of bij het schieten, de Lahoux Clip Elite 50V voldoet aan alle eisen. Het hoge detectiebereik van meer dan een kilometer en het grote, superscherpe 1024 × 768 OLED-scherm maken het mogelijk om aan elke jachtsituatie te voldoen. Of het nu bij de voederplaats is of op het beschadigde gebied in maïs, de Lahoux Clip Elite 50V maakt jagen op de juiste manier mogelijk, zelfs bij slechte weersomstandigheden, zoals nieuwe maan of mist..", 
                        en: "The Lahoux Clip Elite 50V can be used both as a handheld device and as a dual use attachment. In combination with its low price, this results in an unbeatable price-performance ratio. The possibility of quick mounting and dismounting of the attachment allows you to fully exploit the potential of the Lahoux Clip Elite 50V. Its compact size and low weight allow for relaxed hunting. Whether during a stalk or when firing a shot, the Lahoux Clip Elite 50V will meet every requirement. The high detection range of more than one kilometre and the large, super sharp 1024×768 OLED display allow to meet every hunting situation. Whether at the feeding place, or on the damaged area in the corn, the Lahoux Clip Elite 50V enables hunting in the right way even in bad weather conditions, such as new moon or fog.", 
                         de: "Das Lahoux Clip Elite 50V ist sowohl als Handgerät wie auch als Vorsatzgerät (Dual Use) nutzbar. In Kombination mit seinem günstigen Preis ergibt sich hieraus ein unschlagbares Preis-Leistungs-Verhältnis. Die Möglichkeit der schnellen Montage und Demontage des Vorsatzes gestatten hier die Potenziale das Lahoux Clip Elite 50V voll auszuschöpfen. Seine kompakte Größe und sein geringes Gewicht erlauben ein entspanntes Jagen. Ob nun auf der Pirsch oder bei der Schussabgabe, das Lahoux Clip Elite 50V wird jeder Anforderung gerecht. Die hohe Detektionsreichweite von über einem Kilometer und das große, superscharfe 1024×768 OLED Display erlauben es jeder jagdlichen Situation gerecht zu werden. Ob nun an der Kirrung, oder auf der Schadensfläche im Mais, das Lahoux Clip Elite 50V ermöglicht auch bei schlechten Witterungsbedingten, wie Neumond oder Nebel ein waidgerechtes Jagen."},
        longDes_2: {    nl:"De nieuwe Lahoux Clip Elite 50V is gebaseerd op de Lahoux Clip 42, maar heeft een 12µm pixel pitch detector en een 50 mm lens, die zorgt voor maximale scherpte en contrast bij een hoger detectiebereik. Het 50 mm-objectief kan tot 8,5x worden vergroot met behulp van daglichtoptiek (richtkijker, verrekijker enz.). Het aangepaste display verhoogt de levensduur van de batterij met 25% ten opzichte van de Lahoux Clip 50V. Met deze parameters, zoals een gezichtsveld van 8,8 ° x 7,0 °, is de Lahoux Clip Elite 50V een allrounder, die de hoge eisen van de veld- en bosjacht combineert. Dit maakt de Lahoux Clip Elite 50V tot de optimale warmtebeeldkijker voor prijsbewuste jagers!", 
                        en: "The new Lahoux Clip Elite 50V is based on the Lahoux Clip 42, but has a 12µm pixel pitch detector and a 50mm lens, which ensures maximum sharpness and contrast at a higher detection range. The 50 mm lens can be magnified up to 8.5x by daylight optics (rifle scope, binoculars etc.).  The modified display increases the battery life by 25% compared to the Lahoux Clip 50V. With these parameters, such as a field of view of 8,8 ° x 7,0 °, the Lahoux Clip Elite 50V is an allrounder, which combines the high demands of field and forest hunting. This makes the Lahoux Clip Elite 50V the optimal thermal imaging device for price-conscious hunters!", 
                        de: "Das neue Lahoux Clip Elite 50V basiert auf dem Lahoux Clip 42, verfügt jedoch über einen 12µm Pixel Pitch Detektor und ein 50 mm Objektiv, welche maximale Schärfe und größtmöglicher Kontrast bei gleichzeitig höherer Detektionsreichweite gewährleisten. Das 50 mm Objektiv kann durch die Tageslichtoptik (Zielfernrohr, Fernglas etc.) bis zu 8,5-fach vergrößert werden. Durch das modifizierte Display, die Akkulaufzeit um 25%, gegenüber dem Lahoux Clip 50V, erhöht werden. Mit diesen Parametern, wie einem gegenüber dem Lahoux Clip 50V noch mal vergrößerten Sichtfeld von 8,8 ° x 7,0 °, ist das Lahoux Clip Elite 50V, ein Allrounder, welcher sowohl die hohen Ansprüche der Feldjagd, wie auch der Waldjagd in sich vereint. Damit ist das Lahoux Clip Elite 50V das optimale Wärmebildgerät für preisbewusste Jäger!"},
        pros: {     nl:["4 kleurmodi: wit-heet, zwart-heet, rood-heet/hot spot, pseudokleur", "Geïntegreerd: bluetooth, kompas en bewegingssensor", "Bluetooth-afstandsbediening", "Geschikt voor standaardkalibers" ], 
                    en: ["4 colour modes: white-hot, black-hot, red-hot / hot spot, pseudo colour", "Integrated: bluetooth, compass and motion sensor", "Bluetooth remote control", "Suitable for standard calibres"], 
                    de: ["4 Bildmodi: weiß-heiß/grün, schwarz-heiß, rot-heiß / hot spot, pseudofarbe", "Integriert: Bluetooth, Kompass und Bewegungssensor", "Bluetooth Fernbedienung", "Geeignet für Standardkaliber"]

        },
        number: "02-0006-02908",
        ean: "6090301530522",
        leveromvang: [4, 13, 3, 1, 8, 22, 14],
        flyer: {nl:"Lahoux_Clip_serie_NL.pdf", en: "Lahoux_Clip_serie_EN.pdf", de: "Lahoux_Clip_serie_DE.pdf"},
        images: ["wm20_1.png", "wm20_2.png", "wm20_3.png", "wm20_4.png"],
        dri: "Warmtebeeld_Voorzetten.png",
        related: ["wm1", "wm8"],
        accessories: ["as6", "as10", "as11"],
        footnote_battery: true,
        techs: { 
            Detector: { 
                en: {"Resolution": "640 × 512 Pixel", "Pixel pitch": "12 µm", "Sensitivity (NETD)": "< 50 mK"},
                nl: {"Resolutie": "640 × 512 Pixel", "Pixelafstand": "12 µm", "Gevoeligheid (NETD)": "< 50 mK"},
                de: {"Auflösung": "640 × 512 Pixel", "Pixelabstand": "12 µm", "Empfindlichkeit (NETD)": "< 50 mK"}
            },
            Optics: {
                en: {"Lens": "50 mm", "Relative aperture": "F/1.2", "Field of view": "8.7°x7.0°", "Zoom (digital)": "1×"},
                nl: {"Lens": "50 mm", "Relatieve lensopening": "F/1.2", "Gezichtsveld": "8.7°x7.0°", "Zoom (digital)": "1×"},
                de: {"Linse": "50 mm", "Relative Blende": "F/1.2", "Sichtfeld": "8.7°x7.0°", "Zoom (digital)": "1×"}
            },
            Electronics: {
                en: {"Display": "Super OLED 1024 × 768 Pixel", "Battery": "2x CR123 battery", "Battery (with extended battery compartment, Lahoux BFE 1)": "2x 16650", "Battery runtime with Lahoux BFE 1": "Circa 6 hours (at 21°C) *"},
                nl: {"Beeldscherm": "Super OLED 1024 × 768 Pixel", "Batterij": "2x CR123 battery", "Batterij (met verlengd batterijvak, Lahoux BFE 1)": "2x 16650", "Batterijduur met Lahoux BFE 1": "Circa 6 uur (bij 21°C) *"},
                de: {"Anzeige": "Super OLED 1024 × 768 Pixel", "Batterie": "2x CR123 battery", "Batterie mit erweitertem Batteriefach, Lahoux BFE 1)": "2x 16650", "Batterielaufzeit mit Lahoux BFE 1": "Etwa 6 Stunden (bei 21°C) *"}
            },
            Operating: {
                en: {"Watertight": "IP66", "Dimensions": "166 × 60 × 68 mm", "Weight": "0.42 kg"},
                nl: {"Waterdichtheid": "IP66", "Afmetingen": "166 × 60 × 68 mm", "Gewicht": "0.42 kg"},
                de: {"Wasserdicht": "IP66", "Abmessungen": "166 × 60 × 68 mm", "Weight": "0.42 kg"}
            }
        }
	},

    {
		id: "wm38",
        orderID: "155",
        type: "thermal",
        title: {nl: "Lahoux Clip Elite 50W", en: "Lahoux Clip Elite 50W", de: "Lahoux Clip Elite 50W"},
        url: "Lahoux-Clip-Elite-50W",
        price: "3401.36",
        new: false,
        endOfLife: true,
        shortDes: { nl:"De Lahoux Clip Elite 50W kan zowel als handkijker als als voorzet (dual-use) worden gebruikt. In combinatie met zijn lage prijs resulteert dit in een onverslaanbare prijs-prestatieverhouding.", 
                    en: "The Lahoux Clip Elite 50W can be used both as a handheld device and as a dual use attachment. In combination with its low price, this results in an unbeatable price-performance ratio.", 
                    de: "Das Lahoux Clip Elite 50W ist sowohl als Handgerät wie auch als Vorsatzgerät (Dual Use) nutzbar. In Kombination mit seinem günstigen Preis ergibt sich hieraus ein unschlagbares Preis-Leistungs-Verhältnis."},
        longDes_1: {    nl:"De Lahoux Clip Elite 50W kan zowel als handkijker als als voorzet (dual-use) worden gebruikt. In combinatie met zijn lage prijs resulteert dit in een onverslaanbare prijs-prestatieverhouding. Door de mogelijkheid tot snelle montage en demontage van het opzetstuk kunt u het potentieel van de Lahoux Clip Elite 50W volledig benutten. Het compacte formaat, wifi en het lage gewicht zorgen voor een ontspannen jacht. Of het nu tijdens de bers is of bij het schieten, de Lahoux Clip Elite 50W voldoet aan alle eisen. Het hoge detectiebereik van meer dan een kilometer en het grote, superscherpe 1024 × 768 OLED-scherm maken het mogelijk om aan elke jachtsituatie te voldoen. Of het nu bij de voederplaats is of op het beschadigde gebied in maïs, de Lahoux Clip Elite 50W maakt jagen op de juiste manier mogelijk, zelfs bij slechte weersomstandigheden, zoals nieuwe maan of mist..", 
                        en: "The Lahoux Clip Elite 50W can be used both as a handheld device and as a dual use attachment. In combination with its low price, this results in an unbeatable price-performance ratio. The possibility of quick mounting and dismounting of the attachment allows you to fully exploit the potential of the Lahoux Clip Elite 50W. Its compact size, wifi and low weight allow for relaxed hunting. Whether during a stalk or when firing a shot, the Lahoux Clip Elite 50W will meet every requirement. The high detection range of more than one kilometer and the large, super sharp 1024×768 OLED display allow to meet every hunting situation. Whether at the feeding place, or on the damaged area in the corn, the Lahoux Clip Elite 50W enables hunting in the right way even in bad weather conditions, such as new moon or fog.", 
                         de: "Das Lahoux Clip Elite 50W ist sowohl als Handgerät wie auch als Vorsatzgerät (Dual Use) nutzbar. In Kombination mit seinem günstigen Preis ergibt sich hieraus ein unschlagbares Preis-Leistungs-Verhältnis. Die Möglichkeit der schnellen Montage und Demontage des Vorsatzes gestatten hier die Potenziale das Lahoux Clip Elite 50W voll auszuschöpfen. Seine kompakte Größe, wifi und sein geringes Gewicht erlauben ein entspanntes Jagen. Ob nun auf der Pirsch oder bei der Schussabgabe, das Lahoux Clip Elite 50W wird jeder Anforderung gerecht. Die hohe Detektionsreichweite von über einem Kilometer und das große, superscharfe 1024×768 OLED Display erlauben es jeder jagdlichen Situation gerecht zu werden. Ob nun an der Kirrung, oder auf der Schadensfläche im Mais, das Lahoux Clip Elite 50W ermöglicht auch bei schlechten Witterungsbedingten, wie Neumond oder Nebel ein waidgerechtes Jagen."},
        longDes_2: {    nl:"De nieuwe Lahoux Clip Elite 50W is gebaseerd op de Lahoux Clip 42, maar heeft een 12µm pixel pitch detector en een 50 mm lens, die zorgt voor maximale scherpte en contrast bij een hoger detectiebereik. Het 50 mm-objectief kan tot 8,5x worden vergroot met behulp van daglichtoptiek (richtkijker, verrekijker enz.). Het aangepaste display verhoogt de levensduur van de batterij met 25% ten opzichte van de Lahoux Clip 50V. Met deze parameters, zoals een gezichtsveld van 8.7°x7.0°, is de Lahoux Clip Elite 50W een allrounder, die de hoge eisen van de veld- en bosjacht combineert. Dit maakt de Lahoux Clip Elite 50W tot de optimale warmtebeeldkijker voor prijsbewuste jagers!", 
                        en: "The new Lahoux Clip Elite 50W is based on the Lahoux Clip 42, but has a 12µm pixel pitch detector and a 50mm lens, which ensures maximum sharpness and contrast at a higher detection range. The 50 mm lens can be magnified up to 8.5x by daylight optics (rifle scope, binoculars etc.).  The modified display increases the battery life by 25% compared to the Lahoux Clip 50V. With these parameters, such as a field of view of 8.7°x7.0°, the Lahoux Clip Elite 50W is an allrounder, which combines the high demands of field and forest hunting. This makes the Lahoux Clip Elite 50W the optimal thermal imaging device for price-conscious hunters!", 
                        de: "Das neue Lahoux Clip Elite 50W basiert auf dem Lahoux Clip 42, verfügt jedoch über einen 12µm Pixel Pitch Detektor und ein 50 mm Objektiv, welche maximale Schärfe und größtmöglicher Kontrast bei gleichzeitig höherer Detektionsreichweite gewährleisten. Das 50 mm Objektiv kann durch die Tageslichtoptik (Zielfernrohr, Fernglas etc.) bis zu 8,5-fach vergrößert werden. Durch das modifizierte Display, die Akkulaufzeit um 25%, gegenüber dem Lahoux Clip 50V, erhöht werden. Mit diesen Parametern, wie einem gegenüber dem Lahoux Clip 50V noch mal vergrößerten Sichtfeld von 8.7°x7.0°, ist das Lahoux Clip Elite 50W, ein Allrounder, welcher sowohl die hohen Ansprüche der Feldjagd, wie auch der Waldjagd in sich vereint. Damit ist das Lahoux Clip Elite 50W das optimale Wärmebildgerät für preisbewusste Jäger!"},
        pros: {     nl:["4 kleurmodi: wit-heet, zwart-heet, rood-heet/hot spot, pseudokleur", "Geïntegreerd: bluetooth, kompas en bewegingssensor", "Bluetooth-afstandsbediening", "Wifi", "Geschikt voor standaardkalibers" ], 
                    en: ["4 colour modes: white-hot, black-hot, red-hot / hot spot, pseudo colour", "Integrated: bluetooth, compass and motion sensor", "Bluetooth remote control", "Wifi", "Suitable for standard calibres"], 
                    de: ["4 Bildmodi: weiß-heiß/grün, schwarz-heiß, rot-heiß / hot spot, pseudofarbe", "Integriert: Bluetooth, Kompass und Bewegungssensor", "Bluetooth Fernbedienung", "Wifi", "Geeignet für Standardkaliber"]

        },
        number: "02-0006-03645",
        ean: "6090301883864",
        leveromvang: [4, 13, 3, 1, 8, 22, 14],
        flyer: {nl:"Lahoux_Clip_serie_NL.pdf", en: "Lahoux_Clip_serie_EN.pdf", de: "Lahoux_Clip_serie_DE.pdf"},
        images: ["Lahoux-Clip-Elite-50W.png"],
        footnote_battery: true,
        techs: { 
            Detector: { 
                en: {"Resolution": "640 × 512 Pixel", "Pixel pitch": "12 µm", "Sensitivity (NETD)": "< 50 mK"},
                nl: {"Resolutie": "640 × 512 Pixel", "Pixelafstand": "12 µm", "Gevoeligheid (NETD)": "< 50 mK"},
                de: {"Auflösung": "640 × 512 Pixel", "Pixelabstand": "12 µm", "Empfindlichkeit (NETD)": "< 50 mK"}
            },
            Optics: {
                en: {"Lens": "50 mm", "Relative aperture": "F/1.2", "Field of view": "8.7°x7.0°", "Zoom (digital)": "1×"},
                nl: {"Lens": "50 mm", "Relatieve lensopening": "F/1.2", "Gezichtsveld": "8.7°x7.0°", "Zoom (digital)": "1×"},
                de: {"Linse": "50 mm", "Relative Blende": "F/1.2", "Sichtfeld": "8.7°x7.0°", "Zoom (digital)": "1×"}
            },
            Electronics: {
                en: {"Display": "Super OLED 1024 × 768 Pixel", "Wifi": "√", "Battery": "2x CR123 battery", "Battery (with extended battery compartment, Lahoux BFE 1)": "2x 16650", "Battery runtime with Lahoux BFE 1": "Circa 6 hours (at 21°C) *"},
                nl: {"Beeldscherm": "Super OLED 1024 × 768 Pixel", "Wifi": "√", "Batterij": "2x CR123 batterij", "Batterij (met verlengd batterijvak, Lahoux BFE 1)": "2x 16650", "Batterijduur met Lahoux BFE 1": "Circa 6 uur (bij 21°C) *"},
                de: {"Anzeige": "Super OLED 1024 × 768 Pixel", "Wifi": "√", "Batterie": "2x CR123 batterie", "Batterie mit erweitertem Batteriefach, Lahoux BFE 1)": "2x 16650", "Batterielaufzeit mit Lahoux BFE 1": "Etwa 6 Stunden (bei 21°C) *"}
            },
            Operating: {
                en: {"Watertight": "IP66", "Dimensions": "166 × 60 × 68 mm", "Weight": "0.42 kg"},
                nl: {"Waterdichtheid": "IP66", "Afmetingen": "166 × 60 × 68 mm", "Gewicht": "0.42 kg"},
                de: {"Wasserdicht": "IP66", "Abmessungen": "166 × 60 × 68 mm", "Gewicht": "0.42 kg"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: true, filterRiflescope: false}
	},

    {
		id: "wm45",
        orderID: "132",
        type: "thermal",
        title: {nl: "Lahoux Clip 35 V2", en: "Lahoux Clip 35 V2", de: "Lahoux Clip 35 V2"},
        url: "Lahoux-Clip-35V2",
        price: "1641.38",
        new: false,
        //available_date: {nl: "Binnenkort verkrijgbaar", en: "Available soon", de: "Bald verfügbar"},
        shortDes: { nl: "De Lahoux Clip 35 V2 is voorzien van een hoge resolutie warmtebeelddetector en biedt een beeld van uitzonderlijke kwaliteit met een hoog contrast en rijke details. De Lahoux Clip 35 V2 is licht in gewicht en heeft een bescheiden formaat.", 
                    en: "Featuring a high-resolution thermal imaging detector, the Lahoux Clip 35 V2 offers an image of exceptional quality with high contrast and rich detail. The Lahoux Clip 35 V2 is lightweight and modest in size.", 
                    de: "Ausgestattet mit einem hochauflösenden Wärmebilddetektor bietet die Lahoux Clip 35 V2 Bilder von außergewöhnlicher Qualität mit hohem Kontrast und Detailreichtum. Die Lahoux Clip 35 V2 ist leicht und von bescheidener Größe."},
        longDes_1: {    nl: "De Lahoux Clip 35 V2 is voorzien van een hoge resolutie warmtebeelddetector en biedt een beeld van uitzonderlijke kwaliteit met een hoog contrast en rijke details. De Lahoux Clip 35 V2 is licht in gewicht en heeft een bescheiden formaat.", 
                        en: "Featuring a high-resolution thermal imaging detector, the Lahoux Clip 35 V2 offers image of exceptional quality with high contrast and rich detail. The Lahoux Clip 35 V2 is lightweight and modest in size.", 
                        de: "Ausgestattet mit einem hochauflösenden Wärmebilddetektor bietet die Lahoux Clip 35 V2 Bilder von außergewöhnlicher Qualität mit hohem Kontrast und Detailreichtum. Die Lahoux Clip 35 V2 ist leicht und von bescheidener Größe."},
        longDes_2: {    nl: "", 
                        en: "", 
                        de: ""},
        pros: {     nl: ["Zes kleurmodi: Wit Heet, Zwart Heet, Rood Heet, Groen Heet, IJzerrood, Blauw Heet", "IP67 waterdicht geclassificeerd", "Eenvoudige montage"], 
                    en: ["Six color modes: White Hot, Black Hot, Red Hot, Green Hot, Iron Red, Blue Hot", "IP67 waterproof-rated", "Easy installation "], 
                    de: ["Sechs Farbmodi: Weiß heiß, Schwarz heiß, Rot heiß, Grün heiß, Eisenrot, Blau heiß", "Wasserdicht nach IP67", "Einfache Installation"]

        },
        number: "02-0006-03773",
        ean: "",
        images: ["Lahoux-Clip-35-V2-1.png", "Lahoux-Clip-35-V2-2.png", "LLahoux-Clip-35-V2-3.png"],
        footnote_battery: true,
        techs: { 
            Detector: { 
                en: {"Resolution": "384 × 288 px", "Pixel pitch": "17 µm", "Sensitivity (NETD)": "≤40 mk", "Frame rate, Hz": "50"},
                nl: {"Resolutie": "384 × 288 px", "Pixelafstand": "17 µm", "Gevoeligheid (NETD)": "≤40 mk", "Beeldfrequentie, Hz": "50"},
                de: {"Auflösung": "384 × 288 px", "Pixelabstand": "17 µm", "Empfindlichkeit (NETD)": "≤40 mk", "Bildfrequenz, Hz": "50"}
            },
            Optics: {
                en: {"Lens": "35 mm", "Relative aperture": "F/1.2", "Field of view": "10.7°×8°", "Zoom (digital)": "1×, 2x, 4x"},
                nl: {"Lens": "35 mm", "Relatieve lensopening": "F/1.2", "Gezichtsveld": "10.7°×8°", "Zoom (digital)": "1×, 2x, 4x"},
                de: {"Linse": "35 mm", "Relative Blende": "F/1.2", "Sichtfeld": "10.7°×8°", "Zoom (digital)": "1×, 2x, 4x"}
            },
            Electronics: {
                en: {"Display": "OLED 1024 × 768 px", "Battery": "18650 battery"},
                nl: {"Beeldscherm": "OLED 1024 × 768 px", "Batterij": "18650 batterij"},
                de: {"Anzeige": "OLED 1024 × 768 px", "Batterie": "18650 batterie"}
            },
            Operating: {
                en: {"Encapsulation": "IP67", "Dimensions": "178×62×83 mm", "Weight": "550 g"},
                nl: {"Waterdichtheid": "IP67", "Afmetingen": "178×62×83 mm", "Gewicht": "550 g"},
                de: {"Wasserdicht": "IP67", "Abmessungen": "178×62×83 mm", "Gewicht": "550 g"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: true, filterRiflescope: false}
	},

    {
		id: "wm33",
        orderID: "136",
        type: "thermal",
        title: {nl: "Lahoux Clip 50 V2", en: "Lahoux Clip 50 V2", de: "Lahoux Clip 50 V2"},
        url: "Lahoux-Clip-50V2",
        price: "1779.31",
        new: false,
        //available_date: {nl: "Binnenkort verkrijgbaar", en: "Available soon", de: "Bald verfügbar"},
        shortDes: { nl: "De Lahoux Clip 50 V2 is voorzien van een hoge resolutie warmtebeelddetector en biedt een beeld van uitzonderlijke kwaliteit met een hoog contrast en rijke details. De Lahoux Clip 50V2 is licht in gewicht en heeft een bescheiden formaat.", 
                    en: "Featuring a high-resolution thermal imaging detector, the Lahoux Clip 50 V2 offers an image of exceptional quality with high contrast and rich detail. The Lahoux Clip 50V2 is lightweight and modest in size.", 
                    de: "Ausgestattet mit einem hochauflösenden Wärmebilddetektor bietet die Lahoux Clip 50 V2 Bilder von außergewöhnlicher Qualität mit hohem Kontrast und Detailreichtum. Die Lahoux Clip 50V2 ist leicht und von bescheidener Größe."},
        longDes_1: {    nl: "De Lahoux Clip 50 V2 is voorzien van een hoge resolutie warmtebeelddetector en biedt een beeld van uitzonderlijke kwaliteit met een hoog contrast en rijke details. De Lahoux Clip 50V2 is licht in gewicht en heeft een bescheiden formaat.", 
                        en: "Featuring a high-resolution thermal imaging detector, the Lahoux Clip 50 V2 offers image of exceptional quality with high contrast and rich detail. The Lahoux Clip 50V2 is lightweight and modest in size.", 
                        de: "Ausgestattet mit einem hochauflösenden Wärmebilddetektor bietet die Lahoux Clip 50 V2 Bilder von außergewöhnlicher Qualität mit hohem Kontrast und Detailreichtum. Die Lahoux Clip 50V2 ist leicht und von bescheidener Größe."},
        longDes_2: {    nl: "", 
                        en: "", 
                        de: ""},
        pros: {     nl: ["Zes kleurmodi: Wit Heet, Zwart Heet, Rood Heet, Groen Heet, IJzerrood, Blauw Heet", "IP67 waterdicht geclassificeerd", "Eenvoudige montage"], 
                    en: ["Six color modes: White Hot, Black Hot, Red Hot, Green Hot, Iron Red, Blue Hot", "IP67 waterproof-rated", "Easy installation "], 
                    de: ["Sechs Farbmodi: Weiß heiß, Schwarz heiß, Rot heiß, Grün heiß, Eisenrot, Blau heiß", "Wasserdicht nach IP67", "Einfache Installation"]

        },
        number: "02-0006-03504",
        ean: "",
        flyer: {nl:"Lahoux_Clip_serie_NL.pdf", en: "Lahoux_Clip_serie_EN.pdf", de: "Lahoux_Clip_serie_DE.pdf"},
        images: ["Lahoux-Clip-50V2.png", "Lahoux-Clip-50V2-2.png", "Lahoux-Clip-50V2-3.png", "Lahoux-Clip-50V2-4.png"],
        footnote_battery: true,
        techs: { 
            Detector: { 
                en: {"Resolution": "384 × 288 px", "Pixel pitch": "17 µm", "Sensitivity (NETD)": "≤40 mk", "Frame rate, Hz": "50"},
                nl: {"Resolutie": "384 × 288 px", "Pixelafstand": "17 µm", "Gevoeligheid (NETD)": "≤40 mk", "Beeldfrequentie, Hz": "50"},
                de: {"Auflösung": "384 × 288 px", "Pixelabstand": "17 µm", "Empfindlichkeit (NETD)": "≤40 mk", "Bildfrequenz, Hz": "50"}
            },
            Optics: {
                en: {"Lens": "50 mm", "Relative aperture": "F/1.2", "Field of view": "7.5°x5.6°", "Zoom (digital)": "1×, 2x, 4x"},
                nl: {"Lens": "50 mm", "Relatieve lensopening": "F/1.2", "Gezichtsveld": "7.5°x5.6°", "Zoom (digital)": "1×, 2x, 4x"},
                de: {"Linse": "50 mm", "Relative Blende": "F/1.2", "Sichtfeld": "7.5°x5.6°", "Zoom (digital)": "1×, 2x, 4x"}
            },
            Electronics: {
                en: {"Display": "OLED 1024 × 768 px", "Battery": "18650 battery"},
                nl: {"Beeldscherm": "OLED 1024 × 768 px", "Batterij": "18650 batterij"},
                de: {"Anzeige": "OLED 1024 × 768 px", "Batterie": "18650 batterie"}
            },
            Operating: {
                en: {"Encapsulation": "IP67", "Dimensions": "178 × 62 × 83 mm", "Weight": "550 g"},
                nl: {"Waterdichtheid": "IP67", "Afmetingen": "178 × 62 × 83 mm", "Gewicht": "550 g"},
                de: {"Wasserdicht": "IP67", "Abmessungen": "178 × 62 × 83 mm", "Gewicht": "550 g"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: true, filterRiflescope: false}
	},    

    {
		id: "wm34",
        orderID: "137",
        type: "thermal",
        title: {nl: "Lahoux Clip Elite 50 V2", en: "Lahoux Clip Elite 50 V2", de: "Lahoux Clip Elite 50 V2"},
        url: "Lahoux-Clip-Elite-50V2",
        price: "2707.14",
        new: false,
        //available_date: {nl: "Binnenkort verkrijgbaar", en: "Available soon", de: "Bald verfügbar"},
        shortDes: { nl: "De Lahoux Clip Elite 50 V2 is voorzien van een hoge resolutie warmtebeelddetector en biedt een beeld van uitzonderlijke kwaliteit met een hoog contrast en rijke details. De Lahoux Clip Elite 50V2 is licht in gewicht en heeft een bescheiden formaat.", 
                    en: "Featuring a high-resolution thermal imaging detector, the Lahoux Clip Elite 50 V2 offers an image of exceptional quality with high contrast and rich detail. The Lahoux Clip Elite 50V2 is lightweight and modest in size.", 
                    de: "Ausgestattet mit einem hochauflösenden Wärmebilddetektor bietet die Lahoux Clip Elite 50 V2 Bilder von außergewöhnlicher Qualität mit hohem Kontrast und Detailreichtum. Die Lahoux Clip Elite 50V2 ist leicht und von bescheidener Größe."},
        longDes_1: {    nl: "De Lahoux Clip Elite 50 V2 is voorzien van een hoge resolutie warmtebeelddetector en biedt een beeld van uitzonderlijke kwaliteit met een hoog contrast en rijke details. De Lahoux Clip Elite 50V2 is licht in gewicht en heeft een bescheiden formaat.", 
                        en: "Featuring a high-resolution thermal imaging detector, the Lahoux Clip Elite 50 V2 offers image of exceptional quality with high contrast and rich detail. The Lahoux Clip Elite 50V2 is lightweight and modest in size.", 
                        de: "Ausgestattet mit einem hochauflösenden Wärmebilddetektor bietet die Lahoux Clip Elite 50 V2 Bilder von außergewöhnlicher Qualität mit hohem Kontrast und Detailreichtum. Die Lahoux Clip Elite 50V2 ist leicht und von bescheidener Größe."},
        longDes_2: {    nl: "", 
                        en: "", 
                        de: ""},
        pros: {     nl: ["Zes kleurmodi: Wit Heet, Zwart Heet, Rood Heet, Groen Heet, IJzerrood, Blauw Heet", "IP67 waterdicht geclassificeerd", "Eenvoudige montage"], 
                    en: ["Six color modes: White Hot, Black Hot, Red Hot, Green Hot, Iron Red, Blue Hot", "IP67 waterproof-rated", "Easy installation "], 
                    de: ["Sechs Farbmodi: Weiß heiß, Schwarz heiß, Rot heiß, Grün heiß, Eisenrot, Blau heiß", "Wasserdicht nach IP67", "Einfache Installation"]

        },
        number: "02-0006-03505",
        ean: "",
        flyer: {nl:"Lahoux_Clip_serie_NL.pdf", en: "Lahoux_Clip_serie_EN.pdf", de: "Lahoux_Clip_serie_DE.pdf"},
        images: ["Lahoux-Clip-Elite-50V2.png", "Lahoux-Clip-Elite-50V2-2.png", "Lahoux-Clip-Elite-50V2-3.png", "Lahoux-Clip-Elite-50V2-4.png"],
        footnote_battery: true,
        techs: { 
            Detector: { 
                en: {"Resolution": "640 × 480 px", "Pixel pitch": "12 µm", "Sensitivity (NETD)": "≤40 mk", "Frame rate, Hz": "50"},
                nl: {"Resolutie": "640 × 480 px", "Pixelafstand": "12 µm", "Gevoeligheid (NETD)": "≤40 mk", "Beeldfrequentie, Hz": "50"},
                de: {"Auflösung": "640 × 480 px", "Pixelabstand": "12 µm", "Empfindlichkeit (NETD)": "≤40 mk", "Bildfrequenz, Hz": "50"}
            },
            Optics: {
                en: {"Lens": "50 mm", "Relative aperture": "F/1.0", "Field of view": "8.8°x6.6°", "Zoom (digital)": "1×, 2x, 4x, 8x"},
                nl: {"Lens": "50 mm", "Relatieve lensopening": "F/1.0", "Gezichtsveld": "8.8°x6.6°", "Zoom (digital)": "1×, 2x, 4x, 8x"},
                de: {"Linse": "50 mm", "Relative Blende": "F/1.0", "Sichtfeld": "8.8°x6.6°", "Zoom (digital)": "1×, 2x, 4x, 8x"}
            },
            Electronics: {
                en: {"Display": "OLED 800 × 600 px", "Battery": "18650 battery"},
                nl: {"Beeldscherm": "OLED 800 × 600 px", "Batterij": "18650 batterij"},
                de: {"Anzeige": "OLED 800 × 600 px", "Batterie": "18650 batterie"}
            },
            Operating: {
                en: {"Encapsulation": "IP67", "Dimensions": "177 × 66 × 87 mm", "Weight": "≤660 g"},
                nl: {"Waterdichtheid": "IP67", "Afmetingen": "177 × 66 × 87 mm", "Gewicht": "≤660 g"},
                de: {"Wasserdicht": "IP67", "Abmessungen": "177 × 66 × 87 mm", "Gewicht": "≤660 g"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: true, filterRiflescope: false}
	},

    {
		id: "wm35",
        orderID: "140",
        type: "thermal",
        title: {nl: "Lahoux Sight 25", en: "Lahoux Sight 25", de: "Lahoux Sight 25"},
        url: "Lahoux-Sight-25",
        price: "2321.43",
        new: false,
        //available_date: {nl: "Binnenkort verkrijgbaar", en: "Available soon", de: "Bald verfügbar"},
        shortDes: { nl: "De Lahoux Sight 25 is een richtkijker met warmtebeeldfunctie, die de eigenschappen van een uitstekende richtkijker combineert met die van een uitstekend warmtebeeldapparaat. De Lahoux Sight 25 heeft een 25 mm lens, een detectorresolutie van 400 x 300 px en een detectorafstand van 17 µm.", 
                    en: "The Lahoux Sight 25 is a riflescope with thermal imaging function, which combines the attributes of an excellent riflescope with those of an outstanding thermal imaging device. The Lahoux Sight 25 has a 25mm lens, a detector resolution of 400 x 300 px and a detector pitch of 17 µm.", 
                    de: "Das Lahoux Sight 25 ist ein Wärmebild-Zielfernrohr das die Vorteile einer exzellenten Zieloptik mit denen eines hervorragenden Wärmebildgerätes in sich vereint. Das Lahoux Sight 25 hat ein 25mm Objektiv, eine Detektorauflösung von 400 x 300 px und einen Detektorabstand von 17 µm."},
        longDes_1: {    nl: "De Lahoux Sight 25 is een richtkijker met warmtebeeldfunctie, die de eigenschappen van een uitstekende richtkijker combineert met die van een uitstekend warmtebeeldapparaat. De Lahoux Sight 25 heeft een 25 mm lens, een detectorresolutie van 400 x 300 px en een detectorafstand van 17 µm.", 
                        en: "The Lahoux Sight 25 is a riflescope with thermal imaging function, which combines the attributes of an excellent riflescope with those of an outstanding thermal imaging device. The Lahoux Sight 25 has a 25mm lens, a detector resolution of 400 x 300 px and a detector pitch of 17 µm.", 
                        de: "Das Lahoux Sight 25 ist ein Wärmebild-Zielfernrohr das die Vorteile einer exzellenten Zieloptik mit denen eines hervorragenden Wärmebildgerätes in sich vereint. Das Lahoux Sight 25 hat ein 25mm Objektiv, eine Detektorauflösung von 400 x 300 px und einen Detektorabstand von 17 µm."},
        longDes_2: {    nl: "", 
                        en: "", 
                        de: ""},
        pros: {     nl: ["OLED-scherm in kleur", "Zes kleurmodi: Wit Heet, Zwart Heet, Rood Heet, Groen Heet, IJzerrood, Blauw Heet", "Ontworpen voor professionele jagers", "Foto- en video-opnamen", "IP67 waterdicht geclassificeerd"], 
                    en: ["Full-color OLED display", "Six color modes: White Hot, Black Hot, Red Hot, Green Hot, Iron Red, Blue Hot", "Designed for Professional Hunters", "Photo and video recording", "IP67 waterproof-rated"], 
                    de: ["Vollfarbiges OLED-Display", "Sechs Farbmodi: Weiß heiß, Schwarz heiß, Rot heiß, Grün heiß, Eisenrot, Blau heiß", "Entworfen für professionelle Jäger", "Foto- und Videoaufnahmen", "Wasserdicht nach IP67"]
        },
        number: "02-0006-02966",
        ean: "",
        warning: true, 
        images: ["Lahoux-Sight-25.png", "Lahoux-Sight-25-2.png", "Lahoux-Sight-25-3.png", "Lahoux-Sight-25-4.png"],
        footnote_battery: true,
        endOfLife: false,        
        techs: { 
            Detector: { 
                en: {"Resolution": "400 x 300 px", "Pixel pitch": "17 µm", "Sensitivity (NETD)": "≤30 mk", "Frame rate": "50 Hz"},
                nl: {"Resolutie": "400 x 300 px", "Pixelafstand": "17 µm", "Gevoeligheid (NETD)": "≤30 mk", "Beeldfrequentie": "50 Hz"},
                de: {"Auflösung": "400 x 300 px", "Pixelabstand": "17 µm", "Empfindlichkeit (NETD)": "≤30 mk", "Bildfrequenz": "50 Hz"}
            },
            Optics: {
                en: {"Lens": "25 mm", "Field of view": "15.49°×11.65°", "Digital zoom": "1x - 4x"},
                nl: {"Lens": "25 mm", "Gezichtsveld": "15.49°×11.65°", "Digitale zoom": "1x - 4x"},
                de: {"Linse": "25 mm", "Sichtfeld": "15.49°×11.65°", "Digitaler Zoom": "1x - 4x"}
            },
            Electronics: {
                en: {"Display type": "OLED", "Display resolution": "1024 × 768 px", "Battery": "18650 battery (built-in) and customized battery (external)"},
                nl: {"Beeldschermtype": "OLED", "Beeldschermresolutie": "1024 × 768 px", "Batterij": "18650 batterij (ingebouwd) en aangepaste batterij (extern)"},
                de: {"Anzeigetyp": "OLED", "Anzeige Auflösung": "1024 × 768 px", "Batterie": "18650 batterie (eingebaut) und kundenspezifische Batterie (extern)"}
            },
            Operating: {
                en: {"Encapsulation": "IP67", "Internal memory": "16 GB", "Operating temperature range": "-30°C to 50°C", "Weight (without replaceable battery)": "≤930 g", "Dimensions": "367 × 107 × 69 mm", "USB-interface": "Type C, supporting power delivery, charging, and reading data from memory card"},
                nl: {"Waterdichtheid": "IP67", "Intern geheugen": "16 GB", "Bedrijfstemperatuurbereik": "-30°C to 50°C", "Gewicht (zonder vervangbare batterij)": "≤930 g", "Afmetingen": "367 × 107 × 69 mm", "USB-aansluiting": "Type C, ondersteunt voeding, opladen en lezen van gegevens van geheugenkaart"},
                de: {"Wasserdicht": "IP67", "Interne Speicher": "16 GB", "Betriebstemperaturbereich": "-30°C to 50°C", "Gewicht (ohne auswechselbare Batterie)": "≤930 g", "Abmessungen": "367 × 107 × 69 mm", "USB-Anschluss": "Typ C, unterstützt Stromzufuhr, Aufladen und Lesen von Daten von der Speicherkarte"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: false, filterVoorzet: false, filterRiflescope: true}
	},

    {
		id: "wm46",
        orderID: "140",
        type: "thermal",
        title: {nl: "Lahoux Sight 35 V2", en: "Lahoux Sight 35 V2", de: "Lahoux Sight 35 V2"},
        url: "Lahoux-Sight-35-V2",
        price: "2344.83",
        new: false,
        //available_date: {nl: "Binnenkort verkrijgbaar", en: "Available soon", de: "Bald verfügbar"},
        shortDes: { nl: "De Lahoux Sight 35 V2 is een richtkijker met warmtebeeldfunctie, die de eigenschappen van een uitstekende richtkijker combineert met die van een uitstekend warmtebeeldapparaat. De Lahoux Sight 35 V2 heeft een 35mm lens, een detectorresolutie van 400x300 px en een detectorafstand van 12 µm.", 
                    en: "The Lahoux Sight 35 V2 is a riflescope with thermal imaging function, which combines the attributes of an excellent riflescope with those of an outstanding thermal imaging device. The Lahoux Sight 35 V2 has a 35mm lens, a detector resolution of 400x300 px and a detector pitch of 12 µm.", 
                    de: "Das Lahoux Sight 35 V2 ist ein Wärmebild-Zielfernrohr das die Vorteile einer exzellenten Zieloptik mit denen eines hervorragenden Wärmebildgerätes in sich vereint. Das Lahoux Sight 35 V2 hat ein 35mm Objektiv, eine Detektorauflösung von 400x300 px und einen Detektorabstand von 12 µm."},
        longDes_1: {    nl: "De Lahoux Sight 35 V2 is een richtkijker met warmtebeeldfunctie, die de eigenschappen van een uitstekende richtkijker combineert met die van een uitstekend warmtebeeldapparaat. De Lahoux Sight 35 V2 heeft een 35mm lens, een detectorresolutie van 400x300 px en een detectorafstand van 12 µm.", 
                        en: "The Lahoux Sight 35 V2 is a riflescope with thermal imaging function, which combines the attributes of an excellent riflescope with those of an outstanding thermal imaging device. The Lahoux Sight 35 V2 has a 35mm lens, a detector resolution of 400x300 px and a detector pitch of 12 µm.", 
                        de: "Das Lahoux Sight 35 V2 ist ein Wärmebild-Zielfernrohr das die Vorteile einer exzellenten Zieloptik mit denen eines hervorragenden Wärmebildgerätes in sich vereint. Das Lahoux Sight 35 V2 hat ein 35mm Objektiv, eine Detektorauflösung von 400x300 px und einen Detektorabstand von 12 µm."},
        longDes_2: {    nl: "", 
                        en: "", 
                        de: ""},
        pros: {     nl: ["0.39’’ AMOLED display", "Zes kleurmodi: Wit Heet, Zwart Heet, instelbaar Rood Heet, Groen Heet, IJzerrood, Blauw Heet", "Ontworpen voor professionele jagers", "Foto- en video-opnamen", "IP67 waterdicht geclassificeerd"], 
                    en: ["0.39’’ AMOLED display", "Six color modes: White Hot, Black Hot, adjustable Red Hot, Green Hot, Iron Red, Blue Hot", "Designed for Professional Hunters", "Photo and video recording", "IP67 waterproof-rated"], 
                    de: ["0.39’’ AMOLED Display", "Sechs Farbmodi: Weiß heiß, Schwarz heiß, einstellbar Rot heiß, Grün heiß, Eisenrot, Blau heiß", "Entworfen für professionelle Jäger", "Foto- und Videoaufnahmen", "Wasserdicht nach IP67"]
        },
        number: "02-0006-03835",
        ean: "",
        warning: true, 
        images: ["Lahoux-Sight-35-V2.png", "Lahoux-Sight-35-V2-2.png", "Lahoux-Sight-35-V2-3.png", "Lahoux-Sight-35-V2-4.png"],
        footnote_battery: true,
        endOfLife: false,        
        techs: { 
            Detector: { 
                en: {"Resolution": "400x300 px", "Pixel pitch": "12 µm", "Sensitivity (NETD)": "≤30 mK", "Frame rate": "50 Hz"},
                nl: {"Resolutie": "400x300 px", "Pixelafstand": "12 µm", "Gevoeligheid (NETD)": "≤30 mK", "Beeldfrequentie": "50 Hz"},
                de: {"Auflösung": "400x300 px", "Pixelabstand": "12 µm", "Empfindlichkeit (NETD)": "≤30 mK", "Bildfrequenz": "50 Hz"}
            },
            Optics: {
                en: {"Lens": "35 mm", "Field of view": "7.8°×5.9°", "Optical zoom": "2.65x", "Digital zoom": "1x - 4x"},
                nl: {"Lens": "35 mm", "Gezichtsveld": "7.8°×5.9°", "Optische zoom": "2.65x", "Digitale zoom": "1x - 4x"},
                de: {"Linse": "35 mm", "Sichtfeld": "7.8°×5.9° ", "Optischer Zoom": "2.65x", "Digitaler Zoom": "1x - 4x"}
            },
            Electronics: {
                en: {"Display type": "0.39’’ AMOLED", "Display resolution": "1440×1080px", "Battery": "18650 battery (built-in) and customized battery (external)"},
                nl: {"Beeldschermtype": "0.39’’ AMOLED", "Beeldschermresolutie": "1440×1080px", "Batterij": "18650 batterij (ingebouwd) en aangepaste batterij (extern)"},
                de: {"Anzeigetyp": "0.39’’ AMOLED", "Anzeige Auflösung": "1440×1080px", "Batterie": "18650 batterie (eingebaut) und kundenspezifische Batterie (extern)"}
            },
            Operating: {
                en: {"Encapsulation": "IP67", "Internal memory": "128 GB", "Operating temperature range": "-30°C to 50°C", "Weight (without replaceable battery)": "≤990 g", "Dimensions": "≤377×107×69 mm", "USB-interface": "Type C, supporting power delivery, charging, and reading data from memory card"},
                nl: {"Waterdichtheid": "IP67", "Intern geheugen": "128 GB", "Bedrijfstemperatuurbereik": "-30°C to 50°C", "Gewicht (zonder vervangbare batterij)": "≤990 g", "Afmetingen": "≤377×107×69 mm", "USB-aansluiting": "Type C, ondersteunt voeding, opladen en lezen van gegevens van geheugenkaart"},
                de: {"Wasserdicht": "IP67", "Interne Speicher": "128 GB", "Betriebstemperaturbereich": "-30°C to 50°C", "Gewicht (ohne auswechselbare Batterie)": "≤939 g", "Abmessungen": "≤377×107×69 mm", "USB-Anschluss": "Typ C, unterstützt Stromzufuhr, Aufladen und Lesen von Daten von der Speicherkarte"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: false, filterVoorzet: false, filterRiflescope: true}
	},

    {
		id: "wm47",
        orderID: "142",
        type: "thermal",
        title: {nl: "Lahoux Sight 50", en: "Lahoux Sight 50", de: "Lahoux Sight 50"},
        url: "Lahoux-Sight-50",
        price: "2655.17",
        new: false,
        //available_date: {nl: "Binnenkort verkrijgbaar", en: "Available soon", de: "Bald verfügbar"},
        shortDes: { nl: "De Lahoux Sight 50 is een richtkijker met warmtebeeldfunctie, die de eigenschappen van een uitstekende richtkijker combineert met die van een uitstekend warmtebeeldapparaat. De Lahoux Sight 50 heeft een 50mm lens, een detectorresolutie van 400x300 px en een detectorafstand van 12 µm.", 
                    en: "The Lahoux Sight 50 is a riflescope with thermal imaging function, which combines the attributes of an excellent riflescope with those of an outstanding thermal imaging device. The Lahoux Sight 50 has a 50mm lens, a detector resolution of 400x300 px and a detector pitch of 12 µm.", 
                    de: "Das Lahoux Sight 50 ist ein Wärmebild-Zielfernrohr das die Vorteile einer exzellenten Zieloptik mit denen eines hervorragenden Wärmebildgerätes in sich vereint. Das Lahoux Sight 50 hat ein 50mm Objektiv, eine Detektorauflösung von 400x300 px und einen Detektorabstand von 12 µm."},
        longDes_1: {    nl: "De Lahoux Sight 50 is een richtkijker met warmtebeeldfunctie, die de eigenschappen van een uitstekende richtkijker combineert met die van een uitstekend warmtebeeldapparaat. De Lahoux Sight 50 heeft een 50 mm lens, een detectorresolutie van 400x300 px en een detectorafstand van 12 µm.", 
                        en: "The Lahoux Sight 50 is a riflescope with thermal imaging function, which combines the attributes of an excellent riflescope with those of an outstanding thermal imaging device. The Lahoux Sight 50 has a 50mm lens, a detector resolution of 400x300 px and a detector pitch of 12 µm.", 
                        de: "Das Lahoux Sight 50 ist ein Wärmebild-Zielfernrohr das die Vorteile einer exzellenten Zieloptik mit denen eines hervorragenden Wärmebildgerätes in sich vereint. Das Lahoux Sight 50 hat ein 50mm Objektiv, eine Detektorauflösung von 400x300 px und einen Detektorabstand von 12 µm."},
        longDes_2: {    nl: "", 
                        en: "", 
                        de: ""},
        pros: {     nl: ["0.39’’ AMOLED display", "Zes kleurmodi: Wit Heet, Zwart Heet, instelbaar Rood Heet, Groen Heet, IJzerrood, Blauw Heet", "Ontworpen voor professionele jagers", "Foto- en video-opnamen", "IP67 waterdicht geclassificeerd"], 
                    en: ["0.39’’ AMOLED display", "Six color modes: White Hot, Black Hot, adjustable Red Hot, Green Hot, Iron Red, Blue Hot", "Designed for Professional Hunters", "Photo and video recording", "IP67 waterproof-rated"], 
                    de: ["0.39’’ AMOLED Display", "Sechs Farbmodi: Weiß heiß, Schwarz heiß, einstellbar Rot heiß, Grün heiß, Eisenrot, Blau heiß", "Entworfen für professionelle Jäger", "Foto- und Videoaufnahmen", "Wasserdicht nach IP67"]
        },
        number: "02-0006-03837",
        ean: "",
        warning: true, 
        images: ["Lahoux-Sight-50.png", "Lahoux-Sight-50-2.png", "Lahoux-Sight-50-3.png", "Lahoux-Sight-50-4.png"],
        footnote_battery: true,
        endOfLife: false,        
        techs: { 
            Detector: { 
                en: {"Resolution": "400x300 px", "Pixel pitch": "12 µm", "Sensitivity (NETD)": "≤30 mK", "Frame rate": "50 Hz"},
                nl: {"Resolutie": "400x300 px", "Pixelafstand": "12 µm", "Gevoeligheid (NETD)": "≤30 mK", "Beeldfrequentie": "50 Hz"},
                de: {"Auflösung": "400x300 px", "Pixelabstand": "12 µm", "Empfindlichkeit (NETD)": "≤30 mK", "Bildfrequenz": "50 Hz"}
            },
            Optics: {
                en: {"Lens": "50 mm", "Field of view": "5.5°×4.1°", "Optical zoom": "3.78x", "Digital zoom": "1x - 4x"},
                nl: {"Lens": "50 mm", "Gezichtsveld": "5.5°×4.1°", "Optische zoom": "3.78x", "Digitale zoom": "1x - 4x"},
                de: {"Linse": "50 mm", "Sichtfeld": "5.5°×4.1°", "Optischer Zoom": "3.78x", "Digitaler Zoom": "1x - 4x"}
            },
            Electronics: {
                en: {"Display type": "0.39’’ AMOLED", "Display resolution": "1440×1080 px", "Battery": "18650 battery (built-in) and customized battery (external)"},
                nl: {"Beeldschermtype": "0.39’’ AMOLED", "Beeldschermresolutie": "1440×1080 px", "Batterij": "18650 batterij (ingebouwd) en aangepaste batterij (extern)"},
                de: {"Anzeigetyp": "0.39’’ AMOLED", "Anzeige Auflösung": "1440×1080 px", "Batterie": "18650 batterie (eingebaut) und kundenspezifische Batterie (extern)"}
            },
            Operating: {
                en: {"Encapsulation": "IP67", "Internal memory": "128 GB", "Operating temperature range": "-30°C to 50°C", "Weight (without replaceable battery)": "≤990 g", "Dimensions": "≤377×107×69 mm", "USB-interface": "Type C, supporting power delivery, charging, and reading data from memory card"},
                nl: {"Waterdichtheid": "IP67", "Intern geheugen": "128 GB", "Bedrijfstemperatuurbereik": "-30°C to 50°C", "Gewicht (zonder vervangbare batterij)": "≤990 g", "Afmetingen": "≤377×107×69 mm", "USB-aansluiting": "Type C, ondersteunt voeding, opladen en lezen van gegevens van geheugenkaart"},
                de: {"Wasserdicht": "IP67", "Interne Speicher": "128 GB", "Betriebstemperaturbereich": "-30°C to 50°C", "Gewicht (ohne auswechselbare Batterie)": "≤939 g", "Abmessungen": "≤377×107×69 mm", "USB-Anschluss": "Typ C, unterstützt Stromzufuhr, Aufladen und Lesen von Daten von der Speicherkarte"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: false, filterVoorzet: false, filterRiflescope: true}
	},

    {
		id: "wm48",
        orderID: "143",
        type: "thermal",
        title: {nl: "Lahoux Sight Elite 35", en: "Lahoux Sight Elite 35", de: "Lahoux Sight Elite 35"},
        url: "Lahoux-Sight-Elite-35",
        price: "3172.41",
        new: false,
        //available_date: {nl: "Binnenkort verkrijgbaar", en: "Available soon", de: "Bald verfügbar"},
        shortDes: { nl: "De Lahoux Sight Elite 35 is een richtkijker met warmtebeeldfunctie, die de eigenschappen van een uitstekende richtkijker combineert met die van een uitstekend warmtebeeldapparaat. De Lahoux Sight Elite 35 heeft een 35mm lens, een detectorresolutie van 640x480px en een detectorafstand van 12 µm.", 
                    en: "The Lahoux Sight Elite 35 is a riflescope with thermal imaging function, which combines the attributes of an excellent riflescope with those of an outstanding thermal imaging device. The Lahoux Sight Elite 35 has a 35mm lens, a detector resolution of 640x480pxx and a detector pitch of 12 µm.", 
                    de: "Das Lahoux Sight Elite 35 ist ein Wärmebild-Zielfernrohr das die Vorteile einer exzellenten Zieloptik mit denen eines hervorragenden Wärmebildgerätes in sich vereint. Das Lahoux Sight Elite 35 hat ein 35mm Objektiv, eine Detektorauflösung von 640x480px und einen Detektorabstand von 12 µm."},
        longDes_1: {    nl: "De Lahoux Sight Elite 35 is een richtkijker met warmtebeeldfunctie, die de eigenschappen van een uitstekende richtkijker combineert met die van een uitstekend warmtebeeldapparaat. De Lahoux Sight Elite 35 heeft een 35mm lens, een detectorresolutie van 640x480px en een detectorafstand van 12 µm.", 
                        en: "The Lahoux Sight Elite 35 is a riflescope with thermal imaging function, which combines the attributes of an excellent riflescope with those of an outstanding thermal imaging device. The Lahoux Sight Elite 35 has a 35mm lens, a detector resolution of 640x480px and a detector pitch of 12 µm.", 
                        de: "Das Lahoux Sight Elite 35 ist ein Wärmebild-Zielfernrohr das die Vorteile einer exzellenten Zieloptik mit denen eines hervorragenden Wärmebildgerätes in sich vereint. Das Lahoux Sight Elite 35 hat ein 35mm Objektiv, eine Detektorauflösung von 640x480px und einen Detektorabstand von 12 µm."},
        longDes_2: {    nl: "", 
                        en: "", 
                        de: ""},
        pros: {     nl: ["0.39’’ AMOLED display", "Zes kleurmodi: Wit Heet, Zwart Heet, instelbaar Rood Heet, Groen Heet, IJzerrood, Blauw Heet", "Ontworpen voor professionele jagers", "Foto- en video-opnamen", "IP67 waterdicht geclassificeerd"], 
                    en: ["0.39’’ AMOLED display", "Six color modes: White Hot, Black Hot, adjustable Red Hot, Green Hot, Iron Red, Blue Hot", "Designed for Professional Hunters", "Photo and video recording", "IP67 waterproof-rated"], 
                    de: ["0.39’’ AMOLED Display", "Sechs Farbmodi: Weiß heiß, Schwarz heiß, einstellbar Rot heiß, Grün heiß, Eisenrot, Blau heiß", "Entworfen für professionelle Jäger", "Foto- und Videoaufnahmen", "Wasserdicht nach IP67"]
        },
        number: "02-0006-03836",
        ean: "",
        warning: true, 
        images: ["Lahoux-Sight-Elite-35.png", "Lahoux-Sight-Elite-35-2.png", "Lahoux-Sight-Elite-35-3.png", "Lahoux-Sight-Elite-35-4.png"],
        footnote_battery: true,
        endOfLife: false,        
        techs: { 
            Detector: { 
                en: {"Resolution": "640x480px", "Pixel pitch": "12 µm", "Sensitivity (NETD)": "≤30 mK", "Frame rate": "50 Hz"},
                nl: {"Resolutie": "640x480px", "Pixelafstand": "12 µm", "Gevoeligheid (NETD)": "≤30 mK", "Beeldfrequentie": "50 Hz"},
                de: {"Auflösung": "640x480px", "Pixelabstand": "12 µm", "Empfindlichkeit (NETD)": "≤30 mK", "Bildfrequenz": "50 Hz"}
            },
            Optics: {
                en: {"Lens": "35 mm", "Field of view": "12.5°×9.4°", "Optical zoom": "1.65x", "Digital zoom": "1x - 8x"},
                nl: {"Lens": "35 mm", "Gezichtsveld": "12.5°×9.4°", "Optische zoom": "1.65x", "Digitale zoom": "1x - 8x"},
                de: {"Linse": "35 mm", "Sichtfeld": "12.5°×9.4°", "Optischer Zoom": "1.65x", "Digitaler Zoom": "1x - 8x"}
            },
            Electronics: {
                en: {"Display type": "0.39’’ AMOLED", "Display resolution": "1440×1080 px", "Battery": "18650 battery (built-in) and customized battery (external)"},
                nl: {"Beeldschermtype": "0.39’’ AMOLED", "Beeldschermresolutie": "1440×1080 px", "Batterij": "18650 batterij (ingebouwd) en aangepaste batterij (extern)"},
                de: {"Anzeigetyp": "0.39’’ AMOLED", "Anzeige Auflösung": "1440×1080 px", "Batterie": "18650 batterie (eingebaut) und kundenspezifische Batterie (extern)"}
            },
            Operating: {
                en: {"Encapsulation": "IP67", "Internal memory": "128 GB", "Operating temperature range": "-30°C to 50°C", "Weight (without replaceable battery)": "≤990 g", "Dimensions": "≤377×107×69 mm", "USB-interface": "Type C, supporting power delivery, charging, and reading data from memory card"},
                nl: {"Waterdichtheid": "IP67", "Intern geheugen": "128 GB", "Bedrijfstemperatuurbereik": "-30°C to 50°C", "Gewicht (zonder vervangbare batterij)": "≤990 g", "Afmetingen": "≤377×107×69 mm", "USB-aansluiting": "Type C, ondersteunt voeding, opladen en lezen van gegevens van geheugenkaart"},
                de: {"Wasserdicht": "IP67", "Interne Speicher": "128 GB", "Betriebstemperaturbereich": "-30°C to 50°C", "Gewicht (ohne auswechselbare Batterie)": "≤939 g", "Abmessungen": "≤377×107×69 mm", "USB-Anschluss": "Typ C, unterstützt Stromzufuhr, Aufladen und Lesen von Daten von der Speicherkarte"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: false, filterVoorzet: false, filterRiflescope: true}
	},

    {
		id: "wm49",
        orderID: "144",
        type: "thermal",
        title: {nl: "Lahoux Sight Elite 50", en: "Lahoux Sight Elite 50", de: "Lahoux Sight Elite 50"},
        url: "Lahoux-Sight-Elite-50",
        price: "3448.28",
        new: false,
        //available_date: {nl: "Binnenkort verkrijgbaar", en: "Available soon", de: "Bald verfügbar"},
        shortDes: { nl: "De Lahoux Sight Elite 50 is een richtkijker met warmtebeeldfunctie, die de eigenschappen van een uitstekende richtkijker combineert met die van een uitstekend warmtebeeldapparaat. De Lahoux Sight Elite 50 heeft een 50mm lens, een detectorresolutie van 640x480px en een detectorafstand van 12 µm.", 
                    en: "The Lahoux Sight Elite 50 is a riflescope with thermal imaging function, which combines the attributes of an excellent riflescope with those of an outstanding thermal imaging device. The Lahoux Sight Elite 50 has a 50mm lens, a detector resolution of 640x480pxx and a detector pitch of 12 µm.", 
                    de: "Das Lahoux Sight Elite 50 ist ein Wärmebild-Zielfernrohr das die Vorteile einer exzellenten Zieloptik mit denen eines hervorragenden Wärmebildgerätes in sich vereint. Das Lahoux Sight Elite 50 hat ein 50mm Objektiv, eine Detektorauflösung von 640x480px und einen Detektorabstand von 12 µm."},
        longDes_1: {    nl: "De Lahoux Sight Elite 50 is een richtkijker met warmtebeeldfunctie, die de eigenschappen van een uitstekende richtkijker combineert met die van een uitstekend warmtebeeldapparaat. De Lahoux Sight Elite 50 heeft een 50mm lens, een detectorresolutie van 640x480px en een detectorafstand van 12 µm.", 
                        en: "The Lahoux Sight Elite 50 is a riflescope with thermal imaging function, which combines the attributes of an excellent riflescope with those of an outstanding thermal imaging device. The Lahoux Sight Elite 50 has a 50mm lens, a detector resolution of 640x480px and a detector pitch of 12 µm.", 
                        de: "Das Lahoux Sight Elite 50 ist ein Wärmebild-Zielfernrohr das die Vorteile einer exzellenten Zieloptik mit denen eines hervorragenden Wärmebildgerätes in sich vereint. Das Lahoux Sight Elite 50 hat ein 50mm Objektiv, eine Detektorauflösung von 640x480px und einen Detektorabstand von 12 µm."},
        longDes_2: {    nl: "", 
                        en: "", 
                        de: ""},
        pros: {     nl: ["0.39’’ AMOLED display", "Zes kleurmodi: Wit Heet, Zwart Heet, instelbaar Rood Heet, Groen Heet, IJzerrood, Blauw Heet", "Ontworpen voor professionele jagers", "Foto- en video-opnamen", "IP67 waterdicht geclassificeerd"], 
                    en: ["0.39’’ AMOLED display", "Six color modes: White Hot, Black Hot, adjustable Red Hot, Green Hot, Iron Red, Blue Hot", "Designed for Professional Hunters", "Photo and video recording", "IP67 waterproof-rated"], 
                    de: ["0.39’’ AMOLED Display", "Sechs Farbmodi: Weiß heiß, Schwarz heiß, einstellbar Rot heiß, Grün heiß, Eisenrot, Blau heiß", "Entworfen für professionelle Jäger", "Foto- und Videoaufnahmen", "Wasserdicht nach IP67"]
        },
        number: "02-0006-03838",
        ean: "",
        warning: true, 
        images: ["Lahoux-Sight-Elite-50.png", "Lahoux-Sight-Elite-50-2.png", "Lahoux-Sight-Elite-50-3.png", "Lahoux-Sight-Elite-50-4.png"],
        footnote_battery: true,
        endOfLife: false,        
        techs: { 
            Detector: { 
                en: {"Resolution": "640x480px", "Pixel pitch": "12µm", "Sensitivity (NETD)": "≤30 mK", "Frame rate": "50 Hz"},
                nl: {"Resolutie": "640x480px", "Pixelafstand": "12µm", "Gevoeligheid (NETD)": "≤30 mK", "Beeldfrequentie": "50 Hz"},
                de: {"Auflösung": "640x480px", "Pixelabstand": "12µm", "Empfindlichkeit (NETD)": "≤30 mK", "Bildfrequenz": "50 Hz"}
            },
            Optics: {
                en: {"Lens": "50mm", "Field of view": "8.8°×6.6°", "Optical zoom": "2.36x", "Digital zoom": "1x - 8x"},
                nl: {"Lens": "50mm", "Gezichtsveld": "8.8°×6.6°", "Optische zoom": "2.36x", "Digitale zoom": "1x - 8x"},
                de: {"Linse": "50mm", "Sichtfeld": "8.8°×6.6°", "Optischer Zoom": "2.36x", "Digitaler Zoom": "1x - 8x"}
            },
            Electronics: {
                en: {"Display type": "0.39’’ AMOLED", "Display resolution": "1440×1080px", "Battery": "18650 battery (built-in) and customized battery (external)"},
                nl: {"Beeldschermtype": "0.39’’ AMOLED", "Beeldschermresolutie": "1440×1080px", "Batterij": "18650 batterij (ingebouwd) en aangepaste batterij (extern)"},
                de: {"Anzeigetyp": "0.39’’ AMOLED", "Anzeige Auflösung": "1440×1080px", "Batterie": "18650 batterie (eingebaut) und kundenspezifische Batterie (extern)"}
            },
            Operating: {
                en: {"Encapsulation": "IP67", "Internal memory": "128 GB", "Operating temperature range": "-30°C to 50°C", "Weight (without replaceable battery)": "≤990 g", "Dimensions": "≤377×107×69 mm", "USB-interface": "Type C, supporting power delivery, charging, and reading data from memory card"},
                nl: {"Waterdichtheid": "IP67", "Intern geheugen": "128 GB", "Bedrijfstemperatuurbereik": "-30°C to 50°C", "Gewicht (zonder vervangbare batterij)": "≤990 g", "Afmetingen": "≤377×107×69 mm", "USB-aansluiting": "Type C, ondersteunt voeding, opladen en lezen van gegevens van geheugenkaart"},
                de: {"Wasserdicht": "IP67", "Interne Speicher": "128 GB", "Betriebstemperaturbereich": "-30°C to 50°C", "Gewicht (ohne auswechselbare Batterie)": "≤939 g", "Abmessungen": "≤377×107×69 mm", "USB-Anschluss": "Typ C, unterstützt Stromzufuhr, Aufladen und Lesen von Daten von der Speicherkarte"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: false, filterVoorzet: false, filterRiflescope: true}
	},

    {
		id: "wm50",
        orderID: "141",
        type: "thermal",
        title: {nl: "Lahoux Sight 35 V2 LRF", en: "Lahoux Sight 35 V2 LRF", de: "Lahoux Sight 35 V2 LRF"},
        url: "Lahoux-Sight-35-V2-LRF",
        price: "2620.69",
        new: false,
        //available_date: {nl: "Binnenkort verkrijgbaar", en: "Available soon", de: "Bald verfügbar"},\
        shortDes: { nl: "De Lahoux Sight 35 V2 LRF is een thermische richtkijker met laser afstandsmeter die de eigenschappen van een uitstekende richtkijker combineert met die van een uitstekend warmtebeeldapparaat. De Lahoux Sight 35 V2 LRF heeft een 35mm lens, een detectorresolutie van 400x300 px en een detectorafstand van 12 µm.", 
                    en: "The Lahoux Sight 35 V2 LRF is a thermal riflescope with laser range finder which combines the attributes of an excellent riflescope with those of an outstanding thermal imaging device. The Lahoux Sight 35 V2 LRF has a 35mm lens, a detector resolution of 400x300 px and a detector pitch of 12 µm.", 
                    de: "Das Lahoux Sight 35 V2 LRF ist ein Wärmebild-Zielfernrohr mit integriertem Laser-Entfernungsmesser das die Vorteile einer exzellenten Zieloptik mit denen eines hervorragenden Wärmebildgerätes in sich vereint. Das Lahoux Sight 35 V2 LRF hat ein 35mm Objektiv, eine Detektorauflösung von 400x300 px und einen Detektorabstand von 12 µm."},
        longDes_1: {    nl: "De Lahoux Sight 35 V2 LRF is een thermische richtkijker met laser afstandsmeter die de eigenschappen van een uitstekende richtkijker combineert met die van een uitstekend warmtebeeldapparaat. De Lahoux Sight 35 V2 LRF heeft een 35mm lens, een detectorresolutie van 400x300 px en een detectorafstand van 12 µm.", 
                        en: "The Lahoux Sight 35 V2 LRF is a thermal riflescope with laser range finder which combines the attributes of an excellent riflescope with those of an outstanding thermal imaging device. The Lahoux Sight 35 V2 LRF has a 35mm lens, a detector resolution of 400x300 px and a detector pitch of 12 µm.", 
                        de: "Das Lahoux Sight 35 V2 LRF ist ein Wärmebild-Zielfernrohr mit integriertem Laser-Entfernungsmesser das die Vorteile einer exzellenten Zieloptik mit denen eines hervorragenden Wärmebildgerätes in sich vereint. Das Lahoux Sight 35 V2 LRF hat ein 35mm Objektiv, eine Detektorauflösung von 400x300 px und einen Detektorabstand von 12 µm."},
        longDes_2: {    nl: "", 
                        en: "", 
                        de: ""},
        pros: {     nl: ["0.39’’ AMOLED display", "Zes kleurmodi: Wit Heet, Zwart Heet, instelbaar Rood Heet, Groen Heet, IJzerrood, Blauw Heet", "Ontworpen voor professionele jagers", "Foto- en video-opnamen", "IP67 waterdicht geclassificeerd"], 
                    en: ["0.39’’ AMOLED display", "Six color modes: White Hot, Black Hot, adjustable Red Hot, Green Hot, Iron Red, Blue Hot", "Designed for Professional Hunters", "Photo and video recording", "IP67 waterproof-rated"], 
                    de: ["0.39’’ AMOLED Display", "Sechs Farbmodi: Weiß heiß, Schwarz heiß, einstellbar Rot heiß, Grün heiß, Eisenrot, Blau heiß", "Entworfen für professionelle Jäger", "Foto- und Videoaufnahmen", "Wasserdicht nach IP67"]
        },
        number: "02-0006-03834",
        ean: "",
        warning: true, 
        images: ["Lahoux-Sight-35-V2-LRF.png", "Lahoux-Sight-35-V2-LRF-2.png", "Lahoux-Sight-35-V2-LRF-3.png"],
        footnote_battery: true,
        endOfLife: false,        
        techs: { 
            Detector: { 
                en: {"Resolution": "400x300 px", "Pixel pitch": "12 µm", "Sensitivity (NETD)": "≤30 mK", "Frame rate": "50 Hz"},
                nl: {"Resolutie": "400x300 px", "Pixelafstand": "12 µm", "Gevoeligheid (NETD)": "≤30 mK", "Beeldfrequentie": "50 Hz"},
                de: {"Auflösung": "400x300 px", "Pixelabstand": "12 µm", "Empfindlichkeit (NETD)": "≤30 mK", "Bildfrequenz": "50 Hz"}
            },
            Optics: {
                en: {"Lens": "35 mm", "Field of view": "7.8°×5.9°", "Optical zoom": "2.65x", "Digital zoom": "1x - 4x"},
                nl: {"Lens": "35 mm", "Gezichtsveld": "7.8°×5.9°", "Optische zoom": "2.65x", "Digitale zoom": "1x - 4x"},
                de: {"Linse": "35 mm", "Sichtfeld": "7.8°×5.9° ", "Optischer Zoom": "2.65x", "Digitaler Zoom": "1x - 4x"}
            },
            Electronics: {
                en: {"Display type": "0.39’’ AMOLED", "Display resolution": "1440×1080px", "Battery": "18650 battery (built-in) and customized battery (external)"},
                nl: {"Beeldschermtype": "0.39’’ AMOLED", "Beeldschermresolutie": "1440×1080px", "Batterij": "18650 batterij (ingebouwd) en aangepaste batterij (extern)"},
                de: {"Anzeigetyp": "0.39’’ AMOLED", "Anzeige Auflösung": "1440×1080px", "Batterie": "18650 batterie (eingebaut) und kundenspezifische Batterie (extern)"}
            },
            Operating: {
                en: {"Laser-rangefinder range": "1000 m","Encapsulation": "IP67", "Internal memory": "128 GB", "Operating temperature range": "-30°C to 50°C", "Weight (without replaceable battery)": "≤990 g", "Dimensions": "≤377×107×69 mm", "USB-interface": "Type C, supporting power delivery, charging, and reading data from memory card"},
                nl: {"Bereik laserafstandsmeter": "1000 m", "Waterdichtheid": "IP67", "Intern geheugen": "128 GB", "Bedrijfstemperatuurbereik": "-30°C to 50°C", "Gewicht (zonder vervangbare batterij)": "≤990 g", "Afmetingen": "≤377×107×69 mm", "USB-aansluiting": "Type C, ondersteunt voeding, opladen en lezen van gegevens van geheugenkaart"},
                de: {"Laser-Entfernungsmesser Bereich": "1000 m", "Wasserdicht": "IP67", "Interne Speicher": "128 GB", "Betriebstemperaturbereich": "-30°C to 50°C", "Gewicht (ohne auswechselbare Batterie)": "≤939 g", "Abmessungen": "≤377×107×69 mm", "USB-Anschluss": "Typ C, unterstützt Stromzufuhr, Aufladen und Lesen von Daten von der Speicherkarte"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: false, filterVoorzet: false, filterRiflescope: true}
	},

    {
		id: "wm51",
        orderID: "145",
        type: "thermal",
        title: {nl: "Lahoux Sight Elite 50 LRF", en: "Lahoux Sight Elite 50 LRF", de: "Lahoux Sight Elite 50 LRF"},
        url: "Lahoux-Sight-Elite-50-LRF",
        price: "3655.17",
        new: false,
        //available_date: {nl: "Binnenkort verkrijgbaar", en: "Available soon", de: "Bald verfügbar"},\
        shortDes: { nl: "De Lahoux Sight Elite 50 LRF is een thermische richtkijker met laser afstandsmeter die de eigenschappen van een uitstekende richtkijker combineert met die van een uitstekend warmtebeeldapparaat. De Lahoux Sight Elite 50 LRF heeft een 50mm lens, een detectorresolutie van 640x480 px en een detectorafstand van 12 µm.", 
                    en: "The Lahoux Sight Elite 50 LRF is a thermal riflescope with laser range finder which combines the attributes of an excellent riflescope with those of an outstanding thermal imaging device. The Lahoux Sight Elite 50 LRF has a 50mm lens, a detector resolution of 640x480 px and a detector pitch of 12 µm.", 
                    de: "Das Lahoux Sight Elite 50 LRF ist ein Wärmebild-Zielfernrohr mit integriertem Laser-Entfernungsmesser das die Vorteile einer exzellenten Zieloptik mit denen eines hervorragenden Wärmebildgerätes in sich vereint. Das Lahoux Sight Elite 50 LRF hat ein 50mm Objektiv, eine Detektorauflösung von 640x480 px und einen Detektorabstand von 12 µm."},
        longDes_1: {    nl: "De Lahoux Sight Elite 50 LRF is een thermische richtkijker met laser afstandsmeter die de eigenschappen van een uitstekende richtkijker combineert met die van een uitstekend warmtebeeldapparaat. De Lahoux Sight Elite 50 LRF heeft een 50mm lens, een detectorresolutie van 640x480 px en een detectorafstand van 12 µm.", 
                        en: "The Lahoux Sight Elite 50 LRF is a thermal riflescope with laser range finder which combines the attributes of an excellent riflescope with those of an outstanding thermal imaging device. The Lahoux Sight Elite 50 LRF has a 50mm lens, a detector resolution of 640x480 px and a detector pitch of 12 µm.", 
                        de: "Das Lahoux Sight Elite 50 LRF ist ein Wärmebild-Zielfernrohr mit integriertem Laser-Entfernungsmesser das die Vorteile einer exzellenten Zieloptik mit denen eines hervorragenden Wärmebildgerätes in sich vereint. Das Lahoux Sight Elite 50 LRF hat ein 50mm Objektiv, eine Detektorauflösung von 640x480 px und einen Detektorabstand von 12 µm."},
        longDes_2: {    nl: "", 
                        en: "", 
                        de: ""},
        pros: {     nl: ["0.39’’ AMOLED display", "Zes kleurmodi: Wit Heet, Zwart Heet, instelbaar Rood Heet, Groen Heet, IJzerrood, Blauw Heet", "Ontworpen voor professionele jagers", "Foto- en video-opnamen", "IP67 waterdicht geclassificeerd"], 
                    en: ["0.39’’ AMOLED display", "Six color modes: White Hot, Black Hot, adjustable Red Hot, Green Hot, Iron Red, Blue Hot", "Designed for Professional Hunters", "Photo and video recording", "IP67 waterproof-rated"], 
                    de: ["0.39’’ AMOLED Display", "Sechs Farbmodi: Weiß heiß, Schwarz heiß, einstellbar Rot heiß, Grün heiß, Eisenrot, Blau heiß", "Entworfen für professionelle Jäger", "Foto- und Videoaufnahmen", "Wasserdicht nach IP67"]
        },
        number: "02-0006-03834",
        ean: "",
        warning: true, 
        images: ["Lahoux-Sight-35-V2-LRF.png", "Lahoux-Sight-35-V2-LRF-2.png", "Lahoux-Sight-35-V2-LRF-3.png"],
        footnote_battery: true,
        endOfLife: false,        
        techs: { 
            Detector: { 
                en: {"Resolution": "640x480 px", "Pixel pitch": "12 µm", "Sensitivity (NETD)": "≤30 mK", "Frame rate": "50 Hz"},
                nl: {"Resolutie": "640x480 px", "Pixelafstand": "12 µm", "Gevoeligheid (NETD)": "≤30 mK", "Beeldfrequentie": "50 Hz"},
                de: {"Auflösung": "640x480 px", "Pixelabstand": "12 µm", "Empfindlichkeit (NETD)": "≤30 mK", "Bildfrequenz": "50 Hz"}
            },
            Optics: {
                en: {"Lens": "50 mm", "Field of view": "8.8°×6.6°", "Optical zoom": "2.36x", "Digital zoom": "1x - 8x"},
                nl: {"Lens": "50 mm", "Gezichtsveld": "8.8°×6.6°", "Optische zoom": "2.36x", "Digitale zoom": "1x - 8x"},
                de: {"Linse": "50 mm", "Sichtfeld": "8.8°×6.6°", "Optischer Zoom": "2.36x", "Digitaler Zoom": "1x - 8x"}
            },
            Electronics: {
                en: {"Display type": "0.39’’ AMOLED", "Display resolution": "1440×1080px", "Battery": "18650 battery (built-in) and customized battery (external)"},
                nl: {"Beeldschermtype": "0.39’’ AMOLED", "Beeldschermresolutie": "1440×1080px", "Batterij": "18650 batterij (ingebouwd) en aangepaste batterij (extern)"},
                de: {"Anzeigetyp": "0.39’’ AMOLED", "Anzeige Auflösung": "1440×1080px", "Batterie": "18650 batterie (eingebaut) und kundenspezifische Batterie (extern)"}
            },
            Operating: {
                en: {"Laser-rangefinder range": "2000 m","Encapsulation": "IP67", "Internal memory": "128 GB", "Operating temperature range": "-30°C to 50°C", "Weight (without replaceable battery)": "≤990 g", "Dimensions": "≤377×107×69 mm", "USB-interface": "Type C, supporting power delivery, charging, and reading data from memory card"},
                nl: {"Bereik laserafstandsmeter": "2000 m", "Waterdichtheid": "IP67", "Intern geheugen": "128 GB", "Bedrijfstemperatuurbereik": "-30°C to 50°C", "Gewicht (zonder vervangbare batterij)": "≤990 g", "Afmetingen": "≤377×107×69 mm", "USB-aansluiting": "Type C, ondersteunt voeding, opladen en lezen van gegevens van geheugenkaart"},
                de: {"Laser-Entfernungsmesser Bereich": "2000 m", "Wasserdicht": "IP67", "Interne Speicher": "128 GB", "Betriebstemperaturbereich": "-30°C to 50°C", "Gewicht (ohne auswechselbare Batterie)": "≤939 g", "Abmessungen": "≤377×107×69 mm", "USB-Anschluss": "Typ C, unterstützt Stromzufuhr, Aufladen und Lesen von Daten von der Speicherkarte"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: false, filterVoorzet: false, filterRiflescope: true}
	},


    {
		id: "wm21",
        orderID: "141",
        type: "thermal",
        title: {nl: "Lahoux Sight 35", en: "Lahoux Sight 35", de: "Lahoux Sight 35"},
        url: "Lahoux-Sight-35",
        price: "2551.02",
        shortDes: { nl:"De Lahoux Sight 35 is een richtkijker met warmtebeeldfunctie, die de eigenschappen van een uitstekende richtkijker combineert met die van een perfect warmtebeeldapparaat. Hierdoor kan zowel ‘s nachts als overdag worden gejaagd met slechts één richtkijker.", 
                    en: "The Lahoux Sight 35 is a riflescope with thermal imaging function, which combines the attributes of an excellent riflescope with those of an outstanding thermal imaging device. This allows hunting at night as well as during the day with only one device.", 
                    de: "Das Lahoux Sight 35 ist ein Wärmebild-Zielfernrohr das die Vorteile einer exzellenten Zieloptik mit denen eines hervorragenden Wärmebildgerätes in sich vereint. Es ermöglicht mit nur einem Gerät, die Jagd sowohl bei Nacht wie auch bei Tag auszuüben."},
        longDes_1: {    nl:"Het gebruik van een 12µm pixel pitch detector zorgt voor maximale scherpte en contrast met een groot detectiebereik. De beeldverversingssnelheid van 50 Hz maakt een vloeiend en schokvrij beeld mogelijk. Kenmerkend voor de Lahoux Sight 35 is de detectorresolutie van 384 x 288 pixels en de 35 mm lens. Dit zorgt voor een groot gezichtsveld van 7,5° × 5,6°, waardoor de Lahoux Sight 35 in staat is om grotere gebieden tijdens het bersen te overzien, maar ook om voldoende prestaties te hebben voor grotere afstanden. Dit is ook te danken aan de 3 tot 12× (digitaal 1 tot 4×) vergroting. Met zijn vijf kleurmodi is de Lahoux Sight 35 geschikt voor elke jachtsituatie. De gebruiker kan kiezen tussen deze vijf kleurmodi: Wit-heet (white hot), Zwart-heet (black-hot), Rood-heet (red hot), Target Highlighting, Pseudokleur (pseudo colour).", 
                        en: "The use of a 12µm pixel pitch detector ensures maximum sharpness and contrast with a long detection range. The image refresh rate of 50 Hz enables a smooth and vibration free image. Characteristical features of the Lahoux Sight 35 include its detector resolution of 384 x 288 pixels, as well as the 35 mm lens. This provides a large field of view of 7.5° × 5.6°, which enables the Lahoux Sight 35 to observe larger areas in stalking situations, but also to have sufficient power reserve for further shots. This is not at least thanks to its 3 to 12× (1 to 4× digital) magnification. With its 5 colour modes, the Lahoux Sight 35 is suitable for any hunting situation. The user can choose between these 5 colour modes: White hot, Black hot, Red hot, Target Highlighting, Pseudo Colour. ", 
                         de: "Durch den Einsatz eines 12µm Pixel Pitch Detektors, wird maximale Schärfe und größtmöglicher Kontrast, bei gleichzeitig hoher Detektionsreichweite gewährleistet. Die Bildaktualisierungsrate von 50 Herz ermöglicht dabei ein flüssiges und ruckelfreies Bild. Kennzeichnend für das Lahoux Sight 35 ist unter anderem seine Detektorauflösung von 384 x 288 Pixel, wie auch das 35 mm Objektiv. Hierdurch wird ein großes Sehfeld von 7,5° × 5,6° erreicht, das es dem Lahoux Sight 35 ermöglicht, in Kirrungssituationen oder auf der Pirsch größere Bereiche zu überblicken, aber auch für weitere Schüsse genügend Leistungsreserve zu haben. Dies nicht zuletzt, dank seiner 3 bis 12-fachen (1-4 fachen digitalen) Vergrößerung. Mit seinen fünf Farbmodi wird das Lahoux Sight 35 jeder Jagsituation gerecht. Der Nutzer kann zwischen folgenden fünf Farbmodi wählen: Weiß heiß / White hot, Schwarz heiß / Black hot, Schwarz heiß / Black hot, Rot heiß / Red Hot, Farbmarkierung des Ziels / Target-Highlight, Mehrfarbig / Multicolor."},
        longDes_2: {    nl:"Dankzij de buisdiameter van 30 mm is de Lahoux Sight 35 geschikt voor alle gangbare montages en weegt hij met 950 gram slechts iets meer dan andere topklasse richtkijkers zonder warmtebeeldfunctie. De Lahoux Sight 35 is gecertificeerd volgens IP67 en is optimaal beschermd tegen stof en zware regen volgens strenge specificaties. De Lahoux Sight 35 maakt het gebruik van hoogwaardige warmtebeeldtechnologie in combinatie met beproefde richtkijkertechnologie waarbij het vertrouwde uiterlijk nauwelijks gewijzigd is.", 
                        en: "Thanks to its centre tube diameter of 30 mm, the Lahoux Sight 35 is suitable for all common mounting types and, at 950 grams, weighs only slightly more than other top-of-the-range rifle scopes without thermal imaging function. The Lahoux Sight 35 is certified to IP67 and is optimally protected against dust and heavy rain in accordance with strict specifications. The Lahoux Sight 35 enables the use of high-performance thermal imaging technology in combination with proven rifle scope technology without significantly changing the familiar rifle scope design.", 
                        de: "Dank seines Mittelrohrdurchmessers von 30 mm, ist das Lahoux Sight 35 für alle gängigen Montagetypen geeignet und wiegt mit seinen 950 Gramm nur wenig mehr als andere Spitzengläser ohne Wärmebildfunktion. Das Lahoux Sight 35 ist nach IP67 zertifiziert und ist, entsprechend den strengen Vorgaben, optimal gegen Staub und starken Regen geschützt. Das Lahoux Sight 35 ermöglicht den Einsatz von Hochleistungswärmebildtechnik in Kombination mit bewährter Zielfernrohrtechnik und vertrautem Design."},
        number: "02-0006-02519",
        ean: "6090301081031",
        warning: true, 
        leveromvang: [1, 20, 10, 3, 4, 17],
        flyer: {nl:"Lahoux_Sight-35_NL_V1.pdf", en: "Lahoux_Sight-35_EN_V1.pdf", de: "Lahoux_Sight-35_DE_V1.pdf"},
        images: ["wm21_1.png", "wm21_2.png", "wm21_3.png", "wm21_4.png"],
        dri: "Warmtebeeld_Sight.png",
        accessories: ["as15"],
        endOfLife: true,         
        videos: {
            nl: {"Lahoux_Sight_35.mp4": "Beelden gemaakt met Lahoux Sight 35"},
            en: {"Lahoux_Sight_35.mp4": "Images taken with Lahoux Sight 35"},
            de: {"Lahoux_Sight_35.mp4": "Bilder aufgenommen mit Lahoux Sight 35"}
        },
        footnote_battery: true,
        techs: { 
            Detector: { 
                en: {"Resolution": "384 × 288 pixel", "Pixel pitch": "12 µm", "Sensitivity (NETD)": "< 50 mK"},
                nl: {"Resolutie": "384 × 288 pixel", "Pixelafstand": "12 µm", "Gevoeligheid (NETD)": "< 50 mK"},
                de: {"Auflösung": "384 × 288 pixel", "Pixelabstand": "12 µm", "Empfindlichkeit (NETD)": "< 50 mK"}
            },
            Optics: {
                en: {"Lens": "35 mm", "Field of view": "7.5° × 5.6°", "Magnification": "3.0-12.0×", "Digital zoom": "1.0-4.0×", "Eye relief": "70 mm", "Diopter adjustment": "-4 – +4 D"},
                nl: {"Lens": "35 mm", "Gezichtsveld": "7.5° × 5.6°", "Vergroting": "3.0-12.0×", "Digitale zoom": "1.0-4.0×", "Oogafstand": "70 mm", "Dioptrie aanpassing": "-4 – +4 D"},
                de: {"Linse": "35 mm", "Sichtfeld": "7.5° × 5.6°", "Vergrößerung": "3.0-12.0×", "Digitaler Zoom": "1.0-4.0×", "Augenabstand": "70 mm", "Dioptrien-Einstellung": "-4 – +4 D"}
            },
            Electronics: {
                en: {"Display type": "LCOS", "Display resolution": "1280 × 960 pixel", "Battery": "2 internal 18650 batteries and 1 replaceable 18500 battery", "External power supply": "5V (Type USB-C)"},
                nl: {"Beeldschermtype": "LCOS", "Beeldschermresolutie": "1280 × 960 pixel", "Batterij": "2 interne 18650-batterijen en 1 verwisselbare 18500-batterij", "Externe voeding": "5V (Type USB-C)"},
                de: {"Anzeigetyp": "LCOS", "Anzeige Auflösung": "1280 × 960 pixel", "Batterie": "2 interne 18650er Batterien und 1 austauschbare 18500er Batterie", "Externe Stromversorgung ": "5V (Type USB-C)"}
            },
            Operating: {
                en: {"Max. operating time (at 22 °C)": "± 15 hours *", "Diameter of the rifle scope body": "30 mm", "Max. recoil power": "1000 g/s²", "Encapsulation": "IP67", "Internal memory": "16 GB", "Operating temperature range": "-20~+50 °C", "Weight (without replaceable battery)": "<950 g", "Dimensions": "385×85×75 mm", "USB-interface": "USB-C"},
                nl: {"Max. gebruikstijd (bij 22 °C)": "± 15 uur *", "Diameter van de richtkijkerbehuizing": "30 mm", "Max. terugslagkracht": "1000 g/s²", "Waterdichtheid": "IP67", "Intern geheugen": "16 GB", "Bedrijfstemperatuurbereik": "-20~+50 °C", "Gewicht (zonder vervangbare batterij)": "<950 g", "Afmetingen": "385×85×75 mm", "USB-aansluiting": "USB-C"},
                de: {"Max. Betriebsdauer (bei 22 °C)": "± 15 Stunden *", "Durchmesser des Zielfernrohrgehäuses": "30 mm", "Max. Rückstoßkraft": "1000 g/s²", "Wasserdicht": "IP67", "Interne Speicher": "16 GB", "Betriebstemperaturbereich": "-20~+50 °C", "Gewicht (ohne auswechselbare Batterie)": "<950 g", "Abmessungen": "385×85×75 mm", "USB-Anschluss": "USB-C"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: false, filterVoorzet: false, filterRiflescope: true}
	},

    {
		id: "wm36",
        orderID: "142",
        type: "thermal",
        title: {nl: "Lahoux Sight Elite 25", en: "Lahoux Sight Elite 25", de: "Lahoux Sight Elite 25"},
        url: "Lahoux-Sight-Elite-25",
        price: "3607.14",
        new: false,
        //available_date: {nl: "Binnenkort verkrijgbaar", en: "Available soon", de: "Bald verfügbar"},
        shortDes: { nl: "De Lahoux Sight Elite 25 is een richtkijker met warmtebeeldfunctie, die de eigenschappen van een uitstekende richtkijker combineert met die van een uitstekend warmtebeeldapparaat. De Lahoux Sight Elite 25 heeft een 25 mm lens, een detectorresolutie van 640 × 480 px en een detectorafstand van 12 µm.", 
                    en: "The Lahoux Sight Elite 25 is a riflescope with thermal imaging function, which combines the attributes of an excellent riflescope with those of an outstanding thermal imaging device. The Lahoux Sight Elite 25 has a 25mm lens, a detector resolution of 640 × 480 px and a detector pitch of 12 µm.", 
                    de: "Das Lahoux Sight Elite 25 ist ein Wärmebild-Zielfernrohr das die Vorteile einer exzellenten Zieloptik mit denen eines hervorragenden Wärmebildgerätes in sich vereint. Das Lahoux Sight Elite 25 hat ein 25mm Objektiv, eine Detektorauflösung von 640 × 480 px und einen Detektorabstand von 12 µm."},
        longDes_1: {    nl: "De Lahoux Sight Elite 25 is een richtkijker met warmtebeeldfunctie, die de eigenschappen van een uitstekende richtkijker combineert met die van een uitstekend warmtebeeldapparaat. De Lahoux Sight Elite 25 heeft een 25 mm lens, een detectorresolutie van 640 × 480 px en een detectorafstand van 12 µm.", 
                        en: "The Lahoux Sight Elite 25 is a riflescope with thermal imaging function, which combines the attributes of an excellent riflescope with those of an outstanding thermal imaging device. The Lahoux Sight Elite 25 has a 25mm lens, a detector resolution of 640 × 480 px and a detector pitch of 12 µm.", 
                        de: "Das Lahoux Sight Elite 25 ist ein Wärmebild-Zielfernrohr das die Vorteile einer exzellenten Zieloptik mit denen eines hervorragenden Wärmebildgerätes in sich vereint. Das Lahoux Sight Elite 25 hat ein 25mm Objektiv, eine Detektorauflösung von 640 × 480 px und einen Detektorabstand von 12 µm."},
        longDes_2: {    nl: "", 
                        en: "", 
                        de: ""},
        pros: {     nl: ["OLED-scherm in kleur", "Zes kleurmodi: Wit Heet, Zwart Heet, Rood Heet, Groen Heet, IJzerrood, Blauw Heet", "Ontworpen voor professionele jagers", "Foto- en video-opnamen", "IP67 waterdicht geclassificeerd"], 
                    en: ["Full-color OLED display", "Six color modes: White Hot, Black Hot, Red Hot, Green Hot, Iron Red, Blue Hot", "Designed for Professional Hunters", "Photo and video recording", "IP67 waterproof-rated"], 
                    de: ["Vollfarbiges OLED-Display", "Sechs Farbmodi: Weiß heiß, Schwarz heiß, Rot heiß, Grün heiß, Eisenrot, Blau heiß", "Entworfen für professionelle Jäger", "Foto- und Videoaufnahmen", "Wasserdicht nach IP67"]
        },
        number: "02-0006-03506",
        ean: "",
        warning: true, 
        images: ["Lahoux-Sight-Elite-25.png", "Lahoux-Sight-Elite-25-2.png", "Lahoux-Sight-Elite-25-3.png", "Lahoux-Sight-Elite-25-4.png"],
        footnote_battery: true,
        endOfLife: true,         
        techs: { 
            Detector: { 
                en: {"Resolution": "640 x 480 px", "Pixel pitch": "12 µm", "Sensitivity (NETD)": "≤30 mk", "Frame rate": "50 Hz"},
                nl: {"Resolutie": "640 x 480 px", "Pixelafstand": "12 µm", "Gevoeligheid (NETD)": "≤30 mk", "Beeldfrequentie": "50 Hz"},
                de: {"Auflösung": "640 x 480 px", "Pixelabstand": "12 µm", "Empfindlichkeit (NETD)": "≤30 mk", "Bildfrequenz": "50 Hz"}
            },
            Optics: {
                en: {"Lens": "25 mm", "Field of view": "17.46° × 13.14°", "Digital zoom": "1x - 8x"},
                nl: {"Lens": "25 mm", "Gezichtsveld": "17.46° × 13.14°", "Digitale zoom": "1x - 8x"},
                de: {"Linse": "25 mm", "Sichtfeld": "17.46° × 13.14°", "Digitaler Zoom": "1x - 8x"}
            },
            Electronics: {
                en: {"Display type": "OLED", "Display resolutie": "1024 × 768 px", "Battery": "18650 battery (built-in) and customized battery (external)"},
                nl: {"Beeldschermtype": "OLED", "Beeldschermresolutie": "1024 × 768 px", "Batterij": "18650 batterij (ingebouwd) en aangepaste batterij (extern)"},
                de: {"Anzeigetyp": "OLED", "Anzeige Auflösung": "1024 × 768 px", "Batterie": "18650 batterie (eingebaut) und kundenspezifische Batterie (extern)"}
            },
            Operating: {
                en: {"Encapsulation": "IP67", "Internal memory": "16 GB", "Operating temperature range": "-30°C to 50°C", "Weight (without replaceable battery)": "≤980 g", "Dimensions": "367 × 107 × 69 mm", "USB-interface": "Type C, supporting power delivery, charging, and reading data from memory card"},
                nl: {"Waterdichtheid": "IP67", "Intern geheugen": "16 GB", "Bedrijfstemperatuurbereik": "-30°C to 50°C", "Gewicht (zonder vervangbare batterij)": "≤980 g", "Afmetingen": "367 × 107 × 69 mm", "USB-aansluiting": "Type C, ondersteunt voeding, opladen en lezen van gegevens van geheugenkaart"},
                de: {"Wasserdicht": "IP67", "Interne Speicher": "16 GB", "Betriebstemperaturbereich": "-30°C to 50°C", "Gewicht (ohne auswechselbare Batterie)": "≤980 g", "Abmessungen": "367 × 107 × 69 mm", "USB-Anschluss": "Typ C, unterstützt Stromzufuhr, Aufladen und Lesen von Daten von der Speicherkarte"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: false, filterVoorzet: false, filterRiflescope: true}
	},

    {
		id: "wm37",
        orderID: "143",
        type: "thermal",
        title: {nl: "Lahoux Sight Elite 50", en: "Lahoux Sight Elite 50", de: "Lahoux Sight Elite 50"},
        url: "Lahoux-Sight-Elite-50",
        price: "3750.00",
        new: false,
        //available_date: {nl: "Binnenkort verkrijgbaar", en: "Available soon", de: "Bald verfügbar"},
        shortDes: { nl: "De Lahoux Sight Elite 50 is een richtkijker met warmtebeeldfunctie, die de eigenschappen van een uitstekende richtkijker combineert met die van een uitstekend warmtebeeldapparaat. De Lahoux Sight Elite 50 heeft een 50 mm lens, een detectorresolutie van 640 × 480 px en een detectorafstand van 12 µm.", 
                    en: "The Lahoux Sight Elite 50 is a riflescope with thermal imaging function, which combines the attributes of an excellent riflescope with those of an outstanding thermal imaging device. The Lahoux Sight Elite 50 has a 50 mm lens, a detector resolution of 640 × 480 px and a detector pitch of 12 µm.", 
                    de: "Das Lahoux Sight Elite 50 ist ein Wärmebild-Zielfernrohr das die Vorteile einer exzellenten Zieloptik mit denen eines hervorragenden Wärmebildgerätes in sich vereint. Das Lahoux Sight Elite 50 hat ein 50 mm Objektiv, eine Detektorauflösung von 640 × 480 px und einen Detektorabstand von 12 µm."},
        longDes_1: {    nl: "De Lahoux Sight Elite 50 is een richtkijker met warmtebeeldfunctie, die de eigenschappen van een uitstekende richtkijker combineert met die van een uitstekend warmtebeeldapparaat. De Lahoux Sight Elite 50 heeft een 50 mm lens, een detectorresolutie van 640 × 480 px en een detectorafstand van 12 µm.", 
                        en: "The Lahoux Sight Elite 50 is a riflescope with thermal imaging function, which combines the attributes of an excellent riflescope with those of an outstanding thermal imaging device. The Lahoux Sight Elite 50 has a 50 mm lens, a detector resolution of 640 × 480 px and a detector pitch of 12 µm.", 
                        de: "Das Lahoux Sight Elite 50 ist ein Wärmebild-Zielfernrohr das die Vorteile einer exzellenten Zieloptik mit denen eines hervorragenden Wärmebildgerätes in sich vereint. Das Lahoux Sight Elite 50 hat ein 50 mm Objektiv, eine Detektorauflösung von 640 × 480 px und einen Detektorabstand von 12 µm."},
        longDes_2: {    nl: "", 
                        en: "", 
                        de: ""},
        pros: {     nl: ["OLED-scherm in kleur", "Zes kleurmodi: Wit Heet, Zwart Heet, Rood Heet, Groen Heet, IJzerrood, Blauw Heet", "Ontworpen voor professionele jagers", "Foto- en video-opnamen", "IP67 waterdicht geclassificeerd"], 
                    en: ["Full-color OLED display", "Six color modes: White Hot, Black Hot, Red Hot, Green Hot, Iron Red, Blue Hot", "Designed for Professional Hunters", "Photo and video recording", "IP67 waterproof-rated"], 
                    de: ["Vollfarbiges OLED-Display", "Sechs Farbmodi: Weiß heiß, Schwarz heiß, Rot heiß, Grün heiß, Eisenrot, Blau heiß", "Entworfen für professionelle Jäger", "Foto- und Videoaufnahmen", "Wasserdicht nach IP67"]
        },
        number: "02-0006-03507",
        ean: "",
        warning: true, 
        images: ["Lahoux-Sight-Elite-50.png", "Lahoux-Sight-Elite-50-2.png", "Lahoux-Sight-Elite-50-3.png", "Lahoux-Sight-Elite-50-4.png"],
        footnote_battery: true,
        endOfLife: true,         
        techs: { 
            Detector: { 
                en: {"Resolution": "640 x 480 px", "Pixel pitch": "12 µm", "Sensitivity (NETD)": "≤30 mk", "Frame rate": "50 Hz"},
                nl: {"Resolutie": "640 x 480 px", "Pixelafstand": "12 µm", "Gevoeligheid (NETD)": "≤30 mk", "Beeldfrequentie": "50 Hz"},
                de: {"Auflösung": "640 x 480 px", "Pixelabstand": "12 µm", "Empfindlichkeit (NETD)": "≤30 mk", "Bildfrequenz": "50 Hz"}
            },
            Optics: {
                en: {"Lens": "50 mm", "Field of view": "8.78° × 6.59°", "Digital zoom": "1x - 8x"},
                nl: {"Lens": "50 mm", "Gezichtsveld": "8.78° × 6.59°", "Digitale zoom": "1x - 8x"},
                de: {"Linse": "50 mm", "Sichtfeld": "8.78° × 6.59°", "Digitaler Zoom": "1x - 8x"}
            },
            Electronics: {
                en: {"Display type": "OLED", "Display resolutie": "1024 × 768 px", "Battery": "18650 battery (built-in) and customized battery (external)"},
                nl: {"Beeldschermtype": "OLED", "Beeldschermresolutie": "1024 × 768 px", "Batterij": "18650 batterij (ingebouwd) en aangepaste batterij (extern)"},
                de: {"Anzeigetyp": "OLED", "Anzeige Auflösung": "1024 × 768 px", "Batterie": "18650 batterie (eingebaut) und kundenspezifische Batterie (extern)"}
            },
            Operating: {
                en: {"Encapsulation": "IP67", "Internal memory": "16 GB", "Operating temperature range": "-30°C to 50°C", "Weight (without replaceable battery)": "≤990 g", "Dimensions": "377 × 107 × 69 mm", "USB-interface": "Type C, supporting power delivery, charging, and reading data from memory card"},
                nl: {"Waterdichtheid": "IP67", "Intern geheugen": "16 GB", "Bedrijfstemperatuurbereik": "-30°C to 50°C", "Gewicht (zonder vervangbare batterij)": "≤990 g", "Afmetingen": "377 × 107 × 69 mm", "USB-aansluiting": "Type C, ondersteunt voeding, opladen en lezen van gegevens van geheugenkaart"},
                de: {"Wasserdicht": "IP67", "Interne Speicher": "16 GB", "Betriebstemperaturbereich": "-30°C to 50°C", "Gewicht (ohne auswechselbare Batterie)": "≤990 g", "Abmessungen": "377 × 107 × 69 mm", "USB-Anschluss": "Typ C, unterstützt Stromzufuhr, Aufladen und Lesen von Daten von der Speicherkarte"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: false, filterVoorzet: false, filterRiflescope: true}
	},

    {
		id: "wm22",
        orderID: "104",
        type: "thermal",
        title: {nl: "Lahoux Spotter NL 325", en: "Lahoux Spotter NL 325", de: "Lahoux Spotter NL 325"},
        url: "Lahoux-Spotter-NL-325",
        price: "1234.48",
        new: false,
        spotlight: { nl: "De Lahoux Spotter NL 325 is een hoogwaardige, compacte warmtebeeldcamera bedoeld voor observatie. Door gebruik te maken van de nieuwste technologie biedt de Lahoux Spotter NL 325 het scherpste beeld en de beste prestaties in zijn klasse.", 
                    en: "The Lahoux Spotter NL 325 is a high-quality, compact thermal imager intended for observation. By using the latest technology, the Lahoux Spotter 325 offers the sharpest image and the best performance in its class.", 
                    de: "Die Lahoux Spotter NL 325 ist eine hochwertiges hochwertige, kompakte Wärmebildkamera, die zur Beobachtung bestimmt ist. Mit der neuesten Technologie bietet das Lahoux Spotter NL 325 das schärfste Bild und die beste Leistung seiner Klasse."},
        shortDes: { nl: "De Lahoux Spotter NL 325 is een hoogwaardige, compacte warmtebeeldcamera bedoeld voor observatie. Door gebruik te maken van de nieuwste technologie biedt de Lahoux Spotter NL 325 het scherpste beeld en de beste prestaties in zijn klasse.", 
                    en: "The Lahoux Spotter NL 325 is a high-quality, compact thermal imager intended for observation. By using the latest technology, the Lahoux Spotter 325 offers the sharpest image and the best performance in its class.", 
                    de: "Die Lahoux Spotter NL 325 ist eine hochwertiges hochwertige, kompakte Wärmebildkamera, die zur Beobachtung bestimmt ist. Mit der neuesten Technologie bietet das Lahoux Spotter NL 325 das schärfste Bild und die beste Leistung seiner Klasse."},
        longDes_1: {    nl: "De Lahoux Spotter NL 325 is een hoogwaardige, compacte warmtebeeldcamera bedoeld voor observatie. Door gebruik te maken van de nieuwste technologie biedt de Lahoux Spotter NL 325 het scherpste beeld en de beste prestaties in zijn klasse. Niets blijft meer verborgen in uw jachtgebied, ongeacht het weer.", 
                        en: "The Lahoux Spotter NL 325 is a high-quality, compact thermal imager intended for observation. By using the latest technology, the Lahoux Spotter NL 325 offers the sharpest image and the best performance in its class. Nothing remains hidden any longer in your hunting ground, regardless of the weather.", 
                        de: "Die Lahoux Spotter NL 325 ist eine hochwertiges hochwertige, kompakte Wärmebildkamera, die zur Beobachtung bestimmt ist. Mit der neuesten Technologie bietet das Lahoux Spotter NL 325 das schärfste Bild und die beste Leistung seiner Klasse. Unabhängig vom Wetter bleibt in Ihrem Jagdrevier nichts verborgen."},
        longDes_2: {    nl: "De energiezuinige elektronica en lange batterijduur maken langdurig ononderbroken gebruik mogelijk. Het ongecompliceerde ergonomische ontwerp biedt optimaal comfort en een hoge mate van inzetbaarheid. De Lahoux Spotter NL 325 wordt geheel in Nederland geproduceerd volgens de hoogste kwaliteitsnormen", 
                        en: "The energy-efficient electronics and long battery life make long-term uninterrupted use possible. The uncomplicated ergonomic design offers optimum comfort and a high degree of employability. The Lahoux Spotter NL 325 is produced entirely in the Netherlands, to the highest quality standards.", 
                        de: "Die energie-effiziente Elektronik und die lange Akkulaufzeit ermöglichen eine langfristige unterbrechungsfreie Nutzung. Das unkomplizierte ergonomische Design bietet optimalen Komfort und ein hohes Maß an Beschäftigungsfähigkeit. Die Lahoux Spotter NL 325 wird vollständig in den Niederlanden nach höchsten Qualitätsstandards hergestellt."},
        pros: {         nl: ["Grote betrouwbaarheid en ongeëvenaarde prestaties in het donker en bij slechte weersomstandigheden.", "Lange looptijd. Gemakkelijk verwisselbare, oplaadbare batterij. Polariteit wordt automatisch herkend", "Mooie, compacte vormgeving.", "Licht en ook geschikt voor kleine handen. Past makkelijk in een tas.", "Eenvoudige en intuïtieve bediening. (Ook met handschoenen)", "Breed gezichtsveld oculair met Schott-glas, voor optimaal kijkcomfort.", "USB-C-connector voor snel opladen of gebruik van een powerbank.", "Draadloze overdracht via WiFi. Interne opslag (32 GB) van foto en video.", "Kleurendisplay voor een optimale beeldweergave."], 
                        en: ["Highest reliability and unrivaled performance in the dark and in bad weather conditions.", "Long runtime. Easily exchangeable, rechargeable battery. Polarity is automatically recognized.", "Elegant and compact design.", "Lightweight and also suited for smaller hands. Fits easily into a bag.", "Simple and intuitive operation (also with gloves).", "Wide field of view eyepiece with Schott glass, for optimum viewing comfort.", "USB-C connector for fast charging, or use of power bank.", "Wireless transfer via WiFi. Internal storage (32 GB) of photos and videos.", "Colour display for an optimum image."], 
                        de: ["Höchste Zuverlässigkeit und unübertroffene Leistung im Dunkeln und bei schlechten Wetterbedingungen", "Hohe Akkulaufzeit. Leicht austauschbarer Wechsel Akku (automatische Polaritätserkennung).", "Elegantes und kompaktes Design.", "Ein Leichtgewicht die auch für kleinere Hände geeignet ist. Passt in jede Tasche und ist sofort einsatzbereit.", "Einfachste, intuitive Bedienung (auch mit Handschuhen).", "Okular mit Schott-Glas und breitem Sichtfeld, für optimales Sehkomfort.", "USB-C-Anschluss für schnelles Laden oder zur Verwendung mit einer Powerbank.", "Drahtlose Übertragung über WiFi. Interne Speicherung (Speichergröße 32 GB) von Fotos und Videos.", "4-Colour Farbdisplay für eine optimale Bilddarstellung."]
    
            },
        number: "02-0002-03526",
        ean: "6090301597525",
        leveromvang: [2, 3, 4, 5, 16],
        accessories: ["as13","as25"],
        related: ["wm23", "wm24", "wm25"],
        flyer: {nl:"Lahoux_Spotter_NL_Serie_NL.pdf", en: "Lahoux_Spotter_NL_Serie_EN.pdf", de: "Lahoux_Spotter_NL_Serie_DE.pdf"},
        images: ["Lahoux-Spotter-NL-325.png", "Lahoux-Spotter-NL-325-2.png", "Lahoux-Spotter-NL-325-3.png", "Lahoux-Spotter-NL-325-4.png", "Lahoux-Spotter-NL-325-5.png"],
        dri: "Warmtebeeld_Spotters.png",
        footnote_battery: true,
        techs: { 
            Detector: {
                en: {"Sensor resolution": "384x288", "Pixel pitch": "12 μm", "Frame rate": "50 Hz", "Spectral range": "8-14 μm", "Sensitivity to heat (NETD)": "<40 mK"},
                nl: {"Sensorresolutie": "384x288", "Pixelafstand": "12 μm", "Beeldfrequentie": "50 Hz", "Spectraalbereik": "8-14 μm", "Gevoeligheid voor warmte (NETD)": "<40 mK"},
                de: {"Sensorauflösung": "384x288", "Pixelabstand": "12 μm", "Bildfrequenz": "50 Hz", "Spektrallbereich": "8-14 μm", "Empfindlichkeit gegenüber Hitze (NETD)": "<40 mK"}
            },
            Optics: {
                en: {"Lens": "25 mm", "Relavtive apeture": "F/1.0", "Field of view": "10.5° × 7.91°", "Optical magnification": "2,3x, 4,6x, 9,2x", "Digital zoom": "1×, 2×, 4x"},
                nl: {"Lens": "25 mm", "Relatieve lensopening": "F/1.0", "Gezichtsveld": "10.5° × 7.91°", "Optische vergroting": "2,3x, 4,6x, 9,2x", "Digitale zoom": "1×, 2×, 4x"},
                de: {"Linse": "25 mm", "Relative Blende": "F/1.0", "Sichtfeld": "10.5° × 7.91°", "Optische Vergrößerung": "2,3x, 4,6x, 9,2x", "Digitaler Zoom": "1×, 2×, 4x"}
            },
            Electronics: {
                en: {"Display": "OLED 1024×768", "Video output (analog)": "Wifi", "Battery": "18650 li-ion battery"},
                nl: {"Beeldscherm": "OLED 1024×768", "Video-uitgang (analoog)": "Wifi", "Batterij": "18650 li-ion batterij"},
                de: {"Anzeige": "OLED 1024×768", "Videoausgang (analog)": "Wifi", "Batterie": "18650 li-ion batterie"}
            },
            Operating: {
                en: {"Dimensions (l×w×h)": "168×61×50", "Weight": "0.41 kg", "Waterproof": "IP67", "Temperature range": "-20 °C tot 40 °C", "Battery life (power save mode)": "6-8 hours *"},
                nl: {"Afmetingen (l×w×h)": "168×61×50", "Gewicht": "0.41 kg", "Waterdichtheid": "IP67", "Temperatuurbereik": "-20 °C tot 40 °C", "Batterijduur (energiebesparende modus)": "6-8 uur *"},
                de: {"Abmessungen (l×w×h)": "168×61×50", "Gewicht": "0.41 kg", "Wasserdicht": "IP67", "Temperaturbereich": "-20 °C tot 40 °C", "Batterielaufzeit (Energiesparmodus)": "6-8 Stunden *"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
	},

    {
		id: "wm23",
        orderID: "105",
        type: "thermal",
        title: {nl: "Lahoux Spotter NL 350", en: "Lahoux Spotter NL 350", de: "Lahoux Spotter NL 350"},
        url: "Lahoux-Spotter-NL-350",
        price: "1372.41",
        new: false,
        shortDes: { nl: "De Lahoux Spotter NL 350 is een hoogwaardige, compacte warmtebeeldcamera bedoeld voor observatie. Door gebruik te maken van de nieuwste technologie biedt de Lahoux Spotter NL 350 het scherpste beeld en de beste prestaties in zijn klasse.", 
                    en: "The Lahoux Spotter NL 350 is a high-quality, compact thermal imager intended for observation. By using the latest technology, the Lahoux Spotter NL 350 offers the sharpest image and the best performance in its class.", 
                    de: "Die Lahoux Spotter NL 350 ist eine hochwertiges hochwertige, kompakte Wärmebildkamera, die zur Beobachtung bestimmt ist. Mit der neuesten Technologie bietet das Lahoux Spotter NL 350 das schärfste Bild und die beste Leistung seiner Klasse."},
        longDes_1: {    nl: "De Lahoux Spotter NL 350 is een hoogwaardige, compacte warmtebeeldcamera bedoeld voor observatie. Door gebruik te maken van de nieuwste technologie biedt de Lahoux Spotter NL 350 het scherpste beeld en de beste prestaties in zijn klasse. Niets blijft meer verborgen in uw jachtgebied, ongeacht het weer.", 
                        en: "The Lahoux Spotter NL 350 is a high-quality, compact thermal imager intended for observation. By using the latest technology, the Lahoux Spotter NL 350 offers the sharpest image and the best performance in its class. Nothing remains hidden any longer in your hunting ground, regardless of the weather.", 
                        de: "Die Lahoux Spotter NL 350 ist eine hochwertiges hochwertige, kompakte Wärmebildkamera, die zur Beobachtung bestimmt ist. Mit der neuesten Technologie bietet das Lahoux Spotter NL 350 das schärfste Bild und die beste Leistung seiner Klasse. Unabhängig vom Wetter bleibt in Ihrem Jagdrevier nichts verborgen."},
        longDes_2: {    nl: "De energiezuinige elektronica en lange batterijduur maken langdurig ononderbroken gebruik mogelijk. Het ongecompliceerde ergonomische ontwerp biedt optimaal comfort en een hoge mate van inzetbaarheid. De Lahoux Spotter NL 350 wordt geheel in Nederland geproduceerd volgens de hoogste kwaliteitsnormen", 
                        en: "The energy-efficient electronics and long battery life make long-term uninterrupted use possible. The uncomplicated ergonomic design offers optimum comfort and a high degree of employability. The Lahoux Spotter NL 350 is produced entirely in the Netherlands, to the highest quality standards.", 
                        de: "Die energie-effiziente Elektronik und die lange Akkulaufzeit ermöglichen eine langfristige unterbrechungsfreie Nutzung. Das unkomplizierte ergonomische Design bietet optimalen Komfort und ein hohes Maß an Beschäftigungsfähigkeit. Die Lahoux Spotter NL 350 wird vollständig in den Niederlanden nach höchsten Qualitätsstandards hergestellt."},
        pros: {         nl: ["Grote betrouwbaarheid en ongeëvenaarde prestaties in het donker en bij slechte weersomstandigheden.", "Lange looptijd. Gemakkelijk verwisselbare, oplaadbare batterij. Polariteit wordt automatisch herkend", "Mooie, compacte vormgeving.", "Licht en ook geschikt voor kleine handen. Past makkelijk in een tas.", "Eenvoudige en intuïtieve bediening. (Ook met handschoenen)", "Breed gezichtsveld oculair met Schott-glas, voor optimaal kijkcomfort.", "USB-C-connector voor snel opladen of gebruik van een powerbank.", "Draadloze overdracht via WiFi. Interne opslag (32 GB) van foto en video.", "Kleurendisplay voor een optimale beeldweergave."], 
                        en: ["Highest reliability and unrivaled performance in the dark and in bad weather conditions.", "Long runtime. Easily exchangeable, rechargeable battery. Polarity is automatically recognized.", "Elegant and compact design.", "Lightweight and also suited for smaller hands. Fits easily into a bag.", "Simple and intuitive operation (also with gloves).", "Wide field of view eyepiece with Schott glass, for optimum viewing comfort.", "USB-C connector for fast charging, or use of power bank.", "Wireless transfer via WiFi. Internal storage (32 GB) of photos and videos.", "Colour display for an optimum image."], 
                        de: ["Höchste Zuverlässigkeit und unübertroffene Leistung im Dunkeln und bei schlechten Wetterbedingungen", "Hohe Akkulaufzeit. Leicht austauschbarer Wechsel Akku (automatische Polaritätserkennung).", "Elegantes und kompaktes Design.", "Ein Leichtgewicht die auch für kleinere Hände geeignet ist. Passt in jede Tasche und ist sofort einsatzbereit.", "Einfachste, intuitive Bedienung (auch mit Handschuhen).", "Okular mit Schott-Glas und breitem Sichtfeld, für optimales Sehkomfort.", "USB-C-Anschluss für schnelles Laden oder zur Verwendung mit einer Powerbank.", "Drahtlose Übertragung über WiFi. Interne Speicherung (Speichergröße 32 GB) von Fotos und Videos.", "4-Colour Farbdisplay für eine optimale Bilddarstellung."]
    
            },
        number: "02-0002-03527",
        ean: "6090301613638",
        leveromvang: [2, 3, 4, 5, 16],
        accessories: ["as13","as25"],
        related: ["wm22", "wm24", "wm25"],
        flyer: {nl:"Lahoux_Spotter_NL_Serie_NL.pdf", en: "Lahoux_Spotter_NL_Serie_EN.pdf", de: "Lahoux_Spotter_NL_Serie_DE.pdf"},
        images: ["Lahoux-Spotter-NL-350.png", "Lahoux-Spotter-NL-350-2.png", "Lahoux-Spotter-NL-350-3.png", "Lahoux-Spotter-NL-350-4.png", "Lahoux-Spotter-NL-350-5.png"],
        dri: "Warmtebeeld_Spotters.png",
        footnote_battery: true,
        techs: { 
            Detector: {
                en: {"Sensor resolution": "384x288", "Pixel pitch": "12 μm", "Frame rate": "50 Hz", "Spectral range": "8-14 μm", "Sensitivity to heat (NETD)": "<40 mK"},
                nl: {"Sensorresolutie": "384x288", "Pixelafstand": "12 μm", "Beeldfrequentie": "50 Hz", "Spectraalbereik": "8-14 μm", "Gevoeligheid voor warmte (NETD)": "<40 mK"},
                de: {"Sensorauflösung": "384x288", "Pixelabstand": "12 μm", "Bildfrequenz": "50 Hz", "Spektrallbereich": "8-14 μm", "Empfindlichkeit gegenüber Hitze (NETD)": "<40 mK"}
            },
            Optics: {
                en: {"Lens": "50 mm", "Relavtive apeture": "F/1.2", "Field of view": "4.8° × 4.0°", "Optical magnification": "5x, 10x, 20,2x", "Digital zoom": "1×, 2×, 4x"},
                nl: {"Lens": "50 mm", "Relatieve lensopening": "F/1.2", "Gezichtsveld": "4.8° × 4.0°", "Optische vergroting": "5x, 10x, 20,2x", "Digitale zoom": "1×, 2×, 4x"},
                de: {"Linse": "50 mm", "Relative Blende": "F/1.2", "Sichtfeld": "4.8° × 4.0°", "Optische Vergrößerung": "5x, 10x, 20,2x", "Digitaler Zoom": "1×, 2×, 4x"}
            },
            Electronics: {
                en: {"Display": "OLED 1024×768", "Video output (analog)": "Wifi", "Battery": "18650 li-ion battery"},
                nl: {"Beeldscherm": "OLED 1024×768", "Video-uitgang (analoog)": "Wifi", "Batterij": "18650 li-ion batterij"},
                de: {"Anzeige": "OLED 1024×768", "Videoausgang (analog)": "Wifi", "Batterie": "18650 li-ion batterie"}
            },
            Operating: {
                en: {"Dimensions (l×w×h)": "186×63×55", "Weight": "0.46 kg", "Waterproof": "IP67", "Temperature range": "-20 °C tot 40 °C", "Battery life (power save mode)": "6-8 hours *"},
                nl: {"Afmetingen (l×w×h)": "186×63×55", "Gewicht": "0.46 kg", "Waterdichtheid": "IP67", "Temperatuurbereik": "-20 °C tot 40 °C", "Batterijduur (energiebesparende modus)": "6-8 uur *"},
                de: {"Abmessungen (l×w×h)": "186×63×55", "Gewicht": "0.46 kg", "Wasserdicht": "IP67", "Temperaturbereich": "-20 °C tot 40 °C", "Batterielaufzeit (Energiesparmodus)": "6-8 Stunden *"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
	},

    {
		id: "wm24",
        orderID: "106",
        type: "thermal",
        title: {nl: "Lahoux Spotter NL 625", en: "Lahoux Spotter NL 625", de: "Lahoux Spotter NL 625"},
        url: "Lahoux-Spotter-NL-625",
        price: "2062.07",
        new: false,
        shortDes: { nl: "De Lahoux Spotter NL 625 is een hoogwaardige, compacte warmtebeeldcamera bedoeld voor observatie. Door gebruik te maken van de nieuwste technologie biedt de Lahoux Spotter NL 625 het scherpste beeld en de beste prestaties in zijn klasse.", 
                    en: "The Lahoux Spotter NL 625 is a high-quality, compact thermal imager intended for observation. By using the latest technology, the Lahoux Spotter NL 625 offers the sharpest image and the best performance in its class.", 
                    de: "Die Lahoux Spotter NL 625 ist eine hochwertiges hochwertige, kompakte Wärmebildkamera, die zur Beobachtung bestimmt ist. Mit der neuesten Technologie bietet das Lahoux Spotter NL 625 das schärfste Bild und die beste Leistung seiner Klasse."},
        longDes_1: {    nl: "De Lahoux Spotter NL 625 is een hoogwaardige, compacte warmtebeeldcamera bedoeld voor observatie. Door gebruik te maken van de nieuwste technologie biedt de Lahoux Spotter NL 625 het scherpste beeld en de beste prestaties in zijn klasse. Niets blijft meer verborgen in uw jachtgebied, ongeacht het weer.", 
                        en: "The Lahoux Spotter NL 625 is a high-quality, compact thermal imager intended for observation. By using the latest technology, the Lahoux Spotter NL 625 offers the sharpest image and the best performance in its class. Nothing remains hidden any longer in your hunting ground, regardless of the weather.", 
                        de: "Die Lahoux Spotter NL 625 ist eine hochwertiges hochwertige, kompakte Wärmebildkamera, die zur Beobachtung bestimmt ist. Mit der neuesten Technologie bietet das Lahoux Spotter NL 625 das schärfste Bild und die beste Leistung seiner Klasse. Unabhängig vom Wetter bleibt in Ihrem Jagdrevier nichts verborgen."},
        longDes_2: {    nl: "De energiezuinige elektronica en lange batterijduur maken langdurig ononderbroken gebruik mogelijk. Het ongecompliceerde ergonomische ontwerp biedt optimaal comfort en een hoge mate van inzetbaarheid. De Lahoux Spotter NL 625 wordt geheel in Nederland geproduceerd volgens de hoogste kwaliteitsnormen", 
                        en: "The energy-efficient electronics and long battery life make long-term uninterrupted use possible. The uncomplicated ergonomic design offers optimum comfort and a high degree of employability. The Lahoux Spotter NL 625 is produced entirely in the Netherlands, to the highest quality standards.", 
                        de: "Die energie-effiziente Elektronik und die lange Akkulaufzeit ermöglichen eine langfristige unterbrechungsfreie Nutzung. Das unkomplizierte ergonomische Design bietet optimalen Komfort und ein hohes Maß an Beschäftigungsfähigkeit. Die Lahoux Spotter NL 625 wird vollständig in den Niederlanden nach höchsten Qualitätsstandards hergestellt."},
        pros: {         nl: ["Grote betrouwbaarheid en ongeëvenaarde prestaties in het donker en bij slechte weersomstandigheden.", "Lange looptijd. Gemakkelijk verwisselbare, oplaadbare batterij. Polariteit wordt automatisch herkend", "Mooie, compacte vormgeving.", "Licht en ook geschikt voor kleine handen. Past makkelijk in een tas.", "Eenvoudige en intuïtieve bediening. (Ook met handschoenen)", "Breed gezichtsveld oculair met Schott-glas, voor optimaal kijkcomfort.", "USB-C-connector voor snel opladen of gebruik van een powerbank.", "Draadloze overdracht via WiFi. Interne opslag (32 GB) van foto en video.", "Kleurendisplay voor een optimale beeldweergave."], 
                        en: ["Highest reliability and unrivaled performance in the dark and in bad weather conditions.", "Long runtime. Easily exchangeable, rechargeable battery. Polarity is automatically recognized.", "Elegant and compact design.", "Lightweight and also suited for smaller hands. Fits easily into a bag.", "Simple and intuitive operation (also with gloves).", "Wide field of view eyepiece with Schott glass, for optimum viewing comfort.", "USB-C connector for fast charging, or use of power bank.", "Wireless transfer via WiFi. Internal storage (32 GB) of photos and videos.", "Colour display for an optimum image."], 
                        de: ["Höchste Zuverlässigkeit und unübertroffene Leistung im Dunkeln und bei schlechten Wetterbedingungen", "Hohe Akkulaufzeit. Leicht austauschbarer Wechsel Akku (automatische Polaritätserkennung).", "Elegantes und kompaktes Design.", "Ein Leichtgewicht die auch für kleinere Hände geeignet ist. Passt in jede Tasche und ist sofort einsatzbereit.", "Einfachste, intuitive Bedienung (auch mit Handschuhen).", "Okular mit Schott-Glas und breitem Sichtfeld, für optimales Sehkomfort.", "USB-C-Anschluss für schnelles Laden oder zur Verwendung mit einer Powerbank.", "Drahtlose Übertragung über WiFi. Interne Speicherung (Speichergröße 32 GB) von Fotos und Videos.", "4-Colour Farbdisplay für eine optimale Bilddarstellung."]
    
            },
        number: "02-0002-03528",
        ean: "6090301618626",
        leveromvang: [2, 3, 4, 5, 16],
        accessories: ["as13","as25"],
        related: ["wm22", "wm23", "wm25"],
        flyer: {nl:"Lahoux_Spotter_NL_Serie_NL.pdf", en: "Lahoux_Spotter_NL_Serie_EN.pdf", de: "Lahoux_Spotter_NL_Serie_DE.pdf"},
        images: ["Lahoux-Spotter-NL-625.png", "Lahoux-Spotter-NL-625-2.png", "Lahoux-Spotter-NL-625-3.png", "Lahoux-Spotter-NL-625-4.png", "Lahoux-Spotter-NL-625-5.png"],
        dri: "Warmtebeeld_Spotters.png",
        footnote_battery: true,
        techs: { 
            Detector: {
                en: {"Sensor resolution": "640×512", "Pixel pitch": "12 μm", "Frame rate": "50 Hz", "Spectral range": "8-14 μm", "Sensitivity to heat (NETD)": "<40 mK"},
                nl: {"Sensorresolutie": "640×512", "Pixelafstand": "12 μm", "Beeldfrequentie": "50 Hz", "Spectraalbereik": "8-14 μm", "Gevoeligheid voor warmte (NETD)": "<40 mK"},
                de: {"Sensorauflösung": "640×512", "Pixelabstand": "12 μm", "Bildfrequenz": "50 Hz", "Spektrallbereich": "8-14 μm", "Empfindlichkeit gegenüber Hitze (NETD)": "<40 mK"}
            },
            Optics: {
                en: {"Lens": "25 mm", "Relavtive apeture": "F/1.0", "Field of view": "17.5° × 14°", "Optical magnification": "1,4x, 2,8x, 5,5x", "Digital zoom": "1×, 2×, 4x"},
                nl: {"Lens": "25 mm", "Relatieve lensopening": "F/1.0", "Gezichtsveld": "17.5° × 14°", "Optische vergroting": "1,4x, 2,8x, 5,5x", "Digitale zoom": "1×, 2×, 4x"},
                de: {"Linse": "25 mm", "Relative Blende": "F/1.0", "Sichtfeld": "17.5° × 14°", "Optische Vergrößerung": "1,4x, 2,8x, 5,5x", "Digitaler Zoom": "1×, 2×, 4x"}
            },
            Electronics: {
                en: {"Display": "OLED 1024×768", "Video output (analog)": "Wifi", "Battery": "18650 li-ion battery"},
                nl: {"Beeldscherm": "OLED 1024×768", "Video-uitgang (analoog)": "Wifi", "Batterij": "18650 li-ion batterij"},
                de: {"Anzeige": "OLED 1024×768", "Videoausgang (analog)": "Wifi", "Batterie": "18650 li-ion batterie"}
            },
            Operating: {
                en: {"Dimensions (l×w×h)": "168×61×50", "Weight": "0.41 kg", "Waterproof": "IP67", "Temperature range": "-20 °C tot 40 °C", "Battery life (power save mode)": "6-8 hours *"},
                nl: {"Afmetingen (l×w×h)": "168×61×50", "Gewicht": "0.41 kg", "Waterdichtheid": "IP67", "Temperatuurbereik": "-20 °C tot 40 °C", "Batterijduur (energiebesparende modus)": "6-8 uur *"},
                de: {"Abmessungen (l×w×h)": "168×61×50", "Gewicht": "0.41 kg", "Wasserdicht": "IP67", "Temperaturbereich": "-20 °C tot 40 °C", "Batterielaufzeit (Energiesparmodus)": "6-8 Stunden *"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
	},

    {
		id: "wm25",
        orderID: "107",
        type: "thermal",
        title: {nl: "Lahoux Spotter NL 650", en: "Lahoux Spotter NL 650", de: "Lahoux Spotter NL 650"},
        url: "Lahoux-Spotter-NL-650",
        price: "2337.93",
        new: false,
        shortDes: { nl: "De Lahoux Spotter NL 650 is een hoogwaardige, compacte warmtebeeldcamera bedoeld voor observatie. Door gebruik te maken van de nieuwste technologie biedt de Lahoux Spotter NL 650 het scherpste beeld en de beste prestaties in zijn klasse.", 
                    en: "The Lahoux Spotter NL 650 is a high-quality, compact thermal imager intended for observation. By using the latest technology, the Lahoux Spotter NL 650 offers the sharpest image and the best performance in its class.", 
                    de: "Die Lahoux Spotter NL 650 ist eine hochwertiges hochwertige, kompakte Wärmebildkamera, die zur Beobachtung bestimmt ist. Mit der neuesten Technologie bietet das Lahoux Spotter NL 650 das schärfste Bild und die beste Leistung seiner Klasse."},
        longDes_1: {    nl: "De Lahoux Spotter NL 650 is een hoogwaardige, compacte warmtebeeldcamera bedoeld voor observatie. Door gebruik te maken van de nieuwste technologie biedt de Lahoux Spotter NL 650 het scherpste beeld en de beste prestaties in zijn klasse. Niets blijft meer verborgen in uw jachtgebied, ongeacht het weer.", 
                        en: "The Lahoux Spotter NL 650 is a high-quality, compact thermal imager intended for observation. By using the latest technology, the Lahoux Spotter NL 650 offers the sharpest image and the best performance in its class. Nothing remains hidden any longer in your hunting ground, regardless of the weather.", 
                        de: "Die Lahoux Spotter NL 650 ist eine hochwertiges hochwertige, kompakte Wärmebildkamera, die zur Beobachtung bestimmt ist. Mit der neuesten Technologie bietet das Lahoux Spotter NL 650 das schärfste Bild und die beste Leistung seiner Klasse. Unabhängig vom Wetter bleibt in Ihrem Jagdrevier nichts verborgen."},
        longDes_2: {    nl: "De energiezuinige elektronica en lange batterijduur maken langdurig ononderbroken gebruik mogelijk. Het ongecompliceerde ergonomische ontwerp biedt optimaal comfort en een hoge mate van inzetbaarheid. De Lahoux Spotter NL 650 wordt geheel in Nederland geproduceerd volgens de hoogste kwaliteitsnormen", 
                        en: "The energy-efficient electronics and long battery life make long-term uninterrupted use possible. The uncomplicated ergonomic design offers optimum comfort and a high degree of employability. The Lahoux Spotter NL 650 is produced entirely in the Netherlands, to the highest quality standards.", 
                        de: "Die energie-effiziente Elektronik und die lange Akkulaufzeit ermöglichen eine langfristige unterbrechungsfreie Nutzung. Das unkomplizierte ergonomische Design bietet optimalen Komfort und ein hohes Maß an Beschäftigungsfähigkeit. Die Lahoux Spotter NL 650 wird vollständig in den Niederlanden nach höchsten Qualitätsstandards hergestellt."},
        pros: {         nl: ["Grote betrouwbaarheid en ongeëvenaarde prestaties in het donker en bij slechte weersomstandigheden.", "Lange looptijd. Gemakkelijk verwisselbare, oplaadbare batterij. Polariteit wordt automatisch herkend", "Mooie, compacte vormgeving.", "Licht en ook geschikt voor kleine handen. Past makkelijk in een tas.", "Eenvoudige en intuïtieve bediening. (Ook met handschoenen)", "Breed gezichtsveld oculair met Schott-glas, voor optimaal kijkcomfort.", "USB-C-connector voor snel opladen of gebruik van een powerbank.", "Draadloze overdracht via WiFi. Interne opslag (32 GB) van foto en video.", "Kleurendisplay voor een optimale beeldweergave."], 
                        en: ["Highest reliability and unrivaled performance in the dark and in bad weather conditions.", "Long runtime. Easily exchangeable, rechargeable battery. Polarity is automatically recognized.", "Elegant and compact design.", "Lightweight and also suited for smaller hands. Fits easily into a bag.", "Simple and intuitive operation (also with gloves).", "Wide field of view eyepiece with Schott glass, for optimum viewing comfort.", "USB-C connector for fast charging, or use of power bank.", "Wireless transfer via WiFi. Internal storage (32 GB) of photos and videos.", "Colour display for an optimum image."], 
                        de: ["Höchste Zuverlässigkeit und unübertroffene Leistung im Dunkeln und bei schlechten Wetterbedingungen", "Hohe Akkulaufzeit. Leicht austauschbarer Wechsel Akku (automatische Polaritätserkennung).", "Elegantes und kompaktes Design.", "Ein Leichtgewicht die auch für kleinere Hände geeignet ist. Passt in jede Tasche und ist sofort einsatzbereit.", "Einfachste, intuitive Bedienung (auch mit Handschuhen).", "Okular mit Schott-Glas und breitem Sichtfeld, für optimales Sehkomfort.", "USB-C-Anschluss für schnelles Laden oder zur Verwendung mit einer Powerbank.", "Drahtlose Übertragung über WiFi. Interne Speicherung (Speichergröße 32 GB) von Fotos und Videos.", "4-Colour Farbdisplay für eine optimale Bilddarstellung."]
    
            },
        number: "02-0002-03529",
        ean: "6090301637672",
        leveromvang: [2, 3, 4, 5, 16],
        accessories: ["as13","as25"],
        related: ["wm22", "wm23", "wm24"],
        flyer: {nl:"Lahoux_Spotter_NL_Serie_NL.pdf", en: "Lahoux_Spotter_NL_Serie_EN.pdf", de: "Lahoux_Spotter_NL_Serie_DE.pdf"},
        images: ["Lahoux-Spotter-NL-650.png", "Lahoux-Spotter-NL-650-2.png", "Lahoux-Spotter-NL-650-3.png", "Lahoux-Spotter-NL-650-4.png", "Lahoux-Spotter-NL-650-5.png"],
        dri: "Warmtebeeld_Spotters.png",
        videos: {
            nl: {"Eagle-Owl-with-Spotter-NL-650.mp4": "Oehoe (Bubo Bubo) vlak na zijn vrijlating, na herstel in de vogelopvang. Gefilmd met de Lahoux Spotter NL 650."},
            en: {"Eagle-Owl-with-Spotter-NL-650.mp4": "Eagle owl (Bubo Bubo) just after its release, after recovery at the bird sanctuary. Filmed with the Lahoux Spotter NL 650."},
            de: {"Eagle-Owl-with-Spotter-NL-650.mp4": "Uhu (Bubo Bubo) kurz nach seiner Freilassung, nach der Erholung im Vogelschutzgebiet. Gefilmt mit dem Lahoux Spotter NL 650."}
        },
        footnote_battery: true,
        techs: { 
            Detector: {
                en: {"Sensor resolution": "640×512", "Pixel pitch": "12 μm", "Frame rate": "50 Hz", "Spectral range": "8-14 μm", "Sensitivity to heat (NETD)": "<40 mK"},
                nl: {"Sensorresolutie": "640×512", "Pixelafstand": "12 μm", "Beeldfrequentie": "50 Hz", "Spectraalbereik": "8-14 μm", "Gevoeligheid voor warmte (NETD)": "<40 mK"},
                de: {"Sensorauflösung": "640×512", "Pixelabstand": "12 μm", "Bildfrequenz": "50 Hz", "Spektrallbereich": "8-14 μm", "Empfindlichkeit gegenüber Hitze (NETD)": "<40 mK"}
            },
            Optics: {
                en: {"Lens": "50 mm", "Relavtive apeture": "F/1.2", "Field of view": "8.8° × 7.0°", "Optical magnification": "2,8x, 5,5x, 11,0x", "Digital zoom": "1×, 2×, 4x"},
                nl: {"Lens": "50 mm", "Relatieve lensopening": "F/1.2", "Gezichtsveld": "8.8° × 7.0°", "Optische vergroting": "2,8x, 5,5x, 11,0x", "Digitale zoom": "1×, 2×, 4x"},
                de: {"Linse": "50 mm", "Relative Blende": "F/1.2", "Sichtfeld": "8.8° × 7.0°", "Optische Vergrößerung": "2,8x, 5,5x, 11,0x", "Digitaler Zoom": "1×, 2×, 4x"}
            },
            Electronics: {
                en: {"Display": "OLED 1024×768", "Video output (analog)": "Wifi", "Battery": "18650 li-ion battery"},
                nl: {"Beeldscherm": "OLED 1024×768", "Video-uitgang (analoog)": "Wifi", "Batterij": "18650 li-ion batterij"},
                de: {"Anzeige": "OLED 1024×768", "Videoausgang (analog)": "Wifi", "Batterie": "18650 li-ion batterie"}
            },
            Operating: {
                en: {"Dimensions (l×w×h)": "186×63×55", "Weight": "0.46 kg", "Waterproof": "IP67", "Temperature range": "-20 °C tot 40 °C", "Battery life (power save mode)": "6-8 hours *"},
                nl: {"Afmetingen (l×w×h)": "186×63×55", "Gewicht": "0.46 kg", "Waterdichtheid": "IP67", "Temperatuurbereik": "-20 °C tot 40 °C", "Batterijduur (energiebesparende modus)": "6-8 uur *"},
                de: {"Abmessungen (l×w×h)": "186×63×55", "Gewicht": "0.46 kg", "Wasserdicht": "IP67", "Temperaturbereich": "-20 °C tot 40 °C", "Batterielaufzeit (Energiesparmodus)": "6-8 Stunden *"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
	},
    {
        id: "wm26",
        orderID: "114",
        type: "thermal",
        title: {nl: "Lahoux Bino Elite 50 LRF", en: "Lahoux Bino Elite 50 LRF", de: "Lahoux Bino Elite 50 LRF"},
        url: "Lahoux-Bino-Elite-50",
        price: "3310.34",
        new: false,
        shortDes: { nl: "De Lahoux Bino Elite 50 is een thermische observatiecamera met laser afstandsmeter, speciaal ontworpen voor langdurig observatiegebruik en een uitstekend beeld. Met beide ogen open, wordt de oogvermoeidheid aanzienlijk verminderd en het uitstekende beeld goed waargenomen tijdens langere observatie.", 
                    en: "The Lahoux Bino Elite 50 is a thermal, laser range finding observation goggle, especially designed for long observation use and an excellent image. With both eyes open, eye fatigue is reduced greatly during longer observation.", 
                    de: "Das Lahoux Bino Elite 50 ist eine thermische, binokulare Beobachtungskamera mit integriertem Laser-Entfernungsmesser, die speziell für längere Beobachtungseinsätze entwickelt wurde. Durch das binokulare Sehen wird die Ermüdung der Augen bei längerer Beobachtungszeit stark reduziert und gleichzeitig das hervorragende Bild besser wahrgenommen."},
        longDes_1: {    nl: "De Lahoux Bino Elite 50 is een thermische observatiecamera met laser afstandsmeter, speciaal ontworpen voor langdurig observatiegebruik en een uitstekend beeld. Met beide ogen open, wordt de oogvermoeidheid aanzienlijk verminderd en het uitstekende beeld goed waargenomen tijdens langere observatie.", 
                        en: "The Lahoux Bino Elite 50 is a thermal, laser range finding observation goggle, especially designed for long observation use and an excellent image. With both eyes open, eye fatigue is reduced greatly during longer observation.", 
                        de: "Das Lahoux Bino Elite 50 ist eine thermische, binokulare Beobachtungskamera mit integriertem Laser-Entfernungsmesser, die speziell für längere Beobachtungseinsätze entwickelt wurde. Durch das binokulare Sehen wird die Ermüdung der Augen bei längerer Beobachtungszeit stark reduziert und gleichzeitig das hervorragende Bild besser wahrgenommen."},
        longDes_2: {    nl: "De prestaties van de zeer gevoelige VOx ongekoelde IR-detector komen tot leven met de hoogwaardige Oled 1024×768 high-definition full colour beeldschermen. Met een stevig en solide gevoel, verstelbare oculairen, een nauwkeurige laserafstandsmeter en meer functies zoals Foto/video-opname, WIFI, GPS en kompasrichting laat de Lahoux Bino Elite 50 niets te wensen over.", 
                        en: "The performance of the high-sensitivity VOx uncooled IR detector comes to life with the high quality Oled 1024×768 high-definition full colour displays. With a sturdy and solid feeling, adjustable oculars, an accurate laser range finder and more features like Photo/ video recording, WIFI, GPS, compass direction, the Lahoux Bino Elite 50 leaves nothing to be desired.", 
                        de: "Die Leistung des hochempfindlichen ungekühlten VOx-IR-Detektors wird ganz lebendig durch das hochwertigen OLED 1024 × 768 High-Definition-Vollfarbdisplays. Beim Anfassen entsteht durch die gute Haptik ein wertiges Gefühl. Durch die verstellbaren Okulare, dem genauen Laser-Entfernungsmesser und weiteren Funktionen wie Foto-/Videoaufnahme, WIFI, GPS, Kompassrichtung, lässt das Lahoux Bino Elite 50 keine Wünsche offen."},
        pros: {         nl: ["IR-resolutie, 640 × 480/ 12 μ pixel Pitch", "Full colour OLED high-definition display", "Superieur en stabiel beeld", "Volledige beeldaanpassing", "Laser afstandsmeter met een nauwkeurigheid van 600 m", "Uitstekend geschikt voor langere observatie", "Instelbare oogafstand tussen de pupillen", "Robuust gevoel met goede ergonomie"], 
                        en: ["IR resolution of 640 × 480/ 12μ pixel Pitch", "Full colour OLED high-definition display", "Superior and stable image", "Full image adjustment", "Laser ranger finder with 600m accuracy", "Excellent for longer observation", "Adjustable oculars interpupillary distance", "Robust feeling"], 
                        de: ["IR-Auflösung von 640 × 480 Pixel / 12 μ Pixel Pitch", "Vollfarbiges OLED-High-Definition-Display", "Hochwertiges und stabiles Bild", "Umfangreiche Bildanpassungsmöglichkeiten", "Laser-Entfernungsmesser mit 600 m Genauigkeit", "Sehr geeignet für längere Beobachtungen", "Einstellbarer Pupillenabstand der Okulare", "Robust mit gute Haptik"]
    
            },
        number: "02-0006-03146",
        ean: "6090301643680",
        flyer: {nl:"Lahoux_Bino_Elite_50_NL_V1.0.pdf", en: "Lahoux_Bino_Elite_50_EN_V1.0.pdf", de: "Lahoux_Bino_Elite_50_DE_V1.0.pdf"},
        images: ["Bino_Elite_50-2.png"],
        dri: "Warmtebeeld_Spotters.png",
        footnote_battery: true,
        techs: { 
            Detector: {
                en: {"Resolution VOx uncooled": "640x480 px", "Pixel pitch": "12 μm", "Sensitivity (NETD)": "≤ 50 mk", "Frame rate": "50 Hz"},
                nl: {"Resolutie VOx ongekoeld": "640x480 px", "Pixelafstand": "12 μm", "Gevoeligheid (NETD)": "≤ 50 mk", "Beeldfrequentie": "50 Hz"},
                de: {"Auflösung VOx ungekühlt": "640x480 px", "Pixelabstand": "12 μm", "Empfindlichkeit (NETD)": "≤ 50 mk", "Bildfrequenz": "50 Hz"}
            },
            Optics: {
                en: {"Lens": "50 mm/F1.2", "Field of view": "8.8°×6.6°", "Magnification (optical)": "2.8× to 22.4×", "Magnification (digital)": "1× / 8× (Smooth zoom) microSTOP 200 m", "Diopter adjustment": "-4~+2"},
                nl: {"Lens": "50 mm/F1.2", "Gezichtsveld": "8.8°×6.6°", "Vergroting (optisch)": "2.8× to 22.4×", "Vergroting (digitaal)": "1× / 8× (Vloeiende zoom) microSTOP 200 m", "Dioptrie aanpassing": "-4~+2"},
                de: {"Linse": "50 mm/F1.2", "Sichtfeld": "8.8°×6.6°", "Vergrößerung (optisch)": "2.8× to 22.4×", "Vergrößerung (digital)": "1× / 8× (Sanfter Zoom) microSTOP 200 m", "Dioptrien-Einstellung": "-4~+2"}
            },
            Electronics: {
                en: {"Internal memory": "16 GB SD card", "Display OLED": "1024×768 px"},
                nl: {"Intern geheugen": "16 GB SD-kaart", "Beeldscherm OLED": "1024×768 px"},
                de: {"Interne Speicher": "16 GB SD-Karte", "Anzeige OLED": "1024×768 px"}
            },
            Operating: {
                en: {"Laser-rangefinder range": "600 m", "Power supply": "Removable 18650 Li-ion battery x 2", "Battery runtime (at 25 °C) *": "≥ 6 hours", "Encapsulation": "IP66", "Dimensions": "162 mm x 146 mm x 70 mm", "Weight": "880 g"},
                nl: {"Bereik laserafstandsmeter": "600 m", "Voeding": "Verwijderbare 18650 Li-ion batterij x 2", "Batterijduur (bij 25 °C) *": "≥ 6 uur", "Waterdichtheid": "IP66", "Afmetingen": "162 mm x 146 mm x 70 mm", "Gewicht": "880 g"},
                de: {"Laser-Entfernungsmesser Bereich": "600 m", "Stromversorgung": "Abnehmbare 18650 Li-ion batterie x 2", "Batterielaufzeit (bei 25 °C) *": "≥ 6 Stunden", "Wasserdicht": "IP66", "Abmessungen": "162 mm x 146 mm x 70 mm", "Gewicht": "880 g"}
            }
        },
        filter: {filterThermal: true, filterNightvision: false, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
    },


// **** Night vision ****
// Last used id: nz4

{
    id: "nz1",
    orderID: "201",
    type: "night",
    title: {nl: "Lahoux LV-81", en: "Lahoux LV-81", de: "Lahoux LV-81"},
    url: "Lahoux-LV-81",
    price: "2655.17",  // for NV price field is only used for sorting purposes; prce for NV is calculated through the options below
    options: {
        Groen: { 
            1200: {
                    veel: {"price": "2655.17", "number" : "01-0002-01917"},
                    weinig: { "price": "2920.68", "number": "01-0002-01917"},
                    geen: {"price": "3186.04", "number": "01-0002-01917"}
                    },
            1400: {
                    veel: {"price": "3034.48", "number" : "01-0002-01918"},
                    weinig: { "price": "3337.28", "number": "01-0002-01918"},
                    geen: {"price": "3641.38", "number": "01-0002-01918"}
                    },  
            1500: {
                    veel: {"price": "3793.10", "number" : "01-0002-01966"},
                    weinig: { "price": "4172.41", "number": "01-0002-01966"},
                    geen: {"price": "4551.72", "number": "01-0002-01966"}
                    },   
           1700: {
                    veel: {"price": "4931.03", "number" : "01-0002-02125"},
                    weinig: { "price": "5424.13", "number": "01-0002-02125"},
                    geen: {"price": "5917.24", "number": "01-0002-02125"}
                    },   
            1750: {
                    veel: {"price": "4931.03", "number" : "01-0002-02133"},
                    weinig: { "price": "5424.13", "number": "01-0002-02133"},
                    geen: {"price": "5917.24", "number": "01-0002-02133"}
                    },   
            1800: {
                    veel: {"price": "5310.34", "number" : "01-0002-02479"},
                    weinig: { "price": "5841.37", "number": "01-0002-02479"},
                    geen: {"price": "6372.41", "number": "01-0002-02479"}
                    },   
            1900: {
                    veel: {"price": "5689.66", "number" : "01-0002-02480"},
                    weinig: { "price": "6258.26", "number": "01-0002-02480"},
                    geen: {"price": "6827.59", "number": "01-0002-02480"}
                    },   
            1950: {
                    veel: {"price": "6448.28", "number" : "01-0002-02481"},
                    weinig: { "price": "7093.11", "number": "01-0002-02481"},
                    geen: {"price": "7737.94", "number": "01-0002-02481"}
                    },     
            },                            
        Onyx: {
            1200: {
                    veel: {"price": "2931.03", "number" : "01-0002-01965"},
                    weinig: { "price": "3224.13", "number": "01-0002-01965"},
                    geen: {"price": "3517.24", "number": "01-0002-01965"}
                    },
            1400: {
                    veel: {"price": "3310.34", "number" : "01-0002-01967"},
                    weinig: { "price": "3641.37", "number": "01-0002-01967"},
                    geen: {"price": "3972.41", "number": "01-0002-01967"}
                    },  
            1500: {
                    veel: {"price": "4068.97", "number" : "01-0002-01968"},
                    weinig: { "price": "4475.87", "number": "01-0002-01968"},
                    geen: {"price": "4882.76", "number": "01-0002-01968"}
                    },   
            1700: {
                    veel: {"price": "5206.90", "number" : "01-0002-02126"},
                    weinig: { "price": "5727.59", "number": "01-0002-02126"},
                    geen: {"price": "6248.28", "number": "01-0002-02126"}
                    },   
            1750: {
                    veel: {"price": "5206.90", "number" : "01-0002-02132"},
                    weinig: { "price": "5727.59", "number": "01-0002-02132"},
                    geen: {"price": "6248.28", "number": "01-0002-02132"}
                    },   
            1800: {
                    veel: {"price": "5586.21", "number" : "01-0002-02482"},
                    weinig: { "price": "6144.83", "number": "01-0002-02482"},
                    geen: {"price": "6703.45", "number": "01-0002-02482"}
                    },   
            1900: {
                    veel: {"price": "5965.52", "number" : "01-0002-02483"},
                    weinig: { "price": "6562.07", "number": "01-0002-02483"},
                    geen: {"price": "7158.62", "number": "01-0002-02483"}
                    },   
            1950: {
                    veel: {"price": "6724.14", "number" : "01-0002-02484"},
                    weinig: { "price": "7396.55", "number": "01-0002-02484"},
                    geen: {"price": "8068.97", "number": "01-0002-02484"}
                    },  
            }
    },
    spotlight: {     nl:"Geef everzwijn geen kans om wildschade te veroorzaken. De Lahoux LV-81 nachtzichtvoorzetkijker is de eerste keuze als het gaat om nachtjacht en observatie. De compromisloze combinatie van een extreem krachtige beeldversterkerbuis, lenzen met maximale transmissiewaarden, robuuste productie en intuïtieve bediening maken van de LV-81 een hulpmiddel dat u 100 procent zal overtuigen. Lahoux Optics is het enige bedrijf dat de Echo PLUS-beeldversterkerbuis met een waarde tot 2.000 FOM (Figure of Merit) voor de civiele markt integreert.", 
                    en: "Do not give wild boar the chance of causing crop damage. The Lahoux LV-81 night vision device is the first choice when it comes to night hunting and observation. The uncompromising combination of an extremely powerful image intensifier tube, lenses with maximum transmission values, robust production and intuitive operation make the LV-81 a tool that will convince you 100 percent. Lahoux Optics is the only company that integrates the Echo PLUS image intensifier tube with a value of up to 2,000 FOM (Figure of Merit) for the civilian market.", 
                    de: "Geben Sie dem Schwarzwild keine Chance Wildschäden anzurichten. Das Lahoux LV-81 Nachtsichtgerät ist die erste Wahl, wenn es um die Bejagung und Beobachtung bei Nacht geht. Die kompromisslose Kombination aus extrem leistungsfähiger Bildverstärkerröhre, Linsen mit maximalen Transmissionswerten, einer robusten Fertigung sowie der intuitiven Bedienung macht aus dem LV-81 ein Werkzeug, das Sie 100prozentig überzeugt. Lahoux Optics ist das einzige Unternehmen das die Echo PLUS Bildverstärkerröhre mit einem Wert von bis zu 2.000 FOM (Figure of Merit) für den zivilen Markt verbaut."},

    shortDes: {     nl:"Geef everzwijn geen kans om wildschade te veroorzaken. De Lahoux LV-81 nachtzichtvoorzetkijker is de eerste keuze als het gaat om nachtjacht en observatie. De compromisloze combinatie van een extreem krachtige beeldversterkerbuis, lenzen met maximale transmissiewaarden, robuuste productie en intuïtieve bediening maken van de LV-81 een hulpmiddel dat u 100 procent zal overtuigen. Lahoux Optics is het enige bedrijf dat de Echo PLUS-beeldversterkerbuis met een waarde tot 2.000 FOM (Figure of Merit) voor de civiele markt integreert.", 
                    en: "Do not give wild boar the chance of causing crop damage. The Lahoux LV-81 night vision device is the first choice when it comes to night hunting and observation. The uncompromising combination of an extremely powerful image intensifier tube, lenses with maximum transmission values, robust production and intuitive operation make the LV-81 a tool that will convince you 100 percent. Lahoux Optics is the only company that integrates the Echo PLUS image intensifier tube with a value of up to 2,000 FOM (Figure of Merit) for the civilian market.", 
                    de: "Geben Sie dem Schwarzwild keine Chance Wildschäden anzurichten. Das Lahoux LV-81 Nachtsichtgerät ist die erste Wahl, wenn es um die Bejagung und Beobachtung bei Nacht geht. Die kompromisslose Kombination aus extrem leistungsfähiger Bildverstärkerröhre, Linsen mit maximalen Transmissionswerten, einer robusten Fertigung sowie der intuitiven Bedienung macht aus dem LV-81 ein Werkzeug, das Sie 100prozentig überzeugt. Lahoux Optics ist das einzige Unternehmen das die Echo PLUS Bildverstärkerröhre mit einem Wert von bis zu 2.000 FOM (Figure of Merit) für den zivilen Markt verbaut."},
    longDes_1: {    nl:"De Lahoux LV-81 is de nieuwe dimensie in nachtzicht. Het is ontworpen als een voorzet voor verschillende apparaten, zoals richtkijkers, verrekijkers, foto- en videocamera’s en spotting scopes. Met de brandpuntsafstand van 80mm kun je ook op grotere afstand in het wild jagen. U hebt de keuze uit de Photonis™ 2+ tot de generatie Echo en Echo HF restlichtversterkers. Lahoux Optics is het enige bedrijf dat de Echo HF-beeldversterkerbuis met een waarde tot 2.000 FOM (Figure of Merit) voor de civiele markt integreert. Met behulp van een quick release-adapter kan de Lahoux LV-81 eenvoudig worden bevestigd aan de lens van de camera, verrekijker of doeloptiek*. Bovendien is het uitgerust met een montagerail voor het bevestigen van extra componenten zoals een LED-spot. Een CR123-batterij biedt tot 85 bedrijfsuren.", 
                    en: "The Lahoux LV-81 is the new dimension in night vision. It is designed as an attachment for a variety of devices, such as riflescopes, binoculars, photo and video cameras and spotting scopes. With the 80 mm focal length, you can also approach and hunt wild at a greater distance. You have the choice of the Photonis™ 2+ up to the Echo and Echo HF residual light amplifier generation. Lahoux Optics is the only company that integrates the Echo HF image intensifier tube with a value of up to 2,000 FOM (Figure of Merit) for the civilian market. Using a quick release adapter, the Lahoux LV-81 can be easily and simply attached to the lens of the camera, binoculars or target optics*. In addition, it is equipped with a mounting rail for the attachment of additional components such as an LED spotlight. A CR123 battery provides up to 85 hours of operation.", 
                    de: "Das Lahoux LV-81 ist die neue Dimension bei Nachtsichtgeräten. Es ist als Vorzats für die unterschiedlichtsen Geräte, wie beispielsweise Zielfernrohre*, Ferngläser, Foto- und Videokameras und Spektive konzipiert. Mit der 80 mm Brennweite können Sie auch auf größere Distanz Wild ansprechen und gezielt bejagen. Sie haben die Wahl von der Photonis™ 2+ bis hin zur Echo- und Echo HF-Restlichtverstärkergeneration. Dabei ist Lahoux Optics das einzige Unternehmen das die Echo HF Bildverstärkerröhre von Photonis mit einem Wert von bis zu 2.000 FOM (Figure of Merit) für den zivilen Markt verbaut. Mittels eines Schnellspannadapters kann das Lahoux LV-81 einfach und unkompliziert am Objektiv von Kamera, Fernglas oder Zieloptik* angebracht werden. Zusätzlich ist es mit einer Montage-Schiene für die Anbringung von Zusatzbauteilen wie beispielsweise ein LED-Strahler ausgestattet. Für bis zu 85 Stunden Betriebsdauer sorgt eine CR123 Batterie."},
    pros: {     nl:[ "Innovatieve technologie met Photonis™ beeldversterkerbuizen", "Auto Gating en EGAC (Manual Gain) inbegrepen bij Photonis™ Echo en Echo HF", "Tot een maximum van 2.000 FOM (Figure of Merit)", "Zeer hoge en tot de rand scherpe beeldkwaliteit", "Vooral geschikt voor de jacht, zelfs op grotere afstanden" , "Intuïtieve en gebruiksvriendelijke bediening “Inschakelen en toepassen”", "Robuuste constructie, alle lenzen zijn individueel schokbestendig", "Schokbestendige lenzen en interne focus voor optimale precisie", "Automatische uitschakeling na 60 minuten.", "Omgekeerde polariteitsbescherming – de polariteit van de batterij wordt automatisch gedetecteerd (gemakkelijk te veranderen in het donker)", "Optimale bescherming tegen stof en water, beschermingsklasse IP67", "Elk apparaat gaat vergezeld van een gegevensblad met de relevante technische gegevens.", "De complete ontwikkeling en productie van de Lahoux LV-81 vindt plaats in Nederland."], 
                en:[ "Innovative technology with Photonis™ image intensifier tubes", "Auto Gating and EGAC (Manual Gain) included with Photonis™ Echo and Echo HF", "Up to a maximum of 2,000 FOM (Figure of Merit)", "Very high and to the edge sharp picture quality", "Especially suitable for hunting, even at higher distances", "Intuitive and user-friendly operation “turn on and apply”", "Robust construction, all lenses are individually shockproof", "Shock-mounted lenses and internal focus for optimum precision", "Automatic shutdown after 60 min.", "Reverse polarity protection – the polarity of the battery is automatically detected (easily changeable in the dark)", "Optimum protection against dust and water, protection class IP67", "Each device is accompanied by a data sheet with the relevant technical data.", "The complete development and production of the Lahoux LV-81 takes place in the Netherlands."], 
                de: ["Innovative Technik durch Photonis™-Bildverstärkerröhren", "Auto-Gating und EGAC (Manual Gain) inklusive bei Photonis™ Echo und Echo HF", "Bis zu maximal 2.000 FOM (Figure of Merit)", "Sehr hohe und bis an den Rand scharfe Bildqualität", "Besonders geeignet für die Jagd, auch auf höhere Distanzen", "Intuitive und benutzerfreundliche Bedienung „einschalten und anwenden“", "Robuste Bauweise, alle Linsen sind individuell stoßfest verbaut", "Stoßfest montierte Linsen und innenliegender Fokus für optimale Präzision", "Abschaltautomatik nach 60 min.", "Verpolungsschutz – die Polarität der Batterie wird automatisch erkannt (leicht wechselbar bei Dunkelheit)", "Optimaler Schutz gegen Staub und Wasser, Schutzklasse IP 67", "Jedem Gerät liegt ein Datenblatt mit den relevanten technischen Daten bei.", "Die komplette Entwicklung und Herstellung des Lahoux LV-81 erfolgt in den Niederlanden."]

        },
    leveromvang: [1, 12, 19, 4, 3],
    flyer: {nl:"Lahoux_LV-81_NL_V4.pdf", en: "Lahoux_LV-81_EN_V4.pdf", de: "Lahoux_LV-81_DE_V6.pdf"},
    images: ["nz1_1.png", "nz1_2.png", "nz1_5.png"],
    related: ["nz2"],
    accessories: ["as6", "as10", "as1", "as2"],
    footnote_battery: true,
    jubilee_offer: false,
    techs: {
        Optics: {
            en: {"Magnification": "1.0 x", "Field of view": "7.94 °", "Lens / Relative opening": "80mm F/1.55 (for full FOV)", "Eyepiece diameter": "34 mm", "Accuracy": "< 1 MOA", "Parallax" : "100 m", "Focus area" : "10 to ∞ m"},
            nl: {"Vergroting": "1.0 x", "Gezichtsveld": "7.94 °", "Lens / Relatieve lensopening": "80mm F/1.55 (for full FOV)", "Oculair diameter": "34 mm", "Nauwkeurigheid": "< 1 MOA", "Parallax" : "100 m", "Focusgebied" : "10 to ∞ m"},
            de: {"Vergrößerung": "1.0 x", "Sichtfeld": "7.94 °", "Linse / Relative Blende": "80mm F/1.55 (for full FOV)", "Okular Durchmesser": "34 mm", "Genauigkeit": "< 1 MOA", "Parallaxe" : "100 m", "Fokusbereich" : "10 to ∞ m"}
        },
        "Image intensifier tube": {
            en: {"Tube: Photonis™": "GEN 2+/ECHO ( FOM < 1800) ECHO HF (FOM 1800-2000)"},
            nl: {"Buis: Photonis™": "GEN 2+/ECHO ( FOM < 1800) ECHO HF (FOM 1800-2000)"},
            de: {"Rohr: Photonis™": "GEN 2+/ECHO ( FOM < 1800) ECHO HF (FOM 1800-2000)"}
        },
        Operating: {
            en: {"Temperature range": "-40 to +50 °C (-40 to +122 °F)", "Dimensions" : "213 x 78 x 64 mm", "Weight": "0.71 kg (without battery)", "Battery": "1 x CR123", "Battery life": "40-85 hours (at 21°C) * (65 hours typically) one hour shutdown", "Battery indicator" : "Yes", "Watertight": "IP67", "Guaranteed shockproof": "Yes", "Optional": "Brightness control (Egac)", "Two Weaver rails for e.g. an optional laser or diode lamp" : "√"},
            nl: {"Temperatuurbereik": "-40 to +50 °C (-40 to +122 °F)", "Afmetingen" : "213 x 78 x 64 mm", "Gewicht": "0.71 kg (zonder batterij)", "Batterij": "1 x CR123", "Batterijduur": "40-85 uur (bij 21°C) * (65 uur meestal) één uur uitschakeling", "Batterij-indicator" : "Ja", "Waterdichtheid": "IP67", "Gegarandeerd schokbestendig": "Ja", "Optioneel": "Helderheidsregeling (Egac)", "Twee Weaver rails voor bijvoorbeeld een optionele laser- of diodelamp" : "√"},
            de: {"Temperaturbereich": "-40 to +50 °C (-40 to +122 °F)", "Abmessungen" : "213 x 78 x 64 mm", "Gewicht": "0.71 kg (ohne Batterie)", "Batterie": "1 x CR123", "Batterielaufzeit": "40-85 Stunden (bei 21°C) * (65 Stunden in der Regel) eine Stunde Abschaltung", "Batterieanzeige" : "Ja", "Wasserdicht": "IP67", "Garantiert stoßfest": "Ja", "Optional": "Helligkeitskontrolle (Egac)", "Zwei Weaver-Schienen für z. B. eine optionale Laser- oder Diodenlampe" : "√"}
        }
    },
    filter: {filterThermal: false, filterNightvision: true, filterDigital: false, filterObservation: true, filterVoorzet: true, filterRiflescope: false}
},
{
    id: "nz2",
    orderID: "202",
    type: "night",
    title: {nl: "Lahoux Hemera", en: "Lahoux Hemera", de: "Lahoux Hemera"},
    url: "Lahoux-Hemera",
    price: "2168.91", // for NV price field is only used for sorting purposes; prce for NV is calculated through the options below
    options: {
        Groen: { 
            1200: {
                    veel: {"price": "2168.91", "number" : "01-0002-02027"},
                    weinig: { "price": "2277.36", "number": "01-0002-02027"},
                    geen: {"price": "2385.80", "number": "01-0002-02027"}
                    },
            1400: {
                    veel: {"price": "2631.09", "number" : "01-0002-02326"},
                    weinig: { "price": "2762.64", "number": "01-0002-02326"},
                    geen: {"price": "2894.20", "number": "01-0002-02326"}
                    },  
            1500: {
                    veel: {"price": "3210.92", "number" : "01-0002-02029"},
                    weinig: { "price": "3371.47", "number": "01-0002-02029"},
                    geen: {"price": "3532.01", "number": "01-0002-02029"}
                    },   
            1700: {
                    veel: {"price": "3345.38", "number" : "01-0002-01991"},
                    weinig: { "price": "3512.65", "number": "01-0002-01991"},
                    geen: {"price": "3679.92", "number": "01-0002-01991"}
                    },   
            1750: {
                    veel: {"price": "4185.71", "number" : "01-0002-01979"},
                    weinig: { "price": "4395.00", "number": "01-0002-01979"},
                    geen: {"price": "4604.28", "number": "01-0002-01979"}
                    },   
            1800: {
                    veel: {"price": "4942.02", "number" : "01-0002-02489"},
                    weinig: { "price": "5189.12", "number": "01-0002-02489"},
                    geen: {"price": "5436.22", "number": "01-0002-02489"}
                    },   
            1900: {
                    veel: {"price": "5286.35", "number" : "01-0002-02490"},
                    weinig: { "price": "5550.67", "number": "01-0002-02490"},
                    geen: {"price": "5814.99", "number": "01-0002-02490"}
                    },   
            1950: {
                    veel: {"price": "5953.02", "number" : "01-0002-02491"},
                    weinig: { "price": "6250.67", "number": "01-0002-02491"},
                    geen: {"price": "6548.32", "number": "01-0002-02491"}
                    },     
            },                            
        Onyx: {
            1200: {
                    veel: {"price": "2336.97", "number" : "01-0002-02030"},
                    weinig: { "price": "2453.82", "number": "01-0002-02030"},
                    geen: {"price": "2570.67", "number": "01-0002-02030"}
                    },
            1400: {
                    veel: {"price": "2782.35", "number" : "01-0002-02034"},
                    weinig: { "price": "2921.47", "number": "01-0002-02034"},
                    geen: {"price": "3060.59", "number": "01-0002-02034"}
                    },  
            1500: {
                    veel: {"price": "3610.08", "number" : "01-0002-02035"},
                    weinig: { "price": "3790.58", "number": "01-0002-02035"},
                    geen: {"price": "3971.09", "number": "01-0002-02035"}
                    },   
            1700: {
                    veel: {"price": "3869.75", "number" : "01-0002-02031"},
                    weinig: { "price": "4063.24", "number": "01-0002-02031"},
                    geen: {"price": "4256.73", "number": "01-0002-02031"}
                    },   
            1750: {
                    veel: {"price": "4585.71", "number" : "01-0002-01989"},
                    weinig: { "price": "4815.00", "number": "01-0002-01989"},
                    geen: {"price": "5044.28", "number": "01-0002-01989"}
                    },   
            1800: {
                    veel: {"price": "5342.02", "number" : "01-0002-02492"},
                    weinig: { "price": "5609.12", "number": "01-0002-02492"},
                    geen: {"price": "5876.22", "number": "01-0002-02492"}
                    },   
            1900: {
                    veel: {"price": "5667.30", "number" : "01-0002-02493 "},
                    weinig: { "price": "5950.67", "number": "01-0002-02493 "},
                    geen: {"price": "6234.03", "number": "01-0002-02493 "}
                    },   
            1950: {
                    veel: {"price": "6333.97", "number" : "01-0002-02494"},
                    weinig: { "price": "6650.67", "number": "01-0002-02494"},
                    geen: {"price": "6967.37", "number": "01-0002-02494"}
                    },  
            }
    },
    shortDes: { nl:"Ideaal voor bosjacht. Vooral bij aanspreken van wild is het cruciaal om de details te herkennen. Is de bagge voerend en is een schot ook weidelijk? De Lahoux Hemera nachtzichtvoorzetkijker is ideaal als draagbare nachtzicht monoculair, voor observatie en bersen, vanwege het zeer grote gezichtsveld. In combinatie met een primair optiek is het grote gezichtsveld bijzonder gunstig voor bosjacht en dynamische bewegingen. Met een FOM (Figure of Merit) waarde tot 2.000 bereikt het de grenzen van het technisch haalbare.", 
                    en: "Ideal for forest hunting. Especially when selecting game, it is crucial to recognize the details. Is the sow with young and is a shot also ethical? The Lahoux Hemera night vision attachment is ideal as a hand-held night-vision monocular, during observing and stalking, due to the very large field of view. In combination with a primary optic, the large field of view is particularly beneficial for forest hunting and dynamic movement. With a FOM (figure of merit) value of up to 2,000, it reaches the limits of technical feasibility.", 
                    de: "Ideal für die Waldjagd. Gerade beim Ansprechen von Wild, ist es entscheidend die Details zu erkennen. Ist die Bache führend und ist ein Schuss auch waidgerecht ? Das Lahoux Hemera Nachtsicht-Vorsatzgerät eignet sich wegen dem sehr großen Sehfelds beim Beobachten und Pirschen bestens als handgeführtes Nachtsichtmonokular. In Verbindung mit einer Primäroptik ist das große Sehfeld besonders vorteilhaft für die Waldjagd und bei dynamischen Bewegungen. Mit einem FOM (Figure of Merit)-Wert von bis zu 2.000 erreicht es die Grenzen des technisch Machbaren."},
    longDes_1: {    nl:"Het korte en compacte ontwerp van de Lahoux Hemera nachtzicht voorzet kijker biedt een groot gezichtsveld, waardoor het een multifunctionele metgezel is voor de voederplaats, de bers en jagen op bewegende doelen. Intuïtieve en gebruikersvriendelijke bediening, robuustheid en de beste beeldkwaliteit in combinatie met Photonis beeldversterkerbuizen voor maximale prestaties. Lahoux maakt uitsluitend gebruik van Photonis™ beeldversterkerbuizen, gen 2+ en de nieuwste buizentechnologie ECHO en ECHO HF. Dit zorgt voor maximale prestaties, zelfs voor de meest veeleisende klanten. Snelle Auto-Gating (ATG), helderheidsregeling (EGAC) en de hoogste prestaties zijn uniek in deze combinatie. Met een FOM (Figure of Merit) waarde tot 2.000 bereikt het de grenzen van het technisch haalbare.", 
                    en: "The short and compact design of the Lahoux Hemera night vision attachment provides a large field of view, making it a multifunctional companion for feedings places, stalking, and hunting moving targets. Intuitive and user-friendly operation, ruggedness and best image quality in combination with Photonis image intensifier tubes for maximum performance. Lahoux exclusively uses Photonis™ image intensifier tubes, gen 2+ and the latest tube technology ECHO and ECHO HF. This ensures maximum performance even for the most demanding customers. Fast auto-gating (ATG), Manual Gain Control (MGC) and the highest performance values are unique in this combination. With a FOM (figure of merit) value of up to 2,000, it reaches the limits of technical feasibility.", 
                    de: "Die kurze und kompakte Bauweise des Lahoux Hemera Nachtsicht-Vorsatzgerät bietet ein großes Sehfeld und macht es so zum multifunktionalen Begleiter für die Jagd an der Kirrung, die Pirsch und auch auf bewegliche Ziele. Intuitive und benutzerfreundliche Bedienung, Robustheit und beste Bildqualität in Kombination mit Photonis-Bildverstärkerröhren die maximale Leistungsausbeute ermöglichen. Lahoux montiert ausschließlich Bildverstärkerröhren von Photonis™, Gen 2+ und die neueste Röhrentechnologie ECHO und ECHO HF. Damit wird ein Maximum an Leistung selbst für anspruchsvollste Kunden erfüllt. Schnelle Auto-Gating (ATG), Helligkeitsregulierung (EGAC) und höchste Leistungswerte sind in der Kombination einzigartig. Mit einem FOM (Figure of Merit)-Wert von bis zu 2.000 erreicht es die Grenzen des technisch Machbaren."},
    pros: {     nl:["Zeer groot gezichtsveld, daarom zeer goed geschikt als nachtzicht monoculair", "Zeer hoge en tot aan de rand scherpe beeldkwaliteit", "Vooral geschikt voor de jacht op wilde zwijnen op voederplaatsen en jacht op bewegende doelen", "Autogating en EGAC (helderheids regeling) inbegrepen bij Photonis™ Echo en Echo HF buizen", "Tot 2.000 FOM-waarde (Figure of Merit)", "Korte, compacte en robuuste constructie", "Schokvast gemonteerde lenzen en Photonis™ beeldversterkerbuizen", "Interne focussering voor optimale precisie", "Polariteitsbescherming – de polariteit van de batterij wordt automatisch gedetecteerd (gemakkelijk verwisselbaar in het donker)", "Automatische uitschakeling na 60 minuten.", "3,5 cm kortere constructie (ideaal voor montage met geluiddemper)", "Optimale bescherming tegen stof en water, beschermingsklasse IP67", "Elk apparaat wordt geleverd met een meetblad met alle relevante buis gegevens", "De complete ontwikkeling en productie van de Lahoux Hemera vindt plaats in Nederland."], 
                en: ["Very large field of view, therefore very good suitability as a night vision monocular", "Very high edge to edge sharp image quality", "Especially suitable for wild boar hunting at feeding places and hunting moving targets", "Autogating and EGAC (Manual Gain) included with Photonis™ Echo and Echo HF tubes", "Up to 2,000 FOM value (Figure of Merit)", "Short, compact and robust construction", "Shockproof mounted lenses and Photonis™ image intensifier tubes", "Internal focussing for optimum precision", "Reverse polarity protection – the polarity of the battery is automatically detected (easily changeable in the dark)", "Automatic shutdown after 60 minutes.", "3.5 cm shorter construction (ideal for mounting with silencer)", "Optimum protection against dust and water, protection class IP67", "Each device comes with a data sheet with all relevant tube specifications", "The complete development and production of the Lahoux Hemera takes place in the Netherlands."], 
                de: ["Sehr großes Sehfeld, dadurch sehr gute Eignung auch als Nachtsichtmonokular", "Sehr hohe und bis an den Rand scharfe Bildqualität", "Besonders geeignet für die Schwarzwildjagd an der Kirrung und die Jagd auf bewegliche Ziele", "Autogating und EGAC (Helligkeitsregulierung) inclusive bei Photonis™ Echo und Echo HF Röhre", "Bis zu 2.000 FOM-Wert (Figure of Merit)", "Kurze, kompakte und robuste Bauweise", "Stoßfest montierte Linsen und Photonis™ Bildverstärkerröhre", "Innenliegender Fokussierung für optimale Präzision", "Verpolungsschutz – die Polarität der Batterie wird automatisch erkannt (leicht wechselbar bei Dunkelheit)", "Abschaltautomatik nach 60 Minuten", "3,5 cm kürzere Bauweise (ideal bei Montage mit Schalldämpfer)", "Optimaler Schutz gegen Staub und Wasser, Schutzklasse IP 67", "Jedem Gerät liegt ein Datenblatt den relevanten technischen Daten bei.", "Die komplette Entwicklung und Herstellung des Lahoux Hemera erfolgt in den Niederlanden."]

        },
    leveromvang: [1, 15, 3, 4 , 19, 18],
    flyer: {nl:"Lahoux_Hemera_NL_V4.pdf", en: "Lahoux_Hemera_EN_V4.pdf", de: "Lahoux_Hemera_DE_V5.pdf"},
    images: ["nz2_1.png", "nz2_2.png", "nz2_3.png", "nz2_4.png", "nz2_5.png"],
    related: ["nz1"],
    accessories: ["as6", "as10", "as1", "as2"],
    footnote_battery: true,
    jubilee_offer: false,
    techs: {
        Optics: {
            en: {"Magnification": "1.0 x", "Field of view": "18.2 ° nom.", "Lens": "50 mm", "Relative opening": "F/1.40", "Ocular Diameter": "29 mm", "Precision": "< 1 MOA", "Parallax": "100 m", "Focus area": "10 m bis ∞"},
            nl: {"Vergroting": "1.0 x", "Gezichtsveld": "18.2 ° nom.", "Lens": "50 mm", "Relatieve lensopening": "F/1.40", "Oculair diameter": "29 mm", "Nauwkeurigheid": "< 1 MOA", "Parallax": "100 m", "Focusgebied": "10 m bis ∞"},
            de: {"Vergrößerung": "1.0 x", "Sichtfeld": "18.2 ° nom.", "Linse": "50 mm", "Relative Blende": "F/1.40", "Okular Durchmesser": "29 mm", "Genauigkeit": "< 1 MOA", "Parallaxe": "100 m", "Fokusbereich": "10 m bis ∞"}
        },
        "Image intensifier tube": {
            en: {"Tube: Photonis™": "GEN 2+/ECHO ( FOM < 1800) ECHO HF (FOM 1800-2000)"},
            nl: {"Buis: Photonis™": "GEN 2+/ECHO ( FOM < 1800) ECHO HF (FOM 1800-2000)"},
            de: {"Rohr: Photonis™": "GEN 2+/ECHO ( FOM < 1800) ECHO HF (FOM 1800-2000)"}
        },
        Operating: {
            en: {"Temperature range": "-40 °C to 50 °C", "Power supply": "1x CR2 Battery", "Battery life" : "30 Hours (at 21°C) * (Typically)", "Watertight": "IP67", "Dimensions": "166 x 70 x 61 mm", "Weight without batteries": "0,58 Kg"},
            nl: {"Temperatuurbereik": "-40 °C to 50 °C", "Voeding": "1x CR2 Batterij", "Batterijduur" : "30 uur (bij 21°C) * (meestal)", "Waterdichtheid": "IP67", "Afmetingen": "166 x 70 x 61 mm", "Gewicht zonder batterijen": "0,58 Kg"},
            de: {"Temperaturbereich": "-40 °C to 50 °C", "Stromversorgung": "1x CR2 Batterie", "Batterielaufzeit" : "30 Stunden (bei 21°C) * (in der Regel)", "Wasserdicht": "IP67", "Abmessungen": "166 x 70 x 61 mm", "Gewicht ohne Batterien": "0,58 Kg"}
        }
    },
    filter: {filterThermal: false, filterNightvision: true, filterDigital: false, filterObservation: true, filterVoorzet: true, filterRiflescope: false}
    
},
{
    id: "nz3",
    orderID: "203",
    type: "night",
    title: {nl: "Lahoux LVS-7", en: "Lahoux LVS-7", de: "Lahoux LVS-7"},
    url: "Lahoux-LVS-7",
    price: "2777.78", // for NV price field is only used for sorting purposes; prce for NV is calculated through the options below
    options: {
        Groen: { 
            1200: {
                    veel: {"price": "3448.28", "number" : "01-0002-01370"},
                    weinig: { "price": "3793.11", "number": "01-0002-01370"},
                    geen: {"price": "4137.94", "number": "01-0002-01370"}
                    },
            1400: {
                    veel: {"price": "4137.93", "number" : "01-0002-01372"},
                    weinig: { "price": "4551.72", "number": "01-0002-01372"},
                    geen: {"price": "4965.52", "number": "01-0002-01372"}
                    },  
            1500: {
                    veel: {"price": "4827.59", "number" : "01-0002-01371"},
                    weinig: { "price": "5310.35", "number": "01-0002-01371"},
                    geen: {"price": "5793.11", "number": "01-0002-01371"}
                    },   
            },                            
        Onyx: {
            1200: {
                veel: {"price": "3724.14", "number" : "01-0002-01373"},
                weinig: { "price": "4096.55", "number": "01-0002-01373"},
                geen: {"price": "4468.97", "number": "01-0002-01373"}
                },
            1400: {
                veel: {"price": "4413.79", "number" : "01-0002-01364"},
                weinig: { "price": "4855.17", "number": "01-0002-01364"},
                geen: {"price": "5296.55", "number": "01-0002-01364"}
                },  
             1500: {
                veel: {"price": "5310.35", "number" : "01-0002-01363"},
                weinig: { "price": "5841.38", "number": "01-0002-01363"},
                geen: {"price": "6372.42", "number": "01-0002-01363"}
                },   
              }
        },
    shortDes: { nl:"De Lahoux L­VS-7 binoculaire nachtkijker is een robuust, betrouwbaar en lichtgewicht nachtzicht systeem. Deze kijker wordt ook in het Amerikaanse leger gebruikt en heeft zich als betrouwbaar bewezen in zware militaire omstandigheden.", 
                en: "The Lahoux L­VS-7 Night Vision Goggle is a robust and dependable lightweight Night Vision System. It still is the standard issue U.S. Army goggle and has proven to be reliable in harsh military service.", 
                de: "Die Lahoux L­VS-7 Nachtsichtbrille ist ein robustes und leichtes Nachtsichtsystem. Es ist immer noch die Standardbrille in der Armee der VS und hat sich als sehr zuverlässig gezeigt in Militärdienst."},
    longDes_1: {    nl:"De Lahoux L­VS-7 binoculaire nachtkijker is een robuust, betrouwbaar en lichtgewicht nachtzicht systeem. Deze kijker wordt ook in het Amerikaanse leger gebruikt en heeft zich als betrouwbaar bewezen in zware militaire omstandigheden. Het is mogelijk om de Lahoux LVS-7 met volledig instelbare hoofdmontage geleverd te krijgen, wat een comfortabel langdurig gebruik mogelijk maakt. De Lahoux LVS-7 is een uitstekende keuze voor zowel overheden als voor gebruik tijdens jacht- en outdoor activiteiten en is zowel vanuit de hoogzit als tijdens de bers perfect op de korte en middellange afstand te gebruiken.", 
                en: "The Lahoux L­VS-7 Night Vision Goggle is a robust and dependable lightweight Night Vision System. It still is the standard issue U.S. Army goggle and has proven to be reliable in harsh military service. The Lahoux L­VS-7 can be complemented with a lightweight, fully adjustable head mount that allows for comfortable long-time use. The Lahoux L­VS-7 is an excellent choice for outdoor or hunting activities and (special) government use.", 
                de: "Die Lahoux LVS-7 Nachtsichtbrille ist ein robustes und leichtes Nachtsichtsystem. Varianten dieser Brille haben sich im Militäreinsatz als sehr zuverlässig gezeigt und werden nach wie vor bei verschiedenen Armeen eingesetzt. Mit der optional erhältlichen, leichten und individuell einstellbaren Kopfhalterung ist eine komfortable Langzeitnutzang möglich. Die Lahoux LVS-7 Nachtsichtbrille ist für Behören eine ausgezeichnete Wahl, findet aber sicherlich auch im Outdoorbereich und bei der Jagd Verwendung."},
    pros: {     nl: ["Multi-functioneel: Handheld, op het hoofd gemonteerd of op een helm gemonteerd", "Lichtgewicht", "Uitgerust met kortstondige of continue IR-schakelaar", "Interne batterij- en IR-indicators", "Beschikbaar in zwart of zandkleurig", "Waterproof", "Inclusief twee AA batterijen", "Tweejarige garantie"], 
            en: ["Multi-purpose: Handheld, head-mounted or helmet-mounted", "Lightweight", "Equipped with momentary or continuous IR switch", "Internal low battery and IR indicators", "Comes in black or desert tan colours", "Combat proven", "Waterproof", "Including two AA batteries", "Limited two-year warranty"], 
            de: ["Mehrzweck, geeignet als Handheld, Kopf- oder helmgetragen Hauptgetragen oder Helmgetragen", "Nur 480 Gramm leicht (ohne Batterien)", "Ausgestattet mit IR-Schalter. Punkt am Schluss raus", "Interne Batterie und IR-Indikatoren", "Schwarz oder Wüstetarnfarbe", "Kampfbewährt", "Staubfest und wasserdicht IP67", "Inklusive zwei AA-Batterien", "Zweijährige Garantie"]

    },
    leveromvang: [1, 11, 4, 3],
    flyer: {nl:"Lahoux_LVS-7_NL_V2.0.pdf", en: "Lahoux_LVS-7_EN_V2.0.pdf", de: "Lahoux_LVS-7_DE_V2.0.pdf"},
    images: ["nz3_1.png", "nz3_2.png", "nz3_3.png", "nz3_4.png", "nz3_5.png"],
    related: ["nz4"],
    accessories: ["as1", "as2", "as3","as8"],
    footnote_battery: true,
    techs: {
        Optics: {
            en: {"Objective": "26mm", "Field of View": "40°", "Inter-pupillary adjustment": "55 to 71mm", "Relative aperture": "F/1.2", "Focus range": "25cm to ∞", "Magnification (Digital)": "1x", "Dioptric correction": "-6 to +2"},
            nl: {"Objectief": "26mm", "Gezichtsveld": "40°", "Inter-pupilaire aanpassing": "55 to 71mm", "Relatieve lensopening": "F/1.2", "Focusbereik": "25cm to ∞", "Vergroting (digitaal)": "1x", "Dioptrische correctie": "-6 to +2"},
            de: {"Objektiv": "26mm", "Sichtfeld": "40°", "Interpupillare Einstellung": "55 to 71mm", "Relative Blende": "F/1.2", "Fokusbereich": "25cm to ∞", "Vergrößerung (digital)": "1x", "Dioptrische Korrektur": "-6 to +2"}
        },
        Electronics: {
            en: {"Battery": "2x 1.5v AA or 1×3 V BA-5567/U", "Battery life": "50+ Hours (at 21°C) *"},
            nl: {"Batterij": "2x 1.5v AA or 1×3 V BA-5567/U", "Batterijduur": "50+ uur (bij 21°C) *"},
            de: {"Batterie": "2x 1.5v AA or 1×3 V BA-5567/U", "Batterielaufzeit": "50+ Stunden (bei 21°C) *"}
        },
        Operating: {
            en: {"Temperature range": "-40°C to 50°C", "Encapsulation": "IP67", "Dimensions": "148x80x155 mm", "Weight without batteries": "0,48 kg"},
            nl: {"Temperatuurbereik": "-40°C to 50°C", "Waterdichtheid": "IP67", "Afmetingen": "148x80x155 mm", "Gewicht zonder batterijen": "0,48 kg"},
            de: {"Temperaturbereich": "-40°C to 50°C", "Wasserdicht": "IP67", "Abmessungen": "148x80x155 mm", "Gewicht ohne Batterien": "0,48 kg"}
        }
    },
    filter: {filterThermal: false, filterNightvision: true, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
},		
{
    id: "nz4",
    orderID: "204",
    type: "night",
    title: {nl: "Lahoux LVS-14", en: "Lahoux LVS-14", de: "Lahoux LVS-14"},
    url: "Lahoux-LVS-14",
    price: "2689.66", // for NV price field is only used for sorting purposes; prce for NV is calculated through the options below
    options: {
        Groen: { 
            1200: {
                    veel: {"price": "2689.66", "number" : "01-0002-02040"},
                    weinig: { "price": "2958.63", "number": "01-0002-02040"},
                    geen: {"price": "3227.59", "number": "01-0002-02040"}
                    },
            1400: {
                    veel: {"price": "3379.31", "number" : "01-0002-02041"},
                    weinig: { "price": "3717.24", "number": "01-0002-02041"},
                    geen: {"price": "4055.17", "number": "01-0002-02041"}
                    },  
            1500: {
                    veel: {"price": "4068.97", "number" : "01-0002-02042"},
                    weinig: { "price": "4475.87", "number": "01-0002-02042"},
                    geen: {"price": "4882.76", "number": "01-0002-02042"}
                    },   
            1700: {
                    veel: {"price": "4896.55", "number" : "01-0002-02105"},
                    weinig: { "price": "5386.21", "number": "01-0002-02105"},
                    geen: {"price": "5875.86", "number": "01-0002-02105"}
                    },   
            1750: {
                    veel: {"price": "4896.55", "number" : "01-0002-02092"},
                    weinig: { "price": "5386.21", "number": "01-0002-02092"},
                    geen: {"price": "5875.86", "number": "01-0002-02092"}
                    },   
            1800: {
                    veel: {"price": "5172.41", "number" : "01-0002-02498"},
                    weinig: { "price": "5689.65", "number": "01-0002-02498"},
                    geen: {"price": "6206.89", "number": "01-0002-02498"}
                    },   
            1900: {
                    veel: {"price": "5517.24", "number" : "01-0002-02499"},
                    weinig: { "price": "6068.96", "number": "01-0002-02499"},
                    geen: {"price": "6620.69", "number": "01-0002-02499"}
                    },   
            1950: {
                    veel: {"price": "6206.90", "number" : "01-0002-02500"},
                    weinig: { "price": "6827.59", "number": "01-0002-02500"},
                    geen: {"price": "7448.28", "number": "01-0002-02500"}
                    },     
            },                            
        Onyx: {
            1200: {
                    veel: {"price": "2965.52", "number" : "01-0002-02043"},
                    weinig: { "price": "3262.07", "number": "01-0002-02043"},
                    geen: {"price": "3558.62", "number": "01-0002-02043"}
                    },
            1400: {
                    veel: {"price": "3655.17", "number" : "01-0002-02044"},
                    weinig: { "price": "4020.69", "number": "01-0002-02044"},
                    geen: {"price": "4386.20", "number": "01-0002-02044"}
                    },  
            1500: {
                    veel: {"price": "4344.83", "number" : "01-0002-02327"},
                    weinig: { "price": "4779.31", "number": "01-0002-02327"},
                    geen: {"price": "5213.80", "number": "01-0002-02327"}
                    },   
            1700: {
                    veel: {"price": "5172.41", "number" : "01-0002-02108"},
                    weinig: { "price": "5689.65", "number": "01-0002-02108"},
                    geen: {"price": "6206.89", "number": "01-0002-02108"}
                    },   
            1750: {
                    veel: {"price": "5172.41", "number" : "01-0002-02109"},
                    weinig: { "price": "5689.65", "number": "01-0002-02109"},
                    geen: {"price": "6206.89", "number": "01-0002-02109"}
                    },   
            1800: {
                    veel: {"price": "5448.28", "number" : "01-0002-02501"},
                    weinig: { "price": "5993.11", "number": "01-0002-02501"},
                    geen: {"price": "6537.94", "number": "01-0002-02501"}
                    },   
            1900: {
                    veel: {"price": "5793.10", "number" : "01-0002-02502"},
                    weinig: { "price": "6372.41", "number": "01-0002-02502"},
                    geen: {"price": "6951.72", "number": "01-0002-02502"}
                    },   
            1950: {
                    veel: {"price": "6206.90", "number" : "01-0002-02503"},
                    weinig: { "price": "6827.59", "number": "01-0002-02503"},
                    geen: {"price": "7448.28", "number": "01-0002-02503"}
                    },  
            }
    },
    shortDes: { nl:"De Lahoux LVS-14 is een uitstekende keuze voor jacht- en outdoor activiteiten tijdens extreem lage lichtomstandigheden. Hij is zeer geschikt voor korte en middellange observatieafstanden. Deze zeer veelzijdige restlichtkijker kan worden gebruikt uit de hand of met een hoofd- of helmmontage. Met een adapterbrug is het mogelijk om van twee LVS-14 mono’s een bino systeem te maken.", 
                    en: "The Lahoux LVS-14 is an excellent choice for hunting and outdoor activities during extreme low light situations. It is very usefull for short and middle observation distances. This very versatile night vision monocular can be used as a handheld device or with a head or helmet mount. It is possible with a dual adapter to turn two LVS-14 into a bino system.", 
                    de: "Das Lahoux LVS-14 ist eine gute Wahl bei Jagd- und Outdoor Aktivitäten bei extrem niedrigen Sichtverhältnissen. Es ist besonders gut geeignet für kurze bis mittlere Beobachtungsdistanzen. Dieses äußerst vielseitige Nachtsichtgerät kann als Handmonokular oder mit einer Kopf/Helmhalterung eingesetzt werden. Mit einer Montagebrücke können zwei LVS-14 in ein Binokular umgewandelt werden."},
    longDes_1: {    nl:"De Lahoux LVS-14 is een uitstekende keuze voor jacht- en outdoor activiteiten tijdens extreem lage lichtomstandigheden. Hij is zeer geschikt voor korte en middellange observatieafstanden. Deze zeer veelzijdige restlichtkijker kan worden gebruikt uit de hand of met een hoofd- of helmmontage. Met een adapterbrug is het mogelijk om van twee LVS-14 mono’s een bino systeem te maken.", 
                    en: "The Lahoux LVS-14 is an excellent choice for hunting and outdoor activities during extreme low light situations. It is very usefull for short and middle observation distances. This very versatile night vision monocular can be used as a handheld device or with a head or helmet mount. It is possible with a dual adapter to turn two LVS-14 into a bino system.", 
                    de: "Das Lahoux LVS-14 ist eine gute Wahl bei Jagd- und Outdoor Aktivitäten bei extrem niedrigen Sichtverhältnissen. Es ist besonders gut geeignet für kurze bis mittlere Beobachtungsdistanzen. Dieses äußerst vielseitige Nachtsichtgerät kann als Handmonokular oder mit einer Kopf/Helmhalterung eingesetzt werden. Mit einer Montagebrücke können zwei LVS-14 in ein Binokular umgewandelt werden."},

    leveromvang: [ 1, 12, 3, 4 ],
    flyer: {nl:"Lahoux_LVS-14_NL_V5.pdf", en: "Lahoux_LVS-14_EN_V5.pdf", de: "Lahoux_LVS-14_DE_V5.pdf"},
    images: ["nz4_1.png", "nz4_2.png", "nz4_3.png"],
    related: ["nz3"],
    accessories: ["as1", "as3", "as7", "as8"],
    footnote_battery: true,
    techs: {
        Optics: {
            en: {"Lens": "27mm", "FOV": "40°", "Eye relief": "27 mm", "Relative aperture"  : "F/1.2", "Focus range" : "25cm to ∞", "Magnification (Digital)" : "1x", "Diopter correction" : "-6 tot +2" , "Built-in IR illuminator" :  "905nm short range", "Light overload sensor" : "Yes"},
            nl: {"Lens": "27mm", "FOV": "40°", "Oogafstand": "27 mm", "Relatieve lensopening"  : "F/1.2", "Focusbereik" : "25cm to ∞", "Vergroting (digitaal)" : "1x", "Dioptrische correctie" : "-6 tot +2" , "Ingebouwde IR-verlichter" :  "905nm kort bereik", "Lichtoverbelastingssensor" : "Ja"},
            de: {"Linse": "27mm", "FOV": "40°", "Augenabstand": "27 mm", "Relative Blende"  : "F/1.2", "Fokusbereich" : "25cm to ∞", "Vergrößerung (digital)" : "1x", "Dioptrische Korrektur" : "-6 tot +2" , "Eingebauter IR-Strahler" :  "905nm kurze Reichweite", "Lichtüberlastungssensor" : "Ja"}
        },
        Electronics: {
            en: {"Battery": "1x CR-123 or 1x AA", "Battery life": "60+/30+ Hours (at 21°C) *"},
            nl: {"Batterij": "1x CR-123 or 1x AA", "Batterijduur": "60+/30+ uur (bij 21°C) *"},
            de: {"Batterie": "1x CR-123 or 1x AA", "Batterielaufzeit": "60+/30+ Stunden (bei 21°C) *"}
        },
        Operating: {
            en: {"Temperature range": "−51°C to 49°C", "Encapsulation": "IP67", "Dimensions" : "113x65x55 mm", "Weight without batteries": "0,324 kg"},
            nl: {"Temperatuurbereik": "−51°C to 49°C", "Waterdichtheid": "IP67", "Afmetingen" : "113x65x55 mm", "Gewicht zonder batterijen": "0,324 kg"},
            de: {"Temperaturbereich": "−51°C to 49°C", "Wasserdicht": "IP67", "Abmessungen" : "113x65x55 mm", "Gewicht ohne Batterien": "0,324 kg"}
        }
    },
    filter: {filterThermal: false, filterNightvision: true, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
},
{
    id: "nz5",
    orderID: "205",
    type: "night",
    title: {nl: "Lahoux D-545", en: "Lahoux D-545", de: "Lahoux D-545"},
    url: "Lahoux-D-545",
    price: "2144.00", // for NV price field is only used for sorting purposes; prce for NV is calculated through the options below
    options: {
        Groen: { 
            1200: {
                    veel: {"price": "2144.00", "number" : "01-0002-01887"},
                    weinig: { "price": "2251.20", "number": "01-0002-01887"},
                    geen: {"price": "2358.40", "number": "01-0002-01887"}
                    },
            1400: {
                    veel: {"price": "2495.00", "number" : "01-0002-01888"},
                    weinig: { "price": "2619.75", "number": "01-0002-01888"},
                    geen: {"price": "2744.50", "number": "01-0002-01888"}
                    },  
            1500: {
                    veel: {"price": "2995.00", "number" : "01-0002-01971"},
                    weinig: { "price": "3144.75", "number": "01-0002-01971"},
                    geen: {"price": "3294.50", "number": "01-0002-01971"}
                    },   
            1700: {
                    veel: {"price": "3295.00", "number" : "01-0002-02141"},
                    weinig: { "price": "3459.75", "number": "01-0002-02141"},
                    geen: {"price": "3624.50", "number": "01-0002-02141"}
                    },   
            1750: {
                    veel: {"price": "3995.00", "number" : "01-0002-02142"},
                    weinig: { "price": "4194.75", "number": "01-0002-02142"},
                    geen: {"price": "4394.50", "number": "01-0002-02142"}
                    },   
            1800: {
                    veel: {"price": "4650.00", "number" : "01-0002-02504"},
                    weinig: { "price": "4882.50", "number": "01-0002-02504"},
                    geen: {"price": "5115.00", "number": "01-0002-02504"}
                    },   
            1900: {
                    veel: {"price": "5075.24", "number" : "01-0002-02505"},
                    weinig: { "price": "5329.00", "number": "01-0002-02505"},
                    geen: {"price": "5582.76", "number": "01-0002-02505"}
                    },   
            1950: {
                    veel: {"price": "6160.95", "number" : "01-0002-02506"},
                    weinig: { "price": "6469.00", "number": "01-0002-02506"},
                    geen: {"price": "6777.05", "number": "01-0002-02506"}
                    },     
            },                            
        Onyx: {
            1200: {
                    veel: {"price": "2295.00", "number" : "01-0002-01934"},
                    weinig: { "price": "2409.75", "number": "01-0002-01934"},
                    geen: {"price": "2524.50", "number": "01-0002-01934"}
                    },
            1400: {
                    veel: {"price": "2695.00", "number" : "01-0002-01908"},
                    weinig: { "price": "2829.75", "number": "01-0002-01908"},
                    geen: {"price": "2964.50", "number": "01-0002-01908"}
                    },  
            1500: {
                    veel: {"price": "3365.00", "number" : "01-0002-01972"},
                    weinig: { "price": "3533.25", "number": "01-0002-01972"},
                    geen: {"price": "3701.50", "number": "01-0002-01972"}
                    },   
            1700: {
                    veel: {"price": "3695.00", "number" : "01-0002-02124"},
                    weinig: { "price": "3879.75", "number": "01-0002-02124"},
                    geen: {"price": "4064.50", "number": "01-0002-02124"}
                    },   
            1750: {
                    veel: {"price": "4399.00", "number" : "01-0002-02134"},
                    weinig: { "price": "4618.95", "number": "01-0002-02134"},
                    geen: {"price": "4838.90", "number": "01-0002-02134"}
                    },   
            1800: {
                    veel: {"price": "5095.00", "number" : "01-0002-02507"},
                    weinig: { "price": "5349.75", "number": "01-0002-02507"},
                    geen: {"price": "5604.50", "number": "01-0002-02507"}
                    },   
            1900: {
                    veel: {"price": "5399.05", "number" : "01-0002-02508"},
                    weinig: { "price": "5669.00", "number": "01-0002-02508"},
                    geen: {"price": "5938.96", "number": "01-0002-02508"}
                    },   
            1950: {
                    veel: {"price": "6541.90", "number" : "01-0002-02509"},
                    weinig: { "price": "6869.00", "number": "01-0002-02509"},
                    geen: {"price": "7196.09", "number": "01-0002-02509"}
                    },  
            }
    },
    shortDes: {     nl: "De Lahoux D-545 restlicht voorzetkijker is een professionele voorzet met sublieme beeldkwaliteit en hoge resolutie, geschikt voor grote afstanden. ", 
                    en: "The Lahoux D-545 image intensifier clip-on device is a professional front mount with excellent image quality and high resolution, suitable for longer distances.", 
                    de: "Das Lahoux D-545 Nachtsicht-Vorsatzgerät ist ein professionelles Vorsatzgerät und hervorragend für eine hohe Bildqualität und Auflösung auf großen Reichweiten geeignet."},
    longDes_1: {    nl: "De Lahoux D-545 restlicht voorzetkijker is een professionele voorzet met sublieme beeldkwaliteit en hoge resolutie, geschikt voor grote afstanden. De Lahoux D-545 heeft moderne eigenschappen zoals een stille aan/uit kop en automatische uit-schakeling na ongeveer 90 minuten. Het montagesysteem zorgt voor een stabiele en nauwkeurige bevestiging aan primaire optiek zoals een richtkijker, verrekijker of camera. De Lahoux D-545 is beschikbaar met alle Photonis™ beeldversterkerbuizen in groen en Onyx™.", 
                    en: "The Lahoux D-545 image intensifier clip-on device is a professional front mount with excellent image quality and high resolution, suitable for longer distances. It has modern features like a silent on/off button and automatic shut-down after approximately 90 minutes. The mounting system is a stable and accurate connector for primary optics like a scope, binocular or camera. The Lahoux D-545 is available with all Photonis™ intensifier tubes in green and Onyx™.", 
                    de: "Das Lahoux D-545 Nachtsicht-Vorsatzgerät ist ein professionelles Vorsatzgerät und hervorragend für eine hohe Bildqualität und Auflösung auf großen Reichweiten geeignet. Es besitzt moderne Eigenschaften wie eine leise Drucktaste und automatische Ausschaltung nach 90 Minuten. Das Montagesystem ermöglicht eine stabile und präzise Befestigung an Primäroptiken wie Zielfernrohr, Fernglas oder Kamera. Das Lahoux D-545 ist erhältlichmit allen Photonis™ Bildverstärkerröhren in grün und Onyx™ "},
    pros: {     nl: ["Compact, licht, waterdicht, gebruiksvriendelijk en eenvoudig te bedienen", "Beïnvloed de optische nauwkeurigheid niet", "Uitstekende beeldkwaliteit over het totale beeldveld", "Schokvast gemonteerde lenzen en Photonis™ beeldversterkerbuizen"], 
                en: ["User friendly, compact, watertight, light and simple to operateUser friendly, compact, watertight, light and simple to operate", "Does not influence the optical accuracy", "Excellent image quality spanning the complete image", "Shockproof mounted lenses and Photonis™ intensifier tubes"], 
                de: ["Benutzerfreundlich, kompakt, wasserdicht, sehr leicht und eine einfache Bedienung", "Beeinflusst die optische Genauigkeit nicht", "Gute Bildqualität im gesamten Sichtfeld", "Stoßfest montierte Linsen und Photonis™ Bildverstärkerröhre"]

        },                
    flyer: {nl:"Lahoux_D-545_NL_V5.pdf", en: "Lahoux_D-545_EN_V4.pdf", de: "Lahoux_D-545_DE_V4.pdf"},
    images: ["Lahoux-D-545.png"],
    accessories: ["as10"],
    footnote_battery: true,
    techs: {
        Optics: {
            en: {"Tube": "Photonis™, 2+, ECHO, ECHO HF", "Field of view": "8.8°", "Objective lens": "78 mm", "Relative aperture": "F/1.5", "Ocular diameter": "25 mm", "Accuracy": "<1 MOA", "Parallax": "100 m", "Focus range": "10 m till ∞"},
            nl: {"Buis": "Photonis™, 2+, ECHO, ECHO HF", "Gezichtsveld": "8.8°", "Objectieflens": "78 mm", "Relatieve lensopening": "F/1.5", "Oculair diameter": "25 mm", "Nauwkeurigheid": "<1 MOA", "Parallax": "100 m", "Focusgebied": "10 m till ∞"},
            de: {"Rohr": "Photonis™, 2+, ECHO, ECHO HF", "Sichtfeld": "8.8°", "Objektivlinse": "78 mm", "Relative Blende": "F/1.5", "Okular Durchmesser": "25 mm", "Genauigkeit": "<1 MOA", "Parallaxe": "100 m", "Fokusbereich": "10 m till ∞"}
        },
        Electronics: {
            en: {"Battery run-time": "Approx. 60 hours *"},
            nl: {"Batterijduur": "Ca. 60 uur *"},
            de: {"Batterielaufzeit": "Ca. 60 Stunden *"}
        },
        Operating: {
            en: {"Encapsulation": "IP67", "Size (l×w×h)": "194 × 67 × 63 mm", "Weight approx.": "0.550 kg"},
            nl: {"Waterdichtheid": "IP67", "Afmetingen (l×w×h)": "194 × 67 × 63 mm", "Gewicht ca.": "0.550 kg"},
            de: {"Wasserdicht": "IP67", "Abmessungen (l×w×h)": "194 × 67 × 63 mm", "Gewicht ca.": "0.550 kg"}
        }
    },
    filter: {filterThermal: false, filterNightvision: true, filterDigital: false, filterObservation: true, filterVoorzet: true, filterRiflescope: false}
},

{
    id: "nz6",
    orderID: "206",
    type: "night",
    title: {nl: "Lahoux LVS-31", en: "Lahoux LVS-31", de: "Lahoux LVS-31"},
    url: "Lahoux-LVS-31",
    price: "4239.00", // for NV price field is only used for sorting purposes; prce for NV is calculated through the options below
    options: {
        Groen: { 
            1200: {
                    veel: {"price": "3448.28", "number" : "01-0002-03531"},
                    weinig: { "price": "3793.11", "number": "01-0002-03531"},
                    geen: {"price": "4137.94", "number": "01-0002-03531"}
                    },
            1400: {
                    veel: {"price": "4137.93", "number" : "01-0002-03532"},
                    weinig: { "price": "4551.72", "number": "01-0002-03532"},
                    geen: {"price": "4965.52", "number": "01-0002-03532"}
                    },  
            1500: {
                    veel: {"price": "4827.59", "number" : "01-0002-03533"},
                    weinig: { "price": "5310.35", "number": "01-0002-03533"},
                    geen: {"price": "5793.11", "number": "01-0002-03533"}
                    },   
            1700: {
                    veel: {"price": "12379.31", "number" : "01-0002-03534"},
                    weinig: { "price": "13617.24", "number": "01-0002-03534"},
                    geen: {"price": "14855.17", "number": "01-0002-03534"}
                    },   
            1750: {
                    veel: {"price": "12379.31", "number" : "01-0002-03535"},
                    weinig: { "price": "13617.24", "number": "01-0002-03535"},
                    geen: {"price": "14855.17", "number": "01-0002-03535"}
                    },   
            1800: {
                    veel: {"price": "13068.97", "number" : "01-0002-03536"},
                    weinig: { "price": "14375.87", "number": "01-0002-03536"},
                    geen: {"price": "15682.76", "number": "01-0002-03536"}
                    },   
            1900: {
                    veel: {"price": "13758.62", "number" : "01-0002-03537"},
                    weinig: { "price": "15134.48", "number": "01-0002-03537"},
                    geen: {"price": "16510.34", "number": "01-0002-03537"}
                    },   
            1950: {
                    veel: {"price": "14448.28", "number" : "01-0002-03538"},
                    weinig: { "price": "15893.11", "number": "01-0002-03538"},
                    geen: {"price": "17337.94", "number": "01-0002-03538"}
                    },     
            },                            
        Onyx: {
            1200: {
                    veel: {"price": "3724.14", "number" : "01-0002-03539"},
                    weinig: { "price": "4096.55", "number": "01-0002-03539"},
                    geen: {"price": "4468.97", "number": "01-0002-03539"}
                    },
            1400: {
                    veel: {"price": "4413.79", "number" : "01-0002-03540"},
                    weinig: { "price": "4855.17", "number": "01-0002-03540"},
                    geen: {"price": "5296.55", "number": "01-0002-03540"}
                    },  
            1500: {
                    veel: {"price": "5103.45", "number" : "01-0002-03541"},
                    weinig: { "price": "5613.80", "number": "01-0002-03541"},
                    geen: {"price": "6124.14", "number": "01-0002-03541"}
                    },   
            1700: {
                    veel: {"price": "12655.17", "number" : "01-0002-03542"},
                    weinig: { "price": "13920.69", "number": "01-0002-03542"},
                    geen: {"price": "15186.20", "number": "01-0002-03542"}
                    },   
            1750: {
                    veel: {"price": "12655.17", "number" : "01-0002-03543"},
                    weinig: { "price": "13920.69", "number": "01-0002-03543"},
                    geen: {"price": "15186.20", "number": "01-0002-03543"}
                    },   
            1800: {
                    veel: {"price": "13344.83", "number" : "01-0002-03544"},
                    weinig: { "price": "14679.31", "number": "01-0002-03544"},
                    geen: {"price": "16013.80", "number": "01-0002-03544"}
                    },   
            1900: {
                    veel: {"price": "14034.48", "number" : "01-0002-03545"},
                    weinig: { "price": "15437.93", "number": "01-0002-03545"},
                    geen: {"price": "16841.38", "number": "01-0002-03545"}
                    },   
            1950: {
                    veel: {"price": "14724.14", "number" : "01-0002-03546"},
                    weinig: { "price": "16196.55", "number": "01-0002-03546"},
                    geen: {"price": "17668.97", "number": "01-0002-03546"}
                    },  
            }
    },
    shortDes: {     nl: "De nieuwe Lahoux LVS-31 is een dual-tube goggle systeem met ergonomisch design en verbeterde functionaliteit.", 
                    en: "The new Lahoux LVS-31 is a dual-tube goggle system with ergonomic design and improved functionality.", 
                    de: "Die neue Lahoux LVS-31 ist ein Zwei-Schlauch-Brillensystem mit ergonomischem Design und verbesserter Funktionalität."},
    longDes_1: {    nl: "De nieuwe Lahoux LVS-31 is een dual-tube goggle systeem met ergonomisch design en verbeterde functionaliteit. Het bevat functies zoals een speciale Instant-On-IR knop om snel en tijdelijk de IR-verlichting in te schakelen zonder de schakelaar te hoeven bedienen. De connector aan de voorzijde van het toestel wordt gebruikt om het toestel van stroom te voorzien via vrijwel elke stroombron: externe accu, USB powerbank, 12V auto-aansluiting enz. Op verzoek van de klant kan het toestel worden uitgerust met een bajonet- (PVS-7 systeem) of zwaluwstaartstijl beugel.", 
                    en: "The new Lahoux LVS-31 is a dual-tube goggle system with ergonomic design and improved functionality. It accommodates features such as a dedicated Instant-On-IR button to quickly and momentarily engage IR illuminator without having to operate the switch. The connector located at the front of the unit is used to supply power to the unit from almost any power source: external battery pack, USB power bank, 12V car socket and so on. By customer’s request, the unit can be equipped with Bayonet (PVS-7 system) or Dove-Tail style bracket.", 
                    de: "Die neue Lahoux LVS-31 ist ein Zwei-Schlauch-Brillensystem mit ergonomischem Design und verbesserter Funktionalität. Es verfügt über Funktionen wie eine spezielle Instant-On-IR-Taste, mit der die IR-Beleuchtung schnell und kurzzeitig aktiviert werden kann, ohne den Schalter betätigen zu müssen. Der Anschluss an der Vorderseite des Geräts dient zur Stromversorgung des Geräts über eine beliebige Stromquelle: externer Akku, USB-Powerbank, 12-V-Autosteckdose usw. Auf Kundenwunsch kann das Gerät mit einer Bajonett- (PVS-7 System) oder Schwalbenschwanz-Halterung ausgestattet werden."},                
    flyer: {nl:"Lahoux_LVS-31_EN_V1.0.pdf", en: "Lahoux_LVS-31_EN_V1.0.pdf", de: "Lahoux_LVS-31_EN_V1.0.pdf"},
    images: ["Lahoux_LVS-31.png", "Lahoux_LVS-31-2.png"],
    footnote_battery: true,
    techs: {
        Optics: {
            en: {"Tubes": "Photonis™, 2+, ECHO, ECHO HF, 4G (government only)", "Lenses": "Lenses optimized for ECHO HF and 4G", "Objective lens": "27 mm", "Relative aperture": "f/1.2", "Field of view": "40°"},
            nl: {"Buizen": "Photonis™, 2+, ECHO, ECHO HF, 4G (alleen overheid)", "Lenzen": "Lenses Lenzen geoptimaliseerd voor ECHO HF en 4G", "Objectieflens": "27 mm", "Relatieve lensopening": "f/1.2", "Gezichtsveld": "40°"},
            de: {"Rohre": "Photonis™, 2+, ECHO, ECHO HF, 4G (nur für die Regierung)", "Linsen": "Linsen optimiert für ECHO HF und 4G", "Objektivlinse": "27 mm", "Relative Blende": "f/1.2", "Sichtfeld": "40°"}
        },
        Electronics: {
            en: {"Power source": "1× AA or 1× CR123 or external 4 VDC – 15 VDC", "Battery life": "Up to 40 hours *"},
            nl: {"Stroombron": "1× AA of 1× CR123 of externe 4 VDC – 15 VDC", "Batterijduur": "Tot 40 uur *"},
            de: {"Stromquelle": "1× AA oder 1× CR123 oder Externe 4 VDC – 15 VDC", "Batterielaufzeit": "Bis zu 40 Stunden *"}
        },
        Operating: {
            en: {"Optical magnification": "1x", "Focusing range": "25 cm – infinity", "Diopter adjustment": "-6 to +4", "Interpupillary adjustment range": "50 – 90 mm", "Built-in IR illuminator": "Yes", "Momentary IR-on button": "Yes", "Operating temperature": "-40 °C to +50 °C", "Dimensions, mm": "112 x 105 x 70 mm", "Weight, grams": "675"},
            nl: {"Optische vergroting": "1x", "Scherpstelbereik": "25 cm – oneindig", "Dioptrie aanpassing": "-6 to +4", "Interpupilair instelbereik": "50 – 90 mm", "Ingebouwde IR verlichter": "Ja", "Tijdelijke IR-aan-knop": "Ja", "Bedrijfstemperatuur": "-40 °C to +50 °C", "Afmetingen, mm": "112 x 105 x 70 mm", "Gewicht, grams": "675"},
            de: {"Optische Vergrößerung": "1x", "Schärfebereich": "25 cm – unendlich", "Dioptrien-Einstellung": "-6 to +4", "Interpupillarer Einstellbereich": "50 – 90 mm", "Eingebauter IR-Strahler": "Ja", "Kurzzeitige IR-Einschalttaste": "Ja", "Betriebstemperatur": "-40 °C to +50 °C", "Abmessungen, mm": "112 x 105 x 70 mm", "Gewicht, grams": "675"}
        }
    },
    filter: {filterThermal: false, filterNightvision: true, filterDigital: false, filterObservation: true, filterVoorzet: false, filterRiflescope: false}
},


// **** Digital ****
// Last used id: dg4

{
    id: "dg1",
    orderID: "310",
    type: "digital",
    title: {nl: "Lahoux DigiClip", en: "Lahoux DigiClip", de: "Lahoux DigiClip"},
    url: "Lahoux-DigiClip",
    price: "1118.75",
    spotlight: { nl:"De Lahoux DigiClip is de perfecte oplossing voor digitaal nachtzicht voor jagers die op zoek zijn naar een hulpstuk met een goede prijs-kwaliteitverhouding voor hun nachtelijke jacht.", 
                    en: "The Lahoux DigiClip is the perfect digital night vision solution for hunters looking for an attachment with good value for money for their night shooting.", 
                    de: "Das Lahoux DigiClip ist die perfekte digitale Nachtsichtlösung für Jäger, die ein Vorsatzgerät mit einem guten Preis-Leistungs-Verhältnis für ihren Nachtansitz suchen."},

    shortDes: { nl:"De Lahoux DigiClip is de perfecte oplossing voor digitaal nachtzicht voor jagers die op zoek zijn naar een hulpstuk met een goede prijs-kwaliteitverhouding voor hun nachtelijke jacht.", 
                    en: "The Lahoux DigiClip is the perfect digital night vision solution for hunters looking for an attachment with good value for money for their night shooting.", 
                    de: "Das Lahoux DigiClip ist die perfekte digitale Nachtsichtlösung für Jäger, die ein Vorsatzgerät mit einem guten Preis-Leistungs-Verhältnis für ihren Nachtansitz suchen."},
    longDes_1: {    nl:"De Lahoux DigiClip is een van de kleinste en lichtste digitale nachtkijkers ter wereld. De Sony HD-sensor zorgt voor een contrastrijk, stabiel en helder beeld bij gebruik ‘s nachts, zelfs op grote afstand, dat wordt weergegeven op een 1280×960 HD-scherm. De toestellen zijn robuust (IPX&), schotvast, zelfs voor grote kalibers (tot .375 H&H), nauwkeurig (gewoonlijk is inschieten niet nodig) en herhaalnauwkeurig (minder dan 0,3 MOA).", 
                    en: "The Lahoux DigiClip is one of the smallest and lightest digital night vision attachments in the world. The Sony HD sensor enables a high-contrast, stable and clear image when used at night, even at long ranges, which is shown on a 1280×960 HD display. The units are rugged (IPX&), high recoil resistance even for large calibres (up to .375 H&H), accurate (usually no zeroing is required) and repeatable (less than 0.3 MOA).", 
                    de: "Das Lahoux DigiClip gehört zu den kleinsten und leichtesten digitalen Nachtsichtvorsatzgeräten weltweit. Der Sony HD-Sensor ermöglicht im Einsatz bei Nacht auch auf große Reichweiten ein kontrastreiches, stabiles und klares Bild, das auf einem 1280×960 HD Display abgebildet wird. Die Geräte sind robust (IPX&), schussfest auch für große Kaliber (bis .375 H&H), präzise (in der Regel kein Einschießen notwendig) und wiederholgenau (unter 0,3 MOA)."},
    pros: {     nl: ["Hoge detectie- en identificatieafstand bij nacht en schemering.", "Bewezen betrouwbaarheid en duurzaamheid.", "Zeer gemakkelijk te gebruiken; geen ingewikkeld menu of speciale instellingen.", "1280 × 720 px HD-sensor met een 1280 × 960 px HD OLED-scherm.", "Verbeterde nauwkeurigheid over langere afstanden.", "Zeer hoge terugslagbestendigheid bij zware kalibers zoals 12 gauge, 9,3 x 64 cal en .375 cal H&H Magnum.", "Kan overdag gebruikt worden.", "Voor een beter nachtzicht kunnen diverse IR-verlichtingstoestellen worden gebruikt. Deze zijn ook verkrijgbaar bij Lahoux Optics.", "Grote herhaalbaarheid met niet meer dan 0,3 MOA afwijking bij herhaaldelijk monteren en demonteren.", "Kan gebruikt worden met een USB power bank.", "Video-uitgangspoort. Kan gebruikt worden met de PB2R powerbank met ingebouwde recorder"], 
                en: ["High detection and identification distance at night and dusk.", "Proven reliability and durability.", "Very easy to use; no complicated menu or special settings.", "1280 × 720 px HD sensor with a 1280 × 960 px HD OLED screen.", "Improved accuracy over longer distances.", "Very high recoil resistance with heavy calibres such as 12 cal., 9.3 × 64 cal and .375 cal. H&H Magnum.", "Can be used during the day.", "For better night vision, various IR illuminators can be used. These are also available from Lahoux Optics.", "Great repeatability with no more than 0.3 MOA deviation with repeated mounting and dismounting.", "Can be used with a USB power bank.", "Video output port. Can be used with PB2R power bank with built-in recorder."], 
                de: ["Hohe Erkennungs- und Identifikationsdistanz bei Nacht und Dämmerung.", "Bewährte Zuverlässigkeit und Langlebigkeit.", "Sehr einfach zu bedienen; ohne komplexes Menü oder spezielle Einstellungen.", "1280 × 720 px HD-Sensor mit einem 1280 × 960 px HD-OLED-Display.", "Verbesserte Genauigkeit über größere Entfernungen.", "Sehr hohe Rückstoßfestigkeit bei schweren Kalibern wie z. Bsp. Kal. 12, Kal. 9,3 x 64 und Kal. .375 H&H Magnum.", "Kann tagsüber verwendet werden.", "Für eine bessere Nachtsicht können verschiedene IR-Strahler verwendet werden. Diese sind ebenfalls bei Lahoux Optics erhältlich.", "Große Wiederholgenauigkeit mit nicht mehr als 0,3 MOA Abweichung bei wiederholter Montage und Demontage.", "Kann mit einer USB-Powerbanks verwendet werden.", "Video-Ausgangsanschluss. Kann zusammen mit der PB2R Powerbank mit eingebautem Rekorder verwendet werden."]

    },
    number: "05-0006-02759",
    leveromvang: [1, 16, 21, 4, 9],
    flyer: {nl:"Lahoux_DigiClips_NL.pdf", en: "Lahoux_DigiClip_EN.pdf", de: "Lahoux_DigiClip_DE.pdf"},
    images: ["dg1_1.png", "dg1_2.png", "dg1_3.png", "dg1_4.png"],
    related: ["nz2"],
    accessories: ["as1", "as2", "as10", "as6", "as16"],
    footnote_battery: true,
    techs: {
        Technical: { 
            en: {"Sensor type": "CMOS 1/1.8\", 1280×720, PAL", "Spectral range": "0.4 to 1.1 µm", "Frame rate": "50 Hz", "Sensitivity": "0.0001 to 30,000 Lux", "Resolution": "67 lp/mm", "Clip-on visual magnification": "1x", "Eyepiece visual magnification": "4×", "Day sight recommended magnification": "1-12×", "Lens": "36 mm F/1.4", "Lens focusing range": "6m to ∞", "Display": "Colour OLED 1280×960 pixels", "FOV": "9°×12°", "Diopter adjustment": "+3 to-3 D", "Quick release adapter": "Lahoux or Rusan adapter M52x0.75 mm", "Battery": "1 × 18650", "Battery runtime": "4 hours (at 24 °C) *", "External power": "3.2-15 V", "Weight (without battery)": "480 g", "Dimensions": "128×60×70 mm", "Working temperature range": "-20°C – +50°C", "Protection class IEC 60529": "IPX6", "Max. recoil energy": "500 g"},
            nl: {"Sensortype": "CMOS 1/1.8\", 1280×720, PAL", "Spectraalbereik": "0.4 to 1.1 µm", "Beeldfrequentie": "50 Hz", "Gevoeligheid": "0.0001 to 30,000 Lux", "Resolutie": "67 lp/mm", "Clip-on visuele vergroting": "1x", "Visuele vergroting oculair": "4×", "Day sight recommended magnification": "1-12×", "Lens": "36 mm F/1.4", "Scherpstelbereik lens": "6m to ∞", "Beeldscherm": "Kleur OLED 1280×960 pixels", "FOV": "9°×12°", "Dioptrie aanpassing": "+3 to-3 D", "Adapter met snelsluiting": "Lahoux or Rusan adapter M52x0.75 mm", "Batterij": "1 × 18650", "Batterijduur": "4 uur (bij 24 °C) *", "Externe voeding": "3.2-15 V", "Gewicht (zonder batterijen)": "480 g", "Afmetingen": "128×60×70 mm", "Bedrijfstemperatuurbereik": "-20°C – +50°C", "Beschermingsklasse IEC 60529": "IPX6", "Max. terugslagenergie": "500 g"},
            de: {"Sensor-Typ": "CMOS 1/1.8\", 1280×720, PAL", "Spektrallbereich": "0.4 to 1.1 µm", "Bildfrequenz": "50 Hz", "Empfindlichkeit": "0.0001 to 30,000 Lux", "Auflösung": "67 lp/mm", "Visuelle Vergrößerung zum Anstecken": "1x", "Visuelle Vergrößerung des Okulars": "4×", "Day sight recommended magnification": "1-12×", "Linse": "36 mm F/1.4", "Schärfebereich des Linse": "6m to ∞", "Anzeige": "Farbe OLED 1280×960 pixels", "FOV": "9°×12°", "Dioptrien-Einstellung": "+3 to-3 D", "Schnellverschluss-Adapter": "Lahoux or Rusan adapter M52x0.75 mm", "Batterie": "1 × 18650", "Batterielaufzeit": "4 Stunden (bei 24 °C) *", "Externe Stromversorgung": "3.2-15 V", "Gewicht (ohne Batterien)": "480 g", "Abmessungen": "128×60×70 mm", "Betriebstemperaturbereich": "-20°C – +50°C", "Schutzklasse IEC 60529": "IPX6", "Max. Rückstoßenergie": "500 g"}
        },
        
    },
    filter: {filterThermal: false, filterNightvision: false, filterDigital: true, filterObservation: false, filterVoorzet: true, filterRiflescope: false}
},


/*
    *** Remove Lahoux DigiClip Pro from site: EOL; keep data here for future use ***
{
    id: "dg2",
    orderID: "2",
    type: "digital",
    title: {nl: "Lahoux DigiClip Pro", en: "Lahoux DigiClip Pro", de: "Lahoux DigiClip Pro"},
    url: "DigiClip-Pro",
    price: "1659.24",
    shortDes: { nl:"De Lahoux DigiClip pro is de perfecte camera voor gebruikers die een nog beter beeld en meer afstand willen bij de nachtelijke aanzit.", 
                    en: "The Lahoux DigiClip pro is the perfect camera for professional and amateur users that want an even better image and more  distance.", 
                    de: "Das Lahoux DigiClip Pro ist die perfekte Kamera für Anwender, die ein noch besseres Bild und mehr Abstand bei Nacht wünschen."},
    longDes_1: {    nl:"Dankzij gebruik van hoog-contrast glas en een hoge resolutie van de sensor overtreft de Lahoux Digiclip vergelijkbare camera’s met zijn uitstekende beeldkwaliteit. De Lahoux DigiClip pro heeft een metalen behuizing met IPX6 beschermingsklasse waardoor hij robuust is en een lange levensduur heeft. Deze voorzet heeft, zoals wordt verwacht mag worden van Lahoux Optics, een 100% herhaalbaarheid bij herplaatsen en een hoge schokbestendigheid. Hij is compact en heeft een ergonomisch ontwerp waardoor hij makkelijk te hanteren is. De eenvoudig bereikbare focusknop en fijne klikverstelling zorgt voor een eenvoudige scherpstelling. De Lahoux DigiClip pro is de perfecte camera voor gebruikers die een nog beter beeld en meer afstand willen bij de nachtelijke aanzit.", 
                    en: "Due to the high-contrast lens and high-resolution sensor, the Lahoux Digiclip pro surpasses similar devices with its excellent imaging. The Lahoux Digiclip pro has a metal housing with IPX6 class protection for robustness and a long life span. It has, as expected of Lahoux Optics, a 100% repeatability during reinstallation and high recoil resistance. The sleek shape and ergonomic design makes it easy to handle. The easily accessible focusknob and microclick settings makes focussing very easy. The Lahoux DigiClip pro is the perfect camera for professional and amateur users that want an even better image and more  distance.", 
                    de: "Dank der Verwendung von kontrastreichem Glas und einem hochauflösenden Sensor stellt das Lahoux DigiClip Pro vergleichbare Kameras mit seiner hervorragenden Bildqualität in den Schatten. Das Lahoux DigiClip Pro hat ein Metallgehäuse mit der Schutzklasse IPX6 und ist somit robust und langlebig. Dieser Aufsatz hat, wie man es von Lahoux Optics erwarten kann, eine 100%ige Wiederholgenauigkeit bei der Repositionierung und eine hohe Stoßfestigkeit. Er ist kompakt und hat ein ergonomisches Design, dass die Handhabung erleichtert. Der gut erreichbare Fokussierknopf und die feine Rasterung sorgen für eine einfache Fokussierung. Das Lahoux DigiClip Pro ist die perfekte Kamera für Anwender, die ein noch besseres Bild und mehr Abstand bei Nacht wünschen."},
    pros: {     nl: ["Hoge detectie- en identificatieafstand bij nacht en schemering", "Bewezen betrouwbaarheid en duurzaamheid", "Zeer eenvoudig in gebruik zonder complex menu of speciale instellingen", "1280×720 HD sensor met een 1280×960 HD OLED display", "Verbeterde nauwkeurigheid over grotere afstanden", "Zeer hoge terugslagbestendigheid bij zware kalibers zoals cal. 12, 9.3×64, .375 H&H Magnum", "Kan overdag worden gebruikt", "Verschillende IR stralers kunnen gebruikt worden voor een beter nachtelijk beeld. Deze zijn ook verkrijgbaar bij Lahoux Optics", "Grote herhaalnauwkeurigheid met niet meer dan 0,3 MOA divergentie bij herhaaldelijk demonteren en monteren", "Kan gebruikt worden met USB-powerbanks", "Video out-connector. Kan gebruikt worden samen met de PB2R-powerbank met ingebouwde recorder"], 
                en: ["High target detection and identification distance during night and twilight", "Proven reliability and durability", "Super simple operation without any complicated menu or special settings", "1280×720 HD sensor with a 1280×960 HD OLED display", "Improved accuracy over longer distance", "Very high recoil resistance with heavy calibres such as 12 cal., 9.3×64, .375 H&H Magnum", "Can be used during daytime", "Several IR illuminators can be used for improved night time imaging which are also available from? Lahoux Optics", "Great repeatability, having not more than 0.3 MOA divergence when repeatedly dismounted and mounted", "Can be used with USB power banks", "Video out-connector. Can be used together with the power bank PB2R with built in recorder"], 
                de: ["Hohe Erkennungs- und Identifikationsdistanz bei Nacht und Dämmerung", "Bewährte Zuverlässigkeit und Langlebigkeit", "Sehr einfach zu bedienen; ohne komplexes Menü oder spezielle Einstellungen", "1280 × 720 px HD-Sensor mit einem 1280 × 960 px HD-OLED-Display", "Verbesserte Genauigkeit über größere Entfernungen", "Sehr hohe Rückstoßfestigkeit bei schweren Kalibern wie z. Bsp. Kal. 12, Kal. 9,3 x 64 und Kal. .375 H&H Magnum", "Kann tagsüber verwendet werden", "Für eine bessere Nachtsicht können verschiedene IR-Strahler verwendet werden. Diese sind ebenfalls bei Lahoux Optics erhältlich", "Große Wiederholgenauigkeit mit nicht mehr als 0,3 MOA Abweichung bei wiederholter Montage und Demontage", "Kann mit einer USB-Powerbanks verwendet werden", "Video-Ausgangsanschluss. Kann zusammen mit der PB2R Powerbank mit eingebautem Rekorder verwendet werden" ]

    },
    number: "01-0006-02715",
    leveromvang: [1, 16, 21, 4, 9],
    flyer: {nl:"Lahoux_DigiClips_NL_V1.pdf", en: "Lahoux_DigiClips_EN_V1.pdf", de: "Lahoux_DigiClips_DE_V1.pdf"},
    images: ["dg2_1.png", "dg2_2.png", "dg2_3.png", "dg2_4.png"],
    related: ["dg1", "nz1"],
    accessories: ["as1", "as2", "as10", "as6", "as16"],
    footnote_battery: true,
    techs: {
        Technical: { "Sensor type": "CMOS 1/2″, 1280×720, PAL", "Format": "1280 × 720 px", "Spectral range": "0.4 to 1.1 µm", "Frame rate": "50 Hz", "Sensitivity": "0.0001 to 30,000 Lux", "Resolution": "67 lp/mm", "Clip-on visual magnification": "1x", "Eyepiece visual magnification": "2,5×", "Day sight recommended magnification": "1-15×", "Lens": "54 mm F/1.4", "Lens focusing range": "6m to ∞", "Detection range (boar)": "500 m", "Display": "Colour OLED 1280×960 pixels", "FOV": "7°×11°", "Diopter adjustment": "+3 to -4 D", "Quick release adapter": "Lahoux or Rusan adapter M52x0.75 mm", "Battery": "1 × 18650", "Battery runtime": "4 hours (at 24 °C) *", "External power": "3.2-15 V", "Weight (without battery)": "560 g", "Dimensions": "167×75×70 mm", "Working temperature range": "-20°C – +50°C", "Protection class IEC 60529": "IPX6", "Max. recoil energy": "500 g" },
        
    },
    filter: {filterObservation: false, filterVoorzet: true, filterRiflescope: false}
    
},
*/
	
{
    id: "dg3",
    orderID: "303",
    type: "digital",
    title: {nl: "Lahoux DigiScope", en: "Lahoux DigiScope", de: "Lahoux DigiScope"},
    url: "Lahoux-DigiScope",
    price: "884.35",
    shortDes: { nl:"De Lahoux DigiScope is een digitale richtkijker die dag en nacht met maximale precisie kan worden gebruikt. Dit maakt het mogelijk om zowel overdag als ‘s nachts met één richtkijker op het geweer te jagen.", 
                    en: "The Lahoux DigiScope is a digital riflescope that can be used day and night with maximum precision. This makes it possible to hunt with one scope fixed to the rifle during day and night.", 
                    de: "Das Lahoux DigiScope ist ein digitales Zielfernrohr, das bei Tag und Nacht mit höchster Präzision eingesetzt werden kann. Dadurch ist es möglich, mit einem einzigen Zielfernrohr am Gewehr zu jagen, sowohl bei Tag als auch bei Nacht."},
    longDes_1: {    nl:"De Lahoux DigiScope is een digitale richtkijker die dag en nacht met maximale precisie kan worden gebruikt. Dit maakt het mogelijk om zowel overdag als ‘s nachts met één richtkijker op het geweer te jagen. Overdag is de richtkijker full colour met superieure beelddetails en een zeer hoge zoom mogelijkheid. Zelfs bij dageraad en schemering en bij maanlicht zijn kleurenbeelden mogelijk dankzij de zeer gevoelige sensor. Voor nachtelijk gebruik, met de meegeleverde en afneembare IR verlichting, is het zwart/wit beeld superieur in resolutie en gevoeligheid. Wilde dieren kunnen worden geobserveerd en geïdentificeerd dankzij de gedetailleerde beelden. De beeldverversingsfrequentie van 50 Hz zorgt voor een vloeiend en trillingsvrij beeld. De levensduur van de batterijen is uitzonderlijk voor dit type vizier. Kenmerkend voor de Lahoux DigiScope zijn de 1440×1080 hooggevoelige FPA en de 50 mm lens voor lange afstand observatie. De Lahoux DigiScope kan worden gebruikt voor observatie en heeft ook voldoende energiereserve voor lange afstandsopnamen. Dit is niet in de laatste plaats te danken aan zijn 4 tot 16-voudige vergroting. De Lahoux DigiScope is een zeer betaalbare combinatie van een klassiek richtkijker uiterlijk voor 30 mm ringen en een digitaal beeld dat dag en nacht gebruikt kan worden.", 
                    en: "The Lahoux DigiScope is a digital riflescope that can be used day and night with maximum precision. This makes it possible to hunt with one scope fixed to the rifle during day and night. During the day the scope is full colour with supreme image details and a very high zoom possibility. Even during dawn and dusk and with moonlight colour images are possible due to the very sensitive sensor. For night-time use, with the included and detachable IR illuminator, the black and white image is superior in resolution and sensitivity. Wildlife can be observed and identified because of the detailed images. The image refresh rate of 50 Hz enables a smooth and vibration free image. The battery runtime is exceptional for this type of sight. Characteristical features of the Lahoux DigiScope include its 1440×1080 highly sensitive FPA and the 50 mm lens for long range observation. The Lahoux DigiScope can be used for observation and also has sufficient power reserve for long range shots. This is not at least thanks to its 4 to 16 times magnification. The Lahoux DigiScope is a very affordable combination of classic rifle scope appearance for 30 mm rings and a digital image that can be used day and night.", 
                    de: "Das Lahoux DigiScope ist ein digitales Zielfernrohr, dass am Tage und in der Nacht mit höchster Präzision eingesetzt werden kann. Dies ermöglicht die Jagd mit einem am Gewehr befestigten Zielfernrohr. Tagsüber bietet das DigiScope ein volles Farbspektrum mit überragenden Bilddetails und einer sehr hohen Zoommöglichkeit. Auch in der Morgen-/Abenddämmerung und bei Mondschein sind Farbbilder durch den sehr empfindlichen Sensor möglich. Für den nächtlichen Gebrauch, mit dem mitgelieferten und abnehmbaren IR Illuminator, ist das Schwarz-Weiß-Bild in Auflösung und Empfindlichkeit dem Tagesbild überlegen. Wildtiere können somit beobachtet und genau identifiziert werden aufgrund der detailreichen Bilder. Die Bildwiederholfrequenz von 50 Hz ermöglicht ein flüssiges und vibrationsfreies Bild. Die Akkulaufzeit ist für diese Art von Zielfernrohr außergewöhnlich lang und beständig. Charakteristische Merkmale des Lahoux DigiScope sind der hochempfindliche FPA 1440×1080 und das 50-mm-Objektiv für die Fernbeobachtung. Das Lahoux DigiScope kann zum Beobachten verwendet werden und hat auch genug Reserven für Distanzschüsse. Das liegt nicht zuletzt an seiner 4- bis 16-fachen Vergrößerung. Das Lahoux DigiScope ist eine sehr erschwingliche Kombination aus einem klassischen Zielfernrohroptik für 30-mm-Ringe und einem digitalen Bild, das sowohl tagsüber als auch nachtüber verwendet werden kann."},
    pros: {     nl: ["1440×1080 zeer gevoelige FPA voor maximale scherpte en beeldkwaliteit", "Kleur (dag) en zwart-wit (nacht) modi", "50 Hz beeldverversingsfrequentie voor een stabiel beeld", "Foto- en videofunctie", "Tot 16x vergroting"], 
                en: ["1440×1080 highly sensitive FPA for maximum sharpness and image quality", "Colour (day) and Black and White (night) modes", "50 Hz image refresh rate for a stabile image.", "Photo and video function", "Up to 16x magnification"], 
                de: ["1440 x 1080 hochempfindlicher FPA für maximale Schärfe und Bildqualität", "Farbmodus (Tag) und Schwarzweißmodus (Nacht)", "50 Hz Bildwiederholfrequenz für ein stabiles Bild.", "Foto- und Videofunktion", "Bis zu 16-fache Vergrößerung" ]

    },
    number: "05-0006-03175",
    ean: "6090301524552",
    warning: true,
    leveromvang: [1, 16, 21, 4, 9],
    flyer: {nl:"Lahoux_DigiScope_NL_V1.03.pdf", en: "Lahoux_DigiScope_EN_V1.03.pdf", de: "Lahoux_DigiScope_DE_V1.03.pdf"},
    images: ["dg3_1.png", "dg3_2.png", "dg3_3.png"],
    endOfLife: true, 
    techs: {
        Detector: { 
            en: {"Resolution": "1440×1080 digital, highly sensitive FPA", "Frame rate": "50 Hz"},
            nl: {"Resolutie": "1440×1080 digitaal, zeer gevoelig FPA", "Beeldfrequentie": "50 Hz"},
            de: {"Auflösung": "1440×1080 digital, hochempfindlich FPA", "Bildfrequenz": "50 Hz"}
        },
        Optics: {
            en: {"Objectief lens": "50", "Magnification (digital)": "4.0~16.0", "Microstap Zoom": "Yes", "Display Resolution": "1280×960", "Type": "LCOS", "Eye relief": "70", "Diopter adjustment": "-4~+4"},
            nl: {"Objectieflens": "50", "Vergroting (digitaal)": "4.0~16.0", "Microstap Zoom": "Ja", "Beeldschermresolutie": "1280×960", "Type": "LCOS", "Oogafstand": "70", "Dioptrie aanpassing": "-4~+4"},
            de: {"Objektivlinse": "50", "Vergrößerung (digital)": "4.0~16.0", "Microstap Zoom": "Ja", "Anzeige Auflösung": "1280×960", "Typ": "LCOS", "Augenabstand": "70", "Dioptrien-Einstellung": "-4~+4"}
        },
        Operating: {
            en: {"Battery": "Built-in 6600mAh and a replaceable 18500 battery", "External power supply" : "5V (Type USB-C)", "Field of view": "6.6 × 4.9", "Diameter of the riflescope": "30 mm", "Max. recoil power": "1000 g/s²", "Encapsulation": "IP67", "Internal memory": "16 GB", "Operating temperature range": "-20~ +50 °C", "Weight": "0.95 kg", "IR illuminator": "850/940", "Stadiametric rangefinder": "Yes"},
            nl: {"Batterij": "Ingebouwde 6600mAh en een vervangbare 18500 batterij", "Externe voeding" : "5V (Type USB-C)", "Gezichtsveld": "6.6 × 4.9", "Diameter van de richtkijker": "30 mm", "Max. terugslagvermogen": "1000 g/s²", "Waterdichtheid": "IP67", "Intern geheugen": "16 GB", "Bedrijfstemperatuurbereik": "-20~ +50 °C", "Gewicht": "0.95 kg", "IR-verlichter": "850/940", "Stadiametrische afstandsmeter": "Ja"},
            de: {"Batterie": "Eingebauter 6600mAh und austauschbarer 18500 batterie", "Externe Stromversorgung" : "5V (Type USB-C)", "Sichtfeld": "6.6 × 4.9", "Durchmesser des Zielfernrohrs": "30 mm", "Max. Rückstoßleistung": "1000 g/s²", "Wasserdicht": "IP67", "Interne Speicher": "16 GB", "Betriebstemperaturbereich": "-20~ +50 °C", "Gewicht": "0.95 kg", "IR-Strahler": "850/940", "Stadiametrischer Entfernungsmesser": "Ja"}
        }
    },
    filter: {filterThermal: false, filterNightvision: false, filterDigital: true, filterObservation: false, filterVoorzet: false, filterRiflescope: true}
},

{
    id: "dg4",
    orderID: "300",
    type: "digital",
    title: {nl: "Lahoux DigiScope V2", en: "Lahoux DigiScope V2", de: "Lahoux DigiScope V2"},
    url: "Lahoux-DigiScope-V2",
    price: "979.31",
    shortDes: {  nl:"De Lahoux DigiScope V2 is een nieuwe digitale richtkijker die dag en nacht met maximale precisie kan worden gebruikt. Dit maakt het mogelijk om zowel overdag als ‘s nachts met één richtkijker op het geweer te jagen.", 
                 en: "The Lahoux Digiscope V2 is a new digital riflescope that can be used day and night with maximum precision. This makes it possible to hunt with one scope fixed to the rifle during day and night.", 
                 de: "Das Lahoux Digiscope V2 ist ein neues digitales Zielfernrohr, das bei Tag und Nacht mit höchster Präzision eingesetzt werden kann. Dadurch ist es möglich, mit einem einzigen Zielfernrohr am Gewehr zu jagen, sowohl bei Tag als auch bei Nacht."},
    longDes_1: { nl:"De Lahoux Digiscope V2 is een nieuwe digitale richtkijker die dag en nacht met maximale precisie kan worden gebruikt. Dit maakt het mogelijk om zowel overdag als ‘s nachts met één richtkijker op het geweer te jagen. Overdag is de richtkijker full colour met superieure beelddetails en een hoge zoom mogelijkheid. Zelfs bij dageraad en schemering en bij maanlicht zijn kleurenbeelden mogelijk dankzij de zeer gevoelige sensor. Voor nachtelijk gebruik, met de meegeleverde en afneembare IR verlichting, is het zwart/wit beeld superieur in resolutie en gevoeligheid. Wilde dieren kunnen worden geobserveerd en geïdentificeerd dankzij de gedetailleerde beelden. De beeldverversingsfrequentie van 50 Hz zorgt voor een vloeiend en trillingsvrij beeld. De levensduur van de batterijen is uitzonderlijk voor dit type vizier. Kenmerkend voor de Lahoux Digiscope V2 zijn de 1920×1080 hooggevoelige FPA en de 50 mm lens voor lange afstand observatie. De Lahoux Digiscope V2 kan worden gebruikt voor obeservatie en heeft ook voldoende energiereserve voor lange afstandsopnamen. Dit is niet in de laatste plaats te danken aan zijn 1 tot 8-voudige vergroting. De Lahoux Digiscope V2 is een zeer betaalbare combinatie van een klassiek richtkijker uiterlijk voor 30 mm montage en een digitaal beeld dat dag en nacht gebruikt kan worden.", 
                 en: "The Lahoux Digiscope V2 is a new digital riflescope that can be used day and night with maximum precision. This makes it possible to hunt with one scope fixed to the rifle during day and night. During the day the scope is full colour with supreme image details and a high zoom possibility. Even during dawn and dusk and with moonlight colour images are possible due to the very sensitive sensor. For night-time use, with the included and detachable IR illuminator, the black and white image is superior in resolution and sensitivity. Wildlife can be observed and identified because of the detailed images. The image refresh rate of 50 Hz enables a smooth and vibration free image. The battery runtime is exceptional for this type of sight. Characteristical features of the Lahoux Digiscope V2 include its 1920×1080 highly sensitive FPA and the 50 mm lens for long range observation. The Lahoux Digiscope V2 can be used for observation and also has sufficient power reserve for long range shots. This is not at least thanks to its 1 to 8 times magnification. The Lahoux Digiscope V2 is a very affordable combination of classic rifle scope appearance for 30 mm mount and a digital image that can be used day and night.", 
                 de: "Das Lahoux Digiscope V2 ist ein neues digitales Zielfernrohr, dass am Tage und in der Nacht mit höchster Präzision eingesetzt werden kann. Dies ermöglicht die Jagd mit einem am Gewehr befestigten Zielfernrohr. Tagsüber bietet das DigiScope ein volles Farbspektrum mit überragenden Bilddetails und einer hohen Zoommöglichkeit. Auch in der Morgen-/Abenddämmerung und bei Mondschein sind Farbbilder durch den sehr empfindlichen Sensor möglich. Für den nächtlichen Gebrauch, mit dem mitgelieferten und abnehmbaren IR Illuminator, ist das Schwarz-Weiß-Bild in Auflösung und Empfindlichkeit dem Tagesbild überlegen. Wildtiere können somit beobachtet und genau identifiziert werden aufgrund der detailreichen Bilder. Die Bildwiederholfrequenz von 50 Hz ermöglicht ein flüssiges und vibrationsfreies Bild. Die Akkulaufzeit ist für diese Art von Zielfernrohr außergewöhnlich lang und beständig. Charakteristische Merkmale des Lahoux Digiscope V2 sind der hochempfindliche FPA 1920×1080 und das 50-mm-Objektiv für die Fernbeobachtung. Das Lahoux Digiscope V2 kann zum Beobachten verwendet werden und hat auch genug Reserven für Distanzschüsse. Das liegt nicht zuletzt an seiner 1 bis 8x Vergrößerung. Das Lahoux Digiscope V2 ist eine sehr erschwingliche Kombination aus einem klassischen Zielfernrohr für 30-mm Montage und einem digitalen Bild, das sowohl tagsüber als auch nachtüber verwendet werden kann."},
    pros: {      nl: ["1920×1080 zeer gevoelige FPA voor maximale scherpte en beeldkwaliteit", "Kleur (dag) en zwart-wit (nacht) modi", "50 Hz beeldverversingsfrequentie voor een stabiel beeld", "Foto- en videofunctie", "Tot 16x vergroting"], 
                 en: ["1920×1080 highly sensitive FPA for maximum sharpness and image quality", "Colour (day) and Black and White (night) modes", "50 Hz image refresh rate for a stabile image.", "Photo and video function", "Up to 16x magnification"], 
                 de: ["1920×1080 hochempfindlicher FPA für maximale Schärfe und Bildqualität", "Farbmodus (Tag) und Schwarzweißmodus (Nacht)", "50 Hz Bildwiederholfrequenz für ein stabiles Bild.", "Foto- und Videofunktion", "Bis zu 16-fache Vergrößerung" ]

    },
    number: "05-0006-03774",
//    ean: "6090301524552",
    warning: true,
//    leveromvang: [1, 16, 21, 4, 9],
//   flyer: {nl:"Lahoux_DigiScope_NL_V1.03.pdf", en: "Lahoux_DigiScope_EN_V1.03.pdf", de: "Lahoux_DigiScope_DE_V1.03.pdf"},
    images: ["Lahoux_DigiScope_V2.png"],
    endOfLife: false, 
    techs: {
        Detector: { 
            en: {"Resolution": "1920×1080@4μm CMOS", "Frame rate": "50 Hz"},
            nl: {"Resolutie": "1920×1080@4μm CMOS", "Beeldfrequentie": "50 Hz"},
            de: {"Auflösung": "1920×1080@4μm CMOS", "Bildfrequenz": "50 Hz"}
        },
        Optics: {
            en: {"Objectief lens": "50", "Optical Magnification": "3.125x", "Digital magnification": "1-8x", "Field of View":"9°x 5.2°", "Display Resolution": "1920×1080 AMOLED", "Eye relief": "≥48 mm"},
            nl: {"Objectieflens": "50", "Optische vergroting": "3.125x", "Digitale vergroting": "1-8x", "Gezichtsveld":"9°x 5.2°", "Beeldschermresolutie": "1920×1080 AMOLED", "Oogafstand": "≥48 mm"},
            de: {"Objektivlinse": "50", "Optische Vergrößerung": "3.125x", "Digitale Vergrößerung": "1-8x", "Sichtfeld":"9°x 5.2°", "Anzeige Auflösung": "1920×1080 AMOLED", "Augenabstand": "≥48 mm"}
        },
        Operating: {
            en: {"Battery": "Built-in and a replaceable battery", "Battery life": "15 hours", "External power supply" : "5V (Type USB-C)", "Diameter of the riflescope": "30 mm", "Encapsulation": "IP67", "Internal memory": "32 GB", "Operating temperature range": "-30 ~ +50 °C", "Weight": "≤930 g without battery", "IR illuminator": "850 nm", "Dimensions": "367×107×69 mm"},
            nl: {"Batterij": "Ingebouwde en een vervangbare batterij", "Batterijduur": "15 uur", "Externe voeding" : "5V (Type USB-C)", "Diameter van de richtkijker": "30 mm", "Waterdichtheid": "IP67", "Intern geheugen": "32 GB", "Bedrijfstemperatuurbereik": "-30 ~ +50 °C", "Gewicht": "≤930 g zonder batterij", "IR-verlichter": "850 nm", "Afmetingen": "367×107×69 mm"},
            de: {"Batterie": "Eingebauter und austauschbarer batterie", "Batterielaufzeit": "15 Stunden", "Externe Stromversorgung" : "5V (Type USB-C)", "Durchmesser des Zielfernrohrs": "30 mm", "Wasserdicht": "IP67", "Interne Speicher": "32 GB", "Betriebstemperaturbereich": "-30 ~ +50 °C", "Gewicht": "≤930 g ohne Akku", "IR-Strahler": "850 nm", "Abmessungen": "367×107×69 mm"}
        }
    },
    filter: {filterThermal: false, filterNightvision: false, filterDigital: true, filterObservation: false, filterVoorzet: false, filterRiflescope: true}
},


// **** Accessories ****
// Last used id: as29

{
    id: "as1",
    orderID: "401",
    type: "accessories",
    title: {nl: "Helios", en: "Helios", de: "Helios"},
    url: "Lahoux-Helios",
    price: "437.50",
    spotlight: { nl:"De nieuwe Lahoux Helios IR-straler is een LED-verlichtingstool en kan worden gebruikt ter ondersteuning van een nachtkijker met weinig restlicht. Het observatiegebied wordt verlicht met de illuminator in een licht dat onzichtbaar is voor het menselijk oog bij een golflengte van 855 nm. De helderheid is traploos dimbaar, de hoek van de lichtkegel is veranderlijk.    ", 
                    en: "The new Lahoux Helios IR illuminator is a LED lighting tool and can be used to support a night vision attachment with very little residual light. The observation area is illuminated with the illuminator in a light that is invisible to the human eye at a wavelength of 855 nm. The brightness is infinitely dimmable, the angle of the light cone is changeable.", 
                    de: "Der neue Lahoux Helios IR-Strahler ist ein LED Beleuchtungshilfsmittel und kann unterstützend für das Nachtsichtvorsatzgerät bei sehr wenig Restlicht verwendet werden. Der Beobachtungssektor wird dabei mit dem Strahler in einem für das menschliche Auge unsichtbarem Licht in einer Wellenlänge von 855 nm ausgeleuchtet. Die Helligkeit ist stufenlos dimmbar, der Winkel vom Leuchtkegel ist veränderbar."},

    shortDes: { nl:"De nieuwe Lahoux Helios IR-straler is een LED-verlichtingstool en kan worden gebruikt ter ondersteuning van een nachtkijker met weinig restlicht. Het observatiegebied wordt verlicht met de illuminator in een licht dat onzichtbaar is voor het menselijk oog bij een golflengte van 855 nm. De helderheid is traploos dimbaar, de hoek van de lichtkegel is veranderlijk.    ", 
                    en: "The new Lahoux Helios IR illuminator is a LED lighting tool and can be used to support a night vision attachment with very little residual light. The observation area is illuminated with the illuminator in a light that is invisible to the human eye at a wavelength of 855 nm. The brightness is infinitely dimmable, the angle of the light cone is changeable.", 
                    de: "Der neue Lahoux Helios IR-Strahler ist ein LED Beleuchtungshilfsmittel und kann unterstützend für das Nachtsichtvorsatzgerät bei sehr wenig Restlicht verwendet werden. Der Beobachtungssektor wird dabei mit dem Strahler in einem für das menschliche Auge unsichtbarem Licht in einer Wellenlänge von 855 nm ausgeleuchtet. Die Helligkeit ist stufenlos dimmbar, der Winkel vom Leuchtkegel ist veränderbar."},
    longDes_1: {    nl:"De nieuwe Lahoux Helios IR-illuminator is een LED verlichter en kan worden gebruikt om de nachtzicht kijker te ondersteunen in omstandigheden van zeer weinig restlicht. Het te observeren gebied wordt verlicht met de illuminator in een licht dat onzichtbaar is voor het menselijk oog, bij een golflengte van 855 nm. De helderheid is oneindig dimbaar, de hoek van de lichtkegel is te veranderen.", 
                    en: "The new Lahoux Helios IR illuminator is a LED lighting tool and can be used to support a night vision attachment with very little residual light. The observation area is illuminated with the illuminator in a light that is invisible to the human eye at a wavelength of 855 nm. The brightness is infinitely dimmable, the angle of the light cone is changeable.", 
                    de: "Der neue Lahoux Helios IR-Strahler ist ein LED-Beleuchtungshilfsmittel und kann unterstützend für das Nachtsichtvorsatzgerät bei sehr wenig Restlicht verwendet werden. Der Beobachtungssektor wird dabei mit dem Strahler in einem für das menschliche Auge unsichtbarem Licht in einer Wellenlänge von 855 nm ausgeleuchtet. Die Helligkeit ist stufenlos dimmbar, der Winkel vom Leuchtkegel ist veränderbar."},
    longDes_2: {    nl: "Krachtig – maar veilig voor de ogen! De Helios IR-Illuminator is een van de krachtigste IR LED-stralers op de markt. Het effectieve verlichtingsbereik is afhankelijk van verschillende beïnvloedende factoren en kan tot 500 m bedragen. Hij is oogveilig! Sommige aangeboden IR-stralers op de markt zijn niet oogveilig en kunnen de ogen van mensen en dieren beschadigen met directe of indirecte verlichting. Op korte afstanden moet een blik in de IR-Illuminator worden vermeden!",
                    en: "Powerful – but safe for the eyes! The Lahoux Helios IR Illuminator is one of the most powerful IR LED Illuminators on the market. The effective illumination range depends on various influencing factors and can be up to 500 m. It is eye-safe! Some offered IR Illuminators on the market are not eye-safe and can damage the eyes of humans and animals by direct or indirect illumination. At short distances, a glimpse into an IR Illuminator should be avoided!",
                    de: "Leistungsstark – aber augensicher! Der Helios IR-Strahler ist einer der leistungsstärksten IR-LED-Strahler am Markt. Die effektive Ausleuchtungsreichweite ist von verschiedenen Einflussfaktoren abhängig und kann bis zu 500 m betragen. Er ist augensicher! Einige angebotene IR-Strahler am Markt sind nicht augensicher und können bei direkter oder indirekter Anstrahlung die Augen von Mensch und Tier beschädigen. Dennoch sollte grundsätzlich auf kurze Distanz nicht direkt in einen IR-Strahler gesehen werden!"
    },    
    number: "01-0030-01714",
    flyer: {nl:"Lahoux_Helios_NL_V5.pdf", en: "Lahoux_Helios_EN_V5.0.pdf", de: "Lahoux_Helios_DE_V6.pdf"},
    images: ["as1_1.png", "as1_4.png", "as1_5.png"],
    compatible: ["as2", "nz3", "dg1", "nz1", "nz2"],
    filter: {filterThermal: false, filterNightvision: true, filterDigital: true}
},
{
    id: "as28",
    orderID: "401",
    type: "accessories",
    title: {nl: "Helios Pro", en: "Helios Pro", de: "Helios Pro"},
    url: "Lahoux-Helios-Pro",
    price: "562.50",
    spotlight: { nl:"De Lahoux Helios Pro IR-straler is een LED-verlichtingstool en kan worden gebruikt ter ondersteuning van een nachtkijker met weinig restlicht. Het observatiegebied wordt verlicht met de illuminator in een licht dat onzichtbaar is voor het menselijk oog bij een golflengte van 855 nm. De helderheid is traploos dimbaar, de hoek van de lichtkegel is veranderlijk.    ", 
                    en: "The Lahoux Helios Pro IR illuminator is a LED lighting tool and can be used to support a night vision attachment with very little residual light. The observation area is illuminated with the illuminator in a light that is invisible to the human eye at a wavelength of 855 nm. The brightness is infinitely dimmable, the angle of the light cone is changeable.", 
                    de: "Der Lahoux Helios Pro IR-Strahler ist ein LED Beleuchtungshilfsmittel und kann unterstützend für das Nachtsichtvorsatzgerät bei sehr wenig Restlicht verwendet werden. Der Beobachtungssektor wird dabei mit dem Strahler in einem für das menschliche Auge unsichtbarem Licht in einer Wellenlänge von 855 nm ausgeleuchtet. Die Helligkeit ist stufenlos dimmbar, der Winkel vom Leuchtkegel ist veränderbar."},

    shortDes: { nl:"De nieuwe Lahoux Helios Pro IR-straler is een LED-verlichtingstool en kan worden gebruikt ter ondersteuning van een nachtkijker met weinig restlicht. Het observatiegebied wordt verlicht met de illuminator in een licht dat onzichtbaar is voor het menselijk oog bij een golflengte van 855 nm. De helderheid is traploos dimbaar, de hoek van de lichtkegel is veranderlijk.    ", 
                    en: "The Lahoux Helios Pro IR illuminator is a LED lighting tool and can be used to support a night vision attachment with very little residual light. The observation area is illuminated with the illuminator in a light that is invisible to the human eye at a wavelength of 855 nm. The brightness is infinitely dimmable, the angle of the light cone is changeable.", 
                    de: "Der Lahoux Helios Pro IR-Strahler ist ein LED Beleuchtungshilfsmittel und kann unterstützend für das Nachtsichtvorsatzgerät bei sehr wenig Restlicht verwendet werden. Der Beobachtungssektor wird dabei mit dem Strahler in einem für das menschliche Auge unsichtbarem Licht in einer Wellenlänge von 855 nm ausgeleuchtet. Die Helligkeit ist stufenlos dimmbar, der Winkel vom Leuchtkegel ist veränderbar."},
    longDes_1: {    nl:"De Lahoux Helios Pro IR-illuminator is een LED verlichter en kan worden gebruikt om de nachtzicht kijker te ondersteunen in omstandigheden van zeer weinig restlicht. Het te observeren gebied wordt verlicht met de illuminator in een licht dat onzichtbaar is voor het menselijk oog, bij een golflengte van 855 nm. De helderheid is oneindig dimbaar, de hoek van de lichtkegel is te veranderen.", 
                    en: "The Lahoux Helios Pro IR illuminator is a LED lighting tool and can be used to support a night vision attachment with very little residual light. The observation area is illuminated with the illuminator in a light that is invisible to the human eye at a wavelength of 855 nm. The brightness is infinitely dimmable, the angle of the light cone is changeable.", 
                    de: "Der Lahoux Helios Pro IR-Strahler ist ein LED-Beleuchtungshilfsmittel und kann unterstützend für das Nachtsichtvorsatzgerät bei sehr wenig Restlicht verwendet werden. Der Beobachtungssektor wird dabei mit dem Strahler in einem für das menschliche Auge unsichtbarem Licht in einer Wellenlänge von 855 nm ausgeleuchtet. Die Helligkeit ist stufenlos dimmbar, der Winkel vom Leuchtkegel ist veränderbar."},
    longDes_2: {    nl: "Krachtig – maar veilig voor de ogen! De Helios IR-Illuminator is een van de krachtigste IR LED-stralers op de markt. Het effectieve verlichtingsbereik is afhankelijk van verschillende beïnvloedende factoren en kan tot 500 m bedragen. Hij is oogveilig! Sommige aangeboden IR-stralers op de markt zijn niet oogveilig en kunnen de ogen van mensen en dieren beschadigen met directe of indirecte verlichting. Op korte afstanden moet een blik in de IR-Illuminator worden vermeden!",
                    en: "Powerful – but safe for the eyes! The Lahoux Helios IR Illuminator is one of the most powerful IR LED Illuminators on the market. The effective illumination range depends on various influencing factors and can be up to 500 m. It is eye-safe! Some offered IR Illuminators on the market are not eye-safe and can damage the eyes of humans and animals by direct or indirect illumination. At short distances, a glimpse into an IR Illuminator should be avoided!",
                    de: "Leistungsstark – aber augensicher! Der Helios IR-Strahler ist einer der leistungsstärksten IR-LED-Strahler am Markt. Die effektive Ausleuchtungsreichweite ist von verschiedenen Einflussfaktoren abhängig und kann bis zu 500 m betragen. Er ist augensicher! Einige angebotene IR-Strahler am Markt sind nicht augensicher und können bei direkter oder indirekter Anstrahlung die Augen von Mensch und Tier beschädigen. Dennoch sollte grundsätzlich auf kurze Distanz nicht direkt in einen IR-Strahler gesehen werden!"
    },    
    number: "01-0030-02113",
    flyer: {nl:"Lahoux_Helios_NL_V5.pdf", en: "Lahoux_Helios_EN_V5.0.pdf", de: "Lahoux_Helios_DE_V6.pdf"},
    images: ["as1_1.png", "as1_4.png", "as1_5.png"],
    compatible: ["as2", "nz3", "dg1", "nz1", "nz2"],
    filter: {filterThermal: false, filterNightvision: true, filterDigital: true}
},	
{
    id: "as29",
    orderID: "401",
    type: "accessories",
    title: {nl: "Helios 945", en: "Helios 945", de: "Helios 945"},
    url: "Lahoux-Helios-945",
    price: "587.40",
    spotlight: { nl:"De Lahoux Helios 945 IR-straler is een LED-verlichtingstool en kan worden gebruikt ter ondersteuning van een nachtkijker met weinig restlicht. Het observatiegebied wordt verlicht met de illuminator in een licht dat onzichtbaar is voor het menselijk oog bij een golflengte van 945 nm. De helderheid is traploos dimbaar, de hoek van de lichtkegel is veranderlijk.    ", 
                    en: "The Lahoux Helios 945 IR illuminator is a LED lighting tool and can be used to support a night vision attachment with very little residual light. The observation area is illuminated with the illuminator in a light that is invisible to the human eye at a wavelength of 945 nm. The brightness is infinitely dimmable, the angle of the light cone is changeable.", 
                    de: "Der Lahoux Helios 945 IR-Strahler ist ein LED Beleuchtungshilfsmittel und kann unterstützend für das Nachtsichtvorsatzgerät bei sehr wenig Restlicht verwendet werden. Der Beobachtungssektor wird dabei mit dem Strahler in einem für das menschliche Auge unsichtbarem Licht in einer Wellenlänge von 945 nm ausgeleuchtet. Die Helligkeit ist stufenlos dimmbar, der Winkel vom Leuchtkegel ist veränderbar."},

    shortDes: { nl:"De nieuwe Lahoux Helios 945 IR-straler is een LED-verlichtingstool en kan worden gebruikt ter ondersteuning van een nachtkijker met weinig restlicht. Het observatiegebied wordt verlicht met de illuminator in een licht dat onzichtbaar is voor het menselijk oog bij een golflengte van 945 nm. De helderheid is traploos dimbaar, de hoek van de lichtkegel is veranderlijk.    ", 
                    en: "The Lahoux Helios 945 IR illuminator is a LED lighting tool and can be used to support a night vision attachment with very little residual light. The observation area is illuminated with the illuminator in a light that is invisible to the human eye at a wavelength of 945 nm. The brightness is infinitely dimmable, the angle of the light cone is changeable.", 
                    de: "Der Lahoux Helios 945 IR-Strahler ist ein LED Beleuchtungshilfsmittel und kann unterstützend für das Nachtsichtvorsatzgerät bei sehr wenig Restlicht verwendet werden. Der Beobachtungssektor wird dabei mit dem Strahler in einem für das menschliche Auge unsichtbarem Licht in einer Wellenlänge von 945 nm ausgeleuchtet. Die Helligkeit ist stufenlos dimmbar, der Winkel vom Leuchtkegel ist veränderbar."},
    longDes_1: {    nl:"De Lahoux Helios 945 IR-illuminator is een LED verlichter en kan worden gebruikt om de nachtzicht kijker te ondersteunen in omstandigheden van zeer weinig restlicht. Het te observeren gebied wordt verlicht met de illuminator in een licht dat onzichtbaar is voor het menselijk oog, bij een golflengte van 945 nm. De helderheid is oneindig dimbaar, de hoek van de lichtkegel is te veranderen.", 
                    en: "The Lahoux Helios 945 IR illuminator is a LED lighting tool and can be used to support a night vision attachment with very little residual light. The observation area is illuminated with the illuminator in a light that is invisible to the human eye at a wavelength of 945 nm. The brightness is infinitely dimmable, the angle of the light cone is changeable.", 
                    de: "Der Lahoux Helios 945 IR-Strahler ist ein LED-Beleuchtungshilfsmittel und kann unterstützend für das Nachtsichtvorsatzgerät bei sehr wenig Restlicht verwendet werden. Der Beobachtungssektor wird dabei mit dem Strahler in einem für das menschliche Auge unsichtbarem Licht in einer Wellenlänge von 945 nm ausgeleuchtet. Die Helligkeit ist stufenlos dimmbar, der Winkel vom Leuchtkegel ist veränderbar."},
    longDes_2: {    nl: "Krachtig – maar veilig voor de ogen! De Helios 945 IR-Illuminator is een van de krachtigste IR LED-stralers op de markt. Het effectieve verlichtingsbereik is afhankelijk van verschillende beïnvloedende factoren en kan tot 500 m bedragen. Hij is oogveilig! Sommige aangeboden IR-stralers op de markt zijn niet oogveilig en kunnen de ogen van mensen en dieren beschadigen met directe of indirecte verlichting. Op korte afstanden moet een blik in de IR-Illuminator worden vermeden!",
                    en: "Powerful – but safe for the eyes! The Lahoux Helios 945 IR Illuminator is one of the most powerful IR LED Illuminators on the market. The effective illumination range depends on various influencing factors and can be up to 500 m. It is eye-safe! Some offered IR Illuminators on the market are not eye-safe and can damage the eyes of humans and animals by direct or indirect illumination. At short distances, a glimpse into an IR Illuminator should be avoided!",
                    de: "Leistungsstark – aber augensicher! Der Helios 945 IR-Strahler ist einer der leistungsstärksten IR-LED-Strahler am Markt. Die effektive Ausleuchtungsreichweite ist von verschiedenen Einflussfaktoren abhängig und kann bis zu 500 m betragen. Er ist augensicher! Einige angebotene IR-Strahler am Markt sind nicht augensicher und können bei direkter oder indirekter Anstrahlung die Augen von Mensch und Tier beschädigen. Dennoch sollte grundsätzlich auf kurze Distanz nicht direkt in einen IR-Strahler gesehen werden!"
    },    
    number: "01-0030-02113",
    flyer: {nl:"Lahoux_Helios_NL_V5.pdf", en: "Lahoux_Helios_EN_V5.0.pdf", de: "Lahoux_Helios_DE_V6.pdf"},
    images: ["as1_1.png", "as1_4.png", "as1_5.png"],
    compatible: ["as2", "nz3", "dg1", "nz1", "nz2"],
    filter: {filterThermal: false, filterNightvision: true, filterDigital: true}
},	
{
    id: "as2",
    orderID: "402",
    type: "accessories",
    title: {nl: "Montagebeugel Helios", en: "Mounting bracket Helios", de: "Montagehalterung Helios"},
    url: "Beugel-Helios",
    price: "59.93",
    shortDes: { nl:"De juiste montage voor de infraroodstralers Lahoux Helios, Lahoux Helios Pro en Lahoux Helios 945.", 
                    en: "The right mounts for the infrared illuminators Lahoux Helios, Lahoux Helios Pro and Lahoux Helios 945.", 
                    de: "Die passende Montage für die Infrarotstrahler Lahoux Helios, Lahoux Helios Pro und Lahoux Helios 945."},
    longDes_1: {    nl:"Montagebeugel voor de Lahoux Helios, Lahoux Helios Pro en Lahoux Helios 945 infraroodstralers. Deze bevestiging is speciaal ontwikkeld voor de Helios infraroodstraler en wordt op een Picatinny-rail gemonteerd.", 
                    en: "Mounting bracket for the Lahoux Helios, Lahoux Helios Pro and Lahoux Helios 945 IR illuminators. This mounting bracket has been specially developed for the Helios IR illuminators and has to be mounted on a Picatinny rail.", 
                        de: "Montagehalterung für den Lahoux Helios, Lahoux Helios Pro und Lahoux Helios 945 IR-Strahler. Diese Montage ist speziell für die Helios IR-Strahler entwickelt worden und ist auf einer Picatinniy-Schiene montierbar."},
    number: "01-0030-01940",
    images: ["as2_1.png"],
    compatible: ["as1", "dg1", "nz1", "nz2"],
    filter: {filterThermal: false, filterNightvision: true, filterDigital: true}
},	
{
    id: "as3",
    orderID: "403",
    type: "accessories",
    title: {nl: "Vaste Lens (4x)", en: "Fixed Lens (4x)", de: "Feste Linse (4x)"},
    url: "Fixed-Lens",
    price: "412.86",
    shortDes: { nl:"Een vaste vergrotingslens geschikt voor de Lahoux LVS-14 en LVS-7.", 
                    en: "A snap-on magnification lens or fixed magnification lens suitable for the Lahoux LVS-14 and LVS-7.", 
                    de: "Eine Linse mit fester Vergrößerung, geeignet für Lahoux LVS-14 und LVS-7."},
    longDes_1: {    nl:"Een vaste vergrotingslens geschikt voor de Lahoux LVS-14 en LVS-7. Vergroting: 4x.", 
                    en: "A fixed magnification lens suitable for the Lahoux LVS-14 and LVS-7. Magnification: 4x.", 
                        de: "Eine Linse mit fester Vergrößerung, geeignet für Lahoux LVS-14 und LVS-7. Vergrößerung: 4x."},

    number: "01-0001-02416",
    images: ["as3_1.png", "as3_2.png", "as3_3.png"],
    compatible: ["nz4", "nz3"],
    filter: {filterThermal: false, filterNightvision: true, filterDigital: false}
},
{
    id: "as26",
    orderID: "403",
    type: "accessories",
    title: {nl: "Vaste Lens (6x)", en: "Fixed Lens (6x)", de: "Feste Linse (6x)"},
    url: "Fixed-Lens-6",
    price: "546.86",
    shortDes: { nl:"Een vaste vergrotingslens geschikt voor de Lahoux LVS-14 en LVS-7.", 
                    en: "A snap-on magnification lens or fixed magnification lens suitable for the Lahoux LVS-14 and LVS-7.", 
                    de: "Eine Linse mit fester Vergrößerung, geeignet für Lahoux LVS-14 und LVS-7."},
    longDes_1: {    nl:"Een vaste vergrotingslens geschikt voor de Lahoux LVS-14 en LVS-7. Vergroting: 6x.", 
                    en: "A fixed magnification lens suitable for the Lahoux LVS-14 and LVS-7. Magnification: 6x.", 
                        de: "Eine Linse mit fester Vergrößerung, geeignet für Lahoux LVS-14 und LVS-7. Vergrößerung: 6x."},

    number: "01-0001-02418",
    images: ["as3_1.png", "as3_2.png", "as3_3.png"],
    compatible: ["nz4", "nz3"],
    filter: {filterThermal: false, filterNightvision: true, filterDigital: false}
},
{
    id: "as27",
    orderID: "403",
    type: "accessories",
    title: {nl: "Vaste Lens (10x)", en: "Fixed Lens (10x)", de: "Feste Linse (10x)"},
    url: "Fixed-Lens-10",
    price: "1706.86",
    shortDes: { nl:"Een vaste vergrotingslens geschikt voor de Lahoux LVS-14 en LVS-7.", 
                    en: "A snap-on magnification lens or fixed magnification lens suitable for the Lahoux LVS-14 and LVS-7.", 
                    de: "Eine Linse mit fester Vergrößerung, geeignet für Lahoux LVS-14 und LVS-7."},
    longDes_1: {    nl:"Een vaste vergrotingslens geschikt voor de Lahoux LVS-14 en LVS-7. Vergroting: 10x.", 
                    en: "A fixed magnification lens suitable for the Lahoux LVS-14 and LVS-7. Magnification: 10x.", 
                        de: "Eine Linse mit fester Vergrößerung, geeignet für Lahoux LVS-14 und LVS-7. Vergrößerung: 10x."},

    number: "01-0001-02419",
    images: ["as3_1.png", "as3_2.png", "as3_3.png"],
    compatible: ["nz4", "nz3"],
    filter: {filterThermal: false, filterNightvision: true, filterDigital: false}
},
{
    id: "as6",
    orderID: "404",
    type: "accessories",
    title: {nl: "Monoculair 4x 12mm Set", en: "Monocular 4x 12mm Set", de: "Monokular 4x 12mm Set"},
    url: "Monoculair-4x12",
    price: "193.12",
    shortDes: { nl:"Monoculair in 4x 12mm, incl. Adapter en inschuifring.", 
                    en: "Monocular in 4x 12mm, incl. Adapter and reducing ring.", 
                    de: "Monokular in 4x 12mm, inkl. Adapter und Einschiebering."},
    longDes_1: {    nl:"Monoculair in 4x 12mm. Incl. LV-8-adapter, LV-8/E-adapter en een inschuifring in de maat van de bestelde AD-545-adapter.", 
                    en: "Monocular in 4x 12mm. Incl. LV-8 adapter, LV-8/E adapter and a reducing ring in the size of the ordered AD-545-adapter.", 
                    de: "Monokular in 4x 12mm. Inkl. LV-8-Adapter, LV-8/E-Adapter und einen Einschiebering in der Größe des bestellten AD-545-Adapters."},
    
    number: "01-0002-02200",
    leveromvang: [32, 33, 18],
    images: ["as-monocular-4x12.png", "as-monocular-4x12-LV-8-adapter.png", "as-monocular-4x12-LV-8-E-adapter-inschuifring.png", "as-LV-8-adapter-LV-8-E-adapter-inschuifring.png"],
    compatible: ["nz1", "nz2", "wm1", "dg1"],
    filter: {filterThermal: true, filterNightvision: true, filterDigital: true}
},	
{
    id: "as20",
    orderID: "405",
    type: "accessories",
    title: {nl: "Monoculair 6x 16mm Set", en: "Monocular 6x 16mm Set", de: "Monokular 6x 16mm Set"},
    url: "Monoculair-6x16",
    price: "205.51",
    shortDes: { nl:"Monoculair in 6x 16mm, incl. Adapter en inschuifring.", 
                    en: "Monocular in 6x 16mm, incl. Adapter and reducing ring.", 
                    de: "Monokular in 6x 16mm, inkl. Adapter und Einschiebering."},
    longDes_1: {    nl:"Monoculair in 6x 16mm. Incl. LV-8-adapter, LV-8/E-adapter en een inschuifring in de maat van de bestelde AD-545-adapter.", 
                    en: "Monocular in 6x 16mm. Incl. LV-8 adapter, LV-8/E adapter and a reducing ring in the size of the ordered AD-545-adapter.", 
                    de: "Monokular in 6x 16mm. Inkl. LV-8-Adapter, LV-8/E-Adapter und einen Einschiebering in der Größe des bestellten AD-545-Adapters."},
    
    number: "01-0002-02201",
    leveromvang: [32, 33, 18],
    images: ["as-monocular-6x16.png", "as-monocular-6x16-LV-8-adapter.png", "as-monocular-6x16-LV-8-E-adapter-inschuifring.png", "as-LV-8-adapter-LV-8-E-adapter-inschuifring.png"],
    compatible: ["nz1", "nz2", "wm1", "dg1"],
    filter: {filterThermal: true, filterNightvision: true, filterDigital: true}
},	
{
    id: "as7",
    orderID: "406",
    type: "accessories",
    title: {nl:"QR wapenhouder", en: "QR Weapon Mount", de: "QR Waffenhalterung"},
    url: "QR-Weapon-Mount",
    price: "384.37",
    shortDes: { nl:"Een Quick Release wapenhouder voor de Lahoux LVS-14.", 
                    en: "A Quick Release Weapon Mount for the Lahoux LVS-14.", 
                    de: "Eine Waffenhalterung mit Schnellverschluss für den Lahoux LVS-14."},
    longDes_1: {    nl:"Een Quick Release wapenhouder voor de Lahoux LVS-14.", 
                    en: "A Quick Release Weapon Mount for the Lahoux LVS-14.", 
                        de: "Eine Waffenhalterung mit Schnellverschluss für den Lahoux LVS-14."},

    number: "01-0006-xxxxx",
    images: ["as7_1.png"],
    compatible: ["nz4"],
    filter: {filterThermal: false, filterNightvision: true, filterDigital: false}
},		
{
    id: "as8",
    orderID: "407",
    type: "accessories",
    title: {nl: "Snap-On Lens", en: "Snap-On Lens", de: "Aufsatzlinse"},
    url: "Snap-On-Lens",
    price: "440.00",
    shortDes: { nl:"Een snap-on vergrotingslens geschikt voor de Lahoux LVS-14 en LVS-7.", 
                    en: "A snap-on magnification lens suitable for the Lahoux LVS-14 and LVS-7.", 
                    de: "Ein Aufsatzlinse mit fester Vergrößerung, geeignet für Lahoux LVS-14 und LVS-7."},
    longDes_1: {    nl:"Een snap-on vergrotingslens geschikt voor de Lahoux LVS-14 en LVS-7.", 
                    en: "A snap-on magnification lens suitable for the Lahoux LVS-14 and LVS-7.", 
                    de: "Ein Aufsatzlinse mit fester Vergrößerung, geeignet für Lahoux LVS-14 und LVS-7."},
    
    number: "01-0009-00594",
    images: ["as8_1.png"],
    compatible: ["nz3", "nz4"],
    filter: {filterThermal: false, filterNightvision: true, filterDigital: false}
},
{
    id: "as10",
    orderID: "408",
    type: "accessories",
    title: {nl: "Adapter AD-545-x", en: "Adapter AD-545-x", de: "Adapter AD-545-x"},
    url: "Adapter-AD-545-x",
    price: "142.02",
    shortDes: { nl:"Universele adapter AD 545 x voor voorzetten van het juiste formaat.", 
                    en: "Universal adapter AD 545 x for attachments of the appropriate size.", 
                    de: "Universaladapter AD-545-x für Vorsatzgeräte in der passenden Größe."},
    longDes_1: {    nl:"Universele adapter voor voorzetten, verkrijgbaar in bijna alle maten.", 
                    en: "Universal adapter for attachments, available in almost all sizes.", 
                        de: "Universaladapter für Vorsatzgeräte, in fast allen Größen erhältlich."},
    
    number: "01-0009-xxxxx",
    images: ["as10_1.png"],
    compatible: ["nz1", "wm1", "wm16", "wm18", "wm20", "nz2", "dg1", "nz5"],
    filter: {filterThermal: true, filterNightvision: true, filterDigital: true}
},
{
    id: "as11",
    orderID: "409",
    type: "accessories",
    title: {nl: "Batterijvak Extender BFE 1", en: "Battery compartment Extender BFE 1", de: "Batteriefach Extender BFE 1"},
    url: "BFE1",
    price: "41.18",
    shortDes: { nl:"Batterijvak Extender voor uw Lahoux Clip 42 of Lahoux Clip Elite 50.", 
                    en: "Battery compartment Extender for your Lahoux Clip 42 or Lahoux Clip Elite 50.", 
                    de: "Batteriefach Extender für Ihren Lahoux Clip 42 oder Lahoux Clip Elite 50."},
    longDes_1: {    nl:"De BFE 1 is een optionele batterijvakverlenger voor de Lahoux Clip 42 en Lahoux Clip Elite 50. De BFE 1 kan zonder gereedschap direct op het batterijvak geklikt worden en maakt het mogelijk om van 16650-accu’s gebruik te maken waardoor de looptijd van deze Lahoux Clips aanzienlijk verlengd wordt. Afhankelijk van de temperatuur wordt de looptijd van de Lahoux Clips ongeveer 6 uur. De BFE 1 kan naar wens worden geleverd met of zonder oplader. Ook de 16650-accu’s zijn apart te bestellen. ", 
                    en: "The BFE 1 is an optional battery compartment extender for the Lahoux Clip 42 and Lahoux Clip Elite 50. The BFE 1 can be clicked directly onto the battery compartment without tools and allows the use of 16650 batteries, which significantly increases the running time of these Lahoux Clips. Depending on the temperature, the Lahoux Clips can run for up to approximately 6 hours longer. The BFE 1 can be supplied with or without battery charger. The 16650 batteries can also be ordered separately.", 
                        de: "Der BFE 1 ist eine optionale Batteriefach Extender für das Lahoux Clip 42 und das Lahoux Clip Elite 50. Der BFE 1 kann ohne Werkzeug direkt auf das Batteriefach geklickt werden und ermöglicht so die Verwendung von 16650er-Batterien, welche die Laufzeit der beiden Clips deutlich erhöhen. Je nach Temperatur kann die Batterielaufzeit der beiden Lahoux Clips bis zu 6 Stunden betragen. Der BFE 1 kann mit oder ohne Ladegerät geliefert werden. Die 16650-Akkus können auch separat bestellt werden."},
    number: "09-0009-02815",
    images: ["as11_1.png", "as11_2.png", "as11_3.png"],
    compatible: ["wm18", "wm20"],
    filter: {filterThermal: true, filterNightvision: false, filterDigital: false}
},
{
    id: "as12",
    orderID: "410",
    type: "accessories",
    title: {nl: "Batterijvak Extender BFE 2", en: "Battery compartment Extender BFE 2", de: "Batteriefach Extender BFE 2"},
    url: "BFE2",
    price: "108.40",
    shortDes: { nl:"Batterijvak Extender voor uw Lahoux Clip 25, Lahoux Clip 35 of Lahoux Clip 50.", 
                    en: "Battery compartment extender for your Lahoux Clip 25, Lahoux Clip 35 or Lahoux Clip 50.", 
                    de: "Batteriefach Extender für Ihren Lahoux Clip 25, Lahoux Clip 35 oder Lahoux Clip 50."},
    longDes_1: {    nl:"De BFE 2 is een optionele batterijvakverlenger voor de Lahoux Clip 25, Lahoux Clip 35 en Lahoux Clip 50. De BFE 2 kan door de klant zelf met slechts een (meegeleverde) zeskantsleutel direct op het batterijvak geschroefd worden zodat de BFE 2, gefixeerd aan de behuizing, het mogelijk maakt om van 16650 accu’s gebruik te maken waardoor de looptijd van beide Clips aanzienlijk verlengd wordt. Afhankelijk van de temperatuur wordt de looptijd van de Lahoux Clips ongeveer 6 uur. De BFE 2 kan naar wens worden geleverd met of zonder oplader. Ook de 16650-accu’s zijn apart te bestellen.", 
                    en: "The BFE 2 is an optional battery compartment extender for the Lahoux Clip 25, Lahoux Clip 35 and Lahoux Clip 50. The BFE 2 can be screwed directly to the battery compartment by the customer with just an (included) hexagonal key so that the BFE 2, fixed to the housing, makes it possible to use 16650 batteries which significantly increases the running time of these Lahoux Clips. Depending on the temperature, the Lahoux Clips can run for up to approximately 6 hours longer. The BFE 2 can be supplied with or without battery charger. The 16650 batteries can also be ordered separately.", 
                        de: "Der BFE 2 ist eine optionale Batteriefach Extender für das Lahoux Clip 25, Lahoux Clip 35 und das Lahoux Clip 50. Der BFE 2 kann vom Kunden mit einem (mitgelieferten) Sechskantschlüssel direkt an das Batteriefach geschraubt werden, so dass der BFE 2 fest mit dem Gehäuse verbunden ist. Dies ermöglich die Verwendung von 16650er Batterien, welche die Laufzeit der beiden Lahoux Clips deutlich erhöhen. Je nach Temperatur kann die Batterielaufzeit der beiden Lahoux Clips bis zu 6 Stunden betragen. Der BFE 2 kann mit oder ohne Ladegerät geliefert werden. Die 16650-Akkus können auch separat bestellt werden."},
    number: "09-0009-02817",
    images: ["as12_1.png", "as12_2.png", "as12_3.png"],
    compatible: ["wm30", "wm16", "wm31"],
    filter: {filterThermal: true, filterNightvision: false, filterDigital: false}
},
{
    id: "as26",
    orderID: "411",
    type: "accessories",
    title: {nl: "18650 batterij 3500mAh button top", en: "18650 battery 3500mAh button top", de: "18650 Akku 3500mAh Button Top"},
    url: "18650-battery-3500mAh-button-top",
    price: "22.24",
    shortDes: {     nl: "Extra 18650 batterij 3500mAh flat top", 
                    en: "Additional 18650 battery 3500mAh flat top", 
                    de: "Zusätzlicher 18650 Akku 3500mAh Flat Top"},
    longDes_1: {    nl: "18650 batterij 3500mAh flat top voor onder andere de Spotter NL serie", 
                    en: "18650 battery 3500mAh flat top for Spotter NL series, among others", 
                    de: "18650 Akku 3500mAh Flat Top für z.B. Spotter NL Serie"},
    number: "09-0400-02370",
    images: ["as-18650-battery-3500mAh-flat-top.png"],
    compatible: ["wm22", "wm23", "wm24", "wm25"],
    filter: {filterThermal: true, filterNightvision: false, filterDigital: false}
},
{
    id: "as13",
    orderID: "412",
    type: "accessories",
    title: {nl: "Draagriem", en: "Carrying strap", de: "Trageriemen"},
    url: "Draagriem",
    price: "20.97",
    shortDes: { nl:"Luxe draagriem voor bij de Lahoux Spotter serie.", 
                    en: "Luxury carrying strap for the Lahoux Spotter series.", 
                    de: "Luxus Trageriemen für Lahoux Spotter Serie."},
    longDes_1: {    nl:"Luxe draagriem voor bij de Lahoux Spotter serie.", 
                    en: "Luxury carrying strap for the Lahoux Spotter series.", 
                    de: "Luxus Trageriemen für Lahoux Spotter Serie."},
    
    number: "02-0009-02374",
    images: ["as13_1.png", "as13_2.png"],
    compatible: ["wm6", "wm29", "wm8", "wm9", "wm10", "wm11", "wm12", "wm13" ],
    filter: {filterThermal: true, filterNightvision: false, filterDigital: false}
},
{
    id: "as14",
    orderID: "413",
    type: "accessories",
    title: {nl: "Skull Crusher Hoofddrager", en: "Skull Crusher Head Mount", de: "Skull Crusher Head Mount"},
    url: "Skull-Crusher-Head-Mount",
    price: "313.22",
    shortDes: { nl: "Skull Crusher Hoofddrager voor montage van nachtkijkers of thermische kijkers", 
                en: "Skull Crusher Head Mount for mounting nightvision or thermal devices", 
                de: "Skull Crusher Head Mount zur Montage von Nachtsicht- oder Wärmebildgeräten"},
    longDes_1: { nl: "Skull Crusher Hoofddrager voor montage van nachtkijkers of thermische kijkers", 
                en: "Skull Crusher Head Mount for mounting nightvision or thermal devices", 
                de: "Skull Crusher Head Mount zur Montage von Nachtsicht- oder Wärmebildgeräten"},
    number: "09-0009-00981",
    images: ["as14_1.png"],
    compatible: ["nz3", "nz4"],
    filter: {filterThermal: true, filterNightvision: true, filterDigital: false}
},
{
    id: "as15",
    orderID: "414",
    type: "accessories",
    title: {nl: "Rubber oogcup", en: "Rubber eyecup", de: "Augenmuschel aus Gummi"},
    url: "Rubber-eyecup-Lahoux-Sight-35",
    price: "15.00",
    shortDes: { nl: "Rubber oogcup voor de Lahoux Sight 35", 
                en: "Rubber eyecup for the Lahoux Sight 35", 
                de: "Gummiaugenmuschel für das Lahoux Sight 35"},
    longDes_1: {    nl: "Rubber oogcup voor de Lahoux Sight 35", 
                    en: "Rubber eyecup for the Lahoux Sight 35", 
                    de: "Gummiaugenmuschel für das Lahoux Sight 35"},
    number: "09-0009-02790",
    ean: "6090301522558",
    images: ["as-rubber-oogcup-lahoux-sight-35.png"],
    compatible: ["wm21"],
    filter: {filterThermal: true, filterNightvision: false, filterDigital: false}
},
{
    id: "as16",
    orderID: "415",
    type: "accessories",
    title: {nl: "Opbergtas DigiClip Series", en: "Storage bag DigiClip Series", de: "Aufbewahrungstasche DigiClip Series"},
    url: "Storage-bag-DigiClip-Series",
    price: "30.00",
    shortDes: { nl: "Opbergtas voor de Lahoux DigiClip Serie", 
                en: "Storage bag for the Lahoux DigiClip Series", 
                de: "Aufbewahrungstasche für die Lahoux DigiClip Serie"},
    longDes_1: {    nl: "Opbergtas voor de Lahoux DigiClip Serie", 
                    en: "Storage bag for the Lahoux DigiClip Series", 
                    de: "Aufbewahrungstasche für die Lahoux DigiClip Serie"},
    number: "09-0200-02997",
    ean: "6090301146136",
    images: ["as-tas-voor-lahoux-digiclip-serie.png"],
    compatible: ["dg1"],
    filter: {filterThermal: false, filterNightvision: false, filterDigital: true}
},

{
    id: "as17",
    orderID: "416",
    type: "accessories",
    title: {nl: "Statief", en: "Tripod", de: "Stativ"},
    url: "tripod",
    price: "85.00",
    shortDes: { nl: "Statief voor de ondersteuning van de monitor bij de Lahoux Buzzard", 
                en: "Tripod to support the monitor with the Lahoux Buzzard", 
                de: "Stativ zur Unterstützung des Monitors mit dem Lahoux Buzzard"},
    longDes_1: {    nl: "Statief voor de ondersteuning van de monitor bij de Lahoux Buzzard", 
                    en: "Tripod to support the monitor with the Lahoux Buzzard", 
                    de: "Stativ zur Unterstützung des Monitors mit dem Lahoux Buzzard"},
    longDes_2: {    nl: "Het statief is inclusief bij de complete Buzzard set, maar kan ook separaat besteld worden.", 
                    en: "The tripod is included in the complete Buzzard set, but it can also be ordered seperately.", 
                    de: "Das Stativ ist im kompletten Buzzard-Set enthalten, kann aber auch separat bestellt werden."},
    number: "09-0009-03181",
    ean: "6090301564565",
    images: ["as-statief-voor-lahoux-buzzard.png"],
    compatible: ["wm15", "as18"],
    filter: {filterThermal: true, filterNightvision: false, filterDigital: false}
},

{
    id: "as18",
    orderID: "417",
    type: "accessories",
    title: {nl: "Bril/monitor", en: "Goggle/monitor", de: "Brille/Monitor"},
    url: "goggle-monitor",
    price: "171.00",
    shortDes: { nl: "800D 5\" 40ch 5.8GHz FPV Bril/monitor voor de Lahoux Buzzard", 
                en: "800D 5\" 40ch 5.8GHz FPV Goggle/monitor for the Lahoux Buzzard", 
                de: "800D 5\" 40ch 5.8GHz FPV Brille/Monitor für den Lahoux Buzzard"},
    longDes_1: {    nl: "800D 5\" 40ch 5.8GHz FPV Bril/monitor voor de Lahoux Buzzard", 
                    en: "800D 5\" 40ch 5.8GHz FPV Goggle/monitor for the Lahoux Buzzard", 
                    de: "800D 5\" 40ch 5.8GHz FPV Brille/Monitor für den Lahoux Buzzard"},
    longDes_2: {    nl: "De monitor is inclusief bij de complete Buzzard set, maar kan ook separaat besteld worden. Wij adviseren de monitor alleen op het statief te gebruiken. Alleen ervaren gebruikers kunnen de bril gebruiken als er een assistent is die de drone in het oog kan houden.", 
                    en: "The monitor is included in the complete Buzzard set, but it can also be ordered seperately. We advise to use the monitor on the tripod only. Experienced users can use it as a goggle when there is an assistant who keeps an eye on the drone.", 
                    de: "Der Monitor ist im kompletten Buzzard-Set enthalten, kann aber auch separat bestellt werden. Wir empfehlen, den Monitor nur auf dem Stativ zu verwenden. Nur erfahrene Benutzer können die Brille verwenden, wenn es einen Assistenten gibt, der die Drohne im Auge behalten kann."},
    number: "09-0009-03171",
    ean: "6090301562547",
    images: ["as-lahoux-buzzard-monitor-1.png", "as-lahoux-buzzard-monitor-2.png", "as-lahoux-buzzard-monitor-3.png"],
    compatible: ["as17", "wm15"],
    filter: {filterThermal: true, filterNightvision: false, filterDigital: false}
},

{
    id: "as19",
    orderID: "418",
    type: "accessories",
    title: {nl: "Opbergtas Clip Serie", en: "Storage bag Clip Series", de: "Aufbewahrungstasche Clip-Serie"},
    url: "Storage-bag-Clip-Series",
    price: "30.00",
    shortDes: { nl: "Opbergtas voor de Lahoux Clip Serie", 
                en: "Storage bag for the Lahoux Clip Series", 
                de: "Aufbewahrungstasche für die Lahoux Clip-Serie"},
    longDes_1: {    nl: "Opbergtas voor de Lahoux Clip Serie", 
                    en: "Storage bag for the Lahoux Clip Series", 
                    de: "Aufbewahrungstasche für die Lahoux Clip-Serie"},
    number: "02-0200-03223",
    ean: "6090301677654",
    images: ["as-tas-voor-lahoux-clip-series.png"],
    compatible: ["wm16", "wm18"],
    filter: {filterThermal: true, filterNightvision: false, filterDigital: false}
},

{
    id: "as21",
    orderID: "419",
    type: "accessories",
    title: {nl: "Lahoux Poloshirt", en: "Lahoux Polo Shirt", de: "Lahoux Poloshirt"},
    url: "Lahoux-polo-shirt",
    price: "24.75",
    new: false,
    shortDes: { nl: "Mooi groen poloshirt met geborduurd Lahoux logo aan de voor- en achterkant.", 
                en: "Beautiful green polo shirt with embroidered Lahoux logo on the front and back.", 
                de: "Schönes grünes Poloshirt mit gesticktem Lahoux-Logo auf der Vorder- und Rückseite."},
    longDes_1: {    nl: "Mooi groen poloshirt met geborduurd Lahoux logo aan de voor- en achterkant. Verkrijgbaar in de maten S, M ,L, XL en XXL.", 
                    en: "Beautiful green polo shirt with embroidered Lahoux logo on the front and back. Available in sizes S, M,L, XL and XXL.", 
                    de: "Schönes grünes Poloshirt mit gesticktem Lahoux-Logo auf der Vorder- und Rückseite. Erhältlich in den Größen S, M, L, XL und XXL."},
    number: "09-0009-03514",
    images: ["as-polo-shirt.png", "as-polo-shirt-2.png"],
},

{
    id: "as22",
    orderID: "420",
    type: "accessories",
    title: {nl: "Lahoux Thermo beker", en: "Lahoux Thermo cup", de: "Lahoux Isolierte Becher"},
    url: "Lahoux-thermo-beker",
    price: "37.19",
    new: false,
    shortDes: { nl: "Handige thermo beker om mee te nemen op uw jachttrip, voor warme of koude dranken.", 
                en: "Handy thermo cup to take on your hunting trip, to carry hot or cold drinks.", 
                de: "Praktische Isolierte Becher für den Jagdausflug, um heiße oder kalte Getränke mitzunehmen."},
    longDes_1: {    nl: "Handige thermo beker om mee te nemen op uw jachttrip, voor warme of koude dranken.", 
                    en: "Handy thermo cup to take on your hunting trip, to carry hot or cold drinks.", 
                    de: "Praktische Isolierte Becher für den Jagdausflug, um heiße oder kalte Getränke mitzunehmen."},
    number: "09-0009-03515",
    images: ["as-thermo-beker.png", "as-thermo-beker-2.png"],
},

{
    id: "as23",
    orderID: "421",
    type: "accessories",
    title: {nl: "Lahoux pet", en: "Lahoux cap", de: "Lahoux Kappe"},
    url: "Lahoux-pet",
    price: "12.39",
    new: false,
    shortDes: { nl: "Handige Lahoux pet voor uw tochten in de natuur. Door middel van klittenband verstelbaar en hierdoor one size fits all.", 
                en: "Handy Lahoux cap for your treks in nature. Adjustable by Velcro and therefore one size fits all.", 
                de: "Praktische Lahoux Kappe für Ihre Ausflüge in die Natur. Einstellbar mittels Klettverschluss und daher eine Einheitsgröße für alle."},
    longDes_1: {    nl: "Handige Lahoux pet voor uw tochten in de natuur. Door middel van klittenband verstelbaar en hierdoor one size fits all.", 
                    en: "Handy Lahoux cap for your treks in nature. Adjustable by Velcro and therefore one size fits all.", 
                    de: "Praktische Lahoux Kappe für Ihre Ausflüge in die Natur. Einstellbar mittels Klettverschluss und daher eine Einheitsgröße für alle."},
    number: "09-0009-03516",
    images: ["as-cap.png", "as-cap-2.png"],
},

{
    id: "as25",
    orderID: "422",
    type: "accessories",
    title: {nl: "Lahoux luxe draagtas", en: "Lahoux luxury carryingbag", de: "Lahoux Luxus Tragetasche"},
    url: "Lahoux-luxe-draagtas",
    price: "24.75",
    new: false,
    shortDes: { nl: "In deze tas blijft uw Lahoux kijker beschermd wanneer u hem meeneemt. Door de draagriem is de tas handig om de nek te hangen of om cross body te dragen.", 
                en: "This bag keeps your Lahoux device protected when you take it with you. The carrying strap makes the bag convenient to hang around the neck or wear cross body.", 
                de: "In dieser Tasche ist Ihr Lahoux-Gerät geschützt, wenn Sie ihn mitnehmen. Mit dem Trageriemen lässt sich die Tasche bequem um den Hals hängen oder quer über den Körper tragen."},
    longDes_1: {    nl: "In deze tas blijft uw Lahoux kijker beschermd wanneer u hem meeneemt. Door de draagriem is de tas handig om de nek te hangen of om cross body te dragen. Afmetingen ongeveer BXH 37-40 cm X 32 cm.", 
                    en: "This bag keeps your Lahoux device protected when you take it with you. The carrying strap makes the bag convenient to hang around the neck or wear cross body. Dimensions approximately WXH 37-40 cm X 32 cm.", 
                    de: "In dieser Tasche ist Ihr Lahoux-Gerät geschützt, wenn Sie ihn mitnehmen. Mit dem Trageriemen lässt sich die Tasche bequem um den Hals hängen oder quer über den Körper tragen. Abmessungen ca. BXH 37-40 cm X 32 cm."},
    number: "09-0200-03438",
    images: ["as-green-carrying-bag.png", "as-green-carrying-bag-2.png"],
    compatible: ["wm18", "wm31", "wm20", "nz1", "nz2"],
    filter: {filterThermal: true, filterNightvision: true, filterDigital: false}
},

];
export default DataProducts;