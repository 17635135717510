const DataVacatures = [
/*	{
		id: 1,
    	title: "Assemblage Medewerker",
        url: "assemblage-medewerker",
		city: "Haarlem",
		hours: "Parttime/ Fulltime (32/40 uur)",
        number: "1720224",
        intro: "Je bent als assemblage medewerker verantwoordelijk voor het produceren van verschillende optieken en kijkers. Je voert onder leiding van de teamleider diverse productie werkzaamheden uit. Ben jij technisch aangelegd en op zoek naar een nieuwe uitdaging? Lees dan snel verder.",
        p1: "Lahoux Optics is een snel groeiend en ontwikkelend bedrijf, wat zich richt op het ontwikkelen en produceren van innovatieve en hoogwaardige nachtzicht- en thermische apparatuur voor de civiele markt. We putten hierbij uit ruim 30 jaar ervaring als gevestigde speler binnen deze markt.",
		p2: "Je bent als assemblage medewerker verantwoordelijk voor het produceren van verschillende optieken en kijkers. Je voert onder leiding van de teamleider diverse productie werkzaamheden uit. Bij het uitvoeren van de werkzaamheden wordt rekening gehouden met de productiekwaliteit en de norm- en levertijd. Na het einde van jouw dienst kijk je met trots terug naar wat het team die dag heeft geproduceerd.",
		p3: "Ben jij technisch aangelegd en op zoek naar een nieuwe uitdaging?",
        bullits1: [],
		p4: "",
		p5: "",
		eisen:["Woonachtig in Haarlem en omgeving", "Een aantal jaar relevante ervaring met fijnmechanica en elektronica assemblage", "Ervaring met solderen is een pré", "Twee rechter handen", "Goede ogen is van belang voor details", "32 tot 40 uur beschikbaar per week", "Goede beheersing van Nederlandse en Engelse taal in woord en geschift", "Beheersing van Duitse taal is een pré", "Je bent secuur en hebt oog voor details en kwaliteit", "Je presteert goed onder druk maar wel georganiseerd", "Je kunt goed overweg met Windows, Microsoft Office en andere computersoftware", "Je bent een team player"],
		voorwaarden:["Een jaarcontract met uitzicht op een vast dienstverband", "Marktconform salaris", "Lahoux Optics werkt alleen in dagdiensten van maandag tot en met vrijdag", "Reiskosten op basis van kilometervergoeding", "24 vakantiedagen en 8% vakantiegeld", "Een uitdagende baan in een informele werkomgeving waar ruimte is voor investeringen, nieuwe technieken en opleidings- en doorgroeimogelijkheden"],		   
	},
	{
		id: 2,
    	title: "FPGA Engineer",
        url: "FPGA-engineer",
		city: "Haarlem",
		hours:  "Parttime/Fulltime (32/40 uur)",
        number: "1720225",
        intro: "Als FPGA engineer bij Lahoux kom je te werken op een jonge, informele en dynamische R&D afdeling, waar we het continu ontwikkelen van kwaliteit centraal hebben staan. Voor de ontwikkeling van een cameramodule zijn we op zoek naar een FPGA engineer met ervaring in vision systemen.",
		p1: "Lahoux Optics is een snel groeiend en ontwikkelend bedrijf, wat zich richt op het ontwikkelen en produceren van innovatieve en hoogwaardige nachtzicht- en thermische apparatuur voor de civiele markt. We putten hierbij uit ruim 30 jaar ervaring als gevestigde speler binnen deze markt.",
		p2: "Als FPGA engineer bij Lahoux kom je te werken op een jonge, informele en dynamische R&D afdeling, waar we het continu ontwikkelen van kwaliteit centraal hebben staan.",
		p3: "Voor de ontwikkeling van een cameramodule zijn we op zoek naar een FPGA engineer met ervaring in vision systemen. Binnen deze functie ben je verantwoordelijk voor het omzetten van beeldbewerkingsalgoritmes in een FPGA design block. Deze design blocks valideer en test je aan de hand van simulaties, en integreer en valideer je op hardware. Verder bied je ondersteuning met het opbouwen en onderhouden van (remote-) testopstellingen, en draag je bij aan het maken van testbenches.",
        bullits1: [],
		p4: "",
		p5: "",
		eisen:["Bij voorkeur woonachtig in Randstad", "32-40 uur per week beschikbaar", "HBO+ of WO werk- en -denkniveau", "Ervaringsniveau Medior of Senior", "Ervaring met het implementeren van beeldbewerking applicaties (vision) in embedded systemen", "Minimaal 5 jaar ervaring met FPGA in VHDL", "Ervaring met Xilinx FPGA tooling", "Ervaring met simulatie, integratie en validatie van FPGA designs", "Ervaring met scrum en agile werkwijze is een pré", "Proactief, praktisch ingesteld en communicatief vaardig", "Beheersing van de Engelse taal (in woord en geschrift)"],
		voorwaarden:["Marktconform salaris", "Een jaarcontract met uitzicht op een verlenging van een jaarcontract of een vast dienstverband", "Jong en energiek R&D team", "Veel mogelijkheid tot eigen inbreng en definiëren eigen delen van projecten", "De mogelijkheid tot thuiswerken. Lahoux is hier goed op ingericht met onder andere remote-accessible testopstellingen", "Een uitdagende baan in een informele werkomgeving waar ruimte is voor investeringen, nieuwe technieken en opleidings- en doorgroeimogelijkheden", "Reiskosten op basis van kilometervergoeding"],		   
	},
	{
		id: 3,
    	title: "Senior Elektronica Hardware Engineer",
        url: "senior-elektronica-hardware-engineer",
		city: "Haarlem",
		hours:  "Parttime/ Fulltime (32/40 uur)",
        number: "1720222",
        intro: "Zit het ontwerpen en ontwikkelen in jouw bloed en wil jij jouw bijdrage leveren aan onze innovatieve producten? Wij zijn momenteel met spoed op zoek naar een Senior Elektronica Hardware Engineer binnen onze afdeling R&D.",
		p1: "Zit het ontwerpen en ontwikkelen in jouw bloed en wil jij jouw bijdrage leveren aan onze innovatieve producten? Wij zijn momenteel met spoed op zoek naar een Senior Elektronica Hardware Engineer binnen onze afdeling R&D.",
		p2: "Nieuwsgierig geworden? Lees dan snel hieronder verder of jij jezelf in deze vacature herkent.",
		p3: "Een greep uit de taken en verantwoordelijkheden voor de functie Senior Elektronica Hardware Engineer:",
        bullits1: ["Opstellen schakelschema’s", "Schrijven specificaties voor elektronica ontwerpen", "Maken en productie gereed aanleveren van printplaatontwerpen", "Schrijven validatieplan voor elektronicadesigns", "Testen en valideren elektronica met onder andere microcontrollers en FPGA’s", "Documenteren van designs en ontwerpbeslissingen", "Assemblage prototypes", "Coachen juniormedewerkers"],
		p4: "Lahoux Optics levert nacht- en warmtebeeldkijkers en is gevestigd in Haarlem.",
		p5: "",
		eisen:["Woonachtig in omgeving Haarlem", "32/ 40 uur per week beschikbaar", "Minimaal HBO werk- en -denkniveau", "Minimaal 5 jaar ervaring met elektronica design, inclusief componentenselectie, opstellen schakelschema en routen boards", "Affiniteit met embedded engineering en FPGA-development", "Ervaring met debuggen en validatie van elektronica", "Beheerst Autodesk Eagle", "Ervaring met scrum en agile werkwijze is een pré", "Proactief, praktisch ingesteld en communicatief vaardig", "Beheersing van de Nederlandse en Engelse taal (in woord en geschrift)", "Beheersing van de Duitse taal is een pré"],
		voorwaarden:["Een jaarcontract met uitzicht op een vast dienstverband", "Jong en energiek R&D team", "Veel mogelijkheid tot eigen inbreng en definiëren eigen delen van projecten", "De mogelijkheid tot thuiswerken. Lahoux is hier goed op ingericht met onder andere remote-accessible testopstellingen", "Een uitdagende baan in een informele werkomgeving waar ruimte is voor investeringen, nieuwe technieken met opleidings- en doorgroeikansen", "Lahoux Optics werkt alleen in dagdiensten van maandag tot en met vrijdag", "Marktconform salaris", "Reiskosten op basis van kilometervergoeding", "Vakantiedagen en vakantiegeld" ],		   
	},
	*/
	// {
	// 	id: 4,
    // 	title: "HR-medewerker",
    //     url: "hr-medewerker",
	// 	city: "Haarlem",
	// 	hours:  "Parttime (20-24 uur)",
    //     number: "1720220",
    //     intro: "Lahoux Optics is op zoek naar een enthousiaste en ervaren HR-medewerker die op het gebied van HR een echte duizendpoot is.",
	// 	p1: "Lahoux Optics is op zoek naar een enthousiaste en ervaren HR-medewerker die op het gebied van HR een echte duizendpoot is.",
	// 	p2: "",
	// 	p3: "Een greep uit de taken en verantwoordelijkheden voor de functie HR-medewerker:",
    //     bullits1: ["Werving en selectie", "Contracten opstellen/wijzigen", "Opstellen functieomschrijvingen", "Bijhouden van wetten en regels voor personeelsbeleid en doorvoeren van veranderingen die hieruit voortkomen en vastleggen hiervan", "Verwerken van personeelsadministratie in Exact", "Opstellen-en bijhouden van personeelshandboek", "Inplannen proeftijd-, voortgang-, evaluatie - en exitgesprekken, inclusief notuleren en laten ondertekenen", "Verzuimregistratie (Verzuimvizier)", "Naleving Wet Verbetering Poortwachter inclusief voortgangsgesprekken gedurende ziekte en het vastleggen hiervan", "Verslaglegging aan- en contact onderhouden met de Arbodienst", "Naleven Wet op Privacy", "Ergonomisch werken optimaliseren binnen organisatie", "Ondersteunen en beheren opleidingen (o.a. BHV)", "Opzetten- en bijhouden deel van ISO-certificering personeelsbeleid", "Zorg dragen voor verjaardagen en jubilea", "Organiseren van personeelsgelegenheden"],
	// 	p4: "Dit alles in samenwerking met de HR-collega’s",
	// 	p5: "Lahoux Optics levert nacht- en warmtebeeldkijkers en is gevestigd in Haarlem.",
	// 	eisen:["Woonachtig in omgeving Haarlem", "20-24 uur per week beschikbaar, verdeeld over 3 of 4 dagen", "Dinsdag en vrijdag beschikbaar", "Beschikt over minimaal 2 jaar relevante, recente en soortgelijke HR-werkervaring", "Minimaal HBO werk- en -denkniveau; richting Human resource management", "Presteert goed onder druk maar wel georganiseerd", "Ervaring met Exact is een pré", "Uitstekende beheersing van Windows, Microsoft Office en andere computersoftware", "Team player", "Zelfstandigheid, stressbestendig, resultaat- en probleemoplossend gericht", "Beheersing van de Nederlandse en Engelse taal (in woord en geschrift)", "Beheersing van de Duitse taal is een pré"],
	// 	voorwaarden:["Een jaarcontract met uitzicht op een vast dienstverband", "Een uitdagende baan in een informele werkomgeving waar ruimte is voor investeringen, nieuwe technieken met opleidings- en doorgroeikansen", "Lahoux Optics werkt alleen in dagdiensten van maandag tot en met vrijdag",  "Marktconform salaris", "Reiskosten op basis van kilometervergoeding", "Vakantiedagen en vakantiegeld" ],		   
	// }

];
export default DataVacatures;