import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Card from "../Card";
import DataCards from "./DataCardsJagdHund";

const JagdUndHund = ({ clientCountry, clientVat, checkEndDate }) => {
  const { t } = useTranslation();

  return (
    <StyledJagdUndHund>
      <Helmet>
        <title>Jagd und Hund Dortmund Germany 2023</title>
      </Helmet>
      
      <div className="article-copy">
        <h1>{t("jagd_und_hund.page_heading")}</h1>

        <p><a href="https://www.jagdundhund.de/en-gb" target="_blank" rel="noopener noreferrer">{t("jagd_und_hund.article_link_jagd_hund")}</a> {t("jagd_und_hund.article_p1")}</p>
        <p>{t("jagd_und_hund.article_p2")}</p>


        {/* {checkEndDate("2023-01-29") ? (  
          <p>{t("jagd_und_hund.article_p4")}</p>
          ) : null} */}
      </div>
{/* 
        <section className="cards">
          {DataCards
          .filter((card) => checkEndDate(card.end_date))
          .map((card) => (
              <Card key={card.id} card={card} clientCountry={clientCountry} clientVat={clientVat} />
          ))}
        </section>
 */}

    </StyledJagdUndHund>
  );
};

const StyledJagdUndHund = styled.div`
  margin: 60px auto 120px auto;
  max-width: 90vw;
  font-size: 20px;
  @media (min-width: 768px) {
    max-width: 70vw;
  }

  @media (min-width: 1024px) {
    max-width: 60vw;
  }

  .article-copy {
    max-width: 100vw;
    /* margin: 0 auto; */
    @media (min-width: 768px) {
      max-width: 70vw;
    }

    @media (min-width: 1024px) {
      max-width: 50vw;
    }
  }

  .article-copy a {
    color: var(--text);
  }

  h1 {
    font-size: 24px;
    font-weight: 700;
    border-bottom: 2px solid var(--brand-accent-color);
    padding-bottom: 6px;
    margin-bottom: 20px;
    display: inline-block;
  }

  h2 {
    font-weight: 700;
    border-bottom: 2px solid var(--brand-accent-color);
    padding-bottom: 6px;
    margin-top: 20px;
    margin-bottom: 10px;
    display: inline-block;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 6px;
  }

  p {
    line-height: 22px;
    margin-bottom: 20px;
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
  }

  .card {
    display: flex;
    flex-direction: column;
    min-width: 230px;
    min-height: 324px;
    width: 320px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 20px;
    border-radius: 8px;
    background-color: #595d40;

    animation-name: carditem;
    animation-duration: 2.5s;
    animation-timing-function: ease-out;
  }

  @keyframes carditem {
    from {opacity: 0;}
    to {opacity: 1;}
  }

`;

export default JagdUndHund;
