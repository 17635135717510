import React, {useEffect} from 'react';
import Seo from "./Seo";
import DataGlossary from './DataGlossary';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";

const Glossary = ({ showItems, setShowItems, clickHandlerShowItem }) => {

  const { t, i18n } = useTranslation();

    // check if showItems is event or archive or all
    // if so, set showItem to standard thermal on page load to prevent empty tab/list 
    // (because this page does not know of event or archive or digital or accessories)
    useEffect(() => {
      if (showItems === "events" || showItems === "archive" || showItems === "digital" || showItems === "accessories" || showItems === "all") {
          setShowItems("thermal");
      }
    }, []);

  // make description meta tag in Helmet get translatable content
  const helmetDescription = {
    en: "Definition of various subject-specific terms",
    nl: "Definitie van diverse vakspecifieke termen",
    de: "Definition verschiedener fachspezifischer Begriffe",
  };    

  return (
    <StyledGlossary>
      <Seo
        title={t("glossary_seo_title")}
        description={helmetDescription[i18n.language]}
        url="https://lahouxoptics.com/woordenlijst"
      />

      <div className="glossary">
        <ul>
          {DataGlossary.filter((glos) => glos.type === showItems).map(
            (item) => (
              <li key={item.id}>
                <p>{item.title[i18n.language]}</p>
                <p>{item.desc[i18n.language]}</p>
              </li>
            )
          )}
        </ul>
      </div>
    </StyledGlossary>
  );
}

const StyledGlossary = styled.div`
  margin: 60px auto 0 auto;
  max-width: 90vw;
  font-size: 1.25rem;
  @media (min-width: 768px) {
    max-width: 70vw;
  }

  @media (min-width: 1024px) {
    max-width: 50vw;
  }

  .glossary {
    max-width: 100vw;
    margin: 0 auto;
    @media (min-width: 768px) {
      max-width: 70vw;
    }

    @media (min-width: 1024px) {
      max-width: 50vw;
    }
  }

  ul li p:first-of-type {
    color: #fc751b;
    font-weight: bold;
    margin-bottom: 5px;
  }
  ul li p:last-of-type {
    color: #c1b8b8;
    margin-bottom: 30px;
  }
`;

export default Glossary