import React from 'react';
import DataProducts from './DataProducts'
import styled from 'styled-components';
import {
    Link
  } from "react-router-dom";
  import { useTranslation } from "react-i18next";
  import "../locales/i18n";



const ProductHighlight = ({showItems}) => {
  let text = "wm1";
  switch (showItems) {
    case "night":
      text = "nz1";
      break;
    case "digital":
      text = "dg1";
      break;
      case "thermal":
        text = "wm22";
        break;
      case "accessories":
        text = "as1";
        break;
    default:
      text = "wm1";
  }
    var product = DataProducts.find(product => product.id === text)
    
    const { t, i18n } = useTranslation();
  return (
    <StyledProductHighlight>
        
       
    <div className="highlight">
        
        <img src={"/productafbeeldingen/"+product.images[0]} alt="product afbeelding" />
        <div className="copy">
            <h1>{product.title[i18n.language]}</h1>
            <p>{product.spotlight[i18n.language]}</p>
            <div className="links">
                <Link to="/dealers">{t("find_dealer")}</Link>
                <Link to={`/producten/${product.url}`} >{t("product_detail")} </Link>
            </div>    
        </div>    
    </div>
    </StyledProductHighlight>
  )
}
const StyledProductHighlight = styled.div`
div{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    align-items: center;
    flex-wrap: wrap;
    @media (min-width: 768px) {
      margin-top: 20px;
      flex-wrap: wrap;
    }

    @media (min-width: 1024px) {
      margin-top: 50px;
      flex-wrap: no-wrap;
    }  

}
div img{
    width:90vw;
    @media (min-width: 768px) {
      width:80vwx;
    }

    @media (min-width: 1024px) {
      width:700px;
    }  
}

div div{
    max-width: 500px;
    flex-direction: column;
    margin-left: 40px;
    margin-right: 40px;
    align-items: stretch;
}
div h1{
  border-bottom: 2px solid var(--brand-accent-color);
  padding-bottom: 6px;
  margin-bottom: 10px;
  display: inline;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  @media (min-width: 768px) {
    font-size: 2rem;
  }
}
p{
  color: #fff;
  font-size: 1.25rem;
  margin-bottom: 30px;
  line-height: 1.2;
  @media (min-width: 768px) {
    font-size: 1.375rem;
  }

}
.links{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;
    
    
}

.links a{
    color: var(--link);
    text-decoration: none;
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 10px;
    display: inline-block;
    margin: 0 20px 20px 0;
    font-size: 1.375rem;
  
}
.links a:hover{
  background-color: var(--brand-accent-color);
  border-color: var(--brand-accent-color);
}
.subtitle{
    font-size: 1rem;
    margin: 0 0 30px 0;
}
`
export default ProductHighlight